import { FormInstance } from 'antd';
import { IGetApr } from './apr.types';
import { APR_PRIVREDNI_SUBJEKAT_OPTIONS } from './apr.constants';
import { SwitchSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import { t } from 'i18next';

class AprService {
  makePrivredniSubjekat = (form: FormInstance<IGetApr>) => {
    const checkValues = APR_PRIVREDNI_SUBJEKAT_OPTIONS.map(item => {
      return item.value;
    });
    const newArray: any = APR_PRIVREDNI_SUBJEKAT_OPTIONS.map(item => {
      return {
        type_of_form: 'switch',
        label: item.label,
        name: item.value,
        hideInput: true,
        onSwitchChange: (_: SwitchChangeEventHandler) => {
          form.validateFields(checkValues);
        },
        rules: [
          {
            validator(_: any) {
              const formValues = form.getFieldsValue(checkValues);
              if (!Object.values(formValues).some(value => value === true)) {
                return Promise.reject(new Error(t('COMPANY.BUSINESS_ENTITY_VALIDATION')));
              }
              return Promise.resolve();
            },
          },
        ],
      } as SwitchSifarniciFormProps;
    });
    return newArray;
  };
}

export const aprService = new AprService();
