import { observer } from 'mobx-react-lite';
import React from 'react';
import { Gio5UvozOtpadaModal } from './components/Gio5UvozOtpadaModal/Gio5UvozOtpadaModal';
import { Gio5DozvoleModal } from './components/Gio5DozvoleModal/Gio5DozvoleModal';
import { Gio5ZemljeTranzitaModal } from './components/Gio5ZemljeTranzitaModal/Gio5ZemljeTranzitaModal';

export const Gio5MicroServices: React.FC = observer(() => {
  return (
    <>
      <Gio5DozvoleModal />
      <Gio5UvozOtpadaModal />
      <Gio5ZemljeTranzitaModal />
    </>
  );
});
