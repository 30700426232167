import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import styles from './Obrazac3Opste.module.scss';
import { Col } from 'antd';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { useTranslation } from 'react-i18next';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetObrazac3 } from 'modules/obrasci/store/obrazac3/obrazac3.types';

export const Obrazac3OpsteWizardTab: React.FC = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    if (
      obrasciStore.opste.godina &&
      obrasciStore.opste.preduzece_id !== '' &&
      obrasciStore.opste.postrojenje_id !== '' &&
      obrazac3Store.izvor_zagadjenja_vode_id !== ''
    ) {
      obrasciStore.setDisabledTabButton(false);
    }
  }, [obrasciStore.opste, obrazac3Store.izvor_zagadjenja_vode_id]);

  const defaultWaterSourceOption: ISifarnikInitial | undefined = obrazac3Store.initialState.izvor_zagadjenja_vode_id
    ? {
        label: (obrazac3Store.initialState as IGetObrazac3).izvor_zagadjenja_vode.naziv_ispusta,
        value: obrazac3Store.initialState.izvor_zagadjenja_vode_id,
      }
    : undefined;

  return (
    <Col span={12} className={styles.wrapper}>
      <OpsteGodinaPreduzecePostrojenje withPostrojenje={true} store={obrazac3Store} />
      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('POLLUTION.WATER.TITLE')}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        placeholder={t('POLLUTION.WATER.TITLE')}
        formName={'izvor_zagadjenja_vode_id'}
        fetchOptinsList={obrazac3Store.fetchSourceWater}
        labelOptionAccessor="naziv_ispusta"
        disabledAccessor="aktivan"
        valueOptionAccessor="id"
        readOnly={false}
        defaultOption={defaultWaterSourceOption}
        onChange={value => {
          obrazac3Store.handleChange('izvor_zagadjenja_vode_id', value);
          const { preduzece_id, postrojenje_id, godina } = obrasciStore.opste;
          if (Number(godina) >= 2023) {
            obrazac3Store.checkOtvObaveza({ preduzece_id, postrojenje_id, izvor_zagadjenja_vode_id: value });
          }
        }}
        rules={[REQUIRED_FIELD_RULE(true)]}
        disabled={
          obrasciStore.opste.godina === '' ||
          obrasciStore.opste.postrojenje_id === '' ||
          obrasciStore.opste.preduzece_id === '' ||
          obrazac3Store.initialState.izvor_zagadjenja_vode_id !== '' ||
          !obrasciStore.disabled_tab_button
        }
      />
    </Col>
  );
});
