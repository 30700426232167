import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { NewDep1divljaDeponijaForm } from './components/NewDep1DivljaDeponijaDep1Form/NewDep1DivljaDeponijaForm';
import { observer } from 'mobx-react-lite';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';

export const Dep1NesanitarnaDeponijaWizardTab: React.FC<ICrudTypeModalProps> = observer(props => {
  return (
    <InsideContentWrapper header={null}>
      <NewDep1divljaDeponijaForm form={props.form} />
    </InsideContentWrapper>
  );
});
