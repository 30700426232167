import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { Aao1KolicinaAmbalazeTable } from './components/Aao1KolicinaAmbalazeTable';

export const Aao1KolicinaAmbalazeWizardTab = () => {
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'kolicina-ambalaze-form'} modalName="kolicina-ambalaze-modal" />}
    >
      <Aao1KolicinaAmbalazeTable />
    </InsideContentWrapper>
  );
};
