import { ApiResponse, axios } from 'modules/axios';
import { ITipObrasca, ITipObrascaCreate, IZakljucaneGodinePoTipuObrasca, TIPOVI_OBRAZACA } from './obrasci.types';

import { OBRAZAC_TYPE_API_URL_MAP } from './obrasci.constants';
import queryString from 'query-string';
import { ROUTES } from 'modules/nriz/nriz.constants';
import { Deo6Gio6Enum, Deo6KalkulacijaParams, Deo6KalkulacijaResponse } from './store/deo6/deo6.types';
import { IExportDeo6Gio6PdfForm } from 'components/Modals/ExportDeo6Gio6PdfModal/ExportDeo6Gio6PdfModal';
import { IZakljucavanjeObrazacaFilters } from 'modules/zakljucavanje/zakljucavanje.types';

const fetchTipoviObrasca = (): ApiResponse<{ items: ITipObrasca[] }> => {
  return axios.get(`${ROUTES.TIP_OBRASCA}/?order_by=+skraceni_naziv`);
};

const fetchTipoviObrazacaPoPostrojenju = (id: string): ApiResponse<{ items: ITipObrasca[] }> => {
  return axios.get(`${ROUTES.FACILITY}/${id}/${ROUTES.TIP_OBRASCA}/`);
};
const fetchTipoviObrazacaPoPreduzecu = (id: string): ApiResponse<{ items: ITipObrasca[] }> => {
  return axios.get(`${ROUTES.COMPANY}${id}/${ROUTES.TIP_OBRASCA}/`);
};

const postTipoviObrasca = (obrazac: ITipObrascaCreate): ApiResponse<{ id: string }> => {
  return axios.post(`${ROUTES.TIP_OBRASCA}/`, obrazac);
};

const fetchSingleObrazac = <T>(type: string, id: string): ApiResponse<T> => {
  const typeRoute = OBRAZAC_TYPE_API_URL_MAP[type];
  return axios.get(`${ROUTES.OBRAZAC}/${typeRoute}/${id}`);
};

const fetchListObrazac = <T>(type: string, filters: any): ApiResponse<T> => {
  const query = queryString.stringify(filters);
  const typeRoute = OBRAZAC_TYPE_API_URL_MAP[type];
  return axios.get(`${ROUTES.OBRAZAC}/${typeRoute}/?${query}`);
};

const exportObrazacListExcel = (type: TIPOVI_OBRAZACA, filters: any): ApiResponse<Blob> => {
  const typeRoute = OBRAZAC_TYPE_API_URL_MAP[type];
  const query = queryString.stringify(filters);
  return axios.get(`${ROUTES.OBRAZAC}/${typeRoute}/export?${query}`, { responseType: 'blob' });
};

const calculateGodisnjiBilans = <T>(type: string, payload: any): ApiResponse<T> => {
  const typeRoute = OBRAZAC_TYPE_API_URL_MAP[type];
  return axios.post(`${ROUTES.OBRAZAC}/${typeRoute}/godisnji-bilans/`, payload);
};

const postSingleObrazac = <T>(
  type: string,
  payload: T,
  filters?: any
): ApiResponse<{
  id: string;
}> => {
  const typeRoute = OBRAZAC_TYPE_API_URL_MAP[type];
  const query = queryString.stringify(filters);
  return axios.post(`${ROUTES.OBRAZAC}/${typeRoute}/?${query}`, payload);
};

const putSingleObrazac = <T>(type: string, id: string, payload: T): ApiResponse<{ id: string }> => {
  const typeRoute = OBRAZAC_TYPE_API_URL_MAP[type];
  return axios.put(`${ROUTES.OBRAZAC}/${typeRoute}/${id}`, payload);
};

const putSingleObrazacWithParams = (type: string, id: string, params: string): ApiResponse<{ id: string }> => {
  const typeRoute = OBRAZAC_TYPE_API_URL_MAP[type];
  return axios.put(`${ROUTES.OBRAZAC}/${typeRoute}/${id}/${params}`);
};

const deleteSingleObrazac = <T>(type: string, id: string): ApiResponse<T> => {
  const typeRoute = OBRAZAC_TYPE_API_URL_MAP[type];
  return axios.delete(`${ROUTES.OBRAZAC}/${typeRoute}/${id}`);
};

const fetchInforForLastYear = <T>(filters: any): ApiResponse<T> => {
  const query = queryString.stringify(filters);
  return axios.get(`${ROUTES.OBRAZAC}/obrazac5/info-prethodna-godina/?${query}`);
};

const exportPdfSingleObrazac = (type: TIPOVI_OBRAZACA, id: string): ApiResponse<Blob> => {
  const typeRoute = OBRAZAC_TYPE_API_URL_MAP[type];
  return axios.get(`${ROUTES.OBRAZAC}/${typeRoute}/${id}/pdf`, { responseType: 'blob' });
};

const exportPdfDeo6Gio6 = (type: Deo6Gio6Enum, filters: IExportDeo6Gio6PdfForm): ApiResponse<Blob> => {
  const isGio6: boolean = type === 'GIO6';
  const query = queryString.stringify(filters);
  return axios.get(`${ROUTES.OBRAZAC}/deo6/pdf${isGio6 ? '-gio6' : ''}?${query}`, { responseType: 'blob' });
};

const getDeo6SkladisteInfo = (params: Deo6KalkulacijaParams): ApiResponse<Deo6KalkulacijaResponse> => {
  const query = queryString.stringify(params);
  return axios.get(`${ROUTES.OBRAZAC}/deo6/kalkulacija/?${query}`);
};

const checkGodinaZakljucana = (tipObrascaId: string): ApiResponse<IZakljucaneGodinePoTipuObrasca[]> => {
  return axios.get(`${ROUTES.ZAKLJUCAVANJE.PO_GODINI}/tip-obrasca/${tipObrascaId}`);
};

const checkIfObrazacLocked = (id: string): ApiResponse<IZakljucavanjeObrazacaFilters> => {
  return axios.get(`${ROUTES.ZAKLJUCAVANJE.PO_TIPU}/${id}`);
};

const deleteObrazac = (type: TIPOVI_OBRAZACA, id: string) => {
  const typeRoute = OBRAZAC_TYPE_API_URL_MAP[type];
  return axios.delete(`${ROUTES.OBRAZAC}/${typeRoute}/${id}`);
};

export const obrasciRepo = {
  fetchTipoviObrasca,
  fetchListObrazac,
  postTipoviObrasca,
  fetchSingleObrazac,
  postSingleObrazac,
  putSingleObrazac,
  putSingleObrazacWithParams,
  deleteSingleObrazac,
  fetchTipoviObrazacaPoPreduzecu,
  fetchTipoviObrazacaPoPostrojenju,
  calculateGodisnjiBilans,
  exportObrazacListExcel,
  fetchInforForLastYear,
  exportPdfSingleObrazac,
  exportPdfDeo6Gio6,
  getDeo6SkladisteInfo,
  checkGodinaZakljucana,
  checkIfObrazacLocked,
  deleteObrazac,
};
