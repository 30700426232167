import { IPostPtp2Obrazac, IProizvod } from './ptp2.types';

export const PTP2_INITIAL_STATE: IPostPtp2Obrazac = {
  godina: '',
  preduzece_id: '',
  dozvoljene_korekcije: false,
  napomena: '',
  napomena_administratora: '',
  proizvodi: [],
};

export const PTP2_PROIZVODI_INITIAL_STATE: IProizvod = {
  products_nr_id: '',
  products_type_id: '',
  kolicina_proizvoda_stavljenih_na_trziste: null,
  kolicina_proizvoda: null,
  pdv: null,
  ulje_kolicine: null,
};
