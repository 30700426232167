import { Row, Col, Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import { filtersStore } from 'store/filteri.store';
import { nrizService } from 'modules/nriz/nriz.service';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IGetCompany } from 'modules/company/company.types';
import { IGetFacility } from 'modules/facility/facility.types';
import styles from './ObrasciCompanyUserFilters.module.scss';
import { Select } from 'components/Select/Select';
import storage, { StorageEnum } from 'store/storage';
import { companyStore } from 'modules/company/company.store';
import { facilityStore } from 'modules/facility/facility.store';

export const ObrasciCompanyUserFilters: React.FC = observer(() => {
  const [activeTab, setActiveTab] = useState('');
  const [defaultPostrojenje, setDefaultPostrojenje] = useState<any>();

  const { t } = useTranslation();

  const handlePostrojenjeChange = (_: any, option: any) => {
    storage.setData(StorageEnum.POSTROJENJE, option.postrojenje);
    filtersStore.setFilters('obrasci', {});
    obrasciStore.handleChange('obrazac_type', '');
    obrasciStore.handleChange('real_obrazac_type', '');
    obrasciStore.handleOpste('postrojenje_id', option.postrojenje.id);
    obrasciStore.handleOpste('postrojenje', option.postrojenje);
    obrasciStore.fetchTipoviObrazacaPoPostrojenju();
  };

  useEffect(() => {
    obrasciStore.handleOpste('preduzece_id', (companyStore.company as IGetCompany).id);
    obrasciStore.handleOpste('preduzece', companyStore.company as IGetCompany);
    storage.setData(StorageEnum.PREDUZECE, companyStore.getCompany as IGetCompany);
    if (obrasciStore.opste.postrojenje_id) {
      setActiveTab('2');
      setDefaultPostrojenje({
        value: obrasciStore.opste.postrojenje_id,
        label: obrasciStore.opste.postrojenje.naziv_postrojenja,
        postrojenje: obrasciStore.opste.postrojenje,
      });
      obrasciStore.fetchTipoviObrazacaPoPostrojenju();
    } else {
      setActiveTab('1');
      obrasciStore.fetchTipoviObrazacaPoPreduzecu();
      storage.removeData(StorageEnum.POSTROJENJE);
      facilityStore.fetchFacilityListByUser({
        page: 1,
        size: 100,
        total: 0,
        pages: 0,
      });
    }
  }, []);

  const onTabChange = (e: string) => {
    filtersStore.setFilters('obrasci', {});
    obrasciStore.handleChange('obrazac_type', '');
    obrasciStore.handleChange('real_obrazac_type', '');

    if (e === '1') {
      setActiveTab('1');

      obrasciStore.fetchTipoviObrazacaPoPreduzecu();
      storage.removeData(StorageEnum.POSTROJENJE);
      obrasciStore.handleChange('opste', {
        ...obrasciStore.opste,
        postrojenje_id: '',
        postrojenje: '',
      });
    }

    if (e === '2') {
      setActiveTab('2');
      obrasciStore.handleChange('opste', {
        ...obrasciStore.opste,
        postrojenje_id: (facilityStore.facilityList[0] as IGetFacility).id,
        postrojenje: facilityStore.facilityList[0],
      });
      setDefaultPostrojenje({
        value: obrasciStore.opste.postrojenje_id,
        label: obrasciStore.opste.postrojenje.naziv_postrojenja,
        postrojenje: obrasciStore.opste.postrojenje,
      });
      storage.setData(StorageEnum.POSTROJENJE, obrasciStore.opste.postrojenje);
      obrasciStore.fetchTipoviObrazacaPoPostrojenju();
    }
  };

  const postrojenjaOptions = (facilityStore.getFacilityList as IGetFacility[]).map((item: IGetFacility) => ({
    label: item.naziv_postrojenja,
    value: item.id,
    postrojenje: item,
    // disabled: item.is_active,
  }));

  const tabsUserCompanyFacility = [
    {
      key: '1',
      label: <div className={styles.first_tab}>{t('COMPANY.TITLE')}</div>,
    },
    {
      key: '2',
      label: (
        <Row
          align={'middle'}
          className={`${styles.second_tab} ${
            activeTab === '2' ? styles.second_tab_active : styles.second_tab_inactive
          }`}
        >
          <Col span={4}>{t('FACILITY.TITLE')}</Col>
          {activeTab === '2' && (
            <Col span={20}>
              <Select
                showSearch
                placeholder={t('OBRASCI.PLACEHOLDER.CHOOSE_TIP_OBRASCA')}
                optionFilterProp="children"
                onChange={(e: string, option: any) => handlePostrojenjeChange(e, option)}
                value={defaultPostrojenje}
                filterOption={nrizService.handleSearchOptions}
                options={postrojenjaOptions}
                name={'postrojenje'}
              />
            </Col>
          )}
        </Row>
      ),
    },
  ];

  return (
    <Tabs
      className={styles.tabs}
      activeKey={activeTab}
      onChange={(e: string) => onTabChange(e)}
      type="card"
      items={tabsUserCompanyFacility}
    />
  );
});
