import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { ZastitaBiljaTable } from 'pages/ObrazacWizard/components/pzv1/components/ZastitaBiljaWizardTab/components/ZastitaBiljaTable/ZastitaBiljaTable';
import { ZastitaBiljaHeader } from './components/ZastitaBiljaHeader/ZastitaBiljaHeader';

export const ZastitaBiljaWizardTab = () => {
  // useEffect(()=> {

  // }, [])

  return (
    <InsideContentWrapper header={<ZastitaBiljaHeader />}>
      <ZastitaBiljaTable />
    </InsideContentWrapper>
  );
};
