import { Modal } from 'components/Modal/Modal';
import { NewAirPollutionForm } from 'components/NewForms';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import styles from './AirModal.module.scss';
import { airInitialState } from 'modules/sourcesOfPollution/air/air.constants';
import { airStore } from 'modules/sourcesOfPollution/air/air.store';
import { IGetAir } from 'modules/sourcesOfPollution/air/air.types';
import { IsActiveSwitch } from 'components/IsActiveSwitch/IsActiveSwitch';
import { Row } from 'antd';
import { facilityStore } from 'modules/facility/facility.store';

export const AirModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Modal
      name="air-modal"
      open={modalStore.getCurrentOpenedModal.includes('air-modal')}
      className={`${styles.air_modal}`}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => {
        airStore.setAir(airInitialState);
        modalStore.removeAllModals();
      }}
      title={
        <Row justify={'space-between'} style={{ marginRight: '10px' }}>
          <h3>{t('POLLUTION.AIR.TITLE')}</h3>
          {(airStore.air as IGetAir).id && (
            <IsActiveSwitch
              isActive={airStore.getAir.aktivan}
              aktiviraj={airStore.aktivirajVazduh}
              deaktiviraj={airStore.deaktivirajVazduh}
              disabled={!facilityStore.getFacility.is_active}
            />
          )}
        </Row>
      }
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      width={'50%'}
    >
      <NewAirPollutionForm />
    </Modal>
  );
});
