import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, NAME_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IPostWasteCharacter, IGetWasteCharacter } from './wasteCharacter.types';

export const WASTE_CHARACTER_INITIAL_STATE: IPostWasteCharacter = {
  name: '',
  name_sr: '',
  is_active: true,
};

export const WASTE_CHARACTER_TABLE_DATA: ColumnsType<IGetWasteCharacter> = [NAME_TABLE, NAME_SR_TABLE];

export const WASTE_CHARACTER_FORM_OPTIONS = (_: FormInstance<IGetWasteCharacter>) => {
  return [NAME_FORM, NAME_SR_FORM];
};
