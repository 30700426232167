import { IGetCList } from 'modules/sifarnici/store/cList/cList.types';
import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetIaList } from 'modules/sifarnici/store/iaList/iaList.types';
import { IGetIibList } from 'modules/sifarnici/store/iibList/iibList.types';
import { IGetNList } from 'modules/sifarnici/store/nList/nList.types';
import { IGetQList } from 'modules/sifarnici/store/qList/qList.types';
import { IGetYList } from 'modules/sifarnici/store/yList/yList.types';

export const drListOptions = (options: IGetDRList[]) => {
  return options.map(drOption => ({
    label: `${drOption.name} ${drOption.description_sr}`,
    value: drOption.id,
  }));
};

export const yListOptions = (options: IGetYList[]) => {
  return options.map(yOption => ({
    label: `${yOption.name} ${yOption.description_sr}`,
    value: yOption.id,
  }));
};

export const cListOptions = (options: IGetCList[]) => {
  return options.map(yOption => ({
    label: `${yOption.name} ${yOption.description_sr}`,
    value: yOption.id,
  }));
};

export const nListOptions = (options: IGetNList[]) => {
  return options.map(yOption => ({
    label: `${yOption.name} ${yOption.description_sr}`,
    value: yOption.id,
  }));
};

export const qListOptions = (options: IGetQList[]) => {
  return options.map(yOption => ({
    label: `${yOption.name} ${yOption.description_sr}`,
    value: yOption.id,
  }));
};

export const iaListOptions = (options: IGetIaList[]) => {
  return options.map(yOption => ({
    label: `${yOption.name} ${yOption.description_sr ?? ''}`,
    value: yOption.id,
  }));
};

export const iibListOptions = (options: IGetIibList[]) => {
  return options.map(yOption => ({
    label: `${yOption.name} ${yOption.description_sr ?? ''} `,
    value: yOption.id,
  }));
};
