import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { pzv1ObrazacStore } from 'modules/obrasci/store/pzv1/pzv1.store';
import { NewDjubrivaForm } from './components/NewDjubrivaForm/NewDjubrivaForm';
import { DJUBRIVA_INITIAL_STATE } from 'modules/obrasci/store/pzv1/pzv1.constants';

export const DjubrivaModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="70%"
      name="djubriva-modal"
      title={t('OBRASCI.PZV_1.FERTILIZERS.TITLE')}
      store={pzv1ObrazacStore}
      data={pzv1ObrazacStore.getDjubrivo}
      children={() => <NewDjubrivaForm />}
      submitForm={pzv1ObrazacStore.submitDjubrivaForm}
      initialState={DJUBRIVA_INITIAL_STATE}
      dataToChange="djubrivo"
    />
  );
});
