import { Col, FormInstance } from 'antd';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import {
  EMAIL_FIELD_RULE,
  MAX_LENGTH_RULE,
  PHONE_FIELD_RULE,
  REQUIRED_FIELD_RULE,
  REUSABLE_RULE_REQ,
} from 'modules/nriz/nriz.constants';
import React, { useEffect } from 'react';
import styles from './DkoDeoCWizardTab.module.scss';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { dkoObrazacStore } from 'modules/obrasci/store/dko/dko.store';
import { IGetDkoObrazac } from 'modules/obrasci/store/dko/dko.types';
import { FormInput } from 'components/FormInput/FormInput';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { t } from 'i18next';
import { disableDatesBefore, disabledTodayAndFutureDates } from 'utils/formatDate';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

type FormProps = {
  form: FormInstance;
};

export const DkoDeoCWizardTab: React.FC<FormProps> = observer(({ form }) => {
  const { id } = useParams();

  const defaultPrevoznikOtpadaOption: ISifarnikInitial | undefined = dkoObrazacStore.initialState.prevoznik_otpada_id
    ? {
        label: (dkoObrazacStore.initialState as IGetDkoObrazac).prevoznik_otpada.full_company_name,
        value: dkoObrazacStore.initialState.prevoznik_otpada_id,
      }
    : undefined;

  const defaultLokacijaUtovara: ISifarnikInitial | undefined = dkoObrazacStore.initialState.lokacija_utovara_id
    ? {
        label: (dkoObrazacStore.initialState as IGetDkoObrazac).lokacija_utovara.display_name,
        value: dkoObrazacStore.initialState.lokacija_utovara_id,
      }
    : undefined;

  const defaultPreko: ISifarnikInitial | undefined = dkoObrazacStore.initialState.preko_id
    ? {
        label: (dkoObrazacStore.initialState as IGetDkoObrazac).preko.display_name,
        value: dkoObrazacStore.initialState.preko_id,
      }
    : undefined;

  const defaultPreko2: ISifarnikInitial | undefined = dkoObrazacStore.initialState.preko2_id
    ? {
        label: (dkoObrazacStore.initialState as IGetDkoObrazac).preko2.display_name,
        value: dkoObrazacStore.initialState.preko2_id,
      }
    : undefined;

  const defaultPreko3: ISifarnikInitial | undefined = dkoObrazacStore.initialState.preko3_id
    ? {
        label: (dkoObrazacStore.initialState as IGetDkoObrazac).preko3.display_name,
        value: dkoObrazacStore.initialState.preko3_id,
      }
    : undefined;

  const defaultTypeMeansTransportOption: ISifarnikInitial | undefined = dkoObrazacStore.initialState
    .vrsta_prevoznog_sredstva_id
    ? {
        label: (dkoObrazacStore.initialState as IGetDkoObrazac).vrsta_prevoznog_sredstva.type_of_means_of_transport_sr,
        value: dkoObrazacStore.initialState.vrsta_prevoznog_sredstva_id,
      }
    : undefined;

  useEffect(() => {
    if (dkoObrazacStore.bDatumPredaje) {
      form.setFieldValue('datum_preuzimanja_otpada', dayjs(dkoObrazacStore.bDatumPredaje));
      form.setFieldValue('datum_predaje_otpada_deo_c', '');
      form.setFieldValue('datum_preuzimanja_otpada_deo_d', '');
    } else {
      form.setFieldValue('datum_preuzimanja_otpada', '');
      form.setFieldValue('datum_predaje_otpada_deo_c', '');
      form.setFieldValue('datum_preuzimanja_otpada_deo_d', '');
    }
  }, [dkoObrazacStore.bDatumPredaje]);

  useEffect(() => {
    if (dkoObrazacStore.initialState.mesto_id) {
      form.setFieldValue('lokacija_istovara_id', (dkoObrazacStore.initialState as IGetDkoObrazac).mesto.display_name);
    }
  }, []);

  useEffect(() => {
    if (dkoObrazacStore.initialState.adresa_odredista) {
      form.setFieldValue('adresa_istovara', (dkoObrazacStore.initialState as IGetDkoObrazac).adresa_odredista);
    }
  }, []);

  const disabledDatesFunction = disableDatesBefore(dkoObrazacStore.bDatumPredaje);

  const handleDateChange = (e: any) => {
    dkoObrazacStore.setDatumC(e.$d);
  };

  const prekoFields = ['preko_id', 'preko2_id', 'preko3_id'];

  const handlePrekoValidation = () => {
    form.validateFields(prekoFields);
  };
  // todo: prevod
  return (
    <Col span={24} className={styles.wrapper}>
      <h2>{`${t('WIZARD_TABS.DEO_C')} - ${t('OBRASCI.DKO.PODACI_O_PREVOZNIKU_OTPADA')}`}</h2>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('OBRASCI.DKO.PREDUZECE_PREVOZNIK')}
        placeholder={''}
        formName={'prevoznik_otpada_id'}
        fetchOptinsList={dkoObrazacStore.fetchApr}
        labelOptionAccessor="full_company_name"
        disabledAccessor=""
        valueOptionAccessor="id"
        readOnly={false}
        inputName={'prevoznik_otpada_id'}
        defaultOption={defaultPrevoznikOtpadaOption}
        rules={[REQUIRED_FIELD_RULE(true)]}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      />
      <FormInput
        label={t('OBRASCI.DKO.TELEFON_PREVOZNIKA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'telefon_prevoznika'}
        type="text"
        inputName={'telefon_prevoznika'}
        rules={id ? [REQUIRED_FIELD_RULE(true)] : [REQUIRED_FIELD_RULE(true), PHONE_FIELD_RULE]}
      />
      <FormInput
        label={t('OBRASCI.DKO.TELEFAKS_PREVOZNIKA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'telefaks_prevoznika'}
        type="number"
        inputName={'broj_telefaks_prevoznikadozvole'}
        placeholder=""
      />
      <FormInput
        label={t('OBRASCI.DKO.EMAIL_PREVOZNIK')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'email'}
        type="email"
        inputName={'email'}
        placeholder=""
        rules={[REQUIRED_FIELD_RULE(true), EMAIL_FIELD_RULE, MAX_LENGTH_RULE(30)]}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'vrsta_prevoznog_sredstva_id'}
        fetchOptinsList={dkoObrazacStore.fetchTypeMeansTransport}
        labelOptionAccessor="type_of_means_of_transport_sr"
        valueOptionAccessor="id"
        rules={[REQUIRED_FIELD_RULE(dkoObrazacStore.vidPrevoza === 'drumski')]}
        inputName={'vrsta_prevoznog_sredstva_id'}
        label={t('OBRASCI.DKO.VRASTA_PREVOZNOG_SREDSTVA')}
        colon={true}
        defaultOption={defaultTypeMeansTransportOption}
        placeholder={''}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      />
      <FormInput
        label={t('OBRASCI.DKO.REGISTARSKI_BROJ_PREVOZNOG_SREDSTVA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'registarski_broj_prevoznog_sredstva'}
        type="text"
        inputName={'registarski_broj_prevoznog_sredstva'}
        disabled={
          obrasciStore.zakljucanObrazac &&
          ((dkoObrazacStore.initialState as IGetDkoObrazac).odjava_kretanja ||
            (dkoObrazacStore.initialState as IGetDkoObrazac).potvrda_dokumenta ||
            (dkoObrazacStore.initialState as IGetDkoObrazac).admin_odjava_dokumenta)
        }
        placeholder=""
        rules={[
          REQUIRED_FIELD_RULE(dkoObrazacStore.vidPrevoza === 'drumski'),
          // todo: prevesti
          { min: 7, message: 'Ne sme da ima manje od 7 karaktera' },
        ]}
      />

      <h4>{t('OBRASCI.DKO.RUTA_KRETANJA_OTPADA')}</h4>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'lokacija_utovara_id'}
        inputName={'lokacija_utovara_id'}
        label={t('OBRASCI.DKO.LOKACIJA_UTOVAR')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        labelOptionAccessor={'display_name'}
        valueOptionAccessor={'id'}
        fetchOptinsList={dkoObrazacStore.fetchMesto}
        placeholder={''}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultLokacijaUtovara}
      />
      <FormInput
        label={t('OBRASCI.DKO.ADRESA_UTOVAR')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'adresa_utovara'}
        inputName={'adresa_utovara'}
        placeholder=""
        rules={[REQUIRED_FIELD_RULE(form.getFieldValue('vid_prevoza') === 'drumski')]}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'preko_id'}
        inputName={'preko_id'}
        label={t('OBRASCI.DKO.PREKO')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        labelOptionAccessor={'display_name'}
        valueOptionAccessor={'id'}
        fetchOptinsList={dkoObrazacStore.fetchMesto}
        placeholder={''}
        onChange={handlePrekoValidation}
        rules={[
          REUSABLE_RULE_REQ(true, t('OBRASCI.DKO.PREKO_REQUIRED')),
          {
            validator(_, value) {
              const prekoValues = prekoFields.map(field => form.getFieldValue(field));
              const otherPrekoValues = [prekoValues[1], prekoValues[2]];
              const idMatch = otherPrekoValues.some((element: string) => element === value);
              const editIdCheck = dkoObrazacStore.initialState.preko_id === value;
              if (idMatch && !editIdCheck) return Promise.reject(new Error(t('OBRASCI.DKO.PREKO_EXISTS')));
              return Promise.resolve();
            },
          },
        ]}
        defaultOption={defaultPreko}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'preko2_id'}
        inputName={'preko2_id'}
        label={t('OBRASCI.DKO.PREKO')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        labelOptionAccessor={'display_name'}
        valueOptionAccessor={'id'}
        rules={[
          {
            validator(_, value) {
              const prekoValues = prekoFields.map(field => form.getFieldValue(field));
              const otherPrekoValues = [prekoValues[0], prekoValues[2]];
              if (prekoValues.every(value => value === ''))
                return Promise.reject(new Error(t('OBRASCI.DKO.PREKO_REQUIRED')));
              const idMatch = otherPrekoValues.some((element: string) => element === value);
              const editIdCheck = dkoObrazacStore.initialState.preko2_id === value;
              if (idMatch && !editIdCheck) return Promise.reject(new Error(t('OBRASCI.DKO.PREKO_EXISTS')));
              return Promise.resolve();
            },
          },
        ]}
        fetchOptinsList={dkoObrazacStore.fetchMesto}
        placeholder={''}
        onChange={handlePrekoValidation}
        defaultOption={defaultPreko2}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'preko3_id'}
        inputName={'preko3_id'}
        label={t('OBRASCI.DKO.PREKO')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        labelOptionAccessor={'display_name'}
        valueOptionAccessor={'id'}
        rules={[
          {
            validator(_, value) {
              const prekoValues = prekoFields.map(field => form.getFieldValue(field));
              const otherPrekoValues = [prekoValues[0], prekoValues[1]];
              if (prekoValues.every(value => value === ''))
                return Promise.reject(new Error(t('OBRASCI.DKO.PREKO_REQUIRED')));
              const idMatch = otherPrekoValues.some((element: string) => element === value);
              const editIdCheck = dkoObrazacStore.initialState.preko3_id === value;
              if (idMatch && !editIdCheck) return Promise.reject(new Error(t('OBRASCI.DKO.PREKO_EXISTS')));
              return Promise.resolve();
            },
          },
        ]}
        fetchOptinsList={dkoObrazacStore.fetchMesto}
        placeholder={''}
        onChange={handlePrekoValidation}
        defaultOption={defaultPreko3}
      />
      <FormInput
        name={'lokacija_istovara_id'}
        inputName={'lokacija_istovara_id'}
        disabled={true}
        label={t('OBRASCI.DKO.LOKACIJA_ISTOVARA')}
        rules={[REUSABLE_RULE_REQ(true, t('OBRASCI.DKO.ODREDISTE_REQUIRED'))]}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      />
      <FormInput
        name={'adresa_istovara'}
        inputName={'adresa_istovara'}
        disabled={true}
        label={t('OBRASCI.DKO.ADRESA_ISTOVARA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      />

      <h4>{t('OBRASCI.DKO.DOZVOLA_ZA_UPRAVLJANJE')}</h4>
      <FormInput
        label={t('OBRASCI.DKO.BROJ_DOZVOLE')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'broj_dozvole_deo_c'}
        type="text"
        inputName={'broj_dozvole_deo_c'}
        placeholder=""
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormDatePicker
        name="datum_izdavanja_dozvole_deo_c"
        label={t('OBRASCI.GIO2.DATUM_IZDAVANJA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        format="DD-MM-YYYY"
        picker={'date'}
        disabledDate={disabledTodayAndFutureDates}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormDatePicker
        name="datum_preuzimanja_otpada"
        label={t('OBRASCI.DKO.DATUM_PREUZIMANJA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        format="DD-MM-YYYY"
        picker={'date'}
        disabled
      />

      <h4>{t('OBRASCI.DKO.ODGOVORNO_LICE_PREVOZNIKA_PREUZEO')}</h4>
      <FormInput
        label={t('OBRASCI.DKO.IME_PREZIME')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'ime_prezime_prevoznika_preuzet'}
        inputName={'ime_prezime_prevoznika_preuzet'}
        placeholder=""
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormInput
        label={t('OBRASCI.DKO.BROJ_MOBILNOG_TELEFONA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'broj_mobilnog_telefona_preuzet'}
        rules={id ? [REQUIRED_FIELD_RULE(true)] : [REQUIRED_FIELD_RULE(true), PHONE_FIELD_RULE]}
        type="text"
        inputName={'broj_mobilnog_telefona_preuzet'}
        placeholder=""
      />
      <FormDatePicker
        name="datum_predaje_otpada_deo_c"
        label={t('OBRASCI.DKO.DATUM_PREDAJE_OTPADA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        format="DD-MM-YYYY"
        disabled={obrasciStore.zakljucanObrazac || !dkoObrazacStore.bDatumPredaje}
        picker={'date'}
        onChange={handleDateChange}
        disabledDate={disabledDatesFunction}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <h4>{t('OBRASCI.DKO.ODGOVORNO_LICE_PREVOZNIKA_PREDAO')}</h4>
      <FormInput
        label={t('OBRASCI.DKO.IME_PREZIME')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'ime_prezime_prevoznika_predat'}
        inputName={'ime_prezime_prevoznika_predat'}
        placeholder=""
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormInput
        label={t('OBRASCI.DKO.BROJ_MOBILNOG_TELEFONA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'broj_mobilnog_telefona_predat'}
        rules={id ? [REQUIRED_FIELD_RULE(true)] : [REQUIRED_FIELD_RULE(true), PHONE_FIELD_RULE]}
        type="text"
        inputName={'broj_mobilnog_telefona_predat'}
        placeholder=""
      />
    </Col>
  );
});
