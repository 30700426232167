import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styles from './Obrazac4OpsteWizardTab.module.scss';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { obrazac4Store } from 'modules/obrasci/store/obrazac4/obrazac4.store';
import { IObrazac4Response } from 'modules/obrasci/store/obrazac4/obrazac4.types';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { Col } from 'antd';

export const Obrazac4OpsteWizardTab: React.FC<ICrudTypeModalProps> = observer(() => {
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    if (
      obrasciStore.opste.godina &&
      obrazac4Store.izvor_zagadjenja_tla_id !== '' &&
      obrasciStore.opste.preduzece_id !== '' &&
      obrasciStore.opste.postrojenje_id !== ''
    ) {
      obrasciStore.setDisabledTabButton(false);
    }
  }, [obrazac4Store.izvor_zagadjenja_tla_id, obrasciStore.opste]);

  const defaultSourceOptions = (obrazac4Store.initialState as IObrazac4Response).izvor_zagadjenja_tla_id
    ? {
        label: (obrazac4Store.initialState as IObrazac4Response).izvor_zagadjenja_tla.naziv_lokacije,
        value: (obrazac4Store.initialState as IObrazac4Response).izvor_zagadjenja_tla_id,
      }
    : undefined;

  return (
    <Col span={12} className={styles.wrapper}>
      <OpsteGodinaPreduzecePostrojenje withPostrojenje={true} store={obrazac4Store} />

      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('POLLUTION.SOIL.TITLE')}
        onChange={value => {
          obrazac4Store.handleChange('izvor_zagadjenja_tla_id', value);
        }}
        wait={!!id || obrasciStore.opste.postrojenje_id === ''}
        placeholder={t('COMPANY.PLACEHOLDER.ENTER_NAME')}
        formName={'izvor_zagadjenja_tla_id'}
        fetchOptinsList={obrazac4Store.fetchSourceSoil}
        labelOptionAccessor="naziv_lokacije"
        disabledAccessor="aktivan"
        valueOptionAccessor="id"
        readOnly={false}
        defaultOption={defaultSourceOptions}
        rules={[REQUIRED_FIELD_RULE(true)]}
        disabled={
          obrasciStore.opste.godina === '' ||
          obrasciStore.opste.preduzece_id === '' ||
          obrasciStore.opste.postrojenje_id === '' ||
          obrazac4Store.izvor_zagadjenja_tla_id !== '' ||
          !obrasciStore.disabled_tab_button
        }
      />
    </Col>
  );
});
