// import { Select } from 'antd';
import { Col, Form, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NewSurveyForm.module.scss';

import { FormInput } from 'components/FormInput/FormInput';
import { REQUIRED_FIELD_RULE, SURVEY_VERIFICATION_CODE_RULE } from 'modules/nriz/nriz.constants';
import { facilityStore } from 'modules/facility/facility.store';

export const NewSurveyForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const handleChange = (_: any, option: any) => {
    facilityStore.changeSurvey(option.name, option.value);
  };

  const surveyOptions = [
    {
      key: 1,
      type: 'korak_1_pitanje_1',
      label: t('SURVEY.STEP_1_QUESTION_1'),
    },
    {
      key: 2,
      type: 'korak_1_pitanje_2',
      label: t('SURVEY.STEP_1_QUESTION_2'),
    },
    {
      key: 3,
      type: 'korak_1_pitanje_3',
      label: t('SURVEY.STEP_1_QUESTION_3'),
    },
    {
      key: 4,
      type: 'korak_1_pitanje_4',
      label: t('SURVEY.STEP_1_QUESTION_4'),
    },
    {
      key: 5,
      type: 'korak_1_pitanje_5',
      label: t('SURVEY.STEP_1_QUESTION_5'),
    },
    {
      key: 6,
      type: 'korak_1_pitanje_6',
      label: t('SURVEY.STEP_1_QUESTION_6'),
    },
    {
      key: 7,
      type: 'korak_2_pitanje_1',
      label: t('SURVEY.STEP_2_QUESTION_1'),
      categories: t('SURVEY.CATEGORIES_1'),
    },
    {
      key: 8,
      type: 'korak_2_pitanje_2',
      label: t('SURVEY.STEP_2_QUESTION_2'),
      categories: t('SURVEY.CATEGORIES_2'),
    },
    {
      key: 9,
      type: 'korak_3_pitanje_1',
      label: t('SURVEY.STEP_3_QUESTION_1'),
    },
    {
      key: 10,
      type: 'korak_4_pitanje_1',
      label: t('SURVEY.STEP_4_QUESTION_1'),
    },
  ];

  return (
    <>
      <div
        className={`${styles.border_wrapper} ${
          !facilityStore.getSurveyValidation ? styles.error_border : styles.neutral_border
        } `}
      >
        <Row justify={'end'} align={'middle'} className={styles.errorMsg}>
          <span>{!facilityStore.getSurveyValidation && 'Jedan odgovor mora biti DA'}</span>
        </Row>
        {surveyOptions.map(item => {
          return (
            <Row key={item.key} justify={'space-between'} align={'middle'}>
              <Col span={20}>
                <div>
                  <span>{item.key}.</span>
                  <span>{item.label}</span>
                  {item.categories && <div className={styles.categories}>{item.categories}</div>}
                </div>
              </Col>
              <Col span={3}>
                <Form.Item
                  name={item.type}
                  className={styles.form_item}
                  colon={false}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: `${t('VALIDATION.IS_REQUIRED')}`,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    className={styles.select}
                    onChange={(_, option) => handleChange(_, option)}
                    aria-required={true}
                  >
                    <Select.Option value="da" name={item.type}>
                      {t('YES')}
                    </Select.Option>
                    <Select.Option value="ne" name={item.type}>
                      {t('NO')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          );
        })}
      </div>

      <FormInput
        name="verifikacioni_kod"
        type="text"
        placeholder={t('FACILITY.PLACEHOLDER.ENTER_VERIFICATION_CODE')}
        label={t('FACILITY.VERIFICATION_CODE')}
        inputName={'verifikacioni_kod'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(true), SURVEY_VERIFICATION_CODE_RULE]}

        // className={styles.removeArrow}
      />
    </>
  );
});
