import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IGetNaselja, INaselja } from 'modules/obrasci/store/kom1/kom1.types';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const TableNaselja: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetNaselja> = [
    {
      title: `${t('OBRASCI.DEP_2.SETTLEMENT')}`,
      width: '150px',
      dataIndex: ['mesto', 'display_name'],
      key: 'display_name',
    },
    {
      title: `${t('OBRASCI.KOM_1.TOTAL_NUMBER_OF_HOUSEHOLDS_IN_SETTLEMENT')}`,
      width: '150px',
      dataIndex: 'ukupan_broj_domacinstava_u_naselju',
      key: 'ukupan_broj_domacinstava_u_naselju',
    },
    {
      title: `${t('OBRASCI.KOM_1.NUMBER_OF_HOUSEHOLDS_COVERED_BY_WASTE_COLLECTION')}`,
      dataIndex: 'broj_domacinstava_obuhvacenih_prikupljanjem_otpada',
      key: 'broj_domacinstava_obuhvacenih_prikupljanjem_otpada',
      width: '200px',
    },
    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (_, record: INaselja) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-naselja'}
            onClick={() => {
              kom1ObrazacStore.handleChange('naselja_form', record);
              modalStore.changeModalName('naselja-modal');
            }}
          />
        );
      },
    },
  ];
  // todo: check if backend return whole array or we have to do it with pagination
  return (
    <TableData name="naselje-kom1" columns={baseColumns} dataSource={[...kom1ObrazacStore.initialState.naselja]} />
  );
});
