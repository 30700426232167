import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { Aao2KolicinaAmbalazeTable } from './components/Aao2KolicinaAmbalazeTable';

export const Aao2KolicinaAmbalazeWizardTab = () => {
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'aao2-kolicina-ambalaze-form'} modalName="aao2-kolicina-ambalaze-modal" />}
    >
      <Aao2KolicinaAmbalazeTable />
    </InsideContentWrapper>
  );
};
