import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { FormSearchScrollProps, IPagination } from 'typescript';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

import styles from './FormSearchInfiniteScroll.module.scss';
import { map4select } from 'utils/map4select';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { isEmpty } from 'lodash';
import { DefaultOptionType } from 'antd/es/select';

export const FormSearchInfiniteScroll: React.FC<FormSearchScrollProps> = props => {
  const [options, setOptions] = useState<any>([]);
  const [defaultOption, setDefaultOption] = useState<DefaultOptionType | undefined>(undefined);
  // const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<IPagination>(paginationInitialState);
  const [search, setSearch] = useState<string>('');
  const [allowClear, setAllowClear] = useState<boolean>(false);

  const { t } = useTranslation();

  const form = Form.useFormInstance();

  const currentOptions = !options.length ? [defaultOption] : options;

  const loadMoreOptions = useDebouncedCallback(async (search?: string) => {
    if (props.wait) return;

    // setLoading(true);

    setDefaultOption(undefined);

    const response = await props.fetchOptinsList(pagination, search);

    if (isEmpty(response)) {
      // setLoading(false);
      return;
    }

    // proveriti da li su izmene na ovome ok
    const selectOptions = props.optionsMaker
      ? props.optionsMaker(response.items)
      : map4select({
          valueAccessor: props.valueOptionAccessor,
          labelAccessor: props.labelOptionAccessor,
          sourceArray: response.items,
          disabledAccessor: props?.disabledAccessor,
        });

    setOptions([...options, ...selectOptions]);

    setPagination({
      page: response.page === response.total ? response.total : response.page + 1,
      size: response.size,
      total: response.total,
      pages: response.pages,
    });
    // setLoading(false);
  }, 500);

  const handleSearch = (value: string) => {
    if (props.showSearch) {
      setSearch(value);
      setPagination(paginationInitialState);
      setOptions([]);
      loadMoreOptions(value);
    }
  };

  // if the component has reset option prop, wrap its default option with useMemo to prevent unintentional rerenders

  useEffect(() => {
    const fieldValue = form.getFieldValue(props.formName);

    if (props.resetOption && !fieldValue) {
      setDefaultOption(undefined);
      setPagination(paginationInitialState);
      setOptions([]);
    }
  }, [props.resetOption]);

  useEffect(() => {
    setDefaultOption(props.defaultOption);
  }, [props.defaultOption]);

  useEffect(() => {
    if (form.getFieldValue(props.formName)) setAllowClear(true);
    else setAllowClear(false);
  }, [Form.useWatch(props.formName)]);

  const handleScroll = (e: any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const heightDifference = clientHeight - (scrollHeight - scrollTop);

    if (Math.abs(heightDifference) < 10) {
      if (pagination.page > pagination.pages) return;
      if (search) loadMoreOptions(search);
      else loadMoreOptions();
    }
  };

  const handleLoadDataOnFocus = () => {
    // setLoading(true);
    if (!props.readOnly && options.length === 0) {
      loadMoreOptions();
    }
    // else setLoading(false);
  };

  return (
    <Form.Item
      name={props.formName}
      label={props.label}
      rules={props.rules}
      labelCol={props.labelCol}
      wrapperCol={props.wrapperCol}
      colon={props.colon}
      validateTrigger={props.validateTrigger ? props.validateTrigger : 'onChange'}
    >
      <Select
        showSearch={props.showSearch}
        placeholder={props.placeholder}
        onPopupScroll={handleScroll}
        onSearch={handleSearch}
        // loading={loading}
        notFoundContent={props.notFoundContent ? props.notFoundContent : t('NO_OPTIONS_FOUND')}
        filterOption={false}
        className={props.readOnly ? styles.readOnly : ''}
        onChange={props.onChange}
        disabled={props.disabled}
        onClear={() => {
          setOptions([]);
          setPagination(paginationInitialState);
        }}
        onDropdownVisibleChange={open => {
          if (!options.length && open) {
            setPagination(paginationInitialState);
            handleLoadDataOnFocus();
          }
        }}
        onFocus={() => handleLoadDataOnFocus()}
        allowClear={allowClear}
      >
        {currentOptions.map((option: any, index: any) =>
          option ? (
            <Select.Option key={index} value={option.value} disabled={option.disabled} item={option.item}>
              {option.label}
            </Select.Option>
          ) : undefined
        )}
      </Select>
    </Form.Item>
  );
};
