import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import style from './Aao1Dozvola.module.scss';

import { observer } from 'mobx-react-lite';
import { FormInput } from 'components/FormInput/FormInput';
import { disabledTodayAndFutureDates } from 'utils/formatDate';
import { aao1Store } from 'modules/obrasci/store/aao1/aao1.store';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { t } from 'i18next';
import { Col } from 'antd';

export const Aao1Dozvola = observer(() => {
  return (
    <Col span={12} className={style.permitDiv}>
      <FormInput
        name={'broj_dozvole'}
        inputName={'broj_dozvole'}
        label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.PERMIT_NUMBER')}
        type="text"
        labelCol={{ span: 12 }}
        placeholder={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.PLACEHOLDERS.PERMIT_NUMBER')}
        rules={[REQUIRED_FIELD_RULE(aao1Store.isCompanyWithSelfManagment)]}
        disabled={!aao1Store.isCompanyWithSelfManagment}
      />
      <FormDatePicker
        name="datum_izdavanja"
        label="Datum izdavanja"
        labelCol={{ span: 12 }}
        format="DD-MM-YYYY"
        picker={'date'}
        placeholder={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.PLACEHOLDERS.DATE_OF_ISSUE')}
        rules={[REQUIRED_FIELD_RULE(aao1Store.isCompanyWithSelfManagment)]}
        disabledDate={disabledTodayAndFutureDates}
        disabled={!aao1Store.isCompanyWithSelfManagment}
      />
    </Col>
  );
});
