import { sifarniciRepo } from './sifarnici.repo';
import { formStore } from 'store';
import { IDateArrayPropsData, ISifarniciFormData, ISifarniciTypeOfForm, ISubmitFormSifarnik } from './sifarnici.types';
import { SIFARNICI_TYPE_TABLE_COLUMNS, SIFARNIK_MODAL_DATA } from './sifarnici.constants.tsx';
import dayjs from 'dayjs';
import { omit } from 'lodash';

class SifaniciService {
  fetchSingleSifarnik<Sifarnik = any>(sifarnik_type: string, id: string): Promise<Sifarnik> {
    return sifarniciRepo.fetchSingleSifarnik(sifarnik_type, id);
  }
  fetchSingleSifarnikList = <SifarnikResponse = any>(type: string, filters: any) => {
    return sifarniciRepo.fetchSingleSifarnikList<SifarnikResponse>(type, filters);
  };

  postSingleSifarnik = <SifarniciPayload = any>(type: string, payload: SifarniciPayload) => {
    return sifarniciRepo.postSingleSifarnik<SifarniciPayload>(type, payload);
  };

  putSingleSifarnik = (type: string, payload: any) => {
    return sifarniciRepo.putSingleSifarnik(type, payload);
  };

  deleteSingleSifarnik = <SifarniciPayload = any>(type: string, id: string) => {
    return sifarniciRepo.deleteSingleSifarnik<SifarniciPayload>(type, id);
  };

  fetchUnitCodeByPollutant = (payload: any) => {
    return sifarniciRepo.fetchUnitCodeByPollutant(payload);
  };

  submitFormSifarnik = (props: ISubmitFormSifarnik) => {
    if (formStore.getCrudButtonType === 'submit') {
      return props.postForm(props.state);
    }

    if (formStore.getCrudButtonType === 'change') {
      return props.putForm(props.state);
    }
    if (formStore.getCrudButtonType === 'delete') {
      return props.deleteForm(props.state.id);
    }
  };

  tableColumnsSifarniciFinder = (type: string) => {
    return SIFARNICI_TYPE_TABLE_COLUMNS[type];
  };
  filtersSifarniciFinder = (type: string): ISifarniciFormData => {
    return SIFARNIK_MODAL_DATA[type].formData;
  };

  reverseKeyValue = (obj: any) => {
    const reversedObj = {};
    for (const key in obj) {
      const value = obj[key];
      (reversedObj as any)[value] = key;
    }

    return reversedObj;
  };

  fetchMakeDateAndYearObject = (dateArray: IDateArrayPropsData[], obj: any) => {
    let newObj = {};
    dateArray.map((item: IDateArrayPropsData) => {
      newObj = {
        ...newObj,
        [item.name]: !obj[item.name] || obj[item.name] === 'NULL' ? '' : dayjs(obj[item.name]),
      };
    });
    return { ...obj, ...newObj };
  };

  makeDateAndYearString = (dateArray: IDateArrayPropsData[], obj: any) => {
    let newObj = {};
    dateArray.map((item: IDateArrayPropsData) => {
      if (item.type === 'year') {
        newObj = {
          ...newObj,
          [item.name]: !obj[item.name] || obj[item.name] === 'NULL' ? '' : dayjs(obj[item.name]).format('YYYY'),
        };
      }
    });

    return { ...obj, ...newObj };
  };

  removeRules = (arrayOptions: ISifarniciTypeOfForm[]) => {
    return arrayOptions.map((item: ISifarniciTypeOfForm) => omit(item, ['rules']));
  };
}

export const sifaniciService = new SifaniciService();
