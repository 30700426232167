import { ColumnsType } from 'antd/es/table';
import { IGetTypeMeansTransport, IPostTypeMeansTransport } from './typeMeansTransport.types';
import { THE_ORDER_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import { THE_ORDER_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { MIN_MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const TYPE_MEANS_TRANSPORT_INITIAL_STATE: IPostTypeMeansTransport = {
  the_order: '',
  type_of_means_of_transport: '',
  type_of_means_of_transport_sr: '',
  is_active: true,
};

export const TYPE_MEANS_TRANSPORT_TABLE_DATA: ColumnsType<IGetTypeMeansTransport> = [
  THE_ORDER_TABLE,
  {
    title: 'Vrsta prevoznog sredstva',
    width: 100,
    dataIndex: 'type_of_means_of_transport',
    key: 'type_of_means_of_transport',
  },
  {
    title: 'Vrsta prevoznog sredstva (srp.)',
    width: 100,
    dataIndex: 'type_of_means_of_transport_sr',
    key: 'type_of_means_of_transport_sr',
  },
];

export const TYPE_MEANS_TRANSPORT_FORM_OPTIONS = (_: FormInstance<IGetTypeMeansTransport>) => {
  return [
    THE_ORDER_FORM,
    {
      type_of_form: 'input',
      name: 'type_of_means_of_transport',
      label: 'Vrsta prevoznog sredstva',
      inputName: 'type_of_means_of_transport',
      rules: [MIN_MAX_LENGTH_RULE(3, 100)],
      // placeholder: t('COMPANY.PLACEHOLDER.ENTER_SHORT_NAME'),
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'type_of_means_of_transport_sr',
      label: 'Vrsta prevoznog sredstva (srp.)',
      inputName: 'type_of_means_of_transport_sr',
      rules: [MIN_MAX_LENGTH_RULE(3, 100)],
      // placeholder: t('COMPANY.PLACEHOLDER.ENTER_SHORT_NAME'),
    } as InputSifarniciFormProps,
  ];
};
