import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { authStore } from 'store';
import { routes } from './router.constants';
import { observer } from 'mobx-react-lite';
import { flattenChildren } from 'utils/flattenChildren';
import RouteGuard from './RouteGuard';
import SidebarAnt from 'components/SidebarAnt/SidebarAnt';
import { UserRoles } from 'modules/user/user.types';
import { companyStore } from 'modules/company/company.store';
import { IGetCompany } from 'modules/company/company.types';

export const RoutesList: React.FC = observer(() => {
  const { pathname } = useLocation();

  const currentRole = authStore.getUserRole;

  if (!currentRole && !pathname.includes('user-registration')) authStore.keycloak.login();

  const flatRoutes = flattenChildren(routes);

  const REDIRECT_ROUTES: { [key: string | UserRoles]: string } = {
    [UserRoles.SystemAdmin]: '/companies',
    [UserRoles.CompanyOwner]: `/company/${(companyStore.company as IGetCompany)?.id}`,
    [UserRoles.CompanyUser]: `/company/${(companyStore.company as IGetCompany)?.id}`,
  };

  return (
    <>
      {!(pathname.includes('/obrazac/') || pathname.includes('user-start-page')) && <SidebarAnt />}
      <Routes>
        {flatRoutes.map(route => {
          if (!route.children)
            return (
              <Route
                path={route.path}
                element={<RouteGuard allowedRoles={route.allowedRoles}>{route.element}</RouteGuard>}
                key={route.key}
              />
            );
        })}
        {currentRole && (
          <Route
            path="*"
            element={
              <RouteGuard allowedRoles={[currentRole]}>
                <Navigate to={REDIRECT_ROUTES[currentRole]} />
              </RouteGuard>
            }
          />
        )}
      </Routes>
    </>
  );
});
