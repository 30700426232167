import {
  IPostGio4Country,
  IPostGio4Dozvole,
  IPostGio4IzvozOtpada,
  IPostGio4Obrazac,
  IPostGio4RazrediEeOtpada,
} from './gio4.types';

export const GIO_4_INITIAL_STATE: IPostGio4Obrazac = {
  godina: '',
  preduzece_id: '',
  postrojenje_id: '',
  napomena: '',
  dozvole: [],
  izvoz_otpada: [],
  izjava_odgovornosti: false,
};

export const GIO_4_DOZVOLE_INITIAL_STATE: IPostGio4Dozvole = {
  broj_dozvole: '',
  datum_izdavanja: '',
  datum_isteka: '',
  sakupljanje: false,
  transport: false,
  skladistenje: false,
  tretman: false,
  odlaganje: false,
};

export const GIO_4_IZVOZ_OTPADA_INITIAL_STATE: IPostGio4IzvozOtpada = {
  indeksni_broj_otpada_id: '',
  kolicina_izvezenog_otpada: null,
  broj_dozvole_za_izvoz: '',
  zemlja_izvoza_id: '',
  naziv_i_adresa_postrojenja_za_odlaganje_ili_tretman_otpada: '',
  izvoz_otpada_y_lista_id: '',
  izvoz_otpada_y_lista2_id: '',
  izvoz_otpada_y_lista3_id: '',
  izvoz_otpada_c_lista_id: '',
  izvoz_otpada_n_lista_id: '',
  izvoz_otpada_n_lista2_id: '',
  izvoz_otpada_n_lista3_id: '',
  ia_lista_id: '',
  iib_lista_id: '',
  ponovno_iskoriscenje_r_oznaka_id: '',
  odlaganje_d_oznaka_id: '',
  zemlje_tranzita: [],
  razredi_ee_otpada: [],
};

export const GIO_4_RAZREDI_EE_OTPADA_INITIAL_STATE: IPostGio4RazrediEeOtpada = {
  kolicina: null,
  razred_ee_otpada_id: '',
};

export const GIO_4_ZEMLJA_TRANZITA_INITIAL_STATE: IPostGio4Country = {
  zemlja_tranzita_id: '',
};
