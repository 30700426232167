import { obrasciRepo } from 'modules/obrasci/obrasci.repo';
import {
  NACIN_POSTUPANJA_OPTIONS,
  PREUZET_OD_2020_OPTIONS,
  deo6KalkulacijaMapConfig,
  otpadListsKeys,
} from './deo6.constants';
import {
  CalculateSkladisteArgs,
  Deo6KalkulacijaMaps,
  Deo6KalkulacijaOtpadValues,
  Deo6KalkulacijaParams,
  Deo6KalkulacijaRazredValues,
  Deo6KalkulacijaResponse,
  NacinPostupanjaEnum,
  PreuzetOdEnum,
} from './deo6.types';
import { toJS } from 'mobx';
import { findValidKeyFromKeysArray } from 'utils/findValidKeyFromKeysArray';

export const formCleanPredat = (nacinPostupanja: string) => {
  let arrayToClean: string[] = [];
  const izvezenoVanZemlje = ['zemlja_izvoza_id', 'broj_dozvole_za_izvoz'];
  const odlozenoNaSopstvenojLokaciji = ['d_oznaka_id', 'broj_dozvole_za_odlaganje'];
  const predatoDrugomOperateru = ['preduzece_id', 'naziv_preduzeca', 'operater_je_id', 'broj_dozvole'];
  const predatoDrugomPostrojenju = ['postrojenje_id'];
  const ukljucenoUSopstveniProces = ['nove_vrste_otpada', 'r_oznaka_id', 'broj_dozvole_ponovno_iskoriscenje'];
  const razlogRazlike = ['razlog_postojanja_razlike_zbira_kolicina_novih_vrsti_i_kolicine_predatog_otpada'];

  if (nacinPostupanja === NacinPostupanjaEnum.IZVEZENO) {
    arrayToClean = [
      ...odlozenoNaSopstvenojLokaciji,
      ...predatoDrugomOperateru,
      ...predatoDrugomPostrojenju,
      ...ukljucenoUSopstveniProces,
      ...razlogRazlike,
    ];
  }
  if (nacinPostupanja === NacinPostupanjaEnum.SOPSTVENI_LOKACIJA) {
    arrayToClean = [
      ...izvezenoVanZemlje,
      ...predatoDrugomOperateru,
      ...predatoDrugomPostrojenju,
      ...ukljucenoUSopstveniProces,
      ...razlogRazlike,
    ];
  }
  if (nacinPostupanja === NacinPostupanjaEnum.POSTROJENJE) {
    arrayToClean = [
      ...izvezenoVanZemlje,
      ...predatoDrugomOperateru,
      ...odlozenoNaSopstvenojLokaciji,
      ...ukljucenoUSopstveniProces,
      ...razlogRazlike,
    ];
  }
  if (nacinPostupanja === NacinPostupanjaEnum.PREDATO_DRUGOM_OPERATERU) {
    arrayToClean = [
      ...izvezenoVanZemlje,
      ...predatoDrugomPostrojenju,
      ...odlozenoNaSopstvenojLokaciji,
      ...ukljucenoUSopstveniProces,
      ...razlogRazlike,
    ];
  }
  if (nacinPostupanja === NacinPostupanjaEnum.SPOSTVENI_PROCES_PONOVNOG_ISKORISCENJA) {
    arrayToClean = [
      ...izvezenoVanZemlje,
      ...predatoDrugomPostrojenju,
      ...odlozenoNaSopstvenojLokaciji,
      ...predatoDrugomOperateru,
    ];
  }
  return arrayToClean;
};

export const formCleanPreuzet = (preuzetOd: string) => {
  let arrayToClean: any[] = [];
  const pravongLicaFormItems = [
    'preduzece_id',
    'proizvodjac_otpada',
    'sakupljac',
    'operater_na_deponiji',
    'operater_tretmana_otpada',
    'skladistar',
    'uvoznik_otpada',
    'drugi_vlasnik',
    'naziv_preduzeca',
  ];

  const drugogPostrojenja = ['postrojenje_id'];
  const sopstvenogUvoza = ['zemlja_izvoza_id'];

  if (preuzetOd === PreuzetOdEnum.FIZICKO_LICE || preuzetOd === PreuzetOdEnum.POSTROJENJE) {
    arrayToClean = [...pravongLicaFormItems, ...drugogPostrojenja, ...sopstvenogUvoza];
  }
  if (preuzetOd === PreuzetOdEnum.PRAVNO_LICE) {
    arrayToClean = [...drugogPostrojenja, ...sopstvenogUvoza];
  }
  if (preuzetOd === PreuzetOdEnum.SOPSTVENO_POSTROJENJE) {
    arrayToClean = [...pravongLicaFormItems, ...sopstvenogUvoza];
  }

  if (preuzetOd === PreuzetOdEnum.SOPSTVENI_UVOZ) {
    arrayToClean = [...pravongLicaFormItems, ...drugogPostrojenja];
  }
  return arrayToClean;
};

export const exportPreuzetOdText = (value: string) => {
  const foundObject = PREUZET_OD_2020_OPTIONS.find(obj => obj.value === value);
  return foundObject?.label;
  // const preuzet = PREUZET_OD_2020_OPTIONS.map(item => {item.value === type});
};

export const exportNacinPostupanja = (value: string) => {
  const foundObject = NACIN_POSTUPANJA_OPTIONS.find(obj => obj.value === value);
  return foundObject?.label;
  // const preuzet = PREUZET_OD_2020_OPTIONS.map(item => {item.value === type});
};
class Deo6Service {
  mapDeo6KalkulacijaResponse = (response: Deo6KalkulacijaResponse): Deo6KalkulacijaMaps => {
    const otpadMap = new Map<string, Deo6KalkulacijaOtpadValues>();
    const razredMap = new Map<string, Deo6KalkulacijaRazredValues>();
    response.indeksni_brojevi?.forEach(otpad => otpadMap.set(otpad.indeksni_broj_otpada_id, otpad));
    response.razredi_ee_otpada?.forEach(razred => razredMap.set(razred.razred_ee_otpada_id, razred));
    return { indeksni_brojevi: otpadMap, razredi_ee_otpada: razredMap };
  };

  getDeo6SkladisteInfo = (payload: Deo6KalkulacijaParams) => obrasciRepo.getDeo6SkladisteInfo(payload);

  findKolicinaByAccessor = (item: any, accessorArray: string[]) => {
    const validAccessor =
      accessorArray.map(accessor => {
        if (accessor in item) return accessor;
      })[0] ?? accessorArray[0];
    return item[validAccessor];
  };

  calculateKolicina = (operation: `+` | `-` = `+`, kolicina: number, previousKolicina?: number) => {
    return eval(previousKolicina ? `${previousKolicina} ${operation} ${kolicina}` : `${operation} ${kolicina}`);
  };

  generateMapFromOtpadList = (
    lista: any[],
    otpadMap: Map<string, any>,
    razredMap: Map<string, any>,
    mapAccessor: 'otpad' | 'razred' = 'otpad',
    operation: '+' | '-' = '+'
  ) => {
    if (!lista.length) return;
    const currentMap = mapAccessor === 'otpad' ? otpadMap : razredMap;

    const { idAccessor, objectAccessor, kolicinaAccessorArray } = deo6KalkulacijaMapConfig[mapAccessor];

    const innerOtpadAcessor =
      mapAccessor === 'otpad' ? deo6KalkulacijaMapConfig[mapAccessor].innerOtpadAcessor : undefined;

    lista.forEach(listItem => {
      const itemKolicina = findValidKeyFromKeysArray(listItem, kolicinaAccessorArray);

      const existingItem = currentMap.get(listItem[idAccessor]);

      const kolicina = this.calculateKolicina(operation, itemKolicina, existingItem?.kolicina);

      currentMap.set(listItem[idAccessor], {
        [idAccessor]: listItem[idAccessor],
        [objectAccessor]: listItem[objectAccessor],
        kolicina,
      });

      if ('razred' in listItem)
        this.generateMapFromOtpadList(listItem.razred, otpadMap, razredMap, 'razred', operation);

      if (innerOtpadAcessor && listItem[innerOtpadAcessor]) {
        this.generateMapFromOtpadList(listItem[innerOtpadAcessor], otpadMap, razredMap, 'otpad', '+');
      }
    });
  };

  compareKolicinaMaps = (
    skladisteMap: Map<string, Deo6KalkulacijaOtpadValues | Deo6KalkulacijaRazredValues>,
    calculatedMap: Map<string, Deo6KalkulacijaOtpadValues | Deo6KalkulacijaRazredValues>,
    operation: '+' | '-' = '+'
  ) => {
    calculatedMap.forEach((item, key) => {
      const matchingItemInSkladiste = skladisteMap.get(key);
      if (matchingItemInSkladiste) {
        const kolicina = eval(`${matchingItemInSkladiste.kolicina} ${operation} ${item.kolicina}`);
        skladisteMap.set(key, { ...matchingItemInSkladiste, kolicina });
      } else {
        skladisteMap.set(key, item);
      }
    });
  };

  calculateSkladiste = ({ obrazac, skladiste, operation }: CalculateSkladisteArgs): Deo6KalkulacijaMaps => {
    const { indeksni_brojevi, razredi_ee_otpada } = toJS(skladiste);

    if (!obrazac)
      return {
        indeksni_brojevi,
        razredi_ee_otpada,
      };

    const otpadMap = new Map<string, Deo6KalkulacijaOtpadValues>();
    const razredMap = new Map<string, Deo6KalkulacijaRazredValues>();

    otpadListsKeys.forEach(otpadList =>
      //@ts-ignore
      this.generateMapFromOtpadList(obrazac[otpadList.key], otpadMap, razredMap, 'otpad', otpadList.operation)
    );

    this.compareKolicinaMaps(indeksni_brojevi, otpadMap, operation);
    this.compareKolicinaMaps(razredi_ee_otpada, razredMap, operation);

    return {
      indeksni_brojevi,
      razredi_ee_otpada,
    };
  };

  validateSkladista = (
    otpadMap: Map<string, Deo6KalkulacijaOtpadValues>,
    razredMap: Map<string, Deo6KalkulacijaRazredValues>
  ) => {
    const invalidOtpadi: Deo6KalkulacijaOtpadValues[] = [];
    const invalidRazredi: Deo6KalkulacijaRazredValues[] = [];

    otpadMap.forEach(value => {
      if (value.kolicina < 0) invalidOtpadi.push(value);
    });
    razredMap.forEach(value => {
      if (value.kolicina < 0) invalidRazredi.push(value);
    });

    return {
      indeksni_brojevi: invalidOtpadi,
      razredi_ee_otpada: invalidRazredi,
    };
  };
}

export const deo6Service = new Deo6Service();
