import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { airStore } from 'modules/sourcesOfPollution/air/air.store';
import { IGetAir } from 'modules/sourcesOfPollution/air/air.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import { filtersStore } from 'store/filteri.store';

export const AirListTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<IGetAir> = [
    {
      title: `${t('POLLUTION.AIR.SOURCE_NUMBER')}`,
      width: 100,
      dataIndex: 'broj_izvora',
      key: 'broj_izvora',
    },
    {
      title: `${t('POLLUTION.AIR.SOURCE_NAME')}`,
      width: 100,
      dataIndex: 'naziv_izvora',
      key: 'naziv_izvora',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-activity'}
            onClick={() => {
              airStore.setAir(record);
              modalStore.changeModalName(`air-modal`);
            }}
          />
        );
      },
    },
  ];
  return (
    <TableData
      name="air"
      columns={columns}
      dataSource={[...airStore.getAirList]}
      getData={airStore.fetchAirList}
      withPagination={true}
      forceFetch={[filtersStore.filters.izvor_vazduh]}
    />
  );
});
