import { NewCompany } from 'components/NewForms';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import styles from './CompanyInfo.module.scss';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { Row, Popconfirm, Button, FormInstance, Col } from 'antd';

import { useTranslation } from 'react-i18next';
import { authStore, formStore } from 'store';
import { IGetCompany, IPostCompany } from 'modules/company/company.types';
import { CompanyStatus, companyRequiredFields } from 'modules/company/company.constants';
import { companyStore } from 'modules/company/company.store';
import { UserRoles } from 'modules/user/user.types';
import { IsActiveSwitch } from 'components/IsActiveSwitch/IsActiveSwitch';
import { cloneDeep } from 'lodash';
import { EditChangeDiscardButton } from 'components/Buttons/EditChangeDiscardButton/EditChangeDiscardButton';
import { useNavigate } from 'react-router-dom';

interface IPropsHeaderCompany {
  changeIsReadOnly: () => any;
  isReadOnly: boolean;
  form: FormInstance<IPostCompany> | null;
}

export const CompanyInfoHeader: React.FC<IPropsHeaderCompany> = observer(props => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Row align={'middle'} justify={'space-between'}>
      <h3>{t('COMPANY.GENERAL_INFORMATION')}</h3>
      <Row align={'middle'}>
        {authStore.getUserRole === UserRoles.SystemAdmin && companyStore.imaDeo6 && (
          <Col>
            <Popconfirm
              title={undefined}
              placement="leftTop"
              okText={t('YES')}
              icon={<span />}
              cancelText={t('NO')}
              description={
                (companyStore.company as IGetCompany).privremeno_ukinuta_validacija_za_deo6
                  ? 'Da li ste sigurni da želite da vratite validaciju za DEO6?'
                  : 'Da li ste sigurni da želite da ukinete validaciju za DEO6?'
              }
              onConfirm={async () => {
                const isValidationDisabled = !(companyStore.company as IGetCompany)
                  .privremeno_ukinuta_validacija_za_deo6;
                try {
                  companyStore.toggleDeo6Validation((companyStore.company as IGetCompany).id, isValidationDisabled);
                  companyStore.setCompany({
                    ...companyStore.company,
                    privremeno_ukinuta_validacija_za_deo6: isValidationDisabled,
                  });
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              <Button name="odobri-kompaniju" style={{ marginRight: '10px' }}>
                {(companyStore.company as IGetCompany).privremeno_ukinuta_validacija_za_deo6
                  ? 'Vrati validaciju za DEO6'
                  : 'Privremeno ukini validaciju za DEO6'}
              </Button>
            </Popconfirm>
          </Col>
        )}
        {authStore.getUserRole !== UserRoles.CompanyUser && (
          <>
            <Col>
              {companyStore.company.status === CompanyStatus.U_PRIPREMI && props.isReadOnly && (
                <>
                  <Popconfirm
                    title={undefined}
                    // disabled={companyRequiredFields.some(field => !(companyStore.company as IGetCompany)[field])}
                    placement="leftTop"
                    okText={t('YES')}
                    icon={<span />}
                    cancelText={t('NO')}
                    description={t('POPCONFIRM.APROVECOMPANY')}
                    // name="odobri-kompaniju"
                    onConfirm={async () => {
                      const response = await companyStore.companyReject((companyStore.company as IGetCompany).id);
                      if (response) navigate('/companies');
                    }}
                  >
                    <Button
                      name="odbaci-kompaniju"
                      className={`ant-btn-dangerous ${styles.detete_btn}`}
                      style={{ marginRight: '10px' }}
                      // disabled={companyRequiredFields.some(field => !(companyStore.company as IGetCompany)[field])}
                    >
                      {`${t('DELETE')} ${t('preduzeće')}`}
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title={undefined}
                    disabled={companyRequiredFields.some(field => !(companyStore.company as IGetCompany)[field])}
                    placement="leftTop"
                    okText={t('YES')}
                    icon={<span />}
                    cancelText={t('NO')}
                    description={t('POPCONFIRM.APROVECOMPANY')}
                    // name="odobri-kompaniju"
                    onConfirm={() => companyStore.companyApprove((companyStore.company as IGetCompany).id)}
                  >
                    <Button
                      name="odobri-kompaniju"
                      style={{ marginRight: '10px' }}
                      disabled={companyRequiredFields.some(field => !(companyStore.company as IGetCompany)[field])}
                    >
                      {t('APPROVE')}
                    </Button>
                  </Popconfirm>
                </>
              )}
            </Col>
            <Col>
              {companyStore.company.status !== CompanyStatus.U_PRIPREMI && props.isReadOnly && (
                <IsActiveSwitch
                  isActive={companyStore.getCompany.is_active}
                  aktiviraj={companyStore.aktivirajPreduzece}
                  deaktiviraj={companyStore.deaktivirajPreduzece}
                />
              )}
            </Col>
          </>
        )}

        <EditChangeDiscardButton
          name="company-info"
          editButtonVisible={
            props.isReadOnly &&
            (companyStore.getCompany.is_active || companyStore.company.status === CompanyStatus.U_PRIPREMI)
          }
          onEditClick={() => {
            props.changeIsReadOnly();
          }}
          onDiscardClick={() => {
            props.changeIsReadOnly();
            companyStore.setCompany(cloneDeep(companyStore.getCompany));
          }}
          onChangeClick={() => {
            formStore.setCrudButtonType('change');
            // if (props.form) {
            // }

            // todo: Ubacena validacija za submit. Desavalo se da se readonly state menja pre submita forme i da se polja uopste desava validacija polja.
            // Takodje, u samoj komponenti je promenjeno da je REQUIRED_FIELD_RULE uvek true.
            // mozda nije najsrecnije resenje... baci pogled

            props.form?.validateFields().then(
              res => {
                props.form?.submit();
                props.changeIsReadOnly();
                return res;
              },
              err => {
                return err;
              }
            );

            // props.changeIsReadOnly();
          }}
          discardChangeButtonVisible={!props.isReadOnly}
        />
      </Row>
    </Row>
  );
});

export const CompanyInfo: React.FC = observer(() => {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [form, setForm] = useState<FormInstance<IPostCompany> | null>(null);

  const changeIsReadOnly = () => {
    setIsReadOnly(!isReadOnly);
  };

  const passForm = (form: FormInstance<IPostCompany>) => {
    setForm(form);
  };

  useEffect(() => {
    return () => setIsReadOnly(true);
  }, []);

  return (
    <InsideContentWrapper
      header={<CompanyInfoHeader form={form} changeIsReadOnly={changeIsReadOnly} isReadOnly={isReadOnly} />}
    >
      <div className={`${styles.companyInfoWrapper} ${isReadOnly && styles.inputReadOnly}`}>
        <NewCompany readOnly={isReadOnly} passForm={passForm} />
      </div>
    </InsideContentWrapper>
  );
});
