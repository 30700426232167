import React from 'react';
import { FormInput } from 'components/FormInput/FormInput';
import styles from './NewPibForm.module.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import {
  IDENTIFICATION_NUMBER_FIELD_RULE,
  PIB_FIELD_RULE,
  invalidCharactersForInputNumber,

  // REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { useForm } from 'antd/es/form/Form';
import { Form } from 'antd';

import { CRUDButton } from 'components/Buttons/CRUDButton/CRUDButton';
import { pibInitialState } from 'modules/company/company.constants';
import { companyStore } from 'modules/company/company.store';
import { IPib } from 'modules/company/company.types';

export const NewPibForm: React.FC = observer(() => {
  const [form] = useForm();
  const { t } = useTranslation();

  const validatePib = (rule: any, value: string) => {
    if (!value && !form.getFieldValue('maticni_broj')) {
      return Promise.reject(t('AT_LEAST_ONE'));
    }
    return Promise.resolve();
  };

  const validateMaticni = (rule: any, value: string) => {
    if (!value && !form.getFieldValue('pib')) {
      return Promise.reject(t('AT_LEAST_ONE'));
    }
    return Promise.resolve();
  };

  return (
    <Form
      form={form}
      initialValues={pibInitialState}
      name={'pib-form'}
      onFinish={(values: IPib) => {
        companyStore.postPib(values);
      }}
      layout="horizontal"
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      labelAlign="left"
      className={styles.form_pib_wrapper}
    >
      <FormInput
        name="pib"
        rules={[PIB_FIELD_RULE, { validator: validatePib }]}
        type="number"
        placeholder={t('COMPANY.PLACEHOLDER.ENTER_PIB')}
        label={t('COMPANY.PIB')}
        inputName={'pib'}
        colon={true}
        className={styles.inputNumber}
        invalidCharacters={invalidCharactersForInputNumber}
      />
      <FormInput
        invalidCharacters={invalidCharactersForInputNumber}
        name="maticni_broj"
        rules={[IDENTIFICATION_NUMBER_FIELD_RULE, { validator: validateMaticni }]}
        type="number"
        placeholder={t('COMPANY.PLACEHOLDER.ENTER_IDENTIFICATION_NUMBER')}
        label={t('COMPANY.ENTERPRISE_ID_NUMBER')}
        inputName={'maticni_broj'}
        colon={true}
        className={styles.inputNumber}
      />
      <CRUDButton showButton={false} form={form} />
    </Form>
  );
});
