import { Form } from 'antd';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import {
  MORE_OR_EQUILE_0_IS_REQUIRED,
  MORE_THEN_0_IS_REQUIRED,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { ptp2ObrazacStore } from 'modules/obrasci/store/ptp2/ptp2.store';
import { IGetProizvod } from 'modules/obrasci/store/ptp2/ptp2.types';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { ISifarnikInitial, SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { IGetProductsNr } from 'modules/sifarnici/store/productsNr/productsNr.types';
import { IGetProductsType } from 'modules/sifarnici/store/productsType/productsType.types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { arrayToObject } from 'utils/arrayToObject';

export const NewProizvodiForm: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const [isUlje, setisUlje] = useState(false);

  const productTypesOptionMaker = (options: IGetProductsType[]) => {
    return options.map((entity: IGetProductsType) => ({
      label: `${(entity?.display_product ? entity?.display_product : entity?.name) || ''} ${entity?.description || ''}`,
      value: entity.id,
    }));
  };

  useEffect(() => {
    const product = form.getFieldValue('products_nr_id');
    ptp2ObrazacStore.handleChange('filterProductsTypeId', product);
    const changeToObject = arrayToObject(sifarniciStore.list[SIFARNICI_ROUTES.PRODUCTS_NR]);
    const findItem = changeToObject[product] as IGetProductsNr;

    if (!findItem) {
      if (
        ptp2ObrazacStore.getProizvodi.products_nr_id &&
        (ptp2ObrazacStore.getProizvodi as IGetProizvod).products_nr.display_product_category
          .toLowerCase()
          .includes('ulja')
      )
        setisUlje(true);
    } else if (
      findItem.display_product_category.toLowerCase().includes('ulja') ||
      findItem.display_product_category.toLowerCase().includes('oil')
    )
      setisUlje(true);
    else {
      setisUlje(false);
    }
    if (!product) setisUlje(false);
  }, [Form.useWatch('products_nr_id')]);

  useEffect(() => {
    const product = form.getFieldValue('products_type_id');

    if (product) {
      ptp2ObrazacStore.showInputsChanger(product);
    } else {
      ptp2ObrazacStore.handleChange('showIfKg', true);
      ptp2ObrazacStore.handleChange('showIfPercentage', false);
    }

    if (isUlje) form.resetFields(['kolicina_proizvoda_stavljenih_na_trziste']);
  }, [Form.useWatch('products_type_id')]);

  useEffect(() => {
    if (isUlje && form.getFieldValue('products_type_id'))
      form.setFieldValue('kolicina_proizvoda_stavljenih_na_trziste', form.getFieldValue('ulje_kolicine') * 0.9);
  }, [Form.useWatch('ulje_kolicine')]);

  const defaultProductOption: ISifarnikInitial | undefined = ptp2ObrazacStore.getProizvodi.products_nr_id
    ? {
        label: (ptp2ObrazacStore.getProizvodi as IGetProizvod).products_nr.display_product_category,
        value: (ptp2ObrazacStore.getProizvodi as IGetProizvod).products_nr_id,
      }
    : undefined;

  // todo: potrebno je da promenimo ime za label

  const defaultProductTypeOption: ISifarnikInitial | undefined = useMemo(() => {
    return ptp2ObrazacStore.getProizvodi.products_type_id
      ? {
          label: `${
            (ptp2ObrazacStore.getProizvodi as IGetProizvod)?.products_type?.display_product
              ? (ptp2ObrazacStore.getProizvodi as IGetProizvod)?.products_type?.display_product
              : (ptp2ObrazacStore.getProizvodi as IGetProizvod)?.products_type?.name
          } ${
            (ptp2ObrazacStore.getProizvodi as IGetProizvod).products_type?.description !== 'NULL' &&
            (ptp2ObrazacStore.getProizvodi as IGetProizvod).products_type?.description
              ? (ptp2ObrazacStore.getProizvodi as IGetProizvod).products_type?.description
              : ''
          }`,
          value: (ptp2ObrazacStore.getProizvodi as IGetProizvod).products_type_id,
        }
      : undefined;
  }, [ptp2ObrazacStore.getProizvodi]);

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'products_nr_id'}
        fetchOptinsList={ptp2ObrazacStore.fetchProductsNr}
        labelCol={{ span: 12 }}
        labelOptionAccessor="display_product_category"
        valueOptionAccessor="id"
        placeholder={t('OBRASCI.PTP2.VRSTE_PROIZVODA_PO_PERIODIMA')}
        label={t('OBRASCI.PTP2.VRSTE_PROIZVODA_PO_PERIODIMA')}
        inputName={'products_nr_id'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        colon={true}
        notFoundContent={t('OBRASCI.PTP2.NE_POSTOJI_ZA_GODINU')}
        defaultOption={defaultProductOption}
        onChange={() => {
          form.resetFields([
            'products_type_id',
            'kolicina_proizvoda_stavljenih_na_trziste',
            'kolicina_proizvoda',
            'ulje_kolicine',
            'pdv',
          ]);
        }}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'products_type_id'}
        fetchOptinsList={ptp2ObrazacStore.fetchProductsTypes}
        labelCol={{ span: 12 }}
        labelOptionAccessor="description"
        valueOptionAccessor="id"
        placeholder={t('OBRASCI.PTP2.NAZIV_PROIZVODA')}
        label={t('OBRASCI.PTP2.NAZIV_PROIZVODA')}
        inputName={'products_type_id'}
        colon={true}
        notFoundContent={t('OBRASCI.PTP2.NE_POSTOJI_ZA_GODINU')}
        disabled={!ptp2ObrazacStore.filterProductsTypeId}
        resetOption={ptp2ObrazacStore.filterProductsTypeId}
        optionsMaker={productTypesOptionMaker}
        defaultOption={defaultProductTypeOption}
        onChange={() => form.resetFields(['kolicina_proizvoda', 'ulje_kolicine', 'pdv'])}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                ptp2ObrazacStore.proizvodi.products_type_id !== value &&
                ptp2ObrazacStore.initialState.proizvodi.some(item => {
                  return value === item.products_type_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.PTP2.VALIDATION.NE_MOZE_BITI_ISTI_PROIZVOD')));
              }
              return Promise.resolve();
            },
          },
        ]}
      />

      {/* todo prevod */}
      {isUlje && (
        <FormInputNumber
          label={t('Uneti količinu u litrima a u polju ispod će Vam biti prikazana količina u kg')}
          labelCol={{ span: 12 }}
          name="ulje_kolicine"
          precision={4}
          inputName="ulje_kolicine"
          rules={MORE_THEN_0_IS_REQUIRED}
        />
      )}

      <h4 style={{ fontWeight: 900 }}>{t('OBRASCI.PTP2.KOLICINA_STAVLJENIH_NA_TRZISTE_PORUKA')}</h4>
      <FormInputNumber
        label={t('OBRASCI.PTP2.KOLICINA_PROIZVODA_STAVLJENIH_NA_TRZISTE')}
        labelCol={{ span: 12 }}
        name="kolicina_proizvoda_stavljenih_na_trziste"
        inputName="kolicina_proizvoda_stavljenih_na_trziste"
        type="number"
        rules={MORE_THEN_0_IS_REQUIRED}
        disabled={isUlje}
        precision={4}
      />
      {!ptp2ObrazacStore.showIfKg && (
        <>
          <h4 style={{ fontWeight: 900 }}>{t('OBRASCI.PTP2.KOLICINA_PROIZVODA_KG_PORUKA')}</h4>
          <FormInputNumber
            label={t('OBRASCI.PTP2.KOLICINA_PROIZVODA_KG')}
            labelCol={{ span: 12 }}
            name="kolicina_proizvoda"
            inputName="kolicina_proizvoda"
            type="number"
            rules={MORE_THEN_0_IS_REQUIRED}
            precision={0}
          />
        </>
      )}

      {ptp2ObrazacStore.showIfPercentage && (
        <>
          <h4 style={{ fontWeight: 900 }}>{t('OBRASCI.PTP2.KOLICINA_PDV_PORUKA')}</h4>
          <FormInputNumber
            label={t('OBRASCI.PDV')}
            labelCol={{ span: 12 }}
            name="pdv"
            inputName="pdv"
            type="number"
            rules={MORE_OR_EQUILE_0_IS_REQUIRED}
          />
        </>
      )}
    </>
  );
});
