import { IObrazacIzvestajiTranslations } from './translation.types';

// Todo: seperate files to srpski/engleski folder then import them into parent object

export const obrazacIzvestajiTranslations: IObrazacIzvestajiTranslations = {
  GODINA: 'Godina',
  OBRAZAC_IZVESTAJI: 'Obrazac izveštaji',
  TIP_OBRASCA: 'Tip obrasca',
  IZVESTAJ: 'Izveštaj',
  EXPORT_PDF: 'Preuzmi kao PDF',
  EXPORT_EXCEL: 'Preuzmi kao Excel',
};
