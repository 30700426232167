import { Button, Form, Modal, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';
import { zakljucavanjeStore } from 'modules/zakljucavanje/zakljucavanje.store';
import { IZakljucavanjeGodinaIzuzetakPayload } from 'modules/zakljucavanje/zakljucavanje.types';
import { modalStore } from 'store';
import { useTranslation } from 'react-i18next';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { IGetCompany } from 'modules/company/company.types';
import { Select } from 'components/Select/Select';
import styles from './ZakljucavanjeGodinaIzuzeciModal.module.scss';
import { ITipObrasca, ObrazacPeriodIzvestavanja } from 'modules/obrasci/obrasci.types';
import storage from 'store/storage';
import { DefaultOptionType } from 'antd/es/select';

export const ZakljucavanjeGodinaIzuzeciModal = observer(() => {
  const [form] = useForm();

  const { t } = useTranslation();

  const handleSubmit = async (values: IZakljucavanjeGodinaIzuzetakPayload) => {
    const response = await zakljucavanjeStore.postIzuzetakPoGodini(values);
    if (response) modalStore.removeAllModals();
  };

  const preduzeceOptionMaker = (options: IGetCompany[]) => {
    return options.map(company => ({
      label: company.naziv,
      value: (company as IGetCompany).id,
      disabled: !company.is_active,
    }));
  };

  const obrasciOptions = (storage.getData('tipovi_obrazaca') as ITipObrasca[]).reduce(
    (obrazacOptions: DefaultOptionType[], tip) => {
      if (tip.period_izvestavanja === ObrazacPeriodIzvestavanja.GODISNJE)
        return [...obrazacOptions, { label: `${tip.skraceni_naziv} - ${tip.naziv}`, value: tip.id, item: tip }];
      return obrazacOptions;
    },
    []
  );

  return (
    <Modal
      open={modalStore.getCurrentOpenedModal.includes('zakljucavanje-godina-izuzeci-modal')}
      destroyOnClose
      onCancel={() => modalStore.clearModal('zakljucavanje-godina-izuzeci-modal')}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      className={styles.izuzeciModal}
      width={'40%'}
    >
      <Form form={form} preserve={false} onFinish={handleSubmit}>
        <FormSearchInfiniteScroll
          showSearch={true}
          label={t('COMPANY.TITLE')}
          optionsMaker={preduzeceOptionMaker}
          placeholder={t('COMPANY.PLACEHOLDER.ENTER_NAME')}
          formName={'preduzece_id'}
          fetchOptinsList={zakljucavanjeStore.fetchPreduzece}
          labelOptionAccessor="naziv"
          valueOptionAccessor="id"
          readOnly={false}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          rules={[REQUIRED_FIELD_RULE(true)]}
        />
        <Form.Item
          label={'Tip obrasca'}
          name={'tip_obrasca_id'}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          rules={[REQUIRED_FIELD_RULE(true)]}
        >
          <Select name="tip_obrasca_id" options={obrasciOptions} />
        </Form.Item>
        <Row className={styles.izuzeciFormButtons}>
          <Button
            name={`izuzeci-modal-cancel-button`}
            onClick={() => modalStore.clearModal('zakljucavanje-godina-izuzeci-modal')}
            className="ant-btn-dangerous"
          >
            Otkaži
          </Button>
          <Button name={`izuzeci-modal-submit-button`} htmlType="submit">
            Sačuvaj
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});
