import { Checkbox, FormInstance } from 'antd';
import { IPostProductCategories, IGetProductCategories } from './productCategories.types';
import { t } from 'i18next';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';
import {
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  SwitchSifarniciFormProps,
  TextAreaSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { ColumnsType } from 'antd/es/table';
import { DESCRIPTION_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { DESCRIPTION_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
export const PRODUCT_CATEGORIES_INITIAL_STATE: IPostProductCategories = {
  ptp2_report_customs_id: '',
  product: '',
  net_mass: '',
  description: '',
  exempted: false,
  remarks: '',
  is_active: true,
};

export const PRODUCT_CATEGORIES_TABLE_DATA: ColumnsType<IGetProductCategories> = [
  {
    title: t('SIFARNICI.CARINA_PROIZVODJAC'),
    width: '250px',
    dataIndex: ['ptp2_report_customs', 'company_name'],
    key: 'company_name',
  },
  {
    title: t('SIFARNICI.PROIZVOD'),
    width: '200px',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: `${t('SIFARNICI.NETO_MASA')}`,
    width: '150px',
    dataIndex: 'net_mass',
    key: 'net_mass',
  },
  DESCRIPTION_TABLE,
  {
    title: `${t('SIFARNICI.OSLOBODJENI')}`,
    width: '150px',
    dataIndex: 'exempted',
    key: 'exempted',
    render: (_: any, record: IGetProductCategories) => <Checkbox checked={record.exempted} disabled={true} />,
    align: 'center',
  },
  {
    title: `${t('WIZARD_TABS.NAPOMENA')}`,
    width: '200px',
    dataIndex: 'remarks',
    key: 'remarks',
  },
];

export const PRODUCT_CATEGORIES_FORM_OPTIONS = (_: FormInstance<IGetProductCategories>) => {
  return [
    {
      type_of_form: 'searchScroll',
      label: t('SIFARNICI.CARINA_PROIZVODJAC'),
      formName: 'ptp2_report_customs_id',
      fetchOptinsList: SIFARNICI_ROUTES.PTP2_REPORT_CUSTOMS,
      filtersForFetch: {},
      labelOptionAccessor: ['company_name'],
      valueOptionAccessor: 'id',
      sortName: 'company_name',
      // disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('SIFARNICI.PROIZVOD'),
      name: 'product',
      inputName: 'product',
      rules: [MAX_LENGTH_RULE(200)],
      sortName: 'product',
    } as InputSifarniciFormProps,

    {
      type_of_form: 'input',
      name: 'net_mass',
      // placeholder: t('COMPANY.PLACEHOLDER.ENTER_SHORT_NAME'),
      label: t('SIFARNICI.NETO_MASA'),
      inputName: 'net_mass',
      sortName: 'net_mass',
    } as InputSifarniciFormProps,

    DESCRIPTION_FORM,

    {
      type_of_form: 'switch',
      name: 'exempted',
      // placeholder: t('COMPANY.PIB'),
      label: t('SIFARNICI.OSLOBODJENI'),
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'area',
      name: 'remarks',
      // placeholder: t('COMPANY.PIB'),
      label: t('WIZARD_TABS.NAPOMENA'),
      rows: 4,
      labelCol: 10,
      wrapperCol: 14,
      rules: [MAX_LENGTH_RULE(300)],
    } as TextAreaSifarniciFormProps,
  ];
};

// export const PRODUCT_CATEGORIES_FILTERS=(_: FormInstance<IGetProductCategories>) = {

// }
