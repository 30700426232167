import { observer } from 'mobx-react-lite';
import React from 'react';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { TableGoriva } from './components/TableGoriva/TableGoriva';

export const Obrazac1GorivaWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<WizardObrazacListHeader name={'goriva-form'} modalName="goriva-modal" />}>
      <TableGoriva />
    </InsideContentWrapper>
  );
});
