import { IGetCompany, IPib, IPostCompany } from './company.types';

export const companyInitialState: IPostCompany = {
  pib: '',
  maticni_broj: '',
  naziv: '',
  kraci_naziv: '',
  postanski_broj: '',
  mesto_id: '',
  ulica: '',
  kucni_broj: '',
  ime_odgovorne_osobe: '',
  prezime_odgovorne_osobe: '',
  telefon: '',
  email: '',
  sifarnik_pretezne_delatnosti_id: '',
  popunjen_upitnik: false,
  verifikacioni_kod: '',
  status: '',
  is_active: false,
  napomena: '',
};

export const pibInitialState: IPib = {
  pib: '',
  maticni_broj: '',
};

export const companyRequiredFields: (keyof Partial<IGetCompany>)[] = [
  'naziv',
  'kraci_naziv',
  // 'pib',
  // 'maticni_broj',
  'telefon',
  'email',
  // 'sifarnik_pretezne_delatnosti_id',
  'mesto_id',
  'postanski_broj',
  'ulica',
  'kucni_broj',
  'ime_odgovorne_osobe',
  'prezime_odgovorne_osobe',
];

export enum CompanyStatus {
  AKTIVAN = 'aktivan',
  U_PRIPREMI = 'u_pripremi',
}

export enum KoraciRegistracijeKorisnika {
  WARNING = 'warningInfo',
  PIB = 'pib',
  INFO = 'info',
  SURVEY = 'survey',
  HOME = 'home',
}
