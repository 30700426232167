import React from 'react';

import { Select as ANTSelect, SelectProps } from 'antd';

interface ISelectProps extends SelectProps {
  name: string;
}

export const Select: React.FC<ISelectProps> = props => {
  return <ANTSelect {...props} data-test={`input-${props.name}`} />;
};
