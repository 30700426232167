import { IPostGio5Obrazac, IPostGio5Dozvole, IPostGio5UvozOtpada, IPostGio5Country } from './gio5.types';

export const GIO_5_INITIAL_STATE: IPostGio5Obrazac = {
  godina: '',
  preduzece_id: '',
  postrojenje_id: '',
  napomena: '',
  dozvole: [],
  uvoz_otpada: [],
};

export const GIO_5_DOZVOLE_INITIAL_STATE: IPostGio5Dozvole = {
  broj_dozvole: '',
  datum_izdavanja: '',
  datum_isteka: '',
  sakupljanje: false,
  transport: false,
  skladistenje: false,
  tretman: false,
  odlaganje: false,
};

export const GIO_5_UVOZ_OTPADA_INITIAL_STATE: IPostGio5UvozOtpada = {
  indeksni_broj_otpada_id: '',
  kolicina_uvezenog_otpada: null,
  broj_dozvole_za_uvoz: '',
  zemlja_izvoza_id: '',
  naziv_i_adresa_preduzeca_uvoznika: '',
  uvoz_otpada_y_lista_id: '',
  uvoz_otpada_y_lista2_id: '',
  uvoz_otpada_y_lista3_id: '',
  uvoz_otpada_c_lista_id: '',
  uvoz_otpada_n_lista_id: '',
  uvoz_otpada_n_lista2_id: '',
  uvoz_otpada_n_lista3_id: '',
  ia_lista_id: '',
  iib_lista_id: '',
  zemlje_tranzita: [],
};

export const GIO_5_ZEMLJA_TRANZITA_INITIAL_STATE: IPostGio5Country = {
  zemlja_tranzita_id: '',
};
