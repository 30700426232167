import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { aao2Store } from 'modules/obrasci/store/aao2/aao2.store';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import style from './Aao2PravnoLiceTable.module.scss';
import { IPravnoLiceIliPreduzetnik } from 'modules/obrasci/store/aao2/aao2.types';
// import { isEmpty } from 'lodash';
// import { useEffect } from 'react';

export const Aao2PravnoLiceTable: React.FC = observer(() => {
  const { t } = useTranslation();

  // useEffect(() => {
  //   if (isEmpty(aao2Store.initialState.pravno_lice_ili_preduzetnik) && aao2Store.initialState.preduzece_id) {
  //     aao2Store.fetchOldPravnoLiceList();
  //   }
  // }, [aao2Store.initialState.preduzece_id]);

  const columns: ColumnsType<any> = [
    {
      title: t('COMPANY.FULL_NAME'),
      width: '200px',
      align: 'center',
      dataIndex: 'naziv',
    },
    {
      title: t('COMPANY.LOCATION'),
      width: '150px',
      align: 'center',
      dataIndex: ['mesto', 'display_name'],
    },
    {
      title: t('COMPANY.PIB'),
      width: '100px',
      align: 'center',
      dataIndex: 'pib',
    },
    {
      title: t('COMPANY.ENTERPRISE_ID_NUMBER'),
      width: '100px',
      align: 'center',
      dataIndex: 'maticni_broj',
    },
    {
      title: t('COMPANY.POST_NUMBER'),
      width: '100px',
      align: 'center',
      dataIndex: 'postanski_broj',
    },
    {
      title: t('COMPANY.ADDRESS'),
      width: 150,
      align: 'center',
      dataIndex: 'ulica',
    },
    {
      title: t('COMPANY.ADDRESS_NUMBER'),
      width: 150,
      align: 'center',
      dataIndex: 'kucni_broj',
    },
    {
      title: t('COMPANY.PHONE'),
      width: 150,
      align: 'center',
      dataIndex: 'telefon',
    },
    {
      title: t('COMPANY.EMAIL'),
      width: 150,
      align: 'center',
      dataIndex: 'email',
    },
    {
      title: t('COMPANY.PRINCIPAL_BUSINESS_ACTIVITY_CODE'),
      width: 150,
      align: 'center',
      dataIndex: ['sifarnik_pretezne_delatnosti', 'name_sr'],
    },
    {
      title: '',
      fixed: 'right',
      width: 50,
      render: (_, record: IPravnoLiceIliPreduzetnik) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-activity'}
            onClick={() => {
              aao2Store.handleChange('pravnoLiceIliPreduzetnik', record);
              modalStore.changeModalName(`aao2-pravno-lice-modal`);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className={style.tableDiv}>
      <TableData
        name="pravno-lice-aao2"
        columns={columns}
        dataSource={[...aao2Store.getInitialState.pravno_lice_ili_preduzetnik]}
      />
    </div>
  );
});
