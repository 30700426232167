import { observer } from 'mobx-react-lite';
import React from 'react';
import { Gio2DeponijeModal } from './components/Gio2DeponijeModal/Gio2DeponijeModal';
import { Gio2DozvoleModal } from './components/Gio2DozvoleModal/Gio2DozvoleModal';
import { Gio2KolicineModal } from './components/Gio2KolicineModal/Gio2KolicineModal';
import { Gio2RazrediEEModal } from './components/Gio2RazrediEEModal/Gio2RazrediEEModal';

export const Gio2MicroServices: React.FC = observer(() => {
  return (
    <>
      <Gio2DozvoleModal />
      <Gio2DeponijeModal />
      <Gio2KolicineModal />
      <Gio2RazrediEEModal />
    </>
  );
});
