import { axios } from 'modules/axios';
import { IOTVRegistar, OtvOpsteInfo } from 'modules/registri/otv/otv.types';
import { registriConfig } from 'modules/registri/registri.constants';
import { RegistriEnum } from 'modules/registri/registri.types';
import queryString from 'query-string';
import { IPaginatedApiResponse } from 'typescript/NrizTypes';

const checkOtvObaveza = (payload: OtvOpsteInfo): IPaginatedApiResponse<IOTVRegistar> => {
  const query = queryString.stringify(payload);
  return axios.get(`${registriConfig[RegistriEnum.Otv].apiRoute}?${query}`);
};
export const obrazac3Repo = {
  checkOtvObaveza,
};
