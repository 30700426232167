import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { istorijaPromenaStore } from 'modules/istorijaPromena/istorijaPromena.store';
import { IGetIstorijaPromena } from 'modules/istorijaPromena/istorijaPromena.types';
import React from 'react';
import { HistoryDiffViewer } from './components/HistoryDiffViewer/HistoryDiffViewer';
import { filtersStore } from 'store/filteri.store';
import dayjs from 'dayjs';

export const IstorijaPromenaTable: React.FC = observer(() => {
  // const { t } = useTranslation();
  const columns: ColumnsType<IGetIstorijaPromena> = [
    {
      title: `ID`,
      dataIndex: 'id',
      width: '200px',
      key: 'id',
    },
    {
      title: `Datum kreiranja`,
      dataIndex: 'timestamp',
      width: '200px',
      key: 'timestamp',
      render: (_, record: IGetIstorijaPromena) => {
        return <div>{dayjs(record.timestamp).format('DD.MM.YYYY. HH:MM:ss')}</div>;
      },
    },
    {
      title: `Korisnik`,
      dataIndex: 'korisnik',
      width: '200px',
      key: 'korisnik',
    },
    {
      title: `Promene`,
      dataIndex: 'promene',
      width: '1500px',
      key: 'promene',
      render: (_, record: IGetIstorijaPromena) => {
        return <HistoryDiffViewer oldValues={record.before_payload} newValues={record.after_payload} />;
      },
    },
    {
      title: `Tip promene`,
      dataIndex: 'tip',
      key: 'tip',
      width: '200px',
    },
  ];
  return (
    <TableData
      columns={columns}
      scrollConfig={['#istorijaPromenaFilters']}
      dataSource={istorijaPromenaStore.istorijaPromenaList}
      name={'istorija-promena-table'}
      forceFetch={[filtersStore.filters.istorijaPromena]}
      withPagination={true}
      getData={istorijaPromenaStore.fetchIstorijuPromena}
    />
  );
});
