import { observer } from 'mobx-react-lite';
import styles from './Obrazac1UkupnaKolicinaPoAktivnostimaWizardTab.module.scss';
import { obrazac1Store } from 'modules/obrasci/store/obrazac1/obrazac1.store';
import { Col, Row, message } from 'antd';
import { Obrazac1AktivnostiRow } from './components/Obrazac1AktivnostiRow';
import { useEffect } from 'react';

export const Obrazac1UkupnaKolicinaPoAktivnostimaWizardTab = observer(() => {
  useEffect(() => {
    if (obrazac1Store.invalidActivities) {
      obrazac1Store.handleChange('invalidActivities', false);
      message.warning(
        'Došlo je do promene aktivnosti na postrojenju. Potrebno je da unesete količine na kartici Ukupna količina po aktivnostima.',
        3
      );
    }
  }, [obrazac1Store.invalidActivities]);

  //todo: prevod
  return (
    <section className={styles.ukupnaKolicinaPoAktivnostimaWrapper}>
      <Row className={styles.aktivnostRow}>
        <Col span={9}>Aktivnost postrojenja</Col>
        <Col span={5}>Ukupna količina proizvoda u okviru aktivnosti</Col>
        <Col span={4}>Jedinica mere</Col>
        <Col span={4}>Definicija jedinice mere</Col>
        <Col span={2}>Izmeni količinu</Col>
      </Row>
      {obrazac1Store.aktivnosti.map((aktivnost, i) => {
        return <Obrazac1AktivnostiRow aktivnost={aktivnost} key={i} index={i} />;
      })}
    </section>
  );
});
