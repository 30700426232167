import { DefaultOptionType } from 'antd/lib/select';

export interface IMap4SelectConfig {
  sourceArray: any[];
  labelAccessor: string;
  valueAccessor: string;
  disabledAccessor?: string;
}

export const map4select = ({
  sourceArray,
  labelAccessor,
  valueAccessor,
  disabledAccessor = 'is_active',
}: IMap4SelectConfig): DefaultOptionType[] => {
  return sourceArray.map(entity => ({
    label: entity?.[labelAccessor],
    value: entity?.[valueAccessor],
    disabled: disabledAccessor ? !entity?.[disabledAccessor] : false,
  }));
};

export interface IMap4SelectConfigNew {
  sourceArray: any[];
  labelAccessor: string[];
  valueAccessor: string;
  disabledAccessor?: string;
  nestedObjKey?: string;
}

export const makeLabel = (arr: string[], obj: any) => {
  let result = '';
  arr.forEach((key: string, index: number) => {
    result += obj[key];
    if (index < arr.length - 1) {
      result += ' ';
    }
  });
  return result;
};

export const map4selectNew = (selectConfig: IMap4SelectConfigNew): DefaultOptionType[] => {
  return selectConfig.sourceArray.map(entity => {
    const label = makeLabel(
      selectConfig.labelAccessor,
      !selectConfig.nestedObjKey ? entity : entity[selectConfig.nestedObjKey]
    );

    return {
      label: label,
      value: entity?.[selectConfig.valueAccessor],
      disabled: selectConfig.disabledAccessor ? !entity?.[selectConfig.disabledAccessor] : false,
      item: entity,
    };
  });
};
