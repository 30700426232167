import { observer } from 'mobx-react-lite';
import { companyStore } from 'modules/company/company.store';
import { UserRoles } from 'modules/user/user.types';
import { PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authStore } from 'store';
import { KoraciRegistracijeKorisnika } from 'modules/company/company.constants';
import { IGetCompany } from 'modules/company/company.types';
import { rolesForCompanyCheck } from 'store/AuthStore';

type RouteGuardProps = {
  allowedRoles: UserRoles[];
} & PropsWithChildren;

const RouteGuard = observer(({ allowedRoles, children }: RouteGuardProps) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const REDIRECT_ROUTES: { [key: string | UserRoles]: string } = {
    [UserRoles.SystemAdmin]: '/companies',
    [UserRoles.CompanyOwner]: `/company/${(companyStore.company as IGetCompany)?.id}`,
    [UserRoles.CompanyUser]: `/company/${(companyStore.company as IGetCompany)?.id}`,
  };

  const currentRole = authStore.getUserRole;

  const startingRegistration = !currentRole && pathname.includes('user-registration');

  const hasPermissions = currentRole && allowedRoles.includes(currentRole);

  useEffect(() => {
    if (currentRole && rolesForCompanyCheck.includes(currentRole) && companyStore.getPageToShow) {
      if (companyStore.getPageToShow !== KoraciRegistracijeKorisnika.HOME) navigate('/user-start-page');
      else if (pathname.includes('user-start-page')) navigate(`/company/${(companyStore.company as IGetCompany)?.id}`);
    }
    if (currentRole && !hasPermissions) navigate(REDIRECT_ROUTES[currentRole]);
  }, [pathname, companyStore.getPageToShow, currentRole]);

  return <>{hasPermissions || startingRegistration ? children : null}</>;
});

export default RouteGuard;
