import { Col, Form, Row, Select } from 'antd';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0, REQUIRED_FIELD_RULE, CONDITIONALLY_REQUIRED_FIELDS_RULE } from 'modules/nriz/nriz.constants';
import { aao1Store } from 'modules/obrasci/store/aao1/aao1.store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { useTranslation } from 'react-i18next';

import React, { useEffect, useState } from 'react';
import { IGetKolicinaAmbalaze, IGetUpravljanjeAmbalaznimOtpadom } from 'modules/obrasci/store/aao1/aao1.types';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import styles from './NewUpravljanjeAmbalaznimOtpadomForm.module.scss';
import { arrayToObject } from 'utils/arrayToObject';
import { drListOptions } from 'utils/optionMakers';

export const NewUpravljanjeAmbalaznimOtpadomForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const form = Form.useFormInstance();

  const [disabledR2, setDisabledR2] = useState(!form.getFieldValue('druga_operacija_r_id'));
  const [disabledD2, setDisabledD2] = useState(!form.getFieldValue('druga_operacija_d_id'));

  const defaultROption: ISifarnikInitial | undefined = aao1Store.getUpravljanjeAmbalaznimOtpadom.druga_operacija_r_id
    ? {
        value: aao1Store.getUpravljanjeAmbalaznimOtpadom.druga_operacija_r_id,
        label: `${
          (aao1Store.getUpravljanjeAmbalaznimOtpadom as IGetUpravljanjeAmbalaznimOtpadom).druga_operacija_r.name
        } ${
          (aao1Store.getUpravljanjeAmbalaznimOtpadom as IGetUpravljanjeAmbalaznimOtpadom).druga_operacija_r
            .description_sr
        }`,
      }
    : undefined;

  const defaultDOption: ISifarnikInitial | undefined = aao1Store.getUpravljanjeAmbalaznimOtpadom.druga_operacija_d_id
    ? {
        value: aao1Store.getUpravljanjeAmbalaznimOtpadom.druga_operacija_d_id,
        label: `${
          (aao1Store.getUpravljanjeAmbalaznimOtpadom as IGetUpravljanjeAmbalaznimOtpadom).druga_operacija_d.name
        } ${
          (aao1Store.getUpravljanjeAmbalaznimOtpadom as IGetUpravljanjeAmbalaznimOtpadom).druga_operacija_d
            .description_sr
        }`,
      }
    : undefined;

  const defaultPackingTypeOption: ISifarnikInitial | undefined = aao1Store.getUpravljanjeAmbalaznimOtpadom
    .type_of_packing_id
    ? {
        value: aao1Store.getUpravljanjeAmbalaznimOtpadom.type_of_packing_id,
        label: (aao1Store.getUpravljanjeAmbalaznimOtpadom as IGetUpravljanjeAmbalaznimOtpadom).type_of_packing
          .display_type,
      }
    : undefined;

  const onKolicinaRChange = () => {
    const R1Kolicina = Number(form.getFieldValue('kolicina_za_r1_operaciju'));
    const R2Kolicina = Number(form.getFieldValue('kolicina_za_drugu_r_operaciju'));
    const R3Kolicina = Number(form.getFieldValue('kolicina_za_r3_operaciju'));

    const ukupnaRKolicina = R1Kolicina + R2Kolicina + R3Kolicina;

    !R1Kolicina && !R2Kolicina && !R3Kolicina && !ukupnaRKolicina
      ? form.setFieldValue('ukupna_kolicina_ponovno_iskoriscenog_otpada', null)
      : form.setFieldValue('ukupna_kolicina_ponovno_iskoriscenog_otpada', ukupnaRKolicina);
  };

  const onKolicinaDChange = () => {
    const D1Kolicina = Number(form.getFieldValue('kolicina_za_d1_operaciju'));
    const D2Kolicina = Number(form.getFieldValue('kolicina_za_drugu_d_operaciju'));

    const ukupnaDKolicina = D1Kolicina + D2Kolicina;

    !D1Kolicina && !D2Kolicina && !ukupnaDKolicina
      ? form.setFieldValue('ukupna_kolicina_odlozenog_otpada', null)
      : form.setFieldValue('ukupna_kolicina_odlozenog_otpada', ukupnaDKolicina);
  };

  useEffect(() => {
    onKolicinaDChange();
    onKolicinaRChange();
  }, []);

  const upravljanjeOtpadomOptions = aao1Store.initialState.kolicina_ambalaze
    .filter(item => !item.da_li_je_preneta_kolicina_ambalaze_na_operatera)
    .map(item => ({
      label: (item as IGetKolicinaAmbalaze).type_of_packing.display_type_sr,
      value: item.type_of_packing_id,
    }));
  return (
    <Col span={24}>
      <Form.Item
        name={'type_of_packing_id'}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                aao1Store.getUpravljanjeAmbalaznimOtpadom.type_of_packing_id !== value &&
                aao1Store.initialState.upravljanje_ambalaznim_otpadom.some(item => {
                  return value === item.type_of_packing_id;
                })
              ) {
                return Promise.reject(new Error(t('VALIDATION.ALREADY_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
        label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.PACKAGING_TYPE')}
        labelCol={{ span: 8 }}
      >
        <Select
          defaultValue={defaultPackingTypeOption}
          options={upravljanjeOtpadomOptions}
          placeholder={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.PLACEHOLDERS.PACKAGING_TYPE')}
          onSelect={() => form.validateFields(['kolicina_za_samostalno_upravljanje'])}
        ></Select>
      </Form.Item>
      <FormInputNumber
        name="kolicina_za_samostalno_upravljanje"
        label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.SELF_MANAGEMENT_AMOUNT')}
        labelCol={{ span: 8 }}
        placeholder={''}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              const kolAmbToObject = arrayToObject(aao1Store.initialState.kolicina_ambalaze, 'type_of_packing_id');
              const vrstaAmbalaze = form.getFieldValue('type_of_packing_id');
              const povratna = Number(
                //@ts-ignore
                kolAmbToObject[vrstaAmbalaze].ukupna_kolicina_povratne_ambalaze
              )
                ? //@ts-ignore
                  Number(kolAmbToObject[vrstaAmbalaze].ukupna_kolicina_povratne_ambalaze)
                : 0;
              const nepovratna = Number(
                //@ts-ignore
                kolAmbToObject[vrstaAmbalaze].ukupna_kolicina_nepovratne_ambalaze
              )
                ? //@ts-ignore
                  Number(kolAmbToObject[vrstaAmbalaze].ukupna_kolicina_nepovratne_ambalaze)
                : 0;
              const ukupnaKolicina = povratna + nepovratna;

              const preuzeta = Number(form.getFieldValue('preuzeta_kolicina'));
              if (value < preuzeta || value <= 0)
                return Promise.reject(
                  new Error(
                    'Količina za samostalno upravljanje mora biti veća od 0 i ne sme biti manja od preuzete količine'
                  )
                );
              else if (value > ukupnaKolicina)
                return Promise.reject(
                  new Error(
                    'Količina za samostalno upravljanje ne sme biti veća od zbira ukupnih količina za odgovarajuću vrstu ambalaže iz taba Količina ambalaže'
                  )
                );
              return Promise.resolve();
            },
          },
        ]}
        inputName={'kolicina_za_samostalno_upravljanje'}
        colon={true}
      />
      <FormInputNumber
        name="preuzeta_kolicina"
        label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.AMOUNT_TAKEN')}
        labelCol={{ span: 8 }}
        placeholder={''}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              const ukupnaDR =
                Number(form.getFieldValue('ukupna_kolicina_ponovno_iskoriscenog_otpada')) +
                Number(form.getFieldValue('ukupna_kolicina_odlozenog_otpada'));
              if (value < ukupnaDR || value <= 0)
                return Promise.reject(
                  new Error(
                    'Preuzeta količina mora biti veća od 0 i ne sme biti manja od zbira Ukupne količine ponovno iskorišćenog otpada i Ukupne količine odloženog otpada'
                  )
                );
              return Promise.resolve();
            },
          },
        ]}
        inputName={'preuzeta_kolicina'}
        onInputChange={() => form.validateFields(['kolicina_za_samostalno_upravljanje'])}
        colon={true}
      />
      <Row>
        <Col span={12}>
          <Row>
            <Col span={15} className={styles.title}>
              <h4>
                {/* <span style={{ color: 'red' }}>* </span> */}
                {t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.RECYCLED_PACKAGING_WASTE')}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="kolicina_za_r1_operaciju"
                label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.R1_OPERATION_AMOUNT')}
                labelCol={{ span: 18 }}
                placeholder={''}
                inputName={'kolicina_za_r1_operaciju'}
                rules={MORE_THEN_0}
                onInputChange={() => {
                  onKolicinaRChange();
                  form.validateFields([
                    'ukupna_kolicina_odlozenog_otpada',
                    'ukupna_kolicina_ponovno_iskoriscenog_otpada',
                    'preuzeta_kolicina',
                  ]);
                }}
                colon={true}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormSearchInfiniteScroll
                showSearch={true}
                formName={'druga_operacija_r_id'}
                fetchOptinsList={aao1Store.fetchROptions}
                labelOptionAccessor="name"
                valueOptionAccessor="id"
                onChange={value => {
                  setDisabledR2(false);
                  if (!value) {
                    setDisabledR2(true);
                    form.setFieldValue('kolicina_za_drugu_r_operaciju', null);
                    onKolicinaRChange();
                    form.validateFields(['kolicina_za_drugu_r_operaciju', 'preuzeta_kolicina']);
                  }
                }}
                label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.SECOND_R_OPERATION')}
                labelCol={{ span: 18 }}
                inputName={'druga_operacija_r_id'}
                colon={true}
                defaultOption={defaultROption}
                placeholder={''}
                optionsMaker={drListOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="kolicina_za_drugu_r_operaciju"
                label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.R2_OPERATION_AMOUNT')}
                labelCol={{ span: 18 }}
                placeholder={''}
                inputName={'kolicina_za_drugu_r_operaciju'}
                rules={[REQUIRED_FIELD_RULE(!!form.getFieldValue('druga_operacija_r_id')), ...MORE_THEN_0]}
                disabled={disabledR2}
                onInputChange={() => {
                  onKolicinaRChange();
                  form.validateFields([
                    'ukupna_kolicina_odlozenog_otpada',
                    'ukupna_kolicina_ponovno_iskoriscenog_otpada',
                    'preuzeta_kolicina',
                  ]);
                }}
                colon={true}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <FormInputNumber
                name="kolicina_za_r3_operaciju"
                label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.R3_OPERATION_AMOUNT')}
                labelCol={{ span: 18 }}
                placeholder={''}
                inputName={'kolicina_za_r3_operaciju'}
                rules={MORE_THEN_0}
                onInputChange={() => {
                  onKolicinaRChange();
                  form.validateFields([
                    'ukupna_kolicina_odlozenog_otpada',
                    'ukupna_kolicina_ponovno_iskoriscenog_otpada',
                    'preuzeta_kolicina',
                  ]);
                }}
                colon={true}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="ukupna_kolicina_ponovno_iskoriscenog_otpada"
                label={`**${t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.TOTAL_RECYCLED')}`}
                labelCol={{ span: 18 }}
                disabled={true}
                placeholder={''}
                inputName={'ukupna_kolicina_ponovno_iskoriscenog_otpada'}
                onInputChange={() => {
                  onKolicinaRChange();
                  form.validateFields(['preuzeta_kolicina']);
                }}
                rules={[
                  ...MORE_THEN_0,
                  CONDITIONALLY_REQUIRED_FIELDS_RULE(
                    ['ukupna_kolicina_odlozenog_otpada', 'ukupna_kolicina_ponovno_iskoriscenog_otpada'],
                    'Potrebno je da barem jedna od vrednosti bude veća od 0!'
                  ),
                ]}
                colon={true}
              />
            </Col>
          </Row>
        </Col>
        <Col span={10} offset={2}>
          <Row>
            <Col span={15} className={styles.title}>
              <h4>
                {/* <span style={{ color: 'red' }}>* </span> */}
                {t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.DISCARDED_PACKAGING_WASTE')}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="kolicina_za_d1_operaciju"
                label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.D1_OPERATION_AMOUNT')}
                labelCol={{ span: 17 }}
                placeholder={''}
                inputName={'kolicina_za_d1_operaciju'}
                rules={MORE_THEN_0}
                onInputChange={() => {
                  onKolicinaDChange();
                  form.validateFields([
                    'ukupna_kolicina_odlozenog_otpada',
                    'ukupna_kolicina_ponovno_iskoriscenog_otpada',
                    'preuzeta_kolicina',
                  ]);
                }}
                colon={true}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormSearchInfiniteScroll
                showSearch={true}
                formName={'druga_operacija_d_id'}
                fetchOptinsList={aao1Store.fetchDOptions}
                labelOptionAccessor="name"
                valueOptionAccessor="id"
                onChange={value => {
                  setDisabledD2(false);
                  if (!value) {
                    setDisabledD2(true);
                    form.setFieldValue('kolicina_za_drugu_d_operaciju', null);
                    onKolicinaDChange();
                    form.validateFields(['kolicina_za_drugu_d_operaciju', 'preuzeta_kolicina']);
                  }
                }}
                label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.SECOND_D_OPERATION')}
                labelCol={{ span: 17 }}
                inputName={'druga_operacija_d_id'}
                colon={true}
                defaultOption={defaultDOption}
                placeholder={''}
                optionsMaker={drListOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="kolicina_za_drugu_d_operaciju"
                label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.D2_OPERATION_AMOUNT')}
                labelCol={{ span: 17 }}
                placeholder={''}
                inputName={'kolicina_za_drugu_d_operaciju'}
                onInputChange={() => {
                  onKolicinaDChange();
                  form.validateFields([
                    'ukupna_kolicina_odlozenog_otpada',
                    'ukupna_kolicina_ponovno_iskoriscenog_otpada',
                    'preuzeta_kolicina',
                  ]);
                }}
                rules={[REQUIRED_FIELD_RULE(!!form.getFieldValue('druga_operacija_d_id')), ...MORE_THEN_0]}
                disabled={disabledD2}
                colon={true}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ height: '53px' }}></Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="ukupna_kolicina_odlozenog_otpada"
                label={`**${t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.TOTAL_DISCARDED')}`}
                labelCol={{ span: 17 }}
                disabled={true}
                placeholder={''}
                inputName={'ukupna_kolicina_odlozenog_otpada'}
                onInputChange={() => {
                  onKolicinaDChange();
                  form.validateFields(['preuzeta_kolicina']);
                }}
                rules={[
                  ...MORE_THEN_0,
                  CONDITIONALLY_REQUIRED_FIELDS_RULE(
                    ['ukupna_kolicina_odlozenog_otpada', 'ukupna_kolicina_ponovno_iskoriscenog_otpada'],
                    'Potrebno je da barem jedna od vrednosti bude veća od 0!'
                  ),
                ]}
                colon={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
});
