import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { IGetWater, IPostWater, RequestwaterList, ResponseWater } from './water.types';

const fetchWaterList = (payload: RequestwaterList): ApiResponse<ResponseWater> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.WATER}?${query}`);
};

const postWater = (payload: IPostWater): ApiResponse<any> => {
  return axios.post(`${ROUTES.WATER}`, payload);
};

const putWater = (payload: IGetWater): ApiResponse<any> => {
  return axios.put(`${ROUTES.WATER}${payload.id}`, payload);
};

const deleteWater = (id: string): ApiResponse<any> => {
  return axios.delete(`${ROUTES.WATER}${id}`);
};

const aktivirajVodu = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.WATER}${id}/aktiviraj`);
};
const deaktivirajVodu = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.WATER}${id}/deaktiviraj`);
};

export const waterRepo = {
  fetchWaterList,
  postWater,
  putWater,
  deleteWater,
  aktivirajVodu,
  deaktivirajVodu,
};
