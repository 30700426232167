import React from 'react';

import { InputNumber as ANTInputNumber, InputNumberProps } from 'antd';

import './InputNumber.scss';

interface IInputProps extends InputNumberProps {
  name: string;
}

export const InputNumber: React.FC<IInputProps> = props => {
  return <ANTInputNumber {...props} data-test={`input-${props.name}`} />;
};
