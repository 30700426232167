import { UpOutlined, DownOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import styles from './SortByButton.module.scss';
import { FormInstance } from 'antd';

interface ISortByProps {
  form: FormInstance<any>;
  name: string;
  onChange?: (e: any) => any;
}

export const SortByButton: React.FC<ISortByProps> = props => {
  const [state, setState] = useState(1);
  let str = props.form.getFieldValue('order_by') ?? '';

  useEffect(() => {
    if (!str.includes(props.name)) {
      setState(1);
    }
  }, [props.form.getFieldValue('order_by')]);

  return (
    <div
      className={`${styles.wrapper} ${state !== 1 ? styles.active : styles.inactive}`}
      onClick={() => {
        let newState = 0;
        if (state === 1) newState = 2;
        if (state === 2) newState = 3;
        if (state === 3) newState = 1;

        setState(newState);

        if (newState === 1) {
          str = str.replace(`-${props.name}`, '');
        } else if (newState === 2) {
          str = `${str}+${props.name}`;
        } else {
          str = str.replace(`+${props.name}`, `-${props.name}`);
        }
        props.form.setFieldValue('order_by', str);
      }}
    >
      {state !== 2 ? <UpOutlined rev={undefined} width={'1px'} /> : <div style={{ height: '13px' }}></div>}
      {state !== 3 ? <DownOutlined rev={undefined} width={'1px'} /> : <div style={{ height: '13px' }}></div>}
    </div>
  );
};
