import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { ColumnType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData, { ITablePagination } from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { zakljucavanjeFields } from 'modules/zakljucavanje/zakljucavanje.constants';
import { zakljucavanjeService } from 'modules/zakljucavanje/zakljucavanje.service';
import { zakljucavanjeStore } from 'modules/zakljucavanje/zakljucavanje.store';
import { IZakljucavanjeObrazacaFilters } from 'modules/zakljucavanje/zakljucavanje.types';
import { filtersStore } from 'store/filteri.store';

interface ZakljucavanjeTableProps {
  selectedTipObrascaColumns: ColumnType<any>[];
}

export const ZakljucavanjePoTipuTable: React.FC<ZakljucavanjeTableProps> = observer(({ selectedTipObrascaColumns }) => {
  const form = useFormInstance();

  const refetchData = (pagination: ITablePagination) => {
    if (!form.getFieldValue('godina')) return Promise.reject();
    const params = form.getFieldsValue(zakljucavanjeFields);
    return zakljucavanjeStore.fetchZakljucavanjeObrazacaList({ ...pagination, ...params });
  };

  const handleLockUnlockObrazac = async (id: string, godina: number | null) => {
    const response = await zakljucavanjeStore.zakljucajObrasce({ obrazac_id: id, godina });
    const params = form.getFieldsValue(zakljucavanjeFields);
    if (response)
      filtersStore.setFilters('zakljucavanjeObrasci', { ...filtersStore.filters.zakljucavanjeObrasci, ...params });
  };

  const columns: ColumnType<IZakljucavanjeObrazacaFilters>[] = [
    {
      width: 70,
      title: 'Godina',
      dataIndex: 'godina',
      key: 'godina',
    },
    {
      width: 70,
      title: 'Preduzece',
      dataIndex: ['preduzece', 'naziv'],
      key: 'preduzece',
    },
    {
      width: 70,
      title: 'Obrazac',
      dataIndex: ['tip_obrasca', 'skraceni_naziv'],
      key: 'tip_obrasca',
    },
    ...selectedTipObrascaColumns,
    {
      width: 35,
      title: zakljucavanjeService.returnIsLocked(zakljucavanjeStore.showOtkljucano)
        ? 'Otkljucaj obrazac'
        : 'Zakljucaj obrazac',
      align: 'center',
      render(_, record) {
        return (
          <Button
            name="lock-unlock-obrazac-btn"
            onClick={() => handleLockUnlockObrazac(record.obrazac_id, record.godina)}
          >
            {zakljucavanjeService.returnIsLocked(zakljucavanjeStore.showOtkljucano) ? (
              <UnlockOutlined rev={undefined} />
            ) : (
              <LockOutlined rev={undefined} />
            )}
          </Button>
        );
      },
    },
  ];

  return (
    <TableData
      columns={columns}
      name="zakljucavanje-tip-obrasca-table"
      dataSource={zakljucavanjeStore.obrasciZaZakljucavanje}
      forceFetch={[filtersStore.filters.zakljucavanjeObrasci]}
      getData={refetchData}
      withPagination
    />
  );
});
