import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { NewKlasifikacijeObrazac5Form } from '../../Obrazac5MicroServices/components/NewKlasifikacijaObrazac5Form/NewKlasifikacijaObrazac5Form/NewKlasifikacijeObrazac5Form';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';

export const Obrazac5KlasifikacijaWizardTab: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  return (
    <InsideContentWrapper header={null}>
      <NewKlasifikacijeObrazac5Form form={form} />
    </InsideContentWrapper>
  );
});
