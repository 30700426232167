import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrazac5Store } from 'modules/obrasci/store/obrazac5/obrazac5.store';
import { IGetUpravljanjeObrazac5 } from 'modules/obrasci/store/obrazac5/obrazac5.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const TableUpravljanjeObrazac5: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetUpravljanjeObrazac5> = [
    {
      title: `${t('OBRASCI.OBRAZAC5.KOLICINA_NA_SKLADISTENJE')}`,
      width: '200px',
      dataIndex: 'kolicina_isporucenog_otpada_za_skladiste_otpada',
      key: 'kolicina_isporucenog_otpada_za_skladiste_otpada',
    },
    {
      title: `${t('OBRASCI.OBRAZAC5.KOLICINA_NA_ODLAGANJE')}`,
      width: '200px',
      dataIndex: 'kolicina_isporucenog_otpada_za_odlaganje',
      key: 'kolicina_isporucenog_otpada_za_odlaganje',
    },
    {
      title: `${t('OBRASCI.OBRAZAC5.KOLICINA_NA_PONOVNO_ISKORISCENJE')}`,
      dataIndex: 'kolicina_isporucenog_otpada_za_ponovno_iskoriscenje',
      key: 'kolicina_isporucenog_otpada_za_ponovno_iskoriscenje',
      width: '250px',
    },
    {
      title: `${t('OBRASCI.OBRAZAC5.KOLICINA_IZVEZENOG_OTPADA')}`,
      dataIndex: 'kolicina_izvezenog_otpada',
      key: 'kolicina_izvezenog_otpada',
      width: '200px',
    },

    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (_, record: IGetUpravljanjeObrazac5) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-kolicine'}
            onClick={() => {
              obrazac5Store.handleChange('upravljanje_form', record);
              modalStore.changeModalName('obrazac5-upravljanje-modal');
            }}
          />
        );
      },
    },
  ];
  // todo: check if backend return whole array or we have to do it with pagination
  return (
    <TableData
      scrollConfig={['#obrazacTableHeader', '#obrazacWizardHeader']}
      name="upravljanje-obrazac5"
      columns={baseColumns}
      dataSource={[...obrazac5Store.initialState.upravljanje_otpadom]}
    />
  );
});
