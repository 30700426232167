import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { NewKolicineObrazac5Form } from '../../Obrazac5MicroServices/components/NewKolicineObrazac5Form/NewKolicineObrazac5Form';

export const Obrazac5KolicineWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={null}>
      <NewKolicineObrazac5Form />
    </InsideContentWrapper>
  );
});
