import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { useForm } from 'antd/es/form/Form';
import { FormInputProps, IFilterObjectProps, IFilterTableProps } from 'typescript/NrizTypes';
import { filtersStore } from 'store/filteri.store';
import { IS_ACTIVE_SWITCH } from 'modules/sifarnici/sifarniciFormObj.constants';
export const CompaniesListFilters: React.FC = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => filtersStore.setFilters('preduzeca', {});
  }, []);

  const [companyForm] = useForm();

  const companiesFilterOptions = [
    {
      label: t('COMPANY.NAME'),
      name: 'naziv',
      type_of_form: 'input',
      inputName: 'naziv',
      sortName: 'naziv',
    } as FormInputProps & IFilterObjectProps,
    {
      label: t('COMPANY.PIB'),
      name: 'pib',
      type_of_form: 'input',
      inputName: 'pib',
      sortName: 'pib',
    } as FormInputProps & IFilterObjectProps,
    {
      label: t('COMPANY.ENTERPRISE_ID_NUMBER'),
      name: 'maticni_broj',
      type_of_form: 'input',
      inputName: 'maticni_broj',
      sortName: 'maticni_broj',
    } as FormInputProps & IFilterObjectProps,
    {
      type_of_form: 'switch',
      label: 'U pripremi',
      name: 'u_pripremi',
    },
    IS_ACTIVE_SWITCH,
  ];

  const data = {
    form: companyForm,
    initialState: { naziv_preduzeca: '', pib: '', maticni_broj: '', order_by: '', u_pripremi: false, is_active: '' },
    filterName: 'preduzeca',
    filterOptions: companiesFilterOptions,
  } as IFilterTableProps;

  return (
    <div id={'companyFilters'}>
      <FilterTableForm data={data} />
    </div>
  );
});
