import { Modal } from 'components/Modal/Modal';
import { NewUserForm } from 'components/NewForms';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import styles from './UserModal.module.scss';

export const UserModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Modal
      name="user-modal"
      open={modalStore.getCurrentOpenedModal.includes('user-modal')}
      className={`.and-modal-wrap .ant-modal ${styles.user_modal}`}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => modalStore.removeAllModals()}
      title={t('USER.TITLE')}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
    >
      <NewUserForm readOnly={false} showButtons={true} />
    </Modal>
  );
});
