import { FormInstance } from 'antd';

export const resetFormFields = (form: FormInstance<any> | any, array: any[]) => {
  const fields: { [key: string]: any } = form.getFieldsValue();
  const fieldsKeys: string[] = Object.keys(fields);
  const arrayFormFields: { [key: string]: any } = form.getFieldsValue(array);

  const updatedFields: { [key: string]: any } = array.reduce((acc, fieldName) => {
    if (!fieldsKeys.includes(fieldName)) {
      if (arrayFormFields[fieldName] === null) {
        acc[fieldName] = null;
      }
      if (typeof arrayFormFields[fieldName] === 'boolean') {
        acc[fieldName] = false;
      }
      if (typeof arrayFormFields[fieldName] === 'string') {
        acc[fieldName] = '';
      }
      if (typeof arrayFormFields[fieldName] === 'number') {
        acc[fieldName] = null;
      }
      if (typeof arrayFormFields[fieldName] === 'object' && arrayFormFields[fieldName] !== null) {
        acc[fieldName] = {};
      }
    }
    return acc;
  }, {} as { [key: string]: any });
  const newForm = { ...fields, ...updatedFields };

  form.setFieldsValue(newForm);
  return newForm;
};
