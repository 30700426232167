import { Col, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { zakljucavanjeStore } from 'modules/zakljucavanje/zakljucavanje.store';
import { useTranslation } from 'react-i18next';
import styles from './ZakljucavanjePoGodiniHeader.module.scss';
import { Button } from 'components/Button/Button';
import dayjs from 'dayjs';

export const ZakljucavanjePoGodiniHeader = observer(() => {
  const { t } = useTranslation();

  const godineOptions = zakljucavanjeStore.godine.map(godina => {
    return { value: godina.godina, key: godina.godina };
  });

  const onSelectChange = async (godina: number) => {
    zakljucavanjeStore.setSelectedGodina({ godina });
  };

  const otkljucavanjeGodineDisabled = () => {
    const currentYear = dayjs().year();
    const highestAvailableYear = Math.max(...zakljucavanjeStore.godine.map(godina => godina.godina));
    return !(currentYear >= highestAvailableYear);
  };

  return (
    <Row className={styles.poGodiniHeader}>
      <Col span={13}>
        <h1>{t('ZAKLJUCAVANJE_OBRAZACA.PO_GODINI')}</h1>
      </Col>
      <Col>
        <Button
          name={'otkljucavanje-godine'}
          onClick={zakljucavanjeStore.otkljucajGodinu}
          disabled={otkljucavanjeGodineDisabled()}
        >
          Otkljucaj sledecu godinu
        </Button>
      </Col>
      <Col span={5}>
        <Select options={godineOptions} onChange={onSelectChange} placeholder={'Izaberite godinu'} />
      </Col>
    </Row>
  );
});
