import { ColumnsType } from 'antd/es/table';
import { IPostAktivnost2 } from './aktivnost2.types';
import { IGetActivity2 } from 'modules/activityList2/activityList2.types';
import { FormInstance } from 'antd';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { t } from 'i18next';
import { CODE_2_TABLE, CODE_TABLE, NAME_SR_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { CODE_2_FORM, CODE_FORM, NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
export const AKTIVNOST_2_INITIAL_STATE: IPostAktivnost2 = {
  code: '',
  name: '',
  name_sr: '',
  minimumthresholdofcapacity: '',
  minimumthresholdofcapacity_sr: '',
  a_level: null,
  code2: '',
  codeofgroup: '',
  is_active: true,
};

export const AKTIVNOST_2_TABLE_DATA: ColumnsType<IGetActivity2> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  CODE_TABLE,

  // todo: prevod
  {
    title: 'Minimalna granična vrednost kapaciteta ',
    width: '200px',
    dataIndex: 'minimumthresholdofcapacity',
    key: 'minimumthresholdofcapacity',
  },
  {
    title: 'Minimalna granična vrednost kapaciteta (srp.)',
    width: '200px',
    dataIndex: 'minimumthresholdofcapacity_sr',
    key: 'minimumthresholdofcapacity_sr',
  },
  {
    title: 'Nivo a',
    width: '100px',
    dataIndex: 'a_level',
    key: 'a_level',
  },
  CODE_2_TABLE,
  {
    title: 'Kod grupe',
    width: '100px',
    dataIndex: 'codeofgroup',
    key: 'codeofgroup',
  },
];

export const AKTIVNOST_2_FORM_OPTIONS = (_: FormInstance<IGetActivity2>) => {
  return [
    NAME_FORM,
    NAME_SR_FORM,
    CODE_FORM,
    {
      type_of_form: 'input',
      name: 'minimumthresholdofcapacity',
      // todo: prevod
      label: t('Minimalna granična vrednost kapaciteta '),
      inputName: 'minimumthresholdofcapacity',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'minimumthresholdofcapacity_sr',
      // todo: prevod
      label: t('Minimalna granična vrednost kapaciteta (srp.)'),
      inputName: 'minimumthresholdofcapacity_sr',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input_number',
      name: 'a_level',
      // todo: prevod
      label: t('Nivo a'),
      inputName: 'a_level',
    } as InputSifarniciFormProps,
    CODE_2_FORM,
    {
      type_of_form: 'input',
      name: 'codeofgroup',
      // todo: prevod
      label: t('Kod grupe'),
      inputName: 'codeofgroup',
    } as InputSifarniciFormProps,
  ];
};
