import { Row, Select, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Content } from 'components/Content/Content';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { izvestajiService } from 'modules/izvestaji/izvestaji.service';
import { nrizService } from 'modules/nriz/nriz.service';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { izvestajiStore } from 'modules/izvestaji/izvestaji.store';
import { Button } from 'components/Button/Button';
import { IGetIzvestaj } from 'modules/izvestaji/izvestaji.types';
import style from './Izvestaji.module.scss';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import { IzvestajiModal } from './IzvestajiModal/IzvestajiModal';
import { modalStore } from 'store';

export const IzvestajiHeader = observer(() => {
  return (
    <Row justify={'space-between'}>
      <h1>{'Izveštaji'}</h1>
    </Row>
  );
});

export const SelectObrasci = observer(() => {
  const { t } = useTranslation();

  // const obrasciOption = izvestajiStore.tipoviObrazaca.map(tipO => ({
  //   label: `${tipO.skraceni_naziv} - Prijava`,
  //   value: tipO.skraceni_naziv,
  //   disabled: !!(tipO.skraceni_naziv === TIPOVI_OBRAZACA.VOC),
  // }));

  const izvestajiOptions = [
    {
      label: `PTP2 - Prijava`,
      value: TIPOVI_OBRAZACA.PTP2,
    },
  ];
  // todo: prevedi
  return (
    <Row justify={'space-between'} align={'middle'}>
      <Space align="baseline">
        <h3>{'Tip obrasca:'}</h3>
        <Select
          showSearch
          style={{ minWidth: '300px' }}
          placeholder={t('OBRASCI.PLACEHOLDER.CHOOSE_TIP_OBRASCA')}
          optionFilterProp="children"
          onChange={(e: string) => {
            izvestajiStore.handleChange('izvestaj_type', e);
          }}
          value={izvestajiStore.izvestaj_type}
          filterOption={nrizService.handleSearchOptions}
          options={izvestajiOptions}
        ></Select>
      </Space>
      <Space>
        <div className={style.presekButton}>
          <Button
            name={''}
            onClick={() => izvestajiStore.generisiIzvestaj()}
            disabled={!!izvestajiStore.izvestajiList.length}
          >
            Napravi Presek
          </Button>
        </div>
        {izvestajiStore.zaIskljucitiList.length ? (
          <Button name={''} onClick={() => modalStore.changeModalName('izvestaji-modal')}>
            Pregledaj isključene
          </Button>
        ) : (
          <Button name={''} onClick={() => izvestajiStore.posaljiIzvestaje()}>
            Pošalji
          </Button>
        )}
      </Space>
    </Row>
  );
});

export const Izvestaji = observer(() => {
  const { t } = useTranslation();
  t;
  const [columnsIzvestaji, setColumnsIzvestaji] = useState<ColumnsType<any>>([]);

  useEffect(() => {
    setColumnsIzvestaji([
      ...izvestajiService.tableColumnsIzvestajFinder(izvestajiStore.izvestaj_type),
      {
        title: 'Isključivanje',
        width: 60,
        key: 'iskljucivanje',
        fixed: 'right',
        align: 'center',
        render(_, record: IGetIzvestaj) {
          return (
            <Checkbox
              className={style.checkbox}
              defaultChecked={record.iskljucen}
              disabled={izvestajiStore.isRowDisabled(record)}
              onChange={(event: CheckboxChangeEvent) => {
                izvestajiStore.handleIzvestajzaIskljuciti(event.target.checked, record);
              }}
              name={'zaIskljucitii_checkbox'}
            />
          );
        },
      },
    ]);
  }, [izvestajiStore.izvestaj_type, izvestajiStore.izvestajiList]);

  useEffect(() => {
    return () => izvestajiStore.handleChange('zaIskljucitiList', []);
  }, []);

  return (
    <Content header={<IzvestajiHeader />}>
      <SelectObrasci />
      <TableData
        name="izvestaji"
        columns={[...columnsIzvestaji]}
        dataSource={izvestajiStore.izvestajiList}
        getData={izvestajiStore.fetchListIzvestaj}
        withPagination={true}
        forceFetch={[izvestajiStore.izvestaj_type, izvestajiStore.newListFlag]}
        rowClassName={(record: IGetIzvestaj) => (izvestajiStore.isRowDisabled(record) ? 'disabled-row' : '')}
      />
      <IzvestajiModal />
    </Content>
  );
});
