import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { GveEmisijaMerenjaTable } from './components/TableEmisijaMerenjaGve/TableEmisijaMerenjaGve';
import { GveTableEmisijaBilans } from './components/TableEmisijaBilansGve/TableEmisijaBilans';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { useTranslation } from 'react-i18next';
import { GveGodisnjiPitanjeModal } from '../../GveMicroServices/components/GveGodisnjiBilansModal/GveGodisnjiPitanjeModal';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { GveEmisijeUVazduhRadio } from './components/GveEmisijeUVazduhRadio/GveEmisijeUVazduhRadio';

export const GveEmisijeUVazduhWizardTab: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  const form = props.form;

  return (
    <>
      {gveStore.showEmisijeUVazduh && (
        <>
          <InsideContentWrapper>
            <h3>{t('OBRASCI.GVE.MERENJE_NASLOV')}</h3>
          </InsideContentWrapper>
          <InsideContentWrapper
            header={
              <WizardObrazacListHeader
                name={'gve-merenje-form'}
                modalName="gve-merenje-modal"
                onAddMessage={
                  gveStore.initialState.kreiranje_godisnjeg_bilansa
                    ? 'Nakon promene liste merenja, podaci godišnjeg bilansa se rekalkulišu. Prethodno generisani bilans se briše.'
                    : ''
                }
              />
            }
          >
            <GveEmisijaMerenjaTable />
          </InsideContentWrapper>
          <InsideContentWrapper>
            <GveEmisijeUVazduhRadio />
          </InsideContentWrapper>
          <GveGodisnjiPitanjeModal form={form} />
        </>
      )}

      {(gveStore.initialState.kreiranje_godisnjeg_bilansa || !gveStore.showEmisijeUVazduh) && (
        <>
          <InsideContentWrapper>
            <h3>{t('OBRASCI.GVE.GODISNJI_NASLOV')}</h3>
            <h3>{t('OBRASCI.GVE.REKALKULACIJA_BILANSA')}</h3>
          </InsideContentWrapper>

          <InsideContentWrapper
            header={
              !gveStore.showEmisijeUVazduh && (
                <WizardObrazacListHeader name={'gve-bilans-form'} modalName="gve-bilans-modal" />
              )
            }
          >
            <GveTableEmisijaBilans />
          </InsideContentWrapper>
        </>
      )}
    </>
  );
});
