import { CheckboxOptionType, Form, Radio } from 'antd';
import { Popconfirm } from 'components/Popconfirm/Popconfirm';
import { observer } from 'mobx-react-lite';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const GveEmisijeUVazduhRadio = observer(() => {
  const { t } = useTranslation();

  const form = Form.useFormInstance();

  const [popConfirmActivated, setPopConfirmActivated] = useState(false);

  interface GodisnjiCheckboxOptions extends CheckboxOptionType {
    popconfirm?: boolean;
    checked?: boolean;
    key: number | string;
  }

  const godisnjiOptions: GodisnjiCheckboxOptions[] = [
    {
      value: false,
      label: t('OBRASCI.OBRAZAC3.GODISNJI_NE'),
      popconfirm: !!gveStore.initialState.kreiranje_godisnjeg_bilansa,
      checked: gveStore.initialState.kreiranje_godisnjeg_bilansa === false,
      key: Math.random(),
    },
    {
      value: true,
      label: t('OBRASCI.OBRAZAC3.GODISNJI_DA'),
      disabled: gveStore.initialState.emisije_u_vazduh.length === 0 || popConfirmActivated,
      checked: gveStore.initialState.kreiranje_godisnjeg_bilansa === true,
      key: Math.random(),
    },
  ];

  const onGodisnjiOptionChange = (value: boolean) => {
    gveStore.handleInitialStateChange('kreiranje_godisnjeg_bilansa', value);
    form.setFieldValue('kreiranje_godisnjeg_bilansa', value);
    if (value) modalStore.changeModalName('gve-godisnji-bilans-modal');
    else gveStore.handleInitialStateChange('bilans', []);
    // form.setFieldValue('ukupan_broj_radnih_sati_godisnje', null);
  };

  const radioGodisnjiOptions = godisnjiOptions.map(option => {
    return option.popconfirm ? (
      <Popconfirm
        key={option.key}
        name="kreiranje_godisnjeg_bilansa"
        title={t('OBRASCI.GVE.BILANS_PITANJE')}
        onConfirm={() => {
          onGodisnjiOptionChange(false);
          setPopConfirmActivated(false);
        }}
        onCancel={() => {
          form.setFieldValue('kreiranje_godisnjeg_bilansa', true);
          setPopConfirmActivated(false);
        }}
        onOpenChange={open => {
          if (open) setPopConfirmActivated(true);
        }}
        open={popConfirmActivated}
      >
        <Radio value={option.value} disabled={option.disabled} checked={option.checked}>
          {option.label}
        </Radio>
      </Popconfirm>
    ) : (
      <Radio
        value={option.value}
        disabled={option.disabled}
        onChange={event => onGodisnjiOptionChange(event.target.value)}
        checked={option.checked}
        key={option.key}
      >
        {option.label}
      </Radio>
    );
  });
  return (
    <Form.Item
      label={t('OBRASCI.OBRAZAC3.GODISNJI_PITANJE')}
      name={'kreiranje_godisnjeg_bilansa'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    >
      <Radio.Group value={gveStore.initialState.kreiranje_godisnjeg_bilansa}>{radioGodisnjiOptions}</Radio.Group>
    </Form.Item>
  );
});
