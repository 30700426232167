import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { NewGveIzmereneVrednostiForm } from './NewGveIzmereneVrednostiForm/NewGveIzmereneVrednostiForm';
import { GVE_IZMERENE_VREDNOSTII_INITIAL_STATE } from 'modules/obrasci/store/gve/gve.constants';

export const GveIzmereneVrednostiModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  return (
    <CRUDModal
      width="60%"
      name="gve-izmerene-vrednosti-modal"
      title={t('OBRASCI.GVE.MERENJA_VAZDUH')}
      store={gveStore}
      data={gveStore.getIzmereneVrednostiForm}
      children={({ form }) => <NewGveIzmereneVrednostiForm form={form} />}
      submitForm={gveStore.submitIzmereneVrednostiForm}
      initialState={GVE_IZMERENE_VREDNOSTII_INITIAL_STATE}
      dataToChange={'izmerene_vrednosti_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      closeOneModal={true}
    />
  );
});
