import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { TableGio3Kolicine } from './components/TableGio3Kolicine/TableGio3Kolicine';

export const Gio3KolicineWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'gio3-kolicine-form'} modalName="gio3-kolicine-modal" />}
    >
      <TableGio3Kolicine />
    </InsideContentWrapper>
  );
});
