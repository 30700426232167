import { ApiResponse, axios } from 'modules/axios';
import queryString from 'query-string';
import { IPaginatedResponse } from 'typescript/NrizTypes';

const fetchIstorijaPromenaList = <T = any>(filters: any): ApiResponse<IPaginatedResponse<T>> => {
  const query = queryString.stringify(filters);
  return axios.get(`audit-log/?${query}`);
};
export const istorijaPromenaRepo = {
  fetchIstorijaPromenaList,
};
