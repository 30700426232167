import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { IGetAir, IPostAir, ResponseAirList } from './air.types';

const fetchAirList = (payload: any): ApiResponse<ResponseAirList> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.AIR}?${query}`);
};

const postAir = (payload: IPostAir): ApiResponse<any> => {
  return axios.post(`${ROUTES.AIR}`, payload);
};

const putAir = (payload: IGetAir): ApiResponse<any> => {
  return axios.put(`${ROUTES.AIR}${payload.id}`, payload);
};

const deleteAir = (id: string): ApiResponse<any> => {
  return axios.delete(`${ROUTES.AIR}${id}`);
};

const aktivirajVazduh = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.AIR}${id}/aktiviraj`);
};
const deaktivirajVazduh = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.AIR}${id}/deaktiviraj`);
};

export const airRepo = {
  postAir,
  putAir,
  deleteAir,
  fetchAirList,
  aktivirajVazduh,
  deaktivirajVazduh,
};
