import { Row } from 'antd';
import React from 'react';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const EmisijeUTloHeader: React.FC<any> = () => {
  const { t } = useTranslation();

  return (
    <Row justify={'space-between'} align={'bottom'}>
      <h3>{t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.TITLE')}</h3>
      <AddNewButton
        name={t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.TITLE')}
        onButtonClick={() => {
          modalStore.changeModalName(`emisije-u-tlo-modal`);
        }}
      />
    </Row>
  );
};
