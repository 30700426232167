import { observer } from 'mobx-react-lite';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { TableProizvodi } from './components/TableProizvodi/TableProizvodi';
import { Row } from 'antd';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { modalStore } from 'store';

export const HeaderProizvodi: React.FC = observer(() => {
  return (
    <Row justify={'end'} align={'middle'} id="ptp2ProizvodiTabelHeader">
      <AddNewButton
        name="proizvodi-form"
        onButtonClick={() => {
          modalStore.changeModalName('proizvodi-modal');
        }}
      />
    </Row>
  );
});

export const ProizvodiWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<HeaderProizvodi />}>
      <TableProizvodi />
    </InsideContentWrapper>
  );
});
