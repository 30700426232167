import { ColumnsType } from 'antd/es/table';
import { EditButton } from 'components/Buttons/EditButton/EditButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { authStore } from 'store';
import { IGetFacility } from 'modules/facility/facility.types';

import { filtersStore } from 'store/filteri.store';
import { facilityStore } from 'modules/facility/facility.store';
import { UserRoles } from 'modules/user/user.types';
import { facilityFormInitialState } from 'modules/facility/facility.constants';

export const FacilityListTable: React.FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns: ColumnsType<IGetFacility> = [
    {
      title: `${t('FACILITY.NAME')}`,
      dataIndex: 'naziv_postrojenja',
      width: '200px',
      key: 'naziv_postrojenja',
      fixed: 'left',
    },
    {
      title: `${t('COMPANY.LOCATION')}`,
      dataIndex: ['mesto', 'display_name'],
      width: '200px',
      key: 'mesto',
    },
    {
      title: `${t('COMPANY.ADDRESS')}`,
      dataIndex: 'ulica',
      key: 'ulica',
      width: '200px',
    },
    {
      title: `${t('COMPANY.ADDRESS_NUMBER')}`,
      dataIndex: 'kucni_broj',
      key: 'kucni_broj',
      width: '100px',
    },
    {
      title: `${t('COMPANY.PHONE')}`,
      dataIndex: 'telefon',
      key: 'telefon',
      width: '150px',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: '50px',
      render: (_: any, record: IGetFacility) => {
        return (
          <EditButton
            key={record.id}
            onButtonClick={() => {
              facilityStore.setFacility(facilityFormInitialState);
              navigate(`/facility/${record.id}`);
            }}
            name={'go-to-facility'}
          />
        );
      },
    },
  ];

  return (
    <TableData
      name="facility"
      columns={columns}
      dataSource={facilityStore.getFacilityList}
      getData={
        authStore.getUserRole === UserRoles.CompanyUser
          ? facilityStore.fetchFacilityListByUser
          : facilityStore.fetchFacilityCompanyList
      }
      withPagination={true}
      forceFetch={[filtersStore.filters.postrojenja]}
    />
  );
});
