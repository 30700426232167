import { observer } from 'mobx-react-lite';
import React from 'react';
import { NaseljeModal } from './NaseljeModal/NaseljeDep1Modal';

export const Dep1MicroServices: React.FC = observer(() => {
  return (
    <>
      <NaseljeModal />
    </>
  );
});
