import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { OBRAZAC_3_RECIPIJENT_MERENJE_INITIAL_STATE } from 'modules/obrasci/store/obrazac3/obrazac3.constants';
import { NewObrazac3AnalizaRecipijenataMerenjeForm } from './NewObrazac3AnalizaRecipijenataMerenjeForm/NewObrazac3AnalizaRecipijenataMerenjeForm';

export const Obrazac3AnalizaRecipijenataMerenjeModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="70%"
      name="obrazac3-analiza-recipijenta-merenje-modal"
      title={t('OBRASCI.GVE.MERENJA')}
      store={obrazac3Store}
      data={obrazac3Store.getAnalizaMerenjeRecipijenta}
      children={() => <NewObrazac3AnalizaRecipijenataMerenjeForm />}
      submitForm={obrazac3Store.submitMerenjaRecipijenta}
      initialState={OBRAZAC_3_RECIPIJENT_MERENJE_INITIAL_STATE}
      dataToChange={'recipijent_merenje_form'}
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
    />
  );
});
