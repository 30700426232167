import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { useTranslation } from 'react-i18next';
import { NewGio2DozvoleForm } from './components/NewGio2DozvoleForm/NewGio2DozvoleForm';
import { GIO_2_DOZVOLE_INITIAL_STATE } from 'modules/obrasci/store/gio2/gio2.constants';

export const Gio2DozvoleModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  return (
    <CRUDModal
      width="40%"
      name="gio2-dozvole-modal"
      title={t('WIZARD_TABS.DOZVOLE')}
      store={gio2ObrazacStore}
      data={gio2ObrazacStore.getDozvoleForm}
      children={() => <NewGio2DozvoleForm />}
      submitForm={gio2ObrazacStore.submitDozvoleForm}
      initialState={GIO_2_DOZVOLE_INITIAL_STATE}
      dataToChange={'dozvole_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    />
  );
});
