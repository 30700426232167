import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewDivljeDeponijeForm } from './components/NewDivljeDeponijeForm/NewDivljeDeponijeForm';
import { observer } from 'mobx-react-lite';
import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { dep2ObrazacStore } from 'modules/obrasci/store/dep2/dep2.store';
import { DIVLJA_DEPONIJA_INITIAL_STATE } from 'modules/obrasci/store/dep2/dep2.constants';

export const DivljaDeponijaModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="60%"
      name="divlja-deponija-modal"
      title={t('OBRASCI.DEP_2.TITLE')}
      store={dep2ObrazacStore}
      data={dep2ObrazacStore.getDivljaDeponija}
      children={() => <NewDivljeDeponijeForm />}
      submitForm={dep2ObrazacStore.submitDivljaDeponijaForm}
      initialState={DIVLJA_DEPONIJA_INITIAL_STATE}
      dataToChange={'divlja_deponija'}
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 8 }}
    />
  );
});
