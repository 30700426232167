import { observer } from 'mobx-react-lite';

import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { useEffect } from 'react';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import styles from './Gio3OpsteWizardTab.module.scss';
import { Col } from 'antd';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { gio3ObrazacStore } from 'modules/obrasci/store/gio3/gio3.store';

export const Gio3OpsteWizardTab: React.FC<ICrudTypeModalProps> = observer(() => {
  useEffect(() => {
    if (
      obrasciStore.opste.godina &&
      obrasciStore.opste.preduzece_id !== '' &&
      obrasciStore.opste.postrojenje_id !== ''
    ) {
      obrasciStore.setDisabledTabButton(false);
    }
  }, [obrasciStore.opste]);

  return (
    <Col span={12} className={styles.wrapper}>
      <OpsteGodinaPreduzecePostrojenje withPostrojenje={true} store={gio3ObrazacStore} />
    </Col>
  );
});
