import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { IGetSoil, IPostSoil, RequestSoilList, ResponseSoil } from './soil.types';

const fetchSoilList = (payload: RequestSoilList): ApiResponse<ResponseSoil> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.SOIL}?${query}`);
};

const postSoil = (payload: IPostSoil): ApiResponse<any> => {
  return axios.post(`${ROUTES.SOIL}`, payload);
};
const putSoil = (payload: IGetSoil): ApiResponse<any> => {
  return axios.put(`${ROUTES.SOIL}${payload.id}`, payload);
};

const deleteSoil = (id: string): ApiResponse<any> => {
  return axios.delete(`${ROUTES.SOIL}${id}`);
};

const aktivirajTlo = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.SOIL}${id}/aktiviraj`);
};
const deaktivirajTlo = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.SOIL}${id}/deaktiviraj`);
};

export const soilRepo = {
  postSoil,
  putSoil,
  deleteSoil,
  fetchSoilList,
  aktivirajTlo,
  deaktivirajTlo,
};
