import { unsanitaryLandfillRepo } from './unsanitarylandfill.repo';
import { IGetUnsanitaryLandfill, IPostUnsanitaryLandfill, RequestUnsanitaryLandfill } from './unsanitarylandfill.types';

class UnsanitaryLandfillService {
  fetchUnsanitaryLandfillList = (payload: RequestUnsanitaryLandfill) => {
    return unsanitaryLandfillRepo.fetchUnsanitaryLandfillList(payload);
  };

  postUnsanitaryLandfill = (unsanitaryLandfill: IPostUnsanitaryLandfill) => {
    return unsanitaryLandfillRepo.postUnsanitaryLandfill(unsanitaryLandfill);
  };
  putUnsanitaryLandfill = (unsanitaryLandfill: IGetUnsanitaryLandfill) => {
    return unsanitaryLandfillRepo.putUnsanitaryLandfill(unsanitaryLandfill);
  };
  deleteUnsanitaryLandfill = (id: string) => {
    return unsanitaryLandfillRepo.deleteUnsanitaryLandfill(id);
  };

  aktivirajNeasnitarnuDeponiju = (id: string) => {
    return unsanitaryLandfillRepo.aktivirajNeasnitarnuDeponiju(id);
  };
  deaktivirajNeasnitarnuDeponiju = (id: string) => {
    return unsanitaryLandfillRepo.deaktivirajNeasnitarnuDeponiju(id);
  };
}

export const unsanitaryLandfillService = new UnsanitaryLandfillService();
