import { t } from 'i18next';
import { IGetOperatorsPackageWasteManage, IPostOperatorsPackageWasteManage } from './operatorsPackageWasteManage.types';
import { FormInstance } from 'antd';
import {
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';

export const OPERATORS_PACKAGE_WASTE_MANAGE_INITIAL_STATE: IPostOperatorsPackageWasteManage = {
  company_id: '',
  permit_number: '',
  is_active: true,
};

export const OPERATORS_PACKAGE_WASTE_MANAGE_TABLE_DATA = [
  {
    title: `${t('COMPANY.TITLE')}`,
    width: '500px',
    dataIndex: ['preduzece', 'naziv'],
    key: 'company_id',
  },
  {
    title: `${t('OBRASCI.AAO1.PACKAGING_AMOUNT.PERMIT_NUMBER')}`,
    width: '100px',
    dataIndex: 'permit_number',
    key: 'permit_number',
  },
];

export const OPERATORS_PACKAGE_WASTE_MANAGE_FORM_OPTIONS = (form: FormInstance<IGetOperatorsPackageWasteManage>) => {
  form;
  return [
    {
      type_of_form: 'searchScroll',
      label: t('COMPANY.TITLE'),
      formName: 'company_id',
      // placeholder={t('COMPANY.TITLE')}
      fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE,
      filtersForFetch: {},
      labelOptionAccessor: ['naziv'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      objName: 'preduzece',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('OBRASCI.AAO1.PACKAGING_AMOUNT.PERMIT_NUMBER'),
      // placeholder={t('OBRASCI.AAO1.PACKAGING_AMOUNT.PERMIT_NUMBER')}
      name: 'permit_number',
      inputName: 'permit_number',
    } as InputSifarniciFormProps,
  ];
};
