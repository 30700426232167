import to from 'await-to-js';
import { action, makeAutoObservable, observable } from 'mobx';
import { nrizService } from 'modules/nriz/nriz.service';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { IPagination } from 'typescript';
import { IGetIzvestaj, ResponseIzvestaj } from './izvestaji.types';
import { izvestajiService } from './izvestaji.service';
import { arrayToObject } from 'utils/arrayToObject';

class IzvestajiStore {
  izvestaj_type: TIPOVI_OBRAZACA = TIPOVI_OBRAZACA.PTP2;
  filters: any = {};
  izvestajiList: IGetIzvestaj[] = [];
  zaIskljucitiList: IGetIzvestaj[] = [];
  newListFlag = false;

  constructor() {
    makeAutoObservable(this, {
      izvestaj_type: observable,
      izvestajiList: observable,
      zaIskljucitiList: observable,
      filters: observable,
      newListFlag: observable,

      handleChange: action,
      handleIzvestajzaIskljuciti: action,
    });
  }

  handleChange(key: keyof IzvestajiStore, value: any) {
    (this as any)[key] = value;
  }

  handleFiltersIzvestaji(type: string, value: any) {
    this.filters = { ...this.filters, [type]: value };
  }

  handleIzvestajzaIskljuciti = (checkboxValue: boolean, izvestaj: IGetIzvestaj) => {
    const newList = this.izvestajiList.map(record => {
      if (izvestaj.id === record.id) return { ...record, iskljucen: checkboxValue };
      return record;
    });
    this.handleChange('izvestajiList', newList);
    if (checkboxValue) {
      this.handleChange('zaIskljucitiList', [...this.zaIskljucitiList, { ...izvestaj, iskljucen: checkboxValue }]);
    } else {
      const newzaIskljucitii = nrizService.removeFromList(this.zaIskljucitiList, izvestaj.id);
      this.handleChange('zaIskljucitiList', newzaIskljucitii);
    }
  };

  isRowDisabled = (record: IGetIzvestaj) => {
    const izvestajZaIskljucivanje = this.zaIskljucitiList.some(izvestaj => izvestaj.id === record.id);
    return record.iskljucen && !izvestajZaIskljucivanje;
  };

  iskljuciIzvestaje = async () => {
    const iskljuciIdArray = this.zaIskljucitiList.map(zaIskljucitii => zaIskljucitii.id);
    if (this.izvestaj_type) {
      const [err, res] = await to(izvestajiService.iskljuciIzvestaje(this.izvestaj_type, { ids: iskljuciIdArray }));
      if (err || !res) return;
    }
  };

  posaljiIzvestaje = async () => {
    if (this.izvestaj_type) {
      const [err, res] = await to(izvestajiService.posaljiIzvestaje(this.izvestaj_type));
      if (err || !res) return;
      this.handleChange('izvestajiList', []);
    }
  };

  generisiIzvestaj = async () => {
    const [err, res] = await to(izvestajiService.generisiIzvestaj(this.izvestaj_type));
    if (err || !res) return;
    this.handleChange('newListFlag', !this.newListFlag);
  };

  fetchListIzvestaj = async (pagination: IPagination) => {
    const [err, res] = await to(
      izvestajiService.fetchIzvestaji<ResponseIzvestaj>(this.izvestaj_type, {
        ...this.filters,
        page: pagination.page,
        size: pagination.size,
      })
    );
    if (err || !res) return;
    const zaIskljucitiId = arrayToObject(this.zaIskljucitiList);
    const newResponse = res.items.map(izvestaj => {
      if (zaIskljucitiId[izvestaj.id]) return { ...izvestaj, iskljucen: true };
      return izvestaj;
    });
    this.handleChange('izvestajiList', newResponse);
    return { ...res, items: newResponse };
  };
}

export const izvestajiStore = new IzvestajiStore();
