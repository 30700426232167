import { useTranslation } from 'react-i18next';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import Col from 'antd/lib/grid/col';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';
import styles from './Gio4NapomenaWizardTab.module.scss';

export const Gio4NapomenaWizardTab = () => {
  const { t } = useTranslation();
  return (
    <Col span={24} className={styles.napomena}>
      <FormTextArea
        label={t('WIZARD_TABS.NAPOMENA')}
        name={'napomena'}
        rows={4}
        labelCol={3}
        wrapperCol={21}
        rules={[MAX_LENGTH_RULE(3000)]}
      />
    </Col>
  );
};
