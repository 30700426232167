import { IGetCompany } from 'modules/company/company.types';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetPollutantCode } from 'modules/sifarnici/store/pollutantCode/pollutantCode.types';
import { IGetWater } from 'modules/sourcesOfPollution/water/water.types';

export interface IOTVRegistarZagadjenje {
  naziv_zagadjujuce_materije_id: string;
  naziv_zagadjujuce_materije: IGetPollutantCode;
  jedinica_mere: OTVJediniceMere | '';
  granicna_vrednost: number | null;
  id: string;
}

export interface IOTVRegistar {
  id: string;
  preduzece_id: string;
  preduzece: IGetCompany;
  postrojenje_id: string;
  postrojenje: IGetFacility;
  izvor_zagadjenja_vode_id: string;
  izvor_zagadjenja_vode: IGetWater;
  otv_registar_zagadjenje: IOTVRegistarZagadjenje[];
}

export enum OTVJediniceMere {
  mg_l = 'mg/l',
  g_t = 'g/t',
  kg_t = 'kg/t',
}

export interface OtvOpsteInfo {
  preduzece_id: string;
  postrojenje_id: string;
  izvor_zagadjenja_vode_id: string;
}
