import { action, computed, makeObservable, observable } from 'mobx';
import { UserToken } from 'typescript';
import Keycloak from 'keycloak-js';
import { KeycloakUserInfo, UserRoles } from 'modules/user/user.types';
import { companyStore } from 'modules/company/company.store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

export const rolesForCompanyCheck = [UserRoles.CompanyOwner, UserRoles.CompanyUser];

class AuthStore {
  constructor() {
    makeObservable(this, {
      initComplete: observable,
      userAuthenticated: observable,
      userToken: observable,
      userId: observable,
      userInfo: observable,
      keycloak: observable,
      userRole: observable,
      getInitComplete: computed,
      getUserAuthenticated: computed,
      getUserToken: computed,
      getUserRole: computed,
      setInitComplete: action,
      setUserInfo: action,
    });
  }
  initComplete = false;
  userAuthenticated = false;
  userToken: UserToken | null = null;
  userId = '';
  keycloak = new Keycloak({
    url: 'https://nriz-keycloak.sepa.gov.rs/',
    realm: 'nriz',
    clientId: 'nriz-client',
  });
  userRole: UserRoles | null = null;
  userInfo: KeycloakUserInfo | null = null;
  get getInitComplete() {
    return this.initComplete;
  }
  get getUserAuthenticated() {
    return this.keycloak.authenticated;
  }
  get getUserToken() {
    return this.userToken;
  }
  get roles() {
    return this.userInfo?.groups || [];
  }
  get getUserRole() {
    return this.roles[0];
  }

  initKeycloak = async () => {
    this.keycloak.onTokenExpired = () => this.keycloak.updateToken();
    const authenticated = await this.keycloak.init({});
    if (authenticated && this.keycloak.authenticated) {
      const userInfo: KeycloakUserInfo = await this.keycloak.loadUserInfo();
      if (userInfo) {
        this.setUserInfo(userInfo);
        // todo: fetch tipovi obrazaca should be moved out of authstore
        await obrasciStore.fetchTipoviObrazaca();
        if (userInfo.groups && rolesForCompanyCheck.includes(userInfo.groups[0])) {
          await companyStore.checkCompany();
          if (companyStore.company) return this.setInitComplete(true);
        }
      } else return;
    }
    this.setInitComplete(true);
  };
  setUserInfo = (userInfo: KeycloakUserInfo) => (this.userInfo = userInfo);
  setInitComplete = (isComplete: boolean) => (this.initComplete = isComplete);
  setUserId = (id: string) => {
    this.userId = id;
  };
}

export const authStore = new AuthStore();
