import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import styles from './DevicesForWastewaterTreatment.module.scss';
import { DevicesTreatmentOptions } from './components/components/DevicesTreatmentOptions/DevicesTreatmentOptions';
import { waterStore } from 'modules/sourcesOfPollution/water/water.store';

export const DevicesForWastewaterTreatment: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <div className={styles.devicesForWastewaterTreatment}>
      <div>
        <span className={styles.warning}>*</span>
        <span className={styles.text}>
          {t('POLLUTION.WATER.DEVICES_IN_THE_INSTALLATION_OF_WASTEWATER_TREATMENT_FACILITY')}
        </span>
        <div className={styles.errorHeight}>
          {waterStore.getWaterShowDevicesTreatmentError && (
            <span className={styles.warning}>{t('VALIDATION.SELECT_A_FIELD')}</span>
          )}
        </div>
      </div>
      <div
        className={`${styles.devicesWastewaterTreatmentList} ${
          waterStore.getWaterShowDevicesTreatmentError ? styles.error : ''
        }`}
      >
        <DevicesTreatmentOptions />
      </div>
    </div>
  );
});
