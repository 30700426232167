import to from 'await-to-js';
import { makeAutoObservable, observable } from 'mobx';
import { istorijaPromenaService } from './istorijaPromena.service';
import { filtersStore } from 'store/filteri.store';
import { IGetIstorijaPromena } from './istorijaPromena.types';

class IstorijaPromenaStore {
  constructor() {
    makeAutoObservable(this, {
      istorijaPromenaList: observable,
    });
  }
  istorijaPromenaList: IGetIstorijaPromena<any>[] = [];

  handleChange(key: keyof IstorijaPromenaStore, value: any) {
    (this as any)[key] = value;
  }
  fetchIstorijuPromena = async () => {
    const [err, res] = await to(istorijaPromenaService.fetchIstorijaPromenaList(filtersStore.filters.istorijaPromena));
    if (err || !res) return;
    this.handleChange('istorijaPromenaList', res.items);
    return res;
  };
}

export const istorijaPromenaStore = new IstorijaPromenaStore();
