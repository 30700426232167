import dayjs from 'dayjs';
import { IPostPtp2ReportCustoms, IGetPtp2ReportCustoms } from './ptp2ReportCustoms.types';
import { t } from 'i18next';
import { FormInstance } from 'antd';
import { MAX_LENGTH_RULE, PIB_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { InputSifarniciFormProps, DateSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
export const PTP2_REPORT_CUSTOMS_INITIAL_STATE: IPostPtp2ReportCustoms = {
  pro_id: null,
  period_year: '',
  pib: '',
  company_name: '',
  address: '',
  location: '',
  is_active: true,
};

export const PTP2_REPORT_CUSTOMS_TABLE_DATA = [
  {
    title: 'Pro id',
    width: '150px',
    dataIndex: 'pro_id',
    key: 'pro_id',
  },
  {
    title: `${t('YEAR')}`,
    width: '100px',
    dataIndex: 'period_year',
    key: 'period_year',
    // render: (_: any, record: IGetPtp2ReportCustoms) => (
    // <span>{record.period_year ? dayjs(record.period_year).format('YYYY') : ''}</span>
    // ),
  },
  {
    title: `${t('COMPANY.PIB')}`,
    width: '100px',
    dataIndex: 'pib',
    key: 'pib',
  },
  {
    title: `${t('COMPANY.NAME')}`,
    width: '200px',
    dataIndex: 'company_name',
    key: 'company_name',
  },
  {
    title: `${t('ADDRESS')}`,
    width: '100px',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: `${t('SIFARNICI.LOCATION')}`,
    width: '100px',
    dataIndex: 'location',
    key: 'location',
  },
];

export const PTP2_REPORT_CUSTOMS_FORM_OPTIONS = (_: FormInstance<IGetPtp2ReportCustoms>) => {
  return [
    {
      type_of_form: 'input',
      // placeholder: t('SIFARNICI.UNESITE_PRO_ID'),
      label: 'Pro id',
      name: 'pro_id',
      inputName: 'pro_id',
      rules: [MAX_LENGTH_RULE(15)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'period_year',
      label: t('YEAR'),
      // placeholder={t('SELECT_YEAR')}
      format: 'YYYY',
      picker: 'year',
      disabledDate: (current: any) => {
        return current && current > dayjs().endOf('day');
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('COMPANY.PIB'),
      // placeholder={t('COMPANY.PLACEHOLDER.ENTER_PIB')}
      name: 'pib',
      inputName: 'pib',
      rules: [PIB_FIELD_RULE],
      precision: 0,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('OBRASCI.OBRAZAC3.NAZIV_PREDUZECA'),
      // placeholder={t('SIFARNICI.UNESITE_NAZIV_PREDUZECA')}
      name: 'company_name',
      inputName: 'company_name',
      rules: [MAX_LENGTH_RULE(150)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('ADDRESS'),
      // placeholder={t('SIFARNICI.UNESITE_NAZIV_PREDUZECA')}
      name: 'address',
      type: 'text',
      inputName: 'address',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('SIFARNICI.LOCATION'),
      type: 'text',
      // placeholder={t('SIFARNICI.UNESITE_NAZIV_PREDUZECA')}
      name: 'location',
      inputName: 'location',
      rules: [MAX_LENGTH_RULE(200)],
    } as InputSifarniciFormProps,
  ];
};
