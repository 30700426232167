import { ColumnsType } from 'antd/es/table';
import { IGetMediumCode, IPostMediumCode } from './mediumCode.types';

import {
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_TO_DATE_TABLE,
  VALIDITY_FROM_DATE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import { Checkbox, FormInstance } from 'antd';
import { SwitchSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import {
  TITLE_FORM,
  DESCRIPTION_FORM,
  VERSION_FORM,
  CODE_FORM,
  NAME_FORM,
  NAME_SR_FORM,
  VALIDITY_TO_DATE_FORM,
  VALIDITY_FROM_DATE_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';

export const MEDIUM_CODE_INITIAL_STATE: IPostMediumCode = {
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  valid: false,
  name_sr: '',
  is_active: true,
};
export const MEDIUM_CODE_TABLE_DATA: ColumnsType<IGetMediumCode> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  {
    title: 'Validan',
    width: 100,
    dataIndex: 'valid',
    key: 'valid',
    render: (_: any, record: IGetMediumCode) => <Checkbox checked={!!record?.valid} disabled={true} />,
    align: 'center',
  },
];
export const MEDIUM_CODE_FORM_OPTIONS = (form: FormInstance<IGetMediumCode>) => {
  return [
    NAME_FORM,
    NAME_SR_FORM,
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM,
    DESCRIPTION_FORM,
    VERSION_FORM,
    CODE_FORM,
    {
      type_of_form: 'switch',
      name: 'valid',
      // sortName: 'air',
      label: 'Validan',
    } as SwitchSifarniciFormProps,
  ];
};
