import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { dep2ObrazacStore } from 'modules/obrasci/store/dep2/dep2.store';
import { IDivljeDeponije, IGetDivljeDeponije } from 'modules/obrasci/store/dep2/dep2.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const TableDivljaDeponija: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetDivljeDeponije> = [
    {
      title: `${t('OBRASCI.DEP_2.SETTLEMENT')}`,
      width: 100,
      dataIndex: ['mesto', 'display_name'],
      key: 'display_name',
    },
    {
      title: `${t('OBRASCI.DEP_2.ESTIMATED_AMOUNT_OF_WASTE')}`,
      width: 100,
      dataIndex: 'procenjena_kolicina_otpada',
      key: 'procenjena_kolicina_otpada',
    },
    {
      title: `${t('OBRASCI.DEP_2.ESTIMATED_OF_THE_AREA_OF_LANDFILLS')}`,
      dataIndex: 'procenjena_povrsina_smetlista',
      key: 'procenjena_povrsina_smetlista',
      width: 100,
    },
    {
      title: `${t('OBRASCI.DEP_2.HOW_MANY_TIMES_CLEANED')}`,
      dataIndex: 'broj_ciscenja_prostora_u_okviru_godine',
      key: 'broj_ciscenja_prostora_u_okviru_godine',
      width: 100,
    },
    {
      title: `${t('OBRASCI.DEP_2.IS_THERE_REPEATED_DUMPING')}`,
      dataIndex: ['dep_forms_answers', 'answer_sr'],
      key: 'answer',
      width: 100,
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IDivljeDeponije) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-activity'}
            onClick={() => {
              dep2ObrazacStore.handleChange('divlja_deponija', record);
              modalStore.changeModalName('divlja-deponija-modal');
            }}
          />
        );
      },
    },
  ];
  // todo: check if backend return whole array or we have to do it with pagination
  return (
    <TableData
      name="divlja-deponija-dep2"
      columns={baseColumns}
      dataSource={[...dep2ObrazacStore.initialState.divlje_deponije]}
    />
  );
});
