import { ColumnsType } from 'antd/es/table';
import { IGetWasteCatalogSubgroup, IPostWasteCatalogSubgroup } from './wasteCatalogSubgroup.types';
import { CODE_TABLE, DESCRIPTION_SR_TABLE, DESCRIPTION_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import { CODE_FORM, DESCRIPTION_FORM, DESCRIPTION_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';

export const WASTE_CATALOG_SUBGROUP_INITIAL_STATE: IPostWasteCatalogSubgroup = {
  code: '',
  description: '',
  description_sr: '',
  is_active: true,
};
export const WASTE_CATALOG_SUBGROUP_TABLE_DATA: ColumnsType<IGetWasteCatalogSubgroup> = [
  CODE_TABLE,
  DESCRIPTION_TABLE,
  DESCRIPTION_SR_TABLE,
];
export const WASTE_CATALOG_SUBGROUP_FORM_OPTIONS = (_: FormInstance<IGetWasteCatalogSubgroup>) => {
  return [CODE_FORM, DESCRIPTION_FORM, DESCRIPTION_SR_FORM];
};
