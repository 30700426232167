import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { RequestUnsanitaryLandfill, IGetUnsanitaryLandfill, IPostUnsanitaryLandfill } from './unsanitarylandfill.types';

const fetchUnsanitaryLandfillList = (payload: RequestUnsanitaryLandfill): ApiResponse<any> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.UNSANITARY_LANDFILL}/?${query}`);
};

const postUnsanitaryLandfill = (unsanitaryLandfill: IPostUnsanitaryLandfill): ApiResponse<{ id: string }> => {
  return axios.post(`/${ROUTES.UNSANITARY_LANDFILL}/`, unsanitaryLandfill);
};
const putUnsanitaryLandfill = (unsanitaryLandfill: IGetUnsanitaryLandfill) => {
  return axios.put(`/${ROUTES.UNSANITARY_LANDFILL}/${unsanitaryLandfill.id}`, unsanitaryLandfill);
};
const deleteUnsanitaryLandfill = (id: string): ApiResponse<any> => {
  return axios.delete(`/${ROUTES.UNSANITARY_LANDFILL}/${id}`);
};

const aktivirajNeasnitarnuDeponiju = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.UNSANITARY_LANDFILL}/${id}/aktiviraj`);
};
const deaktivirajNeasnitarnuDeponiju = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.UNSANITARY_LANDFILL}/${id}/deaktiviraj`);
};

export const unsanitaryLandfillRepo = {
  fetchUnsanitaryLandfillList,
  postUnsanitaryLandfill,
  putUnsanitaryLandfill,
  deleteUnsanitaryLandfill,
  aktivirajNeasnitarnuDeponiju,
  deaktivirajNeasnitarnuDeponiju,
};
