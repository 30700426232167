import { Col, Row } from 'antd';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { gio4ObrazacStore } from 'modules/obrasci/store/gio4/gio4.store';
import { useTranslation } from 'react-i18next';
import { disabledTodayAndFutureDates, disabledTodayAndPreviousDates } from 'utils/formatDate';

import styles from './NewGio4DozvoleForm.module.scss';
import { useEffect } from 'react';
import { FormInput } from 'components/FormInput/FormInput';
import { IGetDozvoleGio4 } from 'modules/obrasci/store/gio4/gio4.types';

export const NewGio4DozvoleForm: React.FC = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    gio4ObrazacStore.handleChange('dozvoleNizValidacija', []);
    if (gio4ObrazacStore.dozvoleInitial.sakupljanje === true) {
      gio4ObrazacStore.promeniDozvoleNizValidacijaListu('sakupljanje', true);
    }
    if (gio4ObrazacStore.dozvoleInitial.odlaganje === true) {
      gio4ObrazacStore.promeniDozvoleNizValidacijaListu('odlaganje', true);
    }
    if (gio4ObrazacStore.dozvoleInitial.skladistenje === true) {
      gio4ObrazacStore.promeniDozvoleNizValidacijaListu('skladistenje', true);
    }
    if (gio4ObrazacStore.dozvoleInitial.transport === true) {
      gio4ObrazacStore.promeniDozvoleNizValidacijaListu('transport', true);
    }
    if (gio4ObrazacStore.dozvoleInitial.tretman === true) {
      gio4ObrazacStore.promeniDozvoleNizValidacijaListu('tretman', true);
    }
  }, []);

  return (
    <Col span={24}>
      <div className={styles.labelMargin} style={{ fontWeight: 'bold' }}>
        {t('OBRASCI.GIO2.PODACI_O_DOZVOLI_ZA_UPRAVLJANJE')}
      </div>

      <FormInput
        name="broj_dozvole"
        label={t('OBRASCI.GIO2.BROJ_DOZVOLE')}
        placeholder={t('OBRASCI.GIO2.BROJ_DOZVOLE')}
        inputName={'broj_dozvole'}
        colon={true}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                gio4ObrazacStore.getDozvoleInitial.broj_dozvole !== value &&
                gio4ObrazacStore.initialState.dozvole.some((item: IGetDozvoleGio4) => {
                  return value === item.broj_dozvole;
                })
              ) {
                return Promise.reject(new Error(t('VALIDATION.ALREADY_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
      />

      <FormDatePicker
        name="datum_izdavanja"
        label={t('OBRASCI.GIO2.DATUM_IZDAVANJA')}
        labelCol={{ span: 12, offset: 0 }}
        wrapperCol={{ span: 12 }}
        format="DD-MM-YYYY"
        picker={'date'}
        disabledDate={disabledTodayAndFutureDates}
        rules={[REQUIRED_FIELD_RULE(true)]}
        placeholder={t('SELECT_DATE')}
      />
      <FormDatePicker
        name="datum_isteka"
        label={t('OBRASCI.GIO2.DATUM_ISTEKA')}
        labelCol={{ span: 12, offset: 0 }}
        wrapperCol={{ span: 12 }}
        format="DD-MM-YYYY"
        picker={'date'}
        disabledDate={disabledTodayAndPreviousDates}
        rules={[REQUIRED_FIELD_RULE(true)]}
        placeholder={t('SELECT_DATE')}
      />
      <div className={styles.labelMargin} style={{ fontWeight: 'bold' }}>
        <span>*</span> {t('OBRASCI.GIO2.DOZVOLA_VAZI_ZA')}
      </div>
      {!gio4ObrazacStore.dozvoleNizValidacija.length && (
        <div className={styles.validateError}>{t('OBRASCI.GIO2.VALIDACIJA_CHECKBOX')}</div>
      )}

      <Row>
        <Col span={24}>
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'sakupljanje'}
            label={t('OBRASCI.GIO2.SAKUPLJANJE')}
            onCheckBoxChange={e => {
              gio4ObrazacStore.promeniDozvoleNizValidacijaListu('sakupljanje', e.target.checked);
            }}
          />
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'transport'}
            label={t('OBRASCI.GIO2.TRANSPORT')}
            onCheckBoxChange={e => {
              gio4ObrazacStore.promeniDozvoleNizValidacijaListu('transport', e.target.checked);
            }}
          />
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'skladistenje'}
            label={t('OBRASCI.GIO2.SKLADISTENJE')}
            onCheckBoxChange={e => {
              gio4ObrazacStore.promeniDozvoleNizValidacijaListu('skladistenje', e.target.checked);
            }}
          />
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'tretman'}
            label={t('OBRASCI.GIO2.TRETMAN')}
            onCheckBoxChange={e => {
              gio4ObrazacStore.promeniDozvoleNizValidacijaListu('tretman', e.target.checked);
            }}
          />
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'odlaganje'}
            label={t('OBRASCI.GIO2.ODLAGANJE')}
            onCheckBoxChange={e => {
              gio4ObrazacStore.promeniDozvoleNizValidacijaListu('odlaganje', e.target.checked);
            }}
          />
        </Col>
      </Row>
    </Col>
  );
});
