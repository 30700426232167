import { FormInstance } from 'antd';

import { REQUIRED_FIELD_RULE_NO_MSG, SELECT_AT_LEAST_ONE_FIELD_RULE } from 'modules/nriz/nriz.constants';
import {
  CheckboxSifarniciFormProps,
  DateSifarniciFormProps,
  ISifarniciTypeOfForm,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  SelectSifarniciFormProps,
  SwitchSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { IGetWeeeCategories } from 'modules/sifarnici/store/weeeCategories/weeeCategories.types';
import {
  Aao1IzvestajiEnum,
  Aao2IzvestajiEnum,
  Deo6IzvestajiEnum,
  Dep1IzvestajiEnum,
  Dep2IzvestajiEnum,
  Gio2IzvestajiEnum,
  Gio3IzvestajiEnum,
  Gio4IzvestajiEnum,
  Gio5IzvestajiEnum,
  IzvestajiSubtypes,
  Kom1IzvestajiEnum,
  Obrazac1IzvestajiEnum,
  Obrazac2IzvestajiEnum,
  Obrazac3IzvestajiEnum,
  Obrazac5IzvestajiEnum,
  Ptp2IzvestajiEnum,
  PzvIzvestajiEnum,
} from './obrazacIzvestaji.types';

// Todo: replace with filter component specific type (remove one from sifarnici.types, make componetn specific)
export type FormConfig = (form: FormInstance) => ISifarniciTypeOfForm[];
export type FormConfigItemWithForm<FormConfigType = ISifarniciTypeOfForm> = (form: FormInstance) => FormConfigType;

// declaration constants for filters
const YEAR_FILTER: DateSifarniciFormProps = {
  type_of_form: 'date',
  // inputName: 'godina',
  name: 'godina',
  label: 'Godina',
  sortName: 'godina',
  picker: 'year',
  rules: [REQUIRED_FIELD_RULE_NO_MSG],
};

const YEAR_FROM_FILTER: DateSifarniciFormProps = {
  type_of_form: 'date',
  // inputName: 'godina',
  name: 'godina_od',
  label: 'Period od godine',
  sortName: 'godina_od',
  picker: 'year',
  rules: [REQUIRED_FIELD_RULE_NO_MSG],
};

const YEAR_TO_FILTER: DateSifarniciFormProps = {
  type_of_form: 'date',
  // inputName: 'godina',
  name: 'godina_do',
  label: 'Period do godine',
  sortName: 'godina_do',
  picker: 'year',
  rules: [REQUIRED_FIELD_RULE_NO_MSG],
};

const PREDUZECE_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  name: 'preduzece_id',
  formName: 'preduzece_id',
  label: 'Preduzece',
  sortName: 'preduzece_id',
  labelOptionAccessor: ['naziv'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE,
  placeholder: 'Preduzece',
  // onChange: (value: string) => {
  //   filtersStore.handleFiltersChange('obrazacIzvestaji', 'preduzece_id', value);
  // },
};

const POSTROJENJE_FILTER: FormConfigItemWithForm<SearchScrollSifarniciFormProps> = form => ({
  // hideInput: !filtersStore.filters.obrazacIzvestaji.preduzece_id,
  hideInput: !form.getFieldValue('preduzece_id'),
  type_of_form: 'searchScroll',
  name: 'postrojenje_id',
  formName: 'postrojenje_id',
  label: 'Postrojenje',
  sortName: 'postrojenje_id',
  labelOptionAccessor: ['naziv_postrojenja'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.POSTROJENJE,
  filtersForFetch: {
    preduzece_id: form.getFieldValue('preduzece_id'),
  },
  placeholder: 'Postrojenje',
});

const OPSTINA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'municipality_code_id',
  fetchOptinsList: SIFARNICI_ROUTES.MUNICIPALITY_CODE,
  labelOptionAccessor: ['name'],
  valueOptionAccessor: 'id',
  name: 'municipality_code_id',
  label: 'Opština',
  sortName: 'municipality_code_id',
  placeholder: 'Opstina',
};

const MESTO_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  inputName: 'location_name_id',
  formName: 'location_name_id',
  fetchOptinsList: SIFARNICI_ROUTES.MESTO,
  labelOptionAccessor: ['display_name'],
  valueOptionAccessor: 'id',
  name: 'location_name_id',
  label: 'Mesto',
  sortName: 'location_name_id',
  placeholder: 'Mesto',
};

const PRTR_FILTER: CheckboxSifarniciFormProps = {
  type_of_form: 'switch',
  name: 'prtr',
  label: 'PRTR',
  sortName: 'prtr',
};

const VRSTA_RECIPIJENTA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  inputName: 'recipient_type_id',
  formName: 'recipient_type_id',
  fetchOptinsList: SIFARNICI_ROUTES.RECIPIENT_TYPE,
  labelOptionAccessor: ['type_sr'],
  valueOptionAccessor: 'id',
  name: 'recipient_type_id',
  label: 'Vrsta recipijenta',
  sortName: 'recipient_type_id',
  placeholder: 'Vrsta recipijenta',
};

const INDEKSNI_BROJ_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'indeksni_broj_otpada_id',
  fetchOptinsList: SIFARNICI_ROUTES.WASTE_CATALOGUE,
  labelOptionAccessor: ['index_number', 'waste_sr'],
  valueOptionAccessor: 'id',
  inputName: 'indeksni_broj_otpada_id',
  label: 'Indeksni broj',
  placeholder: 'Indeksni broj',
  sortName: 'indeksni_indeksni_broj_otpada',
};

const ZAGADJUJUCA_MATERIJA_FILTER: FormConfigItemWithForm<SearchScrollSifarniciFormProps> = form => ({
  sortName: 'pollutant_id',
  type_of_form: 'searchScroll',
  label: 'Zagađujuća materija',
  formName: 'pollutant_id',
  inputName: 'pollutant_id',
  fetchOptinsList: SIFARNICI_ROUTES.POLLUTANT_CODE,
  filtersForFetch: {},
  labelOptionAccessor: ['name_sr'],
  valueOptionAccessor: 'id',
  placeholder: 'Zagađujuća materija',
  hideInput: !form.getFieldValue('postrojenje_id'),
});

const IZVOR_ZAGADJENJA_VODE_FILTER: FormConfigItemWithForm<SearchScrollSifarniciFormProps> = form => ({
  hideInput: !form.getFieldValue('postrojenje_id'),
  type_of_form: 'searchScroll',
  name: 'izvor_zagadjenja_vode_id',
  formName: 'izvor_zagadjenja_vode_id',
  label: 'Ispust',
  sortName: 'izvor_zagadjenja_vode',
  labelOptionAccessor: ['naziv_ispusta'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE,
  filtersForFetch: {
    postrojenje_id: form.getFieldValue('postrojenje_id'),
  },
  placeholder: 'Ispust',
});

const SIFRA_PRETEZNE_DELATNOSTI_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'nace_activity_code_id',
  fetchOptinsList: SIFARNICI_ROUTES.NACE_ACTIVITY_CODE,
  labelOptionAccessor: ['name_sr'],
  valueOptionAccessor: 'id',
  inputName: 'nace_activity_code_id',
  label: 'Šifra pretežne delatnosti',
  placeholder: 'Šifra pretežne delatnosti',
  sortName: 'nace_activity_code',
};

const NIVO_FILTER: SelectSifarniciFormProps = {
  type_of_form: 'select',
  name: 'nivo',
  label: 'Nivo',
  options: [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 3,
      value: 3,
    },
  ],
};

const KATEGORIJA_EE_OTPADA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'razred_ee_otpada_id',
  fetchOptinsList: SIFARNICI_ROUTES.WEEE_CATEGORIES,
  labelOptionAccessor: ['the_order', 'category_name_sr'],
  valueOptionAccessor: 'id',
  inputName: 'razred_ee_otpada_id',
  label: 'Kategorija EE otpada',
  placeholder: 'Kategorija EE otpada',
  optionsMaker: (options: IGetWeeeCategories[]) => {
    return options.map(weeeCategory => ({
      label: `${weeeCategory.the_order} ${weeeCategory.category_name_sr}`,
      value: weeeCategory.id,
    }));
  },
};

export const PZV_DETERDZENT_DJUBRIVA_SREDSTVA_FILTER_GROUP: (
  form: FormInstance
) => CheckboxSifarniciFormProps[] = form => [
  {
    name: 'deterzenti',
    type_of_form: 'switch',
    label: 'Deterdženti',
    rules: [
      SELECT_AT_LEAST_ONE_FIELD_RULE(
        ['deterzenti', 'mineralna_djubriva', 'sredstva_za_zastitu_bilja'],
        form,
        'Potrebno je odabrati barem jedno polje'
      ),
    ],
  },
  {
    name: 'mineralna_djubriva',
    type_of_form: 'switch',
    label: 'Mineralna đubriva',
    rules: [
      SELECT_AT_LEAST_ONE_FIELD_RULE(
        ['deterzenti', 'mineralna_djubriva', 'sredstva_za_zastitu_bilja'],
        form,
        'Potrebno je odabrati barem jedno polje'
      ),
    ],
  },
  {
    name: 'sredstva_za_zastitu_bilja',
    type_of_form: 'switch',
    label: 'Sredstva za zaštitu bilja',
    rules: [
      SELECT_AT_LEAST_ONE_FIELD_RULE(
        ['deterzenti', 'mineralna_djubriva', 'sredstva_za_zastitu_bilja'],
        form,
        'Potrebno je odabrati barem jedno polje'
      ),
    ],
  },
];

const PIB_PREDUZECA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  name: 'pib_id',
  formName: 'pib_id',
  label: 'Pib preduzeća',
  sortName: 'pib_id',
  labelOptionAccessor: ['pib'],
  valueOptionAccessor: 'pib',
  fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE,
  placeholder: 'Pib preduzeća',
};

const OPASAN_FILTER: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  name: 'opasan',
  label: 'Opasan otpad',
};

const OBRAZAC_FILTER: SelectSifarniciFormProps = {
  type_of_form: 'select',
  name: 'obrazac',
  label: 'Obrazac',
  placeholder: 'Tip obrasca',
  options: [
    { label: 'Obrazac 5', value: 'obrazac_5' },
    { label: 'GIO5', value: 'gio5' },
  ],
};

const Y_LIST_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  inputName: 'y_mark_id',
  formName: 'y_mark_id',
  fetchOptinsList: SIFARNICI_ROUTES.Y_LIST,
  labelOptionAccessor: ['description_sr'],
  valueOptionAccessor: 'id',
  name: 'y_mark_id',
  label: 'Y oznaka',
  sortName: 'y_mark_id',
  placeholder: 'Y oznaka',
};

const OPERATER_FILTER: SelectSifarniciFormProps = {
  type_of_form: 'select',
  name: 'operater',
  label: 'Operater',
  placeholder: 'Operater',
  options: [
    { label: 'Količine prenete na operatera', value: 'kolicine_prenete_na_operatera' },
    { label: 'Količine koje nisu prenete na operatera', value: 'kolicine_koje_nisu_prenete_na_operatera' },
    { label: 'Sve vrste ambalaže', value: 'sve_vrste_ambalaze' },
  ],
};

const OPERATER_SEARCH_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'operater_search_id',
  fetchOptinsList: SIFARNICI_ROUTES.OPERATORS_PACKAGE_WASTE_MANAGE,
  labelOptionAccessor: ['naziv'],
  nestedObjKey: 'preduzece',
  valueOptionAccessor: 'id',
  inputName: 'operater_search_id',
  label: 'Operater',
  placeholder: 'Operater',
  sortName: 'operater_search_id',
};

const DOSTAVLJENO_OD_FILTERS: DateSifarniciFormProps = {
  type_of_form: 'date',
  name: 'dostavljeno_od',
  label: 'Dostavljeno od',
  sortName: 'dostavljeno_od',
  picker: 'date',
};

const DOSTAVLJENO_DO_FILTERS: DateSifarniciFormProps = {
  type_of_form: 'date',
  name: 'dostavljeno_do',
  label: 'Dostavljeno do',
  sortName: 'godina_do',
  picker: 'date',
};

const PIB_OPERATERA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'pib_operatera_id',
  fetchOptinsList: SIFARNICI_ROUTES.OPERATORS_PACKAGE_WASTE_MANAGE,
  labelOptionAccessor: ['pib'],
  nestedObjKey: 'preduzece',
  valueOptionAccessor: 'id',
  inputName: 'pib_operatera_id',
  label: 'Pib operatera',
  placeholder: 'Pib operatera',
  sortName: 'pib_operatera_id',
};

// filters
export const OBRAZAC_1_GORIVA_FILTERS: FormConfig = form => [
  YEAR_FILTER,
  PREDUZECE_FILTER,
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  MESTO_FILTER,
];

export const OBRAZAC_1_PROIZVODI_FILTERS: FormConfig = form => [
  YEAR_FILTER,
  PREDUZECE_FILTER,
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  MESTO_FILTER,
];

export const OBRAZAC_3_ZAGADLJIVE_MATERIJE_FILTERS: FormConfig = form => [
  YEAR_FILTER,
  PREDUZECE_FILTER,
  POSTROJENJE_FILTER(form),
  PRTR_FILTER,
  OPSTINA_FILTER,
  IZVOR_ZAGADJENJA_VODE_FILTER(form),
  ZAGADJUJUCA_MATERIJA_FILTER(form),
  VRSTA_RECIPIJENTA_FILTER,
];

export const OBRAZAC_3_PREGLED_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER,
  YEAR_TO_FILTER,
  PREDUZECE_FILTER,
  POSTROJENJE_FILTER(form),
  PRTR_FILTER,
  OPSTINA_FILTER,
  ZAGADJUJUCA_MATERIJA_FILTER(form),
];

export const OBRAZAC_3_ISPUST_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER,
  YEAR_TO_FILTER,
  PREDUZECE_FILTER,
  POSTROJENJE_FILTER(form),
  PRTR_FILTER,
  OPSTINA_FILTER,
  ZAGADJUJUCA_MATERIJA_FILTER(form),
];

export const OBRAZAC_3_OPSTE_FILTERS: FormConfig = form => [
  YEAR_FILTER,
  PREDUZECE_FILTER,
  POSTROJENJE_FILTER(form),
  PRTR_FILTER,
  OPSTINA_FILTER,
  IZVOR_ZAGADJENJA_VODE_FILTER(form),
  {
    type_of_form: 'switch',
    name: 'ppov',
    label: 'PPOV',
  },
];

export const GIO_4_POSTROJENJA_INDEKSNI_BROJ_FILTERS: FormConfig = form => [
  YEAR_FILTER,
  PREDUZECE_FILTER,
  SIFRA_PRETEZNE_DELATNOSTI_FILTER,
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  INDEKSNI_BROJ_FILTER,
  {
    type_of_form: 'switch',
    name: 'opasan',
    label: 'Opasan',
  },
];

export const GIO_4_KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI_OTPADA_FILTERS: FormConfig = () => [
  YEAR_FILTER,
  INDEKSNI_BROJ_FILTER,
];

export const GIO_4_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_FILTERS: FormConfig = form => [
  YEAR_FILTER,
  PREDUZECE_FILTER,
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  KATEGORIJA_EE_OTPADA_FILTER,
];

export const GIO_4_PREGLED_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER,
  YEAR_TO_FILTER,
  PREDUZECE_FILTER,
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  OPSTINA_FILTER,
  {
    type_of_form: 'switch',
    name: 'opasan',
    label: 'Opasan',
  },
];

export const GIO_4_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS: FormConfig = () => [
  YEAR_FILTER,
  NIVO_FILTER,
  INDEKSNI_BROJ_FILTER,
];

export const PTP_2_OBRACUN_ZA_NAPLATU_FILTERS: FormConfig = () => [YEAR_FROM_FILTER, YEAR_TO_FILTER];
export const PTP_2_VOZILA_UPOREDJIVANJE_FILTERS: FormConfig = () => [YEAR_FROM_FILTER, YEAR_TO_FILTER];
export const PTP_2_NEDOSTAVLJENI_PODACI_O_VOZILIMA_FILTERS: FormConfig = () => [YEAR_FROM_FILTER, YEAR_TO_FILTER];
export const PTP_2_UVOZ_GUMA_UPOREDJIVANJE_FILTERS: FormConfig = () => [YEAR_FROM_FILTER, YEAR_TO_FILTER];
export const PTP_2_NEDOSTAVLJENI_PODACI_O_GUMAMA_FILTERS: FormConfig = () => [YEAR_FROM_FILTER, YEAR_TO_FILTER];

export const PZV_KOLICINE_PROIZVODA_PO_PREDUZECU_FILTERS: FormConfig = form => [
  YEAR_FILTER,
  PREDUZECE_FILTER,
  ...PZV_DETERDZENT_DJUBRIVA_SREDSTVA_FILTER_GROUP(form),
];
export const PZV_KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU_FILTERS: FormConfig = form => [
  YEAR_FILTER,
  PREDUZECE_FILTER,
  ...PZV_DETERDZENT_DJUBRIVA_SREDSTVA_FILTER_GROUP(form),
];

export const OBRAZAC_5_POSTROJENJA_FILTERS: FormConfig = form => [
  YEAR_FILTER,
  PREDUZECE_FILTER,
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  OPASAN_FILTER,
  OBRAZAC_FILTER,
];

export const OBRAZAC_5_POSTROJENJA_INDEKSNI_BROJ_FILTERS: FormConfig = form => [
  YEAR_FILTER,
  PREDUZECE_FILTER,
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  SIFRA_PRETEZNE_DELATNOSTI_FILTER,
  INDEKSNI_BROJ_FILTER,
  OPASAN_FILTER,
  OBRAZAC_FILTER,
  Y_LIST_FILTER,
];

export const OBRAZAC_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS: FormConfig = () => [
  YEAR_FILTER,
  OBRAZAC_FILTER,
  Y_LIST_FILTER,
  INDEKSNI_BROJ_FILTER,
];

export const OBRAZAC_5_OTPAD_NE_OPASAN_FILTERS: FormConfig = () => [YEAR_FILTER, OBRAZAC_FILTER];

export const OBRAZAC_5_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER,
  YEAR_TO_FILTER,
  PREDUZECE_FILTER,
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  OPSTINA_FILTER,
  OPASAN_FILTER,
  OBRAZAC_FILTER,
];

export const OBRAZAC_5_POSTROJENJA_ZA_PLACANJE_FILTERS: FormConfig = form => [
  YEAR_FILTER,
  PREDUZECE_FILTER,
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  OPASAN_FILTER,
  OBRAZAC_FILTER,
];

export const GIO_5_POSTROJENJA_INDEKSNI_BROJ_FILTERS: FormConfig = form => [
  YEAR_FILTER,
  PREDUZECE_FILTER,
  SIFRA_PRETEZNE_DELATNOSTI_FILTER,
  POSTROJENJE_FILTER(form),
  OPSTINA_FILTER,
  INDEKSNI_BROJ_FILTER,
  OPASAN_FILTER,
];

export const GIO_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS: FormConfig = () => [
  YEAR_FILTER,
  NIVO_FILTER,
  INDEKSNI_BROJ_FILTER,
];

export const GIO_5_UVOZ_OTPADA_PREGLED_PO_GODINI_FILTERS: FormConfig = form => [
  YEAR_FROM_FILTER,
  YEAR_TO_FILTER,
  PREDUZECE_FILTER,
  POSTROJENJE_FILTER(form),
  INDEKSNI_BROJ_FILTER,
  OPSTINA_FILTER,
  OPASAN_FILTER,
];

export const GIO_5_KOLICINA_UVEZENOG_OTPADA_PO_VRSTI_FILTERS: FormConfig = () => [YEAR_FILTER, INDEKSNI_BROJ_FILTER];

export const AAO_1_PREGLED_PO_GODINAMA_FILTERS: FormConfig = () => [YEAR_FROM_FILTER, YEAR_TO_FILTER, PREDUZECE_FILTER];

export const AAO_1_PRIKAZ_1_FILTERS: FormConfig = () => [
  YEAR_FILTER,
  OPERATER_FILTER,
  PIB_PREDUZECA_FILTER,
  OPERATER_SEARCH_FILTER,
  DOSTAVLJENO_OD_FILTERS,
  DOSTAVLJENO_DO_FILTERS,
];

export const AAO_1_SVI_PODACI_FILTERS: FormConfig = () => [YEAR_FILTER, PIB_PREDUZECA_FILTER];

export const AAO_1_NEDOSTAVLJENI_OBRASCI_FILTERS: FormConfig = () => [YEAR_FILTER, PIB_OPERATERA_FILTER];

export const OBRAZAC_IZVESTAJI_FILTERS_MAP: {
  [key in IzvestajiSubtypes]: (form: FormInstance) => ISifarniciTypeOfForm[];
} = {
  [Obrazac1IzvestajiEnum.GORIVA]: OBRAZAC_1_GORIVA_FILTERS,
  [Obrazac1IzvestajiEnum.PROIZVODI]: OBRAZAC_1_PROIZVODI_FILTERS,
  [Obrazac1IzvestajiEnum.SIROVINE]: () => [],
  [Obrazac1IzvestajiEnum.OPSTE]: () => [],
  [Obrazac2IzvestajiEnum.ZAGADLJIVE_MATERIJE]: () => [],
  [Obrazac2IzvestajiEnum.GORIVO]: () => [],
  [Obrazac2IzvestajiEnum.PREGLED_PO_GODINAMA]: () => [],
  [Obrazac2IzvestajiEnum.IZVORI_PO_GODINAMA]: () => [],
  [Obrazac2IzvestajiEnum.OPSTE]: () => [],
  [Obrazac3IzvestajiEnum.ISPUST_PO_GODINAMA]: OBRAZAC_3_ISPUST_PO_GODINAMA_FILTERS,
  [Obrazac3IzvestajiEnum.ZAGADLJIVE_MATERIJE]: OBRAZAC_3_ZAGADLJIVE_MATERIJE_FILTERS,
  [Obrazac3IzvestajiEnum.PREGLED_PO_GODINAMA]: OBRAZAC_3_PREGLED_PO_GODINAMA_FILTERS,
  [Obrazac3IzvestajiEnum.OPSTE]: OBRAZAC_3_OPSTE_FILTERS,
  [Obrazac5IzvestajiEnum.POSTROJENJA]: OBRAZAC_5_POSTROJENJA_FILTERS,
  [Obrazac5IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: OBRAZAC_5_POSTROJENJA_INDEKSNI_BROJ_FILTERS,
  [Obrazac5IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: OBRAZAC_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS,
  [Obrazac5IzvestajiEnum.OTPAD_NE_OPASAN]: OBRAZAC_5_OTPAD_NE_OPASAN_FILTERS,
  [Obrazac5IzvestajiEnum.GENERISANJE_OTPADA_PREGLED_PO_GODINAMA]:
    OBRAZAC_5_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA_FILTERS,
  [Obrazac5IzvestajiEnum.POSTROJENJA_ZA_PLACANJE]: OBRAZAC_5_POSTROJENJA_ZA_PLACANJE_FILTERS,
  [Gio2IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: () => [],
  [Gio2IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: () => [],
  [Gio2IzvestajiEnum.ODLAGANJE_OTPADA_PREGLED_PO_GODINI]: () => [],
  [Gio2IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: () => [],
  [Gio3IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: () => [],
  [Gio3IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: () => [],
  [Gio3IzvestajiEnum.TRETMAN_OTPADA_PREGLED_PO_GODINI]: () => [],
  [Gio3IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: () => [],
  [Gio4IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: GIO_4_POSTROJENJA_INDEKSNI_BROJ_FILTERS,
  [Gio4IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: GIO_4_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS,
  [Gio4IzvestajiEnum.IZVOZ_OTPADA_PREGLED_PO_GODINI]: GIO_4_PREGLED_PO_GODINAMA_FILTERS,
  [Gio4IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: GIO_4_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_FILTERS,
  [Gio4IzvestajiEnum.KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI]: GIO_4_KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI_OTPADA_FILTERS,
  [Gio5IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: GIO_5_POSTROJENJA_INDEKSNI_BROJ_FILTERS,
  [Gio5IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: GIO_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_FILTERS,
  [Gio5IzvestajiEnum.UVOZ_OTPADA_PREGLED_PO_GODINI]: GIO_5_UVOZ_OTPADA_PREGLED_PO_GODINI_FILTERS,
  [Gio5IzvestajiEnum.KOLICINA_UVEZENOG_OTPADA_PO_VRSTI]: GIO_5_KOLICINA_UVEZENOG_OTPADA_PO_VRSTI_FILTERS,
  [Deo6IzvestajiEnum.UKUPNO_REUZET_OTPAD]: () => [],
  [Deo6IzvestajiEnum.PREUZET_OTPAD_POSTROJENJA_INDEKSNI_BROJ]: () => [],
  [Deo6IzvestajiEnum.UKUPNO_PREDAT_OTPAD]: () => [],
  [Deo6IzvestajiEnum.PREDAT_OTPAD_POSTROJENJA_INDEKSNI_BROJ]: () => [],
  [Deo6IzvestajiEnum.REKAPITULACIJA]: () => [],
  [Deo6IzvestajiEnum.REKALKULACIJA]: () => [],
  [Deo6IzvestajiEnum.PRIMOPREDAJA_OTPADA_IZMEDJU_PREDUZECA]: () => [],
  [Kom1IzvestajiEnum.SASTAV_KOMUNALNOG_OTPADA_PO_GODINAMA]: () => [],
  [Kom1IzvestajiEnum.KOLICINA_KOMUNALNOG_OTPADA_PO_GODINAMA]: () => [],
  [Kom1IzvestajiEnum.OBUHVAT_PRIKUPLJENOG_OTPADA_PO_GODINAMA]: () => [],
  [Kom1IzvestajiEnum.UPRAVLJANJE_KOMUNALNIM_OTPADOM]: () => [],
  [Ptp2IzvestajiEnum.OBRACUN_ZA_NAPLATU]: PTP_2_OBRACUN_ZA_NAPLATU_FILTERS,
  [Ptp2IzvestajiEnum.VOZILA_UPOREDJIVANJE]: PTP_2_VOZILA_UPOREDJIVANJE_FILTERS,
  [Ptp2IzvestajiEnum.NEDOSTAVLJENI_PODACI_O_VOZILIMA]: PTP_2_NEDOSTAVLJENI_PODACI_O_VOZILIMA_FILTERS,
  [Ptp2IzvestajiEnum.UVOZ_GUMA_UPOREDJIVANJE]: PTP_2_UVOZ_GUMA_UPOREDJIVANJE_FILTERS,
  [Ptp2IzvestajiEnum.NEDOSTAVLJENI_PODACI_O_GUMAMA]: PTP_2_NEDOSTAVLJENI_PODACI_O_GUMAMA_FILTERS,
  [Dep1IzvestajiEnum.SVI_PODACI]: () => [],
  [Dep2IzvestajiEnum.SVI_PODACI]: () => [],
  [Aao1IzvestajiEnum.PREGLED_PO_GODINAMA]: AAO_1_PREGLED_PO_GODINAMA_FILTERS,
  [Aao1IzvestajiEnum.PRIKAZ_1]: AAO_1_PRIKAZ_1_FILTERS,
  [Aao1IzvestajiEnum.SVI_PODACI]: AAO_1_SVI_PODACI_FILTERS,
  [Aao1IzvestajiEnum.NEDOSTAVLJENI_OBRASCI]: AAO_1_NEDOSTAVLJENI_OBRASCI_FILTERS,
  [Aao2IzvestajiEnum.IZVESTAJ_FOR_BOOK]: () => [],
  [PzvIzvestajiEnum.KOLICINE_PROIZVODA_PO_PREDUZECU]: PZV_KOLICINE_PROIZVODA_PO_PREDUZECU_FILTERS,
  [PzvIzvestajiEnum.KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU]: PZV_KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU_FILTERS,
};
