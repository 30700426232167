import { t } from 'i18next';
import { IGetCompanyWithSelfManagment, IPostCompanyWithSelfManagment } from './companyWithSelfManagment.types';
import { SIFARNICI_ROUTES, SearchScrollSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { FormInstance } from 'antd';

export const COMPANY_WITH_SELF_MANAGEMENT_INITIAL_STATE: IPostCompanyWithSelfManagment = {
  company_id: '',
  is_active: true,
};

export const COMPANY_WITH_SELF_MANAGEMENT_TABLE_DATA = [
  {
    title: `${t('COMPANY.TITLE')}`,
    width: '700px',
    dataIndex: ['preduzece', 'naziv'],
    key: 'naziv',
  },
];
export const COMPANY_WITH_SELF_MANAGEMENT_FORM_OPTIONS = (_: FormInstance<IGetCompanyWithSelfManagment>) => {
  return [
    {
      type_of_form: 'searchScroll',
      label: t('COMPANY.TITLE'),

      formName: 'company_id',
      // placeholder={t('COMPANY.TITLE')}
      fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE,
      filtersForFetch: {},
      labelOptionAccessor: ['naziv'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      objName: 'preduzece',
    } as SearchScrollSifarniciFormProps,
  ];
};
