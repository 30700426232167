import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import React from 'react';
import { NewPredatoKom1Form } from './components/NewPredatoKom1Form';
import { PREDATO_INITIAL_STATE } from 'modules/obrasci/store/kom1/kom1.constants';

export const PredatoModal: React.FC = observer(() => {
  return (
    <CRUDModal
      width="40%"
      name="predato-kom1-modal"
      title={'Predato'}
      store={kom1ObrazacStore}
      data={kom1ObrazacStore.getPredatoForm}
      children={({ form }) => <NewPredatoKom1Form form={form} />}
      submitForm={kom1ObrazacStore.submitPredatoForm}
      initialState={PREDATO_INITIAL_STATE}
      dataToChange={'predato_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      closeOneModal={true}
    />
  );
});
