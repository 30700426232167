import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { NewDeterdzentiForm } from './components/NewDeterdzentiForm/NewDeterdzentiForm';
import { pzv1ObrazacStore } from 'modules/obrasci/store/pzv1/pzv1.store';
import { DETERDZENTI_INITIAL_STATE } from 'modules/obrasci/store/pzv1/pzv1.constants';

export const DeterdzentiModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="70%"
      name="deterdzenti-modal"
      title={t('OBRASCI.PZV_1.DETERGENTS.TITLE')}
      store={pzv1ObrazacStore}
      data={pzv1ObrazacStore.getDeterdzent}
      children={() => <NewDeterdzentiForm />}
      submitForm={pzv1ObrazacStore.submitDeterdzentiForm}
      initialState={DETERDZENTI_INITIAL_STATE}
      dataToChange="deterdzent"
    />
  );
});
