import { observer } from 'mobx-react-lite';
import React from 'react';
import { DivljaDeponijaModal } from './DivljaDeponijaModal/DivljaDeponijaModal';

export const Dep2MicroServices: React.FC = observer(() => {
  return (
    <>
      <DivljaDeponijaModal />
    </>
  );
});
