import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { IGetAktivnostiObrazac1 } from './obrazac1.types';
import { arrayToObject } from 'utils/arrayToObject';
import { IGetActivity1 } from 'modules/activityList1/activityList1.types';

class Obrazac1Service {
  hideUkupnaKolicinaPoAktivnostima = () => {
    const isTabHidden =
      obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1 || Number(obrasciStore.opste.godina) < 2023;

    return isTabHidden;
  };

  validateLocalActivities = (
    localActivities: IGetAktivnostiObrazac1[],
    validActivities: IGetActivity1[]
  ): { areActivitiesValid: boolean; activities: IGetAktivnostiObrazac1[] } => {
    if (localActivities.length === 0 && validActivities.length === 0)
      return { areActivitiesValid: true, activities: localActivities };

    const validActivitiesObject = arrayToObject<string, IGetActivity1>(validActivities, 'aktivnost_id');
    let validActivityCounter = 0;

    const newActivites = localActivities.filter(activity => {
      const aktivnostId = activity.lista_aktivnosti1?.aktivnost_id;
      if (aktivnostId) {
        const isActivityValid = !!validActivitiesObject[aktivnostId];

        if (isActivityValid) {
          validActivityCounter++;
          delete validActivitiesObject[aktivnostId];
        }

        return isActivityValid;
      }
    });

    if (validActivityCounter === localActivities.length && Object.keys(validActivitiesObject).length === 0)
      return { areActivitiesValid: true, activities: localActivities };
    if (Object.keys(validActivitiesObject).length)
      Object.keys(validActivitiesObject).forEach(key =>
        newActivites.push(this.formatNewObrazac1Activity(validActivitiesObject[key]))
      );
    return { areActivitiesValid: false, activities: newActivites };
  };

  formatNewObrazac1Activity = (listActivity: IGetActivity1): IGetAktivnostiObrazac1 => {
    return {
      ukupna_kolicina_proizvoda: 0,
      lista_aktivnosti1_id: listActivity.id,
      lista_aktivnosti1: listActivity,
    };
  };

  // sortObrazac1Activites = () => {}
}

export const obrazac1Service = new Obrazac1Service();
