import { Col, Row, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { zakljucavanjeStore } from 'modules/zakljucavanje/zakljucavanje.store';
import { useEffect } from 'react';
import { ZakljucavanjePoGodiniObrazacRow } from '../ZakljucavanjePoGodiniObrazacRow/ZakljucavanjePoGodiniObrazacRow';
import styles from './ZakljucavanjePoGodiniObrasci.module.scss';
import { Button } from 'components/Button/Button';
import { OTKLJUCANI_OBRASCI } from 'modules/obrasci/obrasci.types';

export const ZakljucavanjePoGodiniObrasci = observer(() => {
  useEffect(() => {
    zakljucavanjeStore.fetchTipoviObrazacaPoGodini();
  }, [zakljucavanjeStore.selectedGodina]);

  const tipoviObrazacaComponentMap = zakljucavanjeStore.tipoviObrazacaPoGodini.length
    ? zakljucavanjeStore.tipoviObrazacaPoGodini.map(tip => {
        return <ZakljucavanjePoGodiniObrazacRow godinaTipObrasca={tip} key={tip.tip_obrasca_id} />;
      })
    : null;

  //todo: sredi stil
  const zakljucavanjeGodineTooltipTitle = (
    <Row>
      <p>Ovom akcijom se ne zakljucavaju sledeci obrasci:</p>
      <div>
        {OTKLJUCANI_OBRASCI.map(tip => (
          <p key={tip}>{`${tip};`}</p>
        ))}
      </div>
    </Row>
  );

  return (
    <div className={styles.zakljucavanjeGodinaObrasciWrapper}>
      <Row className={styles.zakljucavanjeGodinaObrasciHeader}>
        <Col span={18}>
          <h2>Obrazac</h2>
        </Col>
        <Col span={6}>
          <Tooltip title={zakljucavanjeGodineTooltipTitle} arrow={{ pointAtCenter: true }} placement="left">
            <Button
              name="zakljucaj-godinu"
              disabled={!zakljucavanjeStore.selectedGodina}
              onClick={() => zakljucavanjeStore.toggleLockTipObrasca(true)}
            >
              Zakljucaj sve obrasce za godinu
            </Button>
          </Tooltip>
        </Col>
      </Row>
      {tipoviObrazacaComponentMap}
    </div>
  );
});
