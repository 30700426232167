import { FormInstance, useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import {
  ISTORIJA_PROMENA_FILTERS_DATA,
  ISTORIJA_PROMENA_INITIAL_STATE,
} from 'modules/istorijaPromena/istroijaPrimena.constants';
import React from 'react';
import { IFilterTableProps } from 'typescript/NrizTypes';

export const IstorijaPromenaFilters: React.FC = observer(() => {
  const [istorijaPromenaForm] = useForm();
  const data: IFilterTableProps = {
    form: istorijaPromenaForm as FormInstance<any>,
    initialState: ISTORIJA_PROMENA_INITIAL_STATE,
    filterOptions: ISTORIJA_PROMENA_FILTERS_DATA,
    filterName: 'istorijaPromena',
  };

  return (
    <div id="istorijaPromenaFilters">
      <FilterTableForm data={data} />
    </div>
  );
});
