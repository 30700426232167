import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Gio2TableDozvole } from './components/Gio2TableDozvole/Gio2TableDozvole';

export const Gio2DozvoleWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'gio2-dozvole-form'} modalName="gio2-dozvole-modal" />}
    >
      <Gio2TableDozvole />
    </InsideContentWrapper>
  );
});
