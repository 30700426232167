import { EditOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import { Content } from 'components/Content/Content';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { nrizService } from 'modules/nriz/nriz.service';
import { OBRAZAC_TYPE_WIZARD_URL_MAP, disabledObrasci, opsteInitial } from 'modules/obrasci/obrasci.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { authStore, modalStore } from 'store';
import styles from './Obrasci.module.scss';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { Select } from 'components/Select/Select';
import { ObrasciFilters } from './ObrasciFilters/ObrasciFilters';
import { filtersStore } from 'store/filteri.store';
import { ObrasciCompanyUserFilters } from './ObrasciCompanyUserFilters/ObrasciCompanyUserFilters';
import { UserRoles } from 'modules/user/user.types';
import { KREIRAO_IZMENIO_TABLE_OBRAZAC } from 'modules/obrasci/tableDataObrasci.constants';
import { ExcelButton } from 'components/Buttons/ExcelButton/ExcelButton';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { Deo6Gio6Enum } from 'modules/obrasci/store/deo6/deo6.types';
import { ExportDeo6Gio6PdfModal } from 'components/Modals/ExportDeo6Gio6PdfModal/ExportDeo6Gio6PdfModal';

export const ObrasciFilterHeader = observer(() => {
  return (
    <div id="obrasciButtons">{authStore.getUserRole !== UserRoles.SystemAdmin && <ObrasciCompanyUserFilters />}</div>
  );
});

// ////////////////////// ////////////////////// ////////////////////// ////////////////////// ////////////////////// ////////////////////
export interface IObrasciHeaderProps {
  handleNewObrazac: () => void;
}

export const ObrasciHeader: React.FC<IObrasciHeaderProps> = observer(({ handleNewObrazac }) => {
  const { t } = useTranslation();

  return (
    <Row justify={'space-between'} id={'obrasciHeader'}>
      <Col span={6}>
        <h1>{t('OBRASCI.OBRASCI')}</h1>
      </Col>
      <Col span={18} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <Select
          showSearch
          placeholder={t('OBRASCI.PLACEHOLDER.CHOOSE_TIP_OBRASCA')}
          optionFilterProp="children"
          onChange={(e: string) => {
            if (authStore.getUserRole === UserRoles.SystemAdmin) {
              obrasciStore.handleChange('opste', opsteInitial);
            }
            filtersStore.setFilters('obrasci', {});
            obrasciService.handleSameObrazac(e as TIPOVI_OBRAZACA);
          }}
          value={obrasciStore.real_obrazac_type}
          filterOption={nrizService.handleSearchOptions}
          options={obrasciStore.tipoviObrazaca.map(tipO => ({
            label: `${tipO.skraceni_naziv} | ${tipO.naziv}`,
            value: tipO.skraceni_naziv,
            disabled: disabledObrasci.includes(tipO.skraceni_naziv),
            tipO: tipO,
          }))}
          name={'tip-obrasca'}
          style={{ marginRight: '5px', maxWidth: '350px' }}
        />

        <AddNewButton
          name={'user'}
          label={`Dodaj novi ${
            obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.DEO6_GIO6
              ? Deo6Gio6Enum.DEO6
              : obrasciStore.real_obrazac_type
          }`}
          onButtonClick={() => {
            handleNewObrazac();
          }}
        />

        {obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.DEO6_GIO6 && (
          <>
            <Button
              onClick={() => {
                obrasciStore.handleChange('isDeo6OrGio6', Deo6Gio6Enum.DEO6);
                modalStore.changeModalName('deo6-gio6-generisi-pdf-modal');
              }}
              type="primary"
              name={'generisi-deåo6'}
              style={{ marginRight: '5px' }}
            >
              Preuzmi {Deo6Gio6Enum.DEO6} Pdf
            </Button>
            <Button
              onClick={() => {
                obrasciStore.handleChange('isDeo6OrGio6', Deo6Gio6Enum.GIO6);
                modalStore.changeModalName('deo6-gio6-generisi-pdf-modal');
              }}
              type="primary"
              name={'generisi-gio6'}
              style={{ marginRight: '5px' }}
            >
              Preuzmi {Deo6Gio6Enum.GIO6} Pdf
            </Button>
          </>
        )}

        <ExcelButton onClick={() => obrasciStore.exportObrazacListExcel()} name={'obrasci-excel'} />
      </Col>
    </Row>
  );
});

// //////////////////// // ////////////////////// ////////////////////// ////////////////////// ////////////////////// ////////////////////

export const Obrasci = observer(() => {
  const navigate = useNavigate();

  const handleNewObrazac = () => {
    navigate(`${OBRAZAC_TYPE_WIZARD_URL_MAP[obrasciStore.real_obrazac_type]}${obrasciStore.tip_obrasca_id}`);
  };

  const [columnsObrasci, setColumnsObrasci] = useState<ColumnsType<any>>([]);
  useEffect(() => {
    if (obrasciStore.obrazac_type) {
      setColumnsObrasci([
        ...obrasciService.tableFinder(obrasciStore.obrazac_type),
        ...KREIRAO_IZMENIO_TABLE_OBRAZAC,
        {
          title: '',
          key: 'operation',
          fixed: 'right',
          width: '70px',
          align: 'center',
          render: (_, record) => {
            return (
              <Link
                to={`${OBRAZAC_TYPE_WIZARD_URL_MAP[obrasciStore.real_obrazac_type]}${obrasciStore.tip_obrasca_id}/${
                  record.id
                }/`}
              >
                <Button
                  icon={<EditOutlined rev={undefined} />}
                  name={`edit-obrazac-${obrasciStore.real_obrazac_type}`}
                />
              </Link>
            );
          },
        },
      ]);
    }
  }, [obrasciStore.obrasciList]);

  useEffect(() => {
    return () => {
      if (UserRoles.SystemAdmin === authStore.getUserRole) {
        obrasciStore.handleChange('opste', opsteInitial);
      }
      filtersStore.setFilters('obrasci', {});
    };
  }, []);

  return (
    <Content header={<ObrasciHeader handleNewObrazac={handleNewObrazac} />} className={styles.wrapper}>
      <ObrasciFilterHeader />
      {obrasciStore.real_obrazac_type && (
        <>
          <ObrasciFilters />
          <TableData
            scrollConfig={['#obrasciButtons', '#obrasciHeader', '#obrasciFilters']}
            name="obrasci"
            columns={[...columnsObrasci]}
            withPagination={true}
            forceFetch={[filtersStore.filters.obrasci]}
            getData={obrasciStore.fetchListObrazac}
            dataSource={obrasciStore.obrasciList}
          />
        </>
      )}

      <ExportDeo6Gio6PdfModal />
    </Content>
  );
});
