import { FormInstance } from 'antd';
import { IGetAprAddressData, IPostAprAddressData } from './aprAddressData.types';
import { t } from 'i18next';
import {
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  TextAreaSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import {
  MAX_LENGTH_RULE,
  PAK_NUMBER_FIELD_RULE,
  PIB_FIELD_RULE,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
export const APR_ADDRESS_DATA_INITIAL_STATE: IPostAprAddressData = {
  apr_id: '',
  typeofaddress_id: '',
  street: '',
  building_number: '',
  a_floor: '',
  apartment: '',
  letter: '',
  pak: '',
  postal_code: '',
  name_of_post_office: '',
  location_name_id: '',
  additional_address_data: '',
};

export const APR_ADDRESS_DATA_TABLE_DATA = [
  {
    title: `${t('SIFARNICI.APR')}`,
    // align: 'center',
    width: 100,
    dataIndex: ['apr', 'name'],
    key: 'apr',
  },
  {
    title: `${t('COMPANY.ADDRESS_TYPE')}`,
    // align: 'center',
    width: 100,
    dataIndex: ['typeofaddress', 'type_sr'],
    key: 'typeofaddress',
  },
  {
    title: `${t('COMPANY.ADDRESS')}`,
    // align: 'center',
    width: 100,
    dataIndex: 'street',
    key: 'street',
  },
  {
    title: `${t('COMPANY.ADDRESS_NUMBER')}`,
    // align: 'center',
    width: 100,
    dataIndex: 'building_number',
    key: 'building_number',
  },
  {
    title: `${t('COMPANY.FLOOR')}`,
    // align: 'center',
    width: 100,
    dataIndex: 'a_floor',
    key: 'a_floor',
  },
  {
    title: `${t('COMPANY.APARTMENT')}`,
    // align: 'center',
    width: 100,
    dataIndex: 'apartment',
    key: 'apartment',
  },
  {
    title: `${t('COMPANY.LETTER')}`,
    // align: 'center',
    width: 100,
    dataIndex: 'letter',
    key: 'letter',
  },
  {
    title: 'PAK',
    // align: 'center',
    width: 100,
    dataIndex: 'pak',
    key: 'pak',
  },
  {
    title: `${t('COMPANY.POST_NUMBER')}`,
    // align: 'center',
    width: 100,
    dataIndex: 'postal_code',
    key: 'postal_code',
  },
  {
    title: `${t('COMPANY.NAME_OF_POST_OFFICE')}`,
    // align: 'center',
    width: 100,
    dataIndex: 'name_of_post_office',
    key: 'letname_of_post_officeter',
  },
];

export const APR_ADDRESS_DATA_FORM_OPTIONS = (_: FormInstance<IGetAprAddressData>) => {
  return [
    {
      type_of_form: 'searchScroll',
      label: t('COMPANY.APR_TITLES.TITLE'),
      formName: 'apr_id',
      fetchOptinsList: SIFARNICI_ROUTES.APR,
      filtersForFetch: {},
      labelOptionAccessor: ['full_company_name'],
      valueOptionAccessor: 'id',
      // disabledAccessor: 'is_active',
      rules: [REQUIRED_FIELD_RULE(true)],
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: t('COMPANY.ADDRESS_TYPE'),
      formName: 'typeofaddress_id',
      fetchOptinsList: SIFARNICI_ROUTES.APR_TYPE_ADDRESS,
      filtersForFetch: {},
      labelOptionAccessor: ['type_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('COMPANY.ADDRESS'),
      name: 'street',
      inputName: 'street',
      rules: [MAX_LENGTH_RULE(50)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('COMPANY.ADDRESS_NUMBER'),
      name: 'building_number',
      inputName: 'building_number',
      // type: 'number',
      rules: [MAX_LENGTH_RULE(20)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('COMPANY.FLOOR'),
      name: 'a_floor',
      inputName: 'a_floor',
      rules: [MAX_LENGTH_RULE(20)],
      precision: 0,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('COMPANY.APARTMENT'),
      name: 'apartment',
      inputName: 'apartment',
      rules: [MAX_LENGTH_RULE(20)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('COMPANY.LETTER'),
      name: 'letter',
      inputName: 'letter',
      rules: [MAX_LENGTH_RULE(20)],
    } as InputSifarniciFormProps,

    {
      type_of_form: 'area',
      label: t('COMPANY.ADDITIONAL_ADDRESS_INFO'),
      name: 'additional_address_data',
      rules: [MAX_LENGTH_RULE(200)],
      rows: 2,
      labelCol: 10,
      wrapperCol: 14,
    } as TextAreaSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: t('COMPANY.LOCATION_NAME'),
      formName: 'location_name_id',
      fetchOptinsList: SIFARNICI_ROUTES.MESTO,
      objName: 'location',
      filtersForFetch: {
        location_code__neq: 999999,
      },
      labelOptionAccessor: ['display_name'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input',
      label: 'PAK',
      name: 'pak',
      inputName: 'pak',
      rules: [PIB_FIELD_RULE],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('COMPANY.POST_NUMBER'),
      name: 'postal_code',
      inputName: 'postal_code',
      rules: [PAK_NUMBER_FIELD_RULE],
      type: 'number',
      precision: 0,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('COMPANY.NAME_OF_POST_OFFICE'),
      name: 'name_of_post_office',
      inputName: 'name_of_post_office',
      rules: [MAX_LENGTH_RULE(50)],
      type: 'text',
    } as InputSifarniciFormProps,
  ];
};
