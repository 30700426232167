import { Col } from 'antd';
import { observer } from 'mobx-react-lite';
import { MAX_AND_MIN_NUMBER, MORE_THEN_0 } from 'modules/nriz/nriz.constants';
import { useTranslation } from 'react-i18next';
import { Obrazac3GlavniIndustrijskiPotrosaciTable } from './Obrazac3GlavniIndustrijskiPotrosaciTable/Obrazac3GlavniIndustrijskiPotrosaciTable';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import styles from './NewObrazac3VodovodniSistemiForm.module.scss';
import { Obrazac3Sistem } from 'modules/obrasci/store/obrazac3/obrazac3.types';

export const NewObrazac3VodovodniSistemiForm: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <>
      <Col span={24} className={styles.wrapper}>
        <FormInputNumber
          name="ukupna_povrsina_teritorije_opstine"
          label={t('OBRASCI.OBRAZAC3.UKUPNA_POVRSINA_OPSTINA')}
          placeholder={''}
          inputName={'ukupna_povrsina_teritorije_opstine'}
          colon={true}
          rules={MORE_THEN_0}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        />
        <FormInputNumber
          name="procenat_ukupnog_stanovnistva_prikljucenog_na_vodovod"
          label={t('OBRASCI.OBRAZAC3.PROCENAT_UKUPNOG_STANOVNISTVA_VODOVOD')}
          placeholder={''}
          inputName={'procenat_ukupnog_stanovnistva_prikljucenog_na_vodovod'}
          colon={true}
          rules={MAX_AND_MIN_NUMBER(1, 100)}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        />
        <FormInputNumber
          name="kolicina_proizvedene_vode"
          label={t('OBRASCI.OBRAZAC3.KOLICINA_PROIZVEDENE_VODE')}
          placeholder={''}
          inputName={'kolicina_proizvedene_vode'}
          colon={true}
          rules={MORE_THEN_0}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        />
        <FormInputNumber
          name="ukupna_kolicina_isporucene_vode_svim_potrosacima"
          label={t('OBRASCI.OBRAZAC3.UKUPNA_KOLICINA_POTROSACIMA')}
          placeholder={''}
          inputName={'ukupna_kolicina_isporucene_vode_svim_potrosacima'}
          colon={true}
          rules={MORE_THEN_0}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        />
        <FormInputNumber
          name="ukupna_kolicina_isporucene_vode_stanovnistvu"
          label={t('OBRASCI.OBRAZAC3.UKUPNA_KOLICINA_STANOVNISTVU')}
          placeholder={''}
          inputName={'ukupna_kolicina_isporucene_vode_stanovnistvu'}
          colon={true}
          rules={MORE_THEN_0}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        />
        <FormInputNumber
          name="ukupna_kolicina_isporucene_vode_industriji_i_drugim_institucijama"
          label={t('OBRASCI.OBRAZAC3.UKUPNA_KOLICINA_INDUSTRIJI')}
          placeholder={''}
          inputName={'ukupna_kolicina_isporucene_vode_industriji_i_drugim_institucijama'}
          colon={true}
          rules={MORE_THEN_0}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        />
      </Col>
      <Col span={24}>
        <InsideContentWrapper
          header={
            <WizardObrazacListHeader
              name={'obrazac3-analiza-otpadne-vode-form'}
              modalName={`obrazac3-glavni-potrosaci-${Obrazac3Sistem.VODOVODNI}-modal`}
              title={t('OBRASCI.OBRAZAC3.NAJVECI_INDUSTRIJSKI')}
            />
          }
        >
          <Obrazac3GlavniIndustrijskiPotrosaciTable sistem={Obrazac3Sistem.VODOVODNI} />
        </InsideContentWrapper>
      </Col>
    </>
  );
});
