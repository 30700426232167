import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { waterStore } from 'modules/sourcesOfPollution/water/water.store';
import { IGetWater } from 'modules/sourcesOfPollution/water/water.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const WaterListTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<IGetWater> = [
    {
      title: `${t('POLLUTION.WATER.SEWER_NUMBER')}`,
      width: 100,
      dataIndex: 'broj_ispusta',
      key: 'broj_ispusta',
    },
    {
      title: `${t('POLLUTION.WATER.SEWER_NAME')}`,
      width: 100,
      dataIndex: 'naziv_ispusta',
      key: 'naziv_ispusta',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-water'}
            onClick={() => {
              waterStore.setWater(record);
              modalStore.changeModalName('water-modal');
              waterStore.setWaterFormData(true);
              if (!waterStore?.water?.postojanje_postrojenja_za_preciscavanje) {
                waterStore.waterShowDevices = false;
              }
              waterStore.checkIfWaterTypeOfDischargedTrue(record);
            }}
          />
        );
      },
    },
  ];
  return (
    <TableData
      name="water"
      columns={columns}
      dataSource={[...waterStore.getWaterList]}
      getData={waterStore.fetchWaterList}
      withPagination={true}
      forceFetch={[]}
    />
  );
});
