import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { NewGveDodatniPodaciForm } from '../../GveMicroServices/components/NewGveDodatniPodaciForm/NewGveDodatniPodaciForm';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';

export const GveDodatniPodaciWizardTab: React.FC<ICrudTypeModalProps> = observer(props => {
  return (
    <InsideContentWrapper header={null}>
      <NewGveDodatniPodaciForm form={props.form} />
    </InsideContentWrapper>
  );
});
