import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { UsersListTable } from 'components/Tables';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FacilityUserModal } from 'components/Modals/FacilityUserModal/FacilityUserModal';
import { authStore, modalStore } from 'store';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExcelAndAddButton } from 'components/ExcelAndAddButton/ExcelAndAddButton';
import { filtersStore } from 'store/filteri.store';
import { userStore } from 'modules/user/user.store';
import { UserRoles } from 'modules/user/user.types';
import { facilityStore } from 'modules/facility/facility.store';

export const FacilityUsersListHeader: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Row justify={'space-between'} align={'middle'}>
      <h3>{t('USER.LIST')}</h3>
      <ExcelAndAddButton
        excelFunc={() => console.log('excel')}
        excelName={'user'}
        addButtonLabel="Dodaj korisnika na postrojenje"
        addButtonOnChange={
          authStore.getUserRole !== UserRoles.CompanyUser && facilityStore.getFacility.is_active
            ? () => {
                modalStore.changeModalName('facility-user-modal');
              }
            : undefined
        }
      />
    </Row>
  );
});

export const FacilityUsersList: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<FacilityUsersListHeader />}>
      <UsersListTable
        fetchListTable={userStore.fetchUserFacilityList}
        isFacility={true}
        filterName={'korisnik_postrojenje'}
        forceFetch={filtersStore.filters.korisnik_postrojenje}
      />
      <FacilityUserModal />
    </InsideContentWrapper>
  );
});
