import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { NewObrazac3DodatniPodaciForm } from '../../Obrazac3MicroServices/components/NewObrazac3DodatniPodaciForm/NewObrazac3DodatniPodaciForm';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';

export const Obrazac3DodatniPodaciWizardTab: React.FC<ICrudTypeModalProps> = observer(props => {
  return (
    <InsideContentWrapper header={null}>
      <NewObrazac3DodatniPodaciForm form={props.form} />
    </InsideContentWrapper>
  );
});
