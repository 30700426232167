import { Form } from 'antd';
import { DatePicker } from 'components/DayjsPicker/DayjsPicker';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IRangePickerProps } from 'typescript';

export const FormDatePicker: React.FC<IRangePickerProps> = observer(props => {
  return (
    <Form.Item
      label={props.label}
      labelCol={props.labelCol}
      wrapperCol={props.wrapperCol}
      rules={props.rules}
      style={props.style}
      className={props.className}
      name={props.name}
      colon={props.colon}
    >
      <DatePicker
        defaultValue={props.defaultValue}
        defaultPickerValue={props.defaultPickerValue}
        disabled={props.disabled}
        name={props.name}
        inputReadOnly={true}
        format={props.format}
        picker={props.picker ? props.picker : 'date'}
        disabledDate={props.disabledDate}
        onSelect={props.onChange}
        placeholder={props.placeholder ? props.placeholder : ''}
        onChange={value => {
          // todo: proveriti da li nekom ovo menjanje remeti u njegovom kodu
          // if (!value) {
          props.onChange && props.onChange(value);
          // }
        }}
      />
    </Form.Item>
  );
});
