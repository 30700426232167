import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { KOLICINA_INITIAL_STATE } from 'modules/obrasci/store/kom1/kom1.constants';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { useTranslation } from 'react-i18next';
import { NewKolicineForm } from './components/NewKolicineForm/NewKolicineForm';

export const KolicineModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  //   todo: prevod
  return (
    <CRUDModal
      width="40%"
      name="kolicine-modal"
      title={t('OBRASCI.KOM_1.QUANTITY')}
      store={kom1ObrazacStore}
      data={kom1ObrazacStore.getKolicineForm}
      children={({ form }) => <NewKolicineForm form={form} />}
      submitForm={kom1ObrazacStore.submitKolicinaForm}
      initialState={KOLICINA_INITIAL_STATE}
      dataToChange={'kolicine_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    />
  );
});
