import { IPostSoil } from './soil.types';

export const soilInitialState: IPostSoil = {
  broj_lokacije: '',
  naziv_lokacije: '',
  aktivan: false,
  zakljucane_koordinate: false,
  koordinate_long: null,
  koordinate_lat: null,
  napomena: '',
};
