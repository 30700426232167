import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Gio3TableDozvole } from './components/Gio3TableDozvole/Gio3TableDozvole';

export const Gio3DozvoleWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'gio3-dozvole-form'} modalName="gio3-dozvole-modal" />}
    >
      <Gio3TableDozvole />
    </InsideContentWrapper>
  );
});
