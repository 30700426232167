import { observer } from 'mobx-react-lite';
import React from 'react';
import { KomponenteObrazac5Modal } from './components/KomponenteObrazac5Modal/KomponenteObrazac5modal';
import { Obrazac5UpravljanjeModal } from './components/UpravljanjeObrazac5Modal/Obrazac5UpravljanjeModal';

export const Obrazac5MicroServices: React.FC = observer(() => {
  return (
    <>
      <KomponenteObrazac5Modal />
      <Obrazac5UpravljanjeModal />
    </>
  );
});
