import { ColumnsType } from 'antd/es/table';
import { IGetKom1TypeOfWaste, IPostKom1TypeOfWaste } from './kom1TypeOfWaste.types';
import { FormInstance } from 'antd';
import { THE_ORDER_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { THE_ORDER_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { t } from 'i18next';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';

export const KOM1_TYPE_OF_WASTE_INITIAL_STATE: IPostKom1TypeOfWaste = {
  type_of_waste: '',
  type_of_waste_sr: '',
  the_order: '',
  is_active: true,
};
export const KOM1_TYPE_OF_WASTE_TABLE_DATA: ColumnsType<IGetKom1TypeOfWaste> = [
  {
    title: t('OBRASCI.KOM_1.TYPE_OF_WASTE'),
    width: '200px',
    dataIndex: 'type_of_waste',
    key: 'type_of_waste',
  },

  {
    title: `${t('OBRASCI.KOM_1.TYPE_OF_WASTE')} ${t('NA_SRPSKOM')}`,
    width: '200px',
    dataIndex: 'type_of_waste_sr',
    key: 'type_of_waste_sr',
  },

  THE_ORDER_TABLE,
];
export const KOM1_TYPE_OF_WASTE_FORM_OPTIONS = (_: FormInstance<IGetKom1TypeOfWaste>) => {
  return [
    {
      type_of_form: 'input',
      name: 'type_of_waste',
      label: t('OBRASCI.KOM_1.TYPE_OF_WASTE'),
      inputName: 'type_of_waste',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'type_of_waste_sr',
      label: `${t('OBRASCI.KOM_1.TYPE_OF_WASTE')} ${t('NA_SRPSKOM')}`,
      inputName: 'type_of_waste_sr',
    } as InputSifarniciFormProps,
    THE_ORDER_FORM,
  ];
};
