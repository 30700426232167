import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewKomponenteObrazac5Form } from './NewKomponenteObrazac5Form/NewKomponenteObrazac5Form';
import { obrazac5Store } from 'modules/obrasci/store/obrazac5/obrazac5.store';
import { OBRAZAC_5_KOMPONENTE_INITIAL } from 'modules/obrasci/store/obrazac5/obrazac5.constants';

export const KomponenteObrazac5Modal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="50%"
      name="obrazac5-komponente-modal"
      title={t('WIZARD_TABS.KOMPONENTE')}
      store={obrazac5Store}
      data={obrazac5Store.getKomponenteForm}
      children={() => <NewKomponenteObrazac5Form />}
      submitForm={obrazac5Store.submitKomponenteForm} /////
      initialState={OBRAZAC_5_KOMPONENTE_INITIAL} /////
      dataToChange={'komponente_form'}
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
    />
  );
});
