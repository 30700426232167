import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import {
  IGetObrazac3GlavniIndustrijskiPotrosaciVode,
  Obrazac3Sistem,
} from 'modules/obrasci/store/obrazac3/obrazac3.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export interface GlavniIndustrijskiPotrosaciProps {
  sistem: Obrazac3Sistem;
}

export const Obrazac3GlavniIndustrijskiPotrosaciTable: React.FC<GlavniIndustrijskiPotrosaciProps> = observer(
  ({ sistem }) => {
    const { t } = useTranslation();

    const baseColumns: ColumnsType<IGetObrazac3GlavniIndustrijskiPotrosaciVode> = [
      {
        title: `${t('OBRASCI.OBRAZAC3.NAZIV_PREDUZECA')}`,
        width: 200,
        dataIndex: 'naziv',
        key: 'naziv',
      },
      {
        title: `${t('COMPANY.PRINCIPAL_BUSINESS_ACTIVITY_CODE')}`,
        width: 200,
        dataIndex: ['sifra_pretezne_delatnosti', 'name_sr'],
        key: 'sifra_pretezne_delatnosti',
      },
      {
        title: `${t('OBRASCI.OBRAZAC3.KOLICINA_ISPUSTENE')}`,
        width: 200,
        dataIndex: 'kolicina_isporucene_vode',
        key: 'kolicina_isporucene_vode',
      },
      {
        key: 'operation',
        fixed: 'right',
        width: 150,
        align: 'center',
        render: (_, record: IGetObrazac3GlavniIndustrijskiPotrosaciVode) => {
          return (
            <Button
              icon={<EditOutlined rev={undefined} />}
              name={'edit-komponente'}
              onClick={() => {
                obrazac3Store.handleChange('industrijski_potrosaci_form', record);
                modalStore.changeModalName(`obrazac3-glavni-potrosaci-${sistem}-modal`);
              }}
            />
          );
        },
      },
    ];

    const dataSource = [
      ...obrazac3Store.initialState.glavni_industrijski_potrosaci_vode.filter(potrosac => potrosac[sistem]),
    ];

    return <TableData name="glavni-industrijski-potrosaci-obrazac3" columns={baseColumns} dataSource={dataSource} />;
    // resi store
  }
);
