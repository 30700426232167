import { IPostEmisijeUTlo, IPostObrazac4 } from './obrazac4.types';

export const OBRAZAC_4_INITIAL_STATE: IPostObrazac4 = {
  godina: '',
  preduzece_id: '',
  postrojenje_id: '',
  izvor_zagadjenja_tla_id: '',
  waste_catalogue_id: '',
  type_of_waste_id: '',
  godisnja_kolicina_odlozenog_otpada: null,
  ukupna_kolicina_odlozenog_otpada: null,
  dr_list_id: '',
  napomena: '',
  emisije_u_tlo: [],
  obrazac_4: false,
  lriz_obrazac_4: false,
};

export const EMISIJE_U_TLO_INITIAL_STATE: IPostEmisijeUTlo = {
  pollutant_code_id: '',
  koncetracija_zagadjujuce_materije_u_otpadu: null,
  kolicina_zagadjujuce_materije_u_odlozenom_otpadu: null,
  method_basis_code_id: '',
  method_types_id: '',
};
