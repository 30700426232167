import { observer } from 'mobx-react-lite';
import styles from './LogOutButton.module.scss';
import { authStore } from 'store';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

export const LogOutButton: React.FC = observer(() => {
  return (
    <div className={styles.logout_div}>
      <Button
        ghost
        onClick={() => {
          authStore.keycloak.logout();
        }}
        disabled={false}
        className={styles.logout_btn}
      >
        <LogoutOutlined rev={undefined} style={{ fontSize: '18px' }} />
        Odjava
      </Button>
    </div>
  );
});
