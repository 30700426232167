import { IPagination } from 'typescript';
import { userRepo } from './user.repo';
import { IGetUser, IPostUser, IPostUserResponsibility } from './user.types';
import { cloneDeep } from 'lodash';

class UserService {
  fetchUserList = (payload: Partial<IPagination>) => {
    return userRepo.fetchUserList(payload);
  };

  fetchUserCompanyList = (payload: any) => {
    return userRepo.fetchUserCompanyList(payload);
  };

  fetchUserFacilityList = (payload: any) => {
    return userRepo.fetchUserFacilityList(payload);
  };

  exportUsersListExcel = (filters: any) => {
    return userRepo.exportUsersListExcel(filters);
  };

  fetchUser = (id: string) => {
    return userRepo.fetchUser(id);
  };

  putUserResponsible = (id: string, facilityId: { postrojenje_id: string }) => {
    return userRepo.putUserResponsible(id, facilityId);
  };

  putUser = (user: IGetUser) => {
    return userRepo.putUser(user);
  };

  postUser = (user: Partial<IPostUser>) => {
    return userRepo.postUser(user);
  };

  postUserRegistration = (user: IPostUser) => {
    return userRepo.postUserRegistration(user);
  };

  postUserFacility = (userID: string, payload: any) => {
    return userRepo.postUserFacility(userID, payload);
  };

  postUserAdministrator = (user: IPostUser) => {
    return userRepo.postUserAdministrator(user);
  };

  deleteUser = (id: string) => {
    return userRepo.deleteUser(id);
  };
  deleteFacilityUser = (id: string) => {
    return userRepo.deleteFacilityUser(id);
  };

  aktivirajKorisnika = (id: string) => {
    return userRepo.aktivirajKorisnika(id);
  };
  deaktivirajKorisnika = (id: string) => {
    return userRepo.deaktivirajKorisnika(id);
  };
  putUserCompanyResponsibility = (id: string, obj: IPostUserResponsibility) => {
    return userRepo.putUserCompanyResponsibility(id, obj);
  };
  changeCompanyOwner = (id: string) => {
    return userRepo.changeCompanyOwner(id);
  };

  toggleBooleanToFalseInList = (items: any[], key: string) => {
    return items.map((item: any) => {
      return cloneDeep({ ...item, [key]: false });
    });
  };

  deactivateUserList = (items: IGetUser[]) => {
    return items.map((item: IGetUser) => {
      if (item.main_company_user) {
        return item;
      }
      return { ...item, is_active: false };
    });
  };
}

export const userService = new UserService();
