import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrazac4Store } from 'modules/obrasci/store/obrazac4/obrazac4.store';
import { IEmisijeUTlo } from 'modules/obrasci/store/obrazac4/obrazac4.types';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const EmisijeUTloTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<IEmisijeUTlo> = [
    {
      title: `${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.WASTE_NAME')}`,
      width: 50,
      align: 'center',
      dataIndex: ['pollutant_code', 'name_sr'],
    },
    {
      title: `${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.CONCENTRATION')}`,
      width: 50,
      align: 'center',
      dataIndex: 'koncetracija_zagadjujuce_materije_u_otpadu',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.QUANTITY')}`,
      width: 50,
      align: 'center',
      dataIndex: 'kolicina_zagadjujuce_materije_u_odlozenom_otpadu',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.METHOD')}`,
      width: 60,
      align: 'center',
      dataIndex: ['method_types', 'name_sr'],
    },
    {
      title: `${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.WAY')}`,
      width: 50,
      align: 'center',
      dataIndex: ['method_basis_code', 'name_sr'],
    },
    {
      title: '',
      fixed: 'right',
      width: 20,
      render: (_, record) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-activity'}
            onClick={() => {
              obrazac4Store.handleChange('filterNacinOdredjivanjaId', record.method_basis_code_id);
              obrazac4Store.handleChange('emisija', record);
              modalStore.changeModalName(`emisije-u-tlo-modal`);
            }}
          />
        );
      },
    },
  ];

  return (
    <TableData
      name="emisije-u-tlo-obrazac4"
      columns={columns}
      dataSource={[...obrazac4Store.getInitialState.emisije_u_tlo]}
    />
  );
});
