import { omit } from 'lodash';
import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import { ITipObrasca } from 'modules/obrasci/obrasci.types';
import queryString from 'query-string';
import { IPagination } from 'typescript';
import {
  ISurvey,
  ResponseFacility,
  IGetFacility,
  IPostFacilityTipoviObrazaca,
  IPostFacility,
  RequestFacilityList,
} from './facility.types';

const postSurvey = (payload: ISurvey): ApiResponse<{ id: string }> => {
  return axios.post(`${ROUTES.SURVEY}`, payload);
};

const fetchFacilityList = (payload: RequestFacilityList): ApiResponse<ResponseFacility> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.FACILITY}/?${query}`);
};

const fetchFacilityListByUser = (payload: Partial<IPagination>): ApiResponse<ResponseFacility> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.USER}${ROUTES.FACILITY}/?${query}`);
};

const fetchFacility = (id: string): ApiResponse<IGetFacility> => {
  return axios.get(`${ROUTES.FACILITY}/${id}`);
};

const fetchFacilityTipObrasca = (facilityId: string): ApiResponse<ITipObrasca[]> => {
  return axios.get(`${ROUTES.FACILITY}/${facilityId}/tip-obrasca`);
};

const postFacilityTipObrasca = (facilityId: string, tipoviObrazca: IPostFacilityTipoviObrazaca): ApiResponse<any> => {
  return axios.post(`${ROUTES.FACILITY}/${facilityId}/tip-obrasca`, tipoviObrazca);
};

const postFacility = (facility: IPostFacility): ApiResponse<{ id: string }> => {
  return axios.post(`${ROUTES.FACILITY}/`, facility);
};

const putFacility = (facility: IGetFacility) => {
  const putFacilityObj = omit(facility, ['id']);
  return axios.put(`${ROUTES.FACILITY}/${facility.id}`, putFacilityObj);
};

const deleteFacility = (id: string) => {
  return axios.delete(`${ROUTES.FACILITY}/${id}`);
};

const aktivirajPostrojenje = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.FACILITY}/${id}/aktiviraj`);
};
const deaktivirajPostrojenje = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.FACILITY}/${id}/deaktiviraj`);
};

export const facilityRepo = {
  fetchFacility,
  fetchFacilityTipObrasca,
  postFacilityTipObrasca,
  fetchFacilityList,
  postSurvey,
  postFacility,
  aktivirajPostrojenje,
  deaktivirajPostrojenje,
  putFacility,
  deleteFacility,
  fetchFacilityListByUser,
};
