import { ColumnsType } from 'antd/es/table';
import { IGetDepFormAnswers, IPostDepFormAnswers } from './depFormsAnswers.types';
import { FormInstance } from 'antd';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';

export const DEP_FORMS_ANSWERS_INITIAL_STATE: IPostDepFormAnswers = {
  answercode: '',
  answer: '',
  answer_sr: '',
  is_active: true,
};
export const DEP_FORMS_ANSWERS_TABLE_DATA: ColumnsType<IGetDepFormAnswers> = [
  {
    title: 'Odgovor',
    width: '250px',
    dataIndex: 'answer',
    key: 'answer',
  },
  {
    title: 'Odgovor (srp.)',
    width: '250px',
    dataIndex: 'answer_sr',
    key: 'answer_sr',
  },
  {
    title: 'Šifra odgovora',
    width: '100px',
    dataIndex: 'answercode',
    key: 'answercode',
  },
];
export const DEP_FORMS_ANSWERS_FORM_OPTIONS = (_: FormInstance<IGetDepFormAnswers>) => {
  return [
    {
      type_of_form: 'input',
      name: 'answer',
      label: 'Odgovor',
      inputName: 'answer',
      // placeholder: t('COMPANY.PLACEHOLDER.ENTER_SHORT_NAME'),
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'answer_sr',
      label: 'Odgovor (srp.)',
      inputName: 'answer_sr',
      // placeholder: t('COMPANY.PLACEHOLDER.ENTER_SHORT_NAME'),
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'answercode',
      label: 'Šifra odgovora',
      inputName: 'answercode',
      // placeholder: t('COMPANY.PLACEHOLDER.ENTER_SHORT_NAME'),
    } as InputSifarniciFormProps,
  ];
};
