import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd';
import { AAO2_UPRAVLJANJE_AMBALAZNIM_OTPADOM_INITIAL_STATE } from 'modules/obrasci/store/aao2/aao2.constants';
import { aao2Store } from 'modules/obrasci/store/aao2/aao2.store';
import { NewAao2UpravljanjeAmbalaznimOtpadomForm } from './components/NewAao2UpravljanjeAmbalaznimOtpadomForm';

export const Aao2UpravljanjeAmbalaznimOtpadomModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="70%"
      name="aao2-upravljanje-ambalaznim-otpadom-modal"
      title={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.TITLE')}
      store={aao2Store}
      data={aao2Store.getUpravljanjeAmbalaznimOtpadom}
      children={({ form }: { form: FormInstance<any> }) => <NewAao2UpravljanjeAmbalaznimOtpadomForm form={form} />}
      submitForm={aao2Store.submitUpravljanjeAmbOtpadom}
      initialState={AAO2_UPRAVLJANJE_AMBALAZNIM_OTPADOM_INITIAL_STATE}
      dataToChange={'upravljanjeAmbalaznimOtpadom'}
    />
  );
});
