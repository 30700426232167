import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { dep1Store } from 'modules/obrasci/store/dep1/dep1.store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { useEffect } from 'react';
import { IGetDep1 } from 'modules/obrasci/store/dep1/dep1.types';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { useParams } from 'react-router-dom';
import styles from './Dep1OpsteWizardTab.module.scss';
import { Col } from 'antd';

export const Dep1OpsteWizardTab: React.FC = observer(() => {
  const { t } = useTranslation();
  const { id } = useParams();

  const defaultNesanitarnaOption = dep1Store.getInitialState.nesanitarna_deponija_id
    ? {
        label: (dep1Store.initialState as IGetDep1).nesanitarna_deponija.naziv,
        value: dep1Store.initialState.nesanitarna_deponija_id,
      }
    : undefined;

  useEffect(() => {
    if (
      obrasciStore.opste.godina &&
      obrasciStore.opste.preduzece_id !== '' &&
      dep1Store.getFilters.nesanitarna_deponija_id !== ''
    ) {
      obrasciStore.setDisabledTabButton(false);
    }
  }, [dep1Store.getFilters, obrasciStore.opste]);

  return (
    <Col span={12} className={styles.wrapper}>
      <OpsteGodinaPreduzecePostrojenje withPostrojenje={false} store={dep1Store} />

      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('UNSANITARY_LANDFILL.TITLE')}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        placeholder={t('UNSANITARY_LANDFILL.NAME')}
        formName={'nesanitarna_deponija_id'}
        fetchOptinsList={dep1Store.fetchNesanitarnaDeponije}
        labelOptionAccessor="naziv"
        valueOptionAccessor="id"
        readOnly={false}
        wait={!!id || obrasciStore.opste.preduzece_id === ''}
        defaultOption={defaultNesanitarnaOption}
        onChange={value => {
          dep1Store.setFilters('nesanitarna_deponija_id', value);
        }}
        notFoundContent="Dodajte nesanitarnu deponiju na preduzecu"
        rules={[REQUIRED_FIELD_RULE(true)]}
        disabled={
          obrasciStore.opste.godina === '' ||
          dep1Store.getFilters.nesanitarna_deponija_id !== '' ||
          !obrasciStore.disabled_tab_button
        }
      />
    </Col>
  );
});
