import { observer } from 'mobx-react-lite';
import React from 'react';
import { DjubrivaModal } from './DjubrivaModal/DjubrivaModal';
import { DeterdzentiModal } from './DeterdzentiModal/DeterdzentiModal';
import { ZastitaBiljaModal } from './ZastitaBiljaModal/ZastitaBiljaModal';

export const PZV1MicroServices: React.FC = observer(() => {
  return (
    <>
      <DeterdzentiModal />
      <DjubrivaModal />
      <ZastitaBiljaModal />
    </>
  );
});
