import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrazac1Store } from 'modules/obrasci/store/obrazac1/obrazac1.store';
import { IGetProizvodiObrazac1, IProizvodiObrazac1 } from 'modules/obrasci/store/obrazac1/obrazac1.types';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const TableProizvodiObrazac1: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetProizvodiObrazac1> = [
    {
      title: `${t('OBRASCI.OBRAZAC_1.PRODUCT_KEY')}`,
      width: '150px',
      dataIndex: 'sifra_proizvoda',
      key: 'sifra_proizvoda',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.PRODUCT_NAME')}`,
      width: '150px',
      dataIndex: 'naziv_proizvoda',
      key: 'naziv_proizvoda',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.DESCRIPTION')}`,
      dataIndex: 'opis',
      key: 'opis',
      width: '150px',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.UNIT_OF_MEASURE')}`,
      dataIndex: ['jedinica_mere', 'name_sr'],
      key: 'name_sr',
      width: '150px',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.ANNUAL_PRODUCTION')}`,
      width: '150px',
      dataIndex: 'godisnja_proizvodnja',
      key: 'godisnja_proizvodnja',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.INSTALLED_CAPACITIES')}`,
      dataIndex: 'instalirani_kapaciteti',
      key: 'instalirani_kapaciteti',
      width: '150px',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.AVERAGE_ENGAGED_CAPACITIES')}`,
      width: '150px',
      dataIndex: 'prosecno_angazovani_kapacitet',
      key: 'prosecno_angazovani_kapacitet',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.METHOD_OF_STORAGE')}`,
      dataIndex: 'nacin_lagerovanja',
      key: 'nacin_lagerovanja',
      width: '150px',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.MAXIMUM_STORAGE_CAPACITY')}`,
      width: '150px',
      dataIndex: 'maksimalni_kapacitet_lagera',
      key: 'maksimalni_kapacitet_lagera',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.AVERAGE_AMOUNT_OF_STORAGE')}`,
      dataIndex: 'prosecna_kolicina_na_lageru',
      key: 'prosecna_kolicina_na_lageru',
      width: '150px',
    },
    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (_, record: IProizvodiObrazac1) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-proizvodi'}
            onClick={() => {
              obrazac1Store.handleChange('proizvodi_form', record);
              modalStore.changeModalName('proizvodi-obrazac-1-modal');
            }}
          />
        );
      },
    },
  ];
  // todo: check if backend return whole array or we have to do it with pagination
  return (
    <TableData name="proizvodi-obrazac1" columns={baseColumns} dataSource={[...obrazac1Store.initialState.proizvodi]} />
  );
});
