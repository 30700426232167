import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { KOLICINE_GIO_3_INITIAL_STATE } from 'modules/obrasci/store/gio3/gio3.constants';
import { gio3ObrazacStore } from 'modules/obrasci/store/gio3/gio3.store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewKolicineGio3Form } from './components/NewKolicineGio3Form/NewKolicineGio3Form';

export const Gio3KolicineModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="70%"
      name="gio3-kolicine-modal"
      title={t('OBRASCI.KOM_1.QUANTITY')}
      store={gio3ObrazacStore}
      data={gio3ObrazacStore.getKolicineForm}
      children={({ form }) => <NewKolicineGio3Form form={form} />}
      submitForm={gio3ObrazacStore.submitKolicineForm}
      initialState={KOLICINE_GIO_3_INITIAL_STATE}
      dataToChange={'kolicine_form'}
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
      onCancel={() => {
        gio3ObrazacStore.handleChange('index_id', '');
      }}
    />
  );
});
