import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, NAME_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IGetLandfillType } from '../landfillType/landfillType.types';
import { IPostWorkingRegime } from './workingRegime.types';

export const WORKING_REGIME_INITIAL_STATE: IPostWorkingRegime = {
  name: '',
  name_sr: '',
  is_active: true,
};
export const WORKING_REGIME_TABLE_DATA: ColumnsType<IGetLandfillType> = [NAME_TABLE, NAME_SR_TABLE];
export const WORKING_REGIME_FORM_OPTIONS = (_: FormInstance<IGetLandfillType>) => [NAME_FORM, NAME_SR_FORM];
