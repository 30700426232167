import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import React from 'react';
import { TableSastav } from './components/TableSastav/TableSastav';

export const Kom1SastavWizardTab: React.FC = () => {
  return (
    <InsideContentWrapper header={<WizardObrazacListHeader name={'sastav-form'} modalName="sastav-modal" />}>
      <TableSastav />
    </InsideContentWrapper>
  );
};
