import { Col } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { MAX_AND_MIN_NUMBER, MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetGve } from 'modules/obrasci/store/gve/gve.types';

import styles from './NewGveDodatniPodaciForm.module.scss';
import { useEffect } from 'react';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { gveService } from 'modules/obrasci/store/gve/gve.service';

export const NewGveDodatniPodaciForm: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();
  const form = props.form;

  const onGodisnjaEmisijaInputChange = () => {
    const prolece = form?.getFieldValue('prolece');
    const leto = form?.getFieldValue('leto');
    const jesen = form?.getFieldValue('jesen');
    const zima = form?.getFieldValue('zima');
    const godisnjaEmisijaValidation = gveService.handleGodisnjaEmisijaInputChange(prolece, leto, jesen, zima);
    gveStore.handleChange('isEqual100', godisnjaEmisijaValidation);
  };

  useEffect(() => {
    onGodisnjaEmisijaInputChange();
  }, [form]);

  useEffect(() => {
    if (gveStore.showEmisijeUVazduh) {
      const srednjiProtok = gveStore.initialState.bilans.length
        ? gveStore.initialState.bilans[0].srednji_godisnji_protok_na_mernom_mestu
        : null;
      form.setFieldValue('srednji_godisnji_protok', srednjiProtok);
    }
  }, [gveStore.initialState.bilans.length]);

  const defaultWorkingRegime: ISifarnikInitial | undefined = gveStore.initialState.radni_rezim_ispusta_id
    ? {
        label: (gveStore.initialState as IGetGve).radni_rezim_ispusta.name_sr,
        value: (gveStore.initialState as IGetGve).radni_rezim_ispusta_id,
      }
    : undefined;

  const isUkupanBrojSatiRequired = gveStore.showEmisijeUVazduh
    ? !!gveStore.initialState.kreiranje_godisnjeg_bilansa
    : !!gveStore.initialState.emisije_u_vazduh.length;

  return (
    <Col span={24} className={styles.wrapper}>
      <h3>{t('OBRASCI.GVE.PODACI_O_IZVORU')}</h3>
      <FormInputNumber
        name="godisnja_iskoriscenost_kapaciteta"
        label={t('OBRASCI.GVE.GODISNJA_ISKORISCENOST_KAPACITETA')}
        type="number"
        placeholder={t('OBRASCI.GVE.GODISNJA_ISKORISCENOST_KAPACITETA')}
        inputName={'godisnja_iskoriscenost_kapaciteta'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MAX_AND_MIN_NUMBER(0, 100)}
      />
      <FormInputNumber
        name="srednja_godisnja_temperatura"
        label={t('OBRASCI.GVE.SREDNJA_GODISNJA_TEMPERATURA')}
        type="number"
        placeholder={t('OBRASCI.GVE.SREDNJA_GODISNJA_TEMPERATURA')}
        inputName={'srednja_godisnja_temperatura'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_THEN_0}
      />
      <FormInputNumber
        name="srednja_godisnja_brzina"
        label={t('OBRASCI.GVE.SREDNJA_GODISNJA_BRZINA')}
        type="number"
        placeholder={t('OBRASCI.GVE.SREDNJA_GODISNJA_BRZINA')}
        inputName={'srednja_godisnja_brzina'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_THEN_0}
      />
      <FormInputNumber
        name="srednji_godisnji_protok"
        label={t('OBRASCI.GVE.SREDNJI_GODISNJI_PROTOK')}
        type="number"
        placeholder={t('OBRASCI.GVE.SREDNJI_GODISNJI_PROTOK')}
        inputName={'srednji_godisnji_protok'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        disabled={gveStore.showEmisijeUVazduh}
        rules={MORE_THEN_0}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'radni_rezim_ispusta_id'}
        fetchOptinsList={gveStore.fetchWorkingRegime}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.GVE.RADNI_REZIM_IZVORA')}
        readOnly={false}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        inputName={'radni_rezim_ispusta_id'}
        defaultOption={defaultWorkingRegime}
        placeholder={t('OBRASCI.GVE.RADNI_REZIM_IZVORA')}
        rules={[REQUIRED_FIELD_RULE(!!gveStore.initialState.emisije_u_vazduh.length)]}
      />

      <h3>{t('OBRASCI.GVE.PODACI_O_RADU')}</h3>
      <FormInputNumber
        name="broj_radnih_dana"
        label={t('OBRASCI.GVE.BROJ_RADNIH_DANA')}
        type="number"
        placeholder={t('OBRASCI.GVE.BROJ_RADNIH_DANA')}
        inputName={'broj_radnih_dana'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MAX_AND_MIN_NUMBER(0, 366)}
      />
      <FormInputNumber
        name="broj_radnih_sati"
        label={t('OBRASCI.GVE.BROJ_RADNIH_SATA')}
        type="number"
        placeholder={t('OBRASCI.GVE.BROJ_RADNIH_SATA')}
        inputName={'broj_radnih_sati'}
        colon={true}
        rules={MAX_AND_MIN_NUMBER(0, 24)}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      />
      <FormInputNumber
        name="ukupan_broj_radnih_sati_godisnje"
        label={t('OBRASCI.GVE.UKUPAN_BROJ_RADNIH_SATI')}
        type="number"
        placeholder={t('OBRASCI.GVE.UKUPAN_BROJ_RADNIH_SATI')}
        inputName={'ukupan_broj_radnih_sati_godisnje'}
        colon={true}
        labelCol={{ span: 12 }}
        disabled={!!gveStore.initialState.kreiranje_godisnjeg_bilansa}
        wrapperCol={{ span: 12 }}
        rules={[REQUIRED_FIELD_RULE(isUkupanBrojSatiRequired), ...MAX_AND_MIN_NUMBER(1, 999999)]}
      />

      <h3>{t('OBRASCI.GVE.RASPODELA_EMISIJA')}</h3>

      {!gveStore.isEqual100 && <h4 className={styles.warningText}>{t('OBRASCI.GVE.ZBIR_UPOZORENJE')}</h4>}

      <FormInputNumber
        name="zima"
        label={t('OBRASCI.GVE.ZIMA')}
        type="number"
        placeholder={t('OBRASCI.GVE.ZIMA')}
        inputName={'zima'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        onInputChange={onGodisnjaEmisijaInputChange}
        rules={MAX_AND_MIN_NUMBER(0, 100)}
      />
      <FormInputNumber
        name="prolece"
        label={t('OBRASCI.GVE.PROLECE')}
        type="number"
        placeholder={t('OBRASCI.GVE.PROLECE')}
        inputName={'prolece'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        onInputChange={onGodisnjaEmisijaInputChange}
        rules={MAX_AND_MIN_NUMBER(0, 100)}
      />
      <FormInputNumber
        name="leto"
        label={t('OBRASCI.GVE.LETO')}
        type="number"
        placeholder={t('OBRASCI.GVE.LETO')}
        inputName={'leto'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        onInputChange={onGodisnjaEmisijaInputChange}
        rules={MAX_AND_MIN_NUMBER(0, 100)}
      />
      <FormInputNumber
        name="jesen"
        label={t('OBRASCI.GVE.JESEN')}
        type="number"
        placeholder={t('OBRASCI.GVE.JESEN')}
        inputName={'jesen'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        onInputChange={onGodisnjaEmisijaInputChange}
        rules={MAX_AND_MIN_NUMBER(0, 100)}
      />
    </Col>
  );
});
