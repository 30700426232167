import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { NewObrazac3KanalizacioniSistemiForm } from '../../Obrazac3MicroServices/components/NewObrazac3KanalizacioniSistemiForm/NewObrazac3KanalizacioniSistemiForm';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';

export const Obrazac3KanalizacioniSistemiWizardTab: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  return (
    <InsideContentWrapper header={null}>
      <NewObrazac3KanalizacioniSistemiForm form={form} />
    </InsideContentWrapper>
  );
});
