import { ColumnsType } from 'antd/es/table';
import { IGetSliv, IPostSliv } from './sliv.types';
import { FormInstance } from 'antd';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';

export const SLIV_INITIAL_STATE: IPostSliv = {
  basin_name: '',
  is_active: true,
};

export const SLIV_TABLE_DATA: ColumnsType<IGetSliv> = [
  {
    title: 'Ime sliva',
    width: '700px',
    dataIndex: 'basin_name',
    key: 'basin_name',
  },
];

export const SLIV_FORM_OPTIONS = (_: FormInstance<IGetSliv>) => {
  return [
    {
      type_of_form: 'input',
      name: 'basin_name',
      // todo: prevod
      label: 'Ime sliva',
      inputName: 'basin_name',
    } as InputSifarniciFormProps,
  ];
};
