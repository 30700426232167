import { Form } from 'antd';
import { RangePicker } from 'components/DayjsPicker/DayjsPicker';
import React from 'react';
import { IRangePickerProps } from 'typescript';
// import { disabledTodayAndFutureDates } from 'utils/formatDate';

export const FormRangePicker: React.FC<IRangePickerProps> = props => {
  return (
    <Form.Item
      label={props.label}
      labelCol={props.labelCol}
      wrapperCol={props.wrapperCol}
      rules={props.rules}
      style={props.style}
      className={props.className}
      name={props.name}
    >
      <RangePicker format="YYYY" picker={'year' as any} disabledDate={props.disabledDate} />
    </Form.Item>
  );
};
