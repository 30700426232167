import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Gio4IzvozOtpadaTable } from './components/Gio4IzvozOtpadaTable/Gio4IzvozOtpadaTable';

export const Gio4IzvozOtpadaWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'gio4-izvoz-otpada-form'} modalName="gio4-izvoz-otpada-modal" />}
    >
      <Gio4IzvozOtpadaTable />
    </InsideContentWrapper>
  );
});
