import { ColumnsType } from 'antd/es/table';
import { IGetFuelType, IPostFuelType } from './fuelType.types';
import {
  APPROVED_TABLE,
  DESCRIPTION_TABLE,
  NAME_SR_TABLE,
  NAME_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { APPROVED_FORM, DESCRIPTION_FORM, NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';

import {
  DateSifarniciFormProps,
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';

export const FUEL_TYPE_INITIAL_STATE: IPostFuelType = {
  validity_fromdate: '',
  validity_todate: '',
  fuel_type_code: '',
  name: '',
  name_sr: '',
  unit_id: '',
  description: '',
  display_fuel: '',
  display_fuel_sr: '',
  approved: false,
  is_active: true,
};
export const FUEL_TYPE_TABLE_DATA: ColumnsType<IGetFuelType> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  {
    title: t('OBRASCI.OBRAZAC_1.UNIT_OF_MEASURE'),
    width: '200px',
    dataIndex: ['unit', 'name_sr'],
    key: 'unit',
  },
  {
    title: t('SIFARNICI.OD_DATUMA'),
    width: '200px',
    dataIndex: 'validity_fromdate',
    key: 'validity_fromdate',
    render: (_: any, record: IGetFuelType) => (
      <span>{record.validity_fromdate ? dayjs(record.validity_fromdate).format('DD. MM. YYYY.') : ''}</span>
    ),
  },
  {
    title: t('SIFARNICI.DO_DATUMA'),
    width: '200px',
    dataIndex: 'validity_todate',
    key: 'validity_todate',
    render: (_: any, record: IGetFuelType) => (
      <span>{record.validity_todate ? dayjs(record.validity_todate).format('DD. MM. YYYY.') : ''}</span>
    ),
  },
  {
    title: 'Kod tipa goriva',
    width: '200px',
    dataIndex: 'fuel_type_code',
    key: 'fuel_type_code',
  },

  DESCRIPTION_TABLE,
  {
    title: 'Prikaz goriva',
    width: '200px',
    dataIndex: 'display_fuel',
    key: 'display_fuel',
  },

  {
    title: `Prikaz goriva ${t('NA_SRPSKOM')}`,
    width: '200px',
    dataIndex: 'display_fuel_sr',
    key: 'display_fuel_sr',
  },
  APPROVED_TABLE,
];
export const FUEL_TYPE_FORM_OPTIONS = (form: FormInstance<IGetFuelType>) => {
  const queryParams = new URLSearchParams(location.search);
  const sifarnik_type = queryParams.get('sifarnik_type');

  return [
    NAME_FORM,
    NAME_SR_FORM,
    {
      type_of_form: 'searchScroll',
      label: t('OBRASCI.OBRAZAC_1.UNIT_OF_MEASURE'),
      formName: 'unit_id',
      fetchOptinsList: SIFARNICI_ROUTES.UNIT_CODE,
      // filtersForFetch: {},
      labelOptionAccessor: ['name_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      filtersForFetch: {
        order_by: ['-is_active'],
      },
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'validity_fromdate',
      label: t('SIFARNICI.OD_DATUMA'),
      format: 'DD-MM-YYYY',
      picker: 'date',
      onChange: () => {
        form.setFieldValue('validity_todate', '');
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'validity_todate',
      label: t('SIFARNICI.DO_DATUMA'),
      format: 'DD-MM-YYYY',
      disabled: !!(sifarnik_type && !form.getFieldValue('validity_fromdate')),
      picker: 'date',
      onChange: () => {
        //
      },
      disabledDate: (current: any) => {
        if (form.getFieldValue('validity_fromdate')) {
          return current && current.endOf('day') < form.getFieldValue('validity_fromdate');
        }
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'fuel_type_code',
      // todo: prevod
      label: 'Kod tipa goriva',
      inputName: 'fuel_type_code',
    } as InputSifarniciFormProps,

    DESCRIPTION_FORM,
    {
      type_of_form: 'input',
      name: 'display_fuel',
      // todo: prevod
      label: 'Prikaz goriva',
      inputName: 'display_fuel',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'display_fuel_sr',
      // todo: prevod
      label: `Prikaz goriva ${t('NA_SRPSKOM')}`,
      inputName: 'display_fuel_sr',
    } as InputSifarniciFormProps,
    APPROVED_FORM,
  ];
};
