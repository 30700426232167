import { ColumnsType } from 'antd/es/table';
import { IGetPrivrednaDelatnost1, IPostPrivrednaDelatnost1 } from './privrednaDelatnost1.types';
import { FormInstance } from 'antd';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import {
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  NAME_TABLE,
  NAME_SR_TABLE,
  CODE_2_TABLE,
  VALIDITY_TO_DATE_TABLE,
  VALIDITY_FROM_DATE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import {
  TITLE_FORM,
  DESCRIPTION_FORM,
  VERSION_FORM,
  CODE_FORM,
  NAME_FORM,
  NAME_SR_FORM,
  CODE_2_FORM,
  VALIDITY_TO_DATE_FORM,
  VALIDITY_FROM_DATE_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';

export const PRIVREDNA_DELATNOST_1_INITIAL_STATE: IPostPrivrednaDelatnost1 = {
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  name_sr: '',
  minimumthresholdofcapacity: '',
  minimumthresholdofcapacity_sr: '',
  ippccode: '',
  pd_level: '',
  code2: '',
  is_active: true,
};

export const PRIVREDNA_DELATNOST_1_TABLE_DATA: ColumnsType<IGetPrivrednaDelatnost1> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,

  // todo: prevod
  {
    title: 'Minimalna granična vrednost kapaciteta ',
    width: '200px',
    dataIndex: 'minimumthresholdofcapacity',
    key: 'minimumthresholdofcapacity',
  },
  {
    title: 'Minimalna granična vrednost kapaciteta (srp.)',
    width: '200px',
    dataIndex: 'minimumthresholdofcapacity_sr',
    key: 'minimumthresholdofcapacity_sr',
  },
  {
    title: 'IPPC kod',
    width: '100px',
    dataIndex: 'ippccode',
    key: 'ippccode',
  },
  {
    title: 'Nivo pd',
    width: '100px',
    dataIndex: 'pd_level',
    key: 'pd_level',
  },
  CODE_2_TABLE,
];

export const PRIVREDNA_DELATNOST_1_FORM_OPTIONS = (form: FormInstance<IGetPrivrednaDelatnost1>) => {
  return [
    NAME_FORM,
    NAME_SR_FORM,
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM,
    DESCRIPTION_FORM,
    VERSION_FORM,
    CODE_FORM,
    {
      type_of_form: 'input',
      name: 'minimumthresholdofcapacity',
      // todo: prevod
      label: 'Minimalna granična vrednost kapaciteta ',
      inputName: 'minimumthresholdofcapacity',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'minimumthresholdofcapacity_sr',
      // todo: prevod
      label: 'Minimalna granična vrednost kapaciteta (srp.)',
      inputName: 'minimumthresholdofcapacity_sr',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'ippccode',
      // todo: prevod
      label: 'IPPC kod',
      inputName: 'ippccode',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'pd_level',
      // todo: prevod
      label: 'Nivo pd',
      inputName: 'pd_level',
    } as InputSifarniciFormProps,
    CODE_2_FORM,
  ];
};
