import { userInitialState } from 'modules/user/user.constants';
import { IGetIstorijaPromena, ITipPromenaEnum } from './istorijaPromena.types';
import { ISifarniciTypeOfForm, SelectSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';

export const ISTORIJA_PROMENA_INITIAL_STATE: IGetIstorijaPromena = {
  id: '',
  after_payload: {},
  before_payload: {},
  action: '',
  timestamp: '',
  kreirao: userInitialState,
};

export const TIP_PROMENE_SELECT: { label: string; value: ITipPromenaEnum }[] = [
  {
    label: 'Kreiran',
    value: ITipPromenaEnum.POST,
  },
  {
    label: 'Izmena',
    value: ITipPromenaEnum.INSERT,
  },
  {
    label: 'Obrisan',
    value: ITipPromenaEnum.DELETE,
  },
];

export const ISTORIJA_PROMENA_FILTERS_DATA: ISifarniciTypeOfForm[] = [
  {
    type_of_form: 'select',
    label: 'Tip promene',
    name: 'action',
    inputName: 'action',
    options: TIP_PROMENE_SELECT,
  } as SelectSifarniciFormProps,
];
