import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { FormSwitch } from 'components/FormSwitch/FormSwitch';

export const NewDep1FizickaZastitaForm: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Row>
      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.DEP1.PANELI.FIZICKA_ZASTITA')}</h4>

      <Col span={24}>
        <FormSwitch
          labelCol={{ span: 16 }}
          wrapperCol={{ span: 8 }}
          name={'ograda_oko_nesanitarne_deponije'}
          label={t('OBRASCI.DEP1.OGRADA_OKO_NESANITARNE_DEPONIJE')}
        />
        <FormSwitch
          labelCol={{ span: 16 }}
          wrapperCol={{ span: 8 }}
          name={'kapija_rampa_na_ulazu'}
          label={t('OBRASCI.DEP1.KAPIJA_RAMPA_NA_ULAZU')}
        />
        <FormSwitch
          labelCol={{ span: 16 }}
          wrapperCol={{ span: 8 }}
          name={'cuvarska_sluzba'}
          label={t('OBRASCI.DEP1.CUVARSKA_SLUZBA')}
        />
        <FormSwitch
          labelCol={{ span: 16 }}
          wrapperCol={{ span: 8 }}
          name={'kolska_vaga'}
          label={t('OBRASCI.DEP1.KOLSKA_VAGA')}
        />
        <FormSwitch
          labelCol={{ span: 16 }}
          wrapperCol={{ span: 8 }}
          name={'drenazni_sistem_za_prikupljanje_procednih_otpadnih_voda'}
          label={t('OBRASCI.DEP1.DRENAZNI_SISTEM_ZA_PRIKUPLJANJE')}
        />
        <FormSwitch
          labelCol={{ span: 16 }}
          wrapperCol={{ span: 8 }}
          name={'sistem_za_preciscavanje_procednih_otpadnih_voda'}
          label={t('OBRASCI.DEP1.SISTEM_ZA_PRECISCAVANJE_PROCEDNIH_OTPADNIH_VODA')}
        />
        <FormSwitch
          labelCol={{ span: 16 }}
          wrapperCol={{ span: 8 }}
          name={'sistem_za_otplinjavanje_deponijskog_gasa'}
          label={t('OBRASCI.DEP1.SISTEM_ZA_OTPLINJAVANJA_DEPONIJSKOG_GASA')}
        />
      </Col>
    </Row>
  );
});
