import { IGetUser, IPostUser } from 'modules/user/user.types';

export interface IGetIstorijaPromena<T = any> {
  id: string;
  after_payload: T;
  before_payload: T;
  action: string;
  timestamp: string;
  kreirao: IGetUser | IPostUser;
}

export enum ITipPromenaEnum {
  INSERT = 'INSERT',
  DELETE = 'DELETE',
  POST = 'POST',
}
