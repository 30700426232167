import { observer } from 'mobx-react-lite';
import React from 'react';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { TableSirovine } from './components/TableSirovine/TableSirovine';

export const Obrazac1SirovineWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<WizardObrazacListHeader name={'sirovine-form'} modalName="sirovine-modal" />}>
      <TableSirovine />
    </InsideContentWrapper>
  );
});
