import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
  NAME_FORM,
  NAME_SR_FORM,
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
  TITLE_FORM,
  DESCRIPTION_FORM,
  VERSION_FORM,
  CODE_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import {
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import { IPostNutsRegionCode, IGetNutsRegionCode } from './nutsRegionCode.types';

export const NUTS_REGION_CODE_INITIAL_STATE: IPostNutsRegionCode = {
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  name_sr: '',
  is_active: true,
};

export const NUTS_REGION_CODE_TABLE_DATA: ColumnsType<IGetNutsRegionCode> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
];
export const NUTS_REGION_CODE_FORM_OPTIONS = (form: FormInstance<IGetNutsRegionCode>) => {
  return [
    NAME_FORM,
    NAME_SR_FORM,
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM,
    DESCRIPTION_FORM,
    VERSION_FORM,
    CODE_FORM,
  ];
};
