import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewAao1OperateriForm } from './NewAao1OperateriForm/NewAao1OperateriForm';
import { AAO1_OPERATERI_INITIAL_STATE } from 'modules/obrasci/store/aao1/aao1.constants';
import { aao1Store } from 'modules/obrasci/store/aao1/aao1.store';
import { IAAO1Response } from 'modules/obrasci/store/aao1/aao1.types';
import { FormInstance } from 'antd';

export const Aao1OperateriModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="50%"
      name="aao1-operateri-modal"
      title={t('OBRASCI.AAO1.PACKAGING_AMOUNT.OPERATORS')}
      store={aao1Store}
      data={aao1Store.getOperater}
      children={({ form }: { form: FormInstance<IAAO1Response> }) => <NewAao1OperateriForm form={form} />}
      submitForm={aao1Store.submitOperater}
      initialState={AAO1_OPERATERI_INITIAL_STATE}
      dataToChange={'operater_form'}
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
      closeOneModal={true}
    />
  );
});
