import { IPostKomponenteObrazac5, IPostObrazac5, IPostUpravljanjeObrazac5 } from './obrazac5.types';

export const OBRAZAC_5_INITIAL_STATE: IPostObrazac5 = {
  komponente: [],
  upravljanje_otpadom: [],
  obrazac_5: false,
  lriz_obrazac_5: false,
  obrazac_gio1: false,
  godina: '',
  preduzece_id: '',
  postrojenje_id: '',
  indeksni_broj_otpada_id: '',
  napomena: '',
  mesto_nastanka_otpada: '',
  koordinate_long: '',
  koordinate_lat: '',
  vrsta_otpada_id: '',
  opis_otpada: '',
  karakter_otpada_id: '',
  karakter_otpada_q_lista_id: '',
  broj_izvestaja: '',
  datum_izdavanja: '',
  oznaka_otpada_n_lista_id: '',
  oznaka_otpada_n_lista2_id: '',
  oznaka_otpada_n_lista3_id: '',
  oznaka_otpada_y_lista_id: '',
  oznaka_otpada_y_lista2_id: '',
  oznaka_otpada_y_lista3_id: '',
  fizicko_stanje_otpada_id: '',
  godisnja_kolicina_proizvedenog_otpada: null,
  stanje_skladista_1_januar: null,
  stanje_skladista_31_decembar: null,
  nacin_odredjivanja_kolicina_otpada_id: '',
};

export const OBRAZAC_5_KOMPONENTE_INITIAL: IPostKomponenteObrazac5 = {
  cas_broj: '',
  hemijski_naziv: '',
  sadrzaj_opasne_materije: '',
};

export const OBRAZAC_5_UPRAVLJANJE_INITIAL: IPostUpravljanjeObrazac5 = {
  naziv_prevoznika_otpada: '',
  broj_dozvole_za_prevoz_opasnih_materija: '',
  tip_vozila: '',
  nacin_upravljanja: '',
  naziv_postrojenja_za_skladiste_otpada_id: '',
  broj_dozvole_za_skladiste_otpada: '',
  kolicina_isporucenog_otpada_za_skladiste_otpada: null,
  d_ili_r_oznaka_za_skladiste_otpada_id: '',
  naziv_postrojenja_za_tretman_otpada_odlaganje_id: '',
  broj_dozvole_odlaganja: '',
  kolicina_isporucenog_otpada_za_odlaganje: null,
  d_oznaka_za_odlaganje_id: '',
  kolicina_isporucenog_otpada_za_ponovno_iskoriscenje: null,
  broj_dozvole_ponovno_iskoriscenje: '',
  r_oznaka_za_ponovno_iskoriscenje_id: '',
  zemlja_izvoza_id: '',
  kolicina_izvezenog_otpada: null,
  d_ili_r_oznaka_za_izvoz_otpada_id: '',
  naziv_postrojenja_za_tretman_otpada_izvoz: '',
  ulica_postrojenja_za_tretman_otpada_izvoz: '',
  kucni_broj_postrojenja_za_tretman_otpada_izvoz: '',
  mesto_postrojenja_za_tretman_otpada_izvoz: '',
  postanski_broj_postrojenja_za_tretman_otpada_izvoz: '',
  naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje_id: '',
};
