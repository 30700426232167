import { Row } from 'antd';

import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { observer } from 'mobx-react-lite';

import React from 'react';
import { modalStore } from 'store';

export const HeaderDivljaDeponija: React.FC = observer(() => {
  return (
    <Row justify={'end'}>
      <AddNewButton
        name={'divlja-deponija-form'}
        onButtonClick={() => {
          modalStore.changeModalName('divlja-deponija-modal');
        }}
      />
    </Row>
  );
});
