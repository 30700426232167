import { ColumnsType } from 'antd/es/table';
import { IGetSourceType, IPostSourceType } from './sourceType.types';
import { NAME_SR_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';

export const SOURCE_TYPE_INITIAL_STATE: IPostSourceType = {
  name: '',
  name_sr: '',
  is_active: true,
};
export const SOURCE_TYPE_TABLE_DATA: ColumnsType<IGetSourceType> = [NAME_TABLE, NAME_SR_TABLE];
export const SOURCE_TYPE_FORM_OPTIONS = (_: FormInstance<IGetSourceType>) => {
  return [NAME_FORM, NAME_SR_FORM];
};
