import { ColumnsType } from 'antd/es/table';
import { IGetIaList, IPostIaList } from './iaList.types';
import {
  APPROVED_TABLE,
  DESCRIPTION_SR_TABLE,
  DESCRIPTION_TABLE,
  NAME_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import {
  APPROVED_FORM,
  DESCRIPTION_FORM,
  DESCRIPTION_SR_FORM,
  NAME_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';

export const IA_LIST_INITIAL_STATE: IPostIaList = {
  name: '',
  description: '',
  description_sr: '',
  approved: false,
  is_active: true,
};
export const IA_LIST_TABLE_DATA: ColumnsType<IGetIaList> = [
  NAME_TABLE,
  DESCRIPTION_TABLE,
  DESCRIPTION_SR_TABLE,
  APPROVED_TABLE,
];
export const IA_LIST_FORM_OPTIONS = (_: FormInstance<IGetIaList>) => {
  return [NAME_FORM, DESCRIPTION_FORM, DESCRIPTION_SR_FORM, APPROVED_FORM];
};
