import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { gio4ObrazacStore } from 'modules/obrasci/store/gio4/gio4.store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewGio4ZemljeTranzitaForm } from './NewGio4ZemljeTranzitaForm/NewGio4ZemljeTranzitaForm';
import { GIO_4_ZEMLJA_TRANZITA_INITIAL_STATE } from 'modules/obrasci/store/gio4/gio4.constants';

export const Gio4ZemljeTranzitaModal: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <CRUDModal
      width="40%"
      name="gio4-zemlje-tranzita-modal"
      title={t('OBRASCI.GIO4.TRANSIT')}
      store={gio4ObrazacStore}
      data={gio4ObrazacStore.getZemljaTranzita}
      children={() => <NewGio4ZemljeTranzitaForm />}
      submitForm={gio4ObrazacStore.submitZemljeTranzita}
      initialState={GIO_4_ZEMLJA_TRANZITA_INITIAL_STATE}
      dataToChange={'zemljaTranzita'}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      closeOneModal={true}
    />
  );
});
