import React from 'react';

import { Switch as ANTSwitch, SwitchProps } from 'antd';

interface ISwitchProps extends SwitchProps {
  name: string;
}

export const Switch: React.FC<ISwitchProps> = props => {
  return <ANTSwitch {...props} data-test={`switch-${props.name}`} />;
};
