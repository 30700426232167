import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ActivityList2Modal } from 'components/Modals/ActivityModals/ActivityList2Modal/ActivityList2Modal';
import { Row } from 'antd';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { useTranslation } from 'react-i18next';
import { authStore, modalStore } from 'store';

import { ActivityList2Table } from './ActivityList2Table/ActivityList2Table';
import { UserRoles } from 'modules/user/user.types';
import { activityList1Store } from 'modules/activityList1/activityList1.store';
import { activityList2Store } from 'modules/activityList2/activityList2.store';
import { facilityStore } from 'modules/facility/facility.store';

export const ActivityList2Header: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Row justify={'space-between'} align={'bottom'}>
      <h3>{t(`ACTIVITIES.SUBTITLE2`)}</h3>
      {authStore.getUserRole === UserRoles.SystemAdmin &&
        activityList1Store.activityList.length === 0 &&
        facilityStore.getFacility.is_active && (
          <AddNewButton
            label="Dodaj aktivnost 2"
            name={`activity-2`}
            onButtonClick={() => {
              activityList2Store.handleChange('privredna_delatnost_id', '');
              modalStore.changeModalName(`activity-list-modal-2`);
            }}
          />
        )}
    </Row>
  );
});

export const ActivityList2: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<ActivityList2Header />}>
      <ActivityList2Table />
      <ActivityList2Modal />
    </InsideContentWrapper>
  );
});
