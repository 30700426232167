import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { NewEmisijeUTloForm } from './components/NewEmisijeUTloForm';
import { EMISIJE_U_TLO_INITIAL_STATE } from 'modules/obrasci/store/obrazac4/obrazac4.constants';
import { obrazac4Store } from 'modules/obrasci/store/obrazac4/obrazac4.store';

export const EmisijeUTloModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="70%"
      name="emisije-u-tlo-modal"
      title={t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.TITLE')}
      store={obrazac4Store}
      data={obrazac4Store.getEmisija}
      children={({ form }) => <NewEmisijeUTloForm form={form} />}
      submitForm={obrazac4Store.submitEmisijeForm}
      initialState={EMISIJE_U_TLO_INITIAL_STATE}
      dataToChange="emisija"
      onCancel={() => obrazac4Store.handleChange('filterNacinOdredjivanjaId', '')}
    />
  );
});
