import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { useTranslation } from 'react-i18next';
import { NewGio2DeponijeForm } from './components/NewGio2DeponijeForm/NewGio2DeponijeForm';
import { GIO_2_DEPONIJE_INITIAL_STATE } from 'modules/obrasci/store/gio2/gio2.constants';

export const Gio2DeponijeModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  return (
    <CRUDModal
      width="40%"
      name="gio2-deponije-modal"
      title={t('WIZARD_TABS.DEPONIJE')}
      store={gio2ObrazacStore}
      data={gio2ObrazacStore.getDeponijeForm}
      children={() => <NewGio2DeponijeForm />}
      submitForm={gio2ObrazacStore.submitDeponijeForm}
      initialState={GIO_2_DEPONIJE_INITIAL_STATE}
      dataToChange={'deponije_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    />
  );
});
