import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, NAME_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IPostWasteWaterTreatmentType, IGetWasteWaterTreatmentType } from './wasteWaterTreatmentType.types';
import { SIFARNICI_ROUTES, SearchScrollSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';

export const WASTE_WATER_TREATMENT_TYPE_INITIAL_STATE: IPostWasteWaterTreatmentType = {
  name: '',
  name_sr: '',
  is_active: true,
  waste_water_treatment_method_id: '',
};

export const WASTE_WATER_TREATMENT_TYPE_TABLE_DATA: ColumnsType<IGetWasteWaterTreatmentType> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  {
    title: `Način prečišćavanja otpadnih voda`,
    // align: 'center',
    width: 200,
    dataIndex: ['waste_water_treatment_method', 'name_sr'],
    key: 'waste_water_treatment_method',
  },
];

export const WASTE_WATER_TREATMENT_TYPE_FORM_OPTIONS = (_: FormInstance<IGetWasteWaterTreatmentType>) => {
  return [
    NAME_FORM,
    NAME_SR_FORM,
    {
      type_of_form: 'searchScroll',
      label: `Način prečišćavanja otpadnih voda`,
      formName: 'waste_water_treatment_method_id',
      sortName: 'waste_water_treatment_method_id',
      fetchOptinsList: SIFARNICI_ROUTES.WASTE_WATER_TREATMENT_METHOD,
      filtersForFetch: {},
      labelOptionAccessor: ['name_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
  ];
};
