import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { aao1Store } from 'modules/obrasci/store/aao1/aao1.store';
import { AAO1_KOLICINA_AMBALAZA_INITIAL_STATE } from 'modules/obrasci/store/aao1/aao1.constants';
import { NewKolicinaAmbalazeForm } from './components/NewKolicinaAmbalazeForm/NewKolicinaAmbalazeForm';
import { FormInstance } from 'antd';
import { IPostKolicinaAmbalaze } from 'modules/obrasci/store/aao1/aao1.types';

export const KolicinaAmbalazeModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="70%"
      name="kolicina-ambalaze-modal"
      title={t('OBRASCI.AAO1.PACKAGING_AMOUNT.TITLE')}
      store={aao1Store}
      data={aao1Store.getKolicinaAmbalaze}
      children={({ form }: { form: FormInstance<IPostKolicinaAmbalaze> }) => <NewKolicinaAmbalazeForm form={form} />}
      submitForm={aao1Store.submitKolicinaAmbalaze}
      initialState={AAO1_KOLICINA_AMBALAZA_INITIAL_STATE}
      dataToChange={'kolicinaAmbalaze'}
    />
  );
});
