import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IFilterTableProps } from 'typescript/NrizTypes';

export const FilterUnsanitaryLandfillList: React.FC = observer(() => {
  const [unsanitaryForm] = useForm();

  const unsanitaryFilterOptions = [
    {
      type_of_form: 'input',
      inputName: '',
      name: 'search',
      label: 'Ime nesanitarne deponije',
      sortName: 'search',
    },
  ];
  const data = {
    form: unsanitaryForm,
    initialState: { search: '', order_by: '' },
    filterName: 'nesanitarne_deponije',
    filterOptions: unsanitaryFilterOptions,
  } as IFilterTableProps;

  return <FilterTableForm data={data} />;
});
