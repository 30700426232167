import { t } from 'i18next';

import { ITableColumns, TIPOVI_OBRAZACA } from './obrasci.types';
import { ColumnsType } from 'antd/es/table';
import { IGetDkoObrazac } from './store/dko/dko.types';
import { Tag } from 'antd';

const BASE_TABLE_OBRAZAC = [
  {
    title: `${t('COMPANY.TITLE')}`,
    width: '200px',
    dataIndex: ['preduzece', 'naziv'],
    key: 'naziv',
  },
  {
    title: `${t('COMPANY.PIB')}`,
    width: '150px',
    dataIndex: ['preduzece', 'pib'],
    key: 'pib',
  },
  {
    title: `${t('COMPANY.ENTERPRISE_ID_NUMBER')}`,
    width: '150px',
    dataIndex: ['preduzece', 'maticni_broj'],
    key: 'maticni_broj',
  },
];

const DATUM_PREDAJE_OTPADA_TABLE_OBRAZAC = {
  title: `${t('OBRASCI.DKO.DATUM_PREDAJE_OTPADA')}`,
  width: '150px',
  dataIndex: 'datum_predaje_otpada',
  key: 'datum_predaje_otpada',
};

const POSTROJENJE_TABLE_OBRAZAC = {
  title: `${t('FACILITY.TITLE')}`,
  width: '200px',
  dataIndex: ['postrojenje', 'naziv_postrojenja'],
  key: 'naziv_postrojenja',
};

const GODINA_TABLE_OBRAZAC = {
  title: `${t('YEAR')}`,
  width: '100px',
  dataIndex: 'godina',
  key: 'godina',
};

const DATUM_TABLE_OBRAZAC = {
  title: `${t('OBRASCI.DEO_6.DATUM_IZVESTAJA')}`,
  width: '150px',
  dataIndex: 'datum',
  key: 'datum',
};

const IZVOR_ZAGADJIVANJA_VODA_TABLE_OBRAZAC = {
  title: `${t('POLLUTION.WATER.SEWER_NAME')}`,
  width: '200px',
  dataIndex: ['izvor_zagadjenja_vode', 'naziv_ispusta'],
  key: 'naziv_ispusta',
};
const IZVOR_ZAGADJIVANJA_VAZDUH_TABLE_OBRAZAC = {
  title: `${t('POLLUTION.AIR.SOURCE_NAME')}`,
  width: '200px',
  dataIndex: ['izvor_zagadjenja_vazduha', 'naziv_izvora'],
  key: 'naziv_izvora',
};

const IZVOR_ZAGADJIVANJA_TLO_TABLE_OBRAZAC = {
  title: `${t('POLLUTION.SOIL.LOCATION_NAME')}`,
  width: '200px',
  dataIndex: ['izvor_zagadjenja_tla', 'naziv_lokacije'],
  key: 'naziv_lokacije',
};

const INDEX_BROJ_TABLE_OBRAZAC_TABLE_OBRAZAC = {
  title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
  width: '300px',
  key: 'index_number',
  render: (_: any, record: any) => (
    <>{`${record?.indeksni_broj_otpada?.index_number} - ${record?.indeksni_broj_otpada?.waste_sr?.toUpperCase()}`}</>
  ),
};

const NESANITARNA_DEPONIJA_TABLE_OBRAZAC_TABLE_OBRAZAC = {
  title: `${t('UNSANITARY_LANDFILL.NAME')} nesanitarne deponije`,
  dataIndex: ['nesanitarna_deponija', 'naziv'],
  key: 'naziv',
  width: '200px',
};

const BROJ_DOKUMENTA_TABLE_OBRAZAC = {
  title: t('BROJ_DOKUMENTA'),
  width: '150px',
  dataIndex: 'broj_dokumenta',
  key: 'broj_dokumenta',
};

const DKO_STATUS = {
  title: t('Status dokumenta'),
  width: '200px',
  dataIndex: 'status',
  key: 'status',
  align: 'center',
  render: (_: any, record: IGetDkoObrazac) => {
    if (record.potvrda_dokumenta) return <Tag color="green">Potvrđen</Tag>;
    if (record.odjava_kretanja) return <Tag color="red">Odjava / korisnik</Tag>;
    if (record.admin_odjava_dokumenta) return <Tag color="gold">Odjava / administrator</Tag>;
    return <Tag color="cyan">Najavljen</Tag>;
  },
};

export const KREIRAO_IZMENIO_TABLE_OBRAZAC: ColumnsType<any> = [
  {
    title: 'Kreirao',
    width: '150px',
    dataIndex: 'kreirao',
    key: 'broj_dokumenta',
    render: (_: any, record: any) => {
      return (
        <>
          {record?.kreirao?.ime} {record?.kreirao?.prezime}
        </>
      );
    },
  },
  {
    title: 'Izmenio',
    width: '150px',
    dataIndex: 'izmenio',
    key: 'izmenio',
    render: (_: any, record: any) => {
      return (
        <>
          {record?.izmenio?.ime} {record?.izmenio?.prezime}
        </>
      );
    },
  },
];

// const tableData = () => (authStore.getUserRole === UserRoles.SystemAdmin ? OBRAZAC_ADMINISTRATOR_DATA : OBRAZAC_USER_DATA);
export const OBRAZAC_TYPE_TABLE_COLUMNS: ITableColumns = {
  [TIPOVI_OBRAZACA.AAO1]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.AAO2]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.PZV1]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.PTP2]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.DEP1]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    NESANITARNA_DEPONIJA_TABLE_OBRAZAC_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.DEP2]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.KOM1]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC, POSTROJENJE_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.GIO2]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC, POSTROJENJE_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.GIO3]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC, POSTROJENJE_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.GIO4]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC, POSTROJENJE_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.GIO5]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC, POSTROJENJE_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.OBRAZAC_1]: [GODINA_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC, POSTROJENJE_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.OBRAZAC_2]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    POSTROJENJE_TABLE_OBRAZAC,
    IZVOR_ZAGADJIVANJA_VAZDUH_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_3]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    POSTROJENJE_TABLE_OBRAZAC,
    IZVOR_ZAGADJIVANJA_VODA_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_4]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    POSTROJENJE_TABLE_OBRAZAC,
    IZVOR_ZAGADJIVANJA_TLO_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.OBRAZAC_5]: [
    GODINA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    POSTROJENJE_TABLE_OBRAZAC,
    INDEX_BROJ_TABLE_OBRAZAC_TABLE_OBRAZAC,
  ],
  [TIPOVI_OBRAZACA.DEO6_GIO6]: [DATUM_TABLE_OBRAZAC, ...BASE_TABLE_OBRAZAC, POSTROJENJE_TABLE_OBRAZAC],
  [TIPOVI_OBRAZACA.DKO]: [
    BROJ_DOKUMENTA_TABLE_OBRAZAC,
    DATUM_PREDAJE_OTPADA_TABLE_OBRAZAC,
    ...BASE_TABLE_OBRAZAC,
    POSTROJENJE_TABLE_OBRAZAC,
    INDEX_BROJ_TABLE_OBRAZAC_TABLE_OBRAZAC,
    DKO_STATUS,
  ],
};
