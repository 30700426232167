import { activity1Repo } from './activityList1.repo';
import { IGetActivity1, IPostActivity1, RequestActivityList } from './activityList1.types';

class Activity1Service {
  fetchActivityList1 = (payload: RequestActivityList) => {
    return activity1Repo.fetchActivityList1(payload);
  };

  postActivity1 = (payload: IPostActivity1) => {
    return activity1Repo.postActivity1(payload);
  };

  deleteActivity1 = (id: string) => {
    return activity1Repo.deleteActivity1(id);
  };

  putActivity1 = (activity: IGetActivity1) => {
    return activity1Repo.putActivity1(activity);
  };
}

export const activity1Service = new Activity1Service();
