import { ColumnsType } from 'antd/es/table';
import { IGetCompositionCommunalWaste, IPostCompositionCommunalWaste } from './compositionCommunalWaste.types';
import { FormInstance } from 'antd';
import {
  NAME_SR_TABLE,
  NAME_TABLE,
  THE_ORDER_TABLE,
  VERSION_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import { NAME_FORM, NAME_SR_FORM, THE_ORDER_FORM, VERSION_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';

export const COMPOSITION_COMMUNAL_WASTE_INITIAL_STATE: IPostCompositionCommunalWaste = {
  name: '',
  name_sr: '',
  the_order: '',
  is_active: true,
  version: '',
};

export const COMPOSITION_COMMUNAL_WASTE_TABLE_DATA: ColumnsType<IGetCompositionCommunalWaste> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  THE_ORDER_TABLE,
  VERSION_TABLE,
];
export const COMPOSITION_COMMUNAL_WASTE_FORM_OPTIONS = (_: FormInstance<IGetCompositionCommunalWaste>) => {
  return [THE_ORDER_FORM, NAME_FORM, NAME_SR_FORM, VERSION_FORM];
};
