import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Gio5UvozOtpadaTable } from './components/Gio5UvozOtpadaTable/Gio5UvozOtpadaTable';

export const Gio5UvozOtpadaWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'gio5-uvoz-otpada-form'} modalName="gio5-uvoz-otpada-modal" />}
    >
      <Gio5UvozOtpadaTable />
    </InsideContentWrapper>
  );
});
