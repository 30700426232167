import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IGetConfidentiality } from '../confidentiality/confidentiality.types';
import { IPostCoordinateSystem, IGetCoordinateSystem } from './coordinateSystem.types';
import { CODE_TABLE, DESCRIPTION_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { CODE_FORM, DESCRIPTION_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';

export const COORDINATE_SYSTEM_INITIAL_STATE: IPostCoordinateSystem = {
  code: '',
  description: '',
  is_active: true,
};
export const COORDINATE_SYSTEM_TABLE_DATA: ColumnsType<IGetCoordinateSystem> = [CODE_TABLE, DESCRIPTION_TABLE];
export const COORDINATE_SYSTEM_FORM_OPTIONS = (_: FormInstance<IGetConfidentiality>) => {
  return [CODE_FORM, DESCRIPTION_FORM];
};
