import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { useTranslation } from 'react-i18next';
import { NewGio2KolicineForm } from './components/NewGio2KolicineForm/NewKolicineGio2Form';
import { GIO_2_KOLICINE_INITIAL_STATE } from 'modules/obrasci/store/gio2/gio2.constants';

export const Gio2KolicineModal: React.FC = observer(() => {
  const { t } = useTranslation();

  //   todo: prevod
  return (
    <CRUDModal
      width="80%"
      name="gio2-kolicine-modal"
      title={t('OBRASCI.KOM_1.QUANTITY')}
      store={gio2ObrazacStore}
      data={gio2ObrazacStore.getKolicineForm} // getDeponijeForm
      children={({ form }) => <NewGio2KolicineForm form={form} />}
      submitForm={gio2ObrazacStore.submitKolicineForm} /// submitDeponijeForm
      initialState={GIO_2_KOLICINE_INITIAL_STATE} /// DEPONIJAINITI
      dataToChange={'kolicine_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      onCancel={gio2ObrazacStore.resetMicro}
    />
  );
});
