import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { FormInput } from 'components/FormInput/FormInput';
import { MAX_LENGTH_RULE, MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import styles from '../NewUpravljanjeObrazac5.module.scss';
import { obrazac5Store } from 'modules/obrasci/store/obrazac5/obrazac5.store';
import { IGetApr } from 'modules/sifarnici/store/apr/apr.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetUpravljanjeObrazac5 } from 'modules/obrasci/store/obrazac5/obrazac5.types';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { drListOptions } from 'utils/optionMakers';

export const NewPonovnoObrazac5: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  const form = props.form;

  const [required, setRequired] = useState<boolean>(false);

  const onKolicineChange = () => {
    const kolicine = form?.getFieldValue('kolicina_isporucenog_otpada_za_ponovno_iskoriscenje');
    kolicine ? setRequired(true) : setRequired(false);
  };

  const defaultOptionNazivPonovnoIskoriscenje: ISifarnikInitial | undefined = obrazac5Store.getUpravljanjeForm
    .naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje_id
    ? {
        value: obrazac5Store.getUpravljanjeForm.naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje_id,
        label: `${
          (obrazac5Store.getUpravljanjeForm as IGetUpravljanjeObrazac5)
            .naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje?.full_company_name
        } ${
          (obrazac5Store.getUpravljanjeForm as IGetUpravljanjeObrazac5)
            .naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje?.enterprise_id_number
        } ${
          (obrazac5Store.getUpravljanjeForm as IGetUpravljanjeObrazac5)
            .naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje?.tax_identification_number
        }`,
      }
    : undefined;

  const defaultDrOption: ISifarnikInitial | undefined = obrazac5Store.getUpravljanjeForm
    .r_oznaka_za_ponovno_iskoriscenje_id
    ? {
        value: obrazac5Store.getUpravljanjeForm.r_oznaka_za_ponovno_iskoriscenje_id,
        label: `${
          (obrazac5Store.getUpravljanjeForm as IGetUpravljanjeObrazac5).r_oznaka_za_ponovno_iskoriscenje.name
        } ${
          (obrazac5Store.getUpravljanjeForm as IGetUpravljanjeObrazac5).r_oznaka_za_ponovno_iskoriscenje.description_sr
        }`,
      }
    : undefined;

  const listAprOptions = (options: IGetApr[]) => {
    return options.map(entity => ({
      label: `${entity.full_company_name} ${entity.enterprise_id_number} ${entity.tax_identification_number}`,
      value: entity.id,
    }));
  };

  useEffect(() => {
    onKolicineChange();
  }, []);

  return (
    <>
      <h4 className={styles.titleMargin}>{t('OBRASCI.OBRAZAC5.PONOVNO_ISKORISCENJE_OTPADA')}</h4>

      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje_id'}
        fetchOptinsList={obrazac5Store.fetchApr}
        labelOptionAccessor="full_company_name"
        valueOptionAccessor="id"
        disabledAccessor=""
        inputName={'naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje_id'}
        label={t('OBRASCI.OBRAZAC5.NAZIV_ZA_PONOVNO_ISKORISCENJE')}
        colon={true}
        optionsMaker={listAprOptions}
        defaultOption={defaultOptionNazivPonovnoIskoriscenje}
        placeholder={t('OBRASCI.OBRAZAC5.NAZIV_ZA_PONOVNO_ISKORISCENJE')}
        rules={[REQUIRED_FIELD_RULE(required)]}
      />

      <FormInput
        name="broj_dozvole_ponovno_iskoriscenje"
        label={t('OBRASCI.OBRAZAC5.BROJ_DOZVOLE')}
        type="text"
        placeholder={t('OBRASCI.OBRAZAC5.BROJ_DOZVOLE')}
        inputName={'broj_dozvole_ponovno_iskoriscenje'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(required), MAX_LENGTH_RULE(50)]}
      />

      <FormInput
        name="kolicina_isporucenog_otpada_za_ponovno_iskoriscenje"
        label={t('OBRASCI.OBRAZAC5.KOLICINA_NA_PONOVNO_ISKORISCENJE')}
        type="number"
        placeholder={t('OBRASCI.OBRAZAC5.KOLICINA_NA_PONOVNO_ISKORISCENJE')}
        inputName={'kolicina_isporucenog_otpada_za_ponovno_iskoriscenje'}
        colon={true}
        rules={MORE_THEN_0}
        onInputChange={onKolicineChange}
      />

      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'r_oznaka_za_ponovno_iskoriscenje_id'}
        fetchOptinsList={obrazac5Store.fetchDROptionsPonovnoIskoriscenje}
        labelOptionAccessor="name"
        valueOptionAccessor="id"
        inputName={'r_oznaka_za_ponovno_iskoriscenje_id'}
        label={t('OBRASCI.OBRAZAC5.R_OZNAKA')}
        colon={true}
        defaultOption={defaultDrOption}
        placeholder={t('OBRASCI.OBRAZAC5.R_OZNAKA')}
        rules={[REQUIRED_FIELD_RULE(required)]}
        optionsMaker={drListOptions}
      />
    </>
  );
});
