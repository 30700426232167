import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Obrazac3AnalizaRecipijenataTable } from './Obrazac3AnalizaRecipijenataTable/Obrazac3AnalizaRecipijenataTable';
import { Obrazac3AnalizaRecipijenataMerenjeTable } from './Obrazac3AnalizaRecipijenataMerenjeTable/Obrazac3AnalizaRecipijenataMerenjeTable';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

export const Obrazac3AnalizaRecipijenataWizardTab: React.FC = observer(() => {
  return Number(obrasciStore.opste.godina) >= 2023 ? (
    <>
      <InsideContentWrapper
        header={
          <WizardObrazacListHeader
            name={'obrazac3-analiza-recipijenta-merenje-form'}
            modalName="obrazac3-analiza-recipijenta-merenje-modal"
          />
        }
      >
        <Obrazac3AnalizaRecipijenataMerenjeTable />
      </InsideContentWrapper>
    </>
  ) : (
    <InsideContentWrapper
      header={
        <WizardObrazacListHeader
          name={'obrazac3-analiza-recipijenta-merenje-form'}
          modalName="obrazac3-analiza-recipijenata-modal"
        />
      }
    >
      <Obrazac3AnalizaRecipijenataTable />
    </InsideContentWrapper>
  );
  // return (
  //   <InsideContentWrapper
  //     header={
  //       <WizardObrazacListHeader
  //         name={'obrazac3-analiza-recipijenta-merenje-form'}
  //         modalName="obrazac3-analiza-recipijenata-modal"
  //       />
  //     }
  //   >
  //     <Obrazac3AnalizaRecipijenataTable />
  //   </InsideContentWrapper>
  // );
});
