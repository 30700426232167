import { ColumnsType } from 'antd/es/table';
import { IGetWaterBodyCategory, IPostWaterBodyCategory } from './waterBodyCategory.types';
import { FormInstance } from 'antd';
import { t } from 'i18next';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';

export const WATER_BODY_CATEGORY_INITIAL_STATE: IPostWaterBodyCategory = {
  category: '',
  is_active: true,
};
export const WATER_BODY_CATEGORY_TABLE_DATA: ColumnsType<IGetWaterBodyCategory> = [
  {
    title: t('SIFARNICI.NAZIV'),
    // align: 'center',
    width: '700px',
    dataIndex: 'category',
    key: 'category',
  },
];
export const WATER_BODY_CATEGORY_FORM_OPTIONS = (_: FormInstance<IGetWaterBodyCategory>) => {
  return [
    {
      type_of_form: 'input',
      label: t('SIFARNICI.NAZIV'),
      inputName: 'category',
      name: 'category',
    } as InputSifarniciFormProps,
  ];
};
