import { ColumnsType } from 'antd/es/table';
import { IGetMethodOfPackagingWaste, IPostMethodOfPackagingWaste } from './methodOfPackaging.types';
import { FormInstance } from 'antd';
import { THE_ORDER_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { THE_ORDER_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';

export const METHOD_OF_PACKAGING_WASTE_INITIAL_STATE: IPostMethodOfPackagingWaste = {
  the_order: '',
  method_of_packaging: '',
  method_of_packaging_sr: '',
  is_active: true,
};
export const METHOD_OF_PACKAGING_WASTE_TABLE_DATA: ColumnsType<IGetMethodOfPackagingWaste> = [
  THE_ORDER_TABLE,
  {
    title: 'Način pakovanja',
    width: '300px',
    dataIndex: 'method_of_packaging',
    key: 'method_of_packaging',
  },
  {
    title: 'Način pakovanja (srp.)',
    width: '300px',
    dataIndex: 'method_of_packaging_sr',
    key: 'method_of_packaging_sr',
  },
];
export const METHOD_OF_PACKAGING_WASTE_FORM_OPTIONS = (_: FormInstance<IGetMethodOfPackagingWaste>) => {
  return [
    THE_ORDER_FORM,
    {
      type_of_form: 'input',
      name: 'method_of_packaging',
      // todo: prevod
      label: 'Način pakovanja',
      inputName: 'method_of_packaging',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'method_of_packaging_sr',
      // todo: prevod
      label: 'Način pakovanja (srp.)',
      inputName: 'method_of_packaging_sr',
    } as InputSifarniciFormProps,
  ];
};
