import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { MAX_LENGTH_RULE, MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import styles from '../NewUpravljanjeObrazac5.module.scss';
import { obrazac5Store } from 'modules/obrasci/store/obrazac5/obrazac5.store';
import { IGetApr } from 'modules/sifarnici/store/apr/apr.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetUpravljanjeObrazac5 } from 'modules/obrasci/store/obrazac5/obrazac5.types';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { drListOptions } from 'utils/optionMakers';

export const NewSkladistenjeObrazac5: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  const form = props.form;

  const [required, setRequired] = useState<boolean>(false);

  const onKolicineChange = () => {
    const kolicine = form?.getFieldValue('kolicina_isporucenog_otpada_za_skladiste_otpada');
    kolicine ? setRequired(true) : setRequired(false);
  };

  const defaultOptionNazivSkladistenje: ISifarnikInitial | undefined = obrazac5Store.getUpravljanjeForm
    .naziv_postrojenja_za_skladiste_otpada_id
    ? {
        value: obrazac5Store.getUpravljanjeForm.naziv_postrojenja_za_skladiste_otpada_id,
        label: `${
          (obrazac5Store.getUpravljanjeForm as IGetUpravljanjeObrazac5).naziv_postrojenja_za_skladiste_otpada
            .full_company_name
        } ${
          (obrazac5Store.getUpravljanjeForm as IGetUpravljanjeObrazac5).naziv_postrojenja_za_skladiste_otpada
            .enterprise_id_number
        } ${
          (obrazac5Store.getUpravljanjeForm as IGetUpravljanjeObrazac5).naziv_postrojenja_za_skladiste_otpada
            .tax_identification_number
        }`,
      }
    : undefined;

  const defaultDrOption: ISifarnikInitial | undefined = obrazac5Store.getUpravljanjeForm
    .d_ili_r_oznaka_za_skladiste_otpada_id
    ? {
        value: obrazac5Store.getUpravljanjeForm.d_ili_r_oznaka_za_skladiste_otpada_id,
        label: `${
          (obrazac5Store.getUpravljanjeForm as IGetUpravljanjeObrazac5).d_ili_r_oznaka_za_skladiste_otpada.name
        } ${
          (obrazac5Store.getUpravljanjeForm as IGetUpravljanjeObrazac5).d_ili_r_oznaka_za_skladiste_otpada
            .description_sr
        }`,
      }
    : undefined;

  const listAprOptions = (options: IGetApr[]) => {
    return options.map(entity => ({
      label: `${entity.full_company_name} ${entity.enterprise_id_number} ${entity.tax_identification_number}`,
      value: entity.id,
    }));
  };

  useEffect(() => {
    onKolicineChange();
  }, []);

  return (
    <>
      <h4 className={styles.titleMargin}>{t('OBRASCI.OBRAZAC5.SKLADISTE_OTPADA')}</h4>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'naziv_postrojenja_za_skladiste_otpada_id'}
        fetchOptinsList={obrazac5Store.fetchApr}
        labelOptionAccessor="full_company_name"
        disabledAccessor=""
        valueOptionAccessor="id"
        inputName={'naziv_postrojenja_za_skladiste_otpada_id'}
        label={t('OBRASCI.OBRAZAC5.NAZIV_ZA_SKLADISTENJE')}
        colon={true}
        optionsMaker={listAprOptions}
        defaultOption={defaultOptionNazivSkladistenje}
        placeholder={t('OBRASCI.OBRAZAC5.NAZIV_ZA_SKLADISTENJE')}
        rules={[REQUIRED_FIELD_RULE(required)]}
      />
      <FormInput
        name="broj_dozvole_za_skladiste_otpada"
        label={t('OBRASCI.OBRAZAC5.BROJ_DOZVOLE')}
        type="text"
        placeholder={t('OBRASCI.OBRAZAC5.BROJ_DOZVOLE')}
        inputName={'broj_dozvole_za_skladiste_otpada'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(required), MAX_LENGTH_RULE(50)]}
      />
      <FormInput
        name="kolicina_isporucenog_otpada_za_skladiste_otpada"
        label={t('OBRASCI.OBRAZAC5.KOLICINA_NA_SKLADISTENJE')}
        type="number"
        placeholder={t('OBRASCI.OBRAZAC5.KOLICINA_NA_SKLADISTENJE')}
        inputName={'kolicina_isporucenog_otpada_za_skladiste_otpada'}
        colon={true}
        onInputChange={onKolicineChange}
        rules={MORE_THEN_0}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'d_ili_r_oznaka_za_skladiste_otpada_id'}
        fetchOptinsList={obrazac5Store.fetchDROptionsSkladiste}
        labelOptionAccessor="name"
        valueOptionAccessor="id"
        inputName={'d_ili_r_oznaka_za_skladiste_otpada_id'}
        label={t('OBRASCI.OBRAZAC5.D_ILI_R_OZNAKA')}
        colon={true}
        defaultOption={defaultDrOption}
        placeholder={t('OBRASCI.OBRAZAC5.D_ILI_R_OZNAKA')}
        rules={[REQUIRED_FIELD_RULE(required)]}
        optionsMaker={drListOptions}
      />
    </>
  );
});
