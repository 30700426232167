import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import TableData from 'components/TableData/TableData';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { exportNacinPostupanja } from 'modules/obrasci/store/deo6/deo6.service';
import { deo6ObrazacStore } from 'modules/obrasci/store/deo6/deo6.store';
import { IGetPredatOtpad } from 'modules/obrasci/store/deo6/deo6.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const Deo6PredatOtpadWizardTab: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetPredatOtpad> = [
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
      width: 200,
      key: 'waste',
      render: (_: any, record: IGetPredatOtpad) => (
        <>{`${record.indeksni_broj_otpada.index_number} ${record.indeksni_broj_otpada.waste_sr.toUpperCase()}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.DEO_6.KOLICINA_PREDATOG_OTPADA')}`,
      width: 200,
      dataIndex: 'kolicina',
      key: 'kolicina',
    },
    {
      title: `${t('OBRASCI.DEO_6.NACIN_POSTUPANJA_OTPADOM')}`,
      width: 200,
      dataIndex: 'nacin_postupanja',
      key: 'nacin_postupanja',
      render: (_, record: IGetPredatOtpad) => {
        return <>{exportNacinPostupanja(record.nacin_postupanja)}</>;
      },
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetPredatOtpad) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-predat-otpad-deo6'}
            onClick={() => {
              deo6ObrazacStore.handleChange('index_id', record.indeksni_broj_otpada_id);
              if (record.indeksni_broj_otpada.weee && Number(deo6ObrazacStore.godina) >= 2020) {
                deo6ObrazacStore.handleChange('weee_categories', true);
              }
              deo6ObrazacStore.handleChange('predat_form', record);
              modalStore.changeModalName('deo6-predat-otpad-modal');
            }}
          />
        );
      },
    },
  ];

  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'deo6-predat-otpad-form'} modalName="deo6-predat-otpad-modal" />}
    >
      <TableData
        scrollConfig={['#obrazacWizardHeader', '#obrazacTableHeader']}
        name="predat-otpad-deo6"
        columns={baseColumns}
        dataSource={[...deo6ObrazacStore.initialState.predat_otpad]}
      />
    </InsideContentWrapper>
  );
});
