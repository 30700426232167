import { observer } from 'mobx-react-lite';
import { NaseljeModal } from './components/NaseljeModal/NaseljeModal';
import { KolicineModal } from './components/KolicineModal/KolicineModal';
import { SastavModal } from './components/SastavModal/SastavModal';
import { UpravljanjeModal } from './components/UpravljanjeModal/UpravljanjeModal';
import { PredatoModal } from './components/PredatoModal/PredatoModal';

export const Kom1MicroServices: React.FC = observer(() => {
  return (
    <>
      <NaseljeModal />
      <KolicineModal />
      <SastavModal />
      <UpravljanjeModal />
      <PredatoModal />
    </>
  );
});
