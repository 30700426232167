import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { Aao1UpravljanjeAmbalaznimOtpadomTable } from './components/Aao1UpravljanjeAmbalaznimOtpadomTable/Aao1UpravljanjeAmbalaznimOtpadomTable';
import { Aao1Dozvola } from './components/Aao1Dozvola/Aao1Dozvola';
import { aao1Store } from 'modules/obrasci/store/aao1/aao1.store';

export const Aao1UpravljanjeAmbalaznimOtpadomWizardTab = () => {
  return (
    <>
      <Aao1Dozvola />
      <InsideContentWrapper
        header={
          <WizardObrazacListHeader
            disabled={
              !aao1Store.isCompanyWithSelfManagment ||
              (!aao1Store.initialState.kolicina_ambalaze.length &&
                aao1Store.initialState.kolicina_ambalaze.some(kolicina => {
                  return kolicina.da_li_je_preneta_kolicina_ambalaze_na_operatera === false;
                }))
            }
            name={'upravljanje-ambalaznim-otpadom-form'}
            modalName="upravljanje-ambalaznim-otpadom-modal"
            title={
              !aao1Store.initialState.kolicina_ambalaze.length &&
              aao1Store.initialState.kolicina_ambalaze.some(kolicina => {
                return kolicina.da_li_je_preneta_kolicina_ambalaze_na_operatera === false;
              })
                ? 'Morate dodati jednu količinu ambalaže koja nije preneta na operatera na prethodnom tabu'
                : ''
            }
          />
        }
      >
        <Aao1UpravljanjeAmbalaznimOtpadomTable />
      </InsideContentWrapper>
    </>
  );
};
