import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import styles from './NewWaterPollution.module.scss';
import { formStore } from 'store';
import { GeneralNewWater } from './components/GeneralNewWater/GeneralNewWater';
import { TypeOfDischargedWastewater } from './components/TypeOfDischargedWastewater/TypeOfDischargedWastewater';
import { DevicesForWastewaterTreatment } from './components/DevicesForWastewaterTreatment/DevicesForWastewaterTreatment';
import { useTranslation } from 'react-i18next';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { IFormProps } from 'typescript/NrizTypes';
import { CRUDButton } from 'components/Buttons/CRUDButton/CRUDButton';
import { nrizService } from 'modules/nriz/nriz.service';
import { IGetWater, IPostWater } from 'modules/sourcesOfPollution/water/water.types';
import { optionsDevicesWaterTreatment, waterInitialState } from 'modules/sourcesOfPollution/water/water.constants';
import { waterStore } from 'modules/sourcesOfPollution/water/water.store';

export const NewWaterPollution: React.FC<IFormProps> = observer(() => {
  const [form] = useForm();
  const { t } = useTranslation();

  const onFinishFailed = () => {
    const newValues = form.validateFields().then(() => {
      form.setFields(
        form.getFieldsValue().reduce((acc: any, value: any, key: any) => {
          acc[key] = {
            value,
            error: [],
            rules: [],
          };
          return acc;
        }, {})
      );
    });
    return waterStore.submitWater(newValues as any);
  };

  const setTrueOptions = () => {
    optionsDevicesWaterTreatment.forEach(items => {
      items.items.forEach(item => {
        const getValue = form.getFieldValue(item.name);
        if (getValue) {
          waterStore.changeWaterDeviceTreatmentList(item.name, getValue);
        }
      });
    });
  };

  useEffect(() => {
    form.setFieldsValue({ ...waterStore.getWater });
    waterStore.handleChange('waterDeviceTreatmentList', {});
  }, [waterStore.getWater]);

  useEffect(() => {
    setTrueOptions();
  }, [waterStore.getWater]);

  useEffect(() => {
    if (!waterStore.waterShowDevices) {
      const initial = form.getFieldsValue();
      const newInitial = { ...initial, ...waterStore.waterDeviceTreatmentList };
      return form.setFieldsValue(newInitial);
    }
  }, [waterStore.waterShowDevices]);

  return (
    <Form
      form={form}
      initialValues={waterInitialState}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      labelAlign="left"
      name={'water'}
      onFinishFailed={() => {
        if (formStore.crudButtonType === 'delete') {
          onFinishFailed();
        }
        waterStore.checkWaterValidation();
      }}
      onFinish={(values: IPostWater) => {
        waterStore.checkWaterValidation();
        if (waterStore.getWaterShowDevicesTreatmentError || waterStore.getWaterShowTypeOfDischargedError) {
          return;
        }
        waterStore.submitWater({ ...waterInitialState, ...values });
      }}
      colon={false}
    >
      <Row gutter={20} className={styles.new_water_pollution_form}>
        <Col span={nrizService.changeColValue(waterStore.getShowWaterDevices)}>
          <GeneralNewWater form={form} />
          <TypeOfDischargedWastewater />
          <FormCheckbox
            onCheckBoxChange={(e: any) => {
              waterStore.changeShowWaterDevices(e.target.checked);
            }}
            className={styles.wastewaterTreatment}
            labelCol={{ span: 22 }}
            labelAlign={'left'}
            label={<div className={styles.text}>{t('POLLUTION.WATER.IS_THERE_A_TREATMENT_FACILITY_WASTEWATER')}</div>}
            name={'postojanje_postrojenja_za_preciscavanje'}
          />
        </Col>
        {waterStore.getShowWaterDevices && (
          <Col span={12}>
            <DevicesForWastewaterTreatment />
          </Col>
        )}
      </Row>

      {(waterStore.getWater.aktivan && (waterStore.getWater as IGetWater).id) ||
      !(waterStore.getWater as IGetWater).id ? (
        <CRUDButton showButton={!!(waterStore.water as IGetWater).id} withOutDelete={true} form={form} />
      ) : undefined}
    </Form>
  );
});
