import { ROUTES } from 'modules/nriz/nriz.constants';
import { RegistriEnum, RegistriOptionType } from './registri.types';
import i18n from 'translations/i18n.config';
import { OTVRegistar } from 'pages/Registri/components/OTVRegistar/OTVRegistar';
import { ColumnType } from 'antd/es/table';
import { otvStore } from './otv/otv.store';
import { IOTVRegistar } from './otv/otv.types';
import { OTV_INITIAL_STATE, otvFilterComponents } from './otv/otv.constants';
import { FormInstance } from 'antd';

export const registriOptions: RegistriOptionType[] = [
  {
    label: 'OTV',
    value: RegistriEnum.Otv,
  },
  {
    label: i18n.t('REGISTRI.DOZVOLE.TITLE'),
    value: RegistriEnum.Dozvole,
  },
  {
    label: 'LCP',
    value: RegistriEnum.Lcp,
  },
];

function generateRegistriColumns<T>(registar: RegistriEnum): ColumnType<T>[] {
  switch (registar) {
    case RegistriEnum.Otv:
      return [
        { key: 'preduzece', dataIndex: ['preduzece', 'naziv'], title: 'Preduzeće' },
        { key: 'postrojenje', dataIndex: ['postrojenje', 'naziv_postrojenja'], title: 'Postrojenje' },
        {
          key: 'izvor_zagadjenja_vode',
          dataIndex: ['izvor_zagadjenja_vode', 'naziv_ispusta'],
          title: 'Izvor zagađenja',
        },
      ];
    case RegistriEnum.Lcp:
      return [];
    case RegistriEnum.Dozvole:
      return [];
  }
}

export const registriConfig = {
  [RegistriEnum.Otv]: {
    key: RegistriEnum.Otv,
    label: 'OTV Registar',
    route: `/${ROUTES.REGISTRI}/${RegistriEnum.Otv}`,
    store: otvStore,
    apiRoute: 'otv_registar/',
    component: <OTVRegistar />,
    tableData: {
      columns: generateRegistriColumns<IOTVRegistar>(RegistriEnum.Otv),
      fetchData: otvStore.fetchOtvList,
      dataSource: otvStore.otvList,
    },
    filters: {
      components: (form: FormInstance<IOTVRegistar>) => {
        return otvFilterComponents(form);
      },
      initialState: OTV_INITIAL_STATE,
    },
  },
  [RegistriEnum.Lcp]: {
    key: RegistriEnum.Lcp,
    label: 'LCP Registar',
    route: `/${ROUTES.REGISTRI}/${RegistriEnum.Lcp}`,
    store: otvStore,
    apiRoute: 'otv_registar',
    component: <p>adequateJSXcomponent</p>,
    tableData: {
      columns: [],
      fetchData: otvStore.fetchOtvList,
      dataSource: [],
    },
    filters: {
      components: (form: FormInstance<IOTVRegistar>) => {
        return otvFilterComponents(form);
      },
      initialState: 'otvInitial',
    },
  },
  [RegistriEnum.Dozvole]: {
    key: RegistriEnum.Dozvole,
    label: i18n.t('REGISTRI.DOZVOLE.TITLE'),
    route: `/${ROUTES.REGISTRI}/${RegistriEnum.Dozvole}`,
    store: otvStore,
    apiRoute: 'otv_registar',
    component: <p>adequateJSXcomponent</p>,
    tableData: {
      columns: [],
      fetchData: otvStore.fetchOtvList,
      dataSource: [],
    },
    filters: {
      components: (form: FormInstance<IOTVRegistar>) => {
        return otvFilterComponents(form);
      },
      initialState: 'otvInitial',
    },
  },
};

//registri selekt options, registri routes, registri filter config, registri table data
