import { IPostDeterdzent, IPostDjubrivo, IPostPZV1Obrazac, IPostZastitaBilja } from './pzv1.types';

export const DETERDZENTI_INITIAL_STATE: IPostDeterdzent = {
  products_pzv_id: '',
  sadrzaj_fosfata: null,
  kolicina: null,
  naziv: '',
};

export const DJUBRIVA_INITIAL_STATE: IPostDjubrivo = {
  products_pzv_id: '',
  sadrzaj_azota: null,
  kolicina: null,
};

export const ZASTITA_BILJA_INITIAL_STATE: IPostZastitaBilja = {
  products_pzv_id: '',
  sadrzaj_aktivne_materije: null,
  kolicina: null,
};

export const PZV1_INITIAL_STATE: IPostPZV1Obrazac = {
  godina: '',
  preduzece_id: '',
  napomena: '',
  deterdzenti: [],
  mineralna_djubriva: [],
  zastita_bilja: [],
};
