import { Checkbox, Col, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance, useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';
import { IFormProps } from 'typescript/NrizTypes';
import { ICompanyTipoviObrasca } from 'modules/company/company.types';
import style from './NewCompanyTipoviObrazaca.module.scss';
import { ITipObrascaConfig } from 'modules/obrasci/obrasci.types';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { authStore } from 'store';
import { UserRoles } from 'modules/user/user.types';
import { EditChangeDiscardButton } from 'components/Buttons/EditChangeDiscardButton/EditChangeDiscardButton';
import { facilityStore } from 'modules/facility/facility.store';

export interface ICompanyTipoviObrazacaHeaderProps {
  toggleReadOnly: () => void;
  readOnly: boolean;
  handleDiscard: () => void;
  form: FormInstance<any>;
  isActive: boolean;
}

export const CompanyTipoviObrazacaHeader: React.FC<ICompanyTipoviObrazacaHeaderProps> = observer(
  ({ toggleReadOnly, readOnly, handleDiscard, form, isActive }) => {
    const { t } = useTranslation();

    return (
      <Row justify={'space-between'} align={'middle'}>
        <h3>{t('OBRASCI.TIPOVI_OBRAZACA')}</h3>
        <EditChangeDiscardButton
          name="tipovi-obrazaca"
          editButtonVisible={authStore.getUserRole === UserRoles.SystemAdmin && readOnly && isActive}
          onEditClick={toggleReadOnly}
          onDiscardClick={handleDiscard}
          onChangeClick={() => form.submit()}
          discardChangeButtonVisible={!readOnly}
        />
      </Row>
    );
  }
);

export interface INewCompanyTipoviObrazacaProps extends IFormProps {
  selectedObrasciId: string[];
  tipoviObrazaca: ITipObrascaConfig[];
  selectedObrasci: ITipObrascaConfig[];
  initialTipoviIds?: string[];
  isActive: boolean;
  handleSubmit: (selectedTipovi: ICompanyTipoviObrasca) => void;
}

export const NewCompanyTipoviObrazaca: React.FC<INewCompanyTipoviObrazacaProps> = observer(props => {
  const [tipoviObrazacaForm] = useForm();

  const toggleReadOnly = () => {
    facilityStore.handleChange('readOnlyTipoviObrazacaForm', !facilityStore.readOnlyTipoviObrazacaForm);
  };

  const selectedObrasci = obrasciStore.selectedObrasci(tipoviObrazacaForm.getFieldValue('tipovi_obrazaca')) || [];

  const setDefaultValues = () => {
    tipoviObrazacaForm.setFieldsValue({ tipovi_obrazaca: [...props.selectedObrasciId] });
  };

  useEffect(() => {
    setDefaultValues();
  }, [props.selectedObrasciId?.length]); // check if company/preduzece needs to be tracked

  const handleDiscard = () => {
    tipoviObrazacaForm.setFieldsValue({ tipovi_obrazaca: [...(props.initialTipoviIds ? props.initialTipoviIds : [])] });
    toggleReadOnly();
  };

  const handleChange = (e: CheckboxChangeEvent, obrazac: ITipObrascaConfig) => {
    obrazac.onChange ? obrazac.onChange(e, tipoviObrazacaForm) : undefined;
  };

  return (
    <InsideContentWrapper
      header={
        <CompanyTipoviObrazacaHeader
          toggleReadOnly={toggleReadOnly}
          readOnly={facilityStore.readOnlyTipoviObrazacaForm}
          handleDiscard={handleDiscard}
          form={tipoviObrazacaForm}
          isActive={props.isActive}
        />
      }
    >
      <div className={style.tipoviObrazacaWrapper}>
        <Form
          form={tipoviObrazacaForm}
          name={props.formName}
          onFinish={(value: any) => {
            props.handleSubmit(value);
            toggleReadOnly();
          }}
          wrapperCol={{ span: 24 }}
          className={style.form}
        >
          <Form.Item name={'tipovi_obrazaca'}>
            <Checkbox.Group className={style.checkboxGroup} disabled={facilityStore.readOnlyTipoviObrazacaForm}>
              <Row>
                {props.tipoviObrazaca.map(obrazac => {
                  return (
                    <Col span={8} key={obrazac.id} className={style.checkboxCol}>
                      <Checkbox
                        disabled={obrazac.disabled ? obrazac.disabled(selectedObrasci) : undefined}
                        value={obrazac.id}
                        onChange={e => handleChange(e, obrazac)}
                      >
                        {`${obrazac.naziv} (${obrazac.skraceni_naziv})`}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </div>
    </InsideContentWrapper>
  );
});
