import { t } from 'i18next';
import { IGoriva, IPostObrazac1, IProizvodiObrazac1, ISirovine } from './obrazac1.types';
import i18n from 'translations/i18n.config';

export const OBRAZAC_1_INITIAL_STATE: IPostObrazac1 = {
  godina: '',
  preduzece_id: '',
  postrojenje_id: '',
  radni_rezim_id: '',
  pocetak_sezone_mesec: '',
  kraj_sezone_mesec: '',
  broj_smena_dnevno: null,
  broj_radnih_dana_nedeljno: null,
  broj_radnih_dana_godisnje: null,
  broj_radnih_dana_sezonski: null,
  broj_operativnih_sati: null,
  broj_stalno_zaposlenih: null,
  broj_povremeno_zaposlenih: null,
  broj_zaposlenih_u_prvoj_smeni: null,
  broj_zaposlenih_u_drugoj_smeni: null,
  broj_zaposlenih_u_trecoj_smeni: null,
  ukupan_broj_vrsta_otpada: null,
  napomena: '',
  goriva: [],
  proizvodi: [],
  sirovine: [],
  ukupna_kolicina_po_aktivnostima: [],
  obrazac_1: false,
  lriz_obrazac_1: false,
};

export const GORIVO_INITIAL_STATE: IGoriva = {
  naziv_goriva_id: '',
  potrosnja_na_dan: null,
  nacin_lagerovanja: '',
  maksimalni_kapacitet_lagera: null,
  prosecna_kolicina_na_lageru: null,
};

export const PROIZVODI_OBRAZAC_1_INITIAL_STATE: IProizvodiObrazac1 = {
  sifra_proizvoda: '',
  naziv_proizvoda: '',
  opis: '',
  jedinica_mere_id: '',
  godisnja_proizvodnja: null,
  instalirani_kapaciteti: null,
  prosecno_angazovani_kapacitet: null,
  nacin_lagerovanja: '',
  maksimalni_kapacitet_lagera: null,
  prosecna_kolicina_na_lageru: null,
};

export const SIROVINE_INITIAL_STATE: ISirovine = {
  hemijski_naziv: '',
  komercijalni_naziv: '',
  agregatno_stanje_kada_je_na_skladistu: '',
  jedinica_mere_id: '',
  potrosnja_na_dan: null,
  nacin_lagerovanja: '',
  maksimalni_kapacitet_lagera: null,
  prosecna_kolicina_na_lageru: null,
};

export const rezimRadaOptions = [
  {
    value: 'a19ac0c1467c4489a4594c227aab4074',
    label: i18n.t('OBRASCI.OBRAZAC_1.KONTINUALAN'),
    key: 'kontinualan',
  },
  { value: '6070e965d50549fc9551eae9ba9dd878', label: i18n.t('OBRASCI.OBRAZAC_1.SEZONSKI'), key: 'sezonski' },
  {
    value: '449f6967b3a24adba5cefd85f6d2ebb2',
    label: i18n.t('OBRASCI.OBRAZAC_1.SEMIKONTINUALAN'),
    key: 'semikontinualan',
  },
];

export const brojRadnihDanaNedeljnoOptions = [
  { value: 1, lable: '1' },
  { value: 2, lable: '2' },
  { value: 3, lable: '3' },
  { value: 4, lable: '4' },
  { value: 5, lable: '5' },
  { value: 6, lable: '6' },
  { value: 7, lable: '7' },
];

export const brojSmenaDnevnoOptions = [
  { value: 1, lable: '1' },
  { value: 2, lable: '2' },
  { value: 3, lable: '3' },
];

export const zaposleniUPostrojenju = [
  {
    label: t('OBRASCI.OBRAZAC_1.NUMBER_OF_PERMANENT_EMPLOYEES'),
    name: 'broj_stalno_zaposlenih',
  },
  {
    label: t('OBRASCI.OBRAZAC_1.NUMBER_OF_TEMPORARY_EMPLOYEES'),
    name: 'broj_povremeno_zaposlenih',
  },
  {
    label: t('OBRASCI.OBRAZAC_1.NUMBER_OF_EMPLOYEES_IN_FIRST_SHIFT'),
    name: 'broj_zaposlenih_u_prvoj_smeni',
  },
  {
    label: t('OBRASCI.OBRAZAC_1.NUMBER_OF_EMPLOYEES_IN_SECOND_SHIFT'),
    name: 'broj_zaposlenih_u_drugoj_smeni',
  },
  {
    label: t('OBRASCI.OBRAZAC_1.NUMBER_OF_EMPLOYEES_IN_THIRD_SHIFT'),
    name: 'broj_zaposlenih_u_trecoj_smeni',
  },
];
