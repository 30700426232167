import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { IGetUser, IPostUser, IPostUserResponsibility } from 'modules/user/user.types';
import { nrizService } from 'modules/nriz/nriz.service';
import to from 'await-to-js';
import { formStore, modalStore } from 'store';
import { IPagination } from 'typescript';
import { handleSuccessNotify } from 'utils/succesHandling';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetCompany } from 'modules/company/company.types';
import { IPaginationWithFilters } from 'typescript/NrizTypes';
import { filtersStore } from 'store/filteri.store';
import { userInitialState } from './user.constants';
import { facilityStore } from 'modules/facility/facility.store';
import { companyStore } from 'modules/company/company.store';
import { userService } from './user.service';
import dayjs from 'dayjs';
import { saveBlob } from 'utils/fileDownload';

class UserStore {
  constructor() {
    makeAutoObservable(this, {
      user: observable,
      userList: observable,
      facilityUserList: observable,

      userTypeForm: observable,

      getUser: computed,
      getUserList: computed,

      fetchUserList: action,
      fetchUserCompanyList: action,
      fetchUserFacilityList: action,
      setUser: action,
      postUser: action,

      setUserTypeForm: action,
      changeUser: action,
      setUserList: action,
      setFacilityUserList: action,
      deleteUser: action,
      deleteFacilityUser: action,
    });
  }

  user: IPostUser | IGetUser = userInitialState;
  userList: IGetUser[] = [];
  facilityUserList: IGetUser[] = [];
  userTypeForm = '';

  get getUser() {
    return this.user;
  }

  get getUserList() {
    return this.userList;
  }

  get getFacilityUserList() {
    return this.facilityUserList;
  }

  handleChange(key: string, value: any) {
    (this as any)[key] = value;
  }

  // TODO: proveri ovaj poziv
  changeUser = (type: string, value: string) => {
    if (this.user) this.user = { ...this.user, [type]: value };
  };

  putUser = async (payload: IGetUser) => {
    const [err, res] = await to(userService.putUser(payload));
    if (err || !res) return this.setUser(this.user);
    this.setUser(payload);
    modalStore.removeAllModals();
    handleSuccessNotify();
  };

  putUserResponsible = async (payload: IGetUser) => {
    const [err, res] = await to(
      userService.putUserResponsible(payload.id, {
        postrojenje_id: (facilityStore.getFacility as IGetFacility).id,
      })
    );
    if (err || !res) return;
    const newObject = { ...payload, odgovorno_lice: true };
    const newList = nrizService.changeItemInList(
      userService.toggleBooleanToFalseInList(this.facilityUserList, 'odgovorno_lice'),
      newObject
    );
    this.setFacilityUserList([...newList]);
    handleSuccessNotify();
  };

  postUser = async (values: IPostUser) => {
    const newObject = { ...values, preduzece_id: (companyStore.getCompany as IGetCompany).id };
    const [err, res] = await to(userService.postUser(newObject));
    if (err || !res) return;
    this.setUserList([{ ...newObject, id: res.id, is_active: true } as IGetUser, ...this.userList]);
    modalStore.removeAllModals();
    handleSuccessNotify();
    return res;
  };

  postUserRegistration = async (values: IPostUser) => {
    const [err, res] = await to(userService.postUserRegistration(values));
    if (err || !res) return;
    this.setUser({ ...values, id: res.id, is_active: true } as IGetUser);
    handleSuccessNotify();
  };

  postUserFacility = async (values: IGetUser) => {
    const payload = {
      postrojenje_id: (facilityStore.facility as IGetFacility).id,
      odgovorna_osoba: false,
    };
    const [err, res] = await to(userService.postUserFacility(values.id, payload));
    if (err || !res) return;
    this.setFacilityUserList([{ ...values }, ...this.facilityUserList]);
    handleSuccessNotify();
  };

  deleteUser = async (id: string) => {
    const [err, res] = await to(userService.deleteUser(id));
    if (err || !res) return;
    this.setUserList(nrizService.removeFromList(this.userList, id));
    modalStore.removeAllModals();
    history.back();
    history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', function () {
      history.pushState(null, document.title, location.href);
    });
    handleSuccessNotify();
  };

  deleteFacilityUser = async (id: string) => {
    const [err, res] = await to(userService.deleteFacilityUser(id));
    if (err || !res) return;
    this.setFacilityUserList(nrizService.removeFromList(this.facilityUserList, id));

    handleSuccessNotify();
  };

  fetchUserList = async (payload: IPaginationWithFilters) => {
    const [err, res] = await to(
      userService.fetchUserList({
        ...payload,
        ...nrizService.pickFields(filtersStore.filters.korisnik),
      })
    );
    if (err || !res) return;
    runInAction(() => (this.userList = res.items));
    return res;
  };

  fetchUserCompanyList = async (pagination: IPagination) => {
    const [err, res] = await to(
      userService.fetchUserCompanyList({
        page: pagination.page,
        size: pagination.size,
        preduzece_id: (companyStore.company as IGetCompany).id,
        ...nrizService.pickFields(filtersStore.filters.korisnik),
      })
    );
    if (err || !res) return;
    this.setUserList(res.items);
    return res;
  };

  fetchUserFacilityList = async (pagination: IPagination) => {
    const [err, res] = await to(
      userService.fetchUserFacilityList({
        page: pagination.page,
        size: pagination.size,
        id: (facilityStore.facility as IGetFacility).id,
        ...nrizService.pickFields(filtersStore.filters.korisnik_postrojenje),
      })
    );
    if (err || !res) return;
    this.setFacilityUserList(res.items);

    return res;
  };

  exportUsersListExcel = async () => {
    const [err, res] = await to(userService.exportUsersListExcel(filtersStore.filters.korisnik));
    if (err || !res) return;
    const downloadName = `${'Korisnici'}_${dayjs().format('DD-MM-YYYY')}`;
    saveBlob(res, downloadName);
  };

  postUserAdministrator = async (values: IPostUser) => {
    const [err, res] = await to(userService.postUserAdministrator(values));
    if (err || !res) return;
    const newList = [{ ...values, id: res.id, is_active: true }, ...this.userList] as IGetUser[];
    this.setUserList(newList);
    modalStore.removeAllModals();
    handleSuccessNotify();
  };

  fetchUser = async (id: string) => {
    const [err, res] = await to(userService.fetchUser(id));
    if (err || !res) return;
    this.setUser(res as IGetUser);
    companyStore.setCompany(res.preduzece as IGetCompany);
  };

  setUser = (userObj: IPostUser | IGetUser) => (this.user = userObj);
  setUserList = (userList: IGetUser[] | []) => (this.userList = userList);
  setFacilityUserList = (facilityUserList: IGetUser[] | []) => (this.facilityUserList = facilityUserList);

  setUserTypeForm = (value: 'user-registration' | 'user-company' | 'user-administrator') => {
    this.userTypeForm = value;
  };

  aktivirajKorisnika = async () => {
    const [err, res] = await to(userService.aktivirajKorisnika((this.user as IGetUser).id));
    if (err || !res) return;
    this.setUser({ ...this.user, is_active: true });
    handleSuccessNotify();
  };

  deaktivirajKorisnika = async () => {
    const [err, res] = await to(userService.deaktivirajKorisnika((this.user as IGetUser).id));
    if (err || !res) return;
    this.setUser({ ...this.user, is_active: false });
    handleSuccessNotify();
  };

  putUserCompanyResponsibility = async (payload: IPostUserResponsibility) => {
    const [err, res] = await to(userService.putUserCompanyResponsibility((this.user as IGetUser).id, payload));
    if (err || !res) return this.setUser(this.user);

    const newUser = { ...this.user, ...payload };

    let userList = this.userList;
    if (this.userList.length) {
      if (
        payload.odgovorna_osoba_za_saradnju_sa_agencijom &&
        !(this.user as IGetUser).odgovorna_osoba_za_saradnju_sa_agencijom
      ) {
        userList = userService.toggleBooleanToFalseInList(userList, 'odgovorna_osoba_za_saradnju_sa_agencijom');
      }
      if (payload.odgovorna_osoba_preduzeca && !(this.user as IGetUser).odgovorna_osoba_preduzeca) {
        userList = userService.toggleBooleanToFalseInList(userList, 'odgovorna_osoba_preduzeca');
      }
      if (payload.odgovorna_osoba_za_izvestavanje && !(this.user as IGetUser).odgovorna_osoba_za_izvestavanje) {
        userList = userService.toggleBooleanToFalseInList(userList, 'odgovorna_osoba_za_izvestavanje');
      }
      this.setUserList(nrizService.changeItemInList(userList, newUser));
    }

    this.setUser(newUser);
    handleSuccessNotify();
    modalStore.removeAllModals();
  };

  changeCompanyOwner = async (id: { korisnik_id: string }) => {
    const [err, res] = await to(userService.changeCompanyOwner(id.korisnik_id));
    if (err || !res) return;
    this.setUser({ ...this.user, main_company_user: false });
    handleSuccessNotify();
    modalStore.removeAllModals();
  };

  submitUser = (user: IPostUser) => {
    if (formStore.getCrudButtonType === 'submit') {
      if (this.userTypeForm === 'user-registration') {
        return this.postUserRegistration(user);
      }
      if (this.userTypeForm === 'user-company') {
        return this.postUser(user);
      }
      if (this.userTypeForm === 'user-administrator') {
        return this.postUserAdministrator(user);
      }
    }
    const newObject = { id: (this.user as IGetUser).id, ...user } as IGetUser;
    if (formStore.getCrudButtonType === 'change') {
      // TODO: proveri ovaj zbudz, bez usera pre newobjecta gazi sve ono sto je van forme(responsibility itd...)
      return this.putUser({ ...this.user, ...newObject });
    }
    if (formStore.getCrudButtonType === 'delete') {
      if (newObject.id) {
        return this.deleteUser(newObject.id);
      }
    }
  };
}

export const userStore = new UserStore();
