import { t } from 'i18next';
import { obrasciStore } from './obrasci.store';

import { FormInstance } from 'antd';
import {
  DateSifarniciFormProps,
  ISifarniciFormData,
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { TIPOVI_OBRAZACA } from './obrasci.types';
import { authStore } from 'store';
import { UserRoles } from 'modules/user/user.types';

const formFieldNamesConnectedByPreduzece = [
  'postrojenje_id',
  'nesanitarna_deponija_id',
  'izvor_zagadjenja_vazduha_id',
  'izvor_zagadjenja_vode_id',
  'izvor_zagadjenja_tla_id',
  'indeksni_broj_otpada_id',
];

const PREDUZECE_FILTER_OBRAZAC = (form: FormInstance<any>) => {
  return {
    type_of_form: 'searchScroll',
    label: t('COMPANY.TITLE'),
    formName: 'preduzece_id',
    fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_PREDUZECE,
    labelOptionAccessor: ['naziv'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    filtersForFetch: {
      tip_obrasca_id: obrasciStore.tip_obrasca_id,
    },
    readOnly: false,
    resetOption: obrasciStore.real_obrazac_type,
    sortName: 'naziv_preduzeca',
    onChange: value => {
      formFieldNamesConnectedByPreduzece.forEach(field => form.setFieldValue(field, ''));
      obrasciStore.handleOpste('preduzece_id', value);
    },
    hideInput: !(authStore.getUserRole === UserRoles.SystemAdmin),
  } as SearchScrollSifarniciFormProps;
};

const POSTROJENJE_FILTER_OBRAZAC = (form: FormInstance<any>) => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      tip_obrasca_id: obrasciStore.tip_obrasca_id,
      preduzece_id:
        authStore.getUserRole === UserRoles.SystemAdmin
          ? form.getFieldValue('preduzece_id')
          : obrasciStore.opste.preduzece_id,
    },
    formName: 'postrojenje_id',
    fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_POSTROJENJE,
    labelOptionAccessor: ['naziv_postrojenja'],
    valueOptionAccessor: 'id',
    // disabledAccessor: 'is_active',
    resetOption: obrasciStore.opste.preduzece_id,
    label: t('FACILITY.NAME'),
    sortName: 'naziv_postrojenja',
    hideInput: !(
      form.getFieldValue('preduzece_id') &&
      obrasciStore.obrasciPoNivou.postrojenje.some(item => item.skraceni_naziv === obrasciStore.real_obrazac_type) &&
      authStore.getUserRole === UserRoles.SystemAdmin
    ),
  } as SearchScrollSifarniciFormProps;
};

const NESANITARNA_DEPONIJA_FILTER_DATA = (form: FormInstance<any>) => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      preduzece_id:
        authStore.getUserRole === UserRoles.SystemAdmin
          ? form.getFieldValue('preduzece_id')
          : obrasciStore.opste.preduzece_id,
    },
    formName: 'nesanitarna_deponija_id',
    fetchOptinsList: SIFARNICI_ROUTES.NESANITARNA_DEPONIJA,
    labelOptionAccessor: ['naziv'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    resetOption: obrasciStore.opste.preduzece_id,
    label: `${t('UNSANITARY_LANDFILL.NAME')} nesanitarne deponije`,
    sortName: 'naziv_nesanitarne_deponije',
    hideInput:
      (!form.getFieldValue('preduzece_id') && authStore.getUserRole === UserRoles.SystemAdmin) ||
      (!obrasciStore.opste.preduzece_id && authStore.getUserRole !== UserRoles.SystemAdmin),
  } as SearchScrollSifarniciFormProps;
};
const IZVOR_VAZDUH_FILTER_DATA = (form: FormInstance<any>) => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      postrojenje_id:
        authStore.getUserRole === UserRoles.SystemAdmin
          ? form.getFieldValue('postrojenje_id')
          : obrasciStore.opste.postrojenje_id,
    },
    formName: 'izvor_zagadjenja_vazduha_id',
    fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VAZDUHA,
    labelOptionAccessor: ['naziv_izvora'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    resetOption: obrasciStore.opste.postrojenje_id,
    label: t('OBRASCI.GVE.IZVOR_ZAGADJIVANJA'),
    sortName: 'izvor_zagadjenja_vazduha',
    hideInput:
      (!form.getFieldValue('postrojenje_id') && authStore.getUserRole === UserRoles.SystemAdmin) ||
      (!obrasciStore.opste.postrojenje_id && authStore.getUserRole !== UserRoles.SystemAdmin),
  } as SearchScrollSifarniciFormProps;
};
const IZVOR_VODA_FILTER_DATA = (form: FormInstance<any>) => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      postrojenje_id:
        authStore.getUserRole === UserRoles.SystemAdmin
          ? form.getFieldValue('postrojenje_id')
          : obrasciStore.opste.postrojenje_id,
    },
    formName: 'izvor_zagadjenja_vode_id',
    fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE,
    labelOptionAccessor: ['naziv_ispusta'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    resetOption: obrasciStore.opste.postrojenje_id,
    label: t('POLLUTION.WATER.TITLE'),
    sortName: 'izvor_zagadjenja_vode',
    hideInput:
      (!form.getFieldValue('postrojenje_id') && authStore.getUserRole === UserRoles.SystemAdmin) ||
      (!obrasciStore.opste.postrojenje_id && authStore.getUserRole !== UserRoles.SystemAdmin),
  } as SearchScrollSifarniciFormProps;
};
const IZVOR_TLO_FILTER_DATA = (form: FormInstance<any>) => {
  return {
    type_of_form: 'searchScroll',
    filtersForFetch: {
      postrojenje_id:
        authStore.getUserRole === UserRoles.SystemAdmin
          ? form.getFieldValue('postrojenje_id')
          : obrasciStore.opste.postrojenje_id,
    },
    formName: 'izvor_zagadjenja_tla_id',
    fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE,
    labelOptionAccessor: ['naziv_ispusta'],
    valueOptionAccessor: 'id',
    disabledAccessor: 'is_active',
    resetOption: obrasciStore.opste.postrojenje_id,
    label: t('POLLUTION.SOIL.TITLE'),
    sortName: 'izvor_zagadjenja_tla',
    hideInput:
      (!form.getFieldValue('postrojenje_id') && authStore.getUserRole === UserRoles.SystemAdmin) ||
      (!obrasciStore.opste.postrojenje_id && authStore.getUserRole !== UserRoles.SystemAdmin),
  } as SearchScrollSifarniciFormProps;
};

const BROJ_DOKUMENTA_FILTER_OBRAZAC = {
  type_of_form: 'input',
  label: 'Broj dokumenta',
  name: 'broj_dokumenta',
  inputName: 'broj_dokumenta',
  sortName: 'broj_dokumenta',
} as InputSifarniciFormProps;

const INDEX_NUMBER_FILTER_DATA = {
  type_of_form: 'searchScroll',
  formName: 'indeksni_broj_otpada_id',
  fetchOptinsList: SIFARNICI_ROUTES.WASTE_CATALOGUE,
  labelOptionAccessor: ['index_number', 'waste_sr'],
  valueOptionAccessor: 'id',
  inputName: 'indeksni_broj_otpada_id',
  label: t('OBRASCI.KOM_1.TYPE_OF_WASTE'),
  sortName: 'indeksni_broj_otpada',
} as SearchScrollSifarniciFormProps;

const DATUM_FILTER_DATA = {
  type_of_form: 'date',
  label: 'Datum',
  name: 'datum',
  format: 'YYYY-MM-DD',
  picker: 'date',
  sortName: 'datum',
} as DateSifarniciFormProps;

const DATUM_PREDAJE_OTPADA_FILTER_DATA = {
  type_of_form: 'date',
  label: 'Datum',
  name: 'datum_predaje_otpada',
  format: 'YYYY-MM-DD',
  picker: 'date',
  sortName: 'datum_predaje_otpada',
} as DateSifarniciFormProps;

const GODINA_FILTER_DATA = {
  type_of_form: 'date',
  label: 'Godina',
  name: 'godina',
  format: 'YYYY',
  picker: 'year',
  sortName: 'godina',
} as DateSifarniciFormProps;

const PIB_FILTER_DATA = {
  type_of_form: 'input',
  label: t('COMPANY.PIB'),
  name: 'preduzece__pib',
  inputName: 'preduzece__pib',
  sortName: 'pib',
} as InputSifarniciFormProps;

const MATICNI_BROJ_FILTER_DATA = {
  type_of_form: 'input',
  label: t('COMPANY.ENTERPRISE_ID_NUMBER'),
  name: 'preduzece__maticni_broj',
  inputName: 'preduzece__maticni_broj',
  sortName: 'maticni_broj',
} as InputSifarniciFormProps;

const NACIONALNI_ID_FILTER_DATA = (_: FormInstance<any>) => {
  return {
    type_of_form: 'input',
    label: `${t('FACILITY.NACIONAL_ID')} postrojenja`,
    name: 'postrojenje__nacionalni_id',
    inputName: 'postrojenje__nacionalni_id',
    sortName: 'nacionalni_id',
    hideInput: !(
      authStore.getUserRole === UserRoles.SystemAdmin &&
      obrasciStore.obrasciPoNivou.postrojenje.some(item => item.skraceni_naziv === obrasciStore.real_obrazac_type)
    ),
  } as InputSifarniciFormProps;
};

const BASE_FILTER_DATA = (form: FormInstance<any>) => {
  return [
    PREDUZECE_FILTER_OBRAZAC(form),
    POSTROJENJE_FILTER_OBRAZAC(form),
    PIB_FILTER_DATA,
    MATICNI_BROJ_FILTER_DATA,
    NACIONALNI_ID_FILTER_DATA(form),
  ];
};

const BASE_INITIAL_STATE = {
  preduzece_id: '',
  postrojenje_id: '',
  preduzece__pib: '',
  preduzece__maticni_broj: '',
  postrojenje__nacionalni_id: '',
};

const BASE_WITH_GODINA_INITIAL_STATE = {
  ...BASE_INITIAL_STATE,
  godina: '',
};

export const OBRASCI_FILTERS_DATA: { [key: string]: ISifarniciFormData } = {
  [TIPOVI_OBRAZACA.DKO]: {
    initialState: { ...BASE_INITIAL_STATE, datum_predaje_otpada: '', indeksni_broj_otpada_id: '', broj_dokumenta: '' },
    components: (form: FormInstance<any>) => {
      return [
        ...BASE_FILTER_DATA(form),
        DATUM_PREDAJE_OTPADA_FILTER_DATA,
        BROJ_DOKUMENTA_FILTER_OBRAZAC,
        INDEX_NUMBER_FILTER_DATA,
      ];
    },
  },
  [TIPOVI_OBRAZACA.DEO6_GIO6]: {
    initialState: { ...BASE_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), DATUM_FILTER_DATA];
    },
  },
  [TIPOVI_OBRAZACA.AAO1]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, godina: '' },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA];
    },
  },
  [TIPOVI_OBRAZACA.AAO2]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA];
    },
  },
  [TIPOVI_OBRAZACA.OBRAZAC_1]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA];
    },
  },
  [TIPOVI_OBRAZACA.OBRAZAC_2]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_vazduha_id: '' },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA, IZVOR_VAZDUH_FILTER_DATA(form)];
    },
  },
  [TIPOVI_OBRAZACA.OBRAZAC_3]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_vode_id: '' },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA, IZVOR_VODA_FILTER_DATA(form)];
    },
  },
  [TIPOVI_OBRAZACA.OBRAZAC_4]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, izvor_zagadjenja_tla_id: '' },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA, IZVOR_TLO_FILTER_DATA(form)];
    },
  },
  [TIPOVI_OBRAZACA.OBRAZAC_5]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, indeksni_broj_otpada_id: '' },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA, INDEX_NUMBER_FILTER_DATA];
    },
  },

  [TIPOVI_OBRAZACA.DEP1]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE, nesanitarna_deponija_id: '' },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA, NESANITARNA_DEPONIJA_FILTER_DATA(form)];
    },
  },
  [TIPOVI_OBRAZACA.DEP2]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA];
    },
  },

  [TIPOVI_OBRAZACA.GIO2]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA];
    },
  },
  [TIPOVI_OBRAZACA.GIO3]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA];
    },
  },
  [TIPOVI_OBRAZACA.GIO4]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA];
    },
  },
  [TIPOVI_OBRAZACA.GIO5]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA];
    },
  },
  [TIPOVI_OBRAZACA.PTP2]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA];
    },
  },
  [TIPOVI_OBRAZACA.PZV1]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA];
    },
  },

  [TIPOVI_OBRAZACA.KOM1]: {
    initialState: { ...BASE_WITH_GODINA_INITIAL_STATE },
    components: (form: FormInstance<any>) => {
      return [...BASE_FILTER_DATA(form), GODINA_FILTER_DATA];
    },
  },
};
