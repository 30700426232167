import to from 'await-to-js';
import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';

import { filtersStore } from 'store/filteri.store';
import { saveBlob } from 'utils/fileDownload';
import { obrazacIzvestajiService } from './obrazacIzvestaji.service';
import { IzvestajiSubtypes, ObrazacIzvestajiAllReports } from './obrazacIzvestaji.types';
import { ITablePagination } from 'components/TableData/TableData';

class ObrazacIzvestajiStore {
  obrazacIzvestaj: ObrazacIzvestajiAllReports = [];

  constructor() {
    makeAutoObservable(this);
  }

  setObrazacIzvestaj = (izvestaj: ObrazacIzvestajiAllReports) => {
    this.obrazacIzvestaj = izvestaj;
  };

  clearObrazacIzvestaj = () => {
    this.obrazacIzvestaj = [];
  };

  getObrazacIzvestaj = async (izvestajType: IzvestajiSubtypes, pagination: ITablePagination) => {
    const [err, res] = await to(
      obrazacIzvestajiService.getObrazacByType(
        { type: izvestajType, filter_params: filtersStore.filters.obrazacIzvestaji },
        pagination
      )
    );
    if (err) return Promise.reject(err);
    this.setObrazacIzvestaj(res.items);
    return Promise.resolve(res);
  };

  exportToExcel = async (izvestajType: IzvestajiSubtypes) => {
    const [err, res] = await to(
      obrazacIzvestajiService.exportToExcel({
        type: izvestajType,
        filter_params: filtersStore.filters.obrazacIzvestaji,
      })
    );
    if (err) return Promise.reject(err);
    const fileName = `${izvestajType}_${dayjs().format('DD-MM-YYYY')}`;
    saveBlob(res.data, fileName);
  };

  exportToPDF = async (izvestajType: IzvestajiSubtypes) => {
    const [err, res] = await to(
      obrazacIzvestajiService.exportToPDF({ type: izvestajType, filter_params: filtersStore.filters.obrazacIzvestaji })
    );
    if (err) return Promise.reject(err);
    const fileName = `${izvestajType}_${dayjs().format('DD-MM-YYYY')}`;
    saveBlob(res.data, fileName);
  };
}

export const obrazacIzvestajiStore = new ObrazacIzvestajiStore();
