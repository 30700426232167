import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Gio5TableDozvole } from './components/Gio5TableDozvole/Gio5TableDozvole';

export const Gio5DozvoleWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'gio5-dozvole-form'} modalName="gio5-dozvole-modal" />}
    >
      <Gio5TableDozvole />
    </InsideContentWrapper>
  );
});
