import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FilterUnsanitaryLandfillList } from './FilterUnsanitaryLandfillList/FilterUnsanitaryLandfillList';
import { UnsanitaryLandfillListTable } from './UnsanitaryLandfillListTable/UnsanitaryLandfillListTable';
import { ExcelAndAddButton } from 'components/ExcelAndAddButton/ExcelAndAddButton';
import { modalStore } from 'store';
import { companyStore } from 'modules/company/company.store';
import { ITipObrasca, TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';

export const UnsanitaryLandfillListHeader: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Row justify={'space-between'} align={'middle'}>
      <h3>{t('UNSANITARY_LANDFILL.LIST')}</h3>
      <ExcelAndAddButton
        addButtonLabel="Dodaj nesanitarnu deponiju"
        excelFunc={undefined}
        excelName={'nesanitarne_deponije'}
        addButtonOnChange={
          companyStore.getCompany.is_active &&
          companyStore.getCompanyTipoviObrazaca.some(
            (item: ITipObrasca) => item.skraceni_naziv === TIPOVI_OBRAZACA.DEP1
          )
            ? () => {
                modalStore.changeModalName('unsanitary-landfill-modal');
              }
            : undefined
        }
      />
    </Row>
  );
});

export const CompanyUnsanitaryLandfillList: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<UnsanitaryLandfillListHeader />}>
      <FilterUnsanitaryLandfillList />
      <UnsanitaryLandfillListTable />
    </InsideContentWrapper>
  );
});
