import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { aao1Store } from 'modules/obrasci/store/aao1/aao1.store';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import style from './Aao1UpravljanjeAmbalaznimOtpadomTable.module.scss';
import { IGetUpravljanjeAmbalaznimOtpadom } from 'modules/obrasci/store/aao1/aao1.types';

export const Aao1UpravljanjeAmbalaznimOtpadomTable: React.FC = observer(() => {
  const { t } = useTranslation();

  // proveri za broj dozvole sifarnik

  const columns: ColumnsType<IGetUpravljanjeAmbalaznimOtpadom> = [
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.PACKAGING_TYPE'),
      width: 150,
      align: 'center',
      dataIndex: ['type_of_packing', 'display_type_sr'],
      key: 'type_of_packing',
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.SELF_MANAGEMENT_AMOUNT'),
      width: 150,
      align: 'center',
      dataIndex: 'kolicina_za_samostalno_upravljanje',
      key: 'kolicina_za_samostalno_upravljanje',
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.AMOUNT_TAKEN'),
      width: 150,
      align: 'center',
      dataIndex: 'preuzeta_kolicina',
      key: 'preuzeta_kolicina',
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.R1_OPERATION_AMOUNT'),
      width: 150,
      align: 'center',
      dataIndex: 'kolicina_za_r1_operaciju',
      key: 'kolicina_za_r1_operaciju',
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.R3_OPERATION_AMOUNT'),
      width: 150,
      align: 'center',
      dataIndex: 'kolicina_za_r3_operaciju',
      key: 'kolicina_za_r3_operaciju',
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.SECOND_R_OPERATION'),
      width: 150,
      align: 'center',
      key: 'druga_operacija_r',
      render: (_: any, record: IGetUpravljanjeAmbalaznimOtpadom) => {
        if (!record.druga_operacija_r) return <></>;
        return <>{`${record.druga_operacija_r.name ?? ''} ${record.druga_operacija_d.description_sr ?? ''}`}</>;
      },
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.R2_OPERATION_AMOUNT'),
      width: 150,
      align: 'center',
      dataIndex: 'kolicina_za_drugu_r_operaciju',
      key: 'kolicina_za_drugu_r_operaciju',
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.D1_OPERATION_AMOUNT'),
      width: 150,
      align: 'center',
      dataIndex: 'kolicina_za_d1_operaciju',
      key: 'kolicina_za_d1_operaciju',
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.SECOND_D_OPERATION'),
      width: 150,
      align: 'center',
      key: 'druga_operacija_d',
      render: (_: any, record: IGetUpravljanjeAmbalaznimOtpadom) => {
        if (!record.druga_operacija_r) return <></>;
        <>{`${record.druga_operacija_d.name} ${record.druga_operacija_d.description_sr}`}</>;
      },
    },
    {
      title: t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.D2_OPERATION_AMOUNT'),
      width: 150,
      align: 'center',
      dataIndex: 'kolicina_za_drugu_d_operaciju',
      key: 'kolicina_za_drugu_d_operaciju',
    },

    {
      title: '',
      fixed: 'right',
      width: 50,
      render: (_, record) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-activity'}
            onClick={() => {
              aao1Store.handleChange('upravljanjeAmbalaznimOtpadom', record);
              modalStore.changeModalName(`upravljanje-ambalaznim-otpadom-modal`);
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className={style.tableDiv}>
        <TableData
          name="upravljanje-aao1"
          columns={columns}
          dataSource={[...aao1Store.getInitialState.upravljanje_ambalaznim_otpadom]}
        />
      </div>
    </>
  );
});
