import { observer } from 'mobx-react-lite';
import React from 'react';
import { Row } from 'antd';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import styles from './FacilityActivityLists.module.scss';
import { ActivityList1 } from './components/AcitivityList1/ActivityList1';
import { ActivityList2 } from './components/AcitivityList2/ActivityList2';
import { useTranslation } from 'react-i18next';
import { activityList2Store } from 'modules/activityList2/activityList2.store';
import { activityList1Store } from 'modules/activityList1/activityList1.store';

export const ActivityListsHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Row justify={'space-between'} align={'middle'}>
      <h3>{t('ACTIVITIES.TITLE')}</h3>
    </Row>
  );
};

export const FacilityActivityLists: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<ActivityListsHeader />}>
      <div className={styles.activity_lists}>
        {!activityList2Store.getActivityList.length && <ActivityList1 />}
        {!activityList1Store.getActivityList.length && <ActivityList2 />}
      </div>
    </InsideContentWrapper>
  );
});
