import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { Col } from 'antd';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetSastav } from 'modules/obrasci/store/kom1/kom1.types';
import { useTranslation } from 'react-i18next';
import { MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { GODISNJA_DOBA_FIELDS, SASTAV_GODISNJA_DOBA } from 'modules/obrasci/store/kom1/kom1.constants';
import { formWatchArray } from 'utils/formWatchArray';

export const NewSastavForm: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  useEffect(() => {
    const fieldsValue = props.form.getFieldsValue(GODISNJA_DOBA_FIELDS);
    if (Object.values(fieldsValue).some(item => item !== null)) {
      props.form.validateFields(GODISNJA_DOBA_FIELDS);
      const middleValue = obrasciService.setMiddleValue(fieldsValue);
      props.form?.setFieldValue('srednja_vrednost', middleValue);
    }
  }, [formWatchArray(GODISNJA_DOBA_FIELDS)]);

  const defaultSastavKomunalnogOtpada: ISifarnikInitial | undefined = kom1ObrazacStore.sastav_form
    .composition_communal_waste_id
    ? {
        label: (kom1ObrazacStore.getSastavForm as IGetSastav).composition_communal_waste.name_sr,
        value: (kom1ObrazacStore.getSastavForm as IGetSastav).composition_communal_waste.id,
      }
    : undefined;

  return (
    <Col span={24}>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'composition_communal_waste_id'}
        fetchOptinsList={kom1ObrazacStore.fetchCompositionCommunalWaste}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'composition_communal_waste_id'}
        label={t('OBRASCI.KOM_1.COMPOSITION_OF_MUNICIPAL_WASTE')}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                kom1ObrazacStore.getSastavForm.composition_communal_waste_id !== value &&
                kom1ObrazacStore.initialState.sastav.some(item => {
                  return value === item.composition_communal_waste_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.GIO4.VALIDATION.WASTE_CATALOGUE_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
        colon={true}
        defaultOption={defaultSastavKomunalnogOtpada}
        placeholder={''}
      />
      {/* todo: prevod */}
      <label style={{ fontWeight: 'bold' }}>Analiza frakcija tokom sezonskih analiza</label>
      {SASTAV_GODISNJA_DOBA.map((item, key) => {
        return (
          <FormInputNumber
            key={key}
            name={item.name}
            label={item.label}
            rules={[
              {
                validator: (_, value) => {
                  const fields = Object.values(props.form.getFieldsValue(GODISNJA_DOBA_FIELDS)).some(
                    item => item !== null
                  );

                  if (!fields && value === null) {
                    return Promise.reject(t('VALIDATION.SELECT_A_FIELD'));
                  }
                  return Promise.resolve();
                },
              },
              ...MORE_THEN_0,
            ]}
            placeholder={''}
            inputName={item.name}
            colon={true}
            precision={4}
          />
        );
      })}
      <FormInputNumber
        name="srednja_vrednost"
        label={t('Srednja godišnja vrednost (t)')}
        disabled={true}
        placeholder={''}
        inputName={'srednja_vrednost'}
        colon={true}
        precision={4}
      />
    </Col>
  );
});
