import { ColumnsType } from 'antd/es/table';
import { IGetCList, IPostCList } from './cList.types';
import { FormInstance } from 'antd';
// import { Checkbox, FormInstance } from 'antd';
import { DESCRIPTION_SR_TABLE, DESCRIPTION_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { DESCRIPTION_FORM, DESCRIPTION_SR_FORM, NAME_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
// import { SwitchSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';

// todo: proveriti polje v2

export const C_LIST_INITIAL_STATE: IPostCList = {
  name: '',
  description: '',
  description_sr: '',
  // v2: false,
  is_active: true,
};

export const C_LIST_TABLE_DATA: ColumnsType<IGetCList> = [
  NAME_TABLE,
  DESCRIPTION_TABLE,
  DESCRIPTION_SR_TABLE,
  // {
  //   title: 'V2',
  //   width: 100,
  //   dataIndex: 'v2',
  //   key: 'v2',
  //   render: (_: any, record: IGetCList) => <Checkbox checked={!!record.v2} disabled={true} />,
  // },
];
export const C_LIST_FORM_OPTIONS = (_: FormInstance<IGetCList>) => {
  return [
    NAME_FORM,
    DESCRIPTION_FORM,
    DESCRIPTION_SR_FORM,
    // {
    //   type_of_form: 'switch',
    //   name: 'v2',
    //   label: 'V2',
    // } as SwitchSifarniciFormProps,
  ];
};
