import React from 'react';

import { Modal as ANTModal, ModalProps } from 'antd';

export interface IMyModalProps extends ModalProps {
  name: string;
}

export const Modal: React.FC<IMyModalProps> = props => {
  return <ANTModal {...props} data-test={`modal-${props.name}`} />;
};
