import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { UPRAVLJANJE_INITIAL_STATE } from 'modules/obrasci/store/kom1/kom1.constants';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { useTranslation } from 'react-i18next';
import { NewUpravljanjeForm } from './components/NewUpravljanjeForm/NewUpravljanjeForm';
import { FormInstance } from 'antd';
import { IUpravljanje } from 'modules/obrasci/store/kom1/kom1.types';

export const UpravljanjeModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;
  return (
    <CRUDModal
      width="70%"
      name="upravljanje-modal"
      title={'Upravljanje'}
      store={kom1ObrazacStore}
      data={kom1ObrazacStore.getUpravljanjeForm}
      children={({ form }: { form: FormInstance<IUpravljanje> }) => <NewUpravljanjeForm form={form} />}
      submitForm={kom1ObrazacStore.submitUpravljanjeForm}
      initialState={UPRAVLJANJE_INITIAL_STATE}
      dataToChange={'upravljanje_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    />
  );
});
