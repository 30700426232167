import { observer } from 'mobx-react-lite';

import { IGetAktivnostiObrazac1 } from '../../../../../../../modules/obrasci/store/obrazac1/obrazac1.types';
import { Row, Col, InputNumber } from 'antd';
import styles from './Obrazac1AktivnostiRow.module.scss';
import { useState } from 'react';
import { RowEditIcon } from 'components/RowEditIcon/RowEditIcon';
import { obrazac1Store } from 'modules/obrasci/store/obrazac1/obrazac1.store';
import { nrizService } from 'modules/nriz/nriz.service';

interface Obrazac1AktivnostiProps {
  aktivnost: IGetAktivnostiObrazac1;
  index: number;
}

export const Obrazac1AktivnostiRow: React.FC<Obrazac1AktivnostiProps> = observer(({ aktivnost, index }) => {
  const [isKolicinaEditable, setIsKolicinaEditable] = useState(false);
  const [rowKolicina, setRowKolicina] = useState(aktivnost.ukupna_kolicina_proizvoda);

  const saveKolicinaOnClick = () => {
    const aktivnostToChange = obrazac1Store.aktivnosti.find(
      aktivnostToChange => aktivnostToChange.lista_aktivnosti1_id === aktivnost.lista_aktivnosti1_id
    );
    if (aktivnostToChange) {
      const updatedAktivnost = { ...aktivnostToChange, ukupna_kolicina_proizvoda: rowKolicina };
      nrizService.changeItemInList(obrazac1Store.aktivnosti, updatedAktivnost, 'lista_aktivnosti1_id');
    }
  };

  const cancelEdit = () => {
    setRowKolicina(aktivnost.ukupna_kolicina_proizvoda);
  };

  return (
    <Row className={`${styles.aktivnostRow} ${isKolicinaEditable ? styles.activeRow : ''}`}>
      <Col
        span={9}
      >{`${aktivnost.lista_aktivnosti1?.aktivnost1.code} ${aktivnost.lista_aktivnosti1?.aktivnost1.name_sr}`}</Col>
      <Col span={5}>
        {
          <InputNumber
            name="ukupna_kolicina_proizvoda"
            disabled={!isKolicinaEditable}
            value={rowKolicina}
            onChange={value => setRowKolicina(Number(value))}
            controls={false}
            min={0}
          />
        }
      </Col>
      <Col span={4}>{aktivnost.lista_aktivnosti1?.aktivnost1.proizvodi_jedinica_mere}</Col>
      <Col span={4}>{aktivnost.lista_aktivnosti1?.aktivnost1.proizvodi_definicija_jm}</Col>
      <RowEditIcon
        colSpan={2}
        name={`obrazac1_aktivnost1_${index + 1}`}
        iconState={isKolicinaEditable}
        stateToggler={setIsKolicinaEditable}
        onCancelClick={cancelEdit}
        onSaveClick={saveKolicinaOnClick}
      />
    </Row>
  );
});
