import { observer } from 'mobx-react-lite';
import React from 'react';
import { TableNaselja } from './components/TableNaselja/TableNaselja';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';

export const Kom1NaseljaWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<WizardObrazacListHeader name={'naselja-form'} modalName="naselja-modal" />}>
      <TableNaselja />
    </InsideContentWrapper>
  );
});
