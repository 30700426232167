import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { gio4ObrazacStore } from 'modules/obrasci/store/gio4/gio4.store';
import { useTranslation } from 'react-i18next';
import { GIO_4_IZVOZ_OTPADA_INITIAL_STATE } from 'modules/obrasci/store/gio4/gio4.constants';
import { NewGio4IzvozOtpadaForm } from './NewGio4IzvozOtpadaForm/NewGio4IzvozOtpadaForm';
import { FormInstance } from 'antd';
import { IGetIzvozOtpadaGio4 } from 'modules/obrasci/store/gio4/gio4.types';

export const Gio4IzvozOtpadaModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  return (
    <CRUDModal
      width="80%"
      name="gio4-izvoz-otpada-modal"
      title={t('WIZARD_TABS.IZVOZ_OTPADA')}
      store={gio4ObrazacStore}
      data={gio4ObrazacStore.getIzvozOtpada}
      children={({ form }: { form: FormInstance<IGetIzvozOtpadaGio4> }) => <NewGio4IzvozOtpadaForm form={form} />}
      submitForm={gio4ObrazacStore.submitIzvozOtpada}
      initialState={GIO_4_IZVOZ_OTPADA_INITIAL_STATE}
      dataToChange={'izvozOtpada'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      onCancel={() => {
        gio4ObrazacStore.resetMicro();
      }}
    />
  );
});
