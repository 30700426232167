import { FormInstance } from 'antd';
import { IGetWaterBodies } from './waterBodies.types';

class WaterBodiesService {
  makeCodeAndName = (form: FormInstance<IGetWaterBodies>) => {
    const code = form.getFieldValue('water_body_code');
    const name = form.getFieldValue('water_body_name');
    form.setFieldValue('water_body_code_and_name', `${code}, ${name}`);
  };
}

export const waterBodiesService = new WaterBodiesService();
