import { Button, Col, Form, Row } from 'antd';
import { NewSurveyForm } from 'components/NewForms/NewSurveyForm/NewSurveyForm';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { FacilitySurvey } from './components/FacilityRight/FacilitySurvey';

import styles from './Survey.module.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';
import { LogOutButton } from 'components/LogOutButton/LogOutButton';
import { surveyInitialState, facilityFormInitialState } from 'modules/facility/facility.constants';
import { facilityStore } from 'modules/facility/facility.store';
import { ISurvey } from 'modules/facility/facility.types';

export const Survey: React.FC = observer(() => {
  const { t } = useTranslation();

  const [form] = useForm();

  useEffect(() => {
    if (!facilityStore.getFacilityFormShow) {
      form.setFieldsValue({ ...surveyInitialState, ...facilityStore.getSurvey, postrojenje: facilityFormInitialState });
    }
  }, [facilityStore.getFacilityFormShow]);

  return (
    <div className={styles.surveyWrapper}>
      <div className={styles.surveyHeader}>
        <h1>{t('SURVEY.TITLE')}:</h1>
        <LogOutButton />
      </div>
      <Form
        form={form}
        initialValues={surveyInitialState}
        onFinish={(values: ISurvey) => {
          facilityStore.postSurvey(values);
        }}
        className={styles.survey_form}
        labelAlign="left"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
      >
        <Row gutter={20}>
          <Col span={12}>
            <NewSurveyForm />
          </Col>

          {facilityStore.getFacilityFormShow && (
            <Col span={12}>
              <FacilitySurvey />
            </Col>
          )}
        </Row>
        <Form.Item className={styles.btn}>
          <Button name="submit-survey" htmlType="submit">
            {t('SUBMIT')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
});
