import { Col, Row, Select } from 'antd';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { authStore, modalStore } from 'store';
import { AirListTable } from './AirListTable/AirListTable';
import { facilityStore } from 'modules/facility/facility.store';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { t } from 'i18next';
import { UserRoles } from 'modules/user/user.types';
import { filtersStore } from 'store/filteri.store';

const FacilityAirListHeader: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Row justify={'space-between'} align={'bottom'}>
      <h3>{t('POLLUTION.AIR.LIST')}</h3>
      {facilityStore.getFacility.is_active &&
        facilityStore.getFacilityTipoviObrazaca.some(
          (item: any) =>
            item.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_2 ||
            item.skraceni_naziv === TIPOVI_OBRAZACA.GVE ||
            item.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2
        ) &&
        facilityStore.readOnlyTipoviObrazacaForm && (
          <AddNewButton
            label="Dodaj vazduh"
            name={'air'}
            onButtonClick={() => {
              modalStore.changeModalName('air-modal');
            }}
          />
        )}
    </Row>
  );
});

const FacilityAirFilters: React.FC = observer(() => {
  return (
    <>
      {authStore.getUserRole === UserRoles.SystemAdmin && (
        <Row justify={'center'} align={'middle'} style={{ marginBottom: '5px' }}>
          <Col span={14}>{t('POLLUTION.AIR.OBLIGATION_TO_MEASURE')}</Col>
          <Col span={10}>
            <Select
              onChange={value => filtersStore.handleFiltersChange('izvor_vazduh', 'obaveza_merenja', value)}
              allowClear
            >
              <Select.Option value="da">{t('YES')}</Select.Option>
              <Select.Option value="ne">{t('NO')}</Select.Option>
            </Select>
          </Col>
        </Row>
      )}
    </>
  );
});

export const FacilityAirList: React.FC = observer(() => {
  useEffect(() => {
    return () => filtersStore.setFilters('izvor_vazduh', {});
  }, []);

  return (
    <InsideContentWrapper header={<FacilityAirListHeader />}>
      <FacilityAirFilters />
      <AirListTable />
    </InsideContentWrapper>
  );
});
