import { IPagination } from 'typescript';
import { facilityRepo } from './facility.repo';
import {
  IGetFacility,
  IPostFacility,
  IPostFacilityTipoviObrazaca,
  ISurvey,
  RequestFacilityList,
} from './facility.types';

class FacilityService {
  postSurvey = (payload: ISurvey) => {
    return facilityRepo.postSurvey(payload);
  };

  fetchFacilityList = (payload: RequestFacilityList) => {
    return facilityRepo.fetchFacilityList(payload);
  };

  fetchFacilityListByUser = (payload: Partial<IPagination>) => {
    return facilityRepo.fetchFacilityListByUser(payload);
  };

  fetchFacility = (id: string) => {
    return facilityRepo.fetchFacility(id);
  };

  fetchFacilityTipObrasca = (facilityId: string) => {
    return facilityRepo.fetchFacilityTipObrasca(facilityId);
  };

  postFacilityTipObrasca = (facilityId: string, tipoviObrazca: IPostFacilityTipoviObrazaca) => {
    return facilityRepo.postFacilityTipObrasca(facilityId, tipoviObrazca);
  };

  postFacility = (facility: IPostFacility) => {
    return facilityRepo.postFacility(facility);
  };

  putFacility = (facility: IGetFacility) => {
    return facilityRepo.putFacility(facility);
  };

  deleteFacility = (id: string) => {
    return facilityRepo.deleteFacility(id);
  };

  aktivirajPostrojenje = (id: string) => {
    return facilityRepo.aktivirajPostrojenje(id);
  };

  deaktivirajPostrojenje = (id: string) => {
    return facilityRepo.deaktivirajPostrojenje(id);
  };
}

export const facilityService = new FacilityService();
