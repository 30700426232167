import { observer } from 'mobx-react-lite';
import { NewDep1FizickaZastitaForm } from '../NewDep1FizickaZastitaForm/NewDep1FizickaZastitaForm';

import { useTranslation } from 'react-i18next';
import { NewDep1OstaliPodaciForm } from '../NewDep1OstaliPodaciForm/NewDep1OstaliPodaciForm';
import { NewDep1ProjekatSanacijeForm } from '../NewDep1ProjekatSanacijeForm/NewDep1ProjekatSanacijeForm';
import { GradoviNaseljeWrapper } from '../GradoviNaseljeWrapper/GradoviNaseljeWrapper';
import { MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { Col } from 'antd';

import styles from './NewDep1DivljaDeponijaForm.module.scss';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';

export const NewDep1divljaDeponijaForm: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  return (
    <Col span={24} className={styles.depWrapper}>
      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.DEP1.PANELI.KOLICINE_OTPADA')}</h4>
      <FormInputNumber
        label={t('OBRASCI.DEP1.PROSECNE_GODISNJE_KOLICINE_OTPADA')}
        name="prosecne_godisnje_kolicine_otpada"
        inputName="prosecne_godisnje_kolicine_otpada"
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 8 }}
        style={{ width: '20%' }}
        rules={[REQUIRED_FIELD_RULE(true), ...MORE_THEN_0]}
        colon={false}
      />
      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.DEP1.PANELI.GRADOVI_OPSTINE')}</h4>

      <GradoviNaseljeWrapper />
      <NewDep1FizickaZastitaForm />
      <NewDep1OstaliPodaciForm />
      <NewDep1ProjekatSanacijeForm form={props.form} />
    </Col>
  );
});
