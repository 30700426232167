import { IPaginatedApiResponse, IPagination } from 'typescript/NrizTypes';
import { SIFARNIK_TYPE_API_URL_MAP } from './sifarnici.constants.tsx';
import queryString from 'query-string';
import { ApiResponse, axios } from 'modules/axios';

const fetchSingleSifarnikList = <T>(type: string, queryProps: Partial<IPagination>): IPaginatedApiResponse<T> => {
  const query = queryString.stringify(queryProps);
  const typeRoute = SIFARNIK_TYPE_API_URL_MAP[type];

  return axios.get(`/${typeRoute}/${query ? `?${query}` : ''}`);
};

const postSingleSifarnik = <Payload = any>(type: string, payload: Payload): ApiResponse<{ id: string }> => {
  const typeRoute = SIFARNIK_TYPE_API_URL_MAP[type];
  return axios.post(`/${typeRoute}/`, payload);
};

const putSingleSifarnik = (type: string, payload: any): ApiResponse<any> => {
  const typeRoute = SIFARNIK_TYPE_API_URL_MAP[type];
  return axios.put(`/${typeRoute}/${payload.id}`, payload);
};

const deleteSingleSifarnik = <T>(type: string, id: string): ApiResponse<T> => {
  const typeRoute = SIFARNIK_TYPE_API_URL_MAP[type];
  return axios.delete(`/${typeRoute}/${id}`);
};

const fetchSingleSifarnik = <Payload = any>(type: string, id: string): ApiResponse<Payload> => {
  const typeRoute = SIFARNIK_TYPE_API_URL_MAP[type];
  return axios.get(`/${typeRoute}/${id}`);
};

const fetchUnitCodeByPollutant = (payload: any): ApiResponse<any> => {
  const query = queryString.stringify(payload);
  return axios.get(`/unit-code/obrazac/?${query}`);
};

const fetchIsSifarnikTrue = (type: string, id: string) => {
  const typeRoute = SIFARNIK_TYPE_API_URL_MAP[type];

  return axios.get(`${typeRoute}/${id}`);
};

export const sifarniciRepo = {
  fetchUnitCodeByPollutant,
  fetchSingleSifarnikList,
  fetchSingleSifarnik,
  deleteSingleSifarnik,
  putSingleSifarnik,
  fetchIsSifarnikTrue,
  postSingleSifarnik,
};
