import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { NewObrazac3VodovodniSistemiForm } from '../../Obrazac3MicroServices/components/NewObrazac3VodovodniSistemiForm/NewObrazac3VodovodniSistemiForm';

export const Obrazac3VodovodniSistemiWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={null}>
      <NewObrazac3VodovodniSistemiForm />
    </InsideContentWrapper>
  );
});
