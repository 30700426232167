import { Col, Form, Row } from 'antd';
import { FormInput } from 'components/FormInput/FormInput';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { gio5ObrazacStore } from 'modules/obrasci/store/gio5/gio5.store';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { Gio5ZemljeTranzitaTable } from './Gio5ZemljeTranzitaTable/Gio5ZemljeTranzitaTable';
import { IGio5UvozOtpada } from 'modules/obrasci/store/gio5/gio5.types';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { isEmpty } from 'lodash';
import { cListOptions, iaListOptions, iibListOptions, nListOptions, yListOptions } from 'utils/optionMakers';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';

export const NewGio5UvozOtpadaForm: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const [indexStar, setIndexStar] = useState(false);

  useEffect(() => {
    gio5ObrazacStore.handleChange('index_id', form.getFieldValue('indeksni_broj_otpada_id'));
    if (!isEmpty((gio5ObrazacStore.uvozOtpada as IGio5UvozOtpada).indeksni_broj_otpada)) {
      setIndexStar((gio5ObrazacStore.uvozOtpada as IGio5UvozOtpada).indeksni_broj_otpada.index_number.includes('*'));
      return;
    }
    if (form.getFieldValue('indeksni_broj_otpada_id') !== '') {
      const indexObj = gio5ObrazacStore.indexMap(form.getFieldValue('indeksni_broj_otpada_id'));
      setIndexStar(indexObj.index_number.includes('*'));
    }
  }, [Form.useWatch('indeksni_broj_otpada_id', form)]);

  useEffect(() => {
    gio5ObrazacStore.uvozOtpada.zemlja_izvoza_id
      ? gio5ObrazacStore.handleChange(
          'imaTranzita',
          !(gio5ObrazacStore.uvozOtpada as IGio5UvozOtpada).zemlja_izvoza.granici_se_srbija
        )
      : gio5ObrazacStore.handleChange('imaTranzita', false);
  }, [gio5ObrazacStore.uvozOtpada.zemlja_izvoza_id]);

  const defaultIndexOtpadaOption: ISifarnikInitial | undefined = gio5ObrazacStore.getUvozOtpada.indeksni_broj_otpada_id
    ? {
        label: `${(gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).indeksni_broj_otpada.index_number} ${(
          gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada
        ).indeksni_broj_otpada.waste_sr.toLocaleUpperCase()}`,
        value: gio5ObrazacStore.getUvozOtpada.indeksni_broj_otpada_id,
      }
    : undefined;

  const defaultZemljaIzvozaOption: ISifarnikInitial | undefined = gio5ObrazacStore.getUvozOtpada.zemlja_izvoza_id
    ? {
        label: (gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).zemlja_izvoza.name,
        value: gio5ObrazacStore.getUvozOtpada.zemlja_izvoza_id,
      }
    : undefined;

  const defaultYlistOption: ISifarnikInitial | undefined = gio5ObrazacStore.getUvozOtpada.uvoz_otpada_y_lista_id
    ? {
        value: gio5ObrazacStore.getUvozOtpada.uvoz_otpada_y_lista_id,
        label: `${(gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_y_lista.name} ${
          (gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_y_lista.description_sr
        }`,
      }
    : undefined;

  const defaultYlist2Option: ISifarnikInitial | undefined = gio5ObrazacStore.getUvozOtpada.uvoz_otpada_y_lista2_id
    ? {
        value: gio5ObrazacStore.getUvozOtpada.uvoz_otpada_y_lista2_id,
        label: `${(gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_y_lista2.name} ${
          (gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_y_lista2.description_sr
        }`,
      }
    : undefined;

  const defaultYlist3Option: ISifarnikInitial | undefined = gio5ObrazacStore.getUvozOtpada.uvoz_otpada_y_lista3_id
    ? {
        value: gio5ObrazacStore.getUvozOtpada.uvoz_otpada_y_lista3_id,
        label: `${(gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_y_lista3.name} ${
          (gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_y_lista3.description_sr
        }`,
      }
    : undefined;

  const defaultClistOption: ISifarnikInitial | undefined = gio5ObrazacStore.getUvozOtpada.uvoz_otpada_c_lista_id
    ? {
        value: gio5ObrazacStore.getUvozOtpada.uvoz_otpada_c_lista_id,
        label: `${(gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_c_lista.name} ${
          (gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_c_lista.description_sr
        }`,
      }
    : undefined;

  const defaultNlistOption: ISifarnikInitial | undefined = gio5ObrazacStore.getUvozOtpada.uvoz_otpada_n_lista_id
    ? {
        value: gio5ObrazacStore.getUvozOtpada.uvoz_otpada_n_lista_id,
        label: `${(gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_n_lista.name} ${
          (gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_n_lista.description_sr
        }`,
      }
    : undefined;

  const defaultNlist2Option: ISifarnikInitial | undefined = gio5ObrazacStore.getUvozOtpada.uvoz_otpada_n_lista2_id
    ? {
        value: gio5ObrazacStore.getUvozOtpada.uvoz_otpada_n_lista2_id,
        label: `${(gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_n_lista2.name} ${
          (gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_n_lista2.description_sr
        }`,
      }
    : undefined;

  const defaultNlist3Option: ISifarnikInitial | undefined = gio5ObrazacStore.getUvozOtpada.uvoz_otpada_n_lista3_id
    ? {
        value: gio5ObrazacStore.getUvozOtpada.uvoz_otpada_n_lista3_id,
        label: `${(gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_n_lista3.name} ${
          (gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).uvoz_otpada_n_lista3.description_sr
        }`,
      }
    : undefined;

  const defaultIalistOption: ISifarnikInitial | undefined = gio5ObrazacStore.getUvozOtpada.ia_lista_id
    ? {
        value: gio5ObrazacStore.getUvozOtpada.ia_lista_id,
        label: `${(gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).ia_lista.name} ${
          (gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).ia_lista.description_sr ?? ''
        }`,
      }
    : undefined;

  const defaultIiblistOption: ISifarnikInitial | undefined = gio5ObrazacStore.getUvozOtpada.iib_lista_id
    ? {
        value: gio5ObrazacStore.getUvozOtpada.iib_lista_id,
        label: `${(gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).iib_lista.name} ${
          (gio5ObrazacStore.getUvozOtpada as IGio5UvozOtpada).iib_lista.description_sr ?? ''
        }`,
      }
    : undefined;

  const wasteCatalogueOptions = (options: IGetWasteCatalogue[]) => {
    return options.map(wasteOption => ({
      label: `${wasteOption.index_number} ${wasteOption.waste_sr.toUpperCase()}`,
      value: wasteOption.id,
    }));
  };

  return (
    <Col span={24}>
      <Col>
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'indeksni_broj_otpada_id'}
          fetchOptinsList={gio5ObrazacStore.fetchWasteCatalogueList}
          labelOptionAccessor="index_number"
          valueOptionAccessor="id"
          optionsMaker={wasteCatalogueOptions}
          rules={[REQUIRED_FIELD_RULE(true)]}
          inputName={'indeksni_broj_otpada_id'}
          label={t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          colon={true}
          defaultOption={defaultIndexOtpadaOption}
          placeholder={''}
          disabled={gio5ObrazacStore.index_id !== ''}
        />
      </Col>
      <Col>
        <FormInput
          name="kolicina_uvezenog_otpada"
          type="number"
          label={t('OBRASCI.GIO5.IMPORT_AMOUNT')}
          placeholder={''}
          inputName={'kolicina_uvezenog_otpada'}
          colon={true}
          rules={MORE_THEN_0_IS_REQUIRED}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
        />
      </Col>
      <Col>
        <FormInput
          name="broj_dozvole_za_uvoz"
          type="text"
          label={t('OBRASCI.GIO5.IMPORT_LICENSE')}
          rules={[REQUIRED_FIELD_RULE(true)]}
          placeholder={''}
          inputName={'broj_dozvole_za_uvoz'}
          colon={true}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
        />
      </Col>
      <Col>
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'zemlja_izvoza_id'}
          fetchOptinsList={gio5ObrazacStore.fetchCountryOptions}
          labelOptionAccessor="name"
          valueOptionAccessor="id"
          rules={[REQUIRED_FIELD_RULE(true)]}
          inputName={'zemlja_izvoza_id'}
          label={t('OBRASCI.GIO5.EXPORT_COUNTRY')}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          colon={true}
          placeholder={''}
          defaultOption={defaultZemljaIzvozaOption}
          onChange={gio5ObrazacStore.fetchGranica}
        />
      </Col>

      {gio5ObrazacStore.getImaTranzita && <Gio5ZemljeTranzitaTable />}
      <Col>
        <FormInput
          name="naziv_i_adresa_preduzeca_uvoznika"
          type="text"
          label={t('OBRASCI.GIO5.NAME_ADDRESS')}
          placeholder={''}
          inputName={'naziv_i_adresa_preduzeca_uvoznika'}
          colon={true}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
        />
      </Col>
      {/* todo: prevod */}
      <h3>Izvoz otpada liste</h3>
      <Row>
        <Col span={9}>
          <h4>{t('OBRASCI.GIO4.Y_LIST')}</h4>
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            disabled={!indexStar}
            showSearch={true}
            formName={'uvoz_otpada_y_lista_id'}
            fetchOptinsList={gio5ObrazacStore.fetchYListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'uvoz_otpada_y_lista_id'}
            label={'Y'}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            defaultOption={defaultYlistOption}
            placeholder={''}
            optionsMaker={yListOptions}
          />
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch={true}
            formName={'uvoz_otpada_y_lista2_id'}
            fetchOptinsList={gio5ObrazacStore.fetchYListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'uvoz_otpada_y_lista2_id'}
            label={'/ Y'}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            defaultOption={defaultYlist2Option}
            placeholder={''}
            disabled={!indexStar}
            optionsMaker={yListOptions}
          />
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch={true}
            formName={'uvoz_otpada_y_lista3_id'}
            fetchOptinsList={gio5ObrazacStore.fetchYListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'uvoz_otpada_y_lista3_id'}
            label={'/ Y'}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            defaultOption={defaultYlist3Option}
            placeholder={''}
            disabled={!indexStar}
            optionsMaker={yListOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col span={9}>
          <h4>{t('OBRASCI.GIO4.C_LIST')}</h4>
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch={true}
            formName={'uvoz_otpada_c_lista_id'}
            fetchOptinsList={gio5ObrazacStore.fetchCListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'uvoz_otpada_c_lista_id'}
            placeholder={''}
            wrapperCol={{ span: 18, offset: 5 }}
            defaultOption={defaultClistOption}
            optionsMaker={cListOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col span={9}>
          <h4>{t('OBRASCI.GIO4.N_LIST')}</h4>
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch={true}
            formName={'uvoz_otpada_n_lista_id'}
            fetchOptinsList={gio5ObrazacStore.fetchNListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'uvoz_otpada_n_lista_id'}
            label={'H'}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            defaultOption={defaultNlistOption}
            placeholder={''}
            disabled={!indexStar}
            optionsMaker={nListOptions}
          />
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch={true}
            formName={'uvoz_otpada_n_lista2_id'}
            fetchOptinsList={gio5ObrazacStore.fetchNListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'uvoz_otpada_n_lista2_id'}
            label={'/ H'}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            defaultOption={defaultNlist2Option}
            placeholder={''}
            disabled={!indexStar}
            optionsMaker={nListOptions}
          />
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch={true}
            formName={'uvoz_otpada_n_lista3_id'}
            fetchOptinsList={gio5ObrazacStore.fetchNListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'uvoz_otpada_n_lista3_id'}
            label={'/ H'}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            defaultOption={defaultNlist3Option}
            placeholder={''}
            disabled={!indexStar}
            optionsMaker={nListOptions}
          />
        </Col>
      </Row>
      <h3>{t('OBRASCI.GIO4.SHIPPING_LIST')}</h3>
      <Col>
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'ia_lista_id'}
          fetchOptinsList={gio5ObrazacStore.fetchIaListOptions}
          labelOptionAccessor="name"
          valueOptionAccessor="id"
          inputName={'ia_lista_id'}
          label={t('OBRASCI.GIO4.IA_LIST')}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          defaultOption={defaultIalistOption}
          colon={true}
          rules={[REQUIRED_FIELD_RULE(indexStar)]}
          placeholder={''}
          disabled={!indexStar}
          optionsMaker={iaListOptions}
        />
      </Col>
      <Col>
        <FormSearchInfiniteScroll
          showSearch={true}
          formName={'iib_lista_id'}
          fetchOptinsList={gio5ObrazacStore.fetchIibListOptions}
          labelOptionAccessor="name"
          valueOptionAccessor="id"
          inputName={'iib_lista_id'}
          label={t('OBRASCI.GIO4.IIB_LIST')}
          rules={[REQUIRED_FIELD_RULE(!indexStar)]}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          defaultOption={defaultIiblistOption}
          colon={true}
          placeholder={''}
          disabled={indexStar}
          optionsMaker={iibListOptions}
        />
      </Col>
    </Col>
  );
});
