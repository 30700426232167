import { ColumnsType } from 'antd/es/table';
import { FormInstance } from 'antd';
import {
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  DESCRIPTION_TABLE,
  CODE_TABLE,
  TITLE_TABLE,
  VERSION_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import {
  NAME_FORM,
  NAME_SR_FORM,
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
  TITLE_FORM,
  DESCRIPTION_FORM,
  VERSION_FORM,
  CODE_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { IGetNaceActivityCode, IPostNaceActivityCode } from './naceActivityCode.types';

export const NACE_ACTIVITY_CODE_INITIAL_STATE: IPostNaceActivityCode = {
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  name_sr: '',
  code_prtr: null,
  is_active: true,
};

export const NACE_ACTIVITY_CODE_TABLE_DATA: ColumnsType<IGetNaceActivityCode> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  {
    title: 'Šifra prtr',
    width: '100px',
    dataIndex: 'code_prtr',
    key: 'code_prtr',
  },
];
export const NACE_ACTIVITY_CODE_FORM_OPTIONS = (form: FormInstance<IGetNaceActivityCode>) => {
  return [
    NAME_FORM,
    NAME_SR_FORM,
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM,
    DESCRIPTION_FORM,
    VERSION_FORM,
    CODE_FORM,
    {
      type_of_form: 'input',
      name: 'code_prtr',
      // todo: prevod
      label: 'Šifra prtr',
      inputName: 'code_prtr',
    } as InputSifarniciFormProps,
  ];
};
