import { Col } from 'antd';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import { MORE_THAN_OR_EQUAL_0_RULE, MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrazac4Store } from 'modules/obrasci/store/obrazac4/obrazac4.store';
import { IObrazac4Response } from 'modules/obrasci/store/obrazac4/obrazac4.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { useTranslation } from 'react-i18next';
import { drListOptions } from 'utils/optionMakers';

export const Obrazac4DodatniPodaciWizardTab: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaultTypeOfWasteOption: ISifarnikInitial | undefined = obrazac4Store.getInitialState.type_of_waste_id
    ? {
        label: (obrazac4Store.initialState as IObrazac4Response).type_of_waste.name_sr,
        value: obrazac4Store.initialState.type_of_waste_id,
      }
    : undefined;

  const defaultWasteCatalogueOption: ISifarnikInitial | undefined = obrazac4Store.getInitialState.waste_catalogue_id
    ? {
        value: obrazac4Store.getInitialState.waste_catalogue_id,
        label: `${(obrazac4Store.getInitialState as IObrazac4Response).waste_catalogue.index_number} ${(
          obrazac4Store.getInitialState as IObrazac4Response
        ).waste_catalogue.waste_sr.toUpperCase()}`,
      }
    : undefined;

  const defaultDROption: ISifarnikInitial | undefined = obrazac4Store.getInitialState.dr_list_id
    ? {
        label: `${(obrazac4Store.initialState as IObrazac4Response).dr_list.name} ${
          (obrazac4Store.initialState as IObrazac4Response).dr_list.description_sr
        }`,
        value: obrazac4Store.initialState.dr_list_id,
      }
    : undefined;

  const wasteCatalogueOptions = (options: IGetWasteCatalogue[]) => {
    return options.map(wasteOption => ({
      label: `${wasteOption.index_number} ${wasteOption.waste_sr.toUpperCase()}`,
      value: wasteOption.id,
    }));
  };

  return (
    <Col style={{ backgroundColor: 'white', padding: '10px' }}>
      <h3 style={{ marginBottom: '30px ' }}>{t('OBRASCI.OBRAZAC_4.ADDITIONAL_INFO.TITLE')}:</h3>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={`${t('OBRASCI.OBRAZAC_4.ADDITIONAL_INFO.WASTE_TYPE')}`}
        labelCol={{ span: 8 }}
        formName={'type_of_waste_id'}
        inputName={'type_of_waste_id'}
        labelOptionAccessor={'name_sr'}
        valueOptionAccessor={'id'}
        defaultOption={defaultTypeOfWasteOption}
        rules={[REQUIRED_FIELD_RULE(true)]}
        fetchOptinsList={obrazac4Store.fetchTypeOfWasteOptions}
        placeholder={'Izaberite vrstu otpada'}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('OBRASCI.OBRAZAC_4.ADDITIONAL_INFO.WASTE_INDEX')}
        labelCol={{ span: 8 }}
        formName={'waste_catalogue_id'}
        inputName={'waste_catalogue_id'}
        labelOptionAccessor={'index_number'}
        valueOptionAccessor={'id'}
        defaultOption={defaultWasteCatalogueOption}
        fetchOptinsList={obrazac4Store.fetchWasteCatalogueOptions}
        placeholder={'Izaberite indeksni broj otpada'}
        optionsMaker={wasteCatalogueOptions}
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_4.ADDITIONAL_INFO.AMOUNT_YEAR')}`}
        labelCol={{ span: 8 }}
        name={'godisnja_kolicina_odlozenog_otpada'}
        rules={MORE_THEN_0_IS_REQUIRED}
        inputName={'godisnja_kolicina_odlozenog_otpada'}
        placeholder="Unesite godišnju količinu odloženog otpada"
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_4.ADDITIONAL_INFO.AMOUNT_TOTAL')}`}
        labelCol={{ span: 8 }}
        name={'ukupna_kolicina_odlozenog_otpada'}
        rules={[MORE_THAN_OR_EQUAL_0_RULE]}
        inputName={'ukupna_kolicina_odlozenog_otpada'}
        placeholder="Unesite ukupnu količinu odloženog otpada"
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        label={`${t('OBRASCI.OBRAZAC_4.ADDITIONAL_INFO.OPERATION')}`}
        labelCol={{ span: 8 }}
        formName={'dr_list_id'}
        inputName={'dr_list_id'}
        labelOptionAccessor={'name'}
        valueOptionAccessor={'id'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultDROption}
        optionsMaker={drListOptions}
        fetchOptinsList={obrazac4Store.fetchDROptions}
        placeholder={'Izaberite operaciju odlaganja otpada'}
      />
    </Col>
  );
});
