import { observer } from 'mobx-react-lite';
import styles from './Deo6KalkulacijaInfo.module.scss';

interface Deo6KalkulacijaInfoProps {
  dataArray: { label: string; kolicina: number }[];
  kalkulacijaConfigAccessor: 'otpad' | 'razred';
}

export const Deo6KalkulacijaInfo: React.FC<Deo6KalkulacijaInfoProps> = observer(
  ({ dataArray, kalkulacijaConfigAccessor }) => {
    // todo: prevod
    const title = kalkulacijaConfigAccessor === 'otpad' ? 'Indeksni brojevi otpada:' : 'Razredi električnog otpada:';

    return (
      <div className={styles.wrapper}>
        <h4>
          <b>{title}</b>
        </h4>
        {dataArray.map((item, index) => {
          return (
            <ul key={index} className={styles.itemWrapper}>
              <li>
                <span> {item.label} : </span>
                <span className={styles.kolicina}>{item.kolicina}</span>
              </li>
            </ul>
          );
        })}
      </div>
    );
  }
);
