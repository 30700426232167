import React from 'react';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { useTranslation } from 'react-i18next';
import { gio5ObrazacStore } from 'modules/obrasci/store/gio5/gio5.store';
import { IGio5Country } from 'modules/obrasci/store/gio5/gio5.types';
import { modalStore } from 'store';
import styles from './Gio5ZemljeTranzitaTable.module.scss';

export const Gio5ZemljeTranzitaTable: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  const baseColumns: ColumnsType<IGio5Country> = [
    {
      title: 'Zemlja tranzita',
      width: '95%',
      dataIndex: ['zemlja_tranzita', 'name'],
      key: 'category_name',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      width: '5%',
      render: (_, record: IGio5Country) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-gio5-zemlja-tranzita'}
            onClick={() => {
              modalStore.changeModalName('gio5-zemlje-tranzita-modal');
              gio5ObrazacStore.handleChange('zemljaTranzita', record);
            }}
          />
        );
      },
    },
  ];

  return (
    <InsideContentWrapper
      className={styles.header}
      header={
        <WizardObrazacListHeader
          name={'gio5-zemlja-tranzita-form'}
          modalName="gio5-zemlje-tranzita-modal"
          title={
            gio5ObrazacStore.getImaTranzita && gio5ObrazacStore.getUvozOtpada.zemlje_tranzita.length === 0
              ? t('OBRASCI.GIO5.VALIDATION.TRANSIT')
              : ''
          }
        />
      }
    >
      <TableData
        name="zemlje-tranzita-gio5"
        columns={baseColumns}
        dataSource={[...gio5ObrazacStore.uvozOtpada.zemlje_tranzita]}
      />
    </InsideContentWrapper>
  );
});
