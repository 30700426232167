import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { IGetObrazac3AnalizaOtpadneVode } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const Obrazac3AnalizaOtpadneVodeTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetObrazac3AnalizaOtpadneVode> = [
    {
      title: `${t('OBRASCI.OBRAZAC3.NAZIV_ZAGADJUJUCE_MATERIJE')}`,
      width: 200,
      dataIndex: ['naziv_zagadjujuce_materije', 'name_sr'],
      key: 'naziv_zagadjujuce_materije_id',
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.SREDNJA_GODISNJA_IZMERENA')}`,
      dataIndex: 'srednja_godisnja_izmerena_vrednost',
      key: 'srednja_godisnja_izmerena_vrednost',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.EMITOVANE_KOLICINE')}`,
      dataIndex: 'emitovane_kolicine',
      key: 'emitovane_kolicine',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.NACIN_ODREDJIVANJA')}`,
      dataIndex: ['nacin_odredjivanja', 'name_sr'],
      key: 'nacin_odredjivanja_id',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.METOD_ODREDJIVANJA')}`,
      dataIndex: ['metod_odredjivanja', 'name_sr'],
      key: 'metod_odredjivanja_id',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.EMITOVANE_AKCIDENTNE_GOD')}`,
      dataIndex: 'emitovane_kolicine_u_akcidentnim_situacijama',
      key: 'emitovane_kolicine_u_akcidentnim_situacijama',
      width: 200,
    },
    {
      key: 'operation',
      fixed: 'right',
      width: 130,
      align: 'center',
      render: (_, record: IGetObrazac3AnalizaOtpadneVode) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-komponente'}
            onClick={() => {
              obrazac3Store.handleChange('otpadne_vode_form', record);
              obrazac3Store.handleChange('nacin_odredjivanja_id', record.nacin_odredjivanja_id);
              modalStore.changeModalName('obrazac3-analiza-otpadne-vode-modal');
            }}
          />
        );
      },
    },
  ];
  return (
    <TableData
      name="analiza-otpadne-vode-obrazac3"
      columns={baseColumns}
      dataSource={[...obrazac3Store.initialState.analiza_otpadne_vode]}
    />
  );
  // resi store
});
