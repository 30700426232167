import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IGetKolicine, IKolicine } from 'modules/obrasci/store/kom1/kom1.types';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const TableKolicine: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetKolicine> = [
    {
      title: `${t('OBRASCI.KOM_1.TYPE_OF_WASTE')}`,
      width: 100,
      dataIndex: ['kom1_type_of_waste', 'type_of_waste_sr'],
      key: 'type_of_waste_sr',
    },
    {
      title: `${t('OBRASCI.KOM_1.SPRING_ANALYSIS')}`,
      width: 100,
      dataIndex: 'prolecna_analiza',
      key: 'prolecna_analiza',
    },
    {
      title: `${t('OBRASCI.KOM_1.SUMMER_ANALYSIS')}`,
      dataIndex: 'letnja_analiza',
      key: 'letnja_analiza',
      width: 100,
    },
    {
      title: `${t('OBRASCI.KOM_1.AUTUMN_ANALYSIS')}`,
      dataIndex: 'jesenja_analiza',
      key: 'jesenja_analiza',
      width: 100,
    },
    {
      title: `${t('OBRASCI.KOM_1.WINTER_ANALYSIS')}`,
      dataIndex: 'zimska_analiza',
      key: 'zimska_analiza',
      width: 100,
    },
    {
      title: `${t('OBRASCI.KOM_1.MIDDLE_VALUE')}`,
      dataIndex: 'srednja_vrednost',
      key: 'srednja_vrednost',
      width: 100,
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IKolicine) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-kolicine'}
            onClick={() => {
              kom1ObrazacStore.handleChange('kolicine_form', record);
              modalStore.changeModalName('kolicine-modal');
            }}
          />
        );
      },
    },
  ];
  // todo: check if backend return whole array or we have to do it with pagination
  return (
    <TableData name="kolicine-kom1" columns={baseColumns} dataSource={[...kom1ObrazacStore.initialState.kolicine]} />
  );
});
