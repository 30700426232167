import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { NewObrazac3AnalizaRecipijentaForm } from './NewObrazac3AnalizaRecipijentaForm/NewObrazac3AnalizaRecipijentaForm';
import { OBRAZAC_3_ANALIZA_RECIPIJENATA_INITIAL_STATE } from 'modules/obrasci/store/obrazac3/obrazac3.constants';
import { IGetObrazac3AnalizaRecipijenata } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import { FormInstance } from 'antd';

export const Obrazac3AnalizaRecipijenataModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="60%"
      name="obrazac3-analiza-recipijenata-modal"
      title={t('WIZARD_TABS.ANALIZA_RECIPIJENATA')}
      store={obrazac3Store}
      data={obrazac3Store.getAnalizaRecipijenata}
      children={({ form }: { form: FormInstance<IGetObrazac3AnalizaRecipijenata> }) => (
        <NewObrazac3AnalizaRecipijentaForm form={form} />
      )}
      submitForm={obrazac3Store.submitAnalizaRecipijenata}
      initialState={OBRAZAC_3_ANALIZA_RECIPIJENATA_INITIAL_STATE}
      dataToChange={'analiza_recipijenata_form'}
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
      onCancel={() => obrazac3Store.handleChange('nacin_odredjivanja_id', '')}
    />
  );
});
