import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { Aao2PravnoLiceTable } from './components/Aao2PravnoLiceTable';

export const Aao2PravnoLiceWizardTab = () => {
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'aao2-pravno-lice-form'} modalName="aao2-pravno-lice-modal" />}
    >
      <Aao2PravnoLiceTable />
    </InsideContentWrapper>
  );
};
