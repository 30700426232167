import { IGetCompany, IPostCompany } from 'modules/company/company.types';
import { IGetFacility, IPostFacility } from 'modules/facility/facility.types';

export enum ObrazacIzvestajTypeEnum {
  OBRAZAC_1 = 'obrazac1',
  OBRAZAC_2 = 'obrazac2', // GVE
  OBRAZAC_3 = 'obrazac3', // OTV
  OBRAZAC_5 = 'obrazac5', // GIO 1
  GIO_2 = 'gio2',
  GIO_3 = 'gio3',
  GIO_4 = 'gio4',
  GIO_5 = 'gio5',
  DEO_6 = 'deo6',
  KOM_1 = 'kom1',
  PTP2 = 'ptp2',
  AAO1 = 'aao1',
  AAO2 = 'aao2',
  DEP1 = 'dep1',
  DEP2 = 'dep2',
  PZV = 'pzv',
}

// Todo: commented izvestaji enum values are yet to be implemented
// and are of no big relevance right now

export enum Obrazac1IzvestajiEnum {
  GORIVA = 'obrazac1_gorivo',
  PROIZVODI = 'obrazac1_proizvodi',
  SIROVINE = 'obrazac1_sirovine',
  OPSTE = 'obrazac1_opste',
  // PRIKAZ_PROIZVODA_PO_AKTIVNOSTIMA = 'prikaz_proizvoda_po_aktivnostima',
}

export enum Obrazac2IzvestajiEnum {
  ZAGADLJIVE_MATERIJE = 'obrazac2_emisije_u_vazduhu_zag_materije',
  GORIVO = 'obrazac2_gorivo',
  OPSTE = 'obrazac2_opsti',
  PREGLED_PO_GODINAMA = 'obrazac2_pregled_po_godinama',
  IZVORI_PO_GODINAMA = 'obrazac2_emisije_u_vazduhu_izvori_po_godinama',
  // PRTR_KATEGORIJE = 'prtr_kategorije',
  // KONTROLA_UNOSA = 'kontrola_unosa',
  // PRTR_PREGLED_PO_GODINAMA = 'prtr_pregled_po_godinama',
}

export enum Obrazac3IzvestajiEnum {
  ZAGADLJIVE_MATERIJE = 'obrazac3_analiza_otpdane_vode_zag_materije',
  PREGLED_PO_GODINAMA = 'obrazac3_analiza_otpdane_vode_pregled_po_godinama',
  ISPUST_PO_GODINAMA = 'obrazac3_analiza_otpdane_vode_pregled_ispusti_po_godinama',
  OPSTE = 'obrazac3_analiza_otpdane_vode_opste',
  // KONTROLA_UNOSA = 'kontrola_unosa',
  // PRTR_PREGLED_PO_GODINAMA = 'prtr_pregled_po_godinama',
}

export enum Obrazac5IzvestajiEnum {
  POSTROJENJA = 'obrazac5_otpad_postrojenja',
  POSTROJENJA_INDEKSNI_BROJ = 'obrazac5_otpad_postrojenja_indeksni_broj',
  GRUPA_PODGRUPA_INDEKSNI_BROJ = 'obrazac5_otpad_grupa_podgrupa_indeksni_broj',
  OTPAD_NE_OPASAN = 'obrazac5_otpad_ne_opasan',
  POSTROJENJA_ZA_PLACANJE = 'obrazac5_pregled_po_godinama',
  GENERISANJE_OTPADA_PREGLED_PO_GODINAMA = 'generisanje_otpada_po_godinama',
  // GENERISANJE_OTPADA_KONTROLA_UNOSA = 'generisanje_otpada_kontrola_unosa',
  // PRTR_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA = 'prtr_generisanje_otpada_po_godinama',
}

export enum Gio2IzvestajiEnum {
  POSTROJENJA_INDEKSNI_BROJ = 'gio2_postrojenja',
  GRUPA_PODGRUPA_INDEKSNI_BROJ = 'gio2_grupa',
  ODLAGANJE_OTPADA_PREGLED_PO_GODINI = 'gio2_pregled_po_godinama',
  ELEKTRICNI_I_ELEKTRONSKI_OTPAD = 'gio2_elektricni_otpad',
  // ODLAGANJE_OTPADA_KONTROLA_UNOSA = 'odlaganje_otpada_kontrola_unosa',
}

export enum Gio3IzvestajiEnum {
  POSTROJENJA_INDEKSNI_BROJ = 'gio3_postrojenja_indeksni_broj',
  GRUPA_PODGRUPA_INDEKSNI_BROJ = 'gio3_grupa_podgrupa_indeksni_broj',
  TRETMAN_OTPADA_PREGLED_PO_GODINI = 'gio3_tretman_otpada_pregled_po_godinama',
  ELEKTRICNI_I_ELEKTRONSKI_OTPAD = 'gio3_elektricni_i_elektronski_otpad',
  // TRETMAN_OTPADA_KONTROLA_UNOSA = 'tretman_otpada_kontrola_unosa',
}

export enum Gio4IzvestajiEnum {
  POSTROJENJA_INDEKSNI_BROJ = 'gio4_potsrojenja_indeksni_broj',
  GRUPA_PODGRUPA_INDEKSNI_BROJ = 'gio4_grupa_podgrupa_indeksni_broj',
  IZVOZ_OTPADA_PREGLED_PO_GODINI = 'gio4_izvoz_otpada_pregled_po_godinama',
  ELEKTRICNI_I_ELEKTRONSKI_OTPAD = 'gio4_elektricni_i_elektronski_otpad',
  KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI = 'gio4_kolicina_izvezenog_otpada_po_vrsti_otpada_i_zemlji_izvoza',
  // IZVOZ_OTPADA_KONTROLA_UNOSA = 'izvoz_otpada_kontrola_unosa',
}

export enum Gio5IzvestajiEnum {
  POSTROJENJA_INDEKSNI_BROJ = 'gio5_postrojenja',
  GRUPA_PODGRUPA_INDEKSNI_BROJ = 'gio5_grupa',
  UVOZ_OTPADA_PREGLED_PO_GODINI = 'gio5_pregled_po_godinama',
  KOLICINA_UVEZENOG_OTPADA_PO_VRSTI = 'gio5_kolicina_uvezenog_otpada',
  // IZVOZ_OTPADA_KONTROLA_UNOSA = 'izvoz_otpada_kontrola_unosa',
}

export enum Deo6IzvestajiEnum {
  UKUPNO_REUZET_OTPAD = 'ukupno_preuzet_otpad',
  PREUZET_OTPAD_POSTROJENJA_INDEKSNI_BROJ = 'preuzet_otpad_postrojenja_indeksni_broj',
  UKUPNO_PREDAT_OTPAD = 'ukupno_predat_otpad',
  PREDAT_OTPAD_POSTROJENJA_INDEKSNI_BROJ = 'predat_otpad_postrojenja_indeksni_broj',
  REKAPITULACIJA = 'rekapitulacija',
  REKALKULACIJA = 'rekalkulacija',
  PRIMOPREDAJA_OTPADA_IZMEDJU_PREDUZECA = 'primopredaja_otpada_izmedju_preduzeca',
  // GIO_3_DEO6_PO_PREDUZECU = 'gio3_deo6_po_preduzecu',
}

export enum Kom1IzvestajiEnum {
  SASTAV_KOMUNALNOG_OTPADA_PO_GODINAMA = 'sastav_komunalnog_otpada_po_godinama',
  KOLICINA_KOMUNALNOG_OTPADA_PO_GODINAMA = 'kolicina_komunalnog_otpada_po_godinama',
  OBUHVAT_PRIKUPLJENOG_OTPADA_PO_GODINAMA = 'obuhvat_prikupljenog_otpada_po_godinama',
  UPRAVLJANJE_KOMUNALNIM_OTPADOM = 'upravljanje_komunalnim_otpadom',
  // PREGLED_I_STAMPA_PO_SVIM_PREDUZECIMA = 'pregled_i_stampa_po_svim_preduzecima',
}

export enum Ptp2IzvestajiEnum {
  OBRACUN_ZA_NAPLATU = 'obracun_za_naplatu',
  VOZILA_UPOREDJIVANJE = 'vozila_uporedjivanje',
  NEDOSTAVLJENI_PODACI_O_VOZILIMA = 'nedostavljeni_podaci_o_vozilima',
  UVOZ_GUMA_UPOREDJIVANJE = 'uvoz_gumaa_uporedjivanje',
  NEDOSTAVLJENI_PODACI_O_GUMAMA = 'nedostavljeni_podaci_o_gumama',
  // KONTROLA_UNOSA = 'kontrola_unosa',
}

export enum Aao1IzvestajiEnum {
  PREGLED_PO_GODINAMA = 'aao1_pregled_po_godinama',
  PRIKAZ_1 = 'prikaz_1',
  SVI_PODACI = 'aao1_svi_podaci',
  NEDOSTAVLJENI_OBRASCI = 'nedostavljeni_obrasci',
}

export enum Aao2IzvestajiEnum {
  IZVESTAJ_FOR_BOOK = 'izvestaj_for_book', // is naming good?
}

export enum PzvIzvestajiEnum {
  KOLICINE_PROIZVODA_PO_PREDUZECU = 'kolicine_proizvoda_po_preduzecu',
  KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU = 'kolicine_proizvoda_po_grupi_i_preduzecu',
}

// Todo: check if dep1 & dep2 need to be nested under same DEP1_DEP2 parent
export enum Dep1IzvestajiEnum {
  SVI_PODACI = 'dep1_svi_podaci',
}

export enum Dep2IzvestajiEnum {
  SVI_PODACI = 'dep2_svi_podaci',
}

export type IzvestajiSubtypes =
  | Obrazac1IzvestajiEnum
  | Obrazac2IzvestajiEnum
  | Obrazac3IzvestajiEnum
  | Obrazac5IzvestajiEnum
  | Gio2IzvestajiEnum
  | Gio3IzvestajiEnum
  | Gio4IzvestajiEnum
  | Gio5IzvestajiEnum
  | Deo6IzvestajiEnum
  | Kom1IzvestajiEnum
  | Ptp2IzvestajiEnum
  | Aao1IzvestajiEnum
  | Aao2IzvestajiEnum
  | PzvIzvestajiEnum
  | Dep1IzvestajiEnum
  | Dep2IzvestajiEnum;

export interface IObrazacIzvestajFilters {
  type: IzvestajiSubtypes;
  filter_params: ObrazacIzvestajiAllFilters;
}

// #region Obrazac1 | Goriva, Proizvodi, Sirovine & Opste
export interface Obrazac1Gorivo {
  godina: number;
  naziv_goriva: string;
  potrosnja_na_dan: number;
  jedinica_mere: string;
  kreirano: string;
  azurirano: string;
  napomena: string;
  preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .naziv, .pib
  postrojenje: IPostFacility & IGetFacility; // NOTE: uses -> .naziv_postrojenja, .nacionalni_id, .mesto.municipality_code.name
}

export interface Obrazac1GorivoIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  gorivo_id?: string;
}

export interface Obrazac1Proizvod {
  godina: number;
  prtr_kod: string;
  naziv_proizvoda: string;
  kolicina_proizvoda: number;
  jedinica_mere: string;
  kreirano: string;
  azurirano: string;
  napomena: string;
  preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .naziv, .pib
  postrojenje: IPostFacility & IGetFacility; // NOTE: uses -> .naziv_postrojenja, .nacionalni_id, .mesto.municipality_code.name
}

export interface Obrazac1ProizvodIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  aktivnost_id?: string;
}

export interface Obrazac1Sirovina {
  godina: number;
  iupac: string;
  naziv_sirovine: string;
  potrosnja_na_dan: number;
  jedinica_mere: string;
  kreirano: string;
  azurirano: string;
  napomena: string;
  preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .naziv, .pib
  postrojenje: IPostFacility & IGetFacility; // NOTE: uses -> .naziv_postrojenja, .nacionalni_id, .mesto.municipality_code.name
}

export interface Obrazac1Opste {
  godina: number;
  prtr_aktivnost: string;
  broj_ispusta_u_vazduh: number;
  broj_ispusta_u_vodu: number;
  broj_ispusta_na_tlo: number;
  broj_vrsta_otpada: number;
  rezim_rada: string;
  pocetak_sezone: string;
  kraj_sezone: string;
  broj_smena: number;
  broj_radnih_dana_nedeljno: number;
  broj_radnih_dana_godisnje: number;
  broj_radnih_dana_sezonski: number;
  broj_stalno_zaposlenih: number;
  broj_privremeno_zaposlenih: number;
  broj_zaposlenih_u_prvoj_smeni: number;
  broj_zaposlenih_u_drugoj_smeni: number;
  broj_zaposlenih_u_trecoj_smeni: number;
  preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .naziv, .pib, .maticni_broj, .sifarnik_pretezne_delatnosti.name, .mesto.municipality_code.name
  postrojenje: IPostFacility & IGetFacility; // NOTE: uses -> .naziv_postrojenja, .nacionalni_id, .mesto.municipality_code.name
}
// #endregion Obrazac 1

// #region GIO2 | PostrojenjaIndeksniBroj, GrupaPodgrupaIndeksniBroj, GodisnjeOdlaganjeOtpada, KontrolaUnosaOdlaganjaOtpada & ElektricniIElektronskiOtpad
export interface Gio2PostrojenjaIndeksniBroj {
  godina: number;
  indeksni_broj: string;
  opasan: boolean;
  naziv_otpada: string;
  ukupno_preuzeto: number;
  ukupno_odlozeno: number;
  stanje_na_skladistu_1_januar: number;
  stanje_na_skladistu_31_decembar: number;
  kolicina_za_d1_postupak: number;
  kolicina_za_d10_postupak: number;
  drugi_d_postupak_1: string;
  kolicina_za_drugi_d_postupak_1: number;
  drugi_d_postupak_2: string;
  kolicina_za_drugi_d_postupak_2: number;
  preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .naziv, .pib
  postrojenje: IPostFacility & IGetFacility; // NOTE: used -> .naziv_postrojenja, .nacionalni_id, .mesto.municipality_code.name
}

// NOTE: BE should return sorted data by indeksni_broj
export interface Gio2GrupaPodgrupaIndeksniBroj {
  indeksni_broj: string;
  opis: string;
  kolicina: number;
}

export interface Gio2GodisnjeOdlaganjeOtpada {
  indeksni_broj: string;
  opis_otpada: string;
  // TODO: Check
  [godina: number]: number;
  preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .naziv, .pib
  postrojenje: IPostFacility & IGetFacility; // NOTE: used -> .naziv_postrojenja, .nacionalni_id
}

// NOTE: low priority
export interface Gio2KontrolaUnosaOdlaganjaOtpada {
  izmena_za_godinu: number;
  indeksni_broj: string;
  [godina: number]: number; // TODO: Check
  preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .naziv, .pib
  postrojenje: IPostFacility & IGetFacility; // NOTE: used -> .naziv_postrojenja, .nacionalni_id
}

export interface Gio2ElektricniIElektronskiOtpad {
  godina: number;
  indeksni_broj: string;
  ukupno_preuzeto: number;
  ukupno_odlozeno: number;
  stanje_na_deponiji_1_januar: number;
  stanje_na_deponiji_31_decembar: number;
  d_oznaka_1: string;
  kolicina_d_oznaka_1: number;
  d_oznaka_2: string;
  kolicina_d_oznaka_2: number;
  razred: string;
  vrsta_ee_otpada: string;
  kolicina_ee: number;
  preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .naziv, .pib
  postrojenje: IPostFacility & IGetFacility; // NOTE: used -> .naziv_postrojenja, .nacionalni_id
}

// #endregion GIO2

// #region DEO6 | UkupnoPreuzetOtpad, PreuzetOtpadPostrojenjaIndeksniBroj, PredatOtpadPostrojenjaIndeksniBroj, Rekapitulacija, RekalkulacijaIndeksniBroj, RekalkulacijaRazred, PrimopredajaOtpadaIzmedjuPreduzeca & Gio3Deo6PoPreduzecu
export interface Deo6UkupnoPreuzetOtpad {
  indeksni_broj_otpada: string;
  otpad_opis: string;
  kolicina_preuzeta_od_pravnog_lica: number;
  kolicina_preuzeta_od_fizickog_lica: number;
  ukupno_preuzeto: number;
}

export interface Deo6PreuzetOtpadPostrojenjaIndeksniBroj {
  indeksni_broj: string;
  otpad: string;
  kolicina_preuzeta_od_pravnog_lica: number;
  kolicina_preuzeta_od_fizickog_lica: number;
  ukupno_preuzeto: number;
  preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .naziv, .pib
  postrojenje: IPostFacility & IGetFacility; // NOTE: used -> .naziv_postrojenja, .nacionalni_id
}

export interface Deo6UkupnoPredatOtpad {
  indeksni_broj_otpada: string;
  otpad_opis: string;
  ukupno_predato: number;
}

export interface Deo6PredatOtpadPostrojenjaIndeksniBroj {
  indeksni_broj: string;
  otpad: string;
  ukupno_predato: number;
  preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .naziv, .pib
  postrojenje: IPostFacility & IGetFacility; // NOTE: used -> .naziv_postrojenja, .nacionalni_id
}

export interface Deo6Rekapitulacija {
  datum: string;
  transakcija: string;
  indeksni_broj: string;
  kolicina_otpada: number;
  opis_transakcije: string;
  drugo_postrojenje_sopstvenog_preduzeca: string;
  kolicine_elektricnih_proizvoda_razvrstane_po_razredima: {
    veliki_kucni_aparati: string;
    mali_kucni_aparati: string;
    oprema_informaticke_tehnologije: string;
    oprema_siroke_potrosnje: string;
    osvetljenja: string;
    fluorescentne_cevi: string;
    elektricni_i_elektronski_alati: string;
    igracke_i_oprema: string;
    medicinski_uredjaji: string;
    instrumenti_za_pracenje_i_nadzor: string;
    automati: string;
    oprema_i_uredjaji: string;
  };
  preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .naziv, .pib
  postrojenje: IPostFacility & IGetFacility; // NOTE: used -> .naziv_postrojenja, .nacionalni_id
}

export interface Deo6Rekalkulacija {
  stanje_na_skladistu_1_januara: number;
  preuzet_otpad: number;
  predat_otpad: number;
  ponovna_upotreba_otpada: number;
  ostalo_na_skladistu: number;
}

export interface RekalkulacijaIndeksniBroj extends Deo6Rekalkulacija {
  indeksni_broj: string;
}

export interface Deo6RekalkulacijaRazred extends Deo6Rekalkulacija {
  razred: string;
}

export interface Deo6PrimopredajaOtpadaIzmedjuPreduzeca {
  opsti_podaci: {
    godina: number;
    indeksni_broj: string;
  };
  podaci_o_preduzecu_koje_je_predalo_otpad: {
    preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .naziv, .pib, .maticni_broj
    predata_kolicina_otpada: string;
  };
  vrsta_opreatera_kojem_je_otpad_predat: {
    sakupljac: boolean;
    operator_na_deponiji: boolean;
    operator_za_tretman: boolean;
    skladistar: boolean;
    izvoznik: boolean;
    drugi_vlasnik: boolean;
  };
  podaci_o_preduzecu_koje_je_preuzelo_otpad: {
    preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .pib, .maticni_broj
    preuzeta_kolicina_otpada: number;
  };
  tip_preduzeca_koje_je_preuzelo_otpad: {
    proizvodjac: boolean;
    sakupljac: boolean;
    operator_na_deponiji: boolean;
    operator_za_tretman: boolean;
    skladistar: boolean;
    izvoznik: boolean;
    drugi_vlasnik: boolean;
  };
}

export interface Gio3Deo6PoPreduzecu {
  opsti_podaci: {
    godina: number;
    indeksni_broj: string;
    preduzece: IPostCompany & IGetCompany; // NOTE: uses -> .pib, .maticni_broj, .naziv
  };
  gio3: {
    preuzet_otpad: number;
    tretiran_otpad: number;
  };
  deo6: {
    stanje_na_skladistu_1_januara: number;
    preuzet_otpad: number;
    tretiran_otpad: number;
  };
}

// #endregion DEO6

// #region AAO2
export interface AAO2 {
  temp_id: string;
  reporting_year: string;
  company: string;
  permit_number: string;
  group_number: string;
  sub_group_number: string;
  type_of_packing: string;
  index_number: string;
  produced_quatity: number;
  imported_quatity: number;
  exported_quatity: number;
  total_quatity: number;
  taken_quatity_communal: number;
  taken_quatity_noncommunal: number;
  quatity_operation_r1: number;
  other_r_operation: string;
  quantity_other_r_operation: number;
  quantity_operation_d1: number;
  other_d_operation: string;
  quantity_other_d_operation: number;
  total_reused: number;
  total_disposal: number;
  client_numbers: number;
}
// #endregion AAO2

//#region OBRAZAC 3 / OTV

export interface Obrazac3ZagadjujuceMaterije {
  preduzece: string;
  postrojenje: string;
  pib: string;
  nacionalni_id: string;
  opstina_postrojenja: string;

  godina: number;
  prtr: string;
  aktivnosti: string;
  naziv_ispusta: string;
  broj_ispusta: string;
  vrsta_recipijenta: string;
  naziv_recipijenta: string;
  sliv: string;
  vremenski_period_ispustanja_vode: string;
  ukupna_kolicina_ispustene_vode: string;
  srednji_dnevni_protok_otpadne_vode: string;
  zagadjujuca_materija: string;
  srednja_godisnja_izmerena_vrednost: string;
  kolicina_pri_redovnom_radu: string;
  kolicina_u_akcidentnim_situacijama: string;
  kreirano: string;
  azurirano: string;
  napomena: string;
}

export interface Obrazac3PregledPoGodinama {
  preduzece: string;
  postrojenje: string;
  pib: string;
  nacionalni_id: string;
  zagadjujuca_materija: string;
  kolicine: any[];
}

export interface Obrazac3IspustPoGodinama {
  preduzece: string;
  postrojenje: string;
  pib: string;
  nacionalni_id: string;
  oznaka_ispusta: number;
  naziv_ispusta: string;
  zagadjujuca_materija: string;
  kolicine: any[];
}

export interface Obrazac3Opste {
  preduzece: string;
  postrojenje: string;
  pib: string;
  nacionalni_id: string;
  opstina_postrojenja: string;
  godina: number;
  prtr: string;
  aktivnosti: string;
  oznaka_ispusta: number;
  naziv_ispusta: string;
  vrsta_recipijenta: string;
  naziv_recipijenta: string;
  geografska_sirina: string;
  geografska_duzina: string;
  rezim_rada: string;
  rashladne: boolean;
  sanitarne: boolean;
  atmosferske: boolean;
  tehnoloske: boolean;
  period_ispustanja: string;
  ukupna_kolicina_ispustene_vode: string;
  kreirano: string;
  azurirano: string;
}

//#endregion

//#region GIO 4

export interface Gio4PostrojenjaIndeksniBroj {
  preduzece: string;
  postrojenje: string;
  pib: string;
  nacionalni_id: string;
  opstina_postrojenja: string;
  godina: string;
  indeksni_broj: string;
  ukupno_izvezeno: string;
  opasan: boolean;
  naziv_otpada: string;
  broj_dozvole: string;
  zemlja_izvoza: string;
  zemlja_tranzita: string;
  naziv_adresa_postrojenja_u_inostranstvu: string;
  y1: string;
  y2: string;
  y3: string;
  c: string;
  h1: string;
  h2: string;
  h3: string;
  ia: string;
  iib: string;
  r: string;
  d: string;
}

export interface Gio4GrupaPodgrupaIndeksniBroj {
  indeksni_broj: string;
  opis: string;
  kolicina: number;
  children?: Gio4GrupaPodgrupaIndeksniBroj[];
}

export interface Gio4PregledPoGodinama {
  preduzece: string;
  postrojenje: string;
  pib: string;
  nacionalni_id: string;
  indeksni_broj: string;
  opis_otpada: string;
  kolicine: any[];
}

export interface Gio4KolicinaIzvezenogOtpadaPoVrstiOtpada {
  indeksni_broj: string;
  otpad: string;
  kolicina_izvezenog_otpada: number;
  zemlja_izvoza: string;
}

export interface Gio4ElektricniIElektronskiOtpad {
  preduzece: string;
  postrojenje: string;
  pib: string;
  nacionalni_id: string;
  godina: string;
  indeksni_broj: string;
  kolicina_izvezenog_otpada: string;
  broj_dozvole: string;
  zemlja_izvoza: string;
  zemlja_tranzita: string;
  naziv_adresa_postrojenja_u_inostranstvu: string;
  y1: string;
  y2: string;
  y3: string;
  c: string;
  h1: string;
  h2: string;
  h3: string;
  ia: string;
  iib: null;
  r: null;
  d: string;
  razred: string;
  vrsta_ee_otpada: string;
  kolicina_ee: string;
}

//#endregion

//#region PTP2

export interface Ptp2ObracunZaNaplatu {
  vrsta_proizvoda: string;
  kolicine: any[];
}
export interface Ptp2VozilaUporedjivanje {
  pib: string;
  preduzece: string;
  kolicine: any[];
}
export interface Ptp2NedostavljeniPodaciOVozilima {
  pib: string;
  preduzece: string;
  kolicine: any[];
}
export interface Ptp2UvozGumaUporedjivanje {
  pib: string;
  preduzece: string;
  kolicine: any[];
}
export interface Ptp2NedostavljeniPodaciOGumama {
  pib: string;
  preduzece: string;
  kolicine: any[];
}

//#endregion

//#region PZV
export interface PzvKolicineProizvodaPoPreduzecu {
  godina: string;
  pib: string;
  preduzece: string;
  grupa_proizvoda: string;
  proizvod: string;
  opis_proizvoda: string;
  kolicina_proizvoda: string;
  sadrzaj: string;
  predefinisi_sadrzaj: string;
  ukupna_kolicina: string;
}

export interface PzvKolicineProizvodaPoGrupiIPreduzecu {
  godina: string;
  pib: string;
  maticni_broj: string;
  preduzece: string;
  mesto: string;
  opstina: string;
  ulica: string;
  broj: string;
  grupa_proizvoda: string;
  kolicina_grupe_proizvoda: string;
  ukupna_kolicina: string;
  kolicina_grupe_proizvoda_na_carini: string;
}
//#endregion

// #region Obrazac5 | Postrojenja, Postrojenja - Indeksni broj, Grupa-Podgrupa-Indeksni broj, Ne/Opasan, Pregled po godinama, Kontrola unosa, Postrojenja za plaćanje, PRTR Generisanje otpada
export interface Obrazac5Postrojenja {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina_postrojenja: string;
  aktivnosti: string;
  preneto_iz_prethodnog_perioda: number | null;
  godisnja_kolicina_proizvedenog_otpada: number | null;
  kolicina_otpada_predata_na_skladistenje: number | null;
  kolicina_otpada_predata_na_odlaganje: number | null;
  kolicina_otpada_predata_na_tretman: number | null;
  kolicina_izvezenog_otpada: number | null;
  ostalo_na_lokaciji: number | null;
}

export interface Obrazac5PostrojenjaIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  opasan?: string;
  obrazac?: string;
}

export interface Obrazac5PostrojenjaIndeksniBroj {
  godina: number;
  pib: string;
  preduzece: string;
  sifra_pretezne_delatnosti: string;
  nacionalni_id: string;
  postrojenje: string;
  opstina_postrojenja: string;
  aktivnosti: string;
  indeksni_broj: string;
  opasan: boolean;
  naziv_otpada: string;
  vrsta_otpada: string;
  godisnja_kolicina_proizvedenog_otpada: number | null;
  kolicina_otpada_predata_na_skladistenje: number | null;
  kolicina_otpada_predata_na_odlaganje: number | null;
  kolicina_otpada_predata_na_tretman: number | null;
  kolicina_izvezenog_otpada: number | null;
  y1: string | null;
  y2: string | null;
  y3: string | null;
}

export interface Obrazac5PostrojenjaIndeksniBrojIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  principalbusinessactivity_id?: string;
  indeksni_broj_id?: string;
  opasan?: string;
  obrazac?: string;
  y_mark_id?: string;
}

export interface Obrazac5GrupaPodgrupaIndeksniBroj {
  indeksni_broj: string;
  opis: string;
  kolicina: number;
  children?: Obrazac5GrupaPodgrupaIndeksniBroj[];
}

export interface Obrazac5GrupaPodgrupaIndeksniBrojIzvestajFilters {
  godina?: string;
  obrazac?: string;
  y_mark_id?: string;
  indeksni_broj_id?: string;
}

export interface Obrazac5NeOpasan {
  grupa: string;
  kolicina_neopasnog_otpada: number | null;
  kolicina_opasnog_otpada: number | null;
}

export interface Obrazac5NeOpasanIzvestajFilters {
  godina?: string;
  obrazac?: string;
}

export interface Obrazac5PregledPoGodinama {
  pib: string;
  preduzece: string;
  nacionalni_id: number;
  postrojenje: string;
  indeksni_broj: string;
  opis_otpada: string;
  kolicine: any[];
}

export interface Obrazac5PregledPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  indeksni_broj_id?: string;
  opstina_id?: string;
  opasan?: string;
  obrazac?: string;
}

// NOTE: low priority
export interface Obrazac5KontrolaUnosa {
  izmena_za_godinu: number;
  pib: number;
  preduzece: string;
  nacionalni_id: number;
  postrojenje: string;
  indeksni_broj: string;
  opis_otpada: string;
  kolicine: any[];
}

export interface Obrazac5KontrolaUnosaIzvestajFilters {
  godina?: string;
  godina_od?: string;
  godina_do?: string;
  obrazac?: string;
}

export interface Obrazac5PostrojenjaZaPlacanje {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: number;
  postrojenje: string;
  opstina_postrojenja: string;
  ippc: boolean;
  aktivnosti: string;
  preneto_iz_prethodnog_perioda: number | null;
  godisnja_kolicina_proizvedenog_otpada: number | null;
  kolicina_otpada_predata_na_skladistenje: number | null;
  kolicina_otpada_predata_na_odlaganje: number | null;
  kolicina_otpada_predata_na_tretman: number | null;
  kolicina_izvezenog_otpada: number | null;
  ostalo_na_lokaciji: number | null;
}

export interface Obrazac5PostrojenjaZaPlacanjeIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  opasan?: string;
  obrazac?: string;
}

// NOTE: low priority
export interface Obrazac5PRTRGenerisanjeOtpada {
  pib: number;
  preduzece: string;
  nacionalni_id: number;
  istorijski_id: string;
  postrojenje: string;
  indeksni_broj: string;
  opis_otpada: string;
  total: number;
}
// #endregion Obrazac5

// #region GIO5 | Postrojenja-Indeksni broj, Grupa-Podrgrupa-Indeksni broj, Pregled po godinama, Kolicina uvezenog otpada po vrsti otpada, Kontrola unosa
export interface Gio5PostrojenjaIndeksniBroj {
  godina: number;
  pib: string;
  preduzece: string;
  nacionalni_id: number;
  postrojenje: string;
  opstina_postrojenja: string;
  indeksni_broj: string;
  opasan: boolean;
  naziv_otpada: string;
  ukupno_uvezeno: number;
  zemlja_izvoza: string;
  zemlje_tranzita: string;
  y1: string;
  y2: string;
  y3: string;
  c_oznaka: string | null;
  h1: string;
  h2: string;
  h3: string;
  ia_oznaka: string;
  iib_oznaka: string;
}

export interface Gio5PostrojenjaIndeksniBrojIzvestajFilters {
  godina?: string;
  preduzece_id?: string;
  sifra_delatnosti_id?: string;
  postrojenje_id?: string;
  opstina_id?: string;
  indeksni_broj?: string;
  opasan?: string;
}

export interface Gio5GrupaPodgrupaIndeksniBroj {
  indeksni_broj: string;
  opis: string;
  kolicina: number;
  children?: Gio5GrupaPodgrupaIndeksniBroj[];
}

export interface Gio5GrupaPodgrupaIndeksniBrojIzvestajFilters {
  godina?: string;
  nivo?: string;
  indeksni_broj?: string;
}

export interface Gio5PregledPoGodinama {
  pib: string;
  preduzece: string;
  nacionalni_id: number;
  postrojenje: string;
  indeksni_broj: string;
  opis_otpada: string;
  kolicine: any[];
}

export interface Gio5PregledPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
  preduzece_id?: string;
  postrojenje_id?: string;
  indeksni_broj_id?: string;
  opstina_id?: string;
  opasan?: string;
}

export interface Gio5KolicinaUvezenogOtpadaPoVrstiOtpada {
  indeksni_broj: string;
  otpad: string;
  kolicina_uvezenog_otpada: number;
  zemlja_izvoza: string;
}

export interface Gio5KolicinaUvezenogOtpadaPoVrstiOtpadaIzvestajFilters {
  godina?: string;
  indeksni_broj?: string;
}

// NOTE: low priority
export interface Gio5KontrolaUnosa {
  izmena_za_godinu: number;
  pib: number;
  preduzece: string;
  nacionalni_id: number;
  postrojenje: string;
  indeksni_broj: string;
  kolicina: any[];
}
// #endregion GIO5

// #region AAO1 | Pregled po godinama, Prikaz 1, Svi podaci, Nedostavljeni obrasci
export interface Aao1PregledPoGodinama {
  pib: string;
  preduzece: string;
  email: string;
  vrsta_ambalaze: string;
  kolicine: any[];
}

export interface Aao1PregledPoGodinamaIzvestajFilters {
  godina_od?: string;
  godina_do?: string;
  preduzece_id?: string;
}

export interface Aao1Prikaz1 {
  pib: string;
  preduzece: string;
  maticni_broj: string;
  ulica: string;
  kucni_broj: string;
  opstina_naziv: string;
  vrsta_ambalaze: string;
  ukupno_nepovratne_ambalaze: number | null;
  ukupno_povratne_ambalaze: number | null;
  operater1: string;
  operater2: string;
  kreirano: string | null;
  azurirano: string | null;
}

export interface Aao1Prikaz1IzvestajFilters {
  godina?: string;
  operater?: string;
  pib?: string;
  operater_search_id?: string;
  dostavljeno_od?: string;
  dostavljeno_do?: string;
}

export interface Aao1SviPodaci {
  godina: number;
  pib: string;
  preduzece: string;
  proizvodjac_ambalaze: string;
  paker_proizvoda_u_ambalazu: string;
  isporucilac_ambalaze: string;
  uvoznik_ambalaze: string;
  izvozvnik_ambalaze: string;
  krajnji_korisnik_ambalaze: string;
  oznaka_grupe: string;
  oznaka_podgrupe: string;
  vrsta_ambalaze: string;
  nepovratna_ambalaza_proizvedena_kolicina: number | null;
  nepovratna_ambalaza_uvezena_kolicina: number | null;
  nepovratna_amvalaza_izvezena_kolicina: number | null;
  nepovratna_ambalaza_ukupna_kolicina: number | null;
  povratna_ambalaza_proizvedena_kolicina: number | null;
  povratna_ambalaza_uvezena_kolicina: number | null;
  povratna_ambalaza_izvezena_kolicina: number | null;
  povratna_ambalaza_ukupna_kolicina: number | null;
  kolicina_ambalaze_preneta_na_operatere: number | null;
  procenat_ambalaze_prenet_na_operatere: number | null;
  naziv_operatera1: string;
  broj_dozvole_operatera1: string;
  naziv_operatera2: string;
  broj_dozvole_operatera2: string;
  kolicina_ambalaze_na_samostalnom_upravljanju: number | null;
  procenat_ambalaze_prenet_na_samostalno_upravljanje: number | null;
}

export interface Aao1SviPodaciIzvestajFilters {
  godina?: string;
  pib?: string;
}

export interface Aao1NedostavljeniObrasci {
  godina: number;
  pib: string;
  preduzece: string;
  pib_operatera: number;
  naziv_operatera: string;
  maticni_broj: string;
  adresa: string;
  mesto: string;
  postanski_broj: string;
  telefon: string;
  email: string;
}

export interface Aao1NedostavljeniObrasciIzvestajFilters {
  godina?: string;
  pib_operatera?: string;
}
// #endregion AAO1

export type ObrazacIzvestajiAllReports =
  | Obrazac1Gorivo[]
  | Obrazac1Proizvod[]
  | Obrazac1Sirovina[]
  | Obrazac1Opste[]
  | Gio2PostrojenjaIndeksniBroj[]
  | Gio2GrupaPodgrupaIndeksniBroj[]
  | Gio2GodisnjeOdlaganjeOtpada[]
  | Gio2KontrolaUnosaOdlaganjaOtpada[]
  | Gio2ElektricniIElektronskiOtpad[]
  | Deo6UkupnoPreuzetOtpad[]
  | Deo6PreuzetOtpadPostrojenjaIndeksniBroj[]
  | Deo6UkupnoPredatOtpad[]
  | Deo6PredatOtpadPostrojenjaIndeksniBroj[]
  | Deo6Rekapitulacija[]
  | Deo6Rekalkulacija[]
  | RekalkulacijaIndeksniBroj[]
  | Deo6RekalkulacijaRazred[]
  | Deo6PrimopredajaOtpadaIzmedjuPreduzeca[]
  | Gio3Deo6PoPreduzecu[]
  | AAO2[]
  | Obrazac3ZagadjujuceMaterije[]
  | Obrazac3PregledPoGodinama[]
  | Obrazac3IspustPoGodinama[]
  | Obrazac3Opste[]
  | Gio4PregledPoGodinama[]
  | Gio4ElektricniIElektronskiOtpad[]
  | Gio4GrupaPodgrupaIndeksniBroj[]
  | Gio4KolicinaIzvezenogOtpadaPoVrstiOtpada[]
  | Gio4PostrojenjaIndeksniBroj[]
  | Ptp2ObracunZaNaplatu[]
  | Ptp2VozilaUporedjivanje[]
  | Ptp2NedostavljeniPodaciOVozilima[]
  | Ptp2UvozGumaUporedjivanje[]
  | Ptp2NedostavljeniPodaciOGumama[]
  | PzvKolicineProizvodaPoPreduzecu[]
  | PzvKolicineProizvodaPoGrupiIPreduzecu[]
  | Obrazac5Postrojenja[]
  | Obrazac5PostrojenjaIndeksniBroj[]
  | Obrazac5GrupaPodgrupaIndeksniBroj[]
  | Obrazac5NeOpasan[]
  | Obrazac5PregledPoGodinama[]
  | Obrazac5KontrolaUnosa[]
  | Obrazac5PostrojenjaZaPlacanje[]
  | Obrazac5PRTRGenerisanjeOtpada[]
  | Gio5PostrojenjaIndeksniBroj[]
  | Gio5GrupaPodgrupaIndeksniBroj[]
  | Gio5PregledPoGodinama[]
  | Gio5KolicinaUvezenogOtpadaPoVrstiOtpada[]
  | Gio5KontrolaUnosa[]
  | Aao1PregledPoGodinama[]
  | Aao1Prikaz1[]
  | Aao1SviPodaci[]
  | Aao1NedostavljeniObrasci[];

export type ObrazacIzvestajiAllFilters =
  | Obrazac1GorivoIzvestajFilters
  | Obrazac1ProizvodIzvestajFilters
  | Obrazac5PostrojenjaIzvestajFilters
  | Obrazac5PostrojenjaIndeksniBrojIzvestajFilters
  | Obrazac5GrupaPodgrupaIndeksniBrojIzvestajFilters
  | Obrazac5NeOpasanIzvestajFilters
  | Obrazac5PregledPoGodinamaIzvestajFilters
  | Obrazac5KontrolaUnosaIzvestajFilters
  | Obrazac5PostrojenjaZaPlacanjeIzvestajFilters
  | Gio5PostrojenjaIndeksniBrojIzvestajFilters
  | Gio5GrupaPodgrupaIndeksniBrojIzvestajFilters
  | Gio5PregledPoGodinamaIzvestajFilters
  | Gio5KolicinaUvezenogOtpadaPoVrstiOtpadaIzvestajFilters
  | Aao1PregledPoGodinamaIzvestajFilters
  | Aao1Prikaz1IzvestajFilters
  | Aao1SviPodaciIzvestajFilters
  | Aao1NedostavljeniObrasciIzvestajFilters;
