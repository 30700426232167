import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { IGetGorivaGve } from 'modules/obrasci/store/gve/gve.types';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const GveGorivaTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetGorivaGve> = [
    {
      title: `${t(['OBRASCI.GVE.GORIVO'])}`,
      width: 100,
      dataIndex: ['naziv_goriva', 'name_sr'],
      key: 'name_sr',
    },
    {
      title: `${t(['OBRASCI.GVE.UKUPNA_GODISNJA_POTROSNJA'])}`,
      width: 100,
      dataIndex: 'ukupna_godisnja_potrosnja',
      key: 'ukupna_godisnja_potrosnja',
    },

    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetGorivaGve) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-gve-gorivo'}
            onClick={() => {
              gveStore.handleChange('goriva_form', record);
              modalStore.changeModalName('gve-gorivo-modal');
            }}
          />
        );
      },
    },
  ];

  //// storemyyyy TODO
  return <TableData name="gorivo-gve" columns={baseColumns} dataSource={[...gveStore.initialState.goriva]} />;
});
