import { Col, Row } from 'antd';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { observer } from 'mobx-react-lite';
import styles from './DevicesTreatmentOptions.module.scss';
import { optionsDevicesWaterTreatment } from 'modules/sourcesOfPollution/water/water.constants';
import { waterStore } from 'modules/sourcesOfPollution/water/water.store';

export const DevicesTreatmentOptions: React.FC = observer(() => {
  return (
    <>
      {optionsDevicesWaterTreatment.map((items, index) => {
        return (
          <Row key={index}>
            <Col span={8}>
              <span className={styles.title}>{items.title}</span>
            </Col>
            <Col span={16}>
              {items.items.map((item, index) => {
                return (
                  <FormCheckbox
                    key={index}
                    labelCol={{ span: 22 }}
                    onCheckBoxChange={e => waterStore.changeWaterDeviceTreatmentList(item.name, e.target.checked)}
                    name={item.name}
                    label={item.label}
                  />
                );
              })}
            </Col>
          </Row>
        );
      })}
    </>
  );
});
