import { IBaseObrazacStore, IPagination } from 'typescript/NrizTypes';
import {
  IGetDozvoleGio3,
  IGetGio3Obrazac,
  IGetKolicineGio3,
  IGetPostrojenjeGio3,
  IGetRazrediEeOtpadaGio3,
  IPostDozvoleGio3,
  IPostGio3Obrazac,
  IPostKolicineGio3,
  IPostPostrojenjaGio3,
  IPostRazrediEeOtpadaGio3,
} from './gio3.types';
import {
  GIO_3_DOZVOLE_INITIAL_STATE,
  GIO_3_INITIAL_STATE,
  KOLICINE_GIO_3_INITIAL_STATE,
  POSTROJENJA_GIO_3_INITIAL_STATE,
  RAZREDI_EE_OTPADA_INITIAL_STATE,
} from './gio3.constants';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import dayjs from 'dayjs';
import to from 'await-to-js';
import { nrizService } from 'modules/nriz/nriz.service';
import { formStore } from 'store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { computed, makeAutoObservable, observable } from 'mobx';
import { arrayToObject } from 'utils/arrayToObject';
import { handleSuccessNotify } from 'utils/succesHandling';
import {
  IGetWasteCatalogue,
  ResponseWasteCatalouge,
} from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { ResponseDrList } from 'modules/sifarnici/store/dRList/dRList.types';
import { ResponseMesto } from 'modules/sifarnici/store/mesto/mesto.types';
import { ResponseWeeeCategories } from 'modules/sifarnici/store/weeeCategories/weeeCategories.types';

class Gio3ObrazacStore implements IBaseObrazacStore<IGetGio3Obrazac, IPostGio3Obrazac> {
  initialState: IPostGio3Obrazac | IGetGio3Obrazac = GIO_3_INITIAL_STATE;
  kolicine_form: IPostKolicineGio3 | IGetKolicineGio3 = KOLICINE_GIO_3_INITIAL_STATE;
  razredi_ee_form: IPostRazrediEeOtpadaGio3 | IGetRazrediEeOtpadaGio3 = RAZREDI_EE_OTPADA_INITIAL_STATE;
  postrojenja_form: IPostPostrojenjaGio3 | IGetPostrojenjeGio3 = POSTROJENJA_GIO_3_INITIAL_STATE;
  dozvole_form: IPostDozvoleGio3 | IGetDozvoleGio3 = GIO_3_DOZVOLE_INITIAL_STATE;
  dozvoleNizValidacija: string[] = [];
  index_id = '';

  constructor() {
    makeAutoObservable(this, {
      initialState: observable,
      kolicine_form: observable,
      razredi_ee_form: observable,
      postrojenja_form: observable,
      index_id: observable,
      dozvole_form: observable,
      dozvoleNizValidacija: observable,
      getPostrojenjaForm: computed,
      getKolicineForm: computed,
      getRazrediEeForm: computed,
      getDozvoleForm: computed,
    });
  }
  get getKolicineForm() {
    return this.kolicine_form;
  }
  get getRazrediEeForm() {
    return this.razredi_ee_form;
  }
  get getPostrojenjaForm() {
    return this.postrojenja_form;
  }

  get getDozvoleForm() {
    return this.dozvole_form;
  }

  handleChange(key: string, value: any) {
    (this as any)[key] = value;
  }

  handleInitialStateChange(key: string, value: any) {
    (this as any).initialState[key] = value;
  }

  handleKolicineForm(key: string, value: any) {
    (this as any).kolicine_form[key] = value;
  }

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGetGio3Obrazac>(TIPOVI_OBRAZACA.GIO3, id));
    if (err) return;
    const newGodina = dayjs().year((res.godina as any) * 1);
    const newDozvole = this.makeNewDozvole((res as IGetGio3Obrazac).dozvole);
    this.handleChange('initialState', { ...res, godina: newGodina, dozvole: newDozvole });

    return res;
  };

  async postData(payload: IPostGio3Obrazac) {
    const [err, res] = await to(
      obrasciService.postSingleObrazac(TIPOVI_OBRAZACA.GIO3, nrizService.setEmptyValuesToNull(payload))
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }
  putData = async (payload: IGetGio3Obrazac) => {
    const [err, res] = await to(obrasciService.putSingleObrazac(TIPOVI_OBRAZACA.GIO3, payload.id, payload));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.GIO3, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  submitData = (payload: IPostGio3Obrazac | IGetGio3Obrazac) => {
    const newInitialState = {
      id: (this.initialState as IGetGio3Obrazac).id,
      ...GIO_3_INITIAL_STATE,
      ...payload,
      godina: obrasciStore.opste.godina,
      kolicine: this.initialState.kolicine,
      dozvole: this.initialState.dozvole,
      postrojenja: this.initialState.postrojenja,
    } as IGetGio3Obrazac;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  resetStates = () => {
    this.handleChange('initialState', GIO_3_INITIAL_STATE);
  };

  /// DOZVOLE

  promeniDozvoleNizValidacijaListu = (type: string, value: boolean) => {
    const indexOfType = this.dozvoleNizValidacija.indexOf(type);

    if (value === true && indexOfType === -1) {
      this.dozvoleNizValidacija.push(type);
    } else if (value === false && indexOfType !== -1) {
      this.dozvoleNizValidacija.splice(indexOfType, 1);
    }
  };

  submitDozvoleForm = (payload: IPostDozvoleGio3) => {
    if (!this.dozvoleNizValidacija.length) return;
    let newPayload = payload;
    newPayload = { id: (this.dozvole_form as IGetDozvoleGio3).id, ...newPayload } as IGetDozvoleGio3;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.dozvole, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('dozvole', newList);
    this.handleChange('dozvole_form', GIO_3_DOZVOLE_INITIAL_STATE);
  };

  makeNewDozvole = (items: IPostDozvoleGio3[]) => {
    const newDozvoleArray = items.map((item: IPostDozvoleGio3) => {
      return {
        ...item,
        datum_isteka: dayjs(item.datum_isteka),
        datum_izdavanja: dayjs(item.datum_izdavanja),
      };
    });
    return newDozvoleArray;
  };

  indexMap = (id: string) => {
    const obj = arrayToObject(sifarniciStore.list.WASTE_CATALOGUE);
    const findItem = obj[id] as IGetWasteCatalogue;
    return findItem;
  };

  // KOLICINE

  submitKolicineForm = (payload: IPostKolicineGio3) => {
    let newPayload = { ...payload, razredi_ee_otpada: this.kolicine_form.razredi_ee_otpada };
    let sifarnikIndexBroj = {};
    let sifarnikPostupak = {};
    let sifarnikDrugiPostupak = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikIndexBroj = nrizService.addSifarnikInObject({
        exId: this.kolicine_form.indeksni_broj_otpada_id,
        exObj: (this.kolicine_form as IGetKolicineGio3).indeksni_broj_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WASTE_CATALOGUE],
        name: 'indeksni_broj_otpada',
        id: payload.indeksni_broj_otpada_id,
      });

      sifarnikPostupak = nrizService.addSifarnikInObject({
        exId: this.kolicine_form.postupak_ponovnog_iskoriscenja_r_oznaka_id,
        exObj: (this.kolicine_form as IGetKolicineGio3).postupak_ponovnog_iskoriscenja_r_oznaka,
        list: sifarniciStore.list[SIFARNICI_ROUTES.DR_LIST],
        name: 'postupak_ponovnog_iskoriscenja_r_oznaka',
        id: payload.postupak_ponovnog_iskoriscenja_r_oznaka_id,
      });

      sifarnikDrugiPostupak = nrizService.addSifarnikInObject({
        exId: this.kolicine_form.drugi_postupak_ponovnog_iskoriscenja_r_oznaka_id,
        exObj: (this.kolicine_form as IGetKolicineGio3).drugi_postupak_ponovnog_iskoriscenja_r_oznaka,
        list: sifarniciStore.list[SIFARNICI_ROUTES.DR_LIST],
        name: 'drugi_postupak_ponovnog_iskoriscenja_r_oznaka',
        id: payload.drugi_postupak_ponovnog_iskoriscenja_r_oznaka_id,
      });
    }

    newPayload = {
      id: (this.kolicine_form as IGetKolicineGio3).id,
      ...newPayload,
      ...sifarnikIndexBroj,
      ...sifarnikPostupak,
      ...sifarnikDrugiPostupak,
      razredi_ee_otpada: this.kolicine_form.razredi_ee_otpada,
    } as IGetKolicineGio3;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.kolicine, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('kolicine', newList);
    this.handleChange('kolicine_form', KOLICINE_GIO_3_INITIAL_STATE);
  };

  submitRazredEeOtpada = (payload: IPostRazrediEeOtpadaGio3) => {
    let newPayload = payload;
    let sifarnikRazredEeOtapada = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikRazredEeOtapada = nrizService.addSifarnikInObject({
        exId: (this.razredi_ee_form as IGetRazrediEeOtpadaGio3).razred_ee_otpada_id,
        exObj: (this.razredi_ee_form as IGetRazrediEeOtpadaGio3).razred_ee_otpada,
        list: sifarniciStore.list[SIFARNICI_ROUTES.WEEE_CATEGORIES],
        name: 'razred_ee_otpada',
        id: newPayload.razred_ee_otpada_id,
      });
    }

    newPayload = {
      id: (this.razredi_ee_form as IGetRazrediEeOtpadaGio3).id,
      ...newPayload,
      ...sifarnikRazredEeOtapada,
    } as IGetRazrediEeOtpadaGio3;

    const newList = obrasciService.submitMicroServiceForm(
      this.kolicine_form.razredi_ee_otpada,
      newPayload,
      true,
      'gio3-razredi-ee-modal'
    );
    // handleSuccessNotify();
    this.handleKolicineForm('razredi_ee_otpada', newList);
    this.handleChange('razredi_ee_form', RAZREDI_EE_OTPADA_INITIAL_STATE);
  };

  // POSTROJENJA

  submitPostrojenjaForm = (payload: IPostPostrojenjaGio3) => {
    let newPayload = payload;
    let sifarnikMesto = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      if (newPayload.mesto_postrojenja_id) {
        sifarnikMesto = nrizService.addSifarnikInObject({
          exId: this.postrojenja_form.mesto_postrojenja_id,
          exObj: (this.postrojenja_form as IGetPostrojenjeGio3).mesto_postrojenja,
          list: sifarniciStore.list[SIFARNICI_ROUTES.MESTO],
          name: 'mesto_postrojenja',
          id: payload.mesto_postrojenja_id,
        });
      }
    }

    newPayload = {
      id: (this.postrojenja_form as IGetPostrojenjeGio3).id,
      ...newPayload,
      ...sifarnikMesto,
    } as IGetPostrojenjeGio3;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.postrojenja, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('postrojenja', newList);
    this.handleChange('postrojenja_form', POSTROJENJA_GIO_3_INITIAL_STATE);
  };

  //   SIFARNICI
  fetchWasteCatalougeList = async (pagination: IPagination, search: string): Promise<ResponseWasteCatalouge> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.WASTE_CATALOGUE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      level: 3,
      order_by: '+index_number',
    });
    return response;
  };

  fetchWeeeCategoriesList = async (pagination: IPagination, search: string): Promise<ResponseWeeeCategories> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.WEEE_CATEGORIES, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      waste_catalogue_id: this.index_id,
      order_by: '+the_order',
    });
    return response;
  };

  fetchDrList = async (pagination: IPagination, search: string): Promise<ResponseDrList> => {
    let response = {} as ResponseDrList;
    const index = (this.kolicine_form as IGetKolicineGio3)?.indeksni_broj_otpada
      ? (this.kolicine_form as IGetKolicineGio3)?.indeksni_broj_otpada?.index_number
      : this.indexMap(this.index_id)?.index_number;
    let RNames = 'R2,R4,R5,R6,R7,R8,R9,R10,R11';

    if (Number(obrasciStore.opste.godina) < 2019) {
      RNames += ',R12,R13';
    } else {
      RNames += ',R1,R3';
    }

    response = await sifarniciStore.fetchSifarnikOptions(
      SIFARNICI_ROUTES.DR_LIST,
      nrizService.pickFields({
        page: pagination.page,
        size: pagination.size,
        search: search,
        name__in:
          index && index.toString().startsWith('18 ') && Number(obrasciStore.opste.godina) >= 2023
            ? `${RNames},D9`
            : RNames,
        order_by: '+name',
      })
    );

    return response;
  };

  fetchMesto = async (pagination: IPagination, search: string): Promise<ResponseMesto> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.MESTO, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      location_code__neq: 999999,
      order_by: '+display_name',
    });
    return response;
  };
}

export const gio3ObrazacStore = new Gio3ObrazacStore();
