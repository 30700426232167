import { istorijaPromenaRepo } from './istorijaPromena.repo';

class IstorijaPromenaService {
  fetchIstorijaPromenaList = <IstorijaPromenaResponse = any>(filters: any) => {
    return istorijaPromenaRepo.fetchIstorijaPromenaList<IstorijaPromenaResponse>(filters);
  };

  parseNestedJSON = (input: any): any => {
    if (typeof input === 'string') {
      try {
        const parsed = JSON.parse(input);
        return this.parseNestedJSON(parsed);
      } catch (e) {
        return input;
      }
    } else if (Array.isArray(input)) {
      return input.map(item => this.parseNestedJSON(item));
    } else if (typeof input === 'object' && input !== null) {
      const result: { [key: string]: any } = {};
      for (const key in input) {
        // eslint-disable-next-line no-prototype-builtins
        if (input.hasOwnProperty(key)) {
          result[key] = this.parseNestedJSON(input[key]);
        }
      }
      return result;
    }
    return input;
  };
}

export const istorijaPromenaService = new IstorijaPromenaService();
