import { ColumnsType } from 'antd/es/table';
import { IGetLandfillType, IPostLandfillType } from './landfillType.types';
import { NAME_SR_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';

export const LANDFILL_TYPE_INITIAL_STATE: IPostLandfillType = {
  name: '',
  name_sr: '',
  is_active: true,
};
export const LANDFILL_TYPE_TABLE_DATA: ColumnsType<IGetLandfillType> = [NAME_TABLE, NAME_SR_TABLE];
export const LANDFILL_TYPE_FORM_OPTIONS = (_: FormInstance<IGetLandfillType>) => [NAME_FORM, NAME_SR_FORM];
