import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { IGetDeponijeGio2 } from 'modules/obrasci/store/gio2/gio2.types';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const Gio2TableDeponije: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetDeponijeGio2> = [
    {
      title: `${t('OBRASCI.GIO2.ADRESA_DEPONIJE')}`,
      width: 100,
      dataIndex: 'adresa_deponije',
      key: 'adresa_deponije',
    },
    {
      title: `${t('OBRASCI.GIO2.MESTO_DEPONIJE')}`,
      width: 100,
      dataIndex: ['mesto_deponije', 'display_name'],
      key: 'display_name',
    },
    {
      title: `${t('COMPANY.LATITUDE')}`,
      dataIndex: 'geografska_sirina',
      key: 'geografska_sirina',
      width: 100,
    },
    {
      title: `${t('COMPANY.LONGITUDE')}`,
      dataIndex: 'geografska_duzina',
      key: 'geografska_duzina',
      width: 100,
    },
    {
      title: `${t('OBRASCI.GIO2.VRSTA_DEPONIJE')}`,
      dataIndex: ['vrsta_deponije', 'name_sr'],
      key: 'name',
      width: 100,
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetDeponijeGio2) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-gio2-deponije'}
            onClick={() => {
              gio2ObrazacStore.handleChange('deponije_form', record);
              modalStore.changeModalName('gio2-deponije-modal');
            }}
          />
        );
      },
    },
  ];
  // todo: check if backend return whole array or we have to do it with pagination
  return (
    <TableData name="deponije-gio2" columns={baseColumns} dataSource={[...gio2ObrazacStore.initialState.deponije]} />
  );
});
