import { observer } from 'mobx-react-lite';
import { Col } from 'antd';
import styles from './Ptp2Opste.module.scss';
import { OpsteGodinaPreduzecePostrojenje } from '../../components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { useEffect } from 'react';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { useTranslation } from 'react-i18next';
import { ptp2ObrazacStore } from 'modules/obrasci/store/ptp2/ptp2.store';

export const Ptp2OpsteWizardTab: React.FC = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    if (obrasciStore.opste.godina && obrasciStore.opste.preduzece_id !== '') {
      obrasciStore.setDisabledTabButton(false);
    }
  }, [obrasciStore.opste]);

  return (
    <Col span={12} className={styles.ptp2Opste}>
      <OpsteGodinaPreduzecePostrojenje withPostrojenje={false} store={ptp2ObrazacStore} />
      <FormCheckbox name="dozvoljene_korekcije" label={t('OBRASCI.PTP2.DOZVOLJENE_KOREKCIJE')} />
    </Col>
  );
});
