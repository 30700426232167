import { IPagination, ListTableProps } from 'typescript';
import { IGetCompany } from '../company/company.types';
import { IGetFacility } from '../facility/facility.types';

export interface IUserListProps extends ListTableProps {
  isFacility?: boolean;
  filterName: string;
}

export interface ResponseUserList extends IPagination {
  items: IGetUser[];
}

export interface RequestUserFacilityList extends Partial<IPagination> {
  postrojenje_id: string;
}

export interface KeycloakUserInfo {
  groups?: [UserRoles];
  jezik?: 'engleski' | 'srpski';
}

export enum UserRoles {
  SystemAdmin = '/sistem-admin',
  CompanyOwner = '/company-owner',
  CompanyUser = '/company-user',
}

// /////////////////////////////////////

export interface IPostUser {
  email: string;
  ime: string;
  username: string;
  prezime: string;
  jezik: string;
  broj_telefon: string;
  preduzece_id?: string;
  function_of_person?: string;
  alternativni_telefon?: string;
}

export interface IGetUser extends IPostUser, IPostUserResponsibility {
  id: string;
  external_id: string;
  preduzece?: IGetCompany;
  postrojenje_id: string;
  postrojenje?: IGetFacility;
  sistem_administrator: boolean;
  preduzece_kp: PreduzeceKp[];
  is_active: boolean;
  odgovorno_lice: boolean;
  main_company_user?: boolean;
}

export interface PreduzeceKp {
  preduzece_id: string;
  korisnik_id: string;
  preduzece: IGetCompany;
}

export interface IPostUserResponsibility {
  odgovorna_osoba_preduzeca: boolean;
  odgovorna_osoba_za_izvestavanje: boolean;
  odgovorna_osoba_za_saradnju_sa_agencijom: boolean;
}
