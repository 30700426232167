import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { NewGveEmisijeMerenje } from './NewGveEmisijeMerenjaForm/NewGveEmisijeMerenjaForm';
import { GVE_EMISIJE_U_VAZDUH_INITIAL_STATE } from 'modules/obrasci/store/gve/gve.constants';

export const GveEmisijeMerenjaModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  return (
    <CRUDModal
      width="80%"
      name="gve-merenje-modal"
      title={t('OBRASCI.GVE.MERENJA_VAZDUH')}
      store={gveStore}
      data={gveStore.getEmisijeUVazduhForm}
      children={() => <NewGveEmisijeMerenje />}
      submitForm={gveStore.submitEmisijeUVazduhForm}
      initialState={GVE_EMISIJE_U_VAZDUH_INITIAL_STATE}
      dataToChange={'emisije_u_vazduh_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      closeOneModal={true}
    />
  );
});
