import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { POSTROJENJA_GIO_3_INITIAL_STATE } from 'modules/obrasci/store/gio3/gio3.constants';
import { gio3ObrazacStore } from 'modules/obrasci/store/gio3/gio3.store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewPostrojenjaGio3Form } from './components/NewPostrojenjaGio3Form/NewPostrojenjaGio3Form';

export const Gio3PostrojenjaModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="50%"
      name="gio3-postrojenja-modal"
      title={t('FACILITY.TITLE')}
      store={gio3ObrazacStore}
      data={gio3ObrazacStore.getPostrojenjaForm}
      children={() => <NewPostrojenjaGio3Form />}
      submitForm={gio3ObrazacStore.submitPostrojenjaForm}
      initialState={POSTROJENJA_GIO_3_INITIAL_STATE}
      dataToChange={'postrojenja_form'}
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
    />
  );
});
