import generatePicker, { PickerDateProps, RangePickerDateProps } from 'antd/es/date-picker/generatePicker';
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

const DayjsDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export const DatePicker: React.FC<IDatePickerProps> = props => {
  return <DayjsDatePicker {...props} data-test={`date-picker-${props.name}`} />;
};

export const RangePicker: React.FC<RangePickerDateProps<Dayjs>> = props => {
  return <DayjsDatePicker.RangePicker {...props} data-test={`range-picker-${props.name}`} />;
};

export interface IDatePickerProps extends PickerDateProps<Dayjs> {
  name: string;
}

export interface IRangePickerProps extends RangePickerDateProps<Dayjs> {
  name: string;
}
