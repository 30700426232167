import { omit } from 'lodash';

import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { IPostActivity2, IGetActivity2, IResponseActivity2List, RequestActivityList2 } from './activityList2.types';

const fetchActivityList2 = (payload: RequestActivityList2): ApiResponse<IResponseActivity2List> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.ACTIVITY_LIST}2/?${query}`);
};

const postActivity2 = (payload: IPostActivity2): ApiResponse<{ id: string }> => {
  return axios.post(`${ROUTES.ACTIVITY_LIST}2/`, payload);
};

const deleteActivity2 = (id: string) => {
  return axios.delete(`${ROUTES.ACTIVITY_LIST}2/${id}`);
};

const putActivity2 = (activity: IGetActivity2) => {
  const putActivityObj = omit(activity, ['id']);
  return axios.put(`${ROUTES.ACTIVITY_LIST}2/${activity.id}`, putActivityObj);
};

export const activity2Repo = {
  deleteActivity2,
  putActivity2,
  postActivity2,
  fetchActivityList2,
};
