import { Modal } from 'components/Modal/Modal';
import { observer } from 'mobx-react-lite';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { deo6ObrazacStore } from 'modules/obrasci/store/deo6/deo6.store';
import { Deo6KalkulacijaInfo } from './components/Deo6KalkulacijaInfo';
import { Deo6KalkulacijaResponse } from 'modules/obrasci/store/deo6/deo6.types';
import styles from './Deo6KalkulacijaModal.module.scss';
import { modalStore } from 'store';

export const Deo6KalkulacijaModal = observer(() => {
  if (!deo6ObrazacStore.kalkulacijaNotificationModalData) return null;

  const { obrazacData, kalkulacijaArrays } = deo6ObrazacStore.kalkulacijaNotificationModalData;

  const isOkHidden = !!kalkulacijaArrays.indeksni_brojevi.length && !!kalkulacijaArrays.razredi_ee_otpada.length;

  const onOkClick = () => {
    obrasciService.submitFormObrazac({
      payload: obrazacData,
      postForm: deo6ObrazacStore.postData,
      putForm: deo6ObrazacStore.putData,
      deleteForm: deo6ObrazacStore.deleteData,
    });
  };

  const onCancel = () => {
    deo6ObrazacStore.setKalkulacijaNotificationModalData(null);
    modalStore.removeAllModals();
  };

  //todo: prevod

  const infoMessage =
    'Predata je veća količina od stanja u skladištu. Ukoliko želite da sačuvate obrazac kliknite da. Ukoliko želite da ispravite količine, kliknite ne.';

  const errorMessage =
    'Da bi se sačuvao obrazac ne smeju istovremeno postojati i Razred Električnog otpada i Otpad čije su količine na skladištu u minusu!';

  const formatDataArrays = (kalkulacijaArrays: Deo6KalkulacijaResponse) => {
    const otpadInfo: { label: string; kolicina: number }[] = [];
    const razredInfo: { label: string; kolicina: number }[] = [];

    if (kalkulacijaArrays.indeksni_brojevi.length)
      kalkulacijaArrays.indeksni_brojevi.forEach(item => {
        const invalidObjectInfo = { label: item.indeksni_broj_otpada.index_number, kolicina: item.kolicina };
        otpadInfo.push(invalidObjectInfo);
      });
    if (kalkulacijaArrays.razredi_ee_otpada.length)
      kalkulacijaArrays.razredi_ee_otpada.forEach(item => {
        const invalidObjectInfo = { label: item.razred_ee_otpada.category_name_sr, kolicina: item.kolicina };
        razredInfo.push(invalidObjectInfo);
      });

    return {
      razredInfo,
      otpadInfo,
    };
  };

  const { otpadInfo, razredInfo } = formatDataArrays(kalkulacijaArrays);

  return (
    <Modal
      width="50%"
      name="deo6-kalkulacija-modal"
      destroyOnClose={true}
      open={modalStore.getCurrentOpenedModal[0] === 'deo6-kalkulacija-modal'}
      okText={'Sačuvaj obrazac'}
      okButtonProps={{ onClick: onOkClick, hidden: isOkHidden }}
      cancelText={'Nazad'}
      cancelButtonProps={{ onClick: onCancel }}
      closable={false}
    >
      <div className={styles.wrapper}>
        <h3>{isOkHidden ? errorMessage : infoMessage}</h3>
        {!!kalkulacijaArrays.indeksni_brojevi.length && (
          <Deo6KalkulacijaInfo dataArray={otpadInfo} kalkulacijaConfigAccessor="otpad" />
        )}
        {!!kalkulacijaArrays.razredi_ee_otpada.length && (
          <Deo6KalkulacijaInfo dataArray={razredInfo} kalkulacijaConfigAccessor="razred" />
        )}
      </div>
    </Modal>
  );
});
