import { Form } from 'antd';
import { InputNumber } from 'components/InputNumber/InputNumber';
import { observer } from 'mobx-react-lite';
import { invalidCharactersForInputNumber } from 'modules/nriz/nriz.constants';
import React, { KeyboardEvent } from 'react';
import { FormNumberInputProps } from 'typescript/NrizTypes';

export const FormInputNumber: React.FC<FormNumberInputProps> = observer(
  ({
    name,
    placeholder,
    rules,
    iconComponent,
    label,
    disabled,
    colon,
    inputName,
    step,
    className,
    onInputChange,
    defaultValue,
    readOnly,
    value,
    labelCol,
    wrapperCol,
    labelAlign,
    precision,
    invalidCharacters,
    min,
    validateStatus,
    help,
    hidden,
  }) => {
    return (
      <Form.Item
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        name={name}
        rules={rules}
        label={label}
        colon={colon}
        labelAlign={labelAlign}
        validateStatus={validateStatus}
        help={help}
        hidden={hidden}
      >
        <InputNumber
          type="number"
          lang="en_EN"
          step={step}
          min={min}
          prefix={iconComponent}
          placeholder={placeholder}
          disabled={disabled}
          name={inputName as string}
          value={value}
          className={className}
          controls={false}
          onChange={(e: any) => onInputChange && onInputChange(e)}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            invalidCharacters
              ? [...invalidCharacters, ...invalidCharactersForInputNumber].includes(e.key) && e.preventDefault()
              : invalidCharactersForInputNumber.includes(e.key) && e.preventDefault();
          }}
          defaultValue={defaultValue}
          readOnly={readOnly}
          precision={precision}
        />
      </Form.Item>
    );
  }
);
