import { ApiResponse, axios } from 'modules/axios';
import { IGetActivity1, IPostActivity1, IResponseTableActivity1, RequestActivityList } from './activityList1.types';
import { ROUTES } from 'modules/nriz/nriz.constants';
import { omit } from 'lodash';
import queryString from 'query-string';

const fetchActivityList1 = (payload: RequestActivityList): ApiResponse<IResponseTableActivity1> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.ACTIVITY_LIST}1/?${query}`);
};

const postActivity1 = (payload: IPostActivity1): ApiResponse<{ id: string }> => {
  return axios.post(`${ROUTES.ACTIVITY_LIST}1/`, payload);
};

const deleteActivity1 = (id: string) => {
  return axios.delete(`${ROUTES.ACTIVITY_LIST}1/${id}`);
};

const putActivity1 = (activity: IGetActivity1) => {
  const putActivityObj = omit(activity, ['id']);
  return axios.put(`${ROUTES.ACTIVITY_LIST}1/${activity.id}`, putActivityObj);
};

export const activity1Repo = {
  deleteActivity1,
  putActivity1,
  postActivity1,
  fetchActivityList1,
};
