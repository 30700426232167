import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { NewObrazac3AnalizaOtpadneVodeForm } from './NewObrazac3AnalizaOtpadneVodeForm/NewObrazac3AnalizaOtpadneVodeForm';
import { OBRAZAC_3_OTPADNE_VODE_INITIAL_STATE } from 'modules/obrasci/store/obrazac3/obrazac3.constants';
import { IGetObrazac3AnalizaOtpadneVode } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import { FormInstance } from 'antd';

export const Obrazac3AnalizaOtpadneVodeModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="70%"
      name="obrazac3-analiza-otpadne-vode-modal"
      title={t('WIZARD_TABS.ANALIZA_OTPADNE_VODE')}
      store={obrazac3Store}
      data={obrazac3Store.getAnalizaOtpadneVode}
      children={({ form }: { form: FormInstance<IGetObrazac3AnalizaOtpadneVode> }) => (
        <NewObrazac3AnalizaOtpadneVodeForm form={form} />
      )}
      submitForm={obrazac3Store.submitAnalizaOtpadneVode}
      initialState={OBRAZAC_3_OTPADNE_VODE_INITIAL_STATE}
      dataToChange={'otpadne_vode_form'}
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
      onCancel={() => obrazac3Store.handleChange('nacin_odredjivanja_id', '')}
    />
  );
});
