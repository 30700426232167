import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { ptp2ObrazacStore } from 'modules/obrasci/store/ptp2/ptp2.store';
import { IGetProizvod, IProizvod } from 'modules/obrasci/store/ptp2/ptp2.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const TableProizvodi: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetProizvod> = [
    {
      title: `${t('OBRASCI.PTP2.VRSTE_PROIZVODA_PO_PERIODIMA')}`,
      width: '150px',
      dataIndex: ['products_nr', 'display_product_category'],
      key: 'display_product_category',
    },
    {
      title: `${t('OBRASCI.PTP2.NAZIV_PROIZVODA')}`,
      width: '150px',
      dataIndex: ['products_type', 'display_product' || 'name' || 'description'],
      render: (_: any, record: IGetProizvod) =>
        `${
          record?.products_type?.display_product ? record?.products_type?.display_product : record?.products_type?.name
        } ${
          record?.products_type?.description !== 'NULL' && record?.products_type?.description
            ? record?.products_type?.description
            : ''
        }`,
      key: 'display_product',
    },
    {
      title: `${t('OBRASCI.PTP2.KOLICINA_PROIZVODA_STAVLJENIH_NA_TRZISTE')}`,
      dataIndex: 'kolicina_proizvoda_stavljenih_na_trziste',
      key: 'kolicina_proizvoda_stavljenih_na_trziste',
      width: '250px',
      render: (_: any, record: IGetProizvod) => record.kolicina_proizvoda_stavljenih_na_trziste?.toFixed(2),
    },
    {
      title: `${t('OBRASCI.PTP2.KOLICINA_PROIZVODA_KG')}`,
      dataIndex: 'kolicina_proizvoda',
      key: 'kolicina_proizvoda',
      width: '150px',
    },
    {
      title: `${t('OBRASCI.PDV')}`,
      dataIndex: 'pdv',
      key: 'pdv',
      width: '100px',
    },
    {
      // ${t('COMPANY.ADDITIONALLY')}
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (_, record: IProizvod) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-activity'}
            onClick={() => {
              ptp2ObrazacStore.handleChange('proizvodi', record);
              modalStore.changeModalName('proizvodi-modal');
            }}
          />
        );
      },
    },
  ];
  // todo: check if backend return whole array or we have to do it with pagination
  return (
    <TableData
      scrollConfig={['#ptp2ProizvodiTabelHeader', '#obrazacWizardHeader']}
      name="proizvodi-ptp2"
      columns={baseColumns}
      dataSource={[...ptp2ObrazacStore.getInitialState.proizvodi]}
    />
  );
});
