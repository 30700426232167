import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrazac5Store } from 'modules/obrasci/store/obrazac5/obrazac5.store';
import { IGetKomponenteObrazac5 } from 'modules/obrasci/store/obrazac5/obrazac5.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const TableKomponenteObrazac5: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetKomponenteObrazac5> = [
    /// responsekomponenteform
    {
      title: `${t('OBRASCI.OBRAZAC5.CAS_BROJ')}`,
      width: 200,
      dataIndex: 'cas_broj',
      key: 'cas_broj',
    },
    {
      title: `${t('OBRASCI.OBRAZAC5.HEMIJSKI_NAZIV')}`,
      width: 200,
      dataIndex: 'hemijski_naziv',
      key: 'hemijski_naziv',
    },
    {
      title: `${t('OBRASCI.OBRAZAC5.SADRZAJ_OPASNE_MATERIJE')}`,
      dataIndex: 'sadrzaj_opasne_materije',
      key: 'sadrzaj_opasne_materije',
      width: 200,
    },

    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetKomponenteObrazac5) => {
        //responsekomponente
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-komponente'}
            onClick={() => {
              obrazac5Store.handleChange('komponente_form', record);
              modalStore.changeModalName('obrazac5-komponente-modal');
            }}
          />
        );
      },
    },
  ];
  // todo: check if backend return whole array or we have to do it with pagination
  return (
    <TableData
      name="komponente-obrazac5"
      columns={baseColumns}
      dataSource={[...obrazac5Store.initialState.komponente]}
    />
  );
  // resi store
});
