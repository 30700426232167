import dayjs from 'dayjs';
import { IPostProductsNr, IGetProductsNr } from './productsNr.types';
import { t } from 'i18next';
import { FormInstance } from 'antd';
import { MAX_LENGTH_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { DateSifarniciFormProps, InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { productNrService } from './productsNr.service';
// import { useLocation } from 'react-router-dom';

export const PRODUCTS_NR_INITIAL_STATE: IPostProductsNr = {
  period_from_date: '',
  period_to_date: '',
  products_category: '',
  display_product_category: '',
  is_active: true,
};

export const PRODUCTS_NR_TABLE_DATA = [
  {
    title: `${t('SIFARNICI.OD_DATUMA')}`,
    width: '100px',
    dataIndex: 'period_from_date',
    key: 'period_from_date',
    render: (_: any, record: IGetProductsNr) => (
      <span>{record.period_from_date ? dayjs(record.period_from_date).format('DD. MM. YYYY.') : ''}</span>
    ),
  },
  {
    title: `${t('SIFARNICI.DO_DATUMA')}`,
    width: '100px',
    dataIndex: 'period_to_date',
    key: 'period_to_date',
    render: (_: any, record: IGetProductsNr) => (
      <span>{record.period_to_date ? dayjs(record.period_to_date).format('DD. MM. YYYY.') : ''}</span>
    ),
  },
  {
    title: `${t('SIFARNICI.VRSTA_PROIZVODA')}`,
    width: '200px',
    dataIndex: 'products_category',
    key: 'products_category',
  },
  {
    title: `${t('SIFARNICI.VRSTA_PROIZVODA')}`,
    width: '300px',
    dataIndex: 'display_product_category',
    key: 'display_product_category',
  },
];

export const PRODUCTS_NR_FORM_OPTIONS = (form: FormInstance<IGetProductsNr>) => {
  const queryParams = new URLSearchParams(location.search);
  const sifarnik_type = queryParams.get('sifarnik_type');

  return [
    {
      type_of_form: 'date',
      name: 'period_from_date',
      label: t('SIFARNICI.OD_DATUMA'),
      format: 'DD-MM-YYYY',
      picker: 'date',
      onChange: () => {
        form.setFieldValue('period_to_date', '');
        form.setFieldValue('period_to_date__gte', '');
        productNrService.makeVrsteProizvodaInput(form);
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'period_to_date',
      label: t('SIFARNICI.DO_DATUMA'),
      format: 'DD-MM-YYYY',
      picker: 'date',
      onChange: () => {
        productNrService.makeVrsteProizvodaInput(form);
      },
      disabled: !!(sifarnik_type && !form.getFieldValue('period_from_date')),
      disabledDate: (current: any) => {
        const formItem = form.getFieldValue('period_from_date__gte')
          ? form.getFieldValue('period_from_date__gte')
          : form.getFieldValue('period_from_date');
        return current && current.endOf('day') < formItem;
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('SIFARNICI.VRSTA_PROIZVODA'),
      name: 'products_category',
      inputName: 'products_category',
      rules: [REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(100)],
      onInputChange: () => {
        productNrService.makeVrsteProizvodaInput(form);
      },
    } as InputSifarniciFormProps,

    {
      type_of_form: 'input',
      name: 'display_product_category',
      hideInput: true,
      label: t('SIFARNICI.VRSTA_PROIZVODA'),
      inputName: 'display_product_category',
      disabled: true,
    } as InputSifarniciFormProps,
  ];
};
