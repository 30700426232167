import {
  Aao1IzvestajiEnum,
  Aao2IzvestajiEnum,
  Deo6IzvestajiEnum,
  Dep1IzvestajiEnum,
  Dep2IzvestajiEnum,
  Gio2IzvestajiEnum,
  Gio3IzvestajiEnum,
  Gio4IzvestajiEnum,
  Gio5IzvestajiEnum,
  IzvestajiSubtypes,
  Kom1IzvestajiEnum,
  Obrazac1Gorivo,
  Obrazac1IzvestajiEnum,
  Obrazac2IzvestajiEnum,
  Obrazac3IzvestajiEnum,
  Obrazac5IzvestajiEnum,
  Obrazac5Postrojenja,
  Obrazac5PostrojenjaIndeksniBroj,
  Obrazac5GrupaPodgrupaIndeksniBroj,
  Obrazac5NeOpasan,
  Ptp2IzvestajiEnum,
  PzvIzvestajiEnum,
  Obrazac5PostrojenjaZaPlacanje,
  Obrazac5PregledPoGodinama,
  Gio5PostrojenjaIndeksniBroj,
  Gio5GrupaPodgrupaIndeksniBroj,
  Gio5PregledPoGodinama,
  Gio5KolicinaUvezenogOtpadaPoVrstiOtpada,
  Aao1PregledPoGodinama,
  Aao1Prikaz1,
  Aao1SviPodaci,
  Aao1NedostavljeniObrasci,
} from './obrazacIzvestaji.types';

// Todo: add types from obrazac 1
export const OBRAZAC_1_GORIVA_MOCK: Obrazac1Gorivo[] = [
  {
    godina: 2017,
    pib: '100002323',
    preduzece: 'Test preduzece za sistemske izvestaje',
    nacionalni_id: 10000232,
    postrojenje: 'Ritopek agrikultura',
    opstina_postrojenja: 'Grocka',
    naziv_goriva: 'Benzin',
    potrosnja_na_dan: 102,
    jedinica_mere: 'Litar (l)',
    kreirano: '13.02.2022.',
    azurirano: '07.07.2024',
    napomena:
      'Zavrsena analiza infrastrukture i implementacije idejnog resenja softverskog modela najmodernije tehnologije 21 veka koja prevazilazi sve granice',
  },
];

export const OBRAZAC_1_PROIZVODI_MOCK = [
  { id: '1' },
  { godina: '2017' },
  { pib: '100002323' },
  { preduzece: 'Test preduzece za sistemske izvestaje' },
  { nacionalniId: '100002323' },
  { postrojenje: 'Ritopek agrikultura' },
  { opstinaPostrojenja: 'Grocka' },
  { nazivGoriva: 'Benzin' },
  { potrosnjaNaDan: '102' },
  { jedMere: 'Litar (l)' },
  { kreirano: '13.02.2022.' },
  { azurirano: '07.07.2024' },
  {
    napomena:
      'Zavrsena analiza infrastrukture i implementacije idejnog resenja softverskog modela najmodernije tehnologije 21 veka koja prevazilazi sve granice',
  },
];

//#region Obrazac 3 / OTV
export const OBRAZAC_3_ZAGADLJIVE_MATERIJE_MOCK = [
  {
    preduzece: 'Test preduzece za sistemske izvestaje',
    pib: '10002323',
    nacionalni_id: '100002323',
    postrojenje: 'Ritopek agrikultura',
    opstina_postrojenja: 'opstinaa',
    godina: 2018,
    prtr: 'Da',
    aktivnosti: '6.(b)',
    naziv_ispusta: 'ispust tehnoloske vode',
    broj_ispusta: '1',
    vrsta_recipijenta: 'kanal',
    naziv_recipijenta: 'kalovita',
    sliv: 'Dunav',
    vremenski_period_ispustanja_vode: '365',
    ukupna_kolicina_ispustene_vode: '352800',
    srednji_dnevni_protok_otpadne_vode: '3452,05',
    zagadjujuca_materija: 'Ukupni fosfor',
    srednja_godisnja_izmerena_vrednost: '0,37',
    kolicina_pri_redovnom_radu: '130,5',
    kolicina_u_akcidentnim_situacijama: null,
    kreirano: '29.3.2018. 22:43:02',
    azurirano: '29.3.2018. 23:07:16',
    napomena: 'NAPOMENAA',
  },
];

export const OBRAZAC_3_PREGLED_PO_GODINAMA_MOCK = [
  {
    preduzece: 'Test preduzece za sistemske izvestaje',
    pib: '10002323',
    nacionalni_id: '100002323',
    postrojenje: 'Ritopek agrikultura',
    opstina_postrojenja: 'opstinaa',
    zagadjujuca_materija: 'Ukupni fosfor',
    kolicine: [
      {
        godina: 2017,
        kolicina: 995.4,
      },
      {
        godina: 2018,
        kolicina: 195.4,
      },
      {
        godina: 2019,
        kolicina: 335.4,
      },
      {
        godina: 2020,
        kolicina: 44.4,
      },
      {
        godina: 2021,
        kolicina: 85.4,
      },
    ],
  },
];

export const OBRAZAC_3_ISPUST_PO_GODINAMA_MOCK = [
  {
    preduzece: 'Test preduzece za sistemske izvestaje',
    pib: '10002323',
    nacionalni_id: '100002323',
    postrojenje: 'Ritopek agrikultura',
    opstina_postrojenja: 'opstinaa',
    oznaka_ispusta: 1,
    naziv_ispusta: 'naziv isputaaa',
    zagadjujuca_materija: 'Halogenovana organska jedinjenja (k...)',
    kolicine: [
      {
        godina: 2017,
        kolicina: 115.4,
      },
      {
        godina: 2018,
        kolicina: 2000.4,
      },
      {
        godina: 2019,
        kolicina: 335.4,
      },
      {
        godina: 2020,
        kolicina: 44.4,
      },
      {
        godina: 2021,
        kolicina: 85.4,
      },
    ],
  },
];

export const OBRAZAC_3_OPSTE_MOCK = [
  {
    preduzece: 'Test preduzece za sistemske izvestaje',
    pib: '10002323',
    nacionalni_id: '100002323',
    postrojenje: 'Ritopek agrikultura',
    opstina_postrojenja: 'opstinaa',
    godina: 2018,
    prtr: 'Da',
    aktivnosti: '6.(b)',
    oznaka_ispusta: 1,
    naziv_ispusta: 'naziv isputaaa',
    vrsta_recipijenta: 'kanal',
    naziv_recipijenta: 'kalovita',
    geografska_sirina: '11',
    geografska_duzina: '22',
    rezim_rada: 'Diskontinuala',
    rashladne: true,
    sanitarne: true,
    atmosferske: true,
    tehnoloske: false,
    period_ispustanja: '365',
    ukupna_kolicina_ispustene_vode: '352800',
    kreirano: '29.3.2018. 22:43:02',
    azurirano: '29.3.2018. 23:07:16',
  },
];
//#endregion

//#region GIO 4

export const GIO_4_POSTROJENJA_INDEKSNI_BROJ_MOCK = [
  {
    preduzece: 'Test preduzece za sistemske izvestaje',
    pib: '10002323',
    nacionalni_id: '100002323',
    postrojenje: 'Ritopek agrikultura',
    opstina_postrojenja: 'opstinaa',
    godina: '2222',
    indeksni_broj: '01 01 01',
    ukupno_izvezeno: '5',
    opasan: true,
    naziv_otpada: 'Naziv otpada',
    broj_dozvole: 'x-y-z-1',
    zemlja_izvoza: 'Austrija',
    zemlja_tranzita: 'Croatia; Slovenia',
    naziv_adresa_postrojenja_u_inostranstvu: '1321564564321 321 321 321 321',
    y1: 'Y6',
    y2: 'Y4',
    y3: 'Y1',
    c: 'C6',
    h1: 'H5',
    h2: 'H4',
    h3: 'H5',
    ia: 'A1070',
    iib: 'adf',
    r: 'asdff',
    d: 'D7',
  },
];

export const GIO_4_GRUPA_PODGRUPA_INDEKSNI_BROJ_MOCK = [
  {
    indeksni_broj: '01',
    opis: 'OTPADI KOJI NASTAJU U ISTAZIVANJIMA...',
    kolicina: 2,
    children: [
      {
        indeksni_broj: '01 01',
        opis: 'otpadi od iskopavanja minerala...',
        kolicina: 1,
        children: [
          {
            indeksni_broj: '01 01 01',
            opis: 'otpadi od iskopavanja minerala za crnu metalurgiju...',
            kolicina: 1,
          },
        ],
      },
      {
        indeksni_broj: '01 02',
        opis: 'otpadi os iskopavanja minerala...',
        kolicina: 1,
        children: [
          {
            indeksni_broj: '01 02 01',
            opis: 'otpadi od iskopavanja minerala za crnu metalurgiju...',
            kolicina: 1,
          },
        ],
      },
    ],
  },
  {
    indeksni_broj: '20',
    opis: 'KOMUNALNI OTPADI...',
    kolicina: 5,
    children: [
      {
        indeksni_broj: '20 01',
        opis: 'odvojeno sakupljene frakcije...',
        kolicina: 5,
        children: [
          {
            indeksni_broj: '20 01 21',
            opis: 'fluorescentne cevi i drugi otpad koji sadrzi zivu...',
            kolicina: 5,
          },
        ],
      },
    ],
  },
];

export const GIO_4_PREGLED_PO_GODINAMA_MOCK = [
  {
    preduzece: 'Test preduzece za sistemske izvestaje',
    pib: '10002323',
    nacionalni_id: '100002323',
    postrojenje: 'Ritopek agrikultura',
    opstina_postrojenja: 'opstinaa',
    indeksni_broj: '20 01 01',
    opis_otpada: 'papir i karton',
    kolicine: [
      {
        godina: 2017,
        kolicina: 115.4,
      },
      {
        godina: 2018,
        kolicina: 2000.4,
      },
      {
        godina: 2019,
        kolicina: 335.4,
      },
      {
        godina: 2020,
        kolicina: 44.4,
      },
      {
        godina: 2021,
        kolicina: 85.4,
      },
    ],
  },
];

export const GIO_4_KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI_OTPADA_MOCK = [
  {
    indeksni_broj: '01 01 01',
    otpad: 'Otpadi od iskopavanja minerala za crnu metalurgiju',
    kolicina_izvezenog_otpada: 2,
    zemlja_izvoza: 'Croatia',
  },
];
export const GIO_4_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_MOCK = [
  {
    preduzece: 'Test preduzece za sistemske izvestaje',
    pib: '10002323',
    nacionalni_id: '100002323',
    postrojenje: 'Ritopek agrikultura',
    opstina_postrojenja: 'opstinaa',
    godina: '2222',
    indeksni_broj: '20 01 21*',
    kolicina_izvezenog_otpada: '5',
    broj_dozvole: 'x-y-z-1',
    zemlja_izvoza: 'Austrija',
    zemlja_tranzita: 'Croatia; Slovenia',
    naziv_adresa_postrojenja_u_inostranstvu: '1321564564321 321 321 321 321',
    y1: 'Y6',
    y2: 'Y4',
    y3: 'Y1',
    c: 'C6',
    h1: 'H5',
    h2: 'H4',
    h3: 'H5',
    ia: 'A1070',
    iib: null,
    r: null,
    d: 'D7',
    razred: '05a',
    vrsta_ee_otpada: 'Fluorescentne cevi',
    kolicina_ee: '5',
  },
];
//#endregion

//#region PTP2
export const PTP_2_OBRACUN_ZA_NAPLATU_MOCK = [
  {
    vrsta_proizvoda: 'Akumulatori',
    kolicine: [
      {
        godina: 2017,
        kolicina: 115.4,
      },
      {
        godina: 2018,
        kolicina: 2000.4,
      },
      {
        godina: 2019,
        kolicina: 335.4,
      },
      {
        godina: 2020,
        kolicina: 44.4,
      },
      {
        godina: 2021,
        kolicina: 85.4,
      },
    ],
  },
  {
    vrsta_proizvoda: 'Gume',
    kolicine: [
      {
        godina: 2017,
        kolicina: 115.4,
      },
      {
        godina: 2018,
        kolicina: 2000.4,
      },
      {
        godina: 2019,
        kolicina: 335.4,
      },
      {
        godina: 2020,
        kolicina: 44.4,
      },
      {
        godina: 2021,
        kolicina: 1154.4,
      },
    ],
  },
  {
    vrsta_proizvoda: 'Kese',
    kolicine: [
      {
        godina: 2017,
        kolicina: 115.4,
      },
      {
        godina: 2018,
        kolicina: 2000.4,
      },
      {
        godina: 2019,
        kolicina: 335.4,
      },
      {
        godina: 2020,
        kolicina: 44.4,
      },
      {
        godina: 2021,
        kolicina: 85.4,
      },
    ],
  },
];
export const PTP_2_VOZILA_UPOREDJIVANJE_MOCK = [
  {
    preduzece: 'Test preduzece za sistemske izvestaje',
    pib: '10002323',
    kolicine: [
      {
        godina: 2011,
        prijavljena_kolicina: 1.79,
        kolicina_na_carini: 1.79,
        razlika: 0,
      },
      {
        godina: 2014,
        prijavljena_kolicina: null,
        kolicina_na_carini: null,
        razlika: null,
      },
    ],
  },
];
export const PTP_2_NEDOSTAVLJENI_PODACI_O_VOZILIMA_MOCK = [
  {
    preduzece: 'Test preduzece za sistemske izvestaje',
    pib: '10002323',
    kolicine: [
      {
        godina: 2017,
        kolicina: 115.4,
      },
      {
        godina: 2018,
        kolicina: 2000.4,
      },
      {
        godina: 2019,
        kolicina: 335.4,
      },
      {
        godina: 2020,
        kolicina: 44.4,
      },
      {
        godina: 2021,
        kolicina: 4451.4,
      },
    ],
  },
];
export const PTP_2_UVOZ_GUMA_UPOREDJIVANJE_MOCK = [
  {
    preduzece: 'Test preduzece za sistemske izvestaje',
    pib: '10002323',
    kolicine: [
      {
        godina: 2013,
        prijavljena_kolicina: 1.79,
        kolicina_na_carini: 1.79,
        razlika: 0,
      },
      {
        godina: 2019,
        prijavljena_kolicina: 0,
        kolicina_na_carini: 0,
        razlika: 0,
      },
    ],
  },
];
export const PTP_2_NEDOSTAVLJENI_PODACI_O_GUMAMA_MOCK = [
  {
    preduzece: 'Test preduzece za sistemske izvestaje',
    pib: '10002323',
    kolicine: [
      {
        godina: 2017,
        kolicina: 210.4,
      },
      {
        godina: 2018,
        kolicina: 985.4,
      },
      {
        godina: 2019,
        kolicina: 1154.4,
      },
      {
        godina: 2020,
        kolicina: 2014.4,
      },
      {
        godina: 2021,
        kolicina: 85.4,
      },
    ],
  },
];

//#endregion

//#region PZV
export const PZV_KOLICINE_PROIZVODA_PO_PREDUZECU_MOCK = [
  {
    preduzece: 'Test preduzece za sistemske izvestaje',
    pib: '10002323',
    godina: '2020',
    grupa_proizvoda: 'deterdzenti',
    proizvod: 'Deterdzent za industrijske i profesionalne svrhe',
    opis_proizvoda: 'Neki deterdzent',
    kolicina_proizvoda: '250',
    sadrzaj: '10',
    predefinisi_sadrzaj: '15',
    ukupna_kolicina: '25',
  },
];
export const PZV_KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU_MOCK = [
  {
    godina: '2020',
    pib: '10002323',
    maticni_broj: 'maticni broj 111',
    preduzece: 'Test preduzece za sistemske izvestaje',
    mesto: 'Mestoo',
    opstina: 'Opstinaa',
    ulica: 'Ulicaa',
    broj: 'Broj',
    grupa_proizvoda: 'deterdzenti',
    kolicina_grupe_proizvoda: '250',
    ukupna_kolicina: '25',
    kolicina_grupe_proizvoda_na_carini: '250',
  },
];
//#endregion

export const OBRAZAC_5_POSTROJENJA_MOCK: Obrazac5Postrojenja[] = [
  {
    godina: 2017,
    pib: '100002323',
    preduzece: 'Društvo za istraživanje, proizvodnju, preradu, distribuciju i promet nafte i naftnih derivata',
    nacionalni_id: '100002323',
    postrojenje: 'Matica - Poslovni centri u Novom Sadu i Beogradu',
    opstina_postrojenja: 'Grocka',
    aktivnosti: '6. (b)',
    preneto_iz_prethodnog_perioda: 3.25,
    godisnja_kolicina_proizvedenog_otpada: 2477.8,
    kolicina_otpada_predata_na_skladistenje: 1934.3,
    kolicina_otpada_predata_na_odlaganje: 242.3,
    kolicina_otpada_predata_na_tretman: 301.2,
    kolicina_izvezenog_otpada: null,
    ostalo_na_lokaciji: 3.85,
  },
];

export const OBRAZAC_5_POSTROJENJA_INDEKSNI_BROJ_MOCK: Obrazac5PostrojenjaIndeksniBroj[] = [
  {
    godina: 2017,
    pib: '100002323',
    preduzece: 'Društvo za istraživanje, proizvodnju, preradu, distribuciju i promet nafte i naftnih derivata',
    sifra_pretezne_delatnosti: '246814628',
    nacionalni_id: '100002323',
    postrojenje: 'Matica - Poslovni centri u Novom Sadu i Beogradu',
    opstina_postrojenja: 'Grocka',
    aktivnosti: '6. (b)',
    indeksni_broj: '04 02 09',
    opasan: true,
    naziv_otpada: 'otpadi od meševitih materijala',
    vrsta_otpada: 'industrijski',
    godisnja_kolicina_proizvedenog_otpada: 2477.8,
    kolicina_otpada_predata_na_skladistenje: 1934.3,
    kolicina_otpada_predata_na_odlaganje: 242.3,
    kolicina_otpada_predata_na_tretman: 301.2,
    kolicina_izvezenog_otpada: null,
    y1: 'Y5',
    y2: 'Y40',
    y3: '',
  },
];

export const OBRAZAC_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_MOCK: Obrazac5GrupaPodgrupaIndeksniBroj[] = [
  {
    indeksni_broj: '01',
    opis: 'OTPADI KOJI NASTAJU U ISTAZIVANJIMA...',
    kolicina: 2,
    children: [
      {
        indeksni_broj: '01 01',
        opis: 'otpadi od iskopavanja minerala...',
        kolicina: 1,
        children: [
          {
            indeksni_broj: '01 01 01',
            opis: 'otpadi od iskopavanja minerala za crnu metalurgiju...',
            kolicina: 1,
          },
        ],
      },
      {
        indeksni_broj: '01 02',
        opis: 'otpadi os iskopavanja minerala...',
        kolicina: 1,
        children: [
          {
            indeksni_broj: '01 02 01',
            opis: 'otpadi od iskopavanja minerala za crnu metalurgiju...',
            kolicina: 1,
          },
        ],
      },
    ],
  },
  {
    indeksni_broj: '20',
    opis: 'KOMUNALNI OTPADI...',
    kolicina: 5,
    children: [
      {
        indeksni_broj: '20 01',
        opis: 'odvojeno sakupljene frakcije...',
        kolicina: 5,
        children: [
          {
            indeksni_broj: '20 01 21',
            opis: 'fluorescentne cevi i drugi otpad koji sadrzi zivu...',
            kolicina: 5,
          },
        ],
      },
    ],
  },
];

export const OBRAZAC_5_OTPAD_NE_OPASAN_MOCK: Obrazac5NeOpasan[] = [
  {
    grupa: '03',
    kolicina_neopasnog_otpada: 5450.01,
    kolicina_opasnog_otpada: null,
  },
];

export const OBRAZAC_5_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA_MOCK: Obrazac5PregledPoGodinama[] = [
  {
    pib: '100002323',
    preduzece: 'Društvo za istraživanje, proizvodnju, preradu, distribuciju i promet nafte i naftnih derivata',
    nacionalni_id: 100002323,
    postrojenje: 'Matica - Poslovni centri u Novom Sadu i Beogradu',
    indeksni_broj: '04 02 09',
    opis_otpada: 'otpadi od mešovitih materijala',
    kolicine: [
      {
        godina: 2017,
        kolicina: 115.4,
      },
      {
        godina: 2018,
        kolicina: 2000.4,
      },
      {
        godina: 2019,
        kolicina: 335.4,
      },
      {
        godina: 2020,
        kolicina: 44.4,
      },
      {
        godina: 2021,
        kolicina: 85.4,
      },
    ],
  },
];

export const OBRAZAC_5_POSTROJENJA_ZA_PLACANJE_MOCK: Obrazac5PostrojenjaZaPlacanje[] = [
  {
    godina: 2017,
    pib: '100002323',
    preduzece: 'Društvo za istraživanje, proizvodnju, preradu, distribuciju i promet nafte i naftnih derivata',
    nacionalni_id: 100002323,
    postrojenje: 'Matica - Poslovni centri u Novom Sadu i Beogradu',
    opstina_postrojenja: 'Grocka',
    ippc: false,
    aktivnosti: '6. (b)',
    preneto_iz_prethodnog_perioda: 3.25,
    godisnja_kolicina_proizvedenog_otpada: 2477.8,
    kolicina_otpada_predata_na_skladistenje: 1934.3,
    kolicina_otpada_predata_na_odlaganje: 242.3,
    kolicina_otpada_predata_na_tretman: 301.2,
    kolicina_izvezenog_otpada: null,
    ostalo_na_lokaciji: 3.85,
  },
];

export const GIO_5_POSTROJENJA_INDEKSNI_BROJ_MOCK: Gio5PostrojenjaIndeksniBroj[] = [
  {
    godina: 2017,
    pib: '100002323',
    preduzece: 'Društvo za istraživanje, proizvodnju, preradu, distribuciju i promet nafte i naftnih derivata',
    nacionalni_id: 100002323,
    postrojenje: 'Matica - Poslovni centri u Novom Sadu i Beogradu',
    opstina_postrojenja: 'Grocka',
    indeksni_broj: '04 02 09',
    opasan: true,
    naziv_otpada: 'otpadi od meševitih materijala',
    ukupno_uvezeno: 25.2,
    zemlja_izvoza: 'Zimbabwe',
    zemlje_tranzita: 'Madagaskar',
    y1: 'Y5',
    y2: 'Y40',
    y3: '',
    c_oznaka: null,
    h1: 'H5',
    h2: 'H40',
    h3: '',
    ia_oznaka: 'CF1',
    iib_oznaka: 'AAO1',
  },
];

export const GIO_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_MOCK: Gio5GrupaPodgrupaIndeksniBroj[] = [
  {
    indeksni_broj: '01',
    opis: 'OTPADI KOJI NASTAJU U ISTAZIVANJIMA...',
    kolicina: 2,
    children: [
      {
        indeksni_broj: '01 01',
        opis: 'otpadi od iskopavanja minerala...',
        kolicina: 1,
        children: [
          {
            indeksni_broj: '01 01 01',
            opis: 'otpadi od iskopavanja minerala za crnu metalurgiju...',
            kolicina: 1,
          },
        ],
      },
      {
        indeksni_broj: '01 02',
        opis: 'otpadi os iskopavanja minerala...',
        kolicina: 1,
        children: [
          {
            indeksni_broj: '01 02 01',
            opis: 'otpadi od iskopavanja minerala za crnu metalurgiju...',
            kolicina: 1,
          },
        ],
      },
    ],
  },
  {
    indeksni_broj: '20',
    opis: 'KOMUNALNI OTPADI...',
    kolicina: 5,
    children: [
      {
        indeksni_broj: '20 01',
        opis: 'odvojeno sakupljene frakcije...',
        kolicina: 5,
        children: [
          {
            indeksni_broj: '20 01 21',
            opis: 'fluorescentne cevi i drugi otpad koji sadrzi zivu...',
            kolicina: 5,
          },
        ],
      },
    ],
  },
];

export const GIO_5_UVOZ_OTPADA_PREGLED_PO_GODINI_MOCK: Gio5PregledPoGodinama[] = [
  {
    pib: '100002323',
    preduzece: 'Društvo za istraživanje, proizvodnju, preradu, distribuciju i promet nafte i naftnih derivata',
    nacionalni_id: 100002323,
    postrojenje: 'Matica - Poslovni centri u Novom Sadu i Beogradu',
    indeksni_broj: '04 02 09',
    opis_otpada: 'otpadi od mešovitih materijala',
    kolicine: [
      {
        godina: 2017,
        kolicina: 115.4,
      },
      {
        godina: 2018,
        kolicina: 2000.4,
      },
      {
        godina: 2019,
        kolicina: 335.4,
      },
      {
        godina: 2020,
        kolicina: 44.4,
      },
      {
        godina: 2021,
        kolicina: 85.4,
      },
    ],
  },
];

export const GIO_5_KOLICINA_UVEZENOG_OTPADA_PO_VRSTI_MOCK: Gio5KolicinaUvezenogOtpadaPoVrstiOtpada[] = [
  {
    indeksni_broj: '01',
    otpad: 'jalovine iz prerade sulfidne rude koje stvaraju kiselinu',
    kolicina_uvezenog_otpada: 3213,
    zemlja_izvoza: 'Madagaskar',
  },
];

export const AAO_1_PREGLED_PO_GODINAMA_MOCK: Aao1PregledPoGodinama[] = [
  {
    pib: '100002323',
    preduzece: 'Društvo za istraživanje, proizvodnju, preradu, distribuciju i promet nafte i naftnih derivata',
    email: 'test@test.com',
    vrsta_ambalaze: 'Drvo',
    kolicine: [
      {
        godina: 2017,
        kolicina: 115.4,
      },
      {
        godina: 2018,
        kolicina: 2000.4,
      },
      {
        godina: 2019,
        kolicina: 335.4,
      },
      {
        godina: 2020,
        kolicina: 44.4,
      },
      {
        godina: 2021,
        kolicina: 85.4,
      },
    ],
  },
];

export const AAO_1_PRIKAZ_1_MOCK: Aao1Prikaz1[] = [
  {
    pib: '100002323',
    preduzece: 'Društvo za istraživanje, proizvodnju, preradu, distribuciju i promet nafte i naftnih derivata',
    maticni_broj: '100001280',
    ulica: 'Deligradska',
    kucni_broj: '19',
    opstina_naziv: 'Beograd-Savski Venac',
    vrsta_ambalaze: 'Drvo-palete',
    ukupno_nepovratne_ambalaze: 174,
    ukupno_povratne_ambalaze: null,
    operater1: 'EKOSTAR',
    operater2: 'PAK d.o.o.',
    kreirano: '03/03/2017 09:26:41',
    azurirano: null,
  },
];

export const AAO_1_SVI_PODACI_MOCK: Aao1SviPodaci[] = [
  {
    godina: 2017,
    pib: '100002323',
    preduzece: 'Društvo za istraživanje, proizvodnju, preradu, distribuciju i promet nafte i naftnih derivata',
    proizvodjac_ambalaze: '',
    paker_proizvoda_u_ambalazu: '',
    isporucilac_ambalaze: '',
    uvoznik_ambalaze: '',
    izvozvnik_ambalaze: '',
    krajnji_korisnik_ambalaze: '',
    oznaka_grupe: '',
    oznaka_podgrupe: '',
    vrsta_ambalaze: '',
    nepovratna_ambalaza_proizvedena_kolicina: null,
    nepovratna_ambalaza_uvezena_kolicina: null,
    nepovratna_amvalaza_izvezena_kolicina: null,
    nepovratna_ambalaza_ukupna_kolicina: null,
    povratna_ambalaza_proizvedena_kolicina: null,
    povratna_ambalaza_uvezena_kolicina: null,
    povratna_ambalaza_izvezena_kolicina: null,
    povratna_ambalaza_ukupna_kolicina: null,
    kolicina_ambalaze_preneta_na_operatere: null,
    procenat_ambalaze_prenet_na_operatere: null,
    naziv_operatera1: '',
    broj_dozvole_operatera1: '',
    naziv_operatera2: '',
    broj_dozvole_operatera2: '',
    kolicina_ambalaze_na_samostalnom_upravljanju: null,
    procenat_ambalaze_prenet_na_samostalno_upravljanje: null,
  },
];

export const AAO_1_NEDOSTAVLJENI_OBRASCI_MOCK: Aao1NedostavljeniObrasci[] = [
  {
    godina: 2017,
    pib: '100002323',
    preduzece: 'Društvo za istraživanje, proizvodnju, preradu, distribuciju i promet nafte i naftnih derivata',
    maticni_broj: '54646456',
    adresa: 'test ulica',
    mesto: 'test mesto',
    postanski_broj: '111111',
    telefon: '1111111',
    email: 'test@test.com',
    pib_operatera: 1111111,
    naziv_operatera: 'Operater test',
  },
];

export const OBRAZAC_IZVESTAJI_ALL_MOCKS: { [key in IzvestajiSubtypes]: any[] } = {
  [Obrazac1IzvestajiEnum.GORIVA]: OBRAZAC_1_GORIVA_MOCK,
  [Obrazac1IzvestajiEnum.PROIZVODI]: OBRAZAC_1_PROIZVODI_MOCK,
  [Obrazac1IzvestajiEnum.SIROVINE]: [],
  [Obrazac1IzvestajiEnum.OPSTE]: [],
  [Obrazac2IzvestajiEnum.ZAGADLJIVE_MATERIJE]: [],
  [Obrazac2IzvestajiEnum.GORIVO]: [],
  [Obrazac2IzvestajiEnum.OPSTE]: [],
  [Obrazac2IzvestajiEnum.PREGLED_PO_GODINAMA]: [],
  [Obrazac2IzvestajiEnum.IZVORI_PO_GODINAMA]: [],
  [Obrazac3IzvestajiEnum.ZAGADLJIVE_MATERIJE]: OBRAZAC_3_ZAGADLJIVE_MATERIJE_MOCK,
  [Obrazac3IzvestajiEnum.PREGLED_PO_GODINAMA]: OBRAZAC_3_PREGLED_PO_GODINAMA_MOCK,
  [Obrazac3IzvestajiEnum.ISPUST_PO_GODINAMA]: OBRAZAC_3_ISPUST_PO_GODINAMA_MOCK,
  [Obrazac3IzvestajiEnum.OPSTE]: OBRAZAC_3_OPSTE_MOCK,
  [Obrazac5IzvestajiEnum.POSTROJENJA]: OBRAZAC_5_POSTROJENJA_MOCK,
  [Obrazac5IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: OBRAZAC_5_POSTROJENJA_INDEKSNI_BROJ_MOCK,
  [Obrazac5IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: OBRAZAC_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_MOCK,
  [Obrazac5IzvestajiEnum.OTPAD_NE_OPASAN]: OBRAZAC_5_OTPAD_NE_OPASAN_MOCK,
  [Obrazac5IzvestajiEnum.GENERISANJE_OTPADA_PREGLED_PO_GODINAMA]: OBRAZAC_5_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA_MOCK,
  [Obrazac5IzvestajiEnum.POSTROJENJA_ZA_PLACANJE]: OBRAZAC_5_POSTROJENJA_ZA_PLACANJE_MOCK,
  [Gio2IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: [],
  [Gio2IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: [],
  [Gio2IzvestajiEnum.ODLAGANJE_OTPADA_PREGLED_PO_GODINI]: [],
  [Gio2IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: [],
  [Gio3IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: [],
  [Gio3IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: [],
  [Gio3IzvestajiEnum.TRETMAN_OTPADA_PREGLED_PO_GODINI]: [],
  [Gio3IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: [],
  [Gio4IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: GIO_4_POSTROJENJA_INDEKSNI_BROJ_MOCK,
  [Gio4IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: GIO_4_GRUPA_PODGRUPA_INDEKSNI_BROJ_MOCK,
  [Gio4IzvestajiEnum.IZVOZ_OTPADA_PREGLED_PO_GODINI]: GIO_4_PREGLED_PO_GODINAMA_MOCK,
  [Gio4IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: GIO_4_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_MOCK,
  [Gio4IzvestajiEnum.KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI]: GIO_4_KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI_OTPADA_MOCK,
  [Gio5IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: GIO_5_POSTROJENJA_INDEKSNI_BROJ_MOCK,
  [Gio5IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: GIO_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_MOCK,
  [Gio5IzvestajiEnum.UVOZ_OTPADA_PREGLED_PO_GODINI]: GIO_5_UVOZ_OTPADA_PREGLED_PO_GODINI_MOCK,
  [Gio5IzvestajiEnum.KOLICINA_UVEZENOG_OTPADA_PO_VRSTI]: GIO_5_KOLICINA_UVEZENOG_OTPADA_PO_VRSTI_MOCK,
  [Deo6IzvestajiEnum.UKUPNO_REUZET_OTPAD]: [],
  [Deo6IzvestajiEnum.PREUZET_OTPAD_POSTROJENJA_INDEKSNI_BROJ]: [],
  [Deo6IzvestajiEnum.UKUPNO_PREDAT_OTPAD]: [],
  [Deo6IzvestajiEnum.PREDAT_OTPAD_POSTROJENJA_INDEKSNI_BROJ]: [],
  [Deo6IzvestajiEnum.REKAPITULACIJA]: [],
  [Deo6IzvestajiEnum.REKALKULACIJA]: [],
  [Deo6IzvestajiEnum.PRIMOPREDAJA_OTPADA_IZMEDJU_PREDUZECA]: [],
  [Kom1IzvestajiEnum.SASTAV_KOMUNALNOG_OTPADA_PO_GODINAMA]: [],
  [Kom1IzvestajiEnum.KOLICINA_KOMUNALNOG_OTPADA_PO_GODINAMA]: [],
  [Kom1IzvestajiEnum.OBUHVAT_PRIKUPLJENOG_OTPADA_PO_GODINAMA]: [],
  [Kom1IzvestajiEnum.UPRAVLJANJE_KOMUNALNIM_OTPADOM]: [],
  [Ptp2IzvestajiEnum.OBRACUN_ZA_NAPLATU]: PTP_2_OBRACUN_ZA_NAPLATU_MOCK,
  [Ptp2IzvestajiEnum.VOZILA_UPOREDJIVANJE]: PTP_2_VOZILA_UPOREDJIVANJE_MOCK,
  [Ptp2IzvestajiEnum.NEDOSTAVLJENI_PODACI_O_VOZILIMA]: PTP_2_NEDOSTAVLJENI_PODACI_O_VOZILIMA_MOCK,
  [Ptp2IzvestajiEnum.UVOZ_GUMA_UPOREDJIVANJE]: PTP_2_UVOZ_GUMA_UPOREDJIVANJE_MOCK,
  [Ptp2IzvestajiEnum.NEDOSTAVLJENI_PODACI_O_GUMAMA]: PTP_2_NEDOSTAVLJENI_PODACI_O_GUMAMA_MOCK,
  [Aao1IzvestajiEnum.PREGLED_PO_GODINAMA]: AAO_1_PREGLED_PO_GODINAMA_MOCK,
  [Aao1IzvestajiEnum.PRIKAZ_1]: AAO_1_PRIKAZ_1_MOCK,
  [Aao1IzvestajiEnum.SVI_PODACI]: AAO_1_SVI_PODACI_MOCK,
  [Aao1IzvestajiEnum.NEDOSTAVLJENI_OBRASCI]: AAO_1_NEDOSTAVLJENI_OBRASCI_MOCK,
  [Aao2IzvestajiEnum.IZVESTAJ_FOR_BOOK]: [],
  [PzvIzvestajiEnum.KOLICINE_PROIZVODA_PO_PREDUZECU]: PZV_KOLICINE_PROIZVODA_PO_PREDUZECU_MOCK,
  [PzvIzvestajiEnum.KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU]: PZV_KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU_MOCK,
  [Dep1IzvestajiEnum.SVI_PODACI]: [],
  [Dep2IzvestajiEnum.SVI_PODACI]: [],
};
