import { FormInput } from 'components/FormInput/FormInput';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import {
  MAX_LENGTH_RULE,
  MORE_THEN_0_IS_REQUIRED,
  PERCENTAGE_MORE_THAN_0_REQUIRED,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { pzv1ObrazacStore } from 'modules/obrasci/store/pzv1/pzv1.store';
import { IGetDeterdzent } from 'modules/obrasci/store/pzv1/pzv1.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { useTranslation } from 'react-i18next';

export const NewDeterdzentiForm: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaultDeterdzentOption: ISifarnikInitial | undefined = pzv1ObrazacStore.deterdzent.products_pzv_id
    ? {
        label: (pzv1ObrazacStore.deterdzent as IGetDeterdzent).products_pzv.name,
        value: pzv1ObrazacStore.deterdzent.products_pzv_id,
      }
    : undefined;

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={`${t('OBRASCI.PZV_1.DETERGENTS.TYPE')}`}
        labelCol={{ span: 8 }}
        formName={'products_pzv_id'}
        inputName={'products_pzv_id'}
        labelOptionAccessor={'name'}
        valueOptionAccessor={'id'}
        defaultOption={defaultDeterdzentOption}
        fetchOptinsList={pzv1ObrazacStore.fetchDeterdzentOptions}
        placeholder={t('OBRASCI.PZV_1.DETERGENTS.PLACEHOLDERS.TYPE')}
        notFoundContent={'Nema proizvoda za izabranu godinu'}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormInput
        label={`${t('OBRASCI.PZV_1.DETERGENTS.NAME')}`}
        labelCol={{ span: 8 }}
        name={'naziv'}
        inputName={'naziv'}
        placeholder={t('OBRASCI.PZV_1.DETERGENTS.PLACEHOLDERS.NAME')}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(100)]}
      />

      <FormInputNumber
        label={`${t('OBRASCI.PZV_1.DETERGENTS.CONTENT')}`}
        labelCol={{ span: 8 }}
        name={'sadrzaj_fosfata'}
        inputName={'sadrzaj_fosfata'}
        placeholder={t('OBRASCI.PZV_1.DETERGENTS.PLACEHOLDERS.CONTENT')}
        rules={PERCENTAGE_MORE_THAN_0_REQUIRED}
      />
      <FormInputNumber
        label={`${t('OBRASCI.PZV_1.DETERGENTS.QUANTITY')}`}
        labelCol={{ span: 8 }}
        name={'kolicina'}
        inputName={'kolicina'}
        placeholder={t('OBRASCI.PZV_1.DETERGENTS.PLACEHOLDERS.QUANTITY')}
        rules={MORE_THEN_0_IS_REQUIRED}
      />
    </>
  );
});
