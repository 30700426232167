import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { CRUDModal, ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import TableData from 'components/TableData/TableData';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { DEO_6_NOVE_VRSTE_OTPADA_INITIAL_STATE } from 'modules/obrasci/store/deo6/deo6.constants';
import { deo6ObrazacStore } from 'modules/obrasci/store/deo6/deo6.store';
import { IGetNoveVrsteOtpada, IGetRazrediEeOtpadaDeo6 } from 'modules/obrasci/store/deo6/deo6.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

const TableNoveVrsteOtpadaRazred: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetRazrediEeOtpadaDeo6> = [
    {
      title: `${t('OBRASCI.GIO_3.CLASS_EE_WASTE')}`,
      width: 100,
      key: 'category_name',
      render: (_: any, record: IGetRazrediEeOtpadaDeo6) => (
        <>{`${record.razred_ee_otpada.the_order} ${record.razred_ee_otpada.category_name_sr}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.OBRAZAC5.KOLICINA_OTPADA')}`,
      width: 100,
      dataIndex: 'kolicina',
      key: 'kolicina',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record: IGetRazrediEeOtpadaDeo6) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-razred-nove-vrste-otpada-deo6'}
            onClick={() => {
              deo6ObrazacStore.handleChange('razredi_nove_vrste_form', record);
              modalStore.changeModalName('deo6-razred-nove-vrste-otpada-modal');
            }}
          />
        );
      },
    },
  ];

  return (
    <TableData
      name="nove-vrste-razred-otpad-deo6"
      columns={baseColumns}
      dataSource={[...deo6ObrazacStore.getNoveVrsteOtpadaForm.razred]}
    />
  );
});

const NewNoveVrsteOtpadaForm: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  const wasteCatalogueOptions = (options: IGetWasteCatalogue[]) => {
    return options.map(wasteOption => ({
      label: `${wasteOption.index_number} ${wasteOption.waste_sr.toUpperCase()}`,
      value: wasteOption.id,
    }));
  };

  const defaultTypeOfWaste: ISifarnikInitial | undefined = deo6ObrazacStore.getNoveVrsteOtpadaForm
    .indeksni_broj_otpada_id
    ? {
        value: deo6ObrazacStore.getNoveVrsteOtpadaForm.indeksni_broj_otpada_id,
        label: `${
          (deo6ObrazacStore.getNoveVrsteOtpadaForm as IGetNoveVrsteOtpada).indeksni_broj_otpada.index_number
        } ${(
          deo6ObrazacStore.getNoveVrsteOtpadaForm as IGetNoveVrsteOtpada
        ).indeksni_broj_otpada.waste_sr.toUpperCase()}`,
      }
    : undefined;

  useEffect(() => {
    if (deo6ObrazacStore.weee_categories_nove_vrste) {
      props.form.validateFields(['kolicina']);
    }
  }, [deo6ObrazacStore.razredi_nove_vrste_form]);

  useEffect(() => {
    return () => {
      deo6ObrazacStore.handleChange('index_nove_vrste_id', '');
      deo6ObrazacStore.handleChange('weee_categories_nove_vrste', false);
    };
  }, []);

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'indeksni_broj_otpada_id'}
        fetchOptinsList={deo6ObrazacStore.fetchWasteCatalougeList}
        labelOptionAccessor="index_number"
        valueOptionAccessor="id"
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                deo6ObrazacStore.nove_vrste_form.indeksni_broj_otpada_id !== value &&
                deo6ObrazacStore.predat_form.nove_vrste_otpada_lista.some(item => {
                  return value === item.indeksni_broj_otpada_id;
                })
              ) {
                return Promise.reject(new Error(t('Indeksni broj već postoji u listi')));
              }
              return Promise.resolve();
            },
          },
        ]}
        inputName={'indeksni_broj_otpada_id'}
        label={t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}
        colon={true}
        defaultOption={defaultTypeOfWaste}
        placeholder={''}
        disabled={!!(deo6ObrazacStore.index_nove_vrste_id && !props.form.getFieldError('indeksni_broj_otpada_id'))}
        onChange={e => {
          deo6ObrazacStore.handleChange('index_nove_vrste_id', e);
          const obj = deo6ObrazacStore.indexMap(e);
          if (!obj.weee && deo6ObrazacStore.weee_categories_nove_vrste) {
            props.form.validateFields(['kolicina']);
          }
          deo6ObrazacStore.checkWeeeCategoriesNoveVrste(e);
        }}
        optionsMaker={wasteCatalogueOptions}
      />
      <FormInputNumber
        name="kolicina"
        label={t('OBRASCI.OBRAZAC5.KOLICINA_OTPADA')}
        placeholder={''}
        rules={[
          ...MORE_THEN_0_IS_REQUIRED,
          {
            validator(_, value) {
              if (deo6ObrazacStore.weee_categories_nove_vrste) {
                let sum = 0;
                (deo6ObrazacStore.nove_vrste_form as IGetNoveVrsteOtpada).razred.map(
                  (item: IGetRazrediEeOtpadaDeo6) => (sum += item.kolicina ? item.kolicina : 0)
                );
                if (sum !== value) {
                  return Promise.reject(new Error(t('OBRASCI.VALIDATION.JEDNAKA_RAZRED')));
                }
              }
              return Promise.resolve();
            },
          },
        ]}
        inputName={'kolicina'}
        colon={true}
        precision={4}
      />
      {deo6ObrazacStore.weee_categories_nove_vrste && (
        <InsideContentWrapper
          header={
            <WizardObrazacListHeader
              title={t('OBRASCI.GIO_3.TYPE_OF_ELECTRICAL_AND_ELECTRONIC_WASTE')}
              name={'deo6-razred-nove-vrste-form'}
              modalName="deo6-razred-nove-vrste-otpada-modal"
            />
          }
        >
          <TableNoveVrsteOtpadaRazred />
        </InsideContentWrapper>
      )}
    </>
  );
});

export const Deo6NoveVrsteOtpadModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="50%"
      name="deo6-nove-vrste-otpad-modal"
      title={t('OBRASCI.DEO_6.NOVE_VRSTE_OTPADA')}
      store={deo6ObrazacStore}
      data={deo6ObrazacStore.getNoveVrsteOtpadaForm}
      children={({ form }) => <NewNoveVrsteOtpadaForm form={form} />}
      submitForm={deo6ObrazacStore.submitNoveVrsteOtpada}
      initialState={DEO_6_NOVE_VRSTE_OTPADA_INITIAL_STATE}
      dataToChange={'nove_vrste_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      onCancel={deo6ObrazacStore.resetWeeeCategoriesNoveVrste}
      closeOneModal={true}
    />
  );
});
