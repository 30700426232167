import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { GveGorivaTable } from './GveGorivaTable/GveGorivaTable';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';

export const GveGorivaWizardTab: React.FC = observer(() => {
  // todo:Zasto ovde ovaj effect?
  useEffect(() => {
    if (gveStore.izvorZagadjenjaObject.source_type?.name_sr === 'Energetski') {
      gveStore.handleChange('isIzvorZagadjenjaEnergetski', true);
    } else {
      gveStore.handleChange('isIzvorZagadjenjaEnergetski', false);
    }
  }, []);

  return (
    <InsideContentWrapper header={<WizardObrazacListHeader name={'gve-gorivo-form'} modalName="gve-gorivo-modal" />}>
      <GveGorivaTable />
    </InsideContentWrapper>
  );
});
