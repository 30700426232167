import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { NewFacilityForm } from 'components/NewForms';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import styles from './FacilityInfo.module.scss';
import { Col, FormInstance, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { formStore } from 'store';
import { facilityStore } from 'modules/facility/facility.store';
import { cloneDeep } from 'lodash';
import { IPostFacility } from 'modules/facility/facility.types';
import { IsActiveSwitch } from 'components/IsActiveSwitch/IsActiveSwitch';
import { companyStore } from 'modules/company/company.store';
import { EditChangeDiscardButton } from 'components/Buttons/EditChangeDiscardButton/EditChangeDiscardButton';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { airStore } from 'modules/sourcesOfPollution/air/air.store';
import { waterStore } from 'modules/sourcesOfPollution/water/water.store';
import { soilStore } from 'modules/sourcesOfPollution/soil/soil.store';

interface IFacilityInfoHeaderProps {
  form: FormInstance<IPostFacility> | null;
  changeIsReadOnly: () => any;
  isReadOnly: boolean;
}

export const FacilityInfoHeader: React.FC<IFacilityInfoHeaderProps> = observer(props => {
  const { t } = useTranslation();
  const [isButtonsVisible, setIsButtonsVisible] = useState(true);

  const hasObrazac2 = facilityStore.facilityTipoviObrazaca.some(
    (item: any) => item.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_2
  );
  const hasLrizObrazac2 = facilityStore.facilityTipoviObrazaca.some(
    (item: any) => item.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2
  );
  const hasGve = facilityStore.facilityTipoviObrazaca.some((item: any) => item.skraceni_naziv === TIPOVI_OBRAZACA.GVE);

  const hasObrazac3 = facilityStore.facilityTipoviObrazaca.some(
    (item: any) => item.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_3
  );
  const hasLrizObrazac3 = facilityStore.facilityTipoviObrazaca.some(
    (item: any) => item.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3
  );
  const hasOtv = facilityStore.facilityTipoviObrazaca.some((item: any) => item.skraceni_naziv === TIPOVI_OBRAZACA.OTV);

  const hasObrazac4 = facilityStore.facilityTipoviObrazaca.some(
    (item: any) => item.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_4
  );
  const hasLrizObrazac4 = facilityStore.facilityTipoviObrazaca.some(
    (item: any) => item.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4
  );

  useEffect(() => {
    if (
      ((hasLrizObrazac2 || hasObrazac2 || hasGve) && !airStore.airList.length) ||
      ((hasLrizObrazac3 || hasObrazac3 || hasOtv) && !waterStore.waterList.length) ||
      ((hasLrizObrazac4 || hasObrazac4) && !soilStore.soilList.length)
    ) {
      if (!props.isReadOnly) {
        props.changeIsReadOnly();
      }
      return setIsButtonsVisible(false);
    }
    return setIsButtonsVisible(true);
  }, [
    hasGve,
    hasLrizObrazac2,
    hasLrizObrazac3,
    hasLrizObrazac4,
    hasObrazac2,
    hasObrazac3,
    hasObrazac4,
    hasOtv,
    airStore.airList,
    waterStore.waterList,
    soilStore.soilList,
  ]);

  return (
    <Row align={'middle'} justify={'space-between'}>
      <h3>{t('COMPANY.GENERAL_INFORMATION')}</h3>

      <Row align={'middle'}>
        <Col>
          {companyStore.getCompany.is_active && props.isReadOnly && (
            <IsActiveSwitch
              isActive={facilityStore.getFacility.is_active}
              aktiviraj={facilityStore.aktivirajPostrojenje}
              deaktiviraj={facilityStore.deaktivirajPostrojenje}
              disabled={!companyStore.getCompany.is_active}
            />
          )}
        </Col>
        {isButtonsVisible && (
          <EditChangeDiscardButton
            name="facility-info"
            editButtonVisible={props.isReadOnly && facilityStore.getFacility.is_active}
            onEditClick={() => {
              props.changeIsReadOnly();
            }}
            onDiscardClick={() => {
              props.changeIsReadOnly();
              facilityStore.setFacility(cloneDeep(facilityStore.getFacility));
            }}
            onChangeClick={() => {
              formStore.setCrudButtonType('change');
              props.form?.validateFields().then(
                res => {
                  props.form?.submit();
                  props.changeIsReadOnly();
                  return res;
                },
                err => {
                  return err;
                }
              );
            }}
            discardChangeButtonVisible={!props.isReadOnly}
          />
        )}
      </Row>
    </Row>
  );
});

export const FacilityInfo: React.FC = observer(() => {
  const [form, setForm] = useState<FormInstance<IPostFacility> | null>(null);
  const [isReadOnly, setIsReadOnly] = useState(true);

  const changeIsReadOnly = () => {
    setIsReadOnly(!isReadOnly);
  };

  const passForm = (form: FormInstance<IPostFacility>) => {
    setForm(form);
  };

  return (
    <InsideContentWrapper
      header={<FacilityInfoHeader form={form} changeIsReadOnly={changeIsReadOnly} isReadOnly={isReadOnly} />}
    >
      <div className={`${styles.facility_info_wrapper} ${isReadOnly && styles.inputReadOnly}`}>
        <NewFacilityForm readOnly={isReadOnly} passForm={passForm} />
      </div>
    </InsideContentWrapper>
  );
});
