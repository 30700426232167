import { IPostDkoObrazac, PrimalacOtpadaJeEnum, ProizvodjacVlasnikOtpadaEnum, VidPrevozaEnum } from './dko.types';

import i18n from 'translations/i18n.config';

export const DKO_INITIAL_STATE: IPostDkoObrazac = {
  preduzece_id: '',
  postrojenje_id: '',
  potvrda_dokumenta: false,
  odjava_kretanja: false,
  admin_odjava_dokumenta: false,
  indeksni_broj_otpada_id: '',
  napomena: '',
  admin_napomena: '',
  broj_dokumenta: '',
  type_of_waste_id: '',
  q_lista_id: '',
  dko_deo_a_y_lista_id: '',
  dko_deo_a_y_lista2_id: '',
  dko_deo_a_y_lista3_id: '',
  dko_deo_a_c_lista_id: '',
  un_broj_klasa: '',
  dko_deo_a_n_lista_id: '',
  dko_deo_a_n_lista2_id: '',
  dko_deo_a_n_lista3_id: '',
  masa_otpada: null,
  nacin_pakovanja_otpada_id: '',
  fizicko_stanje_otpada_id: '',
  broj_izvestaja: '',
  datum_izdavanja: '',
  vid_prevoza: '',
  mesto_id: '',
  adresa_odredista: '',
  napomena_deo_a: '',
  proizvodjac_vlasnik_otpada: '',
  predvidjeni_nacin_postupanja_id: '',
  broj_dozvole: '',
  datum_izdavanja_dozvole: '',
  datum_predaje_otpada: '',
  ime_prezime: '',
  broj_mobilnog_telefona: '',
  prevoznik_otpada_id: '',
  telefon_prevoznika: '',
  telefaks_prevoznika: '',
  email: '',
  vrsta_prevoznog_sredstva_id: '',
  registarski_broj_prevoznog_sredstva: '',
  lokacija_utovara_id: '',
  adresa_utovara: '',
  preko_id: '',
  preko2_id: '',
  preko3_id: '',
  lokacija_istovara_id: '',
  adresa_istovara: '',
  broj_dozvole_deo_c: '',
  datum_izdavanja_dozvole_deo_c: '',
  datum_preuzimanja_otpada: '',
  ime_prezime_prevoznika_preuzet: '',
  broj_mobilnog_telefona_preuzet: '',
  datum_predaje_otpada_deo_c: '',
  ime_prezime_prevoznika_predat: '',
  broj_mobilnog_telefona_predat: '',
  primalac_otpada_id: '',
  broj_mobilnog_telefona_primaoca_otpada: '',
  telefon_primaoca_otpada: '',
  telefaks_primaoca_otpada: '',
  email_deo_d: '',
  primalac_otpada_je: '',
  broj_dozvole_deo_d: '',
  datum_izdavanja_dozvole_deo_d: '',
  datum_preuzimanja_otpada_deo_d: '',
  ime_prezime_primaoca_otpada: '',
  dko_deo_a_c_lista2_id: '',
  dko_deo_a_c_lista3_id: '',
};

// vid_prevoza

export const VidPrevozaOptions = [
  {
    label: i18n.t('OBRASCI.DKO.DRUMSKI'),
    value: VidPrevozaEnum.DRUMSKI,
  },
  {
    label: i18n.t('OBRASCI.DKO.ZELEZNICKI'),
    value: VidPrevozaEnum.ZELEZNICKI,
  },
  {
    label: i18n.t('OBRASCI.DKO.VODENI'),
    value: VidPrevozaEnum.VODENI,
  },
];

// proizvodjac_vlasnik_otpada

export const ProizvodjacVlasnikOptions = [
  {
    label: i18n.t('OBRASCI.DKO.PROIZVODJAC'),
    value: ProizvodjacVlasnikOtpadaEnum.PROIZVODJAC,
  },
  {
    label: i18n.t('OBRASCI.DKO.VLASNIK'),
    value: ProizvodjacVlasnikOtpadaEnum.VLASNIK,
  },
  {
    label: i18n.t('OBRASCI.DKO.OPERATER'),
    value: ProizvodjacVlasnikOtpadaEnum.OPERATER,
  },
];

// primalac_otpada_je

export const PrimalacOtpadaOptions = [
  {
    label: i18n.t('OBRASCI.DKO.POSTROJENJE_SKLADISTE'),
    value: PrimalacOtpadaJeEnum.POSTROJENJE_ZA_SKLADISTENJE_OTPADA,
  },
  {
    label: i18n.t('OBRASCI.DKO.POSTROJENJE_TRETMAN'),
    value: PrimalacOtpadaJeEnum.POSTROJENJE_ZA_TRETMAN_OTPADA,
  },
  {
    label: i18n.t('OBRASCI.DKO.POSTROJENJE_ODLAGANJE'),
    value: PrimalacOtpadaJeEnum.POSTROJENJE_ZA_ODLAGANJE_OTPADA,
  },
];

export type StringKeys<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

export const datesToFormat: StringKeys<IPostDkoObrazac>[] = [
  'datum_izdavanja',
  'datum_izdavanja_dozvole',
  'datum_predaje_otpada',
  'datum_izdavanja_dozvole_deo_c',
  'datum_preuzimanja_otpada',
  'datum_predaje_otpada_deo_c',
  'datum_izdavanja_dozvole_deo_d',
  'datum_preuzimanja_otpada_deo_d',
];

export const FILTER_APR =
  '9f3d3e64-784a-4fde-a67d-ae372b3f0c90,fa9518f0-5271-4039-adef-c588a922b5f8,40fb460c-2610-4c07-841f-1940865b8a0b,8a3a7889-8c70-4342-bc5a-fff7587c6b0c';
