import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { gio5ObrazacStore } from 'modules/obrasci/store/gio5/gio5.store';
import { useTranslation } from 'react-i18next';
import { GIO_5_DOZVOLE_INITIAL_STATE } from 'modules/obrasci/store/gio5/gio5.constants';
import { NewGio5DozvoleForm } from './components/NewGio4DozvoleForm/NewGio5DozvoleForm';

export const Gio5DozvoleModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  return (
    <CRUDModal
      width="40%"
      name="gio5-dozvole-modal"
      title={t('WIZARD_TABS.DOZVOLE')}
      store={gio5ObrazacStore}
      data={gio5ObrazacStore.getDozvoleInitial}
      children={() => <NewGio5DozvoleForm />}
      submitForm={gio5ObrazacStore.submitDozvoleForm}
      initialState={GIO_5_DOZVOLE_INITIAL_STATE}
      dataToChange={'dozvoleInitial'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    />
  );
});
