import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { pzv1ObrazacStore } from 'modules/obrasci/store/pzv1/pzv1.store';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const DeterdzentiTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const columns: ColumnsType<any> = [
    {
      title: `${t('OBRASCI.PZV_1.DETERGENTS.TYPE')}`,
      width: 50,
      align: 'center',
      dataIndex: ['products_pzv', 'name'],
    },
    {
      title: `${t('OBRASCI.PZV_1.DETERGENTS.NAME')}`,
      width: 50,
      align: 'center',
      dataIndex: 'naziv',
    },
    {
      title: `${t('OBRASCI.PZV_1.DETERGENTS.CONTENT')}`,
      width: 70,
      align: 'center',
      dataIndex: 'sadrzaj_fosfata',
    },
    {
      title: `${t('OBRASCI.PZV_1.DETERGENTS.QUANTITY')}`,
      width: 70,
      align: 'center',
      dataIndex: 'kolicina',
    },
    {
      title: '',
      fixed: 'right',
      align: 'center',
      width: 20,
      render: (_, record) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-activity'}
            onClick={() => {
              pzv1ObrazacStore.handleChange('deterdzent', record);
              modalStore.changeModalName(`deterdzenti-modal`);
            }}
          />
        );
      },
    },
  ];

  return (
    <TableData
      name="deterdzenti-pzv1"
      columns={columns}
      dataSource={[...pzv1ObrazacStore.getInitialState.deterdzenti]}
    />
  );
});
