import { Col } from 'antd';
import { FormInput } from 'components/FormInput/FormInput';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './NewKolicineObrazac5Form.module.scss';
import {
  MORE_OR_EQUILE_0_IS_REQUIRED,
  MORE_THEN_0_IS_REQUIRED,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { obrazac5Store } from 'modules/obrasci/store/obrazac5/obrazac5.store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetObrazac5 } from 'modules/obrasci/store/obrazac5/obrazac5.types';

export const NewKolicineObrazac5Form: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaultNacinOdredjivanja: ISifarnikInitial | undefined = obrazac5Store.initialState
    .nacin_odredjivanja_kolicina_otpada_id
    ? {
        label: (obrazac5Store.getInitialState as IGetObrazac5).nacin_odredjivanja_kolicina_otpada.name_sr,
        value: obrazac5Store.getInitialState.nacin_odredjivanja_kolicina_otpada_id,
      }
    : undefined;

  return (
    <Col span={24} className={styles.wrapper}>
      <h3 className={styles.titleMargin}>{t('OBRASCI.OBRAZAC5.KOLICINA_OTPADA')}</h3>
      <FormInput
        name="godisnja_kolicina_proizvedenog_otpada"
        label={t('OBRASCI.OBRAZAC5.GODISNJA_KOLICINA')}
        type="number"
        placeholder={t('OBRASCI.OBRAZAC5.GODISNJA_KOLICINA')}
        inputName={'godisnja_kolicina_proizvedenog_otpada'}
        colon={true}
        rules={MORE_THEN_0_IS_REQUIRED}
      />
      <FormInput
        name="stanje_skladista_1_januar"
        label={t('OBRASCI.OBRAZAC5.STANJE_1_JANUAR')}
        type="number"
        placeholder={t('OBRASCI.OBRAZAC5.STANJE_1_JANUAR')}
        inputName={'stanje_skladista_1_januar'}
        colon={true}
        rules={MORE_OR_EQUILE_0_IS_REQUIRED}
      />
      <FormInput
        name="stanje_skladista_31_decembar"
        label={t('OBRASCI.OBRAZAC5.STANJE_31_DECEMBAR')}
        type="number"
        placeholder={t('OBRASCI.OBRAZAC5.STANJE_31_DECEMBAR')}
        inputName={'stanje_skladista_31_decembar'}
        colon={true}
        rules={MORE_OR_EQUILE_0_IS_REQUIRED}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'nacin_odredjivanja_kolicina_otpada_id'}
        fetchOptinsList={obrazac5Store.fetchMethodBasisCode}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'nacin_odredjivanja_kolicina_otpada_id'}
        label={t('OBRASCI.OBRAZAC5.NACIN_ODREDJIVANJA')}
        colon={true}
        defaultOption={defaultNacinOdredjivanja}
        placeholder={t('OBRASCI.OBRAZAC5.NACIN_ODREDJIVANJA')}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
    </Col>
  );
});
