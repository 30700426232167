import React from 'react';
import { observer } from 'mobx-react-lite';

import { TableNaseljeDep1 } from '../TableNaseljeDep1/TableNaseljeDep1';
import { HeaderNaseljeDep1 } from '../HeaderNaseljeDep1/HeaderNaseljeDep1';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';

import styles from './GradoviNaseljeWrapper.module.scss';

export const GradoviNaseljeWrapper: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<HeaderNaseljeDep1 />} className={styles.wrapperTable}>
      <TableNaseljeDep1 />
    </InsideContentWrapper>
  );
});
