import { waterRepo } from './water.repo';
import { IGetWater, IPostWater, RequestwaterList } from './water.types';

class WaterService {
  fetchWaterList = (payload: RequestwaterList) => {
    return waterRepo.fetchWaterList(payload);
  };

  postWater = (payload: IPostWater) => {
    return waterRepo.postWater(payload);
  };

  putWater = (payload: IGetWater) => {
    return waterRepo.putWater(payload);
  };

  deleteWater = (id: string) => {
    return waterRepo.deleteWater(id);
  };

  aktivirajVodu = (id: string) => {
    return waterRepo.aktivirajVodu(id);
  };
  deaktivirajVodu = (id: string) => {
    return waterRepo.deaktivirajVodu(id);
  };
}

export const waterService = new WaterService();
