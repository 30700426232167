import { makeAutoObservable, observable, runInAction } from 'mobx';

import { SIFARNICI_ROUTES, IDateArrayPropsData } from './sifarnici.types';

import { sifaniciService } from './sifarnici.service';
import { IPaginatedApiResponse, IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import to from 'await-to-js';
import { handleSuccessNotify } from 'utils/succesHandling';
import { nrizService } from 'modules/nriz/nriz.service';
import { IGetLaboratoriesAirWater } from './store/laboratoriesAirWater/laboratoriesAirWater.types';
import { IGetApr } from './store/apr/apr.types';
import { IGetAprAddressData } from './store/aprAddressData/aprAddressData.types';
import { IGetAprAssigneeFounder } from './store/aprAssigneeFounder/aprAssigneeFounder.types';
import { IGetProductCategories } from './store/productCategories/productCategories.types';
import { IGetProductsNr } from './store/productsNr/productsNr.types';
import { IGetProductsPzv } from './store/productsPzv/productsPzv.types';
import { IGetProductsType } from './store/productsType/productsType.types';
import { IGetPtp2ReportCustoms } from './store/ptp2ReportCustoms/ptp2ReportCustoms.types';
import { modalStore } from 'store';
import { removeSearchParamsFromURL } from 'utils/removeParamsFromUrl';
import { IGetPzvCustomsAndProducers } from './store/pzvCustomsAndProducers/pzvCustomsAndProducers.types';
import { IGetPzvProducts } from './store/pzvProducts/pzvProducts.types';
import { IGetAir } from 'modules/sourcesOfPollution/air/air.types';
import { IGetCompanyWithSelfManagment } from './store/companyWithSelfManagment/companyWithSelfManagment.types';
import { IGetCompany } from 'modules/company/company.types';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetUnsanitaryLandfill } from 'modules/unsanitarylandfill/unsanitarylandfill.types';
import { IGetSoil } from 'modules/sourcesOfPollution/soil/soil.types';
import { IGetWater, ISliv } from 'modules/sourcesOfPollution/water/water.types';
import { IGetActivity1 } from 'modules/activityList1/activityList1.types';
import { IGetActivity2 } from 'modules/activityList2/activityList2.types';
import { IGetPrivrednaDelatnost1 } from './store/privrednaDelatnost1/privrednaDelatnost1.types';
import { IGetPrivrednaDelatnost2 } from './store/privrednaDelatnost2/privrednaDelatnost2.types';
import { filtersStore } from 'store/filteri.store';
import { IGetOperatorsPackageWasteManage } from './store/operatorsPackageWasteManage/operatorsPackageWasteManage.types';
import { IGetDRList } from './store/dRList/dRList.types';
import { IGetActivitiesOfWasteOperators } from './store/activitiesOfWasteOperators/activitiesOfWasteOperators.types';
import { IGetCList } from './store/cList/cList.types';
import { IGetCompositionCommunalWaste } from './store/compositionCommunalWaste/compositionCommunalWaste.types';
import { IGetCountryCode } from './store/countryCode/countryCode.types';
import { IGetDepFormAnswers } from './store/depFormsAnswers/depFormsAnswers.types';
import { IGetFuelType } from './store/fuelType/fuelType.types';
import { IGetIaList } from './store/iaList/iaList.types';
import { IGetIibList } from './store/iibList/iibList.types';
import { IGetKom1TypeOfWaste } from './store/kom1TypeOfWaste/kom1TypeOfWaste.types';
import { IGetLandfillType } from './store/landfillType/landfillType.types';
import { IGetMethodBasisCode } from './store/methodBasisCode/methodBasisCode.types';
import { IGetMethodOfPackagingWaste } from './store/methodOfPackaging/methodOfPackaging.types';
import { IGetMethodTypes } from './store/methodTypes/methodTypes.types';
import { IGetWasteCatalogue } from './store/wasteCatalogue/wasteCatalogue.types';
import { IGetTypeOfWaste } from './store/typeOfWaste/typeOfWaste.types';
import { IGetPollutantCode } from './store/pollutantCode/pollutantCode.types';
import { IGetTypeOfPacking } from './store/typeOfPacking/typeOfPacking.types';
import { IGetWeeeCategories } from './store/weeeCategories/weeeCategories.types';
import { IGetSifarnikPretezneDelatnosti } from './store/sifarnikPretezneDelatnosti/sifarnikPretezneDelatnosti.types';
import { IGetWeeeProductsCategory } from './store/weeeProductsCategory/weeeProductsCategory.types';
import { IGetYList } from './store/yList/yList.types';
import { IGetNList } from './store/nList/nList.types';
import { IGetWasteCharacter } from './store/wasteCharacter/wasteCharacter.types';
import { IGetTypeMeansTransport } from './store/typeMeansTransport/typeMeansTransport.types';
import { IGetMesto } from './store/mesto/mesto.types';
import { IGetPzvTypeOfProducts } from './store/pzvTypeOfProducts/pzvTypeOfProducts.types';
import { IGetQList } from './store/qList/qList.types';
import { IGetUnitCode } from './store/unitCode/unitCode.types';
import { IGetWasteStateCategory } from './store/wasteStateCategory/wasteStateCategory.types';
import { IGetWayOfHandOver } from './store/wayOfHandOver/wayOfHandOver.types';
import { IGetWorkingRegime } from './store/workingRegime/workingRegime.types';
import { IGetWaterBodies } from './store/waterBodies/waterBodies.types';
import { IGetAprCompanyStatus } from './store/aprCompanyStatus/aprCompanyStatus.types';
import { IGetAprDataSource } from './store/aprDataSource/aprDataSource.types';
import { IGetAprLegalForm } from './store/aprLegalForm/aprLegalForm.types';
import { IGetAprSizeOfBusinessEntity } from './store/aprSizeOfBusinessEntity/aprSizeOfBusinessEntity.types';
import { IGetAprTypeOfShapeOfOrganizing } from './store/aprTypeOfShapeOfOrganizing/aprTypeOfShapeOfOrganizing.types';
import { IGetAprTypeAddress } from './store/aprTypeAddress/aprTypeAddress.types';
import { IGetAprTypeAssignee } from './store/aprTypeAssignee/aprTypeAssignee.types';
import { IGetAprTypePersonality } from './store/aprTypePersonality/aprTypePersonality.types';
import { IGetAprAssigneeFunction } from './store/aprAssigneeFunction/aprAssigneeFunction.types';
import { IGetSourceType } from './store/sourceType/sourceType.types';
import { IGetRecipientType } from './store/recipientType/recipientType.types';
import { arrayToObject } from 'utils/arrayToObject';

export interface ISifarnik {
  name: string;
}

class SifarniciStore {
  sifarnik_type: SIFARNICI_ROUTES = SIFARNICI_ROUTES.AKTIVNOST_1;
  filters: any = {};
  sifarniciListTable: ISifarnik[] = [];
  initialState: any = {};

  list: { [sifarnik: string]: any[] } = {
    [SIFARNICI_ROUTES.KOM1_TYPE_OF_WASTE]: [] as IGetKom1TypeOfWaste[],
    [SIFARNICI_ROUTES.WASTE_CATALOGUE]: [] as IGetWasteCatalogue[],
    [SIFARNICI_ROUTES.METHOD_BASIS_CODE]: [] as IGetMethodBasisCode[],
    [SIFARNICI_ROUTES.WAY_OF_HAND_OVER]: [] as IGetWayOfHandOver[],
    [SIFARNICI_ROUTES.COMPOSITION_COMMUNAL_WASTE]: [] as IGetCompositionCommunalWaste[],
    [SIFARNICI_ROUTES.DR_LIST]: [] as IGetDRList[],
    [SIFARNICI_ROUTES.MUNICIPALITY_CODE]: [] as IGetCompositionCommunalWaste[],
    [SIFARNICI_ROUTES.MESTO]: [] as IGetMesto[],
    [SIFARNICI_ROUTES.PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_PREDUZECE]: [] as IGetCompany[],
    [SIFARNICI_ROUTES.PREDUZECE_APR]: [] as IGetCompany[],
    [SIFARNICI_ROUTES.PREDUZECE_POSTROJENJE_TIP_OBRASCA_UPITNIK_POSTROJENJE]: [] as IGetFacility[],
    [SIFARNICI_ROUTES.KORISNIK_POSTROJENJE]: [] as IGetFacility[],
    [SIFARNICI_ROUTES.POSTROJENJE]: [] as IGetFacility[],
    [SIFARNICI_ROUTES.NESANITARNA_DEPONIJA]: [] as IGetUnsanitaryLandfill[],
    [SIFARNICI_ROUTES.PREDUZECE]: [] as IGetCompany[],
    [SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_TLA]: [] as IGetSoil[],
    [SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE]: [] as IGetWater[],
    [SIFARNICI_ROUTES.DEP_FORMS_ANSWERS]: [] as IGetDepFormAnswers[],
    [SIFARNICI_ROUTES.UNIT_CODE]: [] as IGetUnitCode[],
    [SIFARNICI_ROUTES.UNIT_CODE_BY_POLLUTANT]: [] as IGetUnitCode[],
    [SIFARNICI_ROUTES.FUEL_TYPE]: [] as IGetFuelType[],
    [SIFARNICI_ROUTES.WORKING_REGIME]: [] as IGetWorkingRegime[],
    [SIFARNICI_ROUTES.TYPE_OF_WASTE]: [] as IGetTypeOfWaste[],
    [SIFARNICI_ROUTES.POLLUTANT_CODE]: [] as IGetPollutantCode[],
    [SIFARNICI_ROUTES.METHOD_TYPES]: [] as IGetMethodTypes[],
    [SIFARNICI_ROUTES.PRODUCTS_PZV]: [] as IGetProductsPzv[],
    [SIFARNICI_ROUTES.OPERATORS_PACKAGE_WASTE_MANAGE]: [] as IGetOperatorsPackageWasteManage[],
    [SIFARNICI_ROUTES.TYPE_OF_PACKING]: [] as IGetTypeOfPacking[],
    [SIFARNICI_ROUTES.COMPANY_WITH_SELF_MANAGEMENT]: [] as IGetCompanyWithSelfManagment[],
    [SIFARNICI_ROUTES.WEEE_CATEGORIES]: [] as IGetWeeeCategories[],
    [SIFARNICI_ROUTES.SIFARNIK_PRETEZNE_DELATNOSTI]: [] as IGetSifarnikPretezneDelatnosti[],
    [SIFARNICI_ROUTES.LANDFILL_TYPE]: [] as IGetLandfillType[],
    [SIFARNICI_ROUTES.PRODUCTS_NR]: [] as IGetProductsNr[],
    [SIFARNICI_ROUTES.PRODUCTS_TYPE]: [] as IGetProductsType[],
    [SIFARNICI_ROUTES.WEEE_PRODUCTS_CATEGORY]: [] as IGetWeeeProductsCategory[],
    [SIFARNICI_ROUTES.Y_LIST]: [] as IGetYList[],
    [SIFARNICI_ROUTES.Y_LIST2]: [] as IGetYList[],
    [SIFARNICI_ROUTES.Y_LIST3]: [] as IGetYList[],
    [SIFARNICI_ROUTES.N_LIST]: [] as IGetNList[],
    [SIFARNICI_ROUTES.N_LIST2]: [] as IGetNList[],
    [SIFARNICI_ROUTES.N_LIST3]: [] as IGetNList[],
    [SIFARNICI_ROUTES.C_LIST]: [] as IGetCList[],
    [SIFARNICI_ROUTES.IA_LIST]: [] as IGetIaList[],
    [SIFARNICI_ROUTES.IIB_LIST]: [] as IGetIibList[],
    [SIFARNICI_ROUTES.COUNTRY_CODE]: [] as IGetCountryCode[],
    [SIFARNICI_ROUTES.WASTE_CHARACTER]: [] as IGetWasteCharacter[],
    [SIFARNICI_ROUTES.Q_LIST]: [] as IGetQList[],
    [SIFARNICI_ROUTES.WASTE_STATE_CATEGORY]: [] as IGetWasteStateCategory[],
    [SIFARNICI_ROUTES.TYPE_MEANS_TRANSPORT]: [] as IGetTypeMeansTransport[],
    [SIFARNICI_ROUTES.METHOD_OF_PACKAGING_WASTE]: [] as IGetMethodOfPackagingWaste[],
    [SIFARNICI_ROUTES.LABORATORIES_AIR_WATER]: [] as IGetLaboratoriesAirWater[],
    [SIFARNICI_ROUTES.APR]: [] as IGetApr[],
    [SIFARNICI_ROUTES.APR_ASSIGNEE_FOUNDER]: [] as IGetAprAssigneeFounder[],
    [SIFARNICI_ROUTES.APR_ADDRESS_DATA]: [] as IGetAprAddressData[],
    [SIFARNICI_ROUTES.PRODUCT_CATEGORIES]: [] as IGetProductCategories[],
    [SIFARNICI_ROUTES.PTP2_REPORT_CUSTOMS]: [] as IGetPtp2ReportCustoms[],
    [SIFARNICI_ROUTES.PZV_CUSTOMS_AND_PRODUCERS]: [] as IGetPzvCustomsAndProducers[],
    [SIFARNICI_ROUTES.PZV_PRODUCTS]: [] as IGetPzvProducts[],
    [SIFARNICI_ROUTES.APR_COMPANY_STATUS]: [] as IGetAprCompanyStatus[],
    [SIFARNICI_ROUTES.APR_DATA_SOURCE]: [] as IGetAprDataSource[],
    [SIFARNICI_ROUTES.APR_LEGAL_FORM]: [] as IGetAprLegalForm[],
    [SIFARNICI_ROUTES.APR_TYPE_OF_SHAPE_OF_ORGANIZING]: [] as IGetAprTypeOfShapeOfOrganizing[],
    [SIFARNICI_ROUTES.APR_SIZE_OF_BUSINESS_ENTITY]: [] as IGetAprSizeOfBusinessEntity[],
    [SIFARNICI_ROUTES.APR_TYPE_ADDRESS]: [] as IGetAprTypeAddress[],
    [SIFARNICI_ROUTES.APR_TYPE_ASSIGNEE]: [] as IGetAprTypeAssignee[],
    [SIFARNICI_ROUTES.APR_TYPE_PERSONALITY]: [] as IGetAprTypePersonality[],
    [SIFARNICI_ROUTES.APR_TYPE_PERSONALITY_REPRESENTATIV]: [] as IGetAprTypePersonality[],
    [SIFARNICI_ROUTES.APR_TYPE_PERSONALITY_OF_LEGAL_ENTITY]: [] as IGetAprTypePersonality[],
    [SIFARNICI_ROUTES.ASSIGNEE_FUNCTION]: [] as IGetAprAssigneeFunction[],
    [SIFARNICI_ROUTES.AKTIVNOST_1]: [] as IGetActivity1[],
    [SIFARNICI_ROUTES.AKTIVNOST_2]: [] as IGetActivity2[],
    [SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_1]: [] as IGetPrivrednaDelatnost1[],
    [SIFARNICI_ROUTES.PRIVREDNA_DELATNOST_2]: [] as IGetPrivrednaDelatnost2[],
    [SIFARNICI_ROUTES.SOURCE_TYPE]: [] as IGetSourceType[],
    [SIFARNICI_ROUTES.TYPE_OF_RECIPIENT]: [] as IGetRecipientType[],
    [SIFARNICI_ROUTES.ACTIVITIES_OF_WASTE_OPERATORS]: [] as IGetActivitiesOfWasteOperators[],
    [SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VAZDUHA]: [] as IGetAir[],
    [SIFARNICI_ROUTES.PZV_TYPE_OF_PRODUCTS]: [] as IGetPzvTypeOfProducts[],
    [SIFARNICI_ROUTES.SLIV]: [] as ISliv[],
    [SIFARNICI_ROUTES.WATER_BODIES]: [] as IGetWaterBodies[],
  };

  constructor() {
    makeAutoObservable(this, {
      list: observable,
      filters: observable,
      sifarnik_type: observable,
      sifarniciListTable: observable,
      initialState: observable,
    });
  }

  setListTable = (value: any[]) => {
    this.sifarniciListTable = value;
  };

  handleListChange = (type: SIFARNICI_ROUTES, value: any[]) => {
    (this.list as Record<SIFARNICI_ROUTES, any>)[type] = value;
  };

  handleChange(key: string, value: any) {
    (this as any)[key] = value;
  }

  handleFiltersSifarnici(type: string, value: any) {
    this.filters = { ...this.filters, [type]: value };
  }

  getSifarniciIdMap(type: SIFARNICI_ROUTES) {
    return arrayToObject(this.list[type]);
  }

  async fetchSifarnikOptions(type: SIFARNICI_ROUTES, filter: any): Promise<IPaginatedApiResponse<any> | any> {
    const [err, res] = await to(sifaniciService.fetchSingleSifarnikList<IPaginatedApiResponse<any>>(type, filter));
    if (err || !res) return;
    runInAction(
      () =>
        ((this.list as Record<SIFARNICI_ROUTES, any>)[type] = [
          ...(this.list as Record<SIFARNICI_ROUTES, any>)[type],
          ...res.items,
        ])
    );
    return res as IPaginatedResponse;
  }

  fetchSifarnikListTable = async (pagination: Partial<IPagination>): Promise<IPaginatedApiResponse<any> | any> => {
    const [err, res] = await to(
      sifaniciService.fetchSingleSifarnikList<IPaginatedApiResponse<any>>(this.sifarnik_type, {
        ...this.filters,
        page: pagination.page,
        size: pagination.size,
        ...nrizService.pickFields(filtersStore.filters.sifarnici),
      })
    );

    if (err || !res) return;
    this.setListTable(res.items);
    return res;
  };

  postSifarnikInList = (payload: any) => {
    modalStore.removeAllModals();
    handleSuccessNotify();
    const newList = nrizService.addToList(this.sifarniciListTable, payload);
    this.handleChange('sifarniciListTable', newList);
  };

  putSifarnikInList = (payload: any) => {
    modalStore.removeAllModals();
    handleSuccessNotify();

    const newList = nrizService.changeItemInList(this.sifarniciListTable, payload);
    this.handleChange('sifarniciListTable', newList);

    removeSearchParamsFromURL();
  };

  deleteSifarnikInList = (id: string) => {
    modalStore.removeAllModals();
    handleSuccessNotify();

    const newList = nrizService.removeFromList(this.sifarniciListTable, id);
    this.handleChange('sifarniciListTable', newList);
  };

  // /////////////////////////
  fetchSingleSifarnik = async (id: string, dateArray?: IDateArrayPropsData[]) => {
    const [err, res] = await to(sifaniciService.fetchSingleSifarnik(this.sifarnik_type, id));
    if (err || !res) return;
    const newData = dateArray?.length ? sifaniciService.fetchMakeDateAndYearObject(dateArray, res) : res;
    this.handleChange('initialState', newData);
  };

  postSingleSifarnik = async (payload: any, dateArray?: IDateArrayPropsData[]) => {
    let newPayload = dateArray?.length ? sifaniciService.makeDateAndYearString(dateArray, payload) : payload;
    newPayload = { ...newPayload, is_active: true };
    const [err, res] = await to(sifaniciService.postSingleSifarnik(this.sifarnik_type, newPayload));
    if (err || !res) return;
    newPayload = { ...newPayload, id: res.id };
    this.postSifarnikInList(newPayload);
  };

  putSingleSifarnik = async (payload: any, dateArray?: IDateArrayPropsData[]) => {
    const date = dateArray ? dateArray : [];
    const newData = nrizService.setEmptyValuesToNull(sifaniciService.makeDateAndYearString(date, payload));
    const [err, res] = await to(sifaniciService.putSingleSifarnik(this.sifarnik_type, newData));
    if (err || !res) return;
    this.putSifarnikInList(newData);
  };

  resetState = (initialState: any) => {
    this.handleChange('initialState', initialState);
  };

  async fetchSifarnikOptionData(type: SIFARNICI_ROUTES, filter: any): Promise<IPaginatedApiResponse<any> | any> {
    const [err, res] = await to(
      sifaniciService.fetchSingleSifarnikList<IPaginatedApiResponse<any>>(type, nrizService.pickFields(filter))
    );
    if (err || !res) return;
    return res;
  }
}

export const sifarniciStore = new SifarniciStore();
