import { ColumnType } from 'antd/es/table';
import { IPagination } from 'typescript';
import { IPaginatedResponse } from 'typescript/NrizTypes';

export enum RegistriEnum {
  Otv = 'otv',
  Dozvole = 'dozvole',
  Lcp = 'lcp',
}

export type RegistriOptionType = {
  value: RegistriEnum;
  label: string;
};

//todo:tipiziraj
export type RegistriColumns = {
  [key in RegistriEnum]: ColumnType<any>[];
};

export type RegistarKeyProps = {
  registar: RegistriEnum;
};

export interface IBaseRegistriStore<Registar = any> {
  loadData: (id: string) => void;
  fetchDataList?: (pagination: IPagination) => Promise<IPaginatedResponse<Registar>>;
  postData: (payload: Registar) => void;
  putData: (payload: Registar) => void;
  resetStates: () => void;
  initialState: any;
  submitData: (payload: Registar) => void;
  deleteData: (id: string) => void;
  handleValidation?: () => any;
}

// export type RegistriConfigType = {
//   [key in RegistriEnum]: {
//     key: RegistriEnum;
//     label: string;
//     route: string;
//     store: IBaseRegistriStore;
//     apiRoute: string;
//     tableData: {
//       columns: RegistriColumns;
//       fetchData:
//     }
//   }
// }

export enum RegistriLists {
  OTV = 1,
}
