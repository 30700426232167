import { IGetCompany } from 'modules/company/company.types';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetApr } from 'modules/sifarnici/store/apr/apr.types';
import { IGetCountryCode } from 'modules/sifarnici/store/countryCode/countryCode.types';
import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetMethodBasisCode } from 'modules/sifarnici/store/methodBasisCode/methodBasisCode.types';
import { IGetNList } from 'modules/sifarnici/store/nList/nList.types';
import { IGetQList } from 'modules/sifarnici/store/qList/qList.types';
import { IGetTypeOfWaste } from 'modules/sifarnici/store/typeOfWaste/typeOfWaste.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { IGetWasteCharacter } from 'modules/sifarnici/store/wasteCharacter/wasteCharacter.types';
import { IGetWasteStateCategory } from 'modules/sifarnici/store/wasteStateCategory/wasteStateCategory.types';
import { IGetYList } from 'modules/sifarnici/store/yList/yList.types';

export interface IPostObrazac5 {
  godina: number | string;
  preduzece_id: string;
  obrazac_5: boolean;
  lriz_obrazac_5: boolean;
  obrazac_gio1: boolean;
  postrojenje_id: string;
  indeksni_broj_otpada_id: string;
  napomena: string;
  mesto_nastanka_otpada: string;
  koordinate_long: number | string;
  koordinate_lat: number | string;
  vrsta_otpada_id: string;
  opis_otpada: string;
  karakter_otpada_id: string;
  karakter_otpada_q_lista_id: string;
  broj_izvestaja: number | string;
  datum_izdavanja: string;
  oznaka_otpada_n_lista_id: string;
  oznaka_otpada_n_lista2_id: string;
  oznaka_otpada_n_lista3_id: string;
  oznaka_otpada_y_lista_id: string;
  oznaka_otpada_y_lista2_id: string;
  oznaka_otpada_y_lista3_id: string;
  fizicko_stanje_otpada_id: string;
  godisnja_kolicina_proizvedenog_otpada: number | null;
  stanje_skladista_1_januar: number | null;
  stanje_skladista_31_decembar: number | null;
  nacin_odredjivanja_kolicina_otpada_id: string;
  komponente: IPostKomponenteObrazac5[];
  upravljanje_otpadom: IPostUpravljanjeObrazac5[];
}

export interface IGetObrazac5 extends IPostObrazac5 {
  id: string;
  preduzece: IGetCompany;
  postrojenje: IGetFacility;
  indeksni_broj_otpada: IGetWasteCatalogue;
  vrsta_otpada: IGetTypeOfWaste;
  karakter_otpada: IGetWasteCharacter;
  karakter_otpada_q_lista: IGetQList;
  oznaka_otpada_n_lista: IGetNList;
  oznaka_otpada_n_lista2: IGetNList;
  oznaka_otpada_n_lista3: IGetNList;
  oznaka_otpada_y_lista: IGetYList;
  oznaka_otpada_y_lista2: IGetYList;
  oznaka_otpada_y_lista3: IGetYList;
  fizicko_stanje_otpada: IGetWasteStateCategory;
  nacin_odredjivanja_kolicina_otpada: IGetMethodBasisCode;
}

export interface IPostKomponenteObrazac5 {
  cas_broj: number | string;
  hemijski_naziv: string;
  sadrzaj_opasne_materije: number | string;
}

export interface IGetKomponenteObrazac5 extends IPostKomponenteObrazac5 {
  komponente: IPostKomponenteObrazac5;
  id: string;
}

export interface IPostUpravljanjeObrazac5 {
  naziv_prevoznika_otpada: string;
  broj_dozvole_za_prevoz_opasnih_materija: string;
  tip_vozila: string;
  nacin_upravljanja: string;
  naziv_postrojenja_za_skladiste_otpada_id: string;
  broj_dozvole_za_skladiste_otpada: number | string;
  kolicina_isporucenog_otpada_za_skladiste_otpada: number | null;
  d_ili_r_oznaka_za_skladiste_otpada_id: string;
  naziv_postrojenja_za_tretman_otpada_odlaganje_id: string;
  broj_dozvole_odlaganja: number | string;
  kolicina_isporucenog_otpada_za_odlaganje: number | null;
  d_oznaka_za_odlaganje_id: string;
  naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje_id: string;
  broj_dozvole_ponovno_iskoriscenje: string;
  kolicina_isporucenog_otpada_za_ponovno_iskoriscenje: number | null;
  r_oznaka_za_ponovno_iskoriscenje_id: string;
  zemlja_izvoza_id: string;
  kolicina_izvezenog_otpada: number | null;
  d_ili_r_oznaka_za_izvoz_otpada_id: string;
  naziv_postrojenja_za_tretman_otpada_izvoz: string;
  ulica_postrojenja_za_tretman_otpada_izvoz: string;
  kucni_broj_postrojenja_za_tretman_otpada_izvoz: number | string;
  mesto_postrojenja_za_tretman_otpada_izvoz: string;
  postanski_broj_postrojenja_za_tretman_otpada_izvoz: number | string;
}

export interface IGetUpravljanjeObrazac5 extends IPostUpravljanjeObrazac5 {
  id: string;
  naziv_postrojenja_za_skladiste_otpada: IGetApr;
  d_ili_r_oznaka_za_skladiste_otpada: IGetDRList;
  naziv_postrojenja_za_tretman_otpada_odlaganje: IGetApr;
  naziv_postrojenja_za_tretman_otpada_ponovno_iskoriscenje: IGetApr;
  zemlja_izvoza: IGetCountryCode;
  d_oznaka_za_odlaganje: IGetDRList;
  r_oznaka_za_ponovno_iskoriscenje: IGetDRList;
  d_ili_r_oznaka_za_izvoz_otpada: IGetDRList;
}

export enum NacinUpravljanjaEnum {
  SKLADISTENJE = 'skladistenje',
  ODLAGANJE = 'odlaganje',
  PONOVNO = 'ponovno',
  IZVOZ = 'izvoz',
}
