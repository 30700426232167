import { Col } from 'antd';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { MORE_THAN_OR_EQUAL_0_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { IGetObrazac3AnalizaRecipijenata } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const NewObrazac3AnalizaRecipijentaForm: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const defaultPollutantCodeOption: ISifarnikInitial | undefined = obrazac3Store.analiza_recipijenata_form
    .naziv_zagadjujuce_materije_id
    ? {
        label: (obrazac3Store.analiza_recipijenata_form as IGetObrazac3AnalizaRecipijenata).naziv_zagadjujuce_materije
          .name_sr,
        value: obrazac3Store.analiza_recipijenata_form.naziv_zagadjujuce_materije_id,
      }
    : undefined;

  const defaultUnitCodeOption: ISifarnikInitial | undefined = obrazac3Store.analiza_recipijenata_form.jedinica_mere_id
    ? {
        label: (obrazac3Store.analiza_recipijenata_form as IGetObrazac3AnalizaRecipijenata).jedinica_mere.code,
        value: obrazac3Store.analiza_recipijenata_form.jedinica_mere_id,
      }
    : undefined;

  const defaultMethodBasisCodeOption: ISifarnikInitial | undefined = obrazac3Store.analiza_recipijenata_form
    .nacin_odredjivanja_id
    ? {
        label: (obrazac3Store.analiza_recipijenata_form as IGetObrazac3AnalizaRecipijenata).nacin_odredjivanja.name_sr,
        value: obrazac3Store.analiza_recipijenata_form.nacin_odredjivanja_id,
      }
    : undefined;

  const defaultMethodTypesOption: ISifarnikInitial | undefined = useMemo(() => {
    return obrazac3Store.analiza_recipijenata_form.metod_odredjivanja_id
      ? {
          label: (obrazac3Store.analiza_recipijenata_form as IGetObrazac3AnalizaRecipijenata).metod_odredjivanja
            .name_sr,
          value: obrazac3Store.analiza_recipijenata_form.metod_odredjivanja_id,
        }
      : undefined;
  }, [obrazac3Store.analiza_recipijenata_form]);

  const handleMethodBasisCode = (value: string) => {
    form.setFieldValue('metod_odredjivanja_id', '');
    obrazac3Store.handleChange('nacin_odredjivanja_id', value);
  };

  return (
    <Col span={24}>
      <h4>Zagađujuca materija</h4>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'naziv_zagadjujuce_materije_id'}
        fetchOptinsList={obrazac3Store.fetchPollutantCode}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'naziv_zagadjujuce_materije_id'}
        label={t('OBRASCI.OBRAZAC3.NAZIV_ZAGADJUJUCE_MATERIJE')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                obrazac3Store.analiza_recipijenata_form.naziv_zagadjujuce_materije_id !== value &&
                obrazac3Store.initialState.analiza_recipijenta.some(item => {
                  return value === item.naziv_zagadjujuce_materije_id;
                })
              ) {
                return Promise.reject(new Error(t('VALIDATION.ALREADY_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
        defaultOption={defaultPollutantCodeOption}
        placeholder={''}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'jedinica_mere_id'}
        fetchOptinsList={obrazac3Store.fetchUnitCode}
        labelOptionAccessor="code"
        valueOptionAccessor="id"
        inputName={'jedinica_mere_id'}
        label={t('OBRASCI.OBRAZAC3.JEDINICA_MERE')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultUnitCodeOption}
        placeholder={''}
      />

      <h4>Analiza recipijenata</h4>
      <FormInputNumber
        name="pre_ispusta_otpadne_vode"
        label={t('OBRASCI.OBRAZAC3.PRE_ISPUSTA')}
        placeholder={''}
        inputName={'pre_ispusta_otpadne_vode'}
        colon={true}
        rules={[MORE_THAN_OR_EQUAL_0_RULE, REQUIRED_FIELD_RULE(true)]}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      />
      <FormInputNumber
        name="posle_ispusta_otpadne_vode"
        label={t('OBRASCI.OBRAZAC3.POSLE_ISPUSTA')}
        placeholder={''}
        inputName={'posle_ispusta_otpadne_vode'}
        colon={true}
        rules={[MORE_THAN_OR_EQUAL_0_RULE, REQUIRED_FIELD_RULE(true)]}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'nacin_odredjivanja_id'}
        fetchOptinsList={obrazac3Store.fetchMethodBasisCode}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'nacin_odredjivanja_id'}
        label={t('OBRASCI.OBRAZAC3.NACIN_ODREDJIVANJA')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={[REQUIRED_FIELD_RULE(true)]}
        onChange={handleMethodBasisCode}
        defaultOption={defaultMethodBasisCodeOption}
        placeholder={''}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'metod_odredjivanja_id'}
        disabled={!obrazac3Store.nacin_odredjivanja_id}
        fetchOptinsList={obrazac3Store.fetchMethodTypes}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'metod_odredjivanja_id'}
        label={t('OBRASCI.OBRAZAC3.METOD_ODREDJIVANJA')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        resetOption={obrazac3Store.nacin_odredjivanja_id}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultMethodTypesOption}
        placeholder={''}
      />
    </Col>
  );
});
