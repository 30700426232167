import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TypeOfDischargedWastewater.module.scss';
import { Col, Row } from 'antd';
import { waterStore } from 'modules/sourcesOfPollution/water/water.store';

export const TypeOfDischargedWastewater: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Row className={`${styles.type_of_discharged} `}>
      <Col span={10}>
        <span className={styles.warning}>*</span>
        <span className={styles.text}>{t('POLLUTION.WATER.TYPE_OF_DISCHARGED_WASTEWATER')}</span>

        {waterStore.getWaterShowTypeOfDischargedError && (
          <div className={styles.warning}>{t('VALIDATION.SELECT_A_FIELD')}</div>
        )}
      </Col>
      <Col
        span={14}
        className={`${styles.type_options} ${waterStore.getWaterShowTypeOfDischargedError ? styles.error : ''}`}
      >
        <FormCheckbox
          onCheckBoxChange={e => waterStore.changeWaterTypeOfDichargedList('sanitarna', e.target.checked)}
          labelCol={{ span: 23 }}
          name={'sanitarna'}
          label={t('POLLUTION.WATER.SANITARY')}
        />
        <FormCheckbox
          onCheckBoxChange={e => waterStore.changeWaterTypeOfDichargedList('tehnoloska', e.target.checked)}
          labelCol={{ span: 23 }}
          name={'tehnoloska'}
          label={t('POLLUTION.WATER.TECHNOLOGICAL')}
        />
        <FormCheckbox
          onCheckBoxChange={e => waterStore.changeWaterTypeOfDichargedList('rashladna', e.target.checked)}
          labelCol={{ span: 23 }}
          name={'rashladna'}
          label={t('POLLUTION.WATER.COOLING')}
        />
        <FormCheckbox
          onCheckBoxChange={e => waterStore.changeWaterTypeOfDichargedList('atmosferska', e.target.checked)}
          labelCol={{ span: 23 }}
          name={'atmosferska'}
          label={t('POLLUTION.WATER.ATMOSPHERIC')}
        />
      </Col>
    </Row>
  );
});
