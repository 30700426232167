import { Modal } from '../../Modal/Modal';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { modalStore } from '../../../store';
// import { NewCompany } from 'components/NewForms';

import styles from './CompanyModal.module.scss';

export const CompanyModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Modal
      name="company-registration-modal"
      open={modalStore.getCurrentOpenedModal.includes('company-modal')}
      className={`.and-modal-wrap .ant-modal ${styles.company_modal}`}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => modalStore.removeAllModals()}
      title={t('COMPANY.TITLE')}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      width={'80%'}
    >
      {/* <NewCompany readOnly={false} formName={companyStore.getCompanyFormData ? 'company-change' : 'comany-new'} /> */}
    </Modal>
  );
});
