import { Row } from 'antd';
import Form, { useForm } from 'antd/es/form/Form';
import { Button } from 'components/Button/Button';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { Modal } from 'components/Modal/Modal';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { userStore } from 'modules/user/user.store';
import { IGetUser } from 'modules/user/user.types';
import React, { useEffect } from 'react';
import { modalStore } from 'store';

export const ChangeCompanyOwnerModal: React.FC = observer(() => {
  const [form] = useForm();

  const imePrezimeKorisnika = (options: IGetUser[]) => {
    return options
      .filter((user: IGetUser) => user.main_company_user === false)
      .map((user: IGetUser) => {
        return {
          label: `${user.ime} ${user.prezime}`,
          value: user.id,
        };
      });
  };

  useEffect(() => {
    return () => form.resetFields();
  }, []);

  return (
    <Modal
      name="change-company-owner"
      open={modalStore.getCurrentOpenedModal.includes('change-company-owner')}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => {
        modalStore.removeAllModals();
      }}
      title={'Promeni vlasnika preduzeća'}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      width={'50%'}
    >
      <Form
        form={form}
        initialValues={{ korisnik_id: '' }}
        name={'change-company-owner'}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={(value: { korisnik_id: string }) => {
          userStore.changeCompanyOwner(value);
        }}
        labelAlign="left"
      >
        <FormSearchInfiniteScroll
          formName={'korisnik_id'}
          labelOptionAccessor={'ime'}
          valueOptionAccessor={'id'}
          fetchOptinsList={userStore.fetchUserCompanyList}
          placeholder={''}
          label="Novi korisnik preduzeća"
          optionsMaker={imePrezimeKorisnika}
          showSearch={true}
          rules={[REQUIRED_FIELD_RULE(true)]}
        />
        <Row justify={'end'}>
          <Button name="izmeni-vlasnika" htmlType="submit">
            Izmeni
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});
