// import { Checkbox, FormInstance } from 'antd';
import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
// import { SwitchSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { NAME_FORM, DESCRIPTION_FORM, DESCRIPTION_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, DESCRIPTION_TABLE, DESCRIPTION_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IGetNList, IPostNList } from './nList.types';

export const N_LIST_INITIAL_STATE: IPostNList = {
  name: '',
  description: '',
  description_sr: '',
  is_active: true,
  // v2: false,
};

// todo: proveriti polje v2

export const N_LIST_TABLE_DATA: ColumnsType<IGetNList> = [
  { ...NAME_TABLE, width: '100px' },
  DESCRIPTION_TABLE,
  DESCRIPTION_SR_TABLE,
  // {
  //   title: 'V2',
  //   width: 100,
  //   dataIndex: 'v2',
  //   key: 'v2',
  //   render: (_: any, record: IGetNList) => <Checkbox checked={!!record.v2} disabled={true} />,
  // },
];
export const N_LIST_FORM_OPTIONS = (_: FormInstance<IGetNList>) => {
  return [
    NAME_FORM,
    DESCRIPTION_FORM,
    DESCRIPTION_SR_FORM,
    // {
    //   type_of_form: 'switch',
    //   name: 'v2',
    //   label: 'V2',
    // } as SwitchSifarniciFormProps,
  ];
};
