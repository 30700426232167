import { Modal } from 'components/Modal/Modal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import styles from './ActivityList1Modal.module.scss';
import { NewActivity1Form } from 'components/NewForms/NewActivityForms/NewActivity1Form/NewActivity1Form';
import { activityInitialState } from 'modules/activityList1/activityList1.constants';
import { activityList1Store } from 'modules/activityList1/activityList1.store';

export const ActivityList1Modal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Modal
      name={`activity-list-modal-1`}
      open={modalStore.getCurrentOpenedModal.includes(`activity-list-modal-1`)}
      className={`${styles.activity_modal}`}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => {
        activityList1Store.handleChange('activity', activityInitialState);
        activityList1Store.handleChange('privredna_delatnost_id', '');
        modalStore.removeAllModals();
      }}
      title={t('ACTIVITIES.NEW_ACTIVITY_TITLE')}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      width={'70%'}
    >
      <NewActivity1Form />
    </Modal>
  );
});
