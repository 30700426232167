import { Col, Form } from 'antd';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { MORE_OR_EQUILE_0_IS_REQUIRED, MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { IGetObrazac3AnalizaOtpadneVode } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const NewObrazac3AnalizaOtpadneVodeForm: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const defaultPollutantCodeOption: ISifarnikInitial | undefined = obrazac3Store.otpadne_vode_form
    .naziv_zagadjujuce_materije_id
    ? {
        label: (obrazac3Store.otpadne_vode_form as IGetObrazac3AnalizaOtpadneVode).naziv_zagadjujuce_materije.name_sr,
        value: obrazac3Store.otpadne_vode_form.naziv_zagadjujuce_materije_id,
      }
    : undefined;

  const defaultMethodBasisCodeOption: ISifarnikInitial | undefined = obrazac3Store.otpadne_vode_form
    .nacin_odredjivanja_id
    ? {
        label: (obrazac3Store.otpadne_vode_form as IGetObrazac3AnalizaOtpadneVode).nacin_odredjivanja.name_sr,
        value: obrazac3Store.otpadne_vode_form.nacin_odredjivanja_id,
      }
    : undefined;

  const defaultMethodTypesOption: ISifarnikInitial | undefined = useMemo(() => {
    return obrazac3Store.otpadne_vode_form.metod_odredjivanja_id
      ? {
          label: (obrazac3Store.otpadne_vode_form as IGetObrazac3AnalizaOtpadneVode).metod_odredjivanja.name_sr,
          value: obrazac3Store.otpadne_vode_form.metod_odredjivanja_id,
        }
      : undefined;
  }, [obrazac3Store.otpadne_vode_form]);

  const handleMethodBasisCode = (value: string) => {
    form.setFieldValue('metod_odredjivanja_id', '');
    obrazac3Store.handleChange('nacin_odredjivanja_id', value);
  };

  return (
    <Col span={24}>
      <h4>Zagađujuca materija</h4>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'naziv_zagadjujuce_materije_id'}
        fetchOptinsList={obrazac3Store.fetchPollutantCode}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'naziv_zagadjujuce_materije_id'}
        label={t('OBRASCI.OBRAZAC3.NAZIV_ZAGADJUJUCE_MATERIJE')}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                obrazac3Store.otpadne_vode_form.naziv_zagadjujuce_materije_id !== value &&
                obrazac3Store.initialState.analiza_otpadne_vode.some(item => {
                  return value === item.naziv_zagadjujuce_materije_id;
                })
              ) {
                return Promise.reject(new Error(t('VALIDATION.ALREADY_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
        defaultOption={defaultPollutantCodeOption}
        placeholder={''}
      />
      <FormInputNumber
        name="srednja_godisnja_izmerena_vrednost"
        label={t('OBRASCI.OBRAZAC3.SREDNJA_GODISNJA_IZMERENA')}
        placeholder={''}
        inputName={'srednja_godisnja_izmerena_vrednost'}
        colon={true}
        rules={MORE_OR_EQUILE_0_IS_REQUIRED}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
      />

      <h4>Emitovane količine u toku NORMALNOG rada postrojenja</h4>

      <FormInputNumber
        name="emitovane_kolicine"
        label={t('OBRASCI.OBRAZAC3.EMITOVANE_KOLICINE')}
        placeholder={''}
        inputName={'emitovane_kolicine'}
        colon={true}
        rules={MORE_OR_EQUILE_0_IS_REQUIRED}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
      />

      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'nacin_odredjivanja_id'}
        fetchOptinsList={obrazac3Store.fetchMethodBasisCode}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'nacin_odredjivanja_id'}
        label={t('OBRASCI.OBRAZAC3.NACIN_ODREDJIVANJA')}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
        rules={[REQUIRED_FIELD_RULE(true)]}
        onChange={handleMethodBasisCode}
        defaultOption={defaultMethodBasisCodeOption}
        placeholder={''}
      />

      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'metod_odredjivanja_id'}
        disabled={!obrazac3Store.nacin_odredjivanja_id}
        fetchOptinsList={obrazac3Store.fetchMethodTypes}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'metod_odredjivanja_id'}
        label={t('OBRASCI.OBRAZAC3.METOD_ODREDJIVANJA')}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
        rules={[REQUIRED_FIELD_RULE(true)]}
        resetOption={Form.useWatch('nacin_odredjivanja_id', form)}
        defaultOption={defaultMethodTypesOption}
        placeholder={''}
      />
      {/* todo: prevod */}
      <h4>Emitovane količine u AKCIDENTNIM situacijama</h4>
      <FormInputNumber
        name="emitovane_kolicine_u_akcidentnim_situacijama"
        label={t('OBRASCI.OBRAZAC3.EMITOVANE_AKCIDENTNE_GOD')}
        placeholder={''}
        inputName={'emitovane_kolicine_u_akcidentnim_situacijama'}
        colon={true}
        rules={MORE_THEN_0}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
      />
    </Col>
  );
});
