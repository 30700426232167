import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { dep1Store } from 'modules/obrasci/store/dep1/dep1.store';
import { IObrazacDep1Mesto } from 'modules/obrasci/store/dep1/dep1.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const TableNaseljeDep1: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IObrazacDep1Mesto> = [
    {
      title: `${t('COMPANY.LOCATION')}`,
      width: 100,
      dataIndex: ['mesto', 'display_name'],
      key: 'display_name',
    },

    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IObrazacDep1Mesto) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-mesta'}
            onClick={() => {
              dep1Store.handleChange('mesto_form', record);
              modalStore.changeModalName('naselje-modal');
            }}
          />
        );
      },
    },
  ];
  return (
    <TableData name="naselje-dep1" columns={baseColumns} dataSource={[...dep1Store.initialState.obrazac_dep1_mesta]} />
  );
});
