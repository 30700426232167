import to from 'await-to-js';
import { action, computed, makeObservable, observable } from 'mobx';
import { IPagination } from 'typescript';
import { formStore } from '../../store/FormStore';
import { nrizService } from 'modules/nriz/nriz.service';
import { companyStore } from '../company/company.store';
import { modalStore } from '../../store/ModalStore';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { handleSuccessNotify } from 'utils/succesHandling';

import { IGetCompany } from 'modules/company/company.types';
import { IGetUnsanitaryLandfill, IPostUnsanitaryLandfill } from 'modules/unsanitarylandfill/unsanitarylandfill.types';
import { unsanitaryLandfillInitialState } from './unsanitarylandfill.constants';
import { unsanitaryLandfillService } from './unsanitarylandfill.service';
import { ResponseMesto } from 'modules/sifarnici/store/mesto/mesto.types';
import { ITablePagination } from 'components/TableData/TableData';

class UnsanitaryLandfillStore {
  constructor() {
    makeObservable(this, {
      unsanitaryLandfill: observable,
      unsanitaryLandfillList: observable,

      getUnsanitaryLandfill: computed,
      getUnsanitaryLandfillList: computed,

      setUnsanitaryLandfill: action,
      setUnsanitaryLandfillList: action,

      fetchUnsanitaryLandfillList: action,
      postUnsanitaryLandfill: action,
      putUnsanitaryLandfill: action,
    });
  }

  unsanitaryLandfill: IPostUnsanitaryLandfill | IGetUnsanitaryLandfill = unsanitaryLandfillInitialState;
  unsanitaryLandfillList: IGetUnsanitaryLandfill[] = [];

  get getUnsanitaryLandfill() {
    return this.unsanitaryLandfill;
  }

  get getUnsanitaryLandfillList() {
    return this.unsanitaryLandfillList;
  }

  fetchUnsanitaryLandfillList = async (pagination: ITablePagination) => {
    const [err, res] = await to(
      unsanitaryLandfillService.fetchUnsanitaryLandfillList(
        nrizService.pickFields({
          preduzece_id: (companyStore.company as IGetCompany).id,
          page: pagination.page,
          size: pagination.size,
        })
      )
    );
    if (err || !res) return;
    this.setUnsanitaryLandfillList(nrizService.addKey(res.items));
    return res;
  };

  setUnsanitaryLandfill = (values: IPostUnsanitaryLandfill | IGetUnsanitaryLandfill) => {
    this.unsanitaryLandfill = values;
  };

  setUnsanitaryLandfillList = (values: IGetUnsanitaryLandfill[]) => {
    this.unsanitaryLandfillList = values;
  };

  changeUnsanitaryLandfill = (type: string, value: string) => {
    this.unsanitaryLandfill = { ...this.unsanitaryLandfill, [type]: value };
  };

  postUnsanitaryLandfill = async (unsanitaryLandfill: IPostUnsanitaryLandfill) => {
    const newObject = { ...unsanitaryLandfill, preduzece_id: (companyStore.getCompany as IGetCompany).id };
    const [err, res] = await to(unsanitaryLandfillService.postUnsanitaryLandfill(nrizService.pickFields(newObject)));
    if (err || !res) return;

    const sifarnikMesto = nrizService.addSifarnikInObject({
      list: sifarniciStore.list[SIFARNICI_ROUTES.MESTO],
      name: 'mesto',
      id: newObject.mesto_id,
    });

    const newObjectWithSifarnik = {
      id: res.id,
      ...newObject,
      is_active: true,
      ...sifarnikMesto,
    } as unknown as IGetUnsanitaryLandfill;

    this.setUnsanitaryLandfillList([newObjectWithSifarnik, ...this.unsanitaryLandfillList]);
    handleSuccessNotify();
    modalStore.removeAllModals();
  };

  putUnsanitaryLandfill = async (unsanitaryLandfill: IGetUnsanitaryLandfill) => {
    const [err, res] = await to(
      unsanitaryLandfillService.putUnsanitaryLandfill(nrizService.pickFields(unsanitaryLandfill))
    );
    if (err || !res) return;
    let sifarnikMesto = {};
    if (this.unsanitaryLandfill.mesto_id) {
      sifarnikMesto = nrizService.addSifarnikInObject({
        exId: this.unsanitaryLandfill.mesto_id,
        exObj: (this.unsanitaryLandfill as IGetUnsanitaryLandfill).mesto,
        list: sifarniciStore.list[SIFARNICI_ROUTES.MESTO],
        name: 'mesto',
        id: unsanitaryLandfill.mesto_id,
      });
    }
    const newObj = { ...unsanitaryLandfill, ...sifarnikMesto, is_active: true };
    this.setUnsanitaryLandfill(newObj);
    this.setUnsanitaryLandfillList(nrizService.changeItemInList(this.unsanitaryLandfillList, newObj));
    modalStore.removeAllModals();
    handleSuccessNotify();
  };

  // za sada nema brisanja nesanitarne deponije, ako bude potrebe otkomentarisati

  // deleteUnsanitaryLandfill = async (id: string) => {
  //   const [err, res] = await to(unsanitaryLandfillService.deleteUnsanitaryLandfill(id));
  //   if (err || !res) return;
  //   this.setUnsanitaryLandfillList(nrizService.removeFromList(this.unsanitaryLandfillList, id));
  //   this.setUnsanitaryLandfill(unsanitaryLandfillInitialState);
  //   modalStore.removeAllModals();
  //   handleSuccessNotify();
  // };

  fetchMesto = async (pagination: IPagination, search: string): Promise<ResponseMesto> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.MESTO, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      location_code__neq: 999999,
      order_by: '+display_name',
    });
    return response;
  };

  submitUnsanitaryLandfill = (unsanitaryLandfill: IPostUnsanitaryLandfill) => {
    const newPayload = {
      ...unsanitaryLandfill,
      godina_pocetka_deponovanja:
        unsanitaryLandfill.period_deponovanja && unsanitaryLandfill.period_deponovanja[0].year(),
      godina_zavrsetka_deponovanja:
        unsanitaryLandfill.period_deponovanja && unsanitaryLandfill.period_deponovanja[1].year(),
    };
    if (newPayload) {
      if (formStore.getCrudButtonType === 'submit') {
        return this.postUnsanitaryLandfill(newPayload);
      }
      const newObject = {
        id: (this.unsanitaryLandfill as IGetUnsanitaryLandfill).id,
        ...newPayload,
      } as IGetUnsanitaryLandfill;
      if (formStore.getCrudButtonType === 'change') {
        return this.putUnsanitaryLandfill(newObject);
      }
      // if (formStore.getCrudButtonType === 'delete') {
      //   if (newObject.id) return this.deleteUnsanitaryLandfill(newObject.id);
      // }
    }
  };

  aktivirajNeasnitarnuDeponiju = async () => {
    const [err, res] = await to(
      unsanitaryLandfillService.aktivirajNeasnitarnuDeponiju((this.unsanitaryLandfill as IGetUnsanitaryLandfill).id)
    );
    if (err || !res) return;
    const newObj = { ...this.unsanitaryLandfill, is_active: true };
    this.setUnsanitaryLandfill(newObj);
    this.setUnsanitaryLandfillList(nrizService.changeItemInList(this.unsanitaryLandfillList, newObj));
    handleSuccessNotify();
  };

  deaktivirajNeasnitarnuDeponiju = async () => {
    const [err, res] = await to(
      unsanitaryLandfillService.deaktivirajNeasnitarnuDeponiju((this.unsanitaryLandfill as IGetUnsanitaryLandfill).id)
    );
    if (err || !res) return;
    const newObj = { ...this.unsanitaryLandfill, is_active: false };

    this.setUnsanitaryLandfill(newObj);
    this.setUnsanitaryLandfillList(nrizService.changeItemInList(this.unsanitaryLandfillList, newObj));
    handleSuccessNotify();
  };
}

export const unsanitaryLandfillStore = new UnsanitaryLandfillStore();
