import { ITableColumns, TIPOVI_OBRAZACA } from '../obrasci/obrasci.types';
import { ColumnsType } from 'antd/es/table';
import { t } from 'i18next';
import { ROUTES } from 'modules/nriz/nriz.constants';

// nek bleji, mozda zatreba

export const OBRAZAC_TYPE_IZVESTAJ_URL_MAP: { [key: string]: string } = {
  [TIPOVI_OBRAZACA.PTP2]: `/${ROUTES.OBRAZAC}/ptp-2/prijava`,
  [TIPOVI_OBRAZACA.DEP2]: `/${ROUTES.OBRAZAC}/dep-2/prijava`,
  [TIPOVI_OBRAZACA.PZV1]: `/${ROUTES.OBRAZAC}/pzv-1/prijava`,
  [TIPOVI_OBRAZACA.KOM1]: `/${ROUTES.OBRAZAC}/kom-1/prijava`,
  [TIPOVI_OBRAZACA.OBRAZAC_1]: `/${ROUTES.OBRAZAC}/podaci-o-postrojenju/prijava`,
  [TIPOVI_OBRAZACA.OBRAZAC_3]: `/${ROUTES.OBRAZAC}/obrazac3/prijava`,
  [TIPOVI_OBRAZACA.OBRAZAC_4]: `/${ROUTES.OBRAZAC}/emisije-u-tlo/prijava`,
  [TIPOVI_OBRAZACA.OBRAZAC_5]: `/${ROUTES.OBRAZAC}/obrazac5/prijava`,
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1]: `/${ROUTES.OBRAZAC}/lriz1/prijava`,
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2]: `/${ROUTES.OBRAZAC}/lriz2/prijava`,
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3]: `/${ROUTES.OBRAZAC}/lriz3/prijava`,
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4]: `/${ROUTES.OBRAZAC}/lriz4/prijava`,
  [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5]: `/${ROUTES.OBRAZAC}/lriz5/prijava`,
  [TIPOVI_OBRAZACA.DEP1]: `/${ROUTES.OBRAZAC}/dep-1/prijava`,
  [TIPOVI_OBRAZACA.AAO1]: `/${ROUTES.OBRAZAC}/aao-1/prijava`,
  [TIPOVI_OBRAZACA.AAO2]: `/${ROUTES.OBRAZAC}/aao-2/prijava`,
  [TIPOVI_OBRAZACA.GIO1]: `/${ROUTES.OBRAZAC}/gio-1/prijava`,
  [TIPOVI_OBRAZACA.GIO2]: `/${ROUTES.OBRAZAC}/gio-2/prijava`,
  [TIPOVI_OBRAZACA.GIO3]: `/${ROUTES.OBRAZAC}/gio-3/prijava`,
  [TIPOVI_OBRAZACA.GIO4]: `/${ROUTES.OBRAZAC}/gio-4/prijava`,
  [TIPOVI_OBRAZACA.GIO5]: `/${ROUTES.OBRAZAC}/gio-5/prijava`,
  [TIPOVI_OBRAZACA.DKO]: `/${ROUTES.OBRAZAC}/dko/prijava`,
  [TIPOVI_OBRAZACA.GVE]: `/${ROUTES.OBRAZAC}/gve/prijava`,
  [TIPOVI_OBRAZACA.DEO6_GIO6]: `/${ROUTES.OBRAZAC}/deo6/prijava`,
  [TIPOVI_OBRAZACA.OBRAZAC_2]: `/${ROUTES.OBRAZAC}/obrazac2/prijava`,
};

export const OBRAZAC_TYPE_IZVESTAJ_API_URL_MAP_VALID: { [key: string]: string } = {
  // [TIPOVI_OBRAZACA.PTP2]: 'ptp2/prijava',
  [TIPOVI_OBRAZACA.PTP2]: 'ptp2',
  [TIPOVI_OBRAZACA.DEP2]: 'dep2',
  [TIPOVI_OBRAZACA.PZV1]: 'pzv1',
  [TIPOVI_OBRAZACA.KOM1]: 'kom1',
  [TIPOVI_OBRAZACA.OBRAZAC_4]: 'emisije-u-tlo',
  [TIPOVI_OBRAZACA.OBRAZAC_1]: 'obrazac1',
  [TIPOVI_OBRAZACA.DEP1]: 'dep1',
  [TIPOVI_OBRAZACA.AAO1]: 'aao1',
  [TIPOVI_OBRAZACA.GIO3]: 'gio3',
  [TIPOVI_OBRAZACA.AAO2]: 'aao2',
  [TIPOVI_OBRAZACA.GIO2]: 'gio2',
  [TIPOVI_OBRAZACA.OBRAZAC_5]: 'obrazac5',
  [TIPOVI_OBRAZACA.GIO4]: 'gio4',
  [TIPOVI_OBRAZACA.GIO5]: 'gio5',
  [TIPOVI_OBRAZACA.DKO]: 'dko',
  [TIPOVI_OBRAZACA.OBRAZAC_3]: 'obrazac3',
  [TIPOVI_OBRAZACA.DEO6_GIO6]: 'deo6',
  [TIPOVI_OBRAZACA.OBRAZAC_2]: 'obrazac2',
};

export const OBRAZAC_TYPE_IZVESTAJ_API_URL_MAP: { [key: string]: string } = {
  [TIPOVI_OBRAZACA.PTP2]: 'ptp2',
  [TIPOVI_OBRAZACA.DEP2]: 'ptp2',
  [TIPOVI_OBRAZACA.PZV1]: 'ptp2',
  [TIPOVI_OBRAZACA.KOM1]: 'ptp2',
  [TIPOVI_OBRAZACA.OBRAZAC_4]: 'ptp2',
  [TIPOVI_OBRAZACA.OBRAZAC_1]: 'ptp2',
  [TIPOVI_OBRAZACA.DEP1]: 'ptp2',
  [TIPOVI_OBRAZACA.AAO1]: 'ptp2',
  [TIPOVI_OBRAZACA.GIO3]: 'ptp2',
  [TIPOVI_OBRAZACA.AAO2]: 'ptp2',
  [TIPOVI_OBRAZACA.GIO2]: 'ptp2',
  [TIPOVI_OBRAZACA.OBRAZAC_5]: 'ptp2',
  [TIPOVI_OBRAZACA.GIO4]: 'ptp2',
  [TIPOVI_OBRAZACA.GIO5]: 'ptp2',
  [TIPOVI_OBRAZACA.DKO]: 'ptp2',
  [TIPOVI_OBRAZACA.OBRAZAC_3]: 'ptp2',
  [TIPOVI_OBRAZACA.DEO6_GIO6]: 'ptp2',
  [TIPOVI_OBRAZACA.OBRAZAC_2]: 'ptp2',
};

export const PTP2_IZVESTAJ_TABLE_DATA: ColumnsType = [
  {
    title: `${t('YEAR')}`,
    width: 100,
    dataIndex: 'godina',
    key: 'godina',
  },
  {
    title: `${t('COMPANY.TITLE')}`,
    width: 100,
    dataIndex: ['preduzece', 'naziv'],
    key: 'naziv',
  },
  {
    title: `${t('COMPANY.PIB')}`,
    width: 100,
    dataIndex: ['preduzece', 'pib'],
    key: 'pib',
  },
  {
    title: `${t('COMPANY.ENTERPRISE_ID_NUMBER')}`,
    width: 100,
    dataIndex: ['preduzece', 'maticni_broj'],
    key: 'maticni_broj',
  },
];

export const OBRAZAC_TYPE_IZVESTAJ_TABLE_COLUMNS: ITableColumns = {
  [TIPOVI_OBRAZACA.DEP2]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.PZV1]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.KOM1]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.PTP2]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.OBRAZAC_4]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.OBRAZAC_1]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.DEP1]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.AAO1]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.GIO3]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.AAO2]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.GIO2]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.GIO4]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.GIO5]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.DKO]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.OBRAZAC_5]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.OBRAZAC_3]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.OBRAZAC_2]: PTP2_IZVESTAJ_TABLE_DATA,
  [TIPOVI_OBRAZACA.DEO6_GIO6]: PTP2_IZVESTAJ_TABLE_DATA,
};
