import React from 'react';
import { Button as ANTButton, ButtonProps } from 'antd';

import './Button.scss';

interface IButtonProps extends ButtonProps {
  name: string;
}

export const Button: React.FC<IButtonProps> = props => {
  return <ANTButton {...props} data-test={`btn-${props.name}`} />;
};
