import { IPostFacility, ISurvey } from './facility.types';

export const facilityFormInitialState: IPostFacility = {
  naziv_postrojenja: '',
  mesto_id: '',
  postanski_broj: '',
  ulica: '',
  kucni_broj: '',
  telefon: '',
  koordinate_lat: '',
  koordinate_long: '',
  prethodni_nacionalni_id: '',
  istorijski_id: '',
  nacionalni_id: '',
  ippc: false,
  napomena: '',
  is_active: false,
  preduzece_id: '',
};

export const surveyInitialState: ISurvey = {
  korak_1_pitanje_1: '',
  korak_1_pitanje_2: '',
  korak_1_pitanje_3: '',
  korak_1_pitanje_4: '',
  korak_1_pitanje_5: '',
  korak_1_pitanje_6: '',
  korak_2_pitanje_1: '',
  korak_2_pitanje_2: '',
  korak_3_pitanje_1: '',
  korak_4_pitanje_1: '',
  postrojenje: facilityFormInitialState,
  verifikacioni_kod: '',
  preduzece_id: '',
};
