import { observer } from 'mobx-react-lite';
import { Content } from 'components/Content/Content';
import { useTranslation } from 'react-i18next';
import { CompaniesListFilters } from './CompaniesListFilters/CompaniesListFilters';
import { CompanyListTable } from './CompanyListTable/CompanyListTable';
import { ExcelAndAddButton } from 'components/ExcelAndAddButton/ExcelAndAddButton';
import { companyStore } from 'modules/company/company.store';

export const CompaniesListHeader: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <div id={'companyHeader'}>
      <ExcelAndAddButton
        excelFunc={() => companyStore.exportCompanyExcel()}
        excelName={'preduzeca'}
        component={<h1>{t('COMPANY.LIST')}</h1>}
      />
    </div>
  );
});

const CompaniesList = observer(() => {
  return (
    <Content header={<CompaniesListHeader />}>
      <CompaniesListFilters />
      <CompanyListTable />
    </Content>
  );
});

export default CompaniesList;
