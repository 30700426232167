import {
  IPostDeponijeGio2,
  IPostKolicineGio2,
  IPostDozvoleGio2,
  IPostRazrediEeOtpadaGio2,
  IPostGio2Obrazac,
} from './gio2.types';

export const GIO_2_INITIAL_STATE: IPostGio2Obrazac = {
  godina: '',
  preduzece_id: '',
  postrojenje_id: '',
  napomena: '',
  dozvole: [],
  deponije: [],
  kolicine: [],
};

export const GIO_2_DOZVOLE_INITIAL_STATE: IPostDozvoleGio2 = {
  broj_dozvole: '',
  datum_izdavanja: '',
  datum_isteka: '',
  sakupljanje: false,
  transport: false,
  skladistenje: false,
  tretman: false,
  odlaganje: false,
};

export const GIO_2_DEPONIJE_INITIAL_STATE: IPostDeponijeGio2 = {
  adresa_deponije: '',
  mesto_deponije_id: '',
  vrsta_deponije_id: '',
  geografska_sirina: '',
  geografska_duzina: '',
};

export const GIO_2_KOLICINE_INITIAL_STATE: IPostKolicineGio2 = {
  indeksni_broj_otpada_id: '',
  kolicina_preuzetog_otpada: null,
  ukupna_kolicina_ove_vrste_otpada: null,
  stanje_na_deponiji_na_dan_1_januar: null,
  stanje_na_deponiji_na_dan_31_decembar: null,
  postupak_odlaganja_kolicina: null,
  postupak_odlaganja_d_oznaka_id: '',
  drugi_postupak_odlaganja_kolicina: null,
  drugi_postupak_odlaganja_d_oznaka_id: '',
  razredi_ee_otpada: [],
  kolicina_otpada_za_odlaganje: null,
  kolicina_otpada_za_spaljivanje: null,
};

export const GIO_2_RAZREDI_EE_OTPADA_INITIAL_STATE: IPostRazrediEeOtpadaGio2 = {
  razred_ee_otpada_id: '',
  kolicina: null,
};
