import { Modal } from 'components/Modal/Modal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import styles from './ActivityList2Modal.module.scss';
import { NewActivity2Form } from 'components/NewForms/NewActivityForms/NewActivity2Form/NewActivity2Form';
import { activity2InitialState } from 'modules/activityList2/activityList2.constants';
import { activityList2Store } from 'modules/activityList2/activityList2.store';

export const ActivityList2Modal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Modal
      name={`activity-list-modal-2`}
      open={modalStore.getCurrentOpenedModal.includes(`activity-list-modal-2`)}
      className={`${styles.activity_modal}`}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => {
        activityList2Store.handleChange('activity', activity2InitialState);
        activityList2Store.handleChange('privredna_delatnost_id', '');
        modalStore.removeAllModals();
      }}
      title={t('ACTIVITIES.NEW_ACTIVITY_TITLE')}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      width={'70%'}
    >
      <NewActivity2Form />
    </Modal>
  );
});
