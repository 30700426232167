import { observer } from 'mobx-react-lite';
import React from 'react';
import { Obrazac3AnalizaOtpadneVodeModal } from './components/Obrazac3AnalizaOtpadneVodeModal/Obrazac3AnalizaOtpadneVodeModal';
import { Obrazac3AnalizaOtpadneVodeMerenjeModal } from './components/Obrazac3AnalizaOtpadneVodeMerenjeModal/Obrazac3AnalizaOtpadneVodeMerenjeModal';
import { Obrazac3AnalizaOtpadneVodeIzmereneVrednostiModal } from './components/Obrazac3AnalizaOtpadneVodeIzmereneVrednostiModal/Obrazac3AnalizaOtpadneVodeIzmereneVrednostiModal';
import { Obrazac3AnalizaRecipijenataModal } from './components/Obrazac3AnalizaRecipijenataModal/Obrazac3AnalizaRecipijenataModal';
import { Obrazac3AnalizaRecipijenataMerenjeModal } from './components/Obrazac3AnalizaRecipijenataMerenjeModal/Obrazac3AnalizaRecipijenataMerenjeModal';
import { Obrazac3AnalizaRecipijenataIzmereneVrednostiModal } from './components/Obrazac3AnalizaRecipijenataIzmereneVrednostiModal/Obrazac3AnalizaRecipijenataIzmereneVrednostiModal';
import { Obrazac3GlavniIndustrijskiPotrosaciModalKanalizacija } from './components/Obrazac3GlavniIndustrijskiPotrosaciModal/Obrazac3GlavniIndustrijskiPotrosaciModalKanalizacija';
import { Obrazac3GlavniIndustrijskiPotrosaciModalVodovod } from './components/Obrazac3GlavniIndustrijskiPotrosaciModal/Obrazac3GlavniIndustrijskiPotrosaciModalVodovod';

export const Obrazac3MicroServices: React.FC = observer(() => {
  return (
    <>
      <Obrazac3AnalizaOtpadneVodeModal />
      <Obrazac3AnalizaOtpadneVodeMerenjeModal />
      <Obrazac3AnalizaOtpadneVodeIzmereneVrednostiModal />
      <Obrazac3AnalizaRecipijenataModal />
      <Obrazac3AnalizaRecipijenataMerenjeModal />
      <Obrazac3AnalizaRecipijenataIzmereneVrednostiModal />
      <Obrazac3GlavniIndustrijskiPotrosaciModalKanalizacija />
      <Obrazac3GlavniIndustrijskiPotrosaciModalVodovod />
    </>
  );
});
