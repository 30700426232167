import { observer } from 'mobx-react-lite';
import React from 'react';

import styles from './Logo.module.scss';
import logo from '../../assets/image/NRIZ-logo.png';

export const Logo: React.FC = observer(() => {
  return (
    <img
      src={logo}
      alt="Nriz logo"
      className={styles.cover_image} // Apply custom CSS class
    />
  );
});
