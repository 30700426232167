import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewProizvodiForm } from './components/NewProizvodiForm/NewProizvodiForm';
import { observer } from 'mobx-react-lite';
import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { ptp2ObrazacStore } from 'modules/obrasci/store/ptp2/ptp2.store';
import { PTP2_PROIZVODI_INITIAL_STATE } from 'modules/obrasci/store/ptp2/ptp2.constants';

export const ProizvodModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="80%"
      name="proizvodi-modal"
      title={t('WIZARD_TABS.PROIZVODI')}
      store={ptp2ObrazacStore}
      data={ptp2ObrazacStore.getProizvodi}
      children={({ form }) => <NewProizvodiForm form={form} />}
      submitForm={ptp2ObrazacStore.submitProizvodiForm}
      initialState={PTP2_PROIZVODI_INITIAL_STATE}
      dataToChange={'proizvodi'}
      onCancel={() => ptp2ObrazacStore.handleChange('filterProductsTypeId', '')}
    />
  );
});
