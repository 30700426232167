import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { PROIZVODI_OBRAZAC_1_INITIAL_STATE } from 'modules/obrasci/store/obrazac1/obrazac1.constants';
import { obrazac1Store } from 'modules/obrasci/store/obrazac1/obrazac1.store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewProizvodiObrazac1Form } from './components/NewProizvodiObrazac1Form/NewProizvodiObrazac1Form';

export const ProizvodiObrazac1Modal: React.FC = observer(() => {
  const { t } = useTranslation();
  //   todo: prevod
  return (
    <CRUDModal
      width="70%"
      name="proizvodi-obrazac-1-modal"
      title={t('WIZARD_TABS.PROIZVODI')}
      store={obrazac1Store}
      data={obrazac1Store.getProizvodiForm}
      children={() => <NewProizvodiObrazac1Form />}
      submitForm={obrazac1Store.submitProizvodiForm}
      initialState={PROIZVODI_OBRAZAC_1_INITIAL_STATE}
      dataToChange="proizvodi_form"
    />
  );
});
