import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { TableUpravljanje } from './components/TableUpravljanje/TableUpravljanje';

export const Kom1UpravljanjeWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<WizardObrazacListHeader name={'upravljanje-form'} modalName="upravljanje-modal" />}>
      <TableUpravljanje />
    </InsideContentWrapper>
  );
});
