import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { gio3ObrazacStore } from 'modules/obrasci/store/gio3/gio3.store';
import { IGetKolicineGio3 } from 'modules/obrasci/store/gio3/gio3.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const TableGio3Kolicine: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetKolicineGio3> = [
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
      width: '300px',
      dataIndex: ['indeksni_broj_otpada', 'index_number'],
      key: 'waste',
      render: (_: any, record: IGetKolicineGio3) => (
        <>{`${record.indeksni_broj_otpada.index_number} ${record.indeksni_broj_otpada.waste_sr.toUpperCase()}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.GIO_3.AMOUNT_OF_COLLECTED_WASTE_IN_REPORTING_YEAR')}`,
      width: '200px',
      dataIndex: 'kolicina_preuzetog_otpada',
      key: 'kolicina_preuzetog_otpada',
    },
    {
      title: `${t('OBRASCI.GIO_3.THE_TOTAL_AMOUNT_OF_THIS_TYPE_OF_WASTE_REUSED_IN_REPORTING_YEAR')}`,
      dataIndex: 'ukupna_kolicina_ove_vrste_otpada',
      key: 'ukupna_kolicina_ove_vrste_otpada',
      width: '250px',
    },
    // {
    //   title: `${t('OBRASCI.GIO_3.BALANCE_OF_THE_TEMPORARY_WAREHOUSE_JANUARY')}`,
    //   width: 200,
    //   dataIndex: 'stanje_na_deponiji_na_dan_1_januar',
    //   key: 'stanje_na_deponiji_na_dan_1_januar',
    // },
    // {
    //   title: `${t('OBRASCI.GIO_3.BALANCE_OF_THE_TEMPORARY_WAREHOUSE_DECEMBER')}`,
    //   dataIndex: 'stanje_na_deponiji_na_dan_31_decembar',
    //   key: 'stanje_na_deponiji_na_dan_31_decembar',
    //   width: 200,
    // },
    // {
    //   title: `${t('OBRASCI.GIO_3.RECYCLING_PROCESS')}`,
    //   dataIndex: 'postupak_odlaganja_kolicina',
    //   key: 'postupak_odlaganja_kolicina',
    //   width: 200,
    // },
    // {
    //   title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
    //   width: 200,
    //   dataIndex: ['postupak_odlaganja_r_oznaka_id', 'type_of_waste'],
    //   key: 'waste',
    // },

    // {
    //   title: `${t('OBRASCI.GIO_3.ANOTHER_RECYCLING_PROCESS')}`,
    //   dataIndex: 'drugi_postupak_odlaganja_kolicina',
    //   key: 'drugi_postupak_odlaganja_kolicina',
    //   width: 200,
    // },
    // {
    //   title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
    //   width: 200,
    //   dataIndex: ['postupak_odlaganja_r_oznaka_id', 'type_of_waste'],
    //   key: 'waste',
    // },
    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '75px',
      align: 'center',
      render: (_, record: IGetKolicineGio3) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-kolicine'}
            onClick={() => {
              gio3ObrazacStore.handleChange('kolicine_form', record);
              modalStore.changeModalName('gio3-kolicine-modal');
            }}
          />
        );
      },
    },
  ];
  // todo: check if backend return whole array or we have to do it with pagination
  return (
    <TableData name="kolicine-gio3" columns={baseColumns} dataSource={[...gio3ObrazacStore.initialState.kolicine]} />
  );
});
