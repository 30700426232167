import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import to from 'await-to-js';
import { nrizService } from 'modules/nriz/nriz.service';
import { ICompanyTipoviObrasca, IGetCompany, IPib, IPostCompany } from 'modules/company/company.types';
import { IPagination } from 'typescript';
import { isEmpty } from 'lodash';
import { ITipObrasca } from 'modules/obrasci/obrasci.types';
import { handleSuccessNotify } from 'utils/succesHandling';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { authStore } from '../../store/AuthStore';

import { filtersStore } from 'store/filteri.store';
import { CompanyStatus, KoraciRegistracijeKorisnika, companyInitialState } from './company.constants';
import { companyService } from './company.service';
import { UserRoles } from 'modules/user/user.types';
import { ResponseSifarnikPretezneDelatnosti } from 'modules/sifarnici/store/sifarnikPretezneDelatnosti/sifarnikPretezneDelatnosti.types';
import { unsanitaryLandfillStore } from 'modules/unsanitarylandfill/unsanitarylandfill.store';
import { deactivateItems } from 'utils/deactivateItems';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { activateItems } from 'utils/activateItems';
import { ResponseMesto } from 'modules/sifarnici/store/mesto/mesto.types';
import { userStore } from 'modules/user/user.store';
import { userService } from 'modules/user/user.service';
import dayjs from 'dayjs';
import { saveBlob } from 'utils/fileDownload';

class CompanyStore {
  constructor() {
    makeAutoObservable(this, {
      company: observable,
      companiesList: observable,
      companyTipoviObrazaca: observable,
      firstPageToShow: observable,
      initial_tipovi_ids: observable,
      getCompany: computed,
      getCompaniesList: computed,
      getPageToShow: computed,
      fetchCompaniesList: action,
      fetchCompany: action,
      fetchMesto: action,
      checkCompany: action,
      postPib: action,
      setCompany: action,
      setCompaniesList: action,
    });
  }
  company: IPostCompany | IGetCompany = companyInitialState;
  companiesList: IGetCompany[] = [];
  companyTipoviObrazaca: ITipObrasca[] = [];

  imaDeo6 = false;

  initial_tipovi_ids: string[] = [];
  firstPageToShow: KoraciRegistracijeKorisnika | null = null;

  get getCompany() {
    return this.company;
  }

  get getCompanyTipoviObrazaca() {
    return this.companyTipoviObrazaca;
  }

  get getCompanyTipoviObrazacaIds() {
    return this.companyTipoviObrazaca?.map(tipObrasca => tipObrasca.id) || [];
  }

  get getCompaniesList() {
    return this.companiesList;
  }

  get getPageToShow() {
    return this.firstPageToShow;
  }

  handleChange(key: string, value: any) {
    (this as any)[key] = value;
  }

  setCompanyTipoviObrazaca = (selectedTipovi: ITipObrasca[]) => {
    this.companyTipoviObrazaca = selectedTipovi;
  };

  setImaDeo6Flag = (imaDeo6: boolean) => {
    this.imaDeo6 = imaDeo6;
  };

  updateCompanyList = (companyId: string) => {
    this.handleChange('company', { ...this.company, status: 'aktivan' });
    const company = this.companiesList.find((company: IGetCompany) => company.id === companyId);
    if (company) runInAction(() => (company.status = 'aktivan'));
  };

  fetchCompany = async (id: string) => {
    const [err, res] = await to(companyService.fetchCompany(id));
    if (err || !res) return;
    this.handleChange('company', res);
  };

  fetchCompaniesList = async (pagination: Partial<IPagination>) => {
    const [err, res] = await to(
      companyService.fetchCompaniesList({
        page: pagination.page,
        size: pagination.size,
        ...nrizService.pickFields(filtersStore.filters.preduzeca),
      })
    );
    if (err || !res) return;

    this.handleChange('this.companiesList', res.items);
    return res;
  };

  exportCompanyExcel = async () => {
    const [err, res] = await to(companyService.exportCompanyExcel(filtersStore.filters.preduzeca));
    if (err || !res) return;
    const downloadName = `${'Preduzeća'}_${dayjs().format('DD-MM-YYYY')}`;
    saveBlob(res, downloadName);
  };

  fetchMesto = async (pagination: IPagination, search: string): Promise<ResponseMesto> => {
    const response = await sifarniciStore.fetchSifarnikOptions(
      SIFARNICI_ROUTES.MESTO,
      nrizService.pickFields({
        page: pagination.page,
        size: pagination.size,
        search: search,
        location_code__neq: authStore.getUserRole === UserRoles.SystemAdmin && 999999,
        order_by: '+display_name',
      })
    );
    return response;
  };

  fetchPretezneDelatnostiList = async (
    pagination: IPagination,
    search: string
  ): Promise<ResponseSifarnikPretezneDelatnosti> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.SIFARNIK_PRETEZNE_DELATNOSTI, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      version__neq: 0,
      order_by: '+code',
    });
    return response;
  };

  fetchCompanyTipoviObrasca = async (id: string) => {
    const [err, res] = await to(companyService.fetchCompanyTipObrasca(id));
    if (err || !res) return;

    this.setCompanyTipoviObrazaca(res);
    const ids = res.map((item: ITipObrasca) => item.id);
    this.handleChange('initial_tipovi_ids', ids);
  };

  postCompanyTipoviObrasca = async (selectedTipovi: ICompanyTipoviObrasca) => {
    if (!(this.company as IGetCompany).id) return;
    const [err, res] = await to(companyService.postCompanyTipObrasca((this.company as IGetCompany).id, selectedTipovi));
    if (err || !res) return Promise.reject();
    handleSuccessNotify();
    const arrObj = obrasciStore.allTipoviObrazacaMapPoId;
    const newArray = selectedTipovi.tipovi_obrazaca.map((id: string) => arrObj[id]);
    this.setCompanyTipoviObrazaca(newArray);
    this.handleChange('initial_tipovi_ids', selectedTipovi.tipovi_obrazaca);
  };

  checkCompany = async () => {
    const [err, res] = await to(companyService.checkCompany());
    if (err || !res) return;

    if (res.korisnik_id === '') return runInAction(() => (this.firstPageToShow = KoraciRegistracijeKorisnika.WARNING));
    authStore.setUserId(res.korisnik_id);

    if (isEmpty(res.preduzece)) return runInAction(() => (this.firstPageToShow = KoraciRegistracijeKorisnika.PIB));
    runInAction(() => (this.company = res.preduzece));

    if (res.preduzece.status === CompanyStatus.U_PRIPREMI)
      return runInAction(() => (this.firstPageToShow = KoraciRegistracijeKorisnika.INFO));
    if (res.preduzece.status === CompanyStatus.AKTIVAN) {
      if (!res.preduzece.popunjen_upitnik)
        return runInAction(() => (this.firstPageToShow = KoraciRegistracijeKorisnika.SURVEY));
      if (res.preduzece.popunjen_upitnik)
        return runInAction(() => (this.firstPageToShow = KoraciRegistracijeKorisnika.HOME));
    }
    return (this.firstPageToShow = KoraciRegistracijeKorisnika.WARNING);
  };

  postPib = async (values: IPib) => {
    const [err, res] = await to(
      companyService.postPib(nrizService.setEmptyValuesToNull({ pib: values.pib, maticni_broj: values.maticni_broj }))
    );
    if (err || !res) return;
    handleSuccessNotify();
    runInAction(() => (this.firstPageToShow = KoraciRegistracijeKorisnika.INFO));
  };

  // pravljenje nove kompanije nam sada nije potrebno ,ako bude drugacije otkomentarisati

  // postCompany = async (values: ICompany) => {
  //   const [err, res] = await to(companyService.companyRegistration(values));
  //   if (err || !res) return;

  //   let sifarnikMesto = {};
  //   let sifarnikDelatnost = {};
  //   if (values.mesto_id) {
  //     sifarnikMesto = nrizService.addSifarnikInObject({
  //       list: sifarniciStore.list[SIFARNICI_ROUTES.MESTO],
  //       name: 'mesto',
  //       id: values.mesto_id,
  //     });
  //   }
  //   if (values.sifarnik_pretezne_delatnosti_id) {
  //     sifarnikDelatnost = nrizService.addSifarnikInObject({
  //       list: sifarniciStore.list[SIFARNICI_ROUTES.SIFARNIK_PRETEZNE_DELATNOSTI],
  //       name: 'sifarnik_pretezne_delatnosti',
  //       id: values.sifarnik_pretezne_delatnosti_id,
  //     });
  //   }

  //   const newObject = { ...values, id: res.id, status: 'u_pripremi', ...sifarnikMesto, ...sifarnikDelatnost };
  //   this.setCompaniesList([newObject, ...this.companiesList]);
  //   handleSuccessNotify();
  //   modalStore.removeAllModals();
  // };

  aktivirajPreduzece = async () => {
    const [err, res] = await to(companyService.aktivirajPreduzece((this.company as IGetCompany).id));
    if (err || !res) return;
    this.handleChange('company', { ...this.company, is_active: true });

    userStore.setUserList(activateItems(userStore.getUserList));
    unsanitaryLandfillStore.setUnsanitaryLandfillList(activateItems(unsanitaryLandfillStore.getUnsanitaryLandfillList));
    handleSuccessNotify();
  };

  deaktivirajPreduzece = async () => {
    const [err, res] = await to(companyService.deaktivirajPreduzece((this.company as IGetCompany).id));
    if (err || !res) return;
    this.handleChange('company', { ...this.company, is_active: false });

    userStore.setUserList(userService.deactivateUserList(userStore.getUserList));
    unsanitaryLandfillStore.setUnsanitaryLandfillList(
      deactivateItems(unsanitaryLandfillStore.getUnsanitaryLandfillList)
    );

    handleSuccessNotify();
  };

  putCompany = async (payload: IPostCompany) => {
    const newObject = { id: (this.company as IGetCompany).id, ...payload } as IGetCompany;

    const [err, res] = await to(companyService.putCompany(nrizService.setEmptyValuesToNull(newObject) as IGetCompany));
    if (err || !res) return this.setCompany(this.company);
    handleSuccessNotify();
    let sifarnikMesto = {};
    let sifarnikDelatnost = {};

    if (newObject.mesto_id) {
      sifarnikMesto = nrizService.addSifarnikInObject({
        exId: this.company.mesto_id,
        exObj: (this.company as IGetCompany).mesto,
        list: sifarniciStore.list[SIFARNICI_ROUTES.MESTO],
        name: 'mesto',
        id: newObject.mesto_id,
      });
    }
    if (newObject.sifarnik_pretezne_delatnosti_id) {
      sifarnikDelatnost = nrizService.addSifarnikInObject({
        exId: this.company.sifarnik_pretezne_delatnosti_id,
        exObj: (this.company as IGetCompany).sifarnik_pretezne_delatnosti,
        list: sifarniciStore.list[SIFARNICI_ROUTES.SIFARNIK_PRETEZNE_DELATNOSTI],
        name: 'sifarnik_pretezne_delatnosti',
        id: newObject.sifarnik_pretezne_delatnosti_id,
      });
    }

    this.setCompany({
      ...newObject,
      ...sifarnikMesto,
      ...sifarnikDelatnost,
      status: this.company.status,
      is_active: true,
    });
    return res;
  };

  // brisanje kompanije nam sada nije potrebno ,ako bude drugacije otkomentarisati

  // deleteCompany = async (id: string) => {
  //   const [err, res] = await to(companyService.deleteCompany(id));
  //   if (err || !res) return;
  //   handleSuccessNotify();
  //   modalStore.removeAllModals();
  //   history.back();
  //   history.pushState(null, document.title, location.href);
  //   window.addEventListener('popstate', function () {
  //     history.pushState(null, document.title, location.href);
  //   });
  // };

  setCompany = (values: IPostCompany | IGetCompany) => {
    this.company = values;
  };

  setCompaniesList = (values: IGetCompany[]) => {
    this.companiesList = values;
  };

  companyApprove = async (id: string | undefined) => {
    if (!id) return;
    const [err, res] = await to(companyService.companyApprove(id));
    if (err || !res) return;
    handleSuccessNotify();
    this.setCompany({ ...this.company, is_active: true });
    this.updateCompanyList(id);
  };
  companyReject = async (id: string | undefined) => {
    if (!id) return;
    const [err, res] = await to(companyService.companyReject(id));
    if (err || !res) return;
    handleSuccessNotify();
    return res;
  };

  submitCompany = (values: IPostCompany) => {
    return this.putCompany(values);
  };

  checkForDeo6 = async (id: string) => {
    const [err, res] = await to(companyService.checkForDeo6(id));
    if (err || !res) return;
    this.setImaDeo6Flag(res.ima_deo6);
  };

  toggleDeo6Validation = (id: string, isValidationDisabled: boolean) => {
    return companyService.toggleDeo6Validation(id, isValidationDisabled);
  };
}

export const companyStore = new CompanyStore();
