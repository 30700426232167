import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Obrazac3AnalizaOtpadneVodeTable } from './components/Obrazac3AnalizaOtpadneVodeTable/Obrazac3AnalizaOtpadneVodeTable';
import { Obrazac3GodisnjiBilansTable } from './components/Obrazac3GodisnjiBilansTable/Obrazac3GodisnjiBilansTable';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { Obrazac3AnalizaOtpadneVodeMerenjeTable } from './components/Obrazac3AnalizaOtpadneVodeMerenjeTable/Obrazac3AnalizaOtpadneVodeMerenjeTable';
import { Obrazac3GodisnjiPitanjeModal } from '../../Obrazac3MicroServices/components/Obrazac3GodisnjiPitanjeModal/Obrazac3GodisnjiPitanjeModal';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { Obrazac3GodisnjiRadio } from './components/Obrazac3GodisnjiRadio/Obrazac3GodisnjiRadio';

export const Obrazac3AnalizaOtpadneVodeWizardTab: React.FC<ICrudTypeModalProps> = observer(props => {
  const form = props.form;

  return Number(obrasciStore.opste.godina) >= 2023 ? (
    <>
      <InsideContentWrapper
        header={
          <WizardObrazacListHeader
            name={'obrazac3-analiza-otpadne-vode-merenje-form'}
            modalName="obrazac3-analiza-otpadne-vode-merenje-modal"
            onAddMessage={
              obrazac3Store.initialState.kreiranje_godisnjeg_bilansa
                ? 'Nakon promene liste merenja, vrši se rekalkulacija godišnjeg bilansa. Prethodno generisani bilans se briše.'
                : ''
            }
          />
        }
      >
        <Obrazac3AnalizaOtpadneVodeMerenjeTable />
        <Obrazac3GodisnjiRadio />
      </InsideContentWrapper>
      <Obrazac3GodisnjiPitanjeModal form={form} />

      {obrazac3Store.initialState.kreiranje_godisnjeg_bilansa && (
        <InsideContentWrapper header={null}>
          <Obrazac3GodisnjiBilansTable />
        </InsideContentWrapper>
      )}
    </>
  ) : (
    <InsideContentWrapper
      header={
        <WizardObrazacListHeader
          name={'obrazac3-analiza-otpadne-vode-form'}
          modalName="obrazac3-analiza-otpadne-vode-modal"
        />
      }
    >
      <Obrazac3AnalizaOtpadneVodeTable />
    </InsideContentWrapper>
  );
});
