import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Gio2TableDeponije } from './components/Gio2TableDeponije/Gio2TableDeponije';

export const Gio2DeponijeWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'gio2-deponije-form'} modalName="gio2-deponije-modal" />}
    >
      <Gio2TableDeponije />
    </InsideContentWrapper>
  );
});
