// pass config object which will contain sidebar items and rendered components
// rendered components should edit certain obrasci store values
// handle submit by type

import { Form, Popconfirm, Row, Tabs, Tooltip } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import React, { Dispatch, SetStateAction, Suspense, useEffect, useState } from 'react';
import style from './ObrazacWizard.module.scss';
import { Button } from 'components/Button/Button';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/es/form/Form';
import { IBaseObrazacStore } from 'typescript/NrizTypes';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { authStore, formStore, modalStore } from 'store';
import { OBRAZAC_TYPE_WIZARD_URL_MAP } from 'modules/obrasci/obrasci.constants';
import { LogOutButton } from 'components/LogOutButton/LogOutButton';
import { CopyOutlined, DownloadOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { ValidationObrazacNotification } from './components/components/ValidationObrazacNotification/ValidationObrazacNotification';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { dkoObrazacStore } from 'modules/obrasci/store/dko/dko.store';
import { UserRoles } from 'modules/user/user.types';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { Ptp2IzjavaOdgovornosti } from './components/components/ptp2IzjavaOdgovornosti/ptp2IzjavaOdgovornosti';

interface IObrazacWizardButtonHeader {
  form: FormInstance<any>;
}

const ObrazacWizardButtonHeader: React.FC<IObrazacWizardButtonHeader> = observer(props => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [izjava, setIzjava] = useState(false);

  return (
    <div className={style.buttons}>
      <Row justify={'end'}>
        {id && (
          <div style={{ marginRight: '10px' }}>
            {/* todo: prevedi */}
            {obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.DKO && (
              <Tooltip title={'Preuzmi'}>
                <Button
                  style={{ marginRight: '10px' }}
                  name={'stampaj_dugme'}
                  disabled={false}
                  onClick={() => obrasciStore.exportPdfSingleObrazac(id)}
                >
                  <DownloadOutlined rev={undefined} />
                </Button>
              </Tooltip>
            )}
            {obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.DKO && (
              <Tooltip title={'Kopiraj'}>
                <Button
                  name={'kopiraj_dugme'}
                  disabled={false}
                  onClick={() => {
                    navigate(`${OBRAZAC_TYPE_WIZARD_URL_MAP[TIPOVI_OBRAZACA.DKO]}${obrasciStore.tip_obrasca_id}`, {
                      replace: true,
                    });
                    dkoObrazacStore.copyDKO();
                  }}
                >
                  <CopyOutlined rev={undefined} />
                </Button>
              </Tooltip>
            )}
          </div>
        )}

        {!id && (
          <Popconfirm
            title={
              obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2
                ? t('IZJAVA_ODGOVORNOSTI.TITLE')
                : 'Da li ste sigurni da želite da izmenite obrazac?'
            }
            description={
              obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 && (
                <Ptp2IzjavaOdgovornosti izjava={izjava} setIzjava={setIzjava} />
              )
            }
            okText={t('Da')}
            cancelText={t('Odbaci')}
            onConfirm={() => {
              formStore.setCrudButtonType('submit');
              props.form.submit();
            }}
            placement="bottom"
            okButtonProps={{ disabled: obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 && !izjava }}
          >
            <Button disabled={modalStore.isLoading} name={'submit'}>
              {t('SUBMIT')}
            </Button>
          </Popconfirm>
        )}
        {id && !obrasciStore.zakljucanObrazac && (
          <div style={{ marginRight: '10px' }}>
            <Popconfirm
              title={
                obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2
                  ? t('IZJAVA_ODGOVORNOSTI.TITLE')
                  : 'Da li ste sigurni da želite da izbrišete obrazac?'
              }
              description={
                obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 && (
                  <Ptp2IzjavaOdgovornosti izjava={izjava} setIzjava={setIzjava} />
                )
              }
              okText={t('Da')}
              cancelText={t('Odbaci')}
              onConfirm={async () => {
                const response = await obrasciStore.deleteObrazac(id);
                if (response) navigate('/forms');
              }}
              placement="bottom"
              okButtonProps={{ disabled: obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 && !izjava }}
            >
              <Button disabled={modalStore.isLoading} name={'delete'} type="ghost">
                {t('DELETE')}
              </Button>
            </Popconfirm>
          </div>
        )}
        {id && !obrasciStore.zakljucanObrazac && (
          <Row justify={'end'}>
            <Popconfirm
              title={
                obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2
                  ? t('IZJAVA_ODGOVORNOSTI.TITLE')
                  : 'Da li ste sigurni da želite da izmenite obrazac?'
              }
              description={
                obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 && (
                  <Ptp2IzjavaOdgovornosti izjava={izjava} setIzjava={setIzjava} />
                )
              }
              className={`${obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 && style.izjavaPtp2}`}
              okText={t('Da')}
              cancelText={t('Odbaci')}
              onConfirm={() => {
                formStore.setCrudButtonType('change');
                props.form.submit();
              }}
              okButtonProps={{ disabled: obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.PTP2 && !izjava }}
              placement="bottom"
            >
              <Button disabled={modalStore.isLoading} name="change-button">
                {t('CHANGE')}
              </Button>
            </Popconfirm>
          </Row>
        )}
      </Row>
      <Popconfirm
        title={'Da li ste sigurni da želite da izađete sa obrasca?'}
        okText={t('Da')}
        cancelText={t('Odbaci')}
        onConfirm={() => navigate('/forms')}
        placement="bottom"
        okButtonProps={{ disabled: false }}
        cancelButtonProps={{ disabled: false }}
      >
        <Button type="ghost" name="wizard-go-back" disabled={false}>
          {t('BACK')}
        </Button>
      </Popconfirm>
      {!obrasciStore.zakljucanObrazac && (
        <div>
          <InfoCircleTwoTone
            rev={undefined}
            onClick={() =>
              obrasciStore.handleChange('isValidationNotificationOpen', !obrasciStore.isValidationNotificationOpen)
            }
            style={{ fontSize: '20px' }}
          />
        </div>
      )}
    </div>
  );
});

//////////// //////////// //////////// //////////// //////////// //////////// //////////// //////////// //////////
export interface IObrazacHeader {
  form: FormInstance<any> | any;
}

export const WizardHeader: React.FC<IObrazacHeader> = observer(props => {
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      formStore.handleChange('goBack', false);
    };
  }, []);

  useEffect(() => {
    if (formStore.getGoBack) {
      navigate('/forms');
    }
  }, [formStore.getGoBack]);

  return (
    <Row className={style.wizardHeader} align={'middle'} id="obrazacWizardHeader">
      <h1 color="white" style={{ margin: 0 }}>
        {obrasciStore.real_obrazac_type}
      </h1>
      <ObrazacWizardButtonHeader form={props.form} />
    </Row>
  );
});

export interface IRenderTabConfig {
  wizardConfig: IWizardTabConfig[];
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

export interface IWizardTabConfig {
  label: string;
  key: string;
  component: ({ form }: { form: FormInstance<any> }) => JSX.Element;
  isTabHidden?: () => boolean;
}

//////////// //////////// //////////// //////////// //////////// //////////// //////////// //////////// //////////

export const WizardTabs = observer(
  ({
    wizardConfig,
    form,
    store,
  }: {
    wizardConfig: IWizardTabConfig[];
    form: FormInstance<any>;
    store: IBaseObrazacStore;
  }) => {
    const [activeTab, setActiveTab] = useState('1');

    const renderTabs = () => {
      return (
        <div className="ant-tabs-nav-list">
          <div className={style.scrollable}>
            {wizardConfig.map((tab: IWizardTabConfig, i) => {
              if (tab.isTabHidden) {
                const isHidden = tab.isTabHidden();
                if (isHidden) return;
              }
              return (
                <div
                  key={i}
                  className={`${style.navTab} ${activeTab === tab.key ? 'active' : ''}`}
                  onClick={() => {
                    if (obrasciStore.disabled_tab_button) return;
                    setActiveTab(tab.key);
                  }}
                >
                  {tab.label.toUpperCase()}
                </div>
              );
            })}
          </div>
          <LogOutButton />
        </div>
      );
    };

    useEffect(() => {
      renderTabs();
    }, [store.getIsTabHidden]);

    return (
      <Tabs
        defaultActiveKey="1"
        onChange={(tabKey: string) => setActiveTab(tabKey)}
        className={style.wizardTabWrapper}
        renderTabBar={() => renderTabs()}
        activeKey={activeTab}
      >
        {wizardConfig.map(tab => {
          const tabHidden = tab.isTabHidden ? !tab.isTabHidden() : true;
          return (
            <TabPane className={style.wizardTab} key={tab.key} tab={tab?.label} forceRender={true}>
              <Suspense fallback={<div>Loading</div>}>
                <div className={style.wizardContent}>
                  <div className={style.content}>{tabHidden && <tab.component form={form} />}</div>
                </div>
              </Suspense>
            </TabPane>
          );
        })}
      </Tabs>
    );
  }
);

//////////// //////////// //////////// //////////// //////////// //////////// //////////// //////////// //////////

export interface IObrazacWizardProps {
  wizardConfig: IWizardTabConfig[];
  store: IBaseObrazacStore;
  modals: JSX.Element;
}

export const ObrazacWizard = observer(<T,>({ wizardConfig, store, modals }: IObrazacWizardProps) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const location = useLocation();

  const handleObrazacIdInEffect = async (id: string) => {
    obrasciStore.setDisabledTabButton(false);
    obrasciStore.loadSingleObrazacData(store.loadData, id);
    const response = await obrasciStore.checkIfObrazacLocked(id);
    if (response) obrasciStore.setZakljucanObrazac(response);
  };

  useEffect(() => {
    obrasciService.setObrazacTypeWithUrl(location.pathname);

    if (id) {
      handleObrazacIdInEffect(id);
    }

    if (authStore.getUserRole !== UserRoles.SystemAdmin) {
      obrasciService.setOpsteObrazacWizardUser(store);
    }

    return () => obrasciStore.setZakljucanObrazac(false);
  }, []);

  useEffect(() => {
    return () => {
      if (authStore.getUserRole === UserRoles.SystemAdmin) {
        obrasciService.resetObrazacWizardAdmin();
      }

      obrasciStore.setDisabledTabButton(true);
      store.resetStates();
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue(store.initialState);
  }, [store.initialState]);

  return (
    <div className={style.obrazacWizardPage}>
      {modals}
      <ValidationObrazacNotification />
      <Form.Provider>
        <Form
          form={form}
          initialValues={store.initialState}
          name="wizard"
          onFinishFailed={() => {
            obrasciStore.handleChange('isValidationNotificationOpen', true);
          }}
          onFinish={(values: T) => {
            const isValid = obrasciService.handleValidation();
            if (!isValid) return;
            store.submitData({ izjava_o_odgovornosti: true, ...values });
          }}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 15 }}
          labelAlign="left"
          layout="horizontal"
          disabled={obrasciStore.zakljucanObrazac}
        >
          <WizardHeader form={form} />
          <WizardTabs wizardConfig={wizardConfig} form={form} store={store} />
        </Form>
      </Form.Provider>
    </div>
  );
});
