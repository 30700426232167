import { FormInstance } from 'antd';
import { IGetProductsType } from './productsType.types';

class ProductTypeService {
  makeProizvodiInput = (form: FormInstance<IGetProductsType>) => {
    const name = form.getFieldValue('name');
    const valuta = form.getFieldValue('currencyor');
    const jedinica = form.getFieldValue('unit');
    const cena = form.getFieldValue('priceor_percentageof_pdv');

    const bracket = `(${cena || ''} ${valuta || ''} ${`/ ${jedinica || ''}`})`;

    const input_value = `${name} ${((valuta || jedinica || cena) && bracket) || ''}`;

    form.setFieldValue('display_product', input_value);
  };
}

export const productTypeService = new ProductTypeService();
