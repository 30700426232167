import dayjs from 'dayjs';
import { IPostUnsanitaryLandfill } from './unsanitarylandfill.types';

export const unsanitaryLandfillInitialState: IPostUnsanitaryLandfill = {
  oznaka_nesanitarne_deponije: '',
  naziv: '',
  mesto_id: '',
  koordinate_long: '',
  koordinate_lat: '',
  zauzete_katastarske_parcele: '',
  period_deponovanja: [dayjs(), dayjs()],
  napomena: '',
  is_active: false,
};
