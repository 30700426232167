import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrazac1Store } from 'modules/obrasci/store/obrazac1/obrazac1.store';
import { IGetSirovine, ISirovine } from 'modules/obrasci/store/obrazac1/obrazac1.types';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const TableSirovine: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetSirovine> = [
    {
      title: `${t('OBRASCI.OBRAZAC_1.CHEMICAL_NAME_IUPAC')}`,
      width: '150px',
      dataIndex: 'hemijski_naziv',
      key: 'hemijski_naziv',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.COMMERCIAL_NAME')}`,
      width: '150px',
      dataIndex: 'komercijalni_naziv',
      key: 'komercijalni_naziv',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.AGGREGATE_CONDITION_WHEN_IN_STORAGE')}`,
      dataIndex: 'agregatno_stanje_kada_je_na_skladistu',
      key: 'agregatno_stanje_kada_je_na_skladistu',
      width: '200px',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.UNIT_OF_MEASURE')}`,
      width: '150px',
      dataIndex: ['jedinica_mere', 'name_sr'],
      key: 'name_sr',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.CONSUMPTION_PER_DAY')}`,
      dataIndex: 'potrosnja_na_dan',
      key: 'potrosnja_na_dan',
      width: '150px',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.METHOD_OF_STORAGE')}`,
      dataIndex: 'nacin_lagerovanja',
      key: 'nacin_lagerovanja',
      width: '150px',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.MAXIMUM_STORAGE_CAPACITY')}`,
      width: '150px',
      dataIndex: 'maksimalni_kapacitet_lagera',
      key: 'maksimalni_kapacitet_lagera',
    },
    {
      title: `${t('OBRASCI.OBRAZAC_1.AVERAGE_AMOUNT_OF_STORAGE')}`,
      dataIndex: 'prosecna_kolicina_na_lageru',
      key: 'prosecna_kolicina_na_lageru',
      width: '150px',
    },
    {
      title: ``,
      key: 'operation',
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (_, record: ISirovine) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-sirovine'}
            onClick={() => {
              obrazac1Store.handleChange('sirovine_form', record);
              modalStore.changeModalName('sirovine-modal');
            }}
          />
        );
      },
    },
  ];
  // todo: check if backend return whole array or we have to do it with pagination
  return (
    <TableData name="sirovine-obrazac1" columns={baseColumns} dataSource={[...obrazac1Store.initialState.sirovine]} />
  );
});
