import { observer } from 'mobx-react-lite';
import React from 'react';
import { ProizvodModal } from './ProizvodiModal/ProizvodiModal';

export const Ptp2MicroServices: React.FC = observer(() => {
  return (
    <>
      <ProizvodModal />
    </>
  );
});
