import { Row } from 'antd';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { modalStore } from 'store';

interface WizardListHeaderProps {
  name: string;
  modalName: string;
  disabled?: boolean;
  onAddMessage?: string;
  title?: string;
}

export const WizardObrazacListHeader: React.FC<WizardListHeaderProps> = observer(props => {
  return (
    <Row justify={'space-between'} id="obrazacTableHeader">
      <h3>{props.title}</h3>

      <AddNewButton
        disabled={props.disabled}
        name={props.name}
        onButtonClick={() => {
          modalStore.changeModalName(props.modalName);
        }}
      />
    </Row>
  );
});
