import { Form, Input } from 'antd';
import { invalidCharactersFormInput } from 'modules/nriz/nriz.constants';
import { KeyboardEvent } from 'react';
import { FormTextAreaProps } from 'typescript';

export const FormTextArea: React.FC<FormTextAreaProps> = ({
  labelCol,
  wrapperCol,
  name,
  rules,
  label,
  placeholder,
  rows,
  disabled,
  invalidCharacters,
}) => {
  return (
    <Form.Item label={label} labelCol={{ span: labelCol }} wrapperCol={{ span: wrapperCol }} name={name} rules={rules}>
      <Input.TextArea
        rows={rows}
        placeholder={placeholder}
        disabled={disabled}
        onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement>) => {
          invalidCharacters
            ? (invalidCharacters.includes(e.key) || invalidCharactersFormInput.includes(e.key)) && e.preventDefault()
            : invalidCharactersFormInput.includes(e.key) && e.preventDefault();
        }}
      />
    </Form.Item>
  );
};
