import { observer } from 'mobx-react-lite';
import React from 'react';
import { Gio4IzvozOtpadaModal } from './components/Gio4IzvozOtpadaModal/Gio4IzvozOtpadaModal';
import { Gio4DozvoleModal } from './components/Gio4DozvoleModal/Gio4DozvoleModal';
import { Gio4ElektricniOtpadModal } from './components/Gio4ElektricniOtpadModal/Gio4ElektricniOtpadModal';
import { Gio4ZemljeTranzitaModal } from './components/Gio4ZemljeTranzitaModal/Gio4ZemljeTranzitaModal';

export const Gio4MicroServices: React.FC = observer(() => {
  return (
    <>
      <Gio4DozvoleModal />
      <Gio4IzvozOtpadaModal />
      <Gio4ElektricniOtpadModal />
      <Gio4ZemljeTranzitaModal />
    </>
  );
});
