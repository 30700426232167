import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewObrazac3GlavniIndustrijskiPotrosaciForm } from './NewObrazac3GlavniIndustrijskiPotrosaciForm/NewObrazac3GlavniIndustrijskiPotrosaciForm';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { OBRAZAC_3_GLAVNI_INDUSTRIJSKI_POTROSACI_INITIAL_STATE } from 'modules/obrasci/store/obrazac3/obrazac3.constants';
import { Obrazac3Sistem } from 'modules/obrasci/store/obrazac3/obrazac3.types';

export const Obrazac3GlavniIndustrijskiPotrosaciModalKanalizacija: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="50%"
      name={`obrazac3-glavni-potrosaci-${Obrazac3Sistem.KANALIZACIONI}-modal`}
      title={t('OBRASCI.OBRAZAC3.NAJVECI_INDUSTRIJSKI')}
      store={obrazac3Store}
      data={obrazac3Store.getIndustrijskiPotrosaci}
      children={() => <NewObrazac3GlavniIndustrijskiPotrosaciForm sistem={Obrazac3Sistem.KANALIZACIONI} />}
      submitForm={obrazac3Store.submitIndustrijskiPotrosaci}
      initialState={OBRAZAC_3_GLAVNI_INDUSTRIJSKI_POTROSACI_INITIAL_STATE}
      dataToChange={'industrijski_potrosaci_form'}
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
      closeOneModal={true}
    />
  );
});
