import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useParams } from 'react-router-dom';
import { authStore } from 'store';
import { IBaseObrazacStore } from 'typescript/NrizTypes';
import { disabledDatesOutsideOfAllowedYears } from 'utils/formatDate';
import { IGetCompany } from 'modules/company/company.types';
import { IGetFacility } from 'modules/facility/facility.types';
import { UserRoles } from 'modules/user/user.types';
import { obrasciService } from 'modules/obrasci/obrasci.service';

interface IOpsteProps {
  withPostrojenje: boolean;
  onChangePostrojenje?: (value: string, option: any) => any;
  onChangePreduzece?: (value: string, option: any) => any;
  store: IBaseObrazacStore;
  withoutGodina?: boolean;
  disableInput?: boolean;
}

export const OpsteGodinaPreduzecePostrojenje: React.FC<IOpsteProps> = observer(props => {
  const { t } = useTranslation();
  const { id } = useParams();

  const defaultPreduzeceOption: ISifarnikInitial | undefined = props.store.initialState.preduzece_id
    ? {
        label: props.store.initialState.preduzece.naziv,
        value: props.store.initialState.preduzece.id,
      }
    : undefined;

  const defaultPostrojenjeOption: ISifarnikInitial | undefined = props.store.initialState.postrojenje_id
    ? {
        label: props.store.initialState.postrojenje.naziv_postrojenja,
        value: props.store.initialState.postrojenje.id,
      }
    : undefined;

  const preduzeceOptionMaker = (options: IGetCompany[]) => {
    return options.map(company => ({
      label: company.naziv,
      value: (company as IGetCompany).id,
      disabled: !company.is_active,
      item: company,
    }));
  };
  const postrojenjeOptionMaker = (options: IGetFacility[]) => {
    return options.map(facility => ({
      label: facility.naziv_postrojenja,
      value: (facility as IGetFacility).id,
      disabled: !facility.is_active,
    }));
  };

  return (
    <>
      {!props.withoutGodina && (
        <FormDatePicker
          label={t('YEAR')}
          placeholder={t('SELECT_YEAR')}
          name="godina"
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 15 }}
          format="YYYY"
          picker={'year' as any}
          onChange={e => {
            obrasciStore.handleOpste('godina', e.$y);
          }}
          rules={[REQUIRED_FIELD_RULE(true)]}
          disabledDate={date => disabledDatesOutsideOfAllowedYears(date, obrasciStore.otkljucaneGodine)}
          disabled={
            obrasciStore.opste.preduzece_id === '' || !!obrasciStore.opste.godina || props.disableInput
            // || !obrasciStore.disabled_tab_button
          }
        />
      )}
      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('COMPANY.TITLE')}
        onChange={(value, option) => {
          obrasciStore.handleOpste('preduzece_id', value);
          !props.withoutGodina && obrasciStore.checkGodinaZakljucana(value);
          if (props.onChangePreduzece) props.onChangePreduzece(value, option);
        }}
        optionsMaker={preduzeceOptionMaker}
        placeholder={t('COMPANY.PLACEHOLDER.ENTER_NAME')}
        formName={'preduzece_id'}
        fetchOptinsList={obrasciStore.fetchPreduzecaPoObrascu}
        labelOptionAccessor="naziv"
        valueOptionAccessor="id"
        readOnly={false}
        defaultOption={defaultPreduzeceOption}
        rules={[REQUIRED_FIELD_RULE(true)]}
        disabled={
          obrasciStore.opste.preduzece_id !== '' ||
          props.disableInput ||
          // todo: ne znam zasto je ovo tu..
          // obrasciStore.disabled_tab_button ||
          authStore.getUserRole === UserRoles.CompanyOwner
        }
      />
      {props.withPostrojenje && (
        <FormSearchInfiniteScroll
          showSearch={true}
          label={t('FACILITY.TITLE')}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 15 }}
          placeholder={t('FACILITY.PLACEHOLDER.ENTER_NAME')}
          formName={'postrojenje_id'}
          optionsMaker={postrojenjeOptionMaker}
          fetchOptinsList={obrasciStore.fetchPostrojenjePoObrascu}
          labelOptionAccessor="naziv_postrojenja"
          valueOptionAccessor="id"
          readOnly={false}
          wait={obrasciStore.opste.preduzece_id === '' || obrasciStore.opste.postrojenje_id !== '' || !!id}
          defaultOption={defaultPostrojenjeOption}
          onChange={(value, option) => {
            obrasciStore.handleOpste('postrojenje_id', value);
            obrasciService.checkPostrojenjeTipObrascaSameObrazac();
            if (props.onChangePostrojenje) props.onChangePostrojenje(value, option);
          }}
          rules={[REQUIRED_FIELD_RULE(true)]}
          disabled={
            !obrasciStore.opste.preduzece_id ||
            (!props.withoutGodina && !obrasciStore.opste.godina) ||
            obrasciStore.opste.postrojenje_id !== '' ||
            props.disableInput
            // todo: ne znam zasto je ovo tu
            // obrasciStore.disabled_tab_button
          }
        />
      )}
    </>
  );
});
