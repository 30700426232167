import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { gio5ObrazacStore } from 'modules/obrasci/store/gio5/gio5.store';
import { IGio5UvozOtpada } from 'modules/obrasci/store/gio5/gio5.types';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import styles from './Gio5UvozOtpadaTable.module.scss';

export const Gio5UvozOtpadaTable: React.FC = observer(() => {
  styles;
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGio5UvozOtpada> = [
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
      width: 100,
      dataIndex: ['indeksni_broj_otpada', 'index_number'],
      align: 'center',
      key: 'indeksni_broj_otpada',
      render: (_: any, record: IGio5UvozOtpada) => (
        <>{`${record.indeksni_broj_otpada.index_number} ${record.indeksni_broj_otpada.waste_sr.toUpperCase()}`}</>
      ),
    },
    {
      title: 'Kolicina uvezenog otpada',
      width: 100,
      dataIndex: 'kolicina_uvezenog_otpada',
      align: 'center',
      key: 'kolicina_uvezenog_otpada',
    },
    {
      title: 'Zemlja izvoza',
      width: 100,
      dataIndex: ['zemlja_izvoza', 'name'],
      align: 'center',
      key: 'zemlja_izvoza',
    },
    {
      key: 'operation',
      fixed: 'right',
      width: 50,
      align: 'center',
      render: (_, record: IGio5UvozOtpada) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-gio5-uvoz-otpada'}
            onClick={() => {
              gio5ObrazacStore.handleChange('uvozOtpada', record);
              modalStore.changeModalName('gio5-uvoz-otpada-modal');
            }}
          />
        );
      },
    },
  ];
  return (
    <TableData
      name="uvoz-otpada-gio5"
      columns={baseColumns}
      dataSource={[...gio5ObrazacStore.initialState.uvoz_otpada]}
    />
  );
});
