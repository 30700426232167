import React from 'react';
import { ContentProps } from 'typescript/NrizTypes';
import styles from './InsideContentWrapper.module.scss';

export const InsideContentWrapper: React.FC<ContentProps> = props => {
  return (
    <div className={`${props.className} ${styles.insideContentWrapper}`}>
      {props.header && <div className={styles.header}>{props.header}</div>}
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};
