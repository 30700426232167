export const activateItems = (items: any): any[] => {
  const newArray = items.map((item: any) => {
    return { ...item, is_active: true };
  });
  return newArray;
};

export const aktivirajItems = (items: any): any[] => {
  const newArray = items.map((item: any) => {
    return { ...item, aktivan: true };
  });
  return newArray;
};
