import { Col, Row } from 'antd';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import {
  CONDITIONALLY_REQUIRED_FIELDS_RULE,
  MORE_THEN_0,
  MORE_THEN_0_IS_REQUIRED,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { aao2Store } from 'modules/obrasci/store/aao2/aao2.store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { IAao2UpravljanjeAmbalaznimOtpadom } from 'modules/obrasci/store/aao2/aao2.types';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import styles from './NewAao2UpravljanjeAmbalaznimOtpadomForm.module.scss';
import { drListOptions } from 'utils/optionMakers';

export const NewAao2UpravljanjeAmbalaznimOtpadomForm: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const [rKolicinaActive, setRKolicinaActive] = useState(!form.getFieldValue('druga_operacija_r_id'));
  const [dKolicinaActive, setDKolicinaActive] = useState(!form.getFieldValue('druga_operacija_d_id'));

  const defaultPackingTypeOption: ISifarnikInitial | undefined = aao2Store.getUpravljanjeAmbalaznimOtpadom
    .type_of_packing_id
    ? {
        value: aao2Store.getUpravljanjeAmbalaznimOtpadom.type_of_packing_id,
        label: (aao2Store.getUpravljanjeAmbalaznimOtpadom as IAao2UpravljanjeAmbalaznimOtpadom).type_of_packing
          .display_type_sr,
      }
    : undefined;

  const defaultROption: ISifarnikInitial | undefined = aao2Store.getUpravljanjeAmbalaznimOtpadom.druga_operacija_r_id
    ? {
        value: aao2Store.getUpravljanjeAmbalaznimOtpadom.druga_operacija_r_id,
        label: `${
          (aao2Store.getUpravljanjeAmbalaznimOtpadom as IAao2UpravljanjeAmbalaznimOtpadom).druga_operacija_r.name
        } ${
          (aao2Store.getUpravljanjeAmbalaznimOtpadom as IAao2UpravljanjeAmbalaznimOtpadom).druga_operacija_r
            .description_sr
        }`,
      }
    : undefined;

  const defaultDOption: ISifarnikInitial | undefined = aao2Store.getUpravljanjeAmbalaznimOtpadom.druga_operacija_d_id
    ? {
        value: aao2Store.getUpravljanjeAmbalaznimOtpadom.druga_operacija_d_id,
        label: `${
          (aao2Store.getUpravljanjeAmbalaznimOtpadom as IAao2UpravljanjeAmbalaznimOtpadom).druga_operacija_d.name
        } ${
          (aao2Store.getUpravljanjeAmbalaznimOtpadom as IAao2UpravljanjeAmbalaznimOtpadom).druga_operacija_d
            .description_sr
        }`,
      }
    : undefined;

  const onKolicinaRChange = () => {
    const R1Kolicina = Number(form?.getFieldValue('kolicina_za_r1_operaciju'));
    const R2Kolicina = Number(form?.getFieldValue('kolicina_za_drugu_r_operaciju'));

    const ukupnaRKolicina = R1Kolicina + R2Kolicina;

    !R1Kolicina && !R2Kolicina && !ukupnaRKolicina
      ? form?.setFieldValue('ukupna_kolicina_ponovno_iskoriscenog_otpada', null)
      : form?.setFieldValue('ukupna_kolicina_ponovno_iskoriscenog_otpada', ukupnaRKolicina);
  };

  const onKolicinaDChange = () => {
    const D1Kolicina = Number(form?.getFieldValue('kolicina_za_d1_operaciju'));
    const D2Kolicina = Number(form?.getFieldValue('kolicina_za_drugu_d_operaciju'));

    const ukupnaDKolicina = D1Kolicina + D2Kolicina;

    !D1Kolicina && !D2Kolicina && !ukupnaDKolicina
      ? form?.setFieldValue('ukupna_kolicina_odlozenog_otpada', null)
      : form?.setFieldValue('ukupna_kolicina_odlozenog_otpada', ukupnaDKolicina);
  };

  useEffect(() => {
    if (form.getFieldValue('druga_operacija_r_id')) setRKolicinaActive(true);
    if (form.getFieldValue('druga_operacija_d_id')) setDKolicinaActive(true);
    onKolicinaDChange();
    onKolicinaRChange();
  }, []);

  // const upravljanjeOtpadomOptions = Object.entries(aao2Store.upravljanje_otpadom_options).map(([_key, object]) => ({
  //   label: (object as IAao2KolicinaAmbalaze).type_of_packing.display_type_sr,
  //   value: object.type_of_packing_id,
  // }));

  return (
    <Col span={24}>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('OBRASCI.AAO1.PACKAGING_AMOUNT.PACKAGING_TYPE')}
        labelCol={{ span: 8 }}
        formName={'type_of_packing_id'}
        inputName={'type_of_packing_id'}
        labelOptionAccessor={'display_type_sr'}
        valueOptionAccessor={'id'}
        fetchOptinsList={aao2Store.fetchTypeOfPackingOptions}
        placeholder={t('OBRASCI.AAO1.PACKAGING_AMOUNT.PLACEHOLDERS.PACKAGING_TYPE')}
        defaultOption={defaultPackingTypeOption}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                aao2Store.upravljanjeAmbalaznimOtpadom.type_of_packing_id !== value &&
                aao2Store.initialState.upravljanje_ambalaznim_otpadom.some(item => {
                  return value === item.type_of_packing_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.AAO1.PACKAGING_AMOUNT.ERROR.TYPE_OF_PACKAGING_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
      />
      {/* <Form.Item
        name={'type_of_packing_id'}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                aao2Store.getUpravljanjeAmbalaznimOtpadom.type_of_packing_id !== value &&
                aao2Store.initialState.upravljanje_ambalaznim_otpadom.some(item => {
                  return value === item.type_of_packing_id;
                })
              ) {
                return Promise.reject(new Error(t('VALIDATION.ALREADY_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
        label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.PACKAGING_TYPE')}
        labelCol={{ span: 8 }}
      >
        <Select
          defaultValue={defaultPackingTypeOption}
          options={upravljanjeOtpadomOptions}
          placeholder={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.PLACEHOLDERS.PACKAGING_TYPE')}
          onSelect={() =>
            form.validateFields(['preuzeta_kolicina_komunalnog_otpada', 'preuzeta_kolicina_nekomunalnog_otpada'])
          }
        ></Select>
      </Form.Item> */}
      <FormInputNumber
        name="preuzeta_kolicina_komunalnog_otpada"
        label={t('OBRASCI.AAO2.MANAGEMENT_OF_PACKAGING_WASTE.MUNICIPAL_WASTE')}
        labelCol={{ span: 8 }}
        placeholder={''}
        inputName={'preuzeta_kolicina_komunalnog_otpada'}
        rules={[
          ...MORE_THEN_0_IS_REQUIRED,
          {
            validator(_, value) {
              const preuzetaNekomunalna = Number(form.getFieldValue('preuzeta_kolicina_nekomunalnog_otpada'));
              const ukupnaPonovnoIskoriscena = Number(
                form.getFieldValue('ukupna_kolicina_ponovno_iskoriscenog_otpada')
              );
              const ukupnaOdlozena = Number(form.getFieldValue('ukupna_kolicina_odlozenog_otpada'));
              const ukupnaPreuzeta = preuzetaNekomunalna + value;
              if (ukupnaPreuzeta < ukupnaOdlozena + ukupnaPonovnoIskoriscena)
                return Promise.reject(new Error(t('OBRASCI.AAO1.ZBIR_PONOVNO_ISKORISCENJE_PORUKA')));
              return Promise.resolve();
            },
          },
        ]}
        // rules={[
        //   {
        //     validator(_, value) {
        //       const vrstaAmbalaze = form.getFieldValue('type_of_packing_id');
        //       if (!vrstaAmbalaze) return Promise.reject(new Error('Morate odabrati vrstu ambalaže'));
        //       const ukupnaKolicina =
        //         //@ts-ignore
        //         Number(aao2Store.upravljanje_otpadom_options[vrstaAmbalaze].ukupna_kolicina);
        //       const preuzeta = Number(form.getFieldValue('preuzeta_kolicina_nekomunalnog_otpada'));
        //       const ukupnaPonovnoIskoriscena = Number(
        //         form.getFieldValue('ukupna_kolicina_ponovno_iskoriscenog_otpada')
        //       );
        //       const ukupnaOdlozena = Number(form.getFieldValue('ukupna_kolicina_odlozenog_otpada'));
        //       if (Number(value) + preuzeta < 0 || Number(value) + preuzeta < ukupnaOdlozena + ukupnaPonovnoIskoriscena)
        //         return Promise.reject(new Error(t('OBRASCI.AAO1.ZBIR_PONOVNO_ISKORISCENJE_PORUKA')));
        //       else if (value + preuzeta > ukupnaKolicina)
        //         return Promise.reject(new Error(t('OBRASCI.AAO1.ZBIR_VRSTA_AMBALAZE_PORUKA')));
        //       return Promise.resolve();
        //     },
        //   },
        // ]}
        // onInputChange={() => form.validateFields(['preuzeta_kolicina_nekomunalnog_otpada'])}
        colon={true}
      />
      <FormInputNumber
        name="preuzeta_kolicina_nekomunalnog_otpada"
        label={t('OBRASCI.AAO2.MANAGEMENT_OF_PACKAGING_WASTE.NON_MUNICIPAL_WASTE')}
        labelCol={{ span: 8 }}
        placeholder={''}
        rules={[
          ...MORE_THEN_0_IS_REQUIRED,
          {
            validator(_, value) {
              const preuzetaKomunalna = Number(form.getFieldValue('preuzeta_kolicina_komunalnog_otpada'));
              const ukupnaPonovnoIskoriscena = Number(
                form.getFieldValue('ukupna_kolicina_ponovno_iskoriscenog_otpada')
              );
              const ukupnaOdlozena = Number(form.getFieldValue('ukupna_kolicina_odlozenog_otpada'));
              const ukupnaPreuzeta = preuzetaKomunalna + value;
              if (ukupnaPreuzeta < ukupnaOdlozena + ukupnaPonovnoIskoriscena)
                return Promise.reject(new Error(t('OBRASCI.AAO1.ZBIR_PONOVNO_ISKORISCENJE_PORUKA')));
              return Promise.resolve();
            },
          },
        ]}
        // rules={[
        //   {
        //     validator(_, value) {
        //       const vrstaAmbalaze = form.getFieldValue('type_of_packing_id');
        //       if (!vrstaAmbalaze) return Promise.reject(new Error('Morate odabrati vrstu ambalaže'));
        //       const ukupnaKolicina = Number(
        //         //@ts-ignore
        //         aao2Store.upravljanje_otpadom_options[vrstaAmbalaze].ukupna_kolicina
        //       );
        //       const preuzeta = Number(form.getFieldValue('preuzeta_kolicina_komunalnog_otpada'));
        //       const ukupnaPonovnoIskoriscena = Number(
        //         form.getFieldValue('ukupna_kolicina_ponovno_iskoriscenog_otpada')
        //       );
        //       const ukupnaOdlozena = Number(form.getFieldValue('ukupna_kolicina_odlozenog_otpada'));
        //       if (Number(value) + preuzeta < 0 || Number(value) + preuzeta < ukupnaOdlozena + ukupnaPonovnoIskoriscena)
        //         return Promise.reject(
        //           new Error(
        //             'Zbir preuzetih količina mora biti veći od 0 i ne sme biti manji od zbira Ukupne količine ponovno iskorišćenog otpada i Ukupne količine odloženog otpada'
        //           )
        //         );
        //       else if (value + preuzeta > ukupnaKolicina)
        //         return Promise.reject(
        //           new Error(
        //             'Zbir preuzetih količina ne može biti veći od ukupne količine za odgovarajuću vrstu ambalaže iz taba Količina ambalaže'
        //           )
        //         );
        //       return Promise.resolve();
        //     },
        //   },
        // ]}
        inputName={'preuzeta_kolicina_nekomunalnog_otpada'}
        colon={true}
        // onInputChange={() => form.validateFields(['preuzeta_kolicina_komunalnog_otpada'])}
      />
      <Row>
        <Col span={12}>
          <Row>
            <Col span={15} className={styles.title}>
              <h4>
                {/* <span style={{ color: 'red' }}>* </span> */}
                {t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.RECYCLED_PACKAGING_WASTE')}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="kolicina_za_r1_operaciju"
                label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.R1_OPERATION_AMOUNT')}
                labelCol={{ span: 18 }}
                placeholder={''}
                inputName={'kolicina_za_r1_operaciju'}
                rules={MORE_THEN_0}
                onInputChange={() => {
                  onKolicinaRChange();
                  form.validateFields([
                    'preuzeta_kolicina_nekomunalnog_otpada',
                    'preuzeta_kolicina_komunalnog_otpada',
                    'ukupna_kolicina_odlozenog_otpada',
                    'ukupna_kolicina_ponovno_iskoriscenog_otpada',
                  ]);
                }}
                colon={true}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormSearchInfiniteScroll
                showSearch={true}
                formName={'druga_operacija_r_id'}
                fetchOptinsList={aao2Store.fetchROptions}
                labelOptionAccessor="name"
                valueOptionAccessor="id"
                onChange={value => {
                  setRKolicinaActive(false);
                  if (!value) {
                    setRKolicinaActive(true);
                    form.setFieldValue('kolicina_za_drugu_r_operaciju', null);
                    onKolicinaRChange();
                    form.validateFields([
                      'kolicina_za_drugu_r_operaciju',
                      'preuzeta_kolicina_nekomunalnog_otpada',
                      'preuzeta_kolicina_komunalnog_otpada',
                    ]);
                  }
                }}
                label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.SECOND_R_OPERATION')}
                labelCol={{ span: 18 }}
                inputName={'druga_operacija_r_id'}
                colon={true}
                defaultOption={defaultROption}
                placeholder={''}
                optionsMaker={drListOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="kolicina_za_drugu_r_operaciju"
                label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.R2_OPERATION_AMOUNT')}
                labelCol={{ span: 18 }}
                placeholder={''}
                inputName={'kolicina_za_drugu_r_operaciju'}
                rules={[REQUIRED_FIELD_RULE(!rKolicinaActive), ...MORE_THEN_0]}
                disabled={rKolicinaActive}
                onInputChange={() => {
                  onKolicinaRChange();
                  form.validateFields([
                    'preuzeta_kolicina_nekomunalnog_otpada',
                    'preuzeta_kolicina_komunalnog_otpada',
                    'ukupna_kolicina_odlozenog_otpada',
                    'ukupna_kolicina_ponovno_iskoriscenog_otpada',
                  ]);
                }}
                colon={true}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="ukupna_kolicina_ponovno_iskoriscenog_otpada"
                label={`** ${t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.TOTAL_RECYCLED')}`}
                labelCol={{ span: 18 }}
                disabled={true}
                placeholder={''}
                inputName={'ukupna_kolicina_ponovno_iskoriscenog_otpada'}
                onInputChange={() => {
                  onKolicinaRChange();
                  form.validateFields(['preuzeta_kolicina_nekomunalnog_otpada', 'preuzeta_kolicina_komunalnog_otpada']);
                }}
                // rules={[
                //   {
                //     validator(_, value) {
                //       const ukupanOdlozen = form.getFieldValue('ukupna_kolicina_odlozenog_otpada');
                //       if (Number(value) + Number(ukupanOdlozen) === 0)
                //         return Promise.reject(new Error('Barem jedna količina mora biti veća od 0'));
                //       return Promise.resolve();
                //     },
                //   },
                // ]}
                rules={[
                  ...MORE_THEN_0,
                  CONDITIONALLY_REQUIRED_FIELDS_RULE(
                    ['ukupna_kolicina_ponovno_iskoriscenog_otpada', 'ukupna_kolicina_odlozenog_otpada'],
                    'Potrebno je da barem jedna od vrednosti bude veća od 0!'
                  ),
                ]}
                colon={true}
              />
            </Col>
          </Row>
        </Col>
        <Col span={11} offset={1}>
          <Row>
            <Col span={15} className={styles.title}>
              <h4>
                {/* <span style={{ color: 'red' }}>* </span> */}
                {t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.DISCARDED_PACKAGING_WASTE')}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="kolicina_za_d1_operaciju"
                label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.D1_OPERATION_AMOUNT')}
                labelCol={{ span: 18 }}
                placeholder={''}
                inputName={'kolicina_za_d1_operaciju'}
                rules={MORE_THEN_0}
                onInputChange={() => {
                  onKolicinaDChange();
                  form.validateFields([
                    'preuzeta_kolicina_nekomunalnog_otpada',
                    'preuzeta_kolicina_komunalnog_otpada',
                    'ukupna_kolicina_odlozenog_otpada',
                    'ukupna_kolicina_ponovno_iskoriscenog_otpada',
                  ]);
                }}
                colon={true}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormSearchInfiniteScroll
                showSearch={true}
                formName={'druga_operacija_d_id'}
                fetchOptinsList={aao2Store.fetchDOptions}
                labelOptionAccessor="name"
                valueOptionAccessor="id"
                onChange={value => {
                  setDKolicinaActive(false);
                  if (!value) {
                    setDKolicinaActive(true);
                    form.setFieldValue('kolicina_za_drugu_d_operaciju', null);
                    onKolicinaDChange();
                    form.validateFields([
                      'kolicina_za_drugu_d_operaciju',
                      'preuzeta_kolicina_nekomunalnog_otpada',
                      'preuzeta_kolicina_komunalnog_otpada',
                    ]);
                  }
                }}
                label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.SECOND_D_OPERATION')}
                labelCol={{ span: 18 }}
                inputName={'druga_operacija_d_id'}
                colon={true}
                defaultOption={defaultDOption}
                placeholder={''}
                optionsMaker={drListOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="kolicina_za_drugu_d_operaciju"
                label={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.D2_OPERATION_AMOUNT')}
                labelCol={{ span: 18 }}
                placeholder={''}
                inputName={'kolicina_za_drugu_d_operaciju'}
                onInputChange={() => {
                  onKolicinaDChange();
                  form.validateFields([
                    'preuzeta_kolicina_nekomunalnog_otpada',
                    'preuzeta_kolicina_komunalnog_otpada',
                    'ukupna_kolicina_odlozenog_otpada',
                    'ukupna_kolicina_ponovno_iskoriscenog_otpada',
                  ]);
                }}
                rules={[REQUIRED_FIELD_RULE(!dKolicinaActive), ...MORE_THEN_0]}
                disabled={dKolicinaActive}
                colon={true}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="ukupna_kolicina_odlozenog_otpada"
                label={`** ${t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.TOTAL_DISCARDED')}`}
                labelCol={{ span: 18 }}
                disabled={true}
                placeholder={''}
                inputName={'ukupna_kolicina_odlozenog_otpada'}
                onInputChange={() => {
                  onKolicinaDChange();
                  form.validateFields(['preuzeta_kolicina_nekomunalnog_otpada', 'preuzeta_kolicina_komunalnog_otpada']);
                }}
                rules={[
                  ...MORE_THEN_0,
                  CONDITIONALLY_REQUIRED_FIELDS_RULE(
                    ['ukupna_kolicina_ponovno_iskoriscenog_otpada', 'ukupna_kolicina_odlozenog_otpada'],
                    'Potrebno je da barem jedna od vrednosti bude veća od 0!'
                  ),
                ]}
                // rules={[
                //   {
                //     validator(_, value) {
                //       const ukupanPonovnoIskoriscen = form.getFieldValue('ukupna_kolicina_ponovno_iskoriscenog_otpada');
                //       if (Number(value) + Number(ukupanPonovnoIskoriscen) === 0)
                //         return Promise.reject(new Error('Barem jedna količina mora biti veća od 0'));
                //       return Promise.resolve();
                //     },
                //   },
                // ]}
                colon={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
});
