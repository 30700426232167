import to from 'await-to-js';
import { zakljucavanjeRepo } from './zakljucavanje.repo';
import {
  IGodina,
  IGodinaTipObrasca,
  IToggleLockPayload,
  IZakljucavanjeGodinaIzuzeci,
  IZakljucavanjeGodinaIzuzetakPayload,
  IZakljucavanjeObrazacaFilters,
  OtkljucaniZakljucaniTipoviObrazaca,
} from './zakljucavanje.types';
import { ITipObrasca, OTKLJUCANI_OBRASCI, TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import dayjs from 'dayjs';
import { ITablePagination } from 'components/TableData/TableData';
import { ColumnType } from 'antd/es/table';
import { ZAKLJUCAVANJE_TIP_OBRASCA_COLUMN_MAP, ZAKLJUCAVANJE_TIP_OBRASCA_FIELDS_MAP } from './zakljucavanje.constants';
import { FormInstance } from 'antd';
import { IFormScrollWithObjProps } from 'typescript';

class ZakljucavanjeService {
  fetchGodine = async () => {
    const [err, res] = await to(zakljucavanjeRepo.fetchGodine());
    if (err) return null;
    const sortedResponse = res.sort((a, b) => b.godina - a.godina);

    return sortedResponse;
  };

  otkljucajGodinu = async (godine: IGodina[], tipoviObrazaca: IGodinaTipObrasca[]) => {
    const currentYear = dayjs().year();
    const highestAvailableYear = Math.max(...godine.map(godina => godina.godina));
    if (currentYear >= highestAvailableYear) {
      const validPayload = {
        godina: highestAvailableYear + 1,
        tipovi_obrazaca: tipoviObrazaca.map(tip => {
          return { ...tip, zakljucana_godina: false };
        }),
      };
      try {
        await zakljucavanjeRepo.otkljucajGodinu(validPayload);
        return [...godine, { godina: highestAvailableYear + 1 }].sort((a, b) => b.godina - a.godina);
      } catch {
        return null;
      }
    }
    return null;
  };

  fetchTipoviObrazacaPoGodini = async (godina: number) => {
    const [err, res] = await to(zakljucavanjeRepo.fetchTipoviObrazacaPoGodini(godina));
    if (err) return null;
    const sortedTipoviObrazaca = res.tipovi_obrazaca
      // .filter(tip => tip.tip_obrasca.period_izvestavanja === ObrazacPeriodIzvestavanja.GODISNJE)
      .sort((a, b) => {
        if (a.tip_obrasca.skraceni_naziv < b.tip_obrasca.skraceni_naziv) {
          return -1;
        }
        if (a.tip_obrasca.skraceni_naziv > b.tip_obrasca.skraceni_naziv) {
          return 1;
        }
        return 0;
      });

    return { ...res, tipovi_obrazaca: sortedTipoviObrazaca };
  };

  toggleLockTipObrascaZaGodinu = async (
    initialTipoviObrazaca: IGodinaTipObrasca[],
    godina: number,
    tipoviObrazaca: IGodinaTipObrasca[],
    isLocked: boolean
  ) => {
    const zakljucajSveTipove = tipoviObrazaca.length > 1;

    const validTipoviObrazaca = zakljucajSveTipove
      ? tipoviObrazaca.filter(obrazac => !OTKLJUCANI_OBRASCI.includes(obrazac.tip_obrasca.skraceni_naziv))
      : tipoviObrazaca;

    const validPayload = validTipoviObrazaca.map(tip => {
      return { ...tip, zakljucana_godina: isLocked };
    });

    const payload: IToggleLockPayload = { tipovi_obrazaca: validPayload };

    try {
      await zakljucavanjeRepo.toggleLockTipObrascaZaGodinu(godina, payload);
      const idsToUpdate = validTipoviObrazaca.map(obrazac => obrazac.tip_obrasca_id);
      const updatedItems = this.updateGodina(initialTipoviObrazaca, idsToUpdate, isLocked);

      return updatedItems;
    } catch {
      return initialTipoviObrazaca;
    }
  };

  updateGodina = (tipoviObrazaca: IGodinaTipObrasca[], tipObrascaIds: string[], isLocked: boolean) => {
    return tipoviObrazaca.map(tip => {
      if (tipObrascaIds.includes(tip.tip_obrasca_id)) {
        return { ...tip, zakljucana_godina: isLocked };
      }
      return tip;
    });
  };

  fetchIzuzeciPoGodini = async (filters: ITablePagination & Partial<IZakljucavanjeGodinaIzuzeci>) => {
    const [err, res] = await to(zakljucavanjeRepo.fetchIzuzeciPoGodini(filters));
    if (err) return undefined;
    return res;
  };

  postIzuzetakPoGodini = async (godina: IGodina, payload: IZakljucavanjeGodinaIzuzetakPayload) => {
    const validPayload = { ...payload, godina: godina.godina, zakljucana_godina: false };
    const [err] = await to(zakljucavanjeRepo.postIzuzetakPoGodini(validPayload));
    if (err) return null;
    return true;
  };

  deleteIzuzetakPoGodini = async (id: string) => {
    const [err] = await to(zakljucavanjeRepo.deleteIzuzetakPoGodini(id));
    if (err) return null;
    return true;
  };

  fetchTipoviObrazacaPoPreduzecu = async (id: string, tipoviPoGodini: IGodinaTipObrasca[]) => {
    try {
      const response = await zakljucavanjeRepo.fetchTipoviObrazacaPoPreduzecu(id);
      const otkljucaniTipoviUGodini = tipoviPoGodini.reduce((otkljucaniTipovi: string[], tip) => {
        if (tip.zakljucana_godina) return [...otkljucaniTipovi, tip.tip_obrasca_id];
        return otkljucaniTipovi;
      }, []);
      const validTipovi = response.filter(tip => otkljucaniTipoviUGodini.includes(tip.id));
      return validTipovi;
    } catch {
      return [];
    }
  };

  fetchZakljucavanjeObrazacaList = async (filters: Partial<IZakljucavanjeObrazacaFilters>, isLocked: boolean) => {
    const validFilters = { ...filters, zakljucan_obrazac: isLocked };
    const [err, res] = await to(zakljucavanjeRepo.fetchZakljucavanjeObrazacaList(validFilters));
    if (err) return { items: [] };
    return res;
  };

  zakljucajObrasce = async (payload: Partial<IZakljucavanjeObrazacaFilters>, isLocked: boolean) => {
    const validPayload = { ...payload, zakljucan_obrazac: isLocked };
    const [err] = await to(zakljucavanjeRepo.zakljucajObrasce(validPayload));
    if (err) return false;
    return true;
  };
  getConfigByTipObrasca = (
    tip: ITipObrasca | null,
    form: FormInstance<any>
  ): { fields: IFormScrollWithObjProps[]; columns: ColumnType<any>[] } => {
    const obrasciWithOnlyBaseColumns = [
      TIPOVI_OBRAZACA.AAO1,
      TIPOVI_OBRAZACA.AAO2,
      TIPOVI_OBRAZACA.DEP2,
      TIPOVI_OBRAZACA.PTP2,
      TIPOVI_OBRAZACA.PZV1,
      TIPOVI_OBRAZACA.VOC,
    ];
    if (!tip || obrasciWithOnlyBaseColumns.includes(tip.skraceni_naziv)) return { fields: [], columns: [] };
    const getValidTipObrasca = (): TIPOVI_OBRAZACA => {
      switch (tip.skraceni_naziv) {
        case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1:
          return TIPOVI_OBRAZACA.OBRAZAC_1;
        case TIPOVI_OBRAZACA.GVE:
          return TIPOVI_OBRAZACA.OBRAZAC_2;
        case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2:
          return TIPOVI_OBRAZACA.OBRAZAC_2;
        case TIPOVI_OBRAZACA.OTV:
          return TIPOVI_OBRAZACA.OBRAZAC_3;
        case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3:
          return TIPOVI_OBRAZACA.OBRAZAC_3;
        case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4:
          return TIPOVI_OBRAZACA.OBRAZAC_4;
        case TIPOVI_OBRAZACA.GIO1:
          return TIPOVI_OBRAZACA.OBRAZAC_5;
        case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5:
          return TIPOVI_OBRAZACA.OBRAZAC_5;
        default:
          return tip.skraceni_naziv;
      }
    };
    const columns = ZAKLJUCAVANJE_TIP_OBRASCA_COLUMN_MAP[getValidTipObrasca()];
    const fields = ZAKLJUCAVANJE_TIP_OBRASCA_FIELDS_MAP[getValidTipObrasca()](form);

    return { fields, columns };
  };

  returnIsLocked = (value: OtkljucaniZakljucaniTipoviObrazaca) => {
    switch (value) {
      case OtkljucaniZakljucaniTipoviObrazaca.OTKLJUCANO:
        return true;
      case OtkljucaniZakljucaniTipoviObrazaca.ZAKLJUCANO:
        return false;
    }
  };
}

export const zakljucavanjeService = new ZakljucavanjeService();
