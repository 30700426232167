import { Col, Form, Select } from 'antd';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { observer } from 'mobx-react-lite';
import { MAX_AND_MIN_NUMBER, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { useTranslation } from 'react-i18next';
import styles from './NewObrazac3KanalizacioniSistemiForm.module.scss';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { Obrazac3GlavniIndustrijskiPotrosaciTable } from '../NewObrazac3VodovodniSistemiForm/Obrazac3GlavniIndustrijskiPotrosaciTable/Obrazac3GlavniIndustrijskiPotrosaciTable';
import { Obrazac3Sistem } from 'modules/obrasci/store/obrazac3/obrazac3.types';

export const NewObrazac3KanalizacioniSistemiForm: React.FC<ICrudTypeModalProps> = observer(() => {
  const { t } = useTranslation();

  // forcerender renderuje i form.iteme iz ove komponente iako su hidden. nadji workaround

  const merenjaOptions = [
    { value: 'ne_meri_se', label: t('OBRASCI.OBRAZAC3.NE_VRSE_SE') },
    { value: 'volumetrijski', label: t('OBRASCI.OBRAZAC3.VOLUMETRIJSKI') },
    { value: 'mernom_opremom', label: t('OBRASCI.OBRAZAC3.MERNOM_OPREMOM') },
  ];

  return (
    <>
      <Col span={24} className={styles.wrapper}>
        <FormInputNumber
          name="procenat_ukupnog_stanovnistva_prikacenog_na_kanalizaciju"
          label={t('OBRASCI.OBRAZAC3.PROCENAT_UKUPNOG_STANOVNISTVA_KANALIZACIJA')}
          placeholder={''}
          inputName={'procenat_ukupnog_stanovnistva_prikacenog_na_kanalizaciju'}
          colon={true}
          rules={[
            {
              validator: (_: any, value: number) => {
                if (value === null) return Promise.resolve();
                if (value <= 0 || value > 100) {
                  return Promise.reject(new Error(`Broj mora biti veći od 0 i ne veći od 100`));
                }
                return Promise.resolve();
              },
            },
          ]}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        />
        <Form.Item
          label={t('OBRASCI.OBRAZAC3.DA_LI_SE_VRSE_MERENJA')}
          name={'da_li_se_vrse_merenja_kolicina_otpadnih_voda_na_kanalizacionom_sistemu'}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          rules={[REQUIRED_FIELD_RULE(true)]}
        >
          <Select options={merenjaOptions}></Select>
        </Form.Item>
        <h4>{t('OBRASCI.OBRAZAC3.DA_LI_KONTROLA_KVALITETA')}</h4>
        <FormInputNumber
          name="na_sistemu"
          label={t('OBRASCI.OBRAZAC3.NA_SISTEMU')}
          placeholder={''}
          inputName={'na_sistemu'}
          colon={true}
          rules={MAX_AND_MIN_NUMBER(1, 366)}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        />
        <FormInputNumber
          name="na_izlivu"
          label={t('OBRASCI.OBRAZAC3.NA_IZLIVU')}
          placeholder={''}
          inputName={'na_izlivu'}
          colon={true}
          rules={MAX_AND_MIN_NUMBER(1, 366)}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        />
      </Col>
      <Col span={24}>
        <InsideContentWrapper
          header={
            <WizardObrazacListHeader
              name={'obrazac3-glavni-potrosaci-form'}
              modalName={`obrazac3-glavni-potrosaci-${Obrazac3Sistem.KANALIZACIONI}-modal`}
              title={t('OBRASCI.OBRAZAC3.NAJVECI_INDUSTRIJSKI')}
            />
          }
        >
          <Obrazac3GlavniIndustrijskiPotrosaciTable sistem={Obrazac3Sistem.KANALIZACIONI} />
        </InsideContentWrapper>
      </Col>
    </>
  );
});
