import { axios } from 'modules/axios';
import { IObrazacIzvestajFilters, ObrazacIzvestajiAllReports } from './obrazacIzvestaji.types';
import { OBRAZAC_IZVESTAJI_ALL_MOCKS } from './obrazacIzvestaji.mocks';
import { ITablePagination } from 'components/TableData/TableData';

class ObrazacIzvestajiRepo {
  izvestajiBaseUrl = '/izvestaji-po-obrascima/';
  // Todo: check will excel and pdf be same api route but with different body param, or seperate route?
  downloadIzvestajUrl = `${this.izvestajiBaseUrl}stampa`;

  // Todo: add pagination
  getObrazacByType = (
    payload: IObrazacIzvestajFilters,
    pagination: ITablePagination
  ): Promise<{ items: ObrazacIzvestajiAllReports }> => {
    return process.env.NODE_ENV === 'development'
      ? Promise.resolve({
          items: OBRAZAC_IZVESTAJI_ALL_MOCKS[payload.type] as ObrazacIzvestajiAllReports,
          page: 1,
          total: 20,
          pages: 1,
          size: 10,
        })
      : axios.post(this.izvestajiBaseUrl, payload, { params: pagination });
  };

  exportToExcel = (payload: IObrazacIzvestajFilters) => {
    return axios.post<Blob>(this.downloadIzvestajUrl, payload);
  };

  exportToPDF = (payload: IObrazacIzvestajFilters) => {
    return axios.post<Blob>(this.downloadIzvestajUrl, payload, { responseType: 'blob' });
  };
}

export const obrazacIzvestajiRepo = new ObrazacIzvestajiRepo();
