import { IPostDep1, IPostObrazacDep1Mesto } from './dep1.types';

export const DEP1_INITIAL_STATE: IPostDep1 = {
  godina: '',
  preduzece_id: '',
  napomena: '',
  nesanitarna_deponija_id: '',
  prosecne_godisnje_kolicine_otpada: null,
  ograda_oko_nesanitarne_deponije: false,
  kapija_rampa_na_ulazu: false,
  cuvarska_sluzba: false,
  kolska_vaga: false,
  drenazni_sistem_za_prikupljanje_procednih_otpadnih_voda: false,
  sistem_za_preciscavanje_procednih_otpadnih_voda: false,
  sistem_za_otplinjavanje_deponijskog_gasa: false,
  region_za_upravljanje_otpada_kom_pripada_opstina: '',
  lokalne_samouprave_sa_kojima_je_potpisan_sporazum_o_zajednickom_upravljanju_otpadom: '',
  nesanitarna_deponija_smetliste_je_id: '',
  operativni_status_nesanitarne_deponije_id: '',
  dalje_odlaganje_otpada_na_nesanitarnoj_deponiji_id: '',
  nacin_odlaganja_otpada_id: '',
  prekrivanje_inertnim_materijalom_id: '',
  period_za_koji_se_predvidja_trajanje_i_eksploatacija_nesanitarne_deponije_id: '',
  vodjenje_evidencije_o_kolicinama_i_vrstama_otpada_id: '',
  deo_ili_cela_nesanitarna_deponija_u_poplavnom_podrucju_id: '',
  izradjen_projekat_sanacije_zatvaranja_i_rekultivacije: false,
  godina_izrade_projekta_sanacije: 0,
  pribavljena_saglasnost_na_projekat: false,
  godina_pribavljanja_saglasnosti_na_projekat: 0,
  izvodjenje_radova_po_projektu_sanacije_zatvaranja_i_rekultivacije: '',
  radovi_koji_se_izvode_po_projektu_id: '',
  sprovodjenje_mera_zastite_zivotne_sredine_propisane_projektom_id: '',
  sprovodjenje_monitoringa_propisanog_projektom_id: '',
  potrebna_izrada_ili_azuriranje_projekta_id: '',
  obrazac_dep1_mesta: [],
};

export const DEP1_MESTO_INITIAL_STATE: IPostObrazacDep1Mesto = {
  mesto_id: '',
};

export const DEP1_FILTERS_INITIAL_STATE = {
  godina: '',
  preduzece_id: '',
  nesanitarna_deponija_id: '',
  godina_izrade_projekta_sanacije: '',
  godina_pribavljanja_saglasnosti_na_projekat: '',
};
