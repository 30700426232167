import { Col } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { UpravljanjeKomunalnimOtpadom } from './components/UpravljanjeKomunalnimOtpadom/UpravljanjeKomunalnimOtpadom';
import { PredatoTable } from './components/PredatoTable/PredatoTable';
import { KolicinaSakupljenogOtpadaIzReciklaze } from './components/KolicinaSakupljenogOtpadaIzReciklaze/KolicinaSakupljenogOtpadaIzReciklaze';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';

export const NewUpravljanjeForm: React.FC<ICrudTypeModalProps> = observer(props => {
  return (
    <Col span={24}>
      <UpravljanjeKomunalnimOtpadom form={props.form} />
      <KolicinaSakupljenogOtpadaIzReciklaze />
      <PredatoTable />
    </Col>
  );
});
