import { ColumnsType } from 'antd/es/table';
import { IGetWaterArea, IPostWaterArea } from './waterArea.types';
import { FormInstance } from 'antd';
import { t } from 'i18next';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';

export const WATER_AREA_INITIAL_STATE: IPostWaterArea = {
  water_area_name: '',
  is_active: true,
};
export const WATER_AREA_TABLE_DATA: ColumnsType<IGetWaterArea> = [
  { title: t('UNSANITARY_LANDFILL.NAME'), width: '700px', dataIndex: 'water_area_name', key: 'water_area_name' },
];
export const WATER_AREA_FORM_OPTIONS = (_: FormInstance<IGetWaterArea>) => {
  return [
    {
      type_of_form: 'input',
      name: 'water_area_name',
      label: t('UNSANITARY_LANDFILL.NAME'),
      inputName: 'water_area_name',
    } as InputSifarniciFormProps,
  ];
};
