import { Col, Row, SelectProps } from 'antd';
import { Content } from 'components/Content/Content';
import { Select } from 'components/Select/Select';
import TableData, { ITablePagination } from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { Button } from 'components/Button/Button';
import {
  OBRAZAC_IZVESTAJI_SUBTYPE_OPTIONS_MAP,
  OBRAZAC_IZVESTAJI_TYPE_OPTIONS,
} from 'modules/obrazacIzvestaji/obrazacIzvestaji.constants';
import { obrazacIzvestajiStore } from 'modules/obrazacIzvestaji/obrazacIzvestaji.store';
import {
  IzvestajiSubtypes,
  Obrazac1IzvestajiEnum,
  ObrazacIzvestajTypeEnum,
} from 'modules/obrazacIzvestaji/obrazacIzvestaji.types';
import { OBRAZAC_IZVESTAJI_COLUMN_MAP } from 'modules/obrazacIzvestaji/obrazacIzvestajiColumns.constants';
import { filtersStore } from 'store/filteri.store';
import { ObrazacIzvestajiFilters } from './components/ObrazacIzvestajiFilters/ObrazacIzvestajiFilters';

import { ColumnProps } from 'antd/es/table';
import styles from './ObrazacIzvestaji.module.scss';

export const ObrazacIzvestaji = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const getIzvestajType = () =>
    (searchParams.get('type') as ObrazacIzvestajTypeEnum) || ObrazacIzvestajTypeEnum.OBRAZAC_1;
  const getIzvestajSubtype = () => (searchParams.get('subtype') as IzvestajiSubtypes) || Obrazac1IzvestajiEnum.GORIVA;

  const [izvestajType, setIzvestajType] = useState<ObrazacIzvestajTypeEnum>(getIzvestajType());
  const [izvestajSubtype, setIzvestajSubtype] = useState<IzvestajiSubtypes>(getIzvestajSubtype());

  const selectedIzvestajSubtypeOptions = OBRAZAC_IZVESTAJI_SUBTYPE_OPTIONS_MAP[izvestajType];
  const columnsByIzvestajType = izvestajSubtype
    ? OBRAZAC_IZVESTAJI_COLUMN_MAP[izvestajSubtype](obrazacIzvestajiStore.obrazacIzvestaj)
    : [];

  const handleChangeIzvestajType: SelectProps['onChange'] = (izvestajType: ObrazacIzvestajTypeEnum) => {
    const newObrazacFirstSubtype = OBRAZAC_IZVESTAJI_SUBTYPE_OPTIONS_MAP[izvestajType]?.[0]?.value;
    setIzvestajType(izvestajType);
    setIzvestajSubtype(newObrazacFirstSubtype);
    setSearchParams({ type: izvestajType, subtype: newObrazacFirstSubtype });
  };

  const handleChangeIzvestajSubtype: SelectProps['onChange'] = izvestajSubtype => {
    setIzvestajSubtype(izvestajSubtype);
    setSearchParams({ type: izvestajType, subtype: izvestajSubtype });
  };

  const refetchData = (pagination: ITablePagination) => {
    if (isEmpty(filtersStore.filters.obrazacIzvestaji)) return Promise.reject();
    if (!izvestajSubtype) return Promise.reject();
    return obrazacIzvestajiStore.getObrazacIzvestaj(izvestajSubtype, pagination);
  };

  const handleExportPdf = () => {
    obrazacIzvestajiStore.exportToExcel(izvestajSubtype);
  };

  const handleExportExcel = () => {
    obrazacIzvestajiStore.exportToPDF(izvestajSubtype);
  };

  return (
    <Content
      header={
        <Row gutter={16}>
          <Col span={6}>
            <h1>{t('OBRAZAC_IZVESTAJI.OBRAZAC_IZVESTAJI')}</h1>
          </Col>
          <Col span={4}>
            <label>{t('OBRAZAC_IZVESTAJI.TIP_OBRASCA')}:</label>
            <Select
              value={izvestajType}
              options={OBRAZAC_IZVESTAJI_TYPE_OPTIONS}
              name="tip-obrasca"
              onChange={handleChangeIzvestajType}
            />
          </Col>
          <Col span={6}>
            <label>{t('OBRAZAC_IZVESTAJI.IZVESTAJ')}:</label>
            <Select
              value={izvestajSubtype}
              options={selectedIzvestajSubtypeOptions}
              name="pod-tip-obrasca"
              onChange={handleChangeIzvestajSubtype}
            />
          </Col>
          <Col span={8} className={styles.printButtonContainer}>
            <Row gutter={12}>
              <Col span={12}>
                <Button type="primary" name="izvestaj-export-pdf" onClick={handleExportPdf}>
                  {t('OBRAZAC_IZVESTAJI.EXPORT_PDF')}
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" name="izvestaj-export-excel" onClick={handleExportExcel}>
                  {t('OBRAZAC_IZVESTAJI.EXPORT_EXCEL')}l
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <ObrazacIzvestajiFilters izvestajSubtype={izvestajSubtype} />
      <TableData
        name="izvestaji"
        columns={columnsByIzvestajType as ColumnProps<unknown>[]}
        dataSource={obrazacIzvestajiStore.obrazacIzvestaj}
        getData={refetchData}
        withPagination={true}
        canFetchOnInit={false}
        forceFetch={[filtersStore.filters.obrazacIzvestaji]}
      />
    </Content>
  );
});
