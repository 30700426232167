import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { CRUDModal, ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import TableData from 'components/TableData/TableData';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { DEO_6_SKLADISTE_INITIAL_STATE } from 'modules/obrasci/store/deo6/deo6.constants';
import { deo6ObrazacStore } from 'modules/obrasci/store/deo6/deo6.store';
import { IGetRazrediEeOtpadaDeo6, IGetSkladistaDeo6 } from 'modules/obrasci/store/deo6/deo6.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

const TableSkladisteRazred: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetRazrediEeOtpadaDeo6> = [
    {
      title: `${t('OBRASCI.GIO_3.CLASS_EE_WASTE')}`,
      width: 100,
      key: 'category_name',
      render: (_: any, record: IGetRazrediEeOtpadaDeo6) => (
        <>{`${record.razred_ee_otpada.the_order} ${record.razred_ee_otpada.category_name_sr}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.GIO_3.QUANTITY_T')}`,
      width: 100,
      dataIndex: 'kolicina',
      key: 'kolicina',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record: IGetRazrediEeOtpadaDeo6) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-razred-skladiste-deo6'}
            onClick={() => {
              deo6ObrazacStore.handleChange('razredi_ee_form', record);
              modalStore.changeModalName('deo6-razred-skladiste-modal');
            }}
          />
        );
      },
    },
  ];

  return (
    <TableData
      name="skladiste-razredi-deo6"
      columns={baseColumns}
      dataSource={[...deo6ObrazacStore.skladiste_form.razred]}
      forceFetch={[]}
    />
  );
});

const NewSkladisteDeo6Form: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  const wasteCatalogueOptions = (options: IGetWasteCatalogue[]) => {
    return options.map(wasteOption => ({
      label: `${wasteOption.index_number} ${wasteOption.waste_sr.toUpperCase()}`,
      value: wasteOption.id,
    }));
  };

  const defaultTypeOfWaste: ISifarnikInitial | undefined = deo6ObrazacStore.getSkladisteForm.indeksni_broj_otpada_id
    ? {
        value: deo6ObrazacStore.getSkladisteForm.indeksni_broj_otpada_id,
        label: `${(deo6ObrazacStore.getSkladisteForm as IGetSkladistaDeo6).indeksni_broj_otpada.index_number} ${(
          deo6ObrazacStore.getSkladisteForm as IGetSkladistaDeo6
        ).indeksni_broj_otpada.waste_sr.toUpperCase()}`,
      }
    : undefined;

  useEffect(() => {
    if (deo6ObrazacStore.weee_categories) {
      props.form.validateFields(['kolicina_prvi_januar']);
    }
  }, [deo6ObrazacStore.razredi_ee_form]);

  return (
    <>
      <h3>{t('OBRASCI.GIO2.KOLICINA_PREUZETOG_OTPADA')}</h3>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'indeksni_broj_otpada_id'}
        fetchOptinsList={deo6ObrazacStore.fetchWasteCatalougeList}
        labelOptionAccessor="index_number"
        valueOptionAccessor="id"
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                deo6ObrazacStore.skladiste_form.indeksni_broj_otpada_id !== value &&
                deo6ObrazacStore.initialState.skladiste.some(item => {
                  return value === item.indeksni_broj_otpada_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.DEO_6.INDEX_ERROR')));
              }
              return Promise.resolve();
            },
          },
        ]}
        inputName={'indeksni_broj_otpada_id'}
        label={t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}
        colon={true}
        defaultOption={defaultTypeOfWaste}
        placeholder={''}
        disabled={deo6ObrazacStore.index_id !== ''}
        onChange={e => {
          deo6ObrazacStore.handleChange('index_id', e);
          const obj = deo6ObrazacStore.indexMap(e);
          if (!obj.weee && deo6ObrazacStore.weee_categories) {
            props.form.validateFields(['kolicina']);
          }
          deo6ObrazacStore.checkWeeeCategories(e, 'handleSkladisteForm');
        }}
        optionsMaker={wasteCatalogueOptions}
      />
      <FormInputNumber
        name="kolicina_prvi_januar"
        precision={4}
        label={t('OBRASCI.DEO_6.UKUPNA_KOLICINA_OVD_VRSTE_OTPADA')}
        placeholder={''}
        rules={[
          ...MORE_THEN_0_IS_REQUIRED,
          {
            validator(_, value) {
              if (deo6ObrazacStore.weee_categories) {
                let sum = 0;
                (deo6ObrazacStore.skladiste_form as IGetSkladistaDeo6).razred.map(
                  (item: IGetRazrediEeOtpadaDeo6) => (sum += item.kolicina ? item.kolicina : 0)
                );
                if (sum !== value) {
                  return Promise.reject(new Error(t('OBRASCI.VALIDATION.JEDNAKA_RAZRED')));
                }
              }
              return Promise.resolve();
            },
          },
        ]}
        inputName={'kolicina_prvi_januar'}
        colon={true}
      />
      {deo6ObrazacStore.weee_categories && (
        <InsideContentWrapper
          header={
            <WizardObrazacListHeader
              title={t('OBRASCI.GIO_3.TYPE_OF_ELECTRICAL_AND_ELECTRONIC_WASTE')}
              name={'deo6-razred-skladiste-form'}
              modalName="deo6-razred-skladiste-modal"
            />
          }
        >
          <TableSkladisteRazred />
        </InsideContentWrapper>
      )}
    </>
  );
});

export const Deo6SkladisteModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="70%"
      name="deo6-skladiste-modal"
      title={t('WIZARD_TABS.STANJE_NA_SKLADISTU')}
      store={deo6ObrazacStore}
      data={deo6ObrazacStore.getSkladisteForm}
      children={({ form }) => <NewSkladisteDeo6Form form={form} />}
      submitForm={deo6ObrazacStore.submitSkladisteForm}
      initialState={DEO_6_SKLADISTE_INITIAL_STATE}
      dataToChange={'skladiste_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      onCancel={deo6ObrazacStore.resetWeeeCategories}
    />
  );
});
