import { ColumnsType } from 'antd/es/table';
import { IGetPollutantThreshold, IPostPollutantThreshold } from './pollutantThreshold.types';
import { Checkbox, FormInstance } from 'antd';
import {
  DESCRIPTION_TABLE,
  NAME_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  VERSION_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import {
  DESCRIPTION_FORM,
  NAME_FORM,
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
  VERSION_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import {
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  SwitchSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';

export const POLLUTANT_THRESHOLD_INITIAL_STATE: IPostPollutantThreshold = {
  name: '',
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: '',
  threshold: '',
  gve1: false,
  prtr: false,
  lriz: false,
  water_yearly_balance: false,
  is_active: true,
  pollutant_id: '',
  medium_id: '',
  water_unit_id: '',
};
export const POLLUTANT_THRESHOLD_TABLE_DATA: ColumnsType<IGetPollutantThreshold> = [
  NAME_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  {
    title: 'Prag',
    width: 150,
    dataIndex: 'threshold',
    key: 'threshold',
  },
  {
    title: TIPOVI_OBRAZACA.GVE,
    width: 100,
    dataIndex: 'gve1',
    key: 'gve1',
    render: (_: any, record: IGetPollutantThreshold) => <Checkbox checked={!!record.gve1} disabled={true} />,
  },
  {
    title: 'PRTR',
    width: 100,
    dataIndex: 'prtr',
    key: 'prtr',
    render: (_: any, record: IGetPollutantThreshold) => <Checkbox checked={!!record.prtr} disabled={true} />,
  },
  {
    title: 'LRIZ',
    width: 100,
    dataIndex: 'lriz',
    key: 'lriz',
    render: (_: any, record: IGetPollutantThreshold) => <Checkbox checked={!!record.lriz} disabled={true} />,
  },
  {
    title: 'Godišnji bilans vode',
    width: 100,
    dataIndex: 'water_yearly_balance',
    key: 'water_yearly_balance',
    render: (_: any, record: IGetPollutantThreshold) => (
      <Checkbox checked={!!record.water_yearly_balance} disabled={true} />
    ),
  },
  {
    title: 'Šifra zagađivača',
    // align: 'center',
    width: '200px',
    dataIndex: ['pollutant', 'name_sr'],
    key: 'pollutant',
  },
  {
    title: 'Srednja šifra',
    // align: 'center',
    width: '200px',
    dataIndex: ['medium', 'name_sr'],
    key: 'medium',
  },
  {
    title: 'Jedinica vode',
    // align: 'center',
    width: '200px',
    dataIndex: ['water_unit', 'name_sr'],
    key: 'water_unit',
  },
];
export const POLLUTANT_THRESHOLD_FORM_OPTIONS = (form: FormInstance<IGetPollutantThreshold>) => {
  return [
    NAME_FORM,
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    DESCRIPTION_FORM,
    VERSION_FORM,
    {
      type_of_form: 'input',
      label: 'Prag',
      name: 'threshold',
      inputName: 'threshold',
      type: 'text',
      sortName: 'threshold',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'switch',
      name: 'gve1',
      // sortName: 'water',
      label: TIPOVI_OBRAZACA.GVE,
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'switch',
      name: 'prtr',
      // sortName: 'water',
      label: 'PRTR',
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'switch',
      name: 'lriz',
      // sortName: 'water',
      label: 'LRIZ',
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'switch',
      name: 'water_yearly_balance',
      hideInput: true,
      // sortName: 'water',
      label: 'Godišnji bilans vode',
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      // todo: prevod,
      label: 'Šifra zagađivača',
      // placeholder={t('SIFARNICI.PROIZVOD')}
      formName: 'pollutant_id',
      fetchOptinsList: SIFARNICI_ROUTES.POLLUTANT_CODE,
      filtersForFetch: {},
      labelOptionAccessor: ['name_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      // todo: prevod,
      label: 'Srednja šifra',
      // placeholder={t('SIFARNICI.PROIZVOD')}
      formName: 'medium_id',
      fetchOptinsList: SIFARNICI_ROUTES.MEDIUM_CODE,
      filtersForFetch: {},
      labelOptionAccessor: ['name_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      // todo: prevod,
      label: 'Jedinica vode',
      // placeholder={t('SIFARNICI.PROIZVOD')}
      formName: 'water_unit_id',
      fetchOptinsList: SIFARNICI_ROUTES.UNIT_CODE,
      filtersForFetch: {},
      labelOptionAccessor: ['name_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
  ];
};
