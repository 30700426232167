import { Row, Col, Form, Select, FormInstance, message } from 'antd';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { observer } from 'mobx-react-lite';
import { MAX_LENGTH_RULE, MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { VidPrevozaOptions } from 'modules/obrasci/store/dko/dko.constants';
import { dkoObrazacStore } from 'modules/obrasci/store/dko/dko.store';
import { IGetDkoObrazac } from 'modules/obrasci/store/dko/dko.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React, { useEffect } from 'react';
import styles from './DkoDeoAWizardTab.module.scss';
import { disabledTodayAndFutureDates } from 'utils/formatDate';
import { useTranslation } from 'react-i18next';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { debounce } from 'lodash';
import { cListOptions, nListOptions, qListOptions, yListOptions } from 'utils/optionMakers';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

type FormProps = {
  form: FormInstance;
};

export const DkoDeoAWizardTab: React.FC<FormProps> = observer(({ form }) => {
  const { t } = useTranslation();

  useEffect(() => {
    dkoObrazacStore.handleChange('vidPrevoza', form.getFieldValue('vid_prevoza'));
  }, []);

  const handleMasaWarning = debounce(() => {
    const masaOtpada = Number(form.getFieldValue('masa_otpada'));
    const vidPrevoza = form.getFieldValue('vid_prevoza');
    dkoObrazacStore.handleChange('vidPrevoza', vidPrevoza);
    if (masaOtpada > 30 && vidPrevoza === 'drumski') message.warning(t('OBRASCI.DKO.MASA_OTPADA_PROVERA'));
  }, 500);

  const yFieldNames = ['dko_deo_a_y_lista_id', 'dko_deo_a_y_lista2_id', 'dko_deo_a_y_lista3_id'];

  const handleYListValidation = () => {
    form.validateFields(yFieldNames);
  };

  const nFieldNames = ['dko_deo_a_n_lista_id', 'dko_deo_a_n_lista2_id', 'dko_deo_a_n_lista3_id'];

  const handleNListValidation = () => {
    form.validateFields(nFieldNames);
  };
  const cFieldNames = ['dko_deo_a_c_lista_id', 'dko_deo_a_c_lista2_id', 'dko_deo_a_c_lista3_id'];

  const handleCListValidation = () => {
    form.validateFields(cFieldNames);
  };

  const handleOdredisteChange = (_: any, option: any) => {
    if (option) form.setFieldValue('lokacija_istovara_id', option.children);
    else form.setFieldValue('lokacija_istovara_id', '');
  };

  const handleAdresaOdredistaChange = (value: string) => {
    form.setFieldValue('adresa_istovara', value);
  };

  const defaultMestoOption: ISifarnikInitial | undefined = dkoObrazacStore.initialState.mesto_id
    ? {
        label: (dkoObrazacStore.initialState as IGetDkoObrazac).mesto?.display_name,
        value: dkoObrazacStore.initialState.mesto_id,
      }
    : undefined;
  const defaultTypeOfWasteOption: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState.type_of_waste_id
    ? {
        label: (dkoObrazacStore.initialState as IGetDkoObrazac).type_of_waste.name_sr,
        value: dkoObrazacStore.initialState.type_of_waste_id,
      }
    : undefined;

  const defaultQlistOption: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState.q_lista_id
    ? {
        value: dkoObrazacStore.getInitialState.q_lista_id,
        label: `${(dkoObrazacStore.getInitialState as IGetDkoObrazac).q_lista.name} ${
          (dkoObrazacStore.getInitialState as IGetDkoObrazac).q_lista.description_sr
        }`,
      }
    : undefined;

  const defaultYlistOption: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState.dko_deo_a_y_lista_id
    ? {
        value: dkoObrazacStore.getInitialState.dko_deo_a_y_lista_id,
        label: `${(dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_y_lista.name} ${
          (dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_y_lista.description_sr
        }`,
      }
    : undefined;

  const defaultYlist2Option: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState.dko_deo_a_y_lista2_id
    ? {
        value: dkoObrazacStore.getInitialState.dko_deo_a_y_lista2_id,
        label: `${(dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_y_lista2.name} ${
          (dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_y_lista2.description_sr
        }`,
      }
    : undefined;

  const defaultYlist3Option: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState.dko_deo_a_y_lista3_id
    ? {
        value: dkoObrazacStore.getInitialState.dko_deo_a_y_lista3_id,
        label: `${(dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_y_lista3.name} ${
          (dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_y_lista3.description_sr
        }`,
      }
    : undefined;

  const defaultClistOption: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState.dko_deo_a_c_lista_id
    ? {
        value: dkoObrazacStore.getInitialState.dko_deo_a_c_lista_id,
        label: `${(dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_c_lista.name} ${
          (dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_c_lista.description_sr
        }`,
      }
    : undefined;
  const defaultClist2Option: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState.dko_deo_a_c_lista2_id
    ? {
        value: dkoObrazacStore.getInitialState.dko_deo_a_c_lista2_id,
        label: `${(dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_c_lista2.name} ${
          (dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_c_lista2.description_sr
        }`,
      }
    : undefined;
  const defaultClist3Option: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState.dko_deo_a_c_lista3_id
    ? {
        value: dkoObrazacStore.getInitialState.dko_deo_a_c_lista3_id,
        label: `${(dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_c_lista3.name} ${
          (dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_c_lista3.description_sr
        }`,
      }
    : undefined;

  const defaultNlistOption: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState.dko_deo_a_n_lista_id
    ? {
        value: dkoObrazacStore.getInitialState.dko_deo_a_n_lista_id,
        label: `${(dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_n_lista.name} ${
          (dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_n_lista.description_sr
        }`,
      }
    : undefined;

  const defaultNlist2Option: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState.dko_deo_a_n_lista2_id
    ? {
        value: dkoObrazacStore.getInitialState.dko_deo_a_n_lista2_id,
        label: `${(dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_n_lista2.name} ${
          (dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_n_lista2.description_sr
        }`,
      }
    : undefined;

  const defaultNlist3Option: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState.dko_deo_a_n_lista3_id
    ? {
        value: dkoObrazacStore.getInitialState.dko_deo_a_n_lista3_id,
        label: `${(dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_n_lista3.name} ${
          (dkoObrazacStore.getInitialState as IGetDkoObrazac).dko_deo_a_n_lista3.description_sr
        }`,
      }
    : undefined;

  const defaultMethodOfPackingWaste: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState
    .nacin_pakovanja_otpada_id
    ? {
        label: (dkoObrazacStore.getInitialState as IGetDkoObrazac).nacin_pakovanja_otpada.method_of_packaging_sr,
        value: dkoObrazacStore.getInitialState.nacin_pakovanja_otpada_id,
      }
    : undefined;

  const defaultWasteStateCategory: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState
    .fizicko_stanje_otpada_id
    ? {
        label: (dkoObrazacStore.getInitialState as IGetDkoObrazac).fizicko_stanje_otpada.name_sr,
        value: dkoObrazacStore.getInitialState.fizicko_stanje_otpada_id,
      }
    : undefined;

  return (
    <Col span={24} className={styles.wrapper}>
      <h2>{`${t('WIZARD_TABS.DEO_A')} - ${t('OBRASCI.DKO.PODACI_O_OTPADU')}`}</h2>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={`${t('OBRASCI.KOM_1.TYPE_OF_WASTE')}`}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        formName={'type_of_waste_id'}
        inputName={'type_of_waste_id'}
        labelOptionAccessor={'name_sr'}
        valueOptionAccessor={'id'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultTypeOfWasteOption}
        fetchOptinsList={dkoObrazacStore.fetchTypeOfWasteOptions}
        placeholder={''}
      />
      <h3>{t('OBRASCI.DKO.KLASIFIKACIJA_OTPADA')}</h3>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'q_lista_id'}
        fetchOptinsList={dkoObrazacStore.fetchQListOptions}
        labelOptionAccessor="name"
        valueOptionAccessor="id"
        rules={[REQUIRED_FIELD_RULE(true)]}
        inputName={'q_lista_id'}
        label={t('OBRASCI.DKO.Q_LIST')}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        defaultOption={defaultQlistOption}
        placeholder={''}
        optionsMaker={qListOptions}
      />
      <Row gutter={10}>
        <Col span={8}>
          <label>
            <sup style={{ color: 'red' }}>*</sup> {t('OBRASCI.GIO4.Y_LIST')}
          </label>
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch={true}
            formName={'dko_deo_a_y_lista_id'}
            fetchOptinsList={dkoObrazacStore.fetchYListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            rules={[
              {
                validator(_, value) {
                  const yListValues = yFieldNames.map(field => form.getFieldValue(field));
                  const otherYListValues = [yListValues[1], yListValues[2]];
                  if (yListValues.every(value => !value))
                    return Promise.reject(new Error(t('OBRASCI.KOM_1.VALIDATION.BAR_JEDNO_POLJE')));
                  const idMatch = otherYListValues.some((element: string) => element === value && element && value);
                  if (idMatch) return Promise.reject(new Error(t('OBRASCI.DKO.Y_LIST_EXISTS')));
                  return Promise.resolve();
                },
              },
            ]}
            inputName={'dko_deo_a_y_lista_id'}
            label={'Y'}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
            defaultOption={defaultYlistOption}
            onChange={handleYListValidation}
            placeholder={''}
            optionsMaker={yListOptions}
          />
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch={true}
            formName={'dko_deo_a_y_lista2_id'}
            fetchOptinsList={dkoObrazacStore.fetchYListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'dko_deo_a_y_lista2_id'}
            rules={[
              {
                validator(_, value) {
                  const yListValues = yFieldNames.map(field => form.getFieldValue(field));
                  const otherYListValues = [yListValues[0], yListValues[2]];
                  if (yListValues.every(value => !value))
                    return Promise.reject(new Error(t('OBRASCI.KOM_1.VALIDATION.BAR_JEDNO_POLJE')));
                  const idMatch = otherYListValues.some((element: string) => element === value && element && value);
                  if (idMatch) return Promise.reject(new Error(t('OBRASCI.DKO.Y_LIST_EXISTS')));
                  return Promise.resolve();
                },
              },
            ]}
            label={'/ Y'}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            defaultOption={defaultYlist2Option}
            onChange={handleYListValidation}
            placeholder={''}
            optionsMaker={yListOptions}
          />
        </Col>
        <Col span={6}>
          <FormSearchInfiniteScroll
            showSearch={true}
            formName={'dko_deo_a_y_lista3_id'}
            rules={[
              {
                validator(_, value) {
                  const yListValues = yFieldNames.map(field => form.getFieldValue(field));
                  const otherYListValues = [yListValues[0], yListValues[1]];
                  if (yListValues.every(value => !value))
                    return Promise.reject(new Error(t('OBRASCI.KOM_1.VALIDATION.BAR_JEDNO_POLJE')));
                  const idMatch = otherYListValues.some((element: string) => element === value && element && value);
                  if (idMatch) return Promise.reject(new Error(t('OBRASCI.DKO.Y_LIST_EXISTS')));
                  return Promise.resolve();
                },
              },
            ]}
            fetchOptinsList={dkoObrazacStore.fetchYListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            inputName={'dko_deo_a_y_lista3_id'}
            label={'/ Y'}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            defaultOption={defaultYlist3Option}
            onChange={handleYListValidation}
            placeholder={''}
            optionsMaker={yListOptions}
          />
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={8}>
          <label>
            <sup style={{ color: 'red' }}>*</sup> {t('OBRASCI.GIO4.C_LIST')}
          </label>
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch={true}
            label={'C'}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
            formName={'dko_deo_a_c_lista_id'}
            fetchOptinsList={dkoObrazacStore.fetchCListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            rules={[
              {
                validator(_, value) {
                  const cListValues = cFieldNames.map(field => form.getFieldValue(field));
                  const otherCListValues = [cListValues[1], cListValues[2]];
                  if (cListValues.every(value => !value))
                    return Promise.reject(new Error(t('OBRASCI.KOM_1.VALIDATION.BAR_JEDNO_POLJE')));
                  const idMatch = otherCListValues.some((element: string) => element === value && element && value);
                  if (idMatch) return Promise.reject(new Error(t('Već ste izabrali ovu listu')));
                  return Promise.resolve();
                },
              },
            ]}
            inputName={'dko_deo_a_c_lista_id'}
            placeholder={''}
            defaultOption={defaultClistOption}
            optionsMaker={cListOptions}
            onChange={handleCListValidation}
          />
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch={true}
            label={'/ C'}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            formName={'dko_deo_a_c_lista2_id'}
            fetchOptinsList={dkoObrazacStore.fetchCListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            rules={[
              {
                validator(_, value) {
                  const cListValues = cFieldNames.map(field => form.getFieldValue(field));
                  const otherCListValues = [cListValues[0], cListValues[2]];
                  if (cListValues.every(value => !value))
                    return Promise.reject(new Error(t('OBRASCI.KOM_1.VALIDATION.BAR_JEDNO_POLJE')));
                  const idMatch = otherCListValues.some((element: string) => element === value && element && value);
                  if (idMatch) return Promise.reject(new Error(t('Već ste izabrali ovu listu')));
                  return Promise.resolve();
                },
              },
            ]}
            inputName={'dko_deo_a_c_lista2_id'}
            placeholder={''}
            defaultOption={defaultClist2Option}
            optionsMaker={cListOptions}
            onChange={handleCListValidation}
          />
        </Col>
        <Col span={6}>
          <FormSearchInfiniteScroll
            showSearch={true}
            label={'/ C'}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            formName={'dko_deo_a_c_lista3_id'}
            fetchOptinsList={dkoObrazacStore.fetchCListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            rules={[
              {
                validator(_, value) {
                  const cListValues = cFieldNames.map(field => form.getFieldValue(field));
                  const otherCListValues = [cListValues[0], cListValues[1]];
                  if (cListValues.every(value => !value))
                    return Promise.reject(new Error(t('OBRASCI.KOM_1.VALIDATION.BAR_JEDNO_POLJE')));
                  const idMatch = otherCListValues.some((element: string) => element === value && element && value);
                  if (idMatch) return Promise.reject(new Error(t('Već ste izabrali ovu listu')));
                  return Promise.resolve();
                },
              },
            ]}
            inputName={'dko_deo_a_c_lista3_id'}
            placeholder={''}
            defaultOption={defaultClist3Option}
            optionsMaker={cListOptions}
            onChange={handleCListValidation}
          />
        </Col>
      </Row>

      <FormInput
        label={t('OBRASCI.DKO.UN_BROJ_I_KLASA')}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name={'un_broj_klasa'}
        inputName={'un_broj_klasa'}
        placeholder=""
      />
      <Row gutter={10}>
        <Col span={8}>
          <label>
            <sup style={{ color: 'red' }}>*</sup> {t('OBRASCI.GIO4.N_LIST')}
          </label>
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch={true}
            formName={'dko_deo_a_n_lista_id'}
            fetchOptinsList={dkoObrazacStore.fetchNListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            rules={[
              {
                validator(_, value) {
                  const nListValues = nFieldNames.map(field => form.getFieldValue(field));
                  const otherNListValues = [nListValues[1], nListValues[2]];
                  if (nListValues.every(value => !value))
                    return Promise.reject(new Error(t('OBRASCI.KOM_1.VALIDATION.BAR_JEDNO_POLJE')));
                  const idMatch = otherNListValues.some((element: string) => element === value && element && value);
                  if (idMatch) return Promise.reject(new Error(t('OBRASCI.DKO.N_LIST_EXISTS')));
                  return Promise.resolve();
                },
              },
            ]}
            inputName={'dko_deo_a_n_lista_id'}
            onChange={handleNListValidation}
            label={'H'}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
            defaultOption={defaultNlistOption}
            placeholder={''}
            optionsMaker={nListOptions}
          />
        </Col>
        <Col span={5}>
          <FormSearchInfiniteScroll
            showSearch={true}
            formName={'dko_deo_a_n_lista2_id'}
            fetchOptinsList={dkoObrazacStore.fetchNListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            rules={[
              {
                validator(_, value) {
                  const nListValues = nFieldNames.map(field => form.getFieldValue(field));
                  const otherNListValues = [nListValues[0], nListValues[2]];
                  if (nListValues.every(value => !value))
                    return Promise.reject(new Error(t('OBRASCI.KOM_1.VALIDATION.BAR_JEDNO_POLJE')));
                  const idMatch = otherNListValues.some((element: string) => element === value && element && value);
                  if (idMatch) return Promise.reject(new Error(t('OBRASCI.DKO.N_LIST_EXISTS')));
                  return Promise.resolve();
                },
              },
            ]}
            inputName={'dko_deo_a_n_lista2_id'}
            onChange={handleNListValidation}
            label={'/ H'}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            defaultOption={defaultNlist2Option}
            placeholder={''}
            optionsMaker={nListOptions}
          />
        </Col>
        <Col span={6}>
          <FormSearchInfiniteScroll
            showSearch={true}
            formName={'dko_deo_a_n_lista3_id'}
            fetchOptinsList={dkoObrazacStore.fetchNListOptions}
            labelOptionAccessor="name"
            valueOptionAccessor="id"
            rules={[
              {
                validator(_, value) {
                  const nListValues = nFieldNames.map(field => form.getFieldValue(field));
                  const otherNListValues = [nListValues[0], nListValues[1]];
                  if (nListValues.every(value => !value))
                    return Promise.reject(new Error(t('OBRASCI.KOM_1.VALIDATION.BAR_JEDNO_POLJE')));
                  const idMatch = otherNListValues.some((element: string) => element === value && element && value);
                  if (idMatch) return Promise.reject(new Error(t('OBRASCI.DKO.N_LIST_EXISTS')));
                  return Promise.resolve();
                },
              },
            ]}
            inputName={'dko_deo_a_n_lista3_id'}
            onChange={handleNListValidation}
            label={'/ H'}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            defaultOption={defaultNlist3Option}
            placeholder={''}
            optionsMaker={nListOptions}
          />
        </Col>
      </Row>
      <FormInputNumber
        label={t('OBRASCI.DKO.MASA_OTPADA')}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        disabled={
          obrasciStore.zakljucanObrazac &&
          ((dkoObrazacStore.initialState as IGetDkoObrazac).odjava_kretanja ||
            (dkoObrazacStore.initialState as IGetDkoObrazac).potvrda_dokumenta ||
            (dkoObrazacStore.initialState as IGetDkoObrazac).admin_odjava_dokumenta)
        }
        name={'masa_otpada'}
        type="number"
        rules={MORE_THEN_0_IS_REQUIRED}
        onInputChange={handleMasaWarning}
        inputName={'masa_otpada'}
        placeholder=""
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'nacin_pakovanja_otpada_id'}
        fetchOptinsList={dkoObrazacStore.fetchMethodOfPackagingWaste}
        labelOptionAccessor="method_of_packaging_sr"
        valueOptionAccessor="id"
        rules={[REQUIRED_FIELD_RULE(true)]}
        inputName={'nacin_pakovanja_otpada_id'}
        label={t('OBRASCI.DKO.NACIN_PAKOVANJA_OTPADA')}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        defaultOption={defaultMethodOfPackingWaste}
        placeholder={''}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'fizicko_stanje_otpada_id'}
        fetchOptinsList={dkoObrazacStore.fetchWasteStateCategory}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        rules={[REQUIRED_FIELD_RULE(true)]}
        inputName={'fizicko_stanje_otpada_id'}
        label={t('OBRASCI.DKO.FIZICKO_STANJE_OTPADA')}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        defaultOption={defaultWasteStateCategory}
        placeholder={''}
      />

      <h3>{t('OBRASCI.DKO.IZVESTAJ_O_ISPITIVANJU_OTPADA')}</h3>
      <FormInput
        label={t('OBRASCI.DKO.BROJ_IZVESTAJA')}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        rules={[REQUIRED_FIELD_RULE(true)]}
        name={'broj_izvestaja'}
        type="text"
        inputName={'broj_izvestaja'}
        placeholder=""
      />
      <FormDatePicker
        name="datum_izdavanja"
        label={t('OBRASCI.GIO2.DATUM_IZDAVANJA')}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        rules={[REQUIRED_FIELD_RULE(true)]}
        format="DD-MM-YYYY"
        picker={'date'}
        disabledDate={disabledTodayAndFutureDates}
      />
      <Col span={24}>
        <Form.Item
          name={'vid_prevoza'}
          label={t('OBRASCI.DKO.VID_PREVOZA')}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          rules={[REQUIRED_FIELD_RULE(true)]}
        >
          <Select style={{ width: 120 }} options={VidPrevozaOptions} onSelect={handleMasaWarning} />
        </Form.Item>
      </Col>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'mesto_id'}
        label={t('OBRASCI.DKO.ODREDISTE')}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelOptionAccessor={'display_name'}
        valueOptionAccessor={'id'}
        fetchOptinsList={dkoObrazacStore.fetchMesto}
        placeholder={''}
        onChange={handleOdredisteChange}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultMestoOption}
      />
      <FormInput
        label={t('OBRASCI.DKO.ADRESA_ODREDISTA')}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name={'adresa_odredista'}
        rules={[REQUIRED_FIELD_RULE(dkoObrazacStore.vidPrevoza === 'drumski')]}
        onInputChange={debounce(e => handleAdresaOdredistaChange(e.target.value), 300)}
        inputName={'adresa_odredista'}
        placeholder=""
      />

      <FormTextArea
        label={t('OBRASCI.DKO.POSEBNA_NAPOMENA')}
        name={'napomena_deo_a'}
        rows={4}
        labelCol={8}
        wrapperCol={16}
        rules={[MAX_LENGTH_RULE(3000)]}
      />
    </Col>
  );
});
