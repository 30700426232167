import { Modal } from 'components/Modal/Modal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { NewGveGodisnjiPitanjeForm } from './NewGveGodisnjiPitanjeForm/NewGveGodisnjiPitanje';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';

export const GveGodisnjiPitanjeModal: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();
  const form = props.form;

  return (
    <Modal
      name="gve-godisnji-bilans-modal"
      open={modalStore.getCurrentOpenedModal.includes('gve-godisnji-bilans-modal')}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => {
        modalStore.removeAllModals();
        // form.setFieldValue('ukupan_broj_radnih_sati_godisnje', null);
        form.setFieldValue('kreiranje_godisnjeg_bilansa', null);
        gveStore.handleInitialStateChange('kreiranje_godisnjeg_bilansa', null);
      }}
      title={t('POLLUTION.AIR.TITLE')}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      width={'50%'}
    >
      <NewGveGodisnjiPitanjeForm form={form} />
    </Modal>
  );
});
