import { Modal } from 'components/Modal/Modal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import { NewObrazac3GodisnjPitanjeForm } from './NewObrazac3GodisnjPitanjeForm/NewObrazac3GodisnjPitanjeForm';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { OBRAZAC_3_FIELDS_TO_RESET } from 'modules/obrasci/store/obrazac3/obrazac3.constants';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';

export const Obrazac3GodisnjiPitanjeModal: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();
  const form = props.form;

  return (
    <Modal
      name="air-modal"
      open={modalStore.getCurrentOpenedModal.includes('obrazac3-godisnji-bilans-modal')}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => {
        modalStore.removeAllModals();
        OBRAZAC_3_FIELDS_TO_RESET.forEach(field => form.setFieldValue(field, ''));
        form.setFieldValue('kreiranje_godisnjeg_bilansa', null);
        obrazac3Store.handleInitialStateChange('kreiranje_godisnjeg_bilansa', null);
      }}
      title={t('OBRASCI.OBRAZAC3.GODISNJI_BILANS')}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      width={'70%'}
    >
      <NewObrazac3GodisnjPitanjeForm form={form} />
    </Modal>
  );
});
