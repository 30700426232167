import { NewCompanyTipoviObrazaca } from 'components/NewForms/NewCompanyTipoviObrazaca/NewCompanyTipoviObrazaca';
import { observer } from 'mobx-react-lite';
import { ICompanyTipoviObrasca } from 'modules/company/company.types';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import to from 'await-to-js';
import { companyStore } from 'modules/company/company.store';

export const CompanyTipoviObrazaca: React.FC = observer(() => {
  const handleSubmit = async (selectedTipovi: ICompanyTipoviObrasca) => {
    const [err] = await to(companyStore.postCompanyTipoviObrasca(selectedTipovi));
    if (err) return;
  };

  return (
    <NewCompanyTipoviObrazaca
      isActive={companyStore.getCompany.is_active}
      handleSubmit={handleSubmit}
      selectedObrasciId={companyStore.getCompanyTipoviObrazacaIds}
      selectedObrasci={obrasciStore.selectedObrasci(companyStore.getCompanyTipoviObrazacaIds)}
      tipoviObrazaca={obrasciStore.obrasciPoNivou.preduzece}
      initialTipoviIds={companyStore.initial_tipovi_ids}
    />
  );
});
