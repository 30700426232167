import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { GIO_4_RAZREDI_EE_OTPADA_INITIAL_STATE } from 'modules/obrasci/store/gio4/gio4.constants';
import { gio4ObrazacStore } from 'modules/obrasci/store/gio4/gio4.store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewGio4ElektricniOtpadForm } from './NewGio4ElektricniOtpadForm/NewGio4ElektricniOtpadForm';

export const Gio4ElektricniOtpadModal: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <CRUDModal
      width="40%"
      name="gio4-razredi-ee-modal"
      title={t('OBRASCI.GIO_3.CLASS_EE_WASTE')}
      store={gio4ObrazacStore}
      data={gio4ObrazacStore.getRazrediEeForm}
      children={() => <NewGio4ElektricniOtpadForm />}
      submitForm={gio4ObrazacStore.submitRazredEeOtpada}
      initialState={GIO_4_RAZREDI_EE_OTPADA_INITIAL_STATE}
      dataToChange={'razredi_ee_form'}
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
      closeOneModal={true}
    />
  );
});
