import { FormInstance } from 'antd';
import { IGetProductsPzv } from './productsPzv.types';
import { t } from 'i18next';

class ProductsPzvService {
  validationForSwitchComponents = (value: string, form: FormInstance<IGetProductsPzv>) => {
    const detergent = form.getFieldValue('detergent');
    const fertilizer = form.getFieldValue('fertilizer');
    const plant_protection = form.getFieldValue('plant_protection');

    if (!value && ![detergent, fertilizer, plant_protection].some((item: any) => item === true)) {
      return Promise.reject(t('VALIDATION.SELECT_A_FIELD'));
    }
    return Promise.resolve();
  };
}

export const productsPzvService = new ProductsPzvService();
