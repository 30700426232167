import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { IGetObrazac3IzmereneVrednostiRecipijenta } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const Obrazac3AnalizaRecipijenataIzmereneVrednostiTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetObrazac3IzmereneVrednostiRecipijenta> = [
    {
      title: `${t('OBRASCI.OBRAZAC3.NAZIV_ZAGADJUJUCE_MATERIJE')}`,
      dataIndex: ['naziv_zagadjujuce_materije', 'name_sr'],
      key: 'naziv_zagadjujuce_materije_id',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.JEDINICA_MERE')}`,
      dataIndex: ['jedinica_mere', 'code'],
      key: 'jedinica_mere_id',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.PRE_ISPUSTA')}`,
      width: 200,
      dataIndex: 'izmerene_vrednosti_pre_ispusta',
      key: 'izmerene_vrednosti_pre_ispusta',
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.POSLE_ISPUSTA')}`,
      width: 200,
      dataIndex: 'izmerene_vrednosti_posle_ispusta',
      key: 'izmerene_vrednosti_posle_ispusta',
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.METOD_ODREDJIVANJA')}`,
      dataIndex: ['metod_odredjivanja', 'name_sr'],
      key: 'metod_odredjivanja_id',
      width: 200,
    },
    {
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetObrazac3IzmereneVrednostiRecipijenta) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-komponente'}
            onClick={() => {
              obrazac3Store.handleChange('izmerene_vrednosti_recipijenta_form', record);
              modalStore.changeModalName('obrazac3-analiza-recipijenta-izmerene-vrednosti-modal');
            }}
          />
        );
      },
    },
  ];
  return (
    <TableData
      name="analiza-recepijenata-obrazac3"
      columns={baseColumns}
      dataSource={[...obrazac3Store.recipijent_merenje_form.izmerene_vrednosti_recipijenta]}
    />
  );
  // resi store
});
