import { ColumnsType } from 'antd/es/table';
import { IGetNuts2RegionCode, IPostNuts2RegionCode } from './nuts2RegionCode.types';
import { FormInstance } from 'antd';
import { CODE_FORM, NAME_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { CODE_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';

export const NUTS2_REGION_CODE_INITIAL_STATE: IPostNuts2RegionCode = {
  code: '',
  name: '',
  is_active: true,
};
export const NUTS2_REGION_CODE_TABLE_DATA: ColumnsType<IGetNuts2RegionCode> = [
  CODE_TABLE,
  { ...NAME_TABLE, width: '600px' },
];
export const NUTS2_REGION_CODE_FORM_OPTIONS = (_: FormInstance<IGetNuts2RegionCode>) => {
  return [CODE_FORM, NAME_FORM];
};
