import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, NAME_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IPostWasteStateCategory, IGetWasteStateCategory } from './wasteStateCategory.types';

export const WASTE_STATE_CATEGORY_INITIAL_STATE: IPostWasteStateCategory = {
  name: '',
  name_sr: '',
  is_active: true,
};

export const WASTE_STATE_CATEGORY_TABLE_DATA: ColumnsType<IGetWasteStateCategory> = [NAME_TABLE, NAME_SR_TABLE];

export const WASTE_STATE_CATEGORY_FORM_OPTIONS = (_: FormInstance<IGetWasteStateCategory>) => {
  return [NAME_FORM, NAME_SR_FORM];
};
