import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { OBRAZAC_TYPE_IZVESTAJ_TABLE_COLUMNS } from './izvestaji.constants';
import { izvestajiRepo } from './izvestaji.repo';

class IzvestajiService {
  fetchIzvestaji = <IzvestajPayload = any>(type: string, filters: any) => {
    return izvestajiRepo.fetchIzvestaji<IzvestajPayload>(type, filters);
  };

  generisiIzvestaj = (type: string) => {
    return izvestajiRepo.generisiIzvestaj(type);
  };

  tableColumnsIzvestajFinder = (type: string) => {
    return OBRAZAC_TYPE_IZVESTAJ_TABLE_COLUMNS[type];
  };

  iskljuciIzvestaje = (tip: TIPOVI_OBRAZACA, payload: any) => {
    return izvestajiRepo.iskljuciIzvestaje(tip, payload);
  };

  posaljiIzvestaje = (tip: TIPOVI_OBRAZACA) => {
    return izvestajiRepo.posaljiIzvestaje(tip);
  };
}

export const izvestajiService = new IzvestajiService();
