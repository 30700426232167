import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import styles from './UserCompanyResponsibility.module.scss';
import { FormInstance, Row } from 'antd';
import { IGetUser, IPostUserResponsibility, UserRoles } from 'modules/user/user.types';
import { authStore, formStore } from 'store';
import { NewUserCompanyResponsibilityForm } from 'components/NewForms/NewUserCompanyResponsibilityForm/NewUserCompanyResponsibilityForm';
import { EditChangeDiscardButton } from 'components/Buttons/EditChangeDiscardButton/EditChangeDiscardButton';
import { userStore } from 'modules/user/user.store';
import { cloneDeep } from 'lodash';

export interface IPropsUserResponsibilityHeader {
  changeIsReadOnly: () => any;
  isReadOnly: boolean;
  form: FormInstance<IPostUserResponsibility> | null;
}

const UserCompanyResponsibilityHeader: React.FC<IPropsUserResponsibilityHeader> = observer(props => {
  return (
    <Row justify={'space-between'} align={'middle'}>
      <h3>{'Odgovornosti na preduzeću'}</h3>
      <Row align={'middle'}>
        {authStore.getUserRole !== UserRoles.CompanyUser && (
          <EditChangeDiscardButton
            name="company-info"
            editButtonVisible={props.isReadOnly && (userStore.getUser as IGetUser).is_active}
            onEditClick={() => {
              props.changeIsReadOnly();
            }}
            onDiscardClick={() => {
              props.changeIsReadOnly();
              userStore.setUser(cloneDeep(userStore.getUser));
            }}
            onChangeClick={() => {
              formStore.setCrudButtonType('change');
              // if (props.form) {
              // }

              // todo: Ubacena validacija za submit. Desavalo se da se readonly state menja pre submita forme i da se polja uopste desava validacija polja.
              // Takodje, u samoj komponenti je promenjeno da je REQUIRED_FIELD_RULE uvek true.
              // mozda nije najsrecnije resenje... baci pogled

              props.form?.validateFields().then(
                res => {
                  props.form?.submit();
                  props.changeIsReadOnly();
                  return res;
                },
                err => {
                  return err;
                }
              );

              // props.changeIsReadOnly();
            }}
            discardChangeButtonVisible={!props.isReadOnly}
          />
        )}
      </Row>
    </Row>
  );
});

export const UserCompanyResponsibility: React.FC = observer(() => {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [form, setForm] = useState<FormInstance<IPostUserResponsibility> | null>(null);
  const changeIsReadOnly = () => {
    setIsReadOnly(!isReadOnly);
  };

  const passForm = (form: FormInstance<IPostUserResponsibility>) => {
    setForm(form);
  };

  useEffect(() => {
    return () => setIsReadOnly(true);
  }, []);

  return (
    <InsideContentWrapper
      header={
        <UserCompanyResponsibilityHeader changeIsReadOnly={changeIsReadOnly} form={form} isReadOnly={isReadOnly} />
      }
    >
      <div className={styles.user_info_wrapper}>
        <NewUserCompanyResponsibilityForm showAlert={true} readOnly={isReadOnly} passForm={passForm} />
      </div>
    </InsideContentWrapper>
  );
});
