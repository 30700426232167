import React from 'react';
import { IstorijaPromenaTable } from './components/IstorijaPromenaTable/IstorijaPromenaTable';
import { IstorijaPromenaFilters } from './components/IstorijaPromenaFilters/IstorijaPromenaFilters';
import { Content } from 'components/Content/Content';

export const IstorijaPromena: React.FC = () => {
  return (
    <Content>
      <IstorijaPromenaFilters />
      <IstorijaPromenaTable />
    </Content>
  );
};
