import { Col, Row, notification } from 'antd';
import { observer } from 'mobx-react-lite';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ValidationObrazacNotification.module.scss';

import { VALIDATION_OBRAZAC } from 'modules/obrasci/validationObrasci.service';
export const ValidationObrazacNotification: React.FC = observer(() => {
  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification({ top: 100 });

  useEffect(() => {
    if (obrasciStore.isValidationNotificationOpen) {
      api.open({
        message: <h3>Uslovi obrasca:</h3>,
        description: (
          <>
            <>{t('OBRASCI.VALIDATION.POPUNITI_SVA_OBAVEZNA_POLJA')}</>
            {obrasciStore.obrazac_type &&
              VALIDATION_OBRAZAC[obrasciStore.obrazac_type].map((item, index) => {
                const isValid = item.isValid();
                if (isValid) return;
                return (
                  <Row
                    gutter={5}
                    key={index}
                    className={`${styles.box}  ${
                      isValid === null
                        ? styles.all_fields
                        : `${isValid ? styles.success : styles.error} 
                    `
                    }`}
                  >
                    <Col>{item.label}</Col>
                  </Row>
                );
              })}
          </>
        ),
        duration: null,
        onClose() {
          obrasciStore.handleChange('isValidationNotificationOpen', false);
        },

        style: { width: '500px' },
      });
    } else {
      api.destroy();
    }
  }, [obrasciStore.isValidationNotificationOpen]);
  return <div className={styles.wrapper}>{contextHolder}</div>;
});
