import { observer } from 'mobx-react-lite';
import React from 'react';
import { Aao2KolicinaAmbalazeModal } from './components/Aao2KolicinaAmbalazeModal/Aao2KolicinaAmbalazeModal';
import { Aao2UpravljanjeAmbalaznimOtpadomModal } from './components/Aao2UpravljanjeAmbalaznimOtpadomModal/Aao2UpravljanjeAmbalaznimOtpadomModal';
import { Aao2PreduzetnikPravnoLiceModal } from './components/Aao2PreduzetnikPravnoLiceModal/Aao2PreduzetnikPravnoLiceModal';

export const Aao2MicroServices: React.FC = observer(() => {
  return (
    <>
      <Aao2KolicinaAmbalazeModal />
      <Aao2UpravljanjeAmbalaznimOtpadomModal />
      <Aao2PreduzetnikPravnoLiceModal />
    </>
  );
});
