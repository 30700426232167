import { action, computed, makeObservable, observable } from 'mobx';

class FormStore {
  constructor() {
    makeObservable(this, {
      crudButtonType: observable,
      goBack: observable,
      // messages: observable,

      getCrudButtonType: computed,
      getGoBack: computed,

      setCrudButtonType: action,
    });
  }

  readOnly = false;
  crudButtonType = '';
  goBack = false;
  error: string[] = [];
  // messages: any = {
  //   submit: 'Da li ste sigurni da želite da sačuvate?',
  //   delete: 'Da li ste sigurni da želite da obrisete?',
  // };

  get getCrudButtonType() {
    return this.crudButtonType;
  }
  get getGoBack() {
    return this.goBack;
  }

  handleChange = (type: string, value: any) => {
    (this as any)[type] = value;
  };

  setCrudButtonType = (value: string) => {
    this.crudButtonType = value;
  };
}

export const formStore = new FormStore();
