import { ColumnsType } from 'antd/es/table';
import { IGetUnitCode, IPostUnitCode } from './unitCode.types';
import {
  CODE_TABLE,
  DESCRIPTION_TABLE,
  NAME_SR_TABLE,
  NAME_TABLE,
  TITLE_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  VERSION_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';

import { FormInstance } from 'antd';

import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import {
  TITLE_FORM,
  DESCRIPTION_FORM,
  VERSION_FORM,
  CODE_FORM,
  NAME_FORM,
  NAME_SR_FORM,
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';

export const UNIT_CODE_INITIAL_STATE: IPostUnitCode = {
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  name_sr: '',
  prtr_code: '',
  is_active: true,
};
export const UNIT_CODE_TABLE_DATA: ColumnsType<IGetUnitCode> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,

  {
    title: 'PRTR kod',
    width: 100,
    dataIndex: 'prtr_code',
    key: 'prtr_code',
  },
];
export const UNIT_CODE_FORM_OPTIONS = (form: FormInstance<IGetUnitCode>) => {
  return [
    NAME_FORM,
    NAME_SR_FORM,
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM,
    DESCRIPTION_FORM,
    VERSION_FORM,
    CODE_FORM,
    {
      type_of_form: 'input',
      name: 'prtr_code',
      // todo: prevod
      label: 'PRTR kod',
      inputName: 'prtr_code',
    } as InputSifarniciFormProps,
  ];
};
