import { Col, Form, Row, Select } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { DefaultOptionType } from 'antd/es/select';
import { Button } from 'components/Button/Button';
import { FormScrollWithObjInside } from 'components/FormScrollWithObjInside/FormScrollWithObjInside';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import { ITipObrasca, ObrazacPeriodIzvestavanja } from 'modules/obrasci/obrasci.types';
import { zakljucavanjeService } from 'modules/zakljucavanje/zakljucavanje.service';
import { zakljucavanjeStore } from 'modules/zakljucavanje/zakljucavanje.store';
import { IZakljucavanjeObrazacaFilters } from 'modules/zakljucavanje/zakljucavanje.types';
import { filtersStore } from 'store/filteri.store';
import storage from 'store/storage';
import { IFormScrollWithObjProps } from 'typescript';

interface ZakljucavanjeFormProps {
  selectedTipObrascaFields: IFormScrollWithObjProps[];
}

export const ZakljucavanjePoTipuForm: React.FC<ZakljucavanjeFormProps> = observer(({ selectedTipObrascaFields }) => {
  const form = useFormInstance();

  const godineOptions = zakljucavanjeStore.godine.map(godina => {
    return { value: godina.godina, key: godina.godina };
  });

  const obrasciOptions = (storage.getData('tipovi_obrazaca') as ITipObrasca[]).reduce(
    (obrazacOptions: DefaultOptionType[], tip) => {
      if (tip.period_izvestavanja === ObrazacPeriodIzvestavanja.GODISNJE)
        return [...obrazacOptions, { label: `${tip.skraceni_naziv} - ${tip.naziv}`, value: tip.id, item: tip }];
      return obrazacOptions;
    },
    []
  );

  const onObrazacSelectChange = (tip: ITipObrasca) => {
    zakljucavanjeStore.setSelectedTipObrasca(tip);
  };

  const handleSearch = () => {
    const searchParams = form.getFieldsValue(true) as IZakljucavanjeObrazacaFilters;
    filtersStore.setFilters('zakljucavanjeObrasci', { ...searchParams, page: 1, size: 10 });
  };

  const handleSubmit = async () => {
    const params = form.getFieldsValue(true) as IZakljucavanjeObrazacaFilters;
    const response = await zakljucavanjeStore.zakljucajObrasce(params);
    if (response) filtersStore.setFilters('zakljucavanjeObrasci', { ...params, page: 1, size: 10 });
  };

  return (
    <div>
      <Row gutter={20}>
        <Col span={11}>
          <Form.Item name="godina" label="Godina" labelCol={{ span: 8 }}>
            <Select options={godineOptions} placeholder={'Izaberite godinu'} />
          </Form.Item>

          <FormSearchInfiniteScroll
            label="Preduzece"
            labelCol={{ span: 8 }}
            name="preduzece_id"
            formName="preduzece_id"
            labelOptionAccessor="naziv"
            valueOptionAccessor="id"
            placeholder="Preduzece"
            fetchOptinsList={zakljucavanjeStore.fetchPreduzece}
            disabled={!form.getFieldValue('godina')}
            showSearch
            resetOption={zakljucavanjeStore.showOtkljucano}
            onChange={value => {
              if (!value) {
                zakljucavanjeStore.setSelectedTipObrasca(null);
                form.setFieldValue('tip_obrasca_id', '');
              }
            }}
          />

          <Form.Item name="tip_obrasca_id" label={'Tip obrasca'} labelCol={{ span: 8 }}>
            <Select
              options={obrasciOptions}
              placeholder={'Izaberite tip obrasca'}
              onSelect={(_, option) => onObrazacSelectChange(option.item)}
              disabled={!form.getFieldValue('preduzece_id')}
              allowClear
              onClear={() => zakljucavanjeStore.setSelectedTipObrasca(null)}
            />
          </Form.Item>
        </Col>
        {!!selectedTipObrascaFields.length && (
          <Col span={11}>
            {selectedTipObrascaFields.map(field => {
              return (
                <Row key={field.name}>
                  <Col span={24}>
                    <FormScrollWithObjInside {...field} showSearch labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} />
                  </Col>
                </Row>
              );
            })}
          </Col>
        )}
      </Row>
      <Row style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
        <Col>
          <Button name="zakljucavanje-search-btn" onClick={handleSearch}>
            Pretraži obrasce
          </Button>
        </Col>
        <Col>
          <Button name="zakljucavanje-lock-btn" onClick={handleSubmit}>
            {zakljucavanjeService.returnIsLocked(zakljucavanjeStore.showOtkljucano)
              ? 'Otkljucaj obrasce'
              : 'Zakljucaj obrasce'}
          </Button>
        </Col>
      </Row>
    </div>
  );
});
