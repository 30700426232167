import to from 'await-to-js';
import { OTVJediniceMere, OtvOpsteInfo } from 'modules/registri/otv/otv.types';
import { obrazac3Repo } from './obrazac3.repo';

class Obrazac3Service {
  checkOtvObaveza = async (opsteInfo: OtvOpsteInfo): Promise<boolean> => {
    console.log(opsteInfo, 'opsteinfo');

    const [err, res] = await to(obrazac3Repo.checkOtvObaveza(opsteInfo));
    if (err) return false;
    if (res.items.length === 0) return false;
    const isObaveza = res.items[0].otv_registar_zagadjenje.some(item => {
      return item.jedinica_mere !== OTVJediniceMere.mg_l;
    });
    return isObaveza;
  };
}

export const obrazac3Service = new Obrazac3Service();
