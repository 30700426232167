import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import {
  IGodina,
  IObrazacZaZakljucavanje,
  ITipoviGodinaResponse,
  IToggleLockPayload,
  IZakljucavanjeGodinaIzuzeci,
  IZakljucavanjeGodinaIzuzetakPayload,
  IZakljucavanjeObrazacaFilters,
} from './zakljucavanje.types';
import queryString from 'query-string';
import { IPaginatedApiResponse } from 'typescript/NrizTypes';
import { ITipObrasca } from 'modules/obrasci/obrasci.types';
import { ITablePagination } from 'components/TableData/TableData';

const fetchGodine = (): ApiResponse<IGodina[]> => {
  return axios.get(`${ROUTES.ZAKLJUCAVANJE.PO_GODINI}/`);
};

const fetchTipoviObrazacaPoGodini = (godina: number): ApiResponse<ITipoviGodinaResponse> => {
  return axios.get(`${ROUTES.ZAKLJUCAVANJE.PO_GODINI}/${godina}`);
};

const toggleLockTipObrascaZaGodinu = (godina: number, payload: IToggleLockPayload) => {
  return axios.put(`${ROUTES.ZAKLJUCAVANJE.PO_GODINI}/${godina}`, payload);
};

const otkljucajGodinu = (payload: ITipoviGodinaResponse): ApiResponse<string> => {
  return axios.post(`${ROUTES.ZAKLJUCAVANJE.PO_GODINI}/`, payload);
};

const fetchIzuzeciPoGodini = (
  filters: ITablePagination & Partial<IZakljucavanjeGodinaIzuzeci>
): IPaginatedApiResponse<IZakljucavanjeGodinaIzuzeci> => {
  const query = queryString.stringify(filters);
  return axios.get(`${ROUTES.ZAKLJUCAVANJE.IZUZECI_GODINA}/?${query}`);
};

const postIzuzetakPoGodini = (payload: IZakljucavanjeGodinaIzuzetakPayload): ApiResponse<string> => {
  return axios.post(`${ROUTES.ZAKLJUCAVANJE.IZUZECI_GODINA}/`, payload);
};

const deleteIzuzetakPoGodini = (id: string) => {
  return axios.delete(`${ROUTES.ZAKLJUCAVANJE.IZUZECI_GODINA}/${id}`);
};

const fetchTipoviObrazacaPoPreduzecu = (id: string): ApiResponse<ITipObrasca[]> => {
  return axios.get(`${ROUTES.COMPANY}${id}/${ROUTES.TIP_OBRASCA}`);
};

const fetchZakljucavanjeObrazacaList = (
  filters: Partial<IZakljucavanjeObrazacaFilters>
): IPaginatedApiResponse<IObrazacZaZakljucavanje> => {
  const query = queryString.stringify(filters);
  return axios.get(`${ROUTES.ZAKLJUCAVANJE.PO_TIPU}/?${query}`);
};

const zakljucajObrasce = (payload: Partial<IZakljucavanjeObrazacaFilters>) => {
  return axios.put(`${ROUTES.ZAKLJUCAVANJE.PO_TIPU}/`, payload);
};

export const zakljucavanjeRepo = {
  fetchGodine,
  fetchTipoviObrazacaPoGodini,
  toggleLockTipObrascaZaGodinu,
  otkljucajGodinu,
  fetchIzuzeciPoGodini,
  postIzuzetakPoGodini,
  deleteIzuzetakPoGodini,
  fetchTipoviObrazacaPoPreduzecu,
  fetchZakljucavanjeObrazacaList,
  zakljucajObrasce,
};
