import { observer } from 'mobx-react-lite';
import { modalStore } from 'store';
import { Modal } from 'components/Modal/Modal';
import { t } from 'i18next';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { izvestajiStore } from 'modules/izvestaji/izvestaji.store';
import { izvestajiService } from 'modules/izvestaji/izvestaji.service';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { IGetIzvestaj } from 'modules/izvestaji/izvestaji.types';
import style from './IzvestajiModal.module.scss';
import { Button } from 'components/Button/Button';

export const IzvestajiZaIskljucivanjeTable: React.FC = observer(() => {
  const columns: ColumnsType = [
    ...izvestajiService.tableColumnsIzvestajFinder(izvestajiStore.izvestaj_type),
    {
      title: 'Isključivanje',
      width: 80,
      key: 'iskljucivanje',
      fixed: 'right',
      align: 'center',
      render(_, record: IGetIzvestaj) {
        return (
          <Checkbox
            className={style.checkbox}
            checked={record.iskljucen}
            onChange={(event: CheckboxChangeEvent) =>
              izvestajiStore.handleIzvestajzaIskljuciti(event.target.checked, record)
            }
            name={'zaIskljucitii_checkbox'}
          />
        );
      },
    },
  ];
  return <TableData name="izvestaji-modal" columns={columns} dataSource={[...izvestajiStore.zaIskljucitiList]} />;
});

export const IzvestajiModal: React.FC = observer(() => {
  return (
    <Modal
      name="izvestaji-modal"
      open={modalStore.getCurrentOpenedModal.includes('izvestaji-modal')}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => modalStore.removeAllModals()}
      title={t('Lista za isljučivanje')}
      width={'70%'}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      className={style.izvestajModal}
    >
      <IzvestajiZaIskljucivanjeTable />
      <Button
        className={style.iskljuciButton}
        name={'iskljuci_dugme'}
        disabled={!izvestajiStore.zaIskljucitiList.length}
        onClick={() => {
          izvestajiStore.iskljuciIzvestaje();
          izvestajiStore.handleChange('newListFlag', !izvestajiStore.newListFlag);
          modalStore.removeAllModals();
        }}
      >
        Isključi
      </Button>
    </Modal>
  );
});
