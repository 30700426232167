import { FormInstance } from 'antd';
import { observer } from 'mobx-react-lite';
import { RegistriLists } from 'modules/registri/registri.types';
import { OTVRegistarZagadjenjaForm } from 'pages/Registri/components/OTVRegistar/OTVRegistarForm/OTVRegistarZagadjenjaForm/OTVRegistarZagadjenjaForm';
import { useMemo } from 'react';
import { FormListConfig, FormListType } from 'typescript/NrizTypes';

interface ModalFormComponentProps<T> {
  configPath: FormListConfig;
  parentForm: FormInstance<T>;
  formListName: string;
  selectedIndex: number;
}

export const ModalFormComponent = observer(
  // eslint-disable-next-line
  <T extends object>({ configPath, parentForm, formListName, selectedIndex }: ModalFormComponentProps<T>) => {
    const { formListType, formListSubType } = configPath;

    if (!formListType || !formListSubType) return null;

    const component = useMemo(() => {
      switch (formListType) {
        case FormListType.Registri: {
          switch (formListSubType) {
            case RegistriLists.OTV:
              return (
                <OTVRegistarZagadjenjaForm<T>
                  parentForm={parentForm}
                  formListName={formListName}
                  selectedIndex={selectedIndex}
                />
              );
          }
        }
      }
    }, [configPath]);
    return component ? <div>{component}</div> : null;
  }
);
