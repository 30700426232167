import { ColumnsType } from 'antd/es/table';
import { IGetNuts1Code, IPostNuts1Code } from './nuts1Code.types';
import { CODE_FORM, NAME_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { CODE_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';

export const NUTS1_CODE_INITIAL_STATE: IPostNuts1Code = {
  code: '',
  name: '',
  is_active: true,
};
export const NUTS1_CODE_TABLE_DATA: ColumnsType<IGetNuts1Code> = [CODE_TABLE, { ...NAME_TABLE, width: '600px' }];
export const NUTS1_CODE_FORM_OPTIONS = (_: FormInstance<IGetNuts1Code>) => {
  return [CODE_FORM, NAME_FORM];
};
