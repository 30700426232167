import dayjs from 'dayjs';

import { ITablePagination } from 'components/TableData/TableData';
import { obrazacIzvestajiRepo } from './obrazacIzvestaji.repo';
import {
  Aao1PregledPoGodinamaIzvestajFilters,
  IObrazacIzvestajFilters,
  Obrazac1GorivoIzvestajFilters,
  ObrazacIzvestajiAllFilters,
  ObrazacIzvestajiAllReports,
} from './obrazacIzvestaji.types';
import { IIzvestajiColumnGroupProps, IIzvestajiColumnProps } from './obrazacIzvestajiColumns.constants';

class ObrazacIzvestajiService {
  getObrazacByType = (
    payload: IObrazacIzvestajFilters,
    pagination: ITablePagination
  ): Promise<{ items: ObrazacIzvestajiAllReports }> => {
    const validPayload = { ...payload, filter_params: this.parseYearFilterFormat(payload.filter_params) };
    return obrazacIzvestajiRepo.getObrazacByType(validPayload, pagination);
  };

  exportToExcel = (payload: IObrazacIzvestajFilters) => {
    return obrazacIzvestajiRepo.exportToExcel(payload);
  };

  exportToPDF = (payload: IObrazacIzvestajFilters) => {
    return obrazacIzvestajiRepo.exportToExcel(payload);
  };

  formatGodina = (godina?: string) => {
    return godina ? dayjs(godina).format('YYYY') : godina;
  };

  isFilterByGodina = (object: any): object is Obrazac1GorivoIzvestajFilters => {
    return 'godina' in object;
  };

  isFilterByGodinaOd = (object: any): object is Aao1PregledPoGodinamaIzvestajFilters => {
    return 'godina_od' in object;
  };

  isFilterByGodinaDo = (object: any): object is Aao1PregledPoGodinamaIzvestajFilters => {
    return 'godina_do' in object;
  };

  parseYearFilterFormat = (filter_params: ObrazacIzvestajiAllFilters) => {
    if (this.isFilterByGodina(filter_params)) {
      filter_params.godina = this.formatGodina(filter_params.godina);
    }
    if (this.isFilterByGodinaOd(filter_params)) {
      filter_params.godina_od = this.formatGodina(filter_params.godina_od);
    }
    if (this.isFilterByGodinaDo(filter_params)) {
      filter_params.godina_do = this.formatGodina(filter_params.godina_do);
    }
    return filter_params;
  };

  // any for now...
  getYearlyColumns<T>(data: T[]): IIzvestajiColumnProps<T>[] {
    if (!data || !data[0]) return [];

    return (
      (data[0] as any).kolicine?.map((kolicinaItem: any, index: string) => {
        return {
          width: 120,
          render: (record: any) => record?.kolicine?.[index]?.kolicina,
          title: kolicinaItem.godina,
        };
      }) ?? []
    );
  }

  getYearDifferenceGroupColumns<T>(
    data: T[],
    headerCols: IIzvestajiColumnProps<T>[],
    yearGroupCols: (index: number) => IIzvestajiColumnProps<T>[]
  ): IIzvestajiColumnGroupProps<T>[] {
    if (!data || !data[0]) return [];

    const startData = (data[0] as any).kolicine?.[0];
    const endData = (data[0] as any).kolicine?.[1];

    return [
      {
        title: '',
        children: [...headerCols],
      },
      {
        title: startData?.godina,
        children: [...yearGroupCols(0)],
      },
      {
        title: endData?.godina,
        children: [...yearGroupCols(1)],
      },
    ];
  }
}

export const obrazacIzvestajiService = new ObrazacIzvestajiService();
