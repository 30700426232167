import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { gio4ObrazacStore } from 'modules/obrasci/store/gio4/gio4.store';
import { useTranslation } from 'react-i18next';
import { GIO_4_DOZVOLE_INITIAL_STATE } from 'modules/obrasci/store/gio4/gio4.constants';
import { NewGio4DozvoleForm } from './components/NewGio4DozvoleForm/NewGio4DozvoleForm';

export const Gio4DozvoleModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  return (
    <CRUDModal
      width="40%"
      name="gio4-dozvole-modal"
      title={t('WIZARD_TABS.DOZVOLE')}
      store={gio4ObrazacStore}
      data={gio4ObrazacStore.getDozvoleInitial}
      children={() => <NewGio4DozvoleForm />}
      submitForm={gio4ObrazacStore.submitDozvoleForm}
      initialState={GIO_4_DOZVOLE_INITIAL_STATE}
      dataToChange={'dozvoleInitial'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    />
  );
});
