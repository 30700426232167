import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IGetSastav, ISastav } from 'modules/obrasci/store/kom1/kom1.types';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const TableSastav: React.FC = observer(() => {
  const { t } = useTranslation();
  // todo:prevod
  const baseColumns: ColumnsType<IGetSastav> = [
    {
      title: `${t('OBRASCI.KOM_1.COMPOSITION_OF_MUNICIPAL_WASTE')}`,
      width: 100,
      dataIndex: ['composition_communal_waste', 'name_sr'],
      key: 'name_sr',
    },
    {
      title: `Prolećna analiza - ${t('OBRASCI.KOM_1.QUANTITY_FRACTIONS')}`,
      width: 100,
      dataIndex: 'prolecna_analiza',
      key: 'prolecna_analiza',
    },
    {
      title: `Letnja analiza - ${t('OBRASCI.KOM_1.QUANTITY_FRACTIONS')}`,
      dataIndex: 'letnja_analiza',
      key: 'letnja_analiza',
      width: 100,
    },
    {
      title: `Jesenja analiza - ${t('OBRASCI.KOM_1.QUANTITY_FRACTIONS')}`,
      dataIndex: 'jesenja_analiza',
      key: 'jesenja_analiza',
      width: 100,
    },
    {
      title: `Zimska analiza - ${t('OBRASCI.KOM_1.QUANTITY_FRACTIONS')}`,
      dataIndex: 'zimska_analiza',
      key: 'zimska_analiza',
      width: 100,
    },
    {
      title: `${t('Srednja godišnja vrednost (t)')}`,
      dataIndex: 'srednja_vrednost',
      key: 'srednja_vrednost',
      width: 100,
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: ISastav) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-sastav'}
            onClick={() => {
              kom1ObrazacStore.handleChange('sastav_form', record);
              modalStore.changeModalName('sastav-modal');
            }}
          />
        );
      },
    },
  ];

  return <TableData name="sastav-kom1" columns={baseColumns} dataSource={[...kom1ObrazacStore.initialState.sastav]} />;
});
