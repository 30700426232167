import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { TableKolicine } from './components/TableKolicine/TableKolicine';

export const Kom1KolicineWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<WizardObrazacListHeader name={'kolicine-form'} modalName="kolicine-modal" />}>
      <TableKolicine />
    </InsideContentWrapper>
  );
});
