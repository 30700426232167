import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { FormInput } from 'components/FormInput/FormInput';
import {
  MORE_THEN_0,
  POST_NUMBER_FIELD_RULE,
  REQUIRED_FIELD_RULE,
  invalidCharactersForInputNumber,
} from 'modules/nriz/nriz.constants';
import styles from '../NewUpravljanjeObrazac5.module.scss';
import { obrazac5Store } from 'modules/obrasci/store/obrazac5/obrazac5.store';
import { IGetUpravljanjeObrazac5 } from 'modules/obrasci/store/obrazac5/obrazac5.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { drListOptions } from 'utils/optionMakers';

export const NewIzvozObrazac5Form: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  const form = props.form;

  const [required, setRequired] = useState<boolean>(false);

  const onKolicineChange = () => {
    const kolicine = form?.getFieldValue('kolicina_izvezenog_otpada');
    kolicine ? setRequired(true) : setRequired(false);
  };

  const defaultZemljaIzvozaOption: ISifarnikInitial | undefined = obrazac5Store.getUpravljanjeForm.zemlja_izvoza_id
    ? {
        label: (obrazac5Store.getUpravljanjeForm as IGetUpravljanjeObrazac5).zemlja_izvoza.name,
        value: obrazac5Store.getUpravljanjeForm.zemlja_izvoza_id,
      }
    : undefined;

  const defaultDrOption: ISifarnikInitial | undefined = obrazac5Store.getUpravljanjeForm
    .d_ili_r_oznaka_za_izvoz_otpada_id
    ? {
        value: obrazac5Store.getUpravljanjeForm.d_ili_r_oznaka_za_izvoz_otpada_id,
        label: `${(obrazac5Store.getUpravljanjeForm as IGetUpravljanjeObrazac5).d_ili_r_oznaka_za_izvoz_otpada.name} ${
          (obrazac5Store.getUpravljanjeForm as IGetUpravljanjeObrazac5).d_ili_r_oznaka_za_izvoz_otpada.description_sr
        }`,
      }
    : undefined;

  useEffect(() => {
    onKolicineChange();
  }, []);

  return (
    <>
      <h4 className={styles.titleMargin}>{t('OBRASCI.OBRAZAC5.IZVOZ_OTPADA')}</h4>

      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'zemlja_izvoza_id'}
        fetchOptinsList={obrazac5Store.fetchCountryCode}
        labelOptionAccessor="name"
        valueOptionAccessor="id"
        inputName={'zemlja_izvoza_id'}
        label={t('OBRASCI.OBRAZAC5.ZEMLJA_IZVOZA')}
        colon={true}
        defaultOption={defaultZemljaIzvozaOption}
        placeholder={t('OBRASCI.OBRAZAC5.ZEMLJA_IZVOZA')}
        rules={[REQUIRED_FIELD_RULE(required)]}
      />
      <FormInput
        name="kolicina_izvezenog_otpada"
        label={t('OBRASCI.OBRAZAC5.KOLICINA_IZVEZENOG_OTPADA')}
        type="number"
        placeholder={t('OBRASCI.OBRAZAC5.KOLICINA_IZVEZENOG_OTPADA')}
        inputName={'kolicina_izvezenog_otpada'}
        colon={true}
        rules={MORE_THEN_0}
        onInputChange={onKolicineChange}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'d_ili_r_oznaka_za_izvoz_otpada_id'}
        fetchOptinsList={obrazac5Store.fetchDROptionsIzvoz}
        labelOptionAccessor="name"
        valueOptionAccessor="id"
        inputName={'d_ili_r_oznaka_za_izvoz_otpada_id'}
        label={t('OBRASCI.OBRAZAC5.D_ILI_R_OZNAKA')}
        colon={true}
        defaultOption={defaultDrOption}
        placeholder={t('OBRASCI.OBRAZAC5.D_ILI_R_OZNAKA')}
        rules={[REQUIRED_FIELD_RULE(required)]}
        optionsMaker={drListOptions}
      />
      <FormInput
        name="naziv_postrojenja_za_tretman_otpada_izvoz"
        label={t('OBRASCI.OBRAZAC5.NAZIV_ZA_TRETMAN')}
        type="text"
        placeholder={t('OBRASCI.OBRAZAC5.NAZIV_ZA_TRETMAN')}
        inputName={'naziv_postrojenja_za_tretman_otpada_izvoz'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(required)]}
      />
      <FormInput
        name="ulica_postrojenja_za_tretman_otpada_izvoz"
        label={t('OBRASCI.OBRAZAC5.ULICA_ZA_TRETMAN')}
        type="text"
        placeholder={t('OBRASCI.OBRAZAC5.ULICA_ZA_TRETMAN')}
        inputName={'ulica_postrojenja_za_tretman_otpada_izvoz'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(required)]}
      />
      <FormInput
        name="kucni_broj_postrojenja_za_tretman_otpada_izvoz"
        label={t('OBRASCI.OBRAZAC5.KUCNI_BROJ_ZA_TRETMAN')}
        type="text"
        placeholder={t('OBRASCI.OBRAZAC5.KUCNI_BROJ_ZA_TRETMAN')}
        inputName={'kucni_broj_postrojenja_za_tretman_otpada_izvoz'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(required)]}
      />
      <FormInput
        name="mesto_postrojenja_za_tretman_otpada_izvoz"
        label={t('OBRASCI.OBRAZAC5.MESTO_ZA_TRETMAN')}
        type="text"
        placeholder={t('OBRASCI.OBRAZAC5.MESTO_ZA_TRETMAN')}
        inputName={'mesto_postrojenja_za_tretman_otpada_izvoz'}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(required)]}
      />
      <FormInput
        type="number"
        name="postanski_broj_postrojenja_za_tretman_otpada_izvoz"
        label={t('OBRASCI.OBRAZAC5.POSTANSKI_ZA_TRETMAN')}
        placeholder={t('OBRASCI.OBRAZAC5.POSTANSKI_ZA_TRETMAN')}
        inputName={'postanski_broj_postrojenja_za_tretman_otpada_izvoz'}
        invalidCharacters={[...invalidCharactersForInputNumber, '.']}
        colon={true}
        rules={[REQUIRED_FIELD_RULE(required), POST_NUMBER_FIELD_RULE, ...MORE_THEN_0]}
      />
    </>
  );
});
