import { observer } from 'mobx-react-lite';
import styles from './Obrazac3GodisnjiRadio.module.scss';
import { CheckboxOptionType, Form, Radio } from 'antd';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { useTranslation } from 'react-i18next';
import { OBRAZAC_3_FIELDS_TO_RESET } from 'modules/obrasci/store/obrazac3/obrazac3.constants';
import { useState } from 'react';
import { modalStore } from 'store';
import { Popconfirm } from 'components/Popconfirm/Popconfirm';

export const Obrazac3GodisnjiRadio = observer(() => {
  const { t } = useTranslation();

  const form = Form.useFormInstance();

  const [popConfirmActivated, setPopConfirmActivated] = useState(false);

  const godisnjiBilansDisabled = () => {
    const merenja = obrazac3Store.initialState.merenja_otpadne_vode.flatMap(
      merenje => merenje.izmerene_vrednosti_otpadne_vode
    );
    const atLeastOneUnit = merenja.some(merenje => merenje.jedinica_mere_id);

    if (!obrazac3Store.initialState.merenja_otpadne_vode.length || !atLeastOneUnit) return true;
    return false;
  };

  interface GodisnjiCheckboxOptions extends CheckboxOptionType {
    popconfirm?: boolean;
    checked?: boolean;
    key: number | string;
  }

  const godisnjiOptions: GodisnjiCheckboxOptions[] = [
    {
      value: false,
      label: t('OBRASCI.OBRAZAC3.GODISNJI_NE'),
      popconfirm: !!obrazac3Store.initialState.kreiranje_godisnjeg_bilansa,
      checked: obrazac3Store.initialState.kreiranje_godisnjeg_bilansa === false,
      key: Math.random(),
    },
    {
      value: true,
      label: t('OBRASCI.OBRAZAC3.GODISNJI_DA'),
      disabled: godisnjiBilansDisabled() || popConfirmActivated,
      checked: obrazac3Store.initialState.kreiranje_godisnjeg_bilansa === true,
      key: Math.random(),
    },
  ];

  const onGodisnjiOptionChange = (value: boolean) => {
    obrazac3Store.handleInitialStateChange('kreiranje_godisnjeg_bilansa', value);
    form.setFieldValue('kreiranje_godisnjeg_bilansa', value);
    if (value) modalStore.changeModalName('obrazac3-godisnji-bilans-modal');
    else {
      OBRAZAC_3_FIELDS_TO_RESET.forEach(item => {
        form.setFieldValue(item, '');
      });
      obrazac3Store.handleInitialStateChange('godisnji_bilans', []);
    }
  };

  const radioGodisnjiOptions = godisnjiOptions.map(option => {
    return option.popconfirm ? (
      <Popconfirm
        key={option.key}
        name="kreiranje_godisnjeg_bilansa"
        title={t('OBRASCI.GVE.BILANS_PITANJE')}
        onConfirm={() => {
          setPopConfirmActivated(false);
          onGodisnjiOptionChange(false);
        }}
        onCancel={() => {
          form.setFieldValue('kreiranje_godisnjeg_bilansa', true);
          setPopConfirmActivated(false);
        }}
        onOpenChange={open => {
          if (open) setPopConfirmActivated(true);
        }}
        open={popConfirmActivated}
      >
        <Radio value={option.value} disabled={option.disabled} checked={option.checked}>
          {option.label}
        </Radio>
      </Popconfirm>
    ) : (
      <Radio
        value={option.value}
        disabled={option.disabled}
        onChange={event => onGodisnjiOptionChange(event.target.value)}
        checked={option.checked}
        key={option.key}
      >
        {option.label}
      </Radio>
    );
  });

  return (
    <Form.Item
      className={styles.bilansSelect}
      label={t('OBRASCI.OBRAZAC3.GODISNJI_PITANJE')}
      name={'kreiranje_godisnjeg_bilansa'}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Radio.Group value={obrazac3Store.initialState.kreiranje_godisnjeg_bilansa}>{radioGodisnjiOptions}</Radio.Group>
    </Form.Item>
  );
});
