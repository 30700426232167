import { ColumnsType } from 'antd/es/table';
import { IGetMunicipalityCode, IPostMunicipalityCode } from './municipalityCode.types';
import { FormInstance } from 'antd';
import { CODE_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { CODE_FORM, NAME_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { t } from 'i18next';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';

export const MUNICIPALITY_CODE_INITIAL_STATE: IPostMunicipalityCode = {
  code: null,
  name: '',
  longitude_min: '',
  longitude_max: '',
  latitude_min: '',
  latitude_max: '',
  is_active: true,
};
export const MUNICIPALITY_CODE_TABLE_DATA: ColumnsType<IGetMunicipalityCode> = [
  NAME_TABLE,
  CODE_TABLE,
  {
    title: `${t('COMPANY.LONGITUDE')} min`,
    width: '200px',
    dataIndex: 'longitude_min',
    key: 'longitude_min',
  },
  {
    title: `${t('COMPANY.LONGITUDE')} max`,
    width: '200px',
    dataIndex: 'longitude_max',
    key: 'longitude_max',
  },
  {
    title: `${t('COMPANY.LATITUDE')} min`,
    width: '200px',
    dataIndex: 'latitude_min',
    key: 'latitude_min',
  },
  {
    title: `${t('COMPANY.LATITUDE')} max`,
    width: '200px',
    dataIndex: 'latitude_max',
    key: 'latitude_max',
  },
];
export const MUNICIPALITY_CODE_FORM_OPTIONS = (_: FormInstance<IGetMunicipalityCode>) => {
  return [
    CODE_FORM,
    NAME_FORM,
    {
      type_of_form: 'input',
      name: 'longitude_min',
      // todo: prevod
      label: `${t('COMPANY.LONGITUDE')} min`,
      inputName: 'longitude_min',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'longitude_max',
      // todo: prevod
      label: `${t('COMPANY.LONGITUDE')} max`,
      inputName: 'longitude_max',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'latitude_min',
      // todo: prevod
      label: `${t('COMPANY.LATITUDE')} min`,
      inputName: 'latitude_min',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'latitude_max',
      // todo: prevod
      label: `${t('COMPANY.LATITUDE')} max`,
      inputName: 'latitude_max',
    } as InputSifarniciFormProps,
  ];
};
