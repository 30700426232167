import { Checkbox } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';

export const IS_ACTIVE_TABLE = {
  title: `${t('COMPANY.ACTIVE')}`,
  width: '100px',
  key: 'is_active',
  align: 'center',
  render: (_: any, record: any) => <Checkbox checked={!!record?.is_active} disabled={true} />,
};
export const THE_ORDER_TABLE = {
  title: 'Redosled',
  width: '100px',
  dataIndex: 'the_order',
  key: 'the_order',
};

export const TITLE_TABLE = {
  title: 'Naslov',
  width: '200px',
  dataIndex: 'title',
  key: 'title',
};

export const DESCRIPTION_TABLE = {
  title: t('SIFARNICI.OPIS'),
  width: '300px',
  dataIndex: 'description',
  key: 'description',
};
export const DESCRIPTION_SR_TABLE = {
  title: `${t('SIFARNICI.OPIS')} ${t('NA_SRPSKOM')}`,
  width: '300px',
  dataIndex: 'description_sr',
  key: 'description_sr',
};

export const VERSION_TABLE = {
  title: 'Verzija',
  width: '100px',
  dataIndex: 'version',
  key: 'version',
};

export const CODE_TABLE = {
  title: 'Šifra',
  width: '200px',
  dataIndex: 'code',
  key: 'code',
};
export const CODE_2_TABLE = {
  title: 'Šifra 2',
  width: '100px',
  dataIndex: 'code2',
  key: 'code2',
};

export const NAME_TABLE = {
  title: t('NAZIV'),
  width: '250px',
  dataIndex: 'name',
  key: 'name',
};

export const NAME_SR_TABLE = {
  title: `${t('NAZIV')} ${t('NA_SRPSKOM')}`,
  width: '250px',
  dataIndex: 'name_sr',
  key: 'name_sr',
};

export const APPROVED_TABLE = {
  title: `Odobreno`,
  width: '100px',
  dataIndex: 'approved',
  key: 'approved',
  render: (_: any, record: any) => <Checkbox checked={!!record.approved} disabled={true} />,
  align: 'center',
};

export const VALIDITY_FROM_DATE_TABLE = {
  title: t('SIFARNICI.OD_DATUMA'),
  width: '150px',
  dataIndex: 'validity_from_date',
  key: 'validity_from_date',
  render: (_: any, record: any) => (
    <span>{record.validity_from_date ? dayjs(record.validity_from_date).format('DD. MM. YYYY.') : ''}</span>
  ),
};
export const VALIDITY_TO_DATE_TABLE = {
  title: t('SIFARNICI.DO_DATUMA'),
  width: '150px',
  dataIndex: 'validity_to_date',
  key: 'validity_to_date',
  render: (_: any, record: any) => (
    <span>{record.validity_to_date ? dayjs(record.validity_to_date).format('DD. MM. YYYY.') : ''}</span>
  ),
};

export const INDEX_NUMBER_TABLE = {
  title: 'Indeksni broj',
  width: '200px',
  dataIndex: 'index_number',
  key: 'index_number',
};
