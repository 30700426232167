import { useTranslation } from 'react-i18next';
import TableData from 'components/TableData/TableData';
import { ColumnsType } from 'antd/es/table';
import { IGetUser, IUserListProps, UserRoles } from 'modules/user/user.types';
import { observer } from 'mobx-react-lite';
import { authStore, modalStore } from 'store';
import { Button } from 'components/Button/Button';
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { Popconfirm } from 'components/Popconfirm/Popconfirm';
import { Checkbox, message } from 'antd';
import { userStore } from 'modules/user/user.store';
import { UserListFilters } from './UserListFilters/UserListFilters';

export const UsersListTable: React.FC<IUserListProps> = observer(props => {
  const { t } = useTranslation();
  const location = useLocation();

  const toggleResponsible = (user: IGetUser) => {
    if (user.odgovorno_lice) return;
    userStore.putUserResponsible(user);
    message.success(t('USER.ALERTS.RESPONSIBLE_SUCCESS'));
  };

  const baseColumns: ColumnsType<IGetUser> = [
    {
      title: `${t('COMPANY.ACTIVE')}`,
      width: '100px',
      key: 'is_active',
      align: 'center',
      render: (_: any, record: IGetUser) => <Checkbox checked={!!record?.is_active} disabled={true} />,
    },
    {
      title: `${t('USER.USERNAME')}`,
      width: '200px',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: `${t('USER.NAME')}`,
      width: '150px',
      dataIndex: 'ime',
      key: 'ime',
    },
    {
      title: `${t('USER.SURNAME')}`,
      width: '150px',
      dataIndex: 'prezime',
      key: 'prezime',
    },
    {
      title: `${t('USER.EMAIL')}`,
      dataIndex: 'email',
      key: 'email',
      width: '250px',
    },
    {
      title: `${t('USER.LANGUAGE')}`,
      dataIndex: 'jezik',
      key: 'jezik',
      width: '100px',
    },
  ];

  const odgovorneOsobe: ColumnsType<IGetUser> = [
    {
      title: 'Odgovorno lice preduzeća',
      dataIndex: 'odgovorna_osoba_preduzeca',
      key: 'odgovorna_osoba_preduzeca',
      width: '200px',
      align: 'center',
      render: (_: any, record: IGetUser) => <Checkbox checked={!!record.odgovorna_osoba_preduzeca} disabled={true} />,
    },
    {
      title: 'Odgovorno lice za saradnju sa Agencijom',
      dataIndex: 'odgovorna_osoba_za_saradnju_sa_agencijom',
      key: 'odgovorna_osoba_za_saradnju_sa_agencijom',
      align: 'center',
      width: '200px',
      render: (_: any, record: IGetUser) => (
        <Checkbox checked={!!record.odgovorna_osoba_za_saradnju_sa_agencijom} disabled={true} />
      ),
    },
    {
      title: 'Odgovorno lice za izveštavanje',
      dataIndex: 'odgovorna_osoba_za_izvestavanje',
      align: 'center',
      key: 'odgovorna_osoba_za_izvestavanje',
      render: (_: any, record: IGetUser) => (
        <Checkbox checked={!!record.odgovorna_osoba_za_izvestavanje} disabled={true} />
      ),
      width: '200px',
    },
  ];

  const editFixed: ColumnsType<IGetUser> = [
    {
      title: 'Izmena',
      key: 'operation',
      fixed: 'right',
      width: '90px',
      align: 'center',
      render: (_, record) => {
        return (
          <Link to={`/user/${record.id}`}>
            <Button
              disabled={authStore.getUserRole === UserRoles.CompanyUser}
              icon={<EditOutlined rev={undefined} />}
              name={'edit-user'}
            />
          </Link>
        );
      },
    },
  ];

  const changeResponsibilityCompany: ColumnsType<IGetUser> = [
    {
      title: 'Promena odgovornosti',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      width: '150px',
      render: (_: any, record: IGetUser) => {
        return (
          <Button
            onClick={() => {
              userStore.setUser(record);
              modalStore.changeModalName('user-responsibility-modal');
            }}
            disabled={authStore.getUserRole === UserRoles.CompanyUser}
            icon={<UserSwitchOutlined rev={undefined} />}
            name={'edit-user'}
          />
        );
      },
    },
  ];

  const editableColumns: ColumnsType<IGetUser> = [
    ...baseColumns,
    ...odgovorneOsobe,
    ...changeResponsibilityCompany,
    ...editFixed,
  ];

  const usersListPreduzece: ColumnsType<IGetUser> = [
    ...baseColumns,
    {
      title: `${t('COMPANY.NAME')}`,
      dataIndex: ['preduzece', 'naziv'],
      width: '200px',
      key: 'naziv',
    },
    {
      title: `${t('COMPANY.PIB')}`,
      dataIndex: ['preduzece', 'pib'],
      width: '200px',
      key: 'pib',
    },
    {
      title: `${t('COMPANY.ENTERPRISE_ID_NUMBER')}`,
      dataIndex: ['preduzece', 'maticni_broj'],
      width: '200px',
      key: 'maticni_broj',
    },
    ...odgovorneOsobe,
    ...editFixed,
  ];

  const facilityColumns: ColumnsType<IGetUser> = [
    ...baseColumns,
    {
      title: `${t('USER.DELETE')}`,
      key: 'deleteFacility',
      width: '100px',
      fixed: 'right',
      align: 'center',
      render: (_, user) => {
        return (
          <Popconfirm
            name="delete_user"
            placement="leftBottom"
            title={t('USER.DELETE')}
            description={t('USER.ALERTS.DELETE')}
            onConfirm={() => {
              if (user.id) {
                userStore.deleteFacilityUser(user.id);
                message.success(t('USER.ALERTS.DELETE_SUCCESS'));
              }
            }}
            okText={t('YES')}
            cancelText={t('NO')}
          >
            <Button icon={<DeleteOutlined rev={undefined} />} name={'delete-user'} />
          </Popconfirm>
        );
      },
    },
    {
      title: `${t('USER.SET_RESPONSIBLE')}`,
      key: 'responsible',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (_: any, user: IGetUser) => {
        return (
          <Popconfirm
            name="set_responsible"
            placement="leftBottom"
            title={t('USER.SET_RESPONSIBLE')}
            description={t('USER.ALERTS.RESPONSIBLE')}
            onConfirm={() => toggleResponsible(user)}
            okText={t('YES')}
            cancelText={t('NO')}
          >
            <Button
              icon={
                user.odgovorno_lice ? <CheckCircleFilled rev={undefined} /> : <CheckCircleOutlined rev={undefined} />
              }
              name={'responsible-user'}
              onClick={() => {
                if (user.odgovorno_lice) return;
              }}
            />
          </Popconfirm>
        );
      },
    },
  ];

  let validColumns = baseColumns;

  if (authStore.getUserRole !== UserRoles.CompanyUser) {
    validColumns = props.isFacility ? facilityColumns : editableColumns;
  }

  if (location.pathname.includes('/users')) {
    validColumns = usersListPreduzece;
  }

  return (
    <>
      <UserListFilters filterName={props.filterName} />
      <TableData
        scrollConfig={location.pathname.includes('/users') ? ['#userHeader', '#userFilters'] : undefined}
        name="user"
        columns={validColumns}
        dataSource={props.isFacility ? [...userStore.facilityUserList] : [...userStore.userList]}
        getData={props.fetchListTable}
        withPagination={true}
        forceFetch={[props.forceFetch]}
      />
    </>
  );
});
