import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { NewGveGorivaForm } from './NewGveGorivaForm/NewGveGorivaForm';
import { GVE_GORIVA_INITIAL_STATE } from 'modules/obrasci/store/gve/gve.constants';

export const GveGorivaModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  return (
    <CRUDModal
      width="60%"
      name="gve-gorivo-modal"
      title={t('WIZARD_TABS.GORIVA')}
      store={gveStore}
      data={gveStore.getGorivaForm}
      children={() => <NewGveGorivaForm />}
      submitForm={gveStore.submitGorivaForm}
      initialState={GVE_GORIVA_INITIAL_STATE}
      dataToChange={'goriva_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    />
  );
});
