import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import { MAX_LENGTH_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { gio3ObrazacStore } from 'modules/obrasci/store/gio3/gio3.store';
import { IGetPostrojenjeGio3 } from 'modules/obrasci/store/gio3/gio3.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NewPostrojenjaGio3Form: React.FC = observer(() => {
  const { t } = useTranslation();

  const defaMestoOption: ISifarnikInitial | undefined = gio3ObrazacStore.postrojenja_form.mesto_postrojenja_id
    ? {
        label: (gio3ObrazacStore.postrojenja_form as IGetPostrojenjeGio3).mesto_postrojenja.display_name,
        value: gio3ObrazacStore.postrojenja_form.mesto_postrojenja_id,
      }
    : undefined;
  return (
    <>
      <h3>{t('OBRASCI.GIO_3.DATA_ON_THE_WASTE_RECYCLING_FACILITY_OWNED_BY_COMPANY')}</h3>
      <FormInput
        name="adresa_postrojenja"
        type="text"
        label={t('ADDRESS')}
        rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(200)]}
        placeholder={''}
        inputName={'adresa_postrojenja'}
        colon={true}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'mesto_postrojenja_id'}
        fetchOptinsList={gio3ObrazacStore.fetchMesto}
        labelOptionAccessor="display_name"
        rules={[REQUIRED_FIELD_RULE(true)]}
        valueOptionAccessor="id"
        label={t('COMPANY.LOCATION')}
        readOnly={false}
        inputName={'mesto_postrojenja_id'}
        defaultOption={defaMestoOption}
        placeholder={t('COMPANY.LOCATION')}
      />
      <FormInput
        name="geografska_sirina"
        type="number"
        label={t('COMPANY.LATITUDE')}
        placeholder={''}
        // onInputChange={(e: any) => handleChange('prolece', e.target.value)}
        inputName={'geografska_sirina'}
        colon={true}
      />
      <FormInput
        name="geografska_duzina"
        type="number"
        label={t('COMPANY.LONGITUDE')}
        placeholder={''}
        // onInputChange={(e: any) => handleChange('prolece', e.target.value)}
        inputName={'geografska_duzina'}
        colon={true}
      />

      <h4 style={{ marginBlock: '30px', fontWeight: 'bold' }}>
        {t('KOORDINATE_TEXT')}
        <a target="_blank" href="http://www.nrizgis.sepa.gov.rs/nrizgis/kor/">
          http//www.nrizgis.sepa.gov.rs/nrizgis/kor/
        </a>
      </h4>
    </>
  );
});
