import { IGetCompany } from 'modules/company/company.types';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetActivitiesOfWasteOperators } from 'modules/sifarnici/store/activitiesOfWasteOperators/activitiesOfWasteOperators.types';

import { IGetApr } from 'modules/sifarnici/store/apr/apr.types';
import { IGetCountryCode } from 'modules/sifarnici/store/countryCode/countryCode.types';
import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { IGetWeeeCategories } from 'modules/sifarnici/store/weeeCategories/weeeCategories.types';

export interface IPostDeo6Obrazac {
  datum: string;
  preduzece_id: string;
  postrojenje_id: string;
  napomena: string;
  skladiste: IGetSkladistaDeo6[];
  preuzet_otpad: IGetPreuzetOtpad[];
  predat_otpad: IGetPredatOtpad[];
  ponovna_upotreba_otpada: IGetPonovnaUpotrebaDeo6[];
}

export interface IGetDeo6Obrazac extends IPostDeo6Obrazac {
  id: string;
  preduzece: IGetCompany;
  postrojenje: IGetFacility;
}

export interface IPostRazrediEeOtpadaDeo6 {
  razred_ee_otpada_id: string;
  kolicina: number | null;
}

export interface IGetRazrediEeOtpadaDeo6 extends IPostRazrediEeOtpadaDeo6 {
  id: string;
  razred_ee_otpada: IGetWeeeCategories;
}

export interface IPostSkladistaDeo6 {
  indeksni_broj_otpada_id: string;
  kolicina_prvi_januar: number | null;
  razred: IGetRazrediEeOtpadaDeo6[];
}

export interface IGetSkladistaDeo6 extends IPostSkladistaDeo6 {
  indeksni_broj_otpada: IGetWasteCatalogue;
  id: string;
}
export interface IPostPonovnaUpotrebaDeo6 {
  indeksni_broj_otpada_id: string;
  kolicina: number | null;
  razred: IGetRazrediEeOtpadaDeo6[];
}

export interface IGetPonovnaUpotrebaDeo6 extends IPostPonovnaUpotrebaDeo6 {
  indeksni_broj_otpada: IGetWasteCatalogue;
  id: string;
}

export interface IPostPredatOtpad {
  indeksni_broj_otpada_id: string;
  kolicina: number | null;
  nacin_postupanja: NacinPostupanjaEnum | '';
  preduzece_id: string;
  naziv_preduzeca?: string;
  operater_je_id: string;
  broj_dozvole: string;
  r_oznaka_id: string;
  broj_dozvole_ponovno_iskoriscenje: string;
  d_oznaka_id: string;
  broj_dozvole_za_odlaganje: string;
  postrojenje_id: string;
  zemlja_izvoza_id: string;
  broj_dozvole_za_izvoz: string;
  nove_vrste_otpada: boolean;
  razred: IGetRazrediEeOtpadaDeo6[];
  razlog_postojanja_razlike_zbira_kolicina_novih_vrsti_i_kolicine_predatog_otpada?: string;
  nove_vrste_otpada_lista: IGetNoveVrsteOtpada[];
}

export interface IGetPredatOtpad extends IPostPredatOtpad {
  indeksni_broj_otpada: IGetWasteCatalogue;
  preduzece: IGetApr;
  id: string;
  postrojenje: IGetFacility;
  zemlja_izvoza: IGetCountryCode;
  operater_je: IGetActivitiesOfWasteOperators;
  d_oznaka: IGetDRList;
  r_oznaka: IGetDRList;
  razred: IGetRazrediEeOtpadaDeo6[];
  nove_vrste_otpada_lista: IGetNoveVrsteOtpada[];
}

export interface IPostNoveVrsteOtpada {
  indeksni_broj_otpada_id: string;
  kolicina: number | null;
  razred: IGetRazrediEeOtpadaDeo6[];
}

export interface IGetNoveVrsteOtpada extends IPostNoveVrsteOtpada {
  id: string;
  indeksni_broj_otpada: IGetWasteCatalogue;
}

export interface IPostPreuzetOtpad {
  indeksni_broj_otpada_id: string;
  kolicina: number | null;
  preuzet_od: PreuzetOdEnum | '';
  postrojenje_id?: string;
  zemlja_izvoza_id?: string;
  preduzece_id?: string;
  naziv_preduzeca?: string;
  proizvodjac_otpada?: boolean;
  sakupljac?: boolean;
  operater_na_deponiji?: boolean;
  operater_tretmana_otpada?: boolean;
  skladistar?: boolean;
  uvoznik_otpada?: boolean;
  drugi_vlasnik?: boolean;
  razred: IGetRazrediEEPreuzet[];
}

export interface IGetPreuzetOtpad extends IPostPreuzetOtpad {
  indeksni_broj_otpada: IGetWasteCatalogue;
  id: string;
  preduzece: IGetApr;
  postrojenje: IGetFacility;
  zemlja_izvoza: IGetCountryCode;
}

export interface IPostRazrediEEPreuzet {
  razred_ee_otpada_id: string;
  kolicina_domacinstva: number | null;
  kolicina_drugi_izvor: number | null;
  ukupna_kolicina: number | null;
}

export interface IGetRazrediEEPreuzet extends IPostRazrediEEPreuzet {
  razred_ee_otpada: IGetWeeeCategories;
  id: string;
}

export enum PreuzetOdEnum {
  FIZICKO_LICE = 'fizicko_lice',
  PRAVNO_LICE = 'pravno_lice',
  SOPSTVENI_UVOZ = 'sopstveni_uvoz',
  POSTROJENJE = 'postrojenje',
  SOPSTVENO_POSTROJENJE = 'sopstveno_postrojenje',
}

export enum NacinPostupanjaEnum {
  PREDATO_DRUGOM_OPERATERU = 'predato_drugom_operateru',
  SPOSTVENI_PROCES_PONOVNOG_ISKORISCENJA = 'sopstveni_proces_ponovnog_iskoriscenja',
  SOPSTVENI_LOKACIJA = 'sopstveni_lokacija',
  POSTROJENJE = 'postrojenje',
  IZVEZENO = 'izvezeno',
}

export interface Deo6KalkulacijaOtpadValues {
  indeksni_broj_otpada_id: string;
  indeksni_broj_otpada: IGetWasteCatalogue;
  kolicina: number;
}

export interface Deo6KalkulacijaRazredValues {
  razred_ee_otpada_id: string;
  razred_ee_otpada: IGetWeeeCategories;
  kolicina: number;
}

export interface Deo6KalkulacijaResponse {
  indeksni_brojevi: Deo6KalkulacijaOtpadValues[];
  razredi_ee_otpada: Deo6KalkulacijaRazredValues[];
}
export interface Deo6KalkulacijaMaps {
  indeksni_brojevi: Map<string, Deo6KalkulacijaOtpadValues>;
  razredi_ee_otpada: Map<string, Deo6KalkulacijaRazredValues>;
}

export interface Deo6KalkulacijaParams {
  preduzece_id: string;
  postrojenje_id: string;
  datum: string;
}

export interface Deo6KalulacijaModalData {
  kalkulacijaArrays: Deo6KalkulacijaResponse;
  obrazacData: IGetDeo6Obrazac;
}

export interface CalculateSkladisteArgs {
  obrazac?: IGetDeo6Obrazac | undefined;
  skladiste: Deo6KalkulacijaMaps;
  operation?: '+' | '-';
}

export enum Deo6Gio6Enum {
  DEO6 = 'DEO6',
  GIO6 = 'GIO6',
}
