import { Col } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';

import {
  FormInputProps,
  FormNumberInputProps,
  FormSelectProps,
  FormSwitchProps,
  FormTextAreaProps,
  IFormScrollWithObjProps,
  IRangePickerProps,
} from 'typescript/NrizTypes';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSwitch } from 'components/FormSwitch/FormSwitch';
import { FormSelect } from 'components/FormSelect/FormSelect';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { FormScrollWithObjInside } from 'components/FormScrollWithObjInside/FormScrollWithObjInside';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
// import { invalidCharactersForInputNumber } from 'modules/nriz/nriz.constants';

interface IFormComponentsProps {
  data: ISifarniciTypeOfForm;
}

export const FormComponents: React.FC<IFormComponentsProps> = observer(props => {
  if (props.data.type_of_form === 'title') {
    return (
      <Col span={24}>
        <strong>
          <h4>{props.data.title}</h4>
        </strong>
      </Col>
    );
  }
  if (props.data.type_of_form === 'input') {
    return (
      <Col span={24}>
        <FormInput
          name={(props.data as FormInputProps).name}
          inputName={(props.data as FormInputProps).inputName}
          onInputChange={(props.data as FormInputProps).onInputChange}
          label={(props.data as FormInputProps).label}
          placeholder={(props.data as FormInputProps).placeholder}
          rules={(props.data as FormInputProps).rules}
          type={(props.data as FormInputProps).type}
          disabled={(props.data as FormInputProps).disabled}
          invalidCharacters={(props.data as FormInputProps).invalidCharacters}
        />
      </Col>
    );
  }
  if (props.data.type_of_form === 'input_number') {
    return (
      <Col span={24}>
        <FormInputNumber
          // invalidCharacters={invalidCharactersForInputNumber}
          name={(props.data as FormNumberInputProps).name}
          inputName={(props.data as FormNumberInputProps).inputName}
          onInputChange={(props.data as FormNumberInputProps).onInputChange}
          label={(props.data as FormNumberInputProps).label}
          placeholder={(props.data as FormNumberInputProps).placeholder}
          rules={(props.data as FormNumberInputProps).rules}
          type={(props.data as FormNumberInputProps).type}
          disabled={(props.data as FormNumberInputProps).disabled}
          precision={(props.data as FormNumberInputProps).precision}
        />
      </Col>
    );
  }

  if (props.data.type_of_form === 'switch') {
    return (
      <Col span={24}>
        <FormSwitch
          onSwitchChange={(props.data as FormSwitchProps).onSwitchChange}
          rules={(props.data as FormSwitchProps).rules}
          name={(props.data as FormSwitchProps).name}
          label={(props.data as FormSwitchProps).label}
          disabled={(props.data as FormSwitchProps).disabled}
        />
      </Col>
    );
  }

  if (props.data.type_of_form === 'select') {
    return (
      <Col span={24}>
        <FormSelect
          name={(props.data as FormSelectProps).name}
          label={(props.data as FormSelectProps).label}
          options={(props.data as FormSelectProps).options}
          disabled={(props.data as FormSelectProps).disabled}
          onChange={(props.data as FormSelectProps).onChange}
        />
      </Col>
    );
  }

  if (props.data.type_of_form === 'date') {
    return (
      <Col span={24}>
        <FormDatePicker
          label={(props.data as IRangePickerProps).label}
          placeholder={(props.data as IRangePickerProps).placeholder}
          name={(props.data as IRangePickerProps).name}
          format={(props.data as IRangePickerProps).format}
          picker={(props.data as IRangePickerProps).picker}
          onChange={(props.data as IRangePickerProps).onChange}
          disabledDate={(props.data as IRangePickerProps).disabledDate}
          rules={(props.data as IRangePickerProps).rules}
          disabled={props.data.disabled}
        />
      </Col>
    );
  }

  if (props.data.type_of_form === 'area') {
    return (
      <Col span={24}>
        <FormTextArea
          label={(props.data as FormTextAreaProps).label}
          name={(props.data as FormTextAreaProps).name}
          rows={(props.data as FormTextAreaProps).rows}
          labelCol={(props.data as FormTextAreaProps).labelCol}
          wrapperCol={(props.data as FormTextAreaProps).wrapperCol}
          rules={(props.data as FormTextAreaProps).rules}
          disabled={(props.data as FormTextAreaProps).disabled}
        />
      </Col>
    );
  }
  if (props.data.type_of_form === 'searchScroll') {
    return (
      <Col span={24}>
        <FormScrollWithObjInside
          showSearch={true}
          formName={(props.data as IFormScrollWithObjProps).formName}
          label={(props.data as IFormScrollWithObjProps).label}
          rules={(props.data as IFormScrollWithObjProps).rules}
          options={(props.data as IFormScrollWithObjProps).options}
          labelOptionAccessor={(props.data as IFormScrollWithObjProps).labelOptionAccessor}
          valueOptionAccessor={(props.data as IFormScrollWithObjProps).valueOptionAccessor}
          disabledAccessor={(props.data as IFormScrollWithObjProps).disabledAccessor}
          fetchOptinsList={(props.data as IFormScrollWithObjProps).fetchOptinsList}
          onChange={(props.data as IFormScrollWithObjProps).onChange}
          placeholder={(props.data as IFormScrollWithObjProps).placeholder}
          resetOption={(props.data as IFormScrollWithObjProps).resetOption}
          defaultOption={(props.data as IFormScrollWithObjProps).defaultOption}
          disabled={(props.data as IFormScrollWithObjProps).disabled}
          objName={(props.data as IFormScrollWithObjProps).objName}
        />
      </Col>
    );
  }
  return <></>;
});
