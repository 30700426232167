import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { IGetKolicineGio2 } from 'modules/obrasci/store/gio2/gio2.types';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const Gio2TableKolicine: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetKolicineGio2> = [
    {
      title: `${t('OBRASCI.KOM_1.MANAGEMENT.WASTE_INDEX_NUMBER_FROM_THE_WASTE_CATALOG')}`,
      width: 200,
      dataIndex: ['indeksni_broj_otpada', 'index_number'],
      key: 'waste',
      render: (_: any, record: IGetKolicineGio2) => (
        <>{`${record.indeksni_broj_otpada.index_number} ${record.indeksni_broj_otpada.waste_sr.toUpperCase()}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.GIO_3.AMOUNT_OF_COLLECTED_WASTE_IN_REPORTING_YEAR')}`,
      width: 200,
      dataIndex: 'kolicina_preuzetog_otpada',
      key: 'kolicina_preuzetog_otpada',
    },
    {
      title: `${t('OBRASCI.GIO2.UKUPNA_KOLICINA_ODLOZENOG')}`,
      dataIndex: 'ukupna_kolicina_ove_vrste_otpada',
      key: 'ukupna_kolicina_ove_vrste_otpada',
      width: 200,
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetKolicineGio2) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-kolicine-gio2'}
            onClick={() => {
              gio2ObrazacStore.handleChange('kolicine_form', record);
              modalStore.changeModalName('gio2-kolicine-modal');
            }}
          />
        );
      },
    },
  ];
  // todo: check if backend return whole array or we have to do it with pagination
  return (
    <TableData name="kolicine-gio2" columns={baseColumns} dataSource={[...gio2ObrazacStore.initialState.kolicine]} />
  );
});
