import { omit } from 'lodash';
import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { IPagination } from 'typescript';
import {
  ResponseCompany,
  ResponseCheckCompany,
  ICompanyTipoviObrasca,
  IGetCompany,
  ResponseLocationsList,
  IPostCompany,
} from './company.types';

const postPib = (payload: any): ApiResponse<any> => {
  return axios.post(`${ROUTES.CONNECTION_APR}`, payload);
};

const companyRegistration = (payload: IPostCompany): ApiResponse<any> => {
  return axios.post(`${ROUTES.COMPANY}`, payload);
};

const fetchCompaniesList = (payload: Partial<IPagination>): ApiResponse<ResponseCompany> => {
  const query = queryString.stringify(payload);
  return axios.get(`${ROUTES.COMPANY}?${query}`);
};

const exportCompanyExcel = (filters: any): ApiResponse<Blob> => {
  const query = queryString.stringify(filters);
  return axios.get(`${ROUTES.COMPANY}export?${query}`, { responseType: 'blob' });
};

const checkCompany = (): ApiResponse<ResponseCheckCompany> => {
  return axios.get(`${ROUTES.USER}${ROUTES.COMPANY}`);
};

const companyApprove = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.COMPANY}${id}/${ROUTES.APPROVE}`);
};
const companyReject = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.COMPANY}${id}/${ROUTES.REJECT}`);
};

const fetchCompany = (id: string): ApiResponse<IGetCompany> => {
  return axios.get(`${ROUTES.COMPANY}${id}`);
};

const fetchCompanyTipObrasca = (companyId: string): ApiResponse<any> => {
  return axios.get(`${ROUTES.COMPANY}${companyId}/tip-obrasca`);
};

const postCompanyTipObrasca = (companyId: string, tipoviObrazca: ICompanyTipoviObrasca): ApiResponse<any> => {
  return axios.post(`${ROUTES.COMPANY}${companyId}/tip-obrasca`, tipoviObrazca);
};

const putCompany = (company: IGetCompany): ApiResponse<any> => {
  const patchCompanyObj = omit(company, ['id']);
  return axios.put(`${ROUTES.COMPANY}${company.id}`, patchCompanyObj);
};

const deleteCompany = (id: string): ApiResponse<any> => {
  return axios.delete(`${ROUTES.COMPANY}${id}`);
};

const aktivirajPreduzece = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.COMPANY}${id}/aktiviraj`);
};
const deaktivirajPreduzece = (id: string): ApiResponse<any> => {
  return axios.put(`${ROUTES.COMPANY}${id}/deaktiviraj`);
};

// todo: sifarnici prepakuj

const fetchLocationList = (payload: Partial<IPagination>): ApiResponse<ResponseLocationsList> => {
  const query = queryString.stringify(payload);
  return axios.get(`/${ROUTES.LOCATION}/?${query}`);
};

const fetchPretezneDelatnostiList = (payload: Partial<IPagination>): ApiResponse<ResponseLocationsList> => {
  const query = queryString.stringify(payload);
  return axios.get(`/${ROUTES.PRETEZNE_DELATNOSTI}/?${query}`);
};

const checkForDeo6 = (id: string): ApiResponse<{ ima_deo6: boolean }> => {
  return axios.get(`${ROUTES.COMPANY}${id}/deo6`);
};

const toggleDeo6Validation = (id: string, params: { privremeno_ukinuta_validacija_za_deo6: boolean }) => {
  const query = queryString.stringify(params);
  return axios.put(`${ROUTES.COMPANY}${id}/validacija-za-deo6?${query}`);
};

export const companyRepo = {
  postPib,
  checkCompany,
  companyRegistration,
  companyReject,
  companyApprove,
  fetchCompaniesList,
  exportCompanyExcel,
  fetchCompany,
  fetchCompanyTipObrasca,
  postCompanyTipObrasca,
  putCompany,
  aktivirajPreduzece,
  deaktivirajPreduzece,
  deleteCompany,
  checkForDeo6,
  toggleDeo6Validation,
  fetchLocationList,
  fetchPretezneDelatnostiList,
};
