import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';

import { useTranslation } from 'react-i18next';
import { aao1Store } from 'modules/obrasci/store/aao1/aao1.store';
import { AAO1_UPRAVLJANJE_AMBALAZNIM_OTPADOM_INITIAL_STATE } from 'modules/obrasci/store/aao1/aao1.constants';
import { NewUpravljanjeAmbalaznimOtpadomForm } from './components/NewUpravljanjeAmbalaznimOtpadomForm';

export const UpravljanjeAmbalaznimOtpadomModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="70%"
      name="upravljanje-ambalaznim-otpadom-modal"
      title={t('OBRASCI.AAO1.MANAGEMENT_OF_PACKAGING_WASTE.TITLE')}
      store={aao1Store}
      data={aao1Store.getUpravljanjeAmbalaznimOtpadom}
      children={() => <NewUpravljanjeAmbalaznimOtpadomForm />}
      submitForm={aao1Store.submitUpravljanjeAmbOtpadom}
      initialState={AAO1_UPRAVLJANJE_AMBALAZNIM_OTPADOM_INITIAL_STATE}
      dataToChange={'upravljanjeAmbalaznimOtpadom'}
    />
  );
});
