import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import TableData from 'components/TableData/TableData';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IGetPredato, IGetUpravljanje } from 'modules/obrasci/store/kom1/kom1.types';
import React from 'react';
import { modalStore } from 'store';

export const PredatoTable: React.FC = observer(() => {
  const baseColumns: ColumnsType<IGetPredato> = [
    {
      title: t('OBRASCI.KOM_1.MANAGEMENT.WAY_OF_HAND_OVER'),
      width: 100,
      dataIndex: ['way_of_hand_over', 'name_sr'],
      key: 'way_of_hand_over',
    },
    {
      title: t('OBRASCI.KOM_1.QUANTITY_T'),
      width: 100,
      dataIndex: 'kolicina',
      key: 'kolicina',
    },
    {
      title: t('OBRASCI.KOM_1.MANAGEMENT.D_OR_R_MARK'),
      width: 100,
      dataIndex: ['dr_list', 'name'],
      key: 'dr_list_id',
    },
    {
      title: t('OBRASCI.KOM_1.MANAGEMENT.NAME_AND_ADDRESS_OF_THE_OPERATOR'),
      width: 100,
      dataIndex: 'naziv_i_adresa_operatera',
      key: 'naziv_i_adresa_operatera',
    },
    {
      title: t('OBRASCI.KOM_1.MANAGEMENT.OPERATOR_LICENSE_NUMBER'),
      width: 100,
      dataIndex: 'broj_dozvole_operatera',
      key: 'broj_dozvole_operatera',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record: IGetPredato) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-razred-predat-otpad-deo6'}
            onClick={() => {
              kom1ObrazacStore.handleChange('predato_form', record);
              modalStore.changeModalName('predato-kom1-modal');
            }}
          />
        );
      },
    },
  ];
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader title={'Predato'} name={'predato-kom1'} modalName="predato-kom1-modal" />}
    >
      <TableData
        columns={baseColumns}
        dataSource={[...(kom1ObrazacStore.upravljanje_form as IGetUpravljanje).kom1_nacin_predaje_otpada]}
        name={'predato-kom1'}
      />
    </InsideContentWrapper>
  );
});
