import { IPostUser, IPostUserResponsibility } from './user.types';

export const userInitialState: IPostUser = {
  username: '',
  ime: '',
  prezime: '',
  email: '',
  broj_telefon: '',
  jezik: '',
  function_of_person: '',
  alternativni_telefon: '',
};

export const USER_COMPANY_RESPONSIBILITY_INITIAL_STATE: IPostUserResponsibility = {
  odgovorna_osoba_preduzeca: false,
  odgovorna_osoba_za_izvestavanje: false,
  main_company_user: false,
};
