import { Row } from 'antd';
import { Switch } from 'components/Switch/Switch';
import { observer } from 'mobx-react-lite';
import { UserRoles } from 'modules/user/user.types';
import React, { useEffect, useState } from 'react';
import { authStore } from 'store';

interface IIsActiveProps {
  isActive: boolean;
  aktiviraj: () => void;
  deaktiviraj: () => void;
  disabled?: boolean;
}

export const IsActiveSwitch: React.FC<IIsActiveProps> = observer(props => {
  const [isActive, setIsActive] = useState(false);

  const onSwitchChange = (e: any) => {
    e ? props.aktiviraj() : props.deaktiviraj();
  };

  useEffect(() => {
    setIsActive(props?.isActive);
  }, [props?.isActive]);

  return (
    <>
      {authStore.getUserRole === UserRoles.SystemAdmin && (
        <Row align={'middle'}>
          {isActive ? 'Aktivan' : 'Neaktivan'}
          <Switch
            disabled={props?.disabled}
            name={'is_active'}
            checked={isActive}
            onChange={(e: any) => onSwitchChange(e)}
            style={{ margin: '0px 10px' }}
          />
        </Row>
      )}
    </>
  );
});
