import { Col, Row } from 'antd';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { useTranslation } from 'react-i18next';
import { disabledTodayAndFutureDates, disabledTodayAndPreviousDates } from 'utils/formatDate';

import styles from './NewGio2DozvoleForm.module.scss';
import { useEffect } from 'react';
import { FormInput } from 'components/FormInput/FormInput';

export const NewGio2DozvoleForm: React.FC = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    gio2ObrazacStore.handleChange('dozvoleNizValidacija', []);
    if (gio2ObrazacStore.dozvole_form.sakupljanje === true) {
      gio2ObrazacStore.promeniDozvoleNizValidacijaListu('sakupljanje', true);
    }
    if (gio2ObrazacStore.dozvole_form.odlaganje === true) {
      gio2ObrazacStore.promeniDozvoleNizValidacijaListu('odlaganje', true);
    }
    if (gio2ObrazacStore.dozvole_form.skladistenje === true) {
      gio2ObrazacStore.promeniDozvoleNizValidacijaListu('skladistenje', true);
    }
    if (gio2ObrazacStore.dozvole_form.transport === true) {
      gio2ObrazacStore.promeniDozvoleNizValidacijaListu('transport', true);
    }
    if (gio2ObrazacStore.dozvole_form.tretman === true) {
      gio2ObrazacStore.promeniDozvoleNizValidacijaListu('tretman', true);
    }
  }, []);

  return (
    <Col span={24}>
      <div className={styles.labelMargin} style={{ fontWeight: 'bold' }}>
        {t('OBRASCI.GIO2.PODACI_O_DOZVOLI_ZA_UPRAVLJANJE')}
      </div>

      <FormInput
        name="broj_dozvole"
        label={t('OBRASCI.GIO2.BROJ_DOZVOLE')}
        placeholder={t('OBRASCI.GIO2.BROJ_DOZVOLE')}
        inputName={'broj_dozvole'}
        colon={true}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                gio2ObrazacStore.getDozvoleForm.broj_dozvole !== value &&
                gio2ObrazacStore.initialState.dozvole.some(item => {
                  return value === item.broj_dozvole;
                })
              ) {
                return Promise.reject(new Error(t('VALIDATION.ALREADY_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
      />

      <FormDatePicker
        name="datum_izdavanja"
        label={t('OBRASCI.GIO2.DATUM_IZDAVANJA')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        format="DD-MM-YYYY"
        picker={'date'}
        disabledDate={disabledTodayAndFutureDates}
        rules={[REQUIRED_FIELD_RULE(true)]}
        placeholder={t('SELECT_DATE')}
      />
      <FormDatePicker
        name="datum_isteka"
        label={t('OBRASCI.GIO2.DATUM_ISTEKA')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        format="DD-MM-YYYY"
        picker={'date'}
        disabledDate={disabledTodayAndPreviousDates}
        rules={[REQUIRED_FIELD_RULE(true)]}
        placeholder={t('SELECT_DATE')}
      />
      <div className={styles.labelMargin} style={{ fontWeight: 'bold' }}>
        <span>*</span> {t('OBRASCI.GIO2.DOZVOLA_VAZI_ZA')}
      </div>
      {!gio2ObrazacStore.dozvoleNizValidacija.length && (
        <div className={styles.validateError}>{t('OBRASCI.GIO2.VALIDACIJA_CHECKBOX')}</div>
      )}

      <Row>
        <Col span={24}>
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'sakupljanje'}
            label={t('OBRASCI.GIO2.SAKUPLJANJE')}
            onCheckBoxChange={e => {
              gio2ObrazacStore.promeniDozvoleNizValidacijaListu('sakupljanje', e.target.checked);
            }}
          />
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'transport'}
            label={t('OBRASCI.GIO2.TRANSPORT')}
            onCheckBoxChange={e => {
              gio2ObrazacStore.promeniDozvoleNizValidacijaListu('transport', e.target.checked);
            }}
          />
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'skladistenje'}
            label={t('OBRASCI.GIO2.SKLADISTENJE')}
            onCheckBoxChange={e => {
              gio2ObrazacStore.promeniDozvoleNizValidacijaListu('skladistenje', e.target.checked);
            }}
          />
          <FormCheckbox
            // labelCol={{ span: 12 }}
            name={'tretman'}
            label={t('OBRASCI.GIO2.TRETMAN')}
            onCheckBoxChange={e => {
              gio2ObrazacStore.promeniDozvoleNizValidacijaListu('tretman', e.target.checked);
            }}
          />
          <FormCheckbox
            labelCol={{ span: 12 }}
            name={'odlaganje'}
            label={t('OBRASCI.GIO2.ODLAGANJE')}
            onCheckBoxChange={e => {
              gio2ObrazacStore.promeniDozvoleNizValidacijaListu('odlaganje', e.target.checked);
            }}
          />
        </Col>
      </Row>
    </Col>
  );
});
