import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { SASTAV_INITIAL_STATE } from 'modules/obrasci/store/kom1/kom1.constants';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewSastavForm } from './components/NewSastavForm/NewSastavForm';

export const SastavModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  //   todo: prevod
  return (
    <CRUDModal
      width="40%"
      name="sastav-modal"
      title={'Sastav'}
      store={kom1ObrazacStore}
      data={kom1ObrazacStore.getSastavForm}
      children={({ form }) => <NewSastavForm form={form} />}
      submitForm={kom1ObrazacStore.submitSastavForm}
      initialState={SASTAV_INITIAL_STATE}
      dataToChange={'sastav_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    />
  );
});
