import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { EmisijeUTloHeader } from './components/EmisijeUTloHeader/EmisijeUTloHeader';
import { EmisijeUTloTable } from './components/EmisijeUTloTable/EmisijeUTloTable';

export const EmisijeUTloWizardTab = () => {
  return (
    <InsideContentWrapper header={<EmisijeUTloHeader />}>
      <EmisijeUTloTable />
    </InsideContentWrapper>
  );
};
