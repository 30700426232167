import { Col, Row } from 'antd';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import {
  MORE_OR_EQUILE_0_IS_REQUIRED,
  MORE_THEN_0,
  MORE_THEN_0_IS_REQUIRED,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { IGetIzmereneVrednostiGve } from 'modules/obrasci/store/gve/gve.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { EqualsOrLessThan } from 'pages/ObrazacWizard/components/components/EqualsOrLessThan/EqualsOrLessThan';
// import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const NewGveIzmereneVrednostiForm: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  const form = props.form;
  form;

  // const [nazivZagadjujuce, setNazivZagadjujuce] = useState<string>(
  //   gveStore.izmerene_vrednosti_form.naziv_zagadjujuce_materije_id
  // );

  // const [disableGve, setDisableGve] = useState<boolean>(false);

  const defaultZagadjujucaOption: ISifarnikInitial | undefined = gveStore.getIzmereneVrednostiForm
    .naziv_zagadjujuce_materije_id
    ? {
        label: (gveStore.getIzmereneVrednostiForm as IGetIzmereneVrednostiGve).naziv_zagadjujuce_materije.name_sr,
        value: gveStore.getIzmereneVrednostiForm.naziv_zagadjujuce_materije_id,
      }
    : undefined;

  const defaultMetodaOdredjivanjaOption: ISifarnikInitial | undefined = gveStore.getIzmereneVrednostiForm
    .metod_utvrdjivanja_id
    ? {
        label: (gveStore.getIzmereneVrednostiForm as IGetIzmereneVrednostiGve).metod_utvrdjivanja.name_sr,
        value: gveStore.getIzmereneVrednostiForm.metod_utvrdjivanja_id,
      }
    : undefined;

  // useEffect(() => {
  //   if (
  //     !!nazivZagadjujuce &&
  //     gveStore.emisije_u_vazduh_form.merenja_izmerene_vrednosti_emisija_u_vazduhu.length !== 0
  //   ) {
  //     const gve = gveStore.handleGve(nazivZagadjujuce);
  //     if (!gve) {
  //       form.setFieldValue('gve', null);
  //       setDisableGve(false);
  //       return;
  //     }
  //     if (gve) {
  //       form.setFieldValue('gve', gve);
  //       setDisableGve(true);
  //     }
  //   }
  // }, [nazivZagadjujuce]);

  return (
    <Col span={24}>
      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.GVE.ZAGADJUJUCA_MATERIJA')}</h4>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'naziv_zagadjujuce_materije_id'}
        fetchOptinsList={gveStore.fetchPollutantOptions}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.GVE.NAZIV_ZAGADJUJUCE_MATERIJE')}
        readOnly={false}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        inputName={'naziv_zagadjujuce_materije_id'}
        defaultOption={defaultZagadjujucaOption}
        placeholder={t('OBRASCI.GVE.NAZIV_ZAGADJUJUCE_MATERIJE')}
        rules={[REQUIRED_FIELD_RULE(true)]}
        // onChange={e => setNazivZagadjujuce(e)}
      />

      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.GVE.MASENA_KONCENTRACIJA_ZAGAJUJUCE')}</h4>
      <Row>
        <Col span={15}>
          <EqualsOrLessThan
            name={'predznak_izmerene_vrednosti'}
            label={t('OBRASCI.GVE.IZMERENA_VREDNOST')}
            labelCol={{ span: 19 }}
            wrapperCol={{ span: 3 }}
          />
        </Col>
        <Col span={9}>
          <FormInputNumber
            name="izmerene_vrednosti"
            type="number"
            placeholder={t('OBRASCI.GVE.IZMERENA_VREDNOST')}
            inputName={'izmerene_vrednosti'}
            colon={true}
            wrapperCol={{ span: 24 }}
            rules={MORE_THEN_0_IS_REQUIRED}
          />
        </Col>
      </Row>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'metod_utvrdjivanja_id'}
        fetchOptinsList={gveStore.fetchMethodOptionsIzmereneVrednosti}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.GVE.METODA_UTVRDJIVANJA')}
        readOnly={false}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        inputName={'metod_utvrdjivanja_id'}
        defaultOption={defaultMetodaOdredjivanjaOption}
        placeholder={t('OBRASCI.GVE.METODA_UTVRDJIVANJA')}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormInputNumber
        name="gve"
        label={t('OBRASCI.GVE.GVE_MG_NM')}
        type="number"
        placeholder={t('OBRASCI.GVE.GVE_MG_NM')}
        inputName={'gve'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        // disabled={disableGve}
        rules={MORE_OR_EQUILE_0_IS_REQUIRED}
      />

      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.GVE.PROTOK')}</h4>
      <FormInputNumber
        name="protok_otpadnog_gasa"
        label={t('OBRASCI.GVE.PROTOK_OTPADNOG_GASA')}
        type="number"
        placeholder={t('OBRASCI.GVE.PROTOK_OTPADNOG_GASA')}
        inputName={'protok_otpadnog_gasa'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_THEN_0_IS_REQUIRED}
      />

      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.GVE.MASENI_PROTOK_ZAGADJUJUCE')}</h4>

      <Row>
        <Col span={15}>
          <EqualsOrLessThan
            name={'predznak_maseni_protok_zagadjujuce_materije'}
            label={t('OBRASCI.GVE.MASENI_PROTOK_MATERIJE_G_H')}
            labelCol={{ span: 19 }}
            wrapperCol={{ span: 3 }}
          />
        </Col>
        <Col span={9}>
          <FormInputNumber
            name="maseni_protok_zagadjujuce_materije"
            type="number"
            placeholder={t('OBRASCI.GVE.MASENI_PROTOK_MATERIJE_G_H')}
            inputName={'maseni_protok_zagadjujuce_materije'}
            colon={true}
            wrapperCol={{ span: 24 }}
            rules={MORE_THEN_0_IS_REQUIRED}
          />
        </Col>
      </Row>

      <h4 style={{ fontWeight: 'bold' }}>{t('OBRASCI.GVE.EMITOVANE_U_AKCIDENTNIM')}</h4>
      <FormInputNumber
        name="emitovane_kolicine_u_akcidentnim_situacijama"
        label={t('OBRASCI.GVE.EMITOVANE_KOLICINE')}
        type="number"
        placeholder={t('OBRASCI.GVE.EMITOVANE_KOLICINE')}
        inputName={'emitovane_kolicine_u_akcidentnim_situacijama'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MORE_THEN_0}
      />
    </Col>
  );
});
