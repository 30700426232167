import { IPostAir } from './air.types';

export const airInitialState: IPostAir = {
  broj_izvora: null,
  naziv_izvora: '',
  aktivan: false,
  source_type_id: '',
  koordinate_long: null,
  koordinate_lat: null,
  nadmorska_visina: null,
  instalisana_toplotna_snaga_na_ulazu: null,
  visina_izvora: null,
  unutrasnji_precnik_izvora_na_vrhu: null,
  napomena: '',
  postrojenje_id: '',
  zakljucane_koordinate: false,
  obaveza_merenja: 'da',
};
