import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { gio5ObrazacStore } from 'modules/obrasci/store/gio5/gio5.store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewGio5ZemljeTranzitaForm } from './NewGio5ZemljeTranzitaForm/NewGio5ZemljeTranzitaForm';
import { GIO_5_ZEMLJA_TRANZITA_INITIAL_STATE } from 'modules/obrasci/store/gio5/gio5.constants';

export const Gio5ZemljeTranzitaModal: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <CRUDModal
      width="50%"
      name="gio5-zemlje-tranzita-modal"
      title={t('OBRASCI.GIO4.TRANSIT')}
      store={gio5ObrazacStore}
      data={gio5ObrazacStore.getZemljaTranzita}
      children={() => <NewGio5ZemljeTranzitaForm />}
      submitForm={gio5ObrazacStore.submitZemljeTranzita}
      initialState={GIO_5_ZEMLJA_TRANZITA_INITIAL_STATE}
      dataToChange={'zemljaTranzita'}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      closeOneModal={true}
    />
  );
});
