import { ITablePagination } from 'components/TableData/TableData';
import { IGetCompany } from 'modules/company/company.types';
import { IGetFacility } from 'modules/facility/facility.types';
import { ITipObrasca } from 'modules/obrasci/obrasci.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { IGetAir } from 'modules/sourcesOfPollution/air/air.types';
import { IGetSoil } from 'modules/sourcesOfPollution/soil/soil.types';
import { IGetWater } from 'modules/sourcesOfPollution/water/water.types';

export interface IGodina {
  godina: number;
}

export interface IGodinaTipObrasca {
  tip_obrasca_id: string;
  tip_obrasca: ITipObrasca;
  zakljucana_godina: boolean;
}

export interface ITipoviGodinaResponse {
  godina: number;
  tipovi_obrazaca: IGodinaTipObrasca[];
}

export interface IToggleLockPayload {
  tipovi_obrazaca: IGodinaTipObrasca[];
}

export interface IZakljucavanjeGodinaIzuzeci extends IGodinaTipObrasca {
  id: string;
  godina: number;
  preduzece_id: string;
  preduzece: IGetCompany;
}

export interface IZakljucavanjeGodinaIzuzetakPayload {
  godina: number;
  preduzece_id: string;
  zakljucana_godina: boolean;
  tip_obrasca_id: string;
}

export enum OtkljucaniZakljucaniTipoviObrazaca {
  OTKLJUCANO = 'otkljucano',
  ZAKLJUCANO = 'zakljucano',
}

export interface IZakljucavanjeObrazacaFilters extends ITablePagination {
  obrazac_id: string;
  tip_obrasca_id: string;
  godina: number | null;
  preduzece_id: string;
  postrojenje_id: string;
  izvor_zagadjenja_vazduha_id: string;
  izvor_zagadjenja_vode_id: string;
  izvor_zagadjenja_tla_id: string;
  indeksni_broj_otpada_id: string;
  zakljucan_obrazac: boolean;
}

export interface IObrazacZaZakljucavanje extends IZakljucavanjeObrazacaFilters {
  tip_obrasca: ITipObrasca;
  preduzece: IGetCompany;
  postrojenje: IGetFacility;
  izvor_zagadjenja_vazduha: IGetAir;
  izvor_zagadjenja_vode: IGetWater;
  izvor_zagadjenja_tla: IGetSoil;
  indeksni_broj_otpada: IGetWasteCatalogue;
}
