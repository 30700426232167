import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { DjubrivaTable } from 'pages/ObrazacWizard/components/pzv1/components/DjubirvaWizardTab/components/DjubrivaTable/DjubrivaTable';
import { DjubrivaHeader } from './components/DjubrivaHeader';

export const DjubrivaWizardTab = () => {
  return (
    <InsideContentWrapper header={<DjubrivaHeader />}>
      <DjubrivaTable />
    </InsideContentWrapper>
  );
};
