import { Form, FormInstance, Select } from 'antd';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormScrollWithObjInside } from 'components/FormScrollWithObjInside/FormScrollWithObjInside';
import { observer } from 'mobx-react-lite';
import {
  MORE_THEN_0,
  MORE_THEN_0_IS_REQUIRED,
  REQUIRED_FIELD_RULE,
  checkIfItemExistsInList,
} from 'modules/nriz/nriz.constants';
import { OTVJediniceMere } from 'modules/registri/otv/otv.types';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { IGetPollutantCode } from 'modules/sifarnici/store/pollutantCode/pollutantCode.types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RuleConfig } from 'typescript';

interface FormListItemProps<T> {
  parentForm: FormInstance<T>;
  formListName: string;
  selectedIndex: number;
}
export const OTVRegistarZagadjenjaForm = observer(
  // eslint-disable-next-line
  <T extends object>({ parentForm, formListName, selectedIndex }: FormListItemProps<T>) => {
    const { t } = useTranslation();

    const jedinicaMereOptions = Object.values(OTVJediniceMere).map(key => {
      return {
        // proverirti sa be da li je ovo ok?
        label: key,
        value: key,
      };
    });

    const form = Form.useFormInstance();

    const granicnaVrednostRules: RuleConfig[] = useMemo(() => {
      const zagadjucaMaterija: IGetPollutantCode | undefined = form.getFieldValue('naziv_zagadjujuce_materije');
      if (zagadjucaMaterija?.name_sr.includes('BPK5')) return MORE_THEN_0;
      return MORE_THEN_0_IS_REQUIRED;
    }, [Form.useWatch('naziv_zagadjujuce_materije_id')]);

    const zagadjujucaMaterijaExistsRule = useMemo(() => {
      const accessor = 'naziv_zagadjujuce_materije_id';
      const formList = parentForm.getFieldValue(formListName);
      const previousValue = formList[selectedIndex] ? formList[selectedIndex][accessor] : undefined;
      return checkIfItemExistsInList(previousValue, accessor, formList);
    }, [Form.useWatch('naziv_zagadjujuce_materije_id'), Form.useWatch(formListName, parentForm)]);

    return (
      <div>
        <FormScrollWithObjInside
          placeholder=""
          name="naziv_zagadjujuce_materije_id"
          formName="naziv_zagadjujuce_materije_id"
          label="Zagađujuća materija"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          fetchOptinsList={SIFARNICI_ROUTES.POLLUTANT_CODE}
          filtersForFetch={{
            code: 'WATER',
            order_by: '+name_sr',
          }}
          onChange={() => form.setFieldValue('jedinica_mere', OTVJediniceMere.mg_l)}
          labelOptionAccessor={['name_sr']}
          valueOptionAccessor="id"
          disabledAccessor="is_active"
          rules={[REQUIRED_FIELD_RULE(true), zagadjujucaMaterijaExistsRule]}
        />
        <Form.Item
          name={'jedinica_mere'}
          label={t('OBRASCI.OBRAZAC3.JEDINICA_MERE')}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          rules={[REQUIRED_FIELD_RULE(true)]}
        >
          <Select options={jedinicaMereOptions} />
        </Form.Item>
        <FormInputNumber
          inputName={'granicna_vrednost'}
          name={'granicna_vrednost'}
          label="Granična vrednost"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          rules={granicnaVrednostRules}
        />
      </div>
    );
  }
);
