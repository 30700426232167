import { ColumnsType } from 'antd/es/table';
import { IGetMethodBasisCode, IPostMethodBasisCode } from './methodBasisCode.types';

import {
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';

import {
  TITLE_FORM,
  DESCRIPTION_FORM,
  VERSION_FORM,
  CODE_FORM,
  NAME_FORM,
  NAME_SR_FORM,
  VALIDITY_TO_DATE_FORM,
  VALIDITY_FROM_DATE_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';

import { FormInstance } from 'antd';

export const METHOD_BASIS_CODE_INITIAL_STATE: IPostMethodBasisCode = {
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  name_sr: '',
  is_active: true,
};
export const METHOD_BASIS_CODE_TABLE_DATA: ColumnsType<IGetMethodBasisCode> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
];
export const METHOD_BASIS_CODE_FORM_OPTIONS = (form: FormInstance<IGetMethodBasisCode>) => {
  return [
    NAME_FORM,
    NAME_SR_FORM,
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM,
    DESCRIPTION_FORM,
    VERSION_FORM,
    CODE_FORM,
  ];
};
