import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { NewNaseljeGradoviForm } from './NaseljeDep1Form/NewNaseljeDep1Form';
import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { DEP1_MESTO_INITIAL_STATE } from 'modules/obrasci/store/dep1/dep1.constants';
import { dep1Store } from 'modules/obrasci/store/dep1/dep1.store';

export const NaseljeModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="50%"
      name="naselje-modal"
      title={t('OBRASCI.DEP1.PANELI.GRADOVI_OPSTINE')}
      store={dep1Store}
      data={dep1Store.getMestoForm}
      children={() => <NewNaseljeGradoviForm />}
      submitForm={dep1Store.submitMestoForm}
      initialState={DEP1_MESTO_INITIAL_STATE}
      dataToChange="mesto_form"
    />
  );
});
