import { ContentProps } from 'typescript/NrizTypes';
import styles from './Content.module.scss';
import { BackButton } from 'components/Buttons/BackButton/BackButton';

export const Content: React.FC<ContentProps> = props => {
  return (
    <div className={styles.contentWrapper}>
      {props.backBtn && (
        <div className={styles.btn}>
          <BackButton name={props.backBtn.name} route={props.backBtn.route} />
        </div>
      )}
      {props.header && <div className={styles.header}>{props.header}</div>}
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};
