import { observer } from 'mobx-react-lite';
import React from 'react';
import { GorivaModal } from './components/GorivaModal/GorivaModal';
import { ProizvodiObrazac1Modal } from './components/ProizvodiObrazac1Modal/ProizvodiObrazac1Modal';
import { SirovineModal } from './components/SastojciModal/SirovineModal';

export const Obrazac1MicroServices: React.FC = observer(() => {
  return (
    <>
      <GorivaModal />
      <ProizvodiObrazac1Modal />
      <SirovineModal />
    </>
  );
});
