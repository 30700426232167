import { observer } from 'mobx-react-lite';
import React from 'react';
import { Gio3KolicineModal } from './components/Gio3KolicineModal/Gio3KolicineModal';
import { Gio3RazrediEEModal } from './components/Gio3RazrediEEModal/Gio3RazrediEEModal';
import { Gio3PostrojenjaModal } from './components/Gio3PostrojenjaModal/Gio3PostrojenjaModal';
import { Gio3DozvoleModal } from './components/Gio3DozvoleModal/Gio3DozvoleModal';

export const Gio3MicroServices: React.FC = observer(() => {
  return (
    <>
      <Gio3KolicineModal />
      <Gio3RazrediEEModal />
      <Gio3PostrojenjaModal />
      <Gio3DozvoleModal />
    </>
  );
});
