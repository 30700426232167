import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { MORE_THAN_OR_EQUAL_0_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrazac4Store } from 'modules/obrasci/store/obrazac4/obrazac4.store';
import { IEmisijeUTlo } from 'modules/obrasci/store/obrazac4/obrazac4.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const NewEmisijeUTloForm: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();

  const form = props.form;

  const defaultPollutantCodeOption: ISifarnikInitial | undefined = obrazac4Store.emisija.pollutant_code_id
    ? {
        label: (obrazac4Store.emisija as IEmisijeUTlo).pollutant_code.name_sr,
        value: obrazac4Store.emisija.pollutant_code_id,
      }
    : undefined;

  const defaultMethodTypeOption: ISifarnikInitial | undefined = obrazac4Store.emisija.method_types_id
    ? {
        label: (obrazac4Store.emisija as IEmisijeUTlo).method_types?.name_sr,
        value: obrazac4Store.emisija.method_types_id,
      }
    : undefined;

  const defaultMethodBasisCodeOption: ISifarnikInitial | undefined = useMemo(() => {
    return obrazac4Store.emisija.method_basis_code_id
      ? {
          label: (obrazac4Store.emisija as IEmisijeUTlo).method_basis_code?.name_sr,
          value: obrazac4Store.emisija.method_basis_code_id,
        }
      : undefined;
  }, [obrazac4Store.emisija]);

  return (
    <>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={`${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.WASTE_NAME')}`}
        labelCol={{ span: 10 }}
        formName={'pollutant_code_id'}
        inputName={'pollutant_code_id'}
        labelOptionAccessor={'name_sr'}
        valueOptionAccessor={'id'}
        defaultOption={defaultPollutantCodeOption}
        fetchOptinsList={obrazac4Store.fetchPollutantOptions}
        placeholder={'Izaberite zagađujuću materiju'}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                obrazac4Store.emisija.pollutant_code_id !== value &&
                obrazac4Store.initialState.emisije_u_tlo.some(item => {
                  return value === item.pollutant_code_id;
                })
              ) {
                return Promise.reject(new Error('Tip zagađujuće materije već postoji u listi'));
              }
              return Promise.resolve();
            },
          },
        ]}
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.CONCENTRATION')}`}
        labelCol={{ span: 10 }}
        name={'koncetracija_zagadjujuce_materije_u_otpadu'}
        rules={[REQUIRED_FIELD_RULE(true), MORE_THAN_OR_EQUAL_0_RULE]}
        inputName={'koncetracija_zagadjujuce_materije_u_otpadu'}
        placeholder="Unesite koncentraciju zagađujuće materije"
      />
      <FormInputNumber
        label={`${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.QUANTITY')}`}
        labelCol={{ span: 10 }}
        name={'kolicina_zagadjujuce_materije_u_odlozenom_otpadu'}
        rules={[REQUIRED_FIELD_RULE(true), MORE_THAN_OR_EQUAL_0_RULE]}
        inputName={'kolicina_zagadjujuce_materije_u_odlozenom_otpadu'}
        placeholder="Unesite količinu zagađujuće materije"
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        label={`${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.WAY')}`}
        labelCol={{ span: 10 }}
        formName={'method_basis_code_id'}
        inputName={'method_basis_code_id'}
        labelOptionAccessor={'name_sr'}
        valueOptionAccessor={'id'}
        rules={[REQUIRED_FIELD_RULE(true)]}
        defaultOption={defaultMethodBasisCodeOption}
        fetchOptinsList={obrazac4Store.fetchMethodBasisCode}
        placeholder={'Izaberite metod utvrđivanja'}
        onChange={value => {
          form.setFieldValue('method_types_id', '');
          obrazac4Store.handleChange('filterNacinOdredjivanjaId', value);
        }}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        labelCol={{ span: 10 }}
        label={`${t('OBRASCI.OBRAZAC_4.SOIL_EMISSION.METHOD')}`}
        formName={'method_types_id'}
        inputName={'method_types_id'}
        labelOptionAccessor={'name_sr'}
        valueOptionAccessor={'id'}
        defaultOption={defaultMethodTypeOption}
        rules={[REQUIRED_FIELD_RULE(true)]}
        fetchOptinsList={obrazac4Store.fetchMethodTypes}
        placeholder={'Izaberite način određivanja'}
        disabled={!obrazac4Store.filterNacinOdredjivanjaId}
        resetOption={obrazac4Store.filterNacinOdredjivanjaId}
      />
    </>
  );
});
