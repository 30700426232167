import { Button, Form, Row } from 'antd';
import { FormTableModal } from './FormTableModal/FormTableModal';
import Table, { ColumnType } from 'antd/es/table';
import { useMemo, useState } from 'react';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { modalStore } from 'store';
import { EditOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import styles from './FormTable.module.scss';
import { FormListConfig } from 'typescript/NrizTypes';
import { nrizService } from 'modules/nriz/nriz.service';
import { localeEmpty } from 'modules/nriz/nriz.constants';

interface FormTableProps<T> {
  listName: string;
  tableColumns: ColumnType<T>[];
  title?: string;
  onEdit?: (record: T) => void;
  configPath: FormListConfig;
  modalWidth?: string | number;
  scrollY?: string;
}

export const FormTable = observer(
  // eslint-disable-next-line
  <T extends object>({
    listName,
    tableColumns,
    title,
    onEdit,
    configPath,
    modalWidth = '50%',
    scrollY = '300px',
  }: FormTableProps<T>) => {
    const form = Form.useFormInstance();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const tableDataSource: T[] = useMemo(() => {
      const sourceList = nrizService.addKey(form.getFieldValue(listName)) ?? [];
      setSelectedIndex(sourceList.length);
      return sourceList;
    }, [Form.useWatch(listName), form]);

    const handleAddNewRecord = () => {
      setSelectedIndex(tableDataSource.length);
      modalStore.changeModalName(`${listName}-modal`);
    };

    const handleEditRecord = (record: T, formListItemIndex: number) => {
      setSelectedIndex(formListItemIndex);
      onEdit && onEdit(record);
      modalStore.changeModalName(`${listName}-modal`);
    };

    const columns: ColumnType<T>[] = [
      ...tableColumns,
      {
        fixed: 'right',
        align: 'center',
        width: 70,

        render: (_: any, record: T, formListItemIndex: number) => {
          return (
            <Button
              icon={<EditOutlined rev={undefined} />}
              name={`edit-${listName}-item`}
              onClick={() => handleEditRecord(record, formListItemIndex)}
            />
          );
        },
      },
    ];

    const TableHeader = () => (
      <Row justify={'space-between'} id="formTableHeader">
        {title && <h3>{title}</h3>}

        <AddNewButton name={`${listName}-table-add-btn`} onButtonClick={handleAddNewRecord} />
      </Row>
    );

    return (
      <div className={styles.formListWrapper}>
        <Form.List name={listName}>
          {(_items, { add, remove }) => {
            return tableDataSource ? (
              <>
                <FormTableModal<T>
                  name={listName}
                  removeRow={remove}
                  addRow={add}
                  configPath={configPath}
                  selectedIndex={selectedIndex}
                  modalWidth={modalWidth}
                  title={title}
                />
                <TableHeader />
                <Table
                  pagination={false}
                  bordered
                  dataSource={tableDataSource}
                  columns={columns}
                  className={styles.formTable}
                  scroll={{ y: scrollY }}
                  locale={localeEmpty}
                  // todo: resi jebeni scrollConfig
                />
              </>
            ) : null;
          }}
        </Form.List>
      </div>
    );
  }
);
