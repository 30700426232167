import { ColumnsType } from 'antd/es/table';
import { IGetDRList, IPostDRList } from './dRList.types';
import { FormInstance } from 'antd';
import { SIFARNICI_ROUTES, SearchScrollSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { DESCRIPTION_FORM, DESCRIPTION_SR_FORM, NAME_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { DESCRIPTION_SR_TABLE, DESCRIPTION_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';

export const DR_LIST_INITIAL_STATE: IPostDRList = {
  name: '',
  description: '',
  waste_treatment_id: '',
  description_sr: '',
  is_active: true,
};
export const DR_LIST_TABLE_DATA: ColumnsType<IGetDRList> = [
  { title: 'Tretman otpada', width: '200px', dataIndex: ['waste_treatment_code', 'name_sr'], key: 'name' },
  { ...NAME_TABLE, width: '100px' },
  DESCRIPTION_TABLE,
  DESCRIPTION_SR_TABLE,
];
export const DR_LIST_FORM_OPTIONS = (_: FormInstance<IGetDRList>) => {
  return [
    NAME_FORM,
    {
      type_of_form: 'searchScroll',
      // todo: prevod,
      label: 'Tretman otpada',
      // placeholder={t('SIFARNICI.PROIZVOD')}
      formName: 'waste_treatment_id',
      fetchOptinsList: SIFARNICI_ROUTES.WASTE_TREATMENT_CODE,
      filtersForFetch: {},
      labelOptionAccessor: ['name_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      objName: 'waste_treatment_code',
      hideInput: true,
    } as SearchScrollSifarniciFormProps,
    DESCRIPTION_FORM,
    DESCRIPTION_SR_FORM,
  ];
};
