import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { RAZREDI_EE_OTPADA_INITIAL_STATE } from 'modules/obrasci/store/gio3/gio3.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewGio2RazrediEEForm } from './NewGio2RazrediEEForm/NewGio2RazrediEEForm';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';

export const Gio2RazrediEEModal: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <CRUDModal
      width="40%"
      name="gio2-razredi-ee-modal"
      title={t('OBRASCI.GIO_3.CLASS_EE_WASTE')}
      store={gio2ObrazacStore}
      data={gio2ObrazacStore.getRazrediForm}
      children={() => <NewGio2RazrediEEForm />}
      submitForm={gio2ObrazacStore.submitRazredEeOtpada}
      initialState={RAZREDI_EE_OTPADA_INITIAL_STATE}
      dataToChange={'razredi_ee_form'}
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
      closeOneModal={true}
    />
  );
});
