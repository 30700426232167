import to from 'await-to-js';
import { observer } from 'mobx-react-lite';
import { NewCompanyTipoviObrazaca } from 'components/NewForms/NewCompanyTipoviObrazaca/NewCompanyTipoviObrazaca';
import { ICompanyTipoviObrasca } from 'modules/company/company.types';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { facilityStore } from 'modules/facility/facility.store';
import { useEffect } from 'react';
import { IGetFacility } from 'modules/facility/facility.types';

export const FacilityTipoviObrazaca: React.FC = observer(() => {
  useEffect(() => {
    facilityStore.fetchFacilityTipoviObrasca((facilityStore.facility as IGetFacility).id);
  }, []);

  const handleSubmit = async (selectedTipovi: ICompanyTipoviObrasca) => {
    const [err] = await to(
      facilityStore.postFacilityTipoviObrasca(
        obrasciService.getTipoviNames(selectedTipovi.tipovi_obrazaca, obrasciStore.obrasciPoNivou.postrojenje)
      )
    );
    if (err) return;
  };

  return (
    <NewCompanyTipoviObrazaca
      isActive={facilityStore.getFacility.is_active}
      handleSubmit={handleSubmit}
      selectedObrasciId={facilityStore.getFacilityTipoviObrazacaIds}
      selectedObrasci={obrasciStore.selectedObrasci(facilityStore.getFacilityTipoviObrazacaIds)}
      tipoviObrazaca={obrasciStore.obrasciPoNivou.postrojenje}
      initialTipoviIds={facilityStore.initial_tipovi_ids}
    />
  );
});
