import to from 'await-to-js';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { nrizService } from 'modules/nriz/nriz.service';
import { facilityStore } from 'modules/facility/facility.store';
import { formStore } from 'store/FormStore';
import { modalStore } from 'store/ModalStore';
import { IPagination } from 'typescript';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetSoil, IPostSoil } from 'modules/sourcesOfPollution/soil/soil.types';
import { handleSuccessNotify } from 'utils/succesHandling';
import { soilInitialState } from './soil.constants';
import { soilService } from './soil.service';

class SoilStore {
  constructor() {
    makeAutoObservable(this, {
      soil: observable,
      soilList: observable,
      soilFormData: observable,

      getSoilFormData: computed,
      getSoil: computed,
      getSoilList: computed,

      postSoil: action,
      putSoil: action,
      deleteSoil: action,
      setSoilFormData: action,
    });
  }
  soil: IPostSoil | IGetSoil = soilInitialState;
  soilList: IGetSoil[] = [];
  soilFormData = false;

  get getSoil() {
    return this.soil;
  }

  get getSoilList() {
    return this.soilList;
  }

  get getSoilFormData() {
    return this.soilFormData;
  }

  setSoil(soil: IPostSoil | IGetSoil) {
    this.soil = soil;
  }

  handleChange = (type: string, value: any) => {
    (this as any)[type] = value;
  };

  setSoilList = (soil: IGetSoil[]) => {
    this.soilList = [...soil];
  };

  fetchSoilList = async (pagination: IPagination) => {
    const [err, res] = await to(
      soilService.fetchSoilList({
        page: pagination.page,
        size: pagination.size,
        postrojenje_id: (facilityStore.facility as IGetFacility).id,
      })
    );

    if (err || !res) return;
    this.setSoilList(res.items);
    return res;
  };

  postSoil = async (soil: IPostSoil) => {
    const newSoil = {
      ...soil,
      aktivan: this.soil.aktivan,
      postrojenje_id: (facilityStore.getFacility as IGetFacility).id,
    };
    const [err, res] = await to(soilService.postSoil(nrizService.setEmptyValuesToNull(newSoil)));
    if (err || !res) return;
    const newList = [{ ...newSoil, id: res.id, aktivan: true }, ...this.soilList] as IGetSoil[];
    this.setSoilList(newList);
    handleSuccessNotify();
    modalStore.removeAllModals();
  };

  putSoil = async (soil: IGetSoil) => {
    const newSoil = { ...soil, aktivan: this.soil.aktivan };
    const [err, res] = await to(soilService.putSoil(nrizService.setEmptyValuesToNull(newSoil)));
    if (err || !res) return;
    this.setSoil(soilInitialState);
    handleSuccessNotify();
    this.setSoilList(nrizService.changeItemInList(this.soilList, newSoil));
    modalStore.removeAllModals();
  };

  deleteSoil = async (id: string) => {
    const [err, res] = await to(soilService.deleteSoil(id));
    if (err || !res) return;
    this.setSoil(soilInitialState);
    this.setSoilList(nrizService.removeFromList(this.soilList, id));
    handleSuccessNotify();
    modalStore.removeAllModals();
  };

  setSoilFormData = (value: boolean) => {
    this.soilFormData = value;
  };

  submitSoil = (soil: IPostSoil) => {
    if (formStore.getCrudButtonType === 'submit') {
      return this.postSoil(soil);
    }
    const newObject = { ...this.soil, ...soil } as IGetSoil;
    if (formStore.getCrudButtonType === 'change') {
      return this.putSoil(newObject);
    }
    if (formStore.getCrudButtonType === 'delete') {
      if (newObject.id) return this.deleteSoil(newObject.id);
    }
  };

  aktivirajTlo = async () => {
    const [err, res] = await to(soilService.aktivirajTlo((this.soil as IGetSoil).id));
    if (err || !res) return;
    const newObj = { ...this.soil, aktivan: true } as IGetSoil;
    this.setSoil(newObj);
    nrizService.changeItemInList(this.soilList, newObj);
    handleSuccessNotify();
  };

  deaktivirajTlo = async () => {
    const [err, res] = await to(soilService.deaktivirajTlo((this.soil as IGetSoil).id));
    if (err || !res) return;
    const newObj = { ...this.soil, aktivan: false } as IGetSoil;

    this.setSoil(newObj);
    nrizService.changeItemInList(this.soilList, newObj);
    handleSuccessNotify();
  };
}

export const soilStore = new SoilStore();
