import React from 'react';
import { Layout, Menu, MenuProps } from 'antd';
import styles from './SidebarAnt.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { UnsanitaryLandfillModal } from 'components/Modals';
import { UserModal } from 'components/Modals/UserModal/UserModal';
import { FacilityModal } from 'components/Modals/FacilityModal/FacilityModal';
import { WaterModal } from 'components/Modals/WaterModal/WaterModal';
import { SoilModal } from 'components/Modals/SoilModal/SoilModal';
import { AirModal } from 'components/Modals/AirModal/AirModal';
import { Logo } from 'components/Logo/Logo';
import { SifarniciCrudModal } from 'pages/Sifarnici/components/SifarniciCrudModal/SifarniciCrudModal';
import { LogOutButton } from 'components/LogOutButton/LogOutButton';
import { IRouteConfig, routes } from 'router/router.constants';
import { flattenChildren } from 'utils/flattenChildren';
import { authStore } from 'store';
import { companyStore } from 'modules/company/company.store';
import { IGetCompany } from 'modules/company/company.types';

const { Sider } = Layout;

export interface ISidebar {
  key: string;
  label: string;
  icon: React.ReactNode;
  path: string;
  subpath?: string[];
  children?: ISidebar[];
}

const SidebarAnt: React.FC = observer(() => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const currentRole = authStore.getUserRole;

  if (!currentRole) return null;

  const getMenuPath = (route: IRouteConfig) => {
    switch (route.key) {
      case 'user':
        return `/user/${authStore.userId}`;
      case 'company':
        return `/company/${(companyStore.company as IGetCompany)?.id}`;
      default:
        return route.path;
    }
  };

  const generateSidebarItems = (routes: IRouteConfig[]): ISidebar[] => {
    return routes.reduce((menuItems: ISidebar[], route) => {
      if (route.menuProps && route.menuProps.roles.includes(currentRole)) {
        const { key } = route;
        const children = route.children ? generateSidebarItems(route.children) : undefined;
        const { label, icon, subpath } = route.menuProps;
        const newMenuItem = {
          key,
          path: getMenuPath(route),
          children,
          label,
          icon,
          subpath,
        };
        return [...menuItems, newMenuItem];
      }
      return menuItems;
    }, []);
  };

  const menuItems = generateSidebarItems(routes);

  const flatMenuItems = flattenChildren(menuItems);

  const findSelectedKey = () => {
    return flatMenuItems.reduce((selectedKeys: string[], item) => {
      if (pathname.includes(item.path) || item.subpath?.some(path => pathname.includes(path)))
        return [...selectedKeys, item.key];
      return selectedKeys;
    }, []);
  };

  const getOpenSubmenu = () => {
    const openSubmenuKeys = menuItems.filter(route => {
      return route.children && pathname.includes(route.path);
    });
    return openSubmenuKeys.length ? [openSubmenuKeys[0].key.toString()] : [];
  };

  const handleMenuClick: MenuProps['onClick'] = e => {
    const menuItem = flatMenuItems.find(sidebarItem => e.key === sidebarItem.key);
    console.log(menuItem?.path);
    if (menuItem) navigate(menuItem.path);
  };

  return (
    <div>
      {/* TODO: prebaciti modale u posebnu komponentu */}
      {/* <CompanyModal /> */}
      <UserModal />
      <FacilityModal />
      <WaterModal />
      <SoilModal />
      <AirModal />
      <SifarniciCrudModal />
      <UnsanitaryLandfillModal />

      <Layout>
        <Sider theme="dark" width={250}>
          <div>
            <Logo />
            <Menu
              theme="dark"
              mode="inline"
              defaultOpenKeys={getOpenSubmenu()}
              selectedKeys={findSelectedKey()}
              onClick={handleMenuClick}
              items={menuItems}
              className={styles.items}
            />
          </div>
          <LogOutButton />
        </Sider>
      </Layout>
    </div>
  );
});

export default SidebarAnt;
