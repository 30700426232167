import { IGetLaboratoriesAirWater, IPostLaboratoriesAirWater } from './laboratoriesAirWater.types';
import { t } from 'i18next';
import { Checkbox, FormInstance } from 'antd';
import {
  MAX_LENGTH_RULE,
  PIB_FIELD_RULE,
  REQUIRED_FIELD_RULE,
  invalidCharactersForInputNumber,
} from 'modules/nriz/nriz.constants';
import {
  DateSifarniciFormProps,
  InputSifarniciFormProps,
  SwitchSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { disabledTodayAndFutureDates } from 'utils/formatDate';
import dayjs from 'dayjs';
export const LABORATORIES_AIR_WATER_INITIAL_STATE: IPostLaboratoriesAirWater = {
  name_of_laboratory: '',
  tax_identification_number: '',
  address: '',
  place: '',
  permit_number: '',
  date_of_permit_issue: '',
  air: false,
  water: false,
  is_active: true,
};

export const LABORATORIES_AIR_WATER_TABLE_DATA = [
  {
    title: `${t('SIFARNICI.NAZIV_STRUCNE_LABORATORIJE')}`,
    width: '300px',
    dataIndex: 'name_of_laboratory',
    key: 'name_of_laboratory',
  },
  {
    title: `${t('COMPANY.PIB')}`,
    width: '200px',
    dataIndex: 'tax_identification_number',
    key: 'tax_identification_number',
  },
  {
    title: `${t('ADDRESS')}`,
    width: '100px',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: `${t('COMPANY.LOCATION')}`,
    width: '100px',
    dataIndex: 'place',
    key: 'place',
  },
  {
    title: `${t('OBRASCI.DKO.BROJ_DOZVOLE')}`,
    width: 150,
    dataIndex: 'permit_number',
    key: 'permit_number',
  },
  {
    title: `${t('OBRASCI.DKO.DATUM_IZDAVANJA_DOZVOLE')}`,
    width: 150,
    dataIndex: 'date_of_permit_issue',
    key: 'date_of_permit_issue',
    render: (_: any, record: IGetLaboratoriesAirWater) => {
      return (
        <span>{record?.date_of_permit_issue ? dayjs(record?.date_of_permit_issue).format('DD. MM. YYYY.') : ''}</span>
      );
    },
    align: 'center',
  },
  {
    title: `${t('SIFARNICI.AIR')}`,
    width: '100px',
    dataIndex: 'air',
    key: 'air',
    render: (_: any, record: IGetLaboratoriesAirWater) => <Checkbox checked={!!record.air} disabled={true} />,
    align: 'center',
  },
  {
    title: `${t('SIFARNICI.WATER')}`,
    width: '100px',
    dataIndex: 'water',
    key: 'water',
    render: (_: any, record: IGetLaboratoriesAirWater) => <Checkbox checked={!!record.water} disabled={true} />,
  },
];

export const LABORATORIES_AIR_WATER_FORM_OPTIONS = (_: FormInstance<IGetLaboratoriesAirWater>) => {
  return [
    {
      type_of_form: 'input',
      label: t('SIFARNICI.NAZIV_STRUCNE_LABORATORIJE'),
      name: 'name_of_laboratory',
      inputName: 'name_of_laboratory',
      rules: [REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(500)],
      type: 'text',
      sortName: 'name_of_laboratory',
    } as InputSifarniciFormProps,

    {
      type_of_form: 'input',
      name: 'tax_identification_number',
      sortName: 'tax_identification_number',
      rules: [PIB_FIELD_RULE],
      invalidCharacters: invalidCharactersForInputNumber,
      label: t('COMPANY.PIB'),
      inputName: 'tax_identification_number',
      precision: 0,
      type: 'number',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'address',
      sortName: 'address',
      rules: [MAX_LENGTH_RULE(50)],
      // placeholder: t('ADDRESS'),
      label: t('ADDRESS'),
      inputName: 'address',
      type: 'text',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'place',
      sortName: 'place',
      rules: [MAX_LENGTH_RULE(50)],
      // placeholder: t('COMPANY.LOCATION'),
      label: t('COMPANY.LOCATION'),
      inputName: 'place',
      type: 'text',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      sortName: 'permit_number',
      rules: [REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(150)],
      label: t('OBRASCI.DKO.BROJ_DOZVOLE'),
      inputName: 'permit_number',
      name: 'permit_number',
    } as InputSifarniciFormProps,

    {
      type_of_form: 'date',
      name: 'date_of_permit_issue',
      sortName: 'date_of_permit_issue',
      label: t('OBRASCI.DKO.DATUM_IZDAVANJA_DOZVOLE'),
      rules: [REQUIRED_FIELD_RULE(true)],
      format: 'DD-MM-YYYY',
      picker: 'date',
      disabledDate: disabledTodayAndFutureDates,
    } as DateSifarniciFormProps,
    {
      type_of_form: 'switch',
      name: 'air',
      // sortName: 'air',
      label: t('SIFARNICI.AIR'),
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'switch',
      name: 'water',
      // sortName: 'water',
      label: t('SIFARNICI.WATER'),
    } as SwitchSifarniciFormProps,
  ];
};
