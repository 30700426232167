import { RequestActivityList } from 'modules/activityList1/activityList1.types';
import { activity2Repo } from './activityList2.repo';
import { IPostActivity2, IGetActivity2 } from './activityList2.types';

class Activity2Service {
  fetchActivityList2 = (payload: RequestActivityList) => {
    return activity2Repo.fetchActivityList2(payload);
  };

  postActivity2 = (payload: IPostActivity2) => {
    return activity2Repo.postActivity2(payload);
  };

  deleteActivity2 = (id: string) => {
    return activity2Repo.deleteActivity2(id);
  };

  putActivity2 = (activity: IGetActivity2) => {
    return activity2Repo.putActivity2(activity);
  };
}

export const activity2Service = new Activity2Service();
