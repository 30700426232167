class GveService {
  handleGodisnjaEmisijaInputChange = (
    prolece: number | null | '',
    leto: number | null | '',
    jesen: number | null | '',
    zima: number | null | ''
  ) => {
    if (!prolece && prolece !== 0 && !leto && leto !== 0 && !jesen && jesen !== 0 && !zima && zima !== 0) return true;
    const ukupno = Number(prolece) + Number(leto) + Number(jesen) + Number(zima);
    if (ukupno === 100) {
      return true;
    }
    return false;
  };
}

export const gveService = new GveService();
