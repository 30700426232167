import { IDivljeDeponije, IFiltersDep2, IPostDEP2Obrazac } from './dep2.types';

export const DIVLJA_DEPONIJA_INITIAL_STATE: IDivljeDeponije = {
  mesto_id: '',
  koordinate_long: null,
  koordinate_lat: null,
  procenjena_kolicina_otpada: null,
  procenjena_povrsina_smetlista: null,
  broj_ciscenja_prostora_u_okviru_godine: null,
  dep_forms_answers_id: '',
};

export const DEP2_INITIAL_STATE: IPostDEP2Obrazac = {
  godina: '',
  preduzece_id: '',
  divlje_deponije: [],
  napomena: '',
};

export const filtersDep2InitialState: IFiltersDep2 = {
  godina: '',
  preduzece_id: '',
};
