import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { IGetKolicineGio2, IGetRazrediEeOtpadaGio2 } from 'modules/obrasci/store/gio2/gio2.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const TableGio2RazrediEe: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetRazrediEeOtpadaGio2> = [
    {
      title: `${t('OBRASCI.GIO_3.CLASS_EE_WASTE')}`,
      width: 100,
      key: 'category_name',
      render: (_: any, record: IGetRazrediEeOtpadaGio2) => (
        <>{`${record.razred_ee_otpada.the_order} ${record.razred_ee_otpada.category_name_sr}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.GIO_3.QUANTITY_T')}`,
      width: 100,
      dataIndex: 'kolicina',
      key: 'kolicina',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record: IGetRazrediEeOtpadaGio2) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-gio2-razredi-ee'}
            onClick={() => {
              gio2ObrazacStore.handleChange('razredi_ee_form', record);
              modalStore.changeModalName('gio2-razredi-ee-modal');
            }}
          />
        );
      },
    },
  ];

  return (
    <TableData
      name="razredi-gio2"
      columns={baseColumns}
      dataSource={[...(gio2ObrazacStore.kolicine_form as IGetKolicineGio2).razredi_ee_otpada]}
    />
  );
});
