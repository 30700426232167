import { makeAutoObservable, observable, action, computed } from 'mobx';

class ModalStore {
  modalName: string[] = [];
  isSearchModalVisible = true;
  isLoading = false;
  closeOneModal = false;

  constructor() {
    makeAutoObservable(this, {
      modalName: observable,
      isSearchModalVisible: observable,
      isLoading: observable,

      changeModalName: action,
      startLoader: action,
      endLoader: action,

      getCurrentOpenedModal: computed,
      getIsModalVisible: computed,
      getIsLoading: computed,
    });
  }

  get getCurrentOpenedModal() {
    return this.modalName;
  }

  get getIsModalVisible() {
    return this.isSearchModalVisible;
  }

  get getIsLoading() {
    return this.isLoading;
  }

  changeModalName = (name: string) => {
    this.modalName.push(name);
  };

  toggleModalVisible = () => {
    this.isSearchModalVisible = !this.isSearchModalVisible;
  };

  clearModal = (name: string) => {
    this.modalName = this.modalName.filter(item => item !== name);
  };

  removeAllModals = () => {
    this.modalName = [];
  };

  startLoader = () => {
    this.isLoading = true;
  };

  endLoader = () => {
    this.isLoading = false;
  };
}

export const modalStore = new ModalStore();
