import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Switch } from 'components/Switch/Switch';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

interface LockSwitchProps {
  switchName: string;
  initialLocked: boolean;
  onSwitch: (isLocked: boolean) => void;
}

export const LockSwitch: React.FC<LockSwitchProps> = observer(({ switchName, initialLocked, onSwitch }) => {
  const [isLocked, setIsLocked] = useState<boolean>(initialLocked);

  const onSwitchClick = () => {
    onSwitch(!isLocked);
    setIsLocked(isLocked => !isLocked);
  };

  useEffect(() => {
    setIsLocked(initialLocked);
  }, [initialLocked]);

  return (
    <Switch
      name={`${switchName}-lock-switch`}
      unCheckedChildren={<UnlockOutlined rev={undefined} />}
      checkedChildren={<LockOutlined rev={undefined} />}
      checked={isLocked}
      onClick={onSwitchClick}
    />
  );
});
