import { t } from 'i18next';
import { IPostPzvProducts, IGetPzvProducts } from './pzvProducts.types';
import { Checkbox, FormInstance } from 'antd';
import { MAX_LENGTH_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import {
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  InputSifarniciFormProps,
  SwitchSifarniciFormProps,
  TextAreaSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { DESCRIPTION_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { DESCRIPTION_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';

export const PZV_PRODUCTS_INITIAL_STATE: IPostPzvProducts = {
  pzv_customs_and_producers_id: '',
  product_id: '',
  net_mass: null,
  description: '',
  exempted: false,
  remarks: '',
  is_active: true,
};

export const PZV_PRODUCTS_TABLE_DATA = [
  {
    title: t('SIFARNICI.CARINA_PROIZVODJAC'),
    width: '200px',
    dataIndex: ['pzv_customs_and_producers', 'company_name'],
    key: 'company_name',
  },
  {
    title: t('SIFARNICI.PROIZVOD'),
    width: '200px',
    dataIndex: ['product_type', 'name'],
    key: 'product_type',
  },
  {
    title: `${t('SIFARNICI.NETO_MASA')}`,
    width: '150px',
    dataIndex: 'net_mass',
    key: 'net_mass',
  },
  DESCRIPTION_TABLE,
  {
    title: `${t('SIFARNICI.OSLOBODJENI')}`,
    width: '150px',
    dataIndex: 'exempted',
    align: 'center',
    render: (_: any, record: IGetPzvProducts) => <Checkbox checked={record.exempted} disabled={true} />,
    key: 'exempted',
  },
  {
    title: `${t('WIZARD_TABS.NAPOMENA')}`,
    width: '200px',
    dataIndex: 'remarks',
    key: 'remarks',
  },
];

export const PZV_PRODUCTS_FORM_OPTIONS = (form: FormInstance<IGetPzvProducts>) => {
  form;
  return [
    {
      type_of_form: 'searchScroll',
      label: t('SIFARNICI.CARINA_PROIZVODJAC'),
      formName: 'pzv_customs_and_producers_id',
      fetchOptinsList: SIFARNICI_ROUTES.PZV_CUSTOMS_AND_PRODUCERS,
      filtersForFetch: {},
      labelOptionAccessor: ['company_name'],
      valueOptionAccessor: 'id',
      // todo: proveriti
      // disabledAccessor: 'is_active',
      rules: [REQUIRED_FIELD_RULE(true)],
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: t('SIFARNICI.PROIZVOD'),
      formName: 'product_id',
      fetchOptinsList: SIFARNICI_ROUTES.PZV_TYPE_OF_PRODUCTS,
      filtersForFetch: {},
      labelOptionAccessor: ['name'],
      valueOptionAccessor: 'id',
      objName: 'product_type',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input',
      label: t('SIFARNICI.NETO_MASA'),
      name: 'net_mass',
      inputName: 'net_mass',
      // placeholder={t('COMPANY.PLACEHOLDER.ENTER_SHORT_NAME')}
    } as InputSifarniciFormProps,

    DESCRIPTION_FORM,

    {
      type_of_form: 'switch',
      name: 'exempted',
      label: t('SIFARNICI.OSLOBODJENI'),
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'area',
      label: t('WIZARD_TABS.NAPOMENA'),
      name: 'remarks',
      rules: [MAX_LENGTH_RULE(3000)],
      rows: 4,
      labelCol: 10,
      wrapperCol: 14,
      // placeholder={t('COMPANY.PLACEHOLDER.ENTER_SHORT_NAME')}
    } as TextAreaSifarniciFormProps,
  ];
};
