import { soilRepo } from './soil.repo';
import { IGetSoil, IPostSoil, RequestSoilList } from './soil.types';

class SoilService {
  fetchSoilList = (payload: RequestSoilList) => {
    return soilRepo.fetchSoilList(payload);
  };

  postSoil = (payload: IPostSoil) => {
    return soilRepo.postSoil(payload);
  };

  putSoil = (payload: IGetSoil) => {
    return soilRepo.putSoil(payload);
  };

  deleteSoil = (id: string) => {
    return soilRepo.deleteSoil(id);
  };

  aktivirajTlo = (id: string) => {
    return soilRepo.aktivirajTlo(id);
  };
  deaktivirajTlo = (id: string) => {
    return soilRepo.deaktivirajTlo(id);
  };
}

export const soilService = new SoilService();
