import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { SIROVINE_INITIAL_STATE } from 'modules/obrasci/store/obrazac1/obrazac1.constants';
import { obrazac1Store } from 'modules/obrasci/store/obrazac1/obrazac1.store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NewSirovineForm } from './components/NewSirovineForm/NewSirovineForm';

export const SirovineModal: React.FC = observer(() => {
  const { t } = useTranslation();
  //   todo: prevod
  return (
    <CRUDModal
      width="70%"
      name="sirovine-modal"
      title={t('OBRASCI.OBRAZAC_1.RAW_MATERIALS')}
      store={obrazac1Store}
      data={obrazac1Store.getSirovineForm}
      children={() => <NewSirovineForm />}
      submitForm={obrazac1Store.submitSirovineForm}
      initialState={SIROVINE_INITIAL_STATE}
      dataToChange="sirovine_form"
    />
  );
});
