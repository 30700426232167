import { ColumnsType } from 'antd/es/table';
import { IGetWaterBodies, IPostWaterBodies } from './waterBodies.types';
import { FormInstance } from 'antd';
import { THE_ORDER_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { THE_ORDER_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import {
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { waterBodiesService } from './waterBodies.service';

export const WATER_BODIES_INITIAL_STATE: IPostWaterBodies = {
  the_order: null,
  water_body_code: '',
  eu_water_body_code: '',
  water_body_name: '',
  length_of_water_body_km: null,
  water_body_code_and_name: '',
  is_active: true,
  water_course_name_id: '',
  water_body_category_id: '',
  water_area_id: '',
};
export const WATER_BODIES_TABLE_DATA: ColumnsType<IGetWaterBodies> = [
  THE_ORDER_TABLE,
  {
    title: 'Šifra vodnog tela',
    // align: 'center',
    width: '200px',
    dataIndex: 'water_body_code',
    key: 'water_body_code',
  },
  {
    title: 'Šifra EU vodnog tela',
    // align: 'center',
    width: '200px',
    dataIndex: 'eu_water_body_code',
    key: 'eu_water_body_code',
  },
  {
    title: 'Naziv vodnog tela',
    // align: 'center',
    width: '200px',
    dataIndex: 'water_body_name',
    key: 'water_body_name',
  },
  {
    title: 'Dužina vodnog tela (km)',
    // align: 'center',
    width: '200px',
    dataIndex: 'length_of_water_body_km',
    key: 'length_of_water_body_km',
  },
  {
    title: 'Šifra i ime vodnog tela',
    // align: 'center',
    width: '200px',
    dataIndex: 'water_body_code_and_name',
    key: 'water_body_code_and_name',
  },
  {
    title: 'Naziv vodotoka',
    // align: 'center',
    width: '200px',
    dataIndex: ['water_course_name', 'water_course_name'],
    key: 'water_course_name',
  },
  {
    title: 'Kategorija vodnog tela',
    // align: 'center',
    width: '200px',
    dataIndex: ['water_body_category', 'category'],
    key: 'water_body_category',
  },
  {
    title: 'Područje vodnog tela',
    // align: 'center',
    width: '200px',
    dataIndex: ['water_area', 'water_area_name'],
    key: 'water_area',
  },
];
export const WATER_BODIES_FORM_OPTIONS = (form: FormInstance<IGetWaterBodies>) => {
  return [
    THE_ORDER_FORM,
    {
      type_of_form: 'input',
      name: 'water_body_code',
      // todo: prevod
      label: 'Šifra vodnog tela',
      inputName: 'water_body_code',
      onInputChange: () => {
        waterBodiesService.makeCodeAndName(form);
      },
      rules: [REQUIRED_FIELD_RULE(true)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'eu_water_body_code',
      // todo: prevod
      label: 'Šifra EU vodnog tela',
      inputName: 'eu_water_body_code',
      rules: [REQUIRED_FIELD_RULE(true)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'water_body_name',
      // todo: prevod
      label: 'Naziv vodnog tela',
      inputName: 'water_body_name',
      rules: [REQUIRED_FIELD_RULE(true)],
      onInputChange: () => {
        waterBodiesService.makeCodeAndName(form);
      },
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input_number',
      name: 'length_of_water_body_km',
      // todo: prevod
      label: 'Dužina vodnog tela (km)',
      inputName: 'length_of_water_body_km',
      precision: 2,
      rules: MORE_THEN_0,
    } as InputSifarniciFormProps,

    {
      type_of_form: 'searchScroll',
      label: 'Naziv vodotoka',
      formName: 'water_course_name_id',
      sortName: 'water_course_name_id',
      fetchOptinsList: SIFARNICI_ROUTES.WATER_COURSE,
      filtersForFetch: {},
      labelOptionAccessor: ['water_course_name'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: 'Kategorija vodnog tela',
      formName: 'water_body_category_id',
      sortName: 'water_body_category_id',
      fetchOptinsList: SIFARNICI_ROUTES.WATER_BODY_CATEGORY,
      filtersForFetch: {},
      labelOptionAccessor: ['category'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: 'Područje vodnog tela',
      formName: 'water_area_id',
      sortName: 'water_area_id',
      fetchOptinsList: SIFARNICI_ROUTES.WATER_AREA,
      filtersForFetch: {},
      labelOptionAccessor: ['water_area_name'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      //
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'water_body_code_and_name',
      // todo: prevod
      disabled: true,
      label: 'Šifra i naziv vodnog tela',
      inputName: 'water_body_code_and_name',
      hideInput: true,
    } as InputSifarniciFormProps,
  ];
};
