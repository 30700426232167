import { FormInstance } from 'antd';
import { IGetAktivnost1, IPostAktivnost1 } from './aktivnost1.types';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { ColumnsType } from 'antd/es/table';

import {
  NAME_TABLE,
  NAME_SR_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  CODE_2_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import {
  CODE_2_FORM,
  CODE_FORM,
  DESCRIPTION_FORM,
  NAME_FORM,
  NAME_SR_FORM,
  TITLE_FORM,
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
  VERSION_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';

export const AKTIVNOST_1_INITIAL_STATE: IPostAktivnost1 = {
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  name_sr: '',
  minimumthresholdofcapacity: '',
  minimumthresholdofcapacity_sr: '',
  ippccode: '',
  a_level: null,
  code2: '',
  is_active: true,
  proizvodi_id_jm: '',
  proizvodi_notacija_jm: '',
  proizvodi_jedinica_mere: '',
  proizvodi_jedinica_mere_sr: '',
  proizvodi_definicija_jm: '',
  proizvodi_definicija_jm_sr: '',
};

export const AKTIVNOST_1_TABLE_DATA: ColumnsType<IGetAktivnost1> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,

  // todo: prevod
  {
    title: 'Minimalna granična vrednost kapaciteta',
    width: '200px',
    dataIndex: 'minimumthresholdofcapacity',
    key: 'minimumthresholdofcapacity',
  },
  {
    title: 'Minimalna granična vrednost kapaciteta (srp.)',
    width: '200px',
    dataIndex: 'minimumthresholdofcapacity_sr',
    key: 'minimumthresholdofcapacity_sr',
  },
  {
    title: 'IPPC kod',
    width: '100px',
    dataIndex: 'ippccode',
    key: 'ippccode',
  },
  {
    title: 'Nivo a',
    width: '100px',
    dataIndex: 'a_level',
    key: 'a_level',
  },
  CODE_2_TABLE,
  {
    title: 'Proizvodi - ID JM',
    width: '150px',
    dataIndex: 'proizvodi_id_jm',
    key: 'proizvodi_id_jm',
  },
  {
    title: 'Proizvodi - Notacija JM',
    width: '150px',
    dataIndex: 'proizvodi_notacija_jm',
    key: 'proizvodi_notacija_jm',
  },
  {
    title: 'Proizvodi - jedinica mere',
    width: '150px',
    dataIndex: 'proizvodi_jedinica_mere',
    key: 'proizvodi_jedinica_mere',
  },
  {
    title: 'Proizvodi - jedinica mere srp.',
    width: '150px',
    dataIndex: 'proizvodi_jedinica_mere_sr',
    key: 'proizvodi_jedinica_mere_sr',
  },
  {
    title: 'Proizvodi - definicija JM',
    width: '150px',
    dataIndex: 'proizvodi_definicija_jm',
    key: 'proizvodi_definicija_jm',
  },
  {
    title: 'Proizvodi - definicija JM srp.',
    width: '150px',
    dataIndex: 'proizvodi_definicija_jm_srp',
    key: 'proizvodi_definicija_jm_srp',
  },
];

export const AKTIVNOST_1_FORM_OPTIONS = (form: FormInstance<IGetAktivnost1>) => {
  return [
    NAME_FORM,
    NAME_SR_FORM,
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM,
    DESCRIPTION_FORM,
    VERSION_FORM,
    { ...CODE_FORM },
    {
      type_of_form: 'input',
      name: 'minimumthresholdofcapacity',
      // todo: prevod
      label: 'Minimalna granična vrednost kapaciteta ',
      inputName: 'minimumthresholdofcapacity',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'minimumthresholdofcapacity_sr',
      // todo: prevod pla
      label: 'Minimalna granična vrednost kapaciteta (srp.)',
      inputName: 'minimumthresholdofcapacity_sr',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'ippccode',
      // todo: prevod
      label: 'IPPC kod',
      inputName: 'ippccode',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input_number',
      name: 'a_level',
      // todo: prevod
      label: 'Nivo a',
      inputName: 'a_level',
    } as InputSifarniciFormProps,
    CODE_2_FORM,
    {
      type_of_form: 'input',
      name: 'proizvodi_id_jm',
      // todo: prevod
      label: 'Proizvodi - ID JM',
      inputName: 'proizvodi_id_jm',
      hideInput: true,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'proizvodi_notacija_jm',
      // todo: prevod
      label: 'Proizvodi - Notacija JM',
      inputName: 'proizvodi_notacija_jm',
      hideInput: true,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'proizvodi_jedinica_mere',
      // todo: prevod
      label: 'Proizvodi - jedinica mere',
      inputName: 'proizvodi_jedinica_mere',
      hideInput: true,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'proizvodi_jedinica_mere_sr',
      // todo: prevod
      label: 'Proizvodi - jedinica mere srp.',
      inputName: 'proizvodi_jedinica_mere_sr',
      hideInput: true,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'proizvodi_definicija_jm',
      // todo: prevod
      label: 'Proizvodi - definicija JM',
      inputName: 'proizvodi_definicija_jm',
      hideInput: true,
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'proizvodi_definicija_jm_sr',
      // todo: prevod
      label: 'Proizvodi - definicija JM srp.',
      inputName: 'proizvodi_definicija_jm_srp',
      hideInput: true,
    } as InputSifarniciFormProps,
  ];
};
