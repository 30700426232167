import { observer } from 'mobx-react-lite';
import { StartPibForm } from './components/StartPibForm/StartPibForm';
import styles from './UserStartPage.module.scss';

import { Alert } from 'antd';
import { Survey } from './components/Survey/Survey';
import { t } from 'i18next';
import { LogoWithForm } from 'components/LogoWithForm/LogoWithForm';
import { LogOutButton } from 'components/LogOutButton/LogOutButton';
import { companyStore } from 'modules/company/company.store';
import { KoraciRegistracijeKorisnika } from 'modules/company/company.constants';

const UserStartPage: React.FC = observer(() => {
  // TODO: prevesti poruku iz info

  return (
    <div className={styles.user_start_page}>
      {companyStore.getPageToShow === KoraciRegistracijeKorisnika.PIB && <StartPibForm />}
      {companyStore.getPageToShow === KoraciRegistracijeKorisnika.SURVEY && <Survey />}
      {companyStore.getPageToShow === KoraciRegistracijeKorisnika.INFO && (
        <LogoWithForm
          title="Registracija Preduzeća"
          component={
            <div className={styles.alertWrapper}>
              <Alert message={t('USER.ALERTS.INFO')} type={'info'} />
              <LogOutButton />
            </div>
          }
        />
      )}
      {companyStore.getPageToShow === KoraciRegistracijeKorisnika.WARNING && (
        <LogoWithForm
          title="Registracija Preduzeća"
          component={
            <div className={styles.alertWrapper}>
              <span>{t('USER.ALERTS.WARNING')}</span>
              <LogOutButton />
            </div>
          }
        />
      )}
    </div>
  );
});

export default UserStartPage;
