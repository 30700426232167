export const findLabelForTrueKey = (options: { label: string; value: any }[], payload: any) => {
  // Loop through the options array
  for (const option of options) {
    // Check if the key corresponding to the value is true
    if (payload[option.value]) {
      // Return the label when the true key is found
      return option.label;
    }
  }
};
