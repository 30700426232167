import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { TableUpravljanjeObrazac5 } from './TableUpravljanjeObrazac5/TableUpravljanjeObrazac5';

export const Obrazac5UpravljanjeWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'obrazac5-upravljanje-form'} modalName="obrazac5-upravljanje-modal" />}
    >
      <TableUpravljanjeObrazac5 />
    </InsideContentWrapper>
  );
});
