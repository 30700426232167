import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { ColumnType } from 'antd/es/table';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { zakljucavanjeStore } from 'modules/zakljucavanje/zakljucavanje.store';
import { IZakljucavanjeGodinaIzuzeci } from 'modules/zakljucavanje/zakljucavanje.types';
import styles from './ZakljucavanjeGodinaIzuzeciTable.module.scss';
import { modalStore } from 'store';

const ZakljucavanjeGodinaIzuzeciTableHeader = observer(() => {
  return (
    <Row className={styles.izuzeciHeader}>
      <Col>
        <h2>{`Izuzeci za ${zakljucavanjeStore.selectedGodina?.godina}. godinu`}</h2>
      </Col>
      <Col>
        <AddNewButton
          name="add-new-izuzetak"
          onButtonClick={() => modalStore.changeModalName('zakljucavanje-godina-izuzeci-modal')}
        />
      </Col>
    </Row>
  );
});

export const ZakljucavanjeGodinaIzuzeciTable = observer(() => {
  const columns: ColumnType<IZakljucavanjeGodinaIzuzeci>[] = [
    {
      width: 100,
      title: 'Obrazac',
      dataIndex: ['tip_obrasca', 'naziv'],
      render(_, record) {
        return <span>{`${record.tip_obrasca.skraceni_naziv} - ${record.tip_obrasca.naziv}`}</span>;
      },
      key: 'tip_obrasca',
    },
    {
      width: 100,
      title: 'Preduzece',
      dataIndex: ['preduzece', 'naziv'],
      key: 'preduzece',
    },
    {
      width: 30,
      align: 'center',
      title: 'Ukloni izuzetak',
      render(_, record) {
        return (
          <Button
            icon={<DeleteOutlined rev={undefined} />}
            name={'delete-user'}
            onClick={() => zakljucavanjeStore.deleteIzuzetakPoGodini(record.id)}
          />
        );
      },
    },
  ];

  return (
    <div className={styles.izuzeciWrapper}>
      <ZakljucavanjeGodinaIzuzeciTableHeader />
      <TableData
        columns={columns}
        name="zakljucavanje-godina-izuzeci"
        dataSource={zakljucavanjeStore.izuzeciPoGodini}
        getData={zakljucavanjeStore.fetchIzuzeciPoGodini}
        forceFetch={[zakljucavanjeStore.selectedGodina]}
        refetchSamePage={[zakljucavanjeStore.refetchTable]}
        withPagination
      />
    </div>
  );
});
