import { Col, Row } from 'antd';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { aao1Store } from 'modules/obrasci/store/aao1/aao1.store';
import React, { useEffect } from 'react';
import { CONDITIONALLY_REQUIRED_FIELDS_RULE, MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { useTranslation } from 'react-i18next';
import { IGetKolicinaAmbalaze } from 'modules/obrasci/store/aao1/aao1.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { Aao1Operateri } from '../Aao1Operateri/Aao1Operateri';
import style from './NewKolicinaAmbalazeForm.module.scss';

export const NewKolicinaAmbalazeForm: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const defaultPackingTypeOption: ISifarnikInitial | undefined = aao1Store.getKolicinaAmbalaze.type_of_packing_id
    ? {
        value: aao1Store.getKolicinaAmbalaze.type_of_packing_id,
        label: (aao1Store.getKolicinaAmbalaze as IGetKolicinaAmbalaze).type_of_packing.display_type_sr,
      }
    : undefined;

  const onUkupnaChange = () => {
    const ukupnaNepovratna = Number(form.getFieldValue('ukupna_kolicina_nepovratne_ambalaze'));
    const ukupnaPovratna = Number(form.getFieldValue('ukupna_kolicina_povratne_ambalaze'));

    if (aao1Store.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera)
      form.setFieldValue('kolicina_preneta_na_operatera', ukupnaPovratna + ukupnaNepovratna);
  };

  const onKolicinaPovratneInputChange = () => {
    const proizvedenaKolicinaPovratne = Number(form.getFieldValue('proizvedena_kolicina_povratne_ambalaze'));
    const uvezenaKolicinaPovratne = Number(form.getFieldValue('uvezena_kolicina_povratne_ambalaze'));
    const izvezenaKolicinaPovratne = Number(form.getFieldValue('izvezena_kolicina_povratne_ambalaze'));

    const ukupnaKolicinaPovratne = proizvedenaKolicinaPovratne + uvezenaKolicinaPovratne - izvezenaKolicinaPovratne;

    !proizvedenaKolicinaPovratne && !uvezenaKolicinaPovratne && !izvezenaKolicinaPovratne && !ukupnaKolicinaPovratne
      ? form.setFieldValue('ukupna_kolicina_povratne_ambalaze', null)
      : form.setFieldValue('ukupna_kolicina_povratne_ambalaze', ukupnaKolicinaPovratne);

    onUkupnaChange();
  };

  const onKolicinaNepovratneInputChange = () => {
    const proizvedenaKolicinaNepovratne = Number(form.getFieldValue('proizvedena_kolicina_nepovratne_ambalaze'));
    const uvezenaKolicinaNepovratne = Number(form.getFieldValue('uvezena_kolicina_nepovratne_ambalaze'));
    const izvezenaKolicinaNepovratne = Number(form.getFieldValue('izvezena_kolicina_nepovratne_ambalaze'));

    const ukupnaKolicinaNepovratne =
      proizvedenaKolicinaNepovratne + uvezenaKolicinaNepovratne - izvezenaKolicinaNepovratne;

    !proizvedenaKolicinaNepovratne &&
    !uvezenaKolicinaNepovratne &&
    !izvezenaKolicinaNepovratne &&
    !ukupnaKolicinaNepovratne
      ? form.setFieldValue('ukupna_kolicina_nepovratne_ambalaze', null)
      : form.setFieldValue('ukupna_kolicina_nepovratne_ambalaze', ukupnaKolicinaNepovratne);
    onUkupnaChange();
  };

  useEffect(() => {
    onKolicinaPovratneInputChange();
    onKolicinaNepovratneInputChange();
  }, []);

  return (
    <Col span={24} className={style.wrapper}>
      <FormSearchInfiniteScroll
        showSearch={true}
        label={t('OBRASCI.AAO1.PACKAGING_AMOUNT.PACKAGING_TYPE')}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        formName={'type_of_packing_id'}
        inputName={'type_of_packing_id'}
        labelOptionAccessor={'display_type_sr'}
        valueOptionAccessor={'id'}
        fetchOptinsList={aao1Store.fetchTypeOfPackingOptions}
        placeholder={t('OBRASCI.AAO1.PACKAGING_AMOUNT.PLACEHOLDERS.PACKAGING_TYPE')}
        defaultOption={defaultPackingTypeOption}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                aao1Store.getKolicinaAmbalaze.type_of_packing_id !== value &&
                aao1Store.initialState.kolicina_ambalaze.some(item => {
                  return value === item.type_of_packing_id;
                })
              ) {
                return Promise.reject(new Error(t('VALIDATION.ALREADY_EXISTS')));
              }
              return Promise.resolve();
            },
          },
        ]}
      />
      <Row>
        <Col span={15}>
          <Row>
            <Col span={11}></Col>
            <Col span={13}>
              <p>{t('OBRASCI.AAO1.PACKAGING_AMOUNT.RETURN_PACKAGING')}</p>
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <p>{t('OBRASCI.AAO1.PACKAGING_AMOUNT.MANUFACTURED_AMOUNT')}:</p>
            </Col>
            <Col span={13}>
              <FormInputNumber
                name="proizvedena_kolicina_povratne_ambalaze"
                placeholder={''}
                rules={MORE_THEN_0}
                inputName={'proizvedena_kolicina_povratne_ambalaze'}
                onInputChange={() => {
                  onKolicinaPovratneInputChange();
                  form.validateFields(['ukupna_kolicina_povratne_ambalaze', 'ukupna_kolicina_nepovratne_ambalaze']);
                  if (aao1Store.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera)
                    form.validateFields(['kolicina_preneta_na_operatera']);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <p>{t('OBRASCI.AAO1.PACKAGING_AMOUNT.IMPORTED_AMOUNT')}:</p>
            </Col>
            <Col span={13}>
              <FormInputNumber
                name="uvezena_kolicina_povratne_ambalaze"
                onInputChange={() => {
                  onKolicinaPovratneInputChange();
                  form.validateFields(['ukupna_kolicina_povratne_ambalaze', 'ukupna_kolicina_nepovratne_ambalaze']);
                  if (aao1Store.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera)
                    form.validateFields(['kolicina_preneta_na_operatera']);
                }}
                rules={MORE_THEN_0}
                placeholder={''}
                inputName={'uvezena_kolicina_povratne_ambalaze'}
              />
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              {' '}
              <p>{t('OBRASCI.AAO1.PACKAGING_AMOUNT.EXPORTED_AMOUNT')}:</p>
            </Col>
            <Col span={13}>
              <FormInputNumber
                name="izvezena_kolicina_povratne_ambalaze"
                onInputChange={() => {
                  onKolicinaPovratneInputChange();
                  form.validateFields(['ukupna_kolicina_povratne_ambalaze', 'ukupna_kolicina_nepovratne_ambalaze']);
                  if (aao1Store.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera)
                    form.validateFields(['kolicina_preneta_na_operatera']);
                }}
                rules={MORE_THEN_0}
                placeholder={''}
                inputName={'izvezena_kolicina_povratne_ambalaze'}
              />
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <p>
                <span style={{ color: 'red' }}>*</span>
                <span style={{ color: 'red' }}>*</span> {t('OBRASCI.AAO1.PACKAGING_AMOUNT.TOTAL_AMOUNT')}:
              </p>
            </Col>
            <Col span={13}>
              <FormInputNumber
                name="ukupna_kolicina_povratne_ambalaze"
                disabled={true}
                rules={[
                  ...MORE_THEN_0,
                  CONDITIONALLY_REQUIRED_FIELDS_RULE([
                    'ukupna_kolicina_nepovratne_ambalaze',
                    'ukupna_kolicina_povratne_ambalaze',
                  ]),
                ]}
                placeholder={''}
                inputName={'ukupna_kolicina_povratne_ambalaze'}
                colon={true}
              />
            </Col>
          </Row>
        </Col>
        <Col span={8} offset={1}>
          <Row>
            <p>{t('OBRASCI.AAO1.PACKAGING_AMOUNT.NON_RETURNABLE_PACKAGING')}</p>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="proizvedena_kolicina_nepovratne_ambalaze"
                placeholder={''}
                rules={MORE_THEN_0}
                inputName={'proizvedena_kolicina_nepovratne_ambalaze'}
                onInputChange={() => {
                  onKolicinaNepovratneInputChange();
                  form.validateFields(['ukupna_kolicina_nepovratne_ambalaze', 'ukupna_kolicina_povratne_ambalaze']);
                  if (aao1Store.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera)
                    form.validateFields(['kolicina_preneta_na_operatera']);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="uvezena_kolicina_nepovratne_ambalaze"
                onInputChange={() => {
                  onKolicinaNepovratneInputChange();
                  form.validateFields(['ukupna_kolicina_nepovratne_ambalaze', 'ukupna_kolicina_povratne_ambalaze']);
                  if (aao1Store.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera)
                    form.validateFields(['kolicina_preneta_na_operatera']);
                }}
                rules={MORE_THEN_0}
                placeholder={''}
                inputName={'uvezena_kolicina_nepovratne_ambalaze'}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="izvezena_kolicina_nepovratne_ambalaze"
                onInputChange={() => {
                  onKolicinaNepovratneInputChange();
                  form.validateFields(['ukupna_kolicina_nepovratne_ambalaze', 'ukupna_kolicina_povratne_ambalaze']);
                  if (aao1Store.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera)
                    form.validateFields(['kolicina_preneta_na_operatera']);
                }}
                rules={MORE_THEN_0}
                placeholder={''}
                inputName={'izvezena_kolicina_nepovratne_ambalaze'}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInputNumber
                name="ukupna_kolicina_nepovratne_ambalaze"
                disabled={true}
                rules={[
                  ...MORE_THEN_0,
                  CONDITIONALLY_REQUIRED_FIELDS_RULE([
                    'ukupna_kolicina_nepovratne_ambalaze',
                    'ukupna_kolicina_povratne_ambalaze',
                  ]),
                ]}
                placeholder={''}
                inputName={'ukupna_nepovratna'}
                colon={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <FormCheckbox
        className={style.checkbox}
        onCheckBoxChange={() => {
          aao1Store.handleKolicinaAmbalazeChange(
            'da_li_je_preneta_kolicina_ambalaze_na_operatera',
            !aao1Store.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera
          );
          aao1Store.resetOperateri();
        }}
        name="da_li_je_preneta_kolicina_ambalaze_na_operatera"
        labelCol={{ span: 23 }}
        label={t('OBRASCI.AAO1.PACKAGING_AMOUNT.IF_OPERATOR')}
      />
      {aao1Store.kolicinaAmbalaze.da_li_je_preneta_kolicina_ambalaze_na_operatera && <Aao1Operateri form={form} />}
    </Col>
  );
});
