import { Content } from 'components/Content/Content';
import { ExcelAndAddButton } from 'components/ExcelAndAddButton/ExcelAndAddButton';
import { UsersListTable } from 'components/Tables';
import { observer } from 'mobx-react-lite';
import { filtersStore } from 'store/filteri.store';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import { userStore } from 'modules/user/user.store';

const UserListHeader = () => {
  const { t } = useTranslation();
  return (
    <div id="userHeader">
      <ExcelAndAddButton
        component={<h1 className="user-list-header">{t('USER.LIST')}</h1>}
        excelName={'user'}
        excelFunc={() => userStore.exportUsersListExcel()}
        addButtonLabel="Dodaj administratora"
        addButtonOnChange={() => {
          modalStore.changeModalName('user-modal');
          userStore.setUserTypeForm('user-administrator');
        }}
      />
    </div>
  );
};

const UsersList: React.FC = observer(() => {
  return (
    <Content header={<UserListHeader />}>
      <UsersListTable
        isFacility={false}
        fetchListTable={userStore.fetchUserList}
        filterName={'korisnik'}
        forceFetch={filtersStore.filters.korisnik}
      />
    </Content>
  );
});

export default UsersList;
