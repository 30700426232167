import { ColumnsType } from 'antd/es/table';
import { IGetPzvTypeOfProducts, IPostPzvTypeOfProducts } from './pzvTypeOfProducts.types';
import { FormInstance } from 'antd';
import { NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { NAME_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';

export const PZV_TYPE_OF_PRODUCTS_INITIAL_STATE: IPostPzvTypeOfProducts = {
  name: '',
  is_active: true,
};
export const PZV_TYPE_OF_PRODUCTS_TABLE_DATA: ColumnsType<IGetPzvTypeOfProducts> = [{ ...NAME_TABLE, width: '700px' }];
export const PZV_TYPE_OF_PRODUCTS_FORM_OPTIONS = (_: FormInstance<IGetPzvTypeOfProducts>) => {
  return [NAME_FORM];
};
