import { ColumnsType } from 'antd/es/table';
import { IGetActivitiesOfWasteOperators, IPostActivitiesOfWasteOperators } from './activitiesOfWasteOperators.types';
import { FormInstance } from 'antd';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { THE_ORDER_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { THE_ORDER_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';

export const ACTIVITIES_OF_WASTE_OPERATORS_INITIAL_STATE: IPostActivitiesOfWasteOperators = {
  the_order: '',
  activities_operator: '',
  activities_operator_sr: '',
  is_active: true,
};

export const ACTIVITIES_OF_WASTE_OPERATORS_TABLE_DATA: ColumnsType<IGetActivitiesOfWasteOperators> = [
  THE_ORDER_TABLE,
  {
    title: 'Aktivnost operatera',
    width: '300px',
    dataIndex: 'activities_operator',
    key: 'activities_operator',
  },
  {
    title: 'Aktivnost operatera (srp.)',
    width: '300px',
    dataIndex: 'activities_operator_sr',
    key: 'activities_operator_sr',
  },
];

export const ACTIVITIES_OF_WASTE_OPERATORS_FORM_OPTIONS = (_: FormInstance<IGetActivitiesOfWasteOperators>) => {
  return [
    THE_ORDER_FORM,
    {
      type_of_form: 'input',
      name: 'activities_operator',
      // todo: prevod
      label: 'Aktivnost operatera',
      inputName: 'activities_operator',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'activities_operator_sr',
      // todo: prevod
      label: 'Aktivnost operatera (srp.)',
      inputName: 'activities_operator_sr',
    } as InputSifarniciFormProps,
  ];
};
