import { observer } from 'mobx-react-lite';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { Col } from 'antd';
import styles from './PZVOpsteWizardTab.module.scss';
import { useEffect } from 'react';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { pzv1ObrazacStore } from 'modules/obrasci/store/pzv1/pzv1.store';

export const PZVOpsteWizardTab: React.FC = observer(() => {
  useEffect(() => {
    if (obrasciStore.opste.godina && obrasciStore.opste.preduzece_id !== '') {
      obrasciStore.setDisabledTabButton(false);
    }
  }, [obrasciStore.opste]);

  return (
    <Col span={12} className={styles.wrapper}>
      <OpsteGodinaPreduzecePostrojenje withPostrojenje={false} store={pzv1ObrazacStore} />
    </Col>
  );
});
