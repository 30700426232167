import { observer } from 'mobx-react-lite';
import React from 'react';
import { Deo6SkladisteModal } from './components/Deo6SkladisteModal/Deo6SkladisteModal';
import { Deo6PredatOtpadModal } from './components/Deo6PredatOtpadModal/Deo6PredatOtpadModal';
import { Deo6PreuzetOtpadModal } from './components/Deo6PreuzetOtpadModal/Deo6PreuzetOtpadModal';
import { Deo6PonovnaUpotrebaModal } from './components/Deo6PonovnaUpotrebaModal/Deo6PonovnaUpotrebaModal';
import { Deo6NoveVrsteOtpadModal } from './components/Deo6NovaVrstaOtpadaModal/Deo6NovaVrstaOtpadaModal';
import { Deo6RazredNoveVrsteOtpadaModal } from './components/Deo6RazredNoveVrsteOtpadaModal/Deo6RazredNoveVrsteOtpadaModal';
import { Deo6RazredPredatOtpadaModal } from './components/Deo6RazredPredatOtpadModal/Deo6RazredPredatOtpadModal';
import { Deo6RazredPreuzetOtpadaModal } from './components/Deo6RazredPreuzetOtpadModal/Deo6RazredPreuzetOtpadModal';
import { Deo6RazredSkladisteModal } from './components/Deo6RazredSkladisteModal/Deo6RazredSkladisteModal';
import { Deo6RazrediPonovnaUpotrebaModal } from './components/Deo6RazrediPonovnaUpotrebaModal/Deo6RazrediPonovnaUpotrebaModal';
import { Deo6KalkulacijaModal } from './components/Deo6KalkulacijaModal/Deo6KalkulacijaModal';

export const Deo6MicroServices: React.FC = observer(() => {
  return (
    <>
      <Deo6SkladisteModal />
      <Deo6PredatOtpadModal />
      <Deo6PreuzetOtpadModal />
      <Deo6PonovnaUpotrebaModal />
      <Deo6NoveVrsteOtpadModal />
      <Deo6RazredNoveVrsteOtpadaModal />
      <Deo6RazredPredatOtpadaModal />
      <Deo6RazredPreuzetOtpadaModal />
      <Deo6RazredSkladisteModal />
      <Deo6RazrediPonovnaUpotrebaModal />
      <Deo6KalkulacijaModal />
    </>
  );
});
