import React from 'react';
import { observer } from 'mobx-react-lite';
import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { useTranslation } from 'react-i18next';
import { gio3ObrazacStore } from 'modules/obrasci/store/gio3/gio3.store';
import { modalStore } from 'store';
import { IGetRazrediEeOtpadaGio3 } from 'modules/obrasci/store/gio3/gio3.types';

export const TableRazrediEEGio3: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetRazrediEeOtpadaGio3> = [
    {
      title: `${t('OBRASCI.GIO_3.CLASS_EE_WASTE')}`,
      width: 100,
      key: 'category_name',
      render: (_: any, record: IGetRazrediEeOtpadaGio3) => (
        <>{`${record.razred_ee_otpada.the_order} ${record.razred_ee_otpada.category_name_sr}`}</>
      ),
    },
    {
      title: `${t('OBRASCI.GIO_3.QUANTITY_T')}`,
      width: 100,
      dataIndex: 'kolicina',
      key: 'kolicina',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record: IGetRazrediEeOtpadaGio3) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-gio3-razredi-ee'}
            onClick={() => {
              gio3ObrazacStore.handleChange('razredi_ee_form', record);
              modalStore.changeModalName('gio3-razredi-ee-modal');
            }}
          />
        );
      },
    },
  ];

  return (
    <TableData
      name="razredi-gio3"
      columns={baseColumns}
      dataSource={[...gio3ObrazacStore.kolicine_form.razredi_ee_otpada]}
    />
  );
});
