import { BrowserRouter } from 'react-router-dom';
import 'translations/i18n.config';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { authStore, modalStore } from 'store';
import Loader from 'components/Loader/Loader';
import { ToastContainer } from 'components/ToastContainer/ToastContainer';
import { RoutesList } from 'router/RoutesList';

export const App: React.FC = observer(() => {
  useEffect(() => {
    authStore.initKeycloak();
  }, []);

  return (
    <BrowserRouter>
      {authStore.getInitComplete && (
        <>
          <ToastContainer />
          {modalStore.isLoading && <Loader />}
          <RoutesList />
        </>
      )}
    </BrowserRouter>
  );
});

export default App;
