import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { AAO2_PRAVNO_LICE } from 'modules/obrasci/store/aao2/aao2.constants';
import { aao2Store } from 'modules/obrasci/store/aao2/aao2.store';
import { NewAao2PreduzetnikPravnoLiceForm } from './components/NewAao2PreduzetnikPravnoLiceForm';

export const Aao2PreduzetnikPravnoLiceModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  return (
    <CRUDModal
      width="70%"
      name="aao2-pravno-lice-modal"
      title={t('OBRASCI.AAO2.PRAVNO_LICE_TITLE')}
      store={aao2Store}
      data={aao2Store.getPravnoLice}
      children={({ form }) => <NewAao2PreduzetnikPravnoLiceForm form={form} />}
      submitForm={aao2Store.submitPravnoLice}
      initialState={AAO2_PRAVNO_LICE}
      dataToChange={'pravnoLiceIliPreduzetnik'}
    />
  );
});
