import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { t } from 'i18next';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { IPostWaterCourse, IGetWaterCourse } from './waterCourse.types';

export const WATER_COURSE_INITIAL_STATE: IPostWaterCourse = {
  is_active: true,
  water_course_name: '',
};
export const WATER_COURSE_TABLE_DATA: ColumnsType<IGetWaterCourse> = [
  {
    title: t('SIFARNICI.NAZIV'),
    // align: 'center',
    width: '700px',
    dataIndex: 'water_course_name',
    key: 'water_course_name',
  },
];
export const WATER_COURSE_FORM_OPTIONS = (_: FormInstance<IGetWaterCourse>) => {
  return [
    {
      type_of_form: 'input',
      label: t('SIFARNICI.NAZIV'),
      inputName: 'water_course_name',
      name: 'water_course_name',
    } as InputSifarniciFormProps,
  ];
};
