import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FacilityListTable } from './FacilityListTable/FacilityListTable';
import { FacilityFiltersTable } from './FacilityFiltersTable/FacilityFiltersTable';
import { authStore, modalStore } from 'store';
import { ExcelAndAddButton } from 'components/ExcelAndAddButton/ExcelAndAddButton';
import { companyStore } from 'modules/company/company.store';
import { UserRoles } from 'modules/user/user.types';

export const CompanyFacilityListHeader: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Row justify={'space-between'} align={'middle'}>
      <h3>{t('FACILITY.LIST')}</h3>
      <ExcelAndAddButton
        addButtonLabel="Dodaj postrojenje"
        excelFunc={undefined}
        excelName={'postrojenje'}
        addButtonOnChange={
          companyStore.getCompany.is_active && authStore.getUserRole === UserRoles.SystemAdmin
            ? () => {
                modalStore.changeModalName('facility-modal');
              }
            : undefined
        }
      />
    </Row>
  );
});

export const CompanyFacilityList: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<CompanyFacilityListHeader />}>
      <FacilityFiltersTable />
      <FacilityListTable />
    </InsideContentWrapper>
  );
});
