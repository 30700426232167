import { Modal } from 'components/Modal/Modal';
import { NewSifarnikForm } from 'pages/Sifarnici/components/SifarniciCrudModal/NewSifarnikForm/NewSifarnikForm';
import { observer } from 'mobx-react-lite';
import { SIFARNIK_MODAL_DATA } from 'modules/sifarnici/sifarnici.constants';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { ISifarniciConfig } from 'modules/sifarnici/sifarnici.types';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { modalStore } from 'store';
import { removeSearchParamsFromURL } from 'utils/removeParamsFromUrl';

export const SifarniciCrudModal: React.FC = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [sifarniciConfig, setSifarniciConfig] = useState<ISifarniciConfig>(
    SIFARNIK_MODAL_DATA[sifarniciStore.sifarnik_type]
  );

  const [searchParams] = useSearchParams();
  const sifarnik_type = queryParams.get('sifarnik_type');
  const sifarnik_id = queryParams.get('sifarnik_id');

  useEffect(() => {
    if (sifarnik_type) {
      sifarniciStore.handleChange('sifarnik_type', sifarnik_type);
    }
    if (sifarnik_id) {
      modalStore.changeModalName('sifarnici-crud-modal');
    }
    () => removeSearchParamsFromURL();
  }, [searchParams]);

  const handleCancel = () => {
    queryParams.delete('sifarnik_type');
    queryParams.delete('sifarnik_id');
    const newSearch = queryParams.toString();
    navigate({ search: newSearch });
    modalStore.removeAllModals();
  };

  useEffect(() => {
    setSifarniciConfig(SIFARNIK_MODAL_DATA[sifarniciStore.sifarnik_type]);
  }, [sifarniciStore.sifarnik_type]);

  return (
    <Modal
      name={'sifarnici-crud-modal'}
      open={modalStore.getCurrentOpenedModal.includes('sifarnici-crud-modal')}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={handleCancel}
      title={sifarniciConfig.modal.title}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      width={sifarniciConfig.modal.width ? sifarniciConfig.modal.width : '50%'}
    >
      <NewSifarnikForm formData={sifarniciConfig.formData} />
    </Modal>
  );
});
