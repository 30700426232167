import React from 'react';
import { Popconfirm as PopconfirmPop, PopconfirmProps } from 'antd';

import './Popconfirm.module.scss';

interface IPopconfirm extends PopconfirmProps {
  name: string;
}

export const Popconfirm: React.FC<IPopconfirm> = props => {
  return <PopconfirmPop {...props} data-test={`popconfirm-${props.name}`} />;
};
