import { ColumnsType } from 'antd/es/table';
import { IGetTypeOfWaste, IPostTypeOfWaste } from './typeOfWaste.types';
import { NAME_SR_TABLE, NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';

export const TYPE_OF_WASTE_INITIAL_STATE: IPostTypeOfWaste = {
  name: '',
  name_sr: '',
  is_active: true,
};

export const TYPE_OF_WASTE_TABLE_DATA: ColumnsType<IGetTypeOfWaste> = [NAME_TABLE, NAME_SR_TABLE];

export const TYPE_OF_WASTE_FORM_OPTIONS = (_: FormInstance<IGetTypeOfWaste>) => {
  return [NAME_FORM, NAME_SR_FORM];
};
