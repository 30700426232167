import { ColumnProps } from 'antd/es/table';
import { ColumnGroupProps } from 'antd/es/table/ColumnGroup';
import { obrazacIzvestajiService } from './obrazacIzvestaji.service';
import {
  Aao1IzvestajiEnum,
  Aao2IzvestajiEnum,
  Deo6IzvestajiEnum,
  Dep1IzvestajiEnum,
  Dep2IzvestajiEnum,
  Gio2IzvestajiEnum,
  Gio3IzvestajiEnum,
  Gio4ElektricniIElektronskiOtpad,
  Gio4IzvestajiEnum,
  Gio4KolicinaIzvezenogOtpadaPoVrstiOtpada,
  Gio4PostrojenjaIndeksniBroj,
  Gio4PregledPoGodinama,
  Gio5IzvestajiEnum,
  IzvestajiSubtypes,
  Kom1IzvestajiEnum,
  Obrazac1Gorivo,
  Obrazac1IzvestajiEnum,
  Obrazac1Proizvod,
  Obrazac2IzvestajiEnum,
  Obrazac3IspustPoGodinama,
  Obrazac3IzvestajiEnum,
  Obrazac3Opste,
  Obrazac3PregledPoGodinama,
  Obrazac3ZagadjujuceMaterije,
  Obrazac5IzvestajiEnum,
  Ptp2NedostavljeniPodaciOGumama,
  Ptp2NedostavljeniPodaciOVozilima,
  Ptp2ObracunZaNaplatu,
  Ptp2UvozGumaUporedjivanje,
  Ptp2VozilaUporedjivanje,
  Ptp2IzvestajiEnum,
  PzvIzvestajiEnum,
  PzvKolicineProizvodaPoPreduzecu,
  PzvKolicineProizvodaPoGrupiIPreduzecu,
  Obrazac5Postrojenja,
  Obrazac5PostrojenjaIndeksniBroj,
  Obrazac5GrupaPodgrupaIndeksniBroj,
  Obrazac5NeOpasan,
  Obrazac5PregledPoGodinama,
  Obrazac5PostrojenjaZaPlacanje,
  Gio5PostrojenjaIndeksniBroj,
  Gio5GrupaPodgrupaIndeksniBroj,
  Gio5PregledPoGodinama,
  Gio5KolicinaUvezenogOtpadaPoVrstiOtpada,
  Aao1PregledPoGodinama,
  Aao1Prikaz1,
  Aao1SviPodaci,
  Aao1NedostavljeniObrasci,
} from './obrazacIzvestaji.types';

export interface IIzvestajiColumnProps<RecordType = unknown> extends Omit<ColumnProps<RecordType>, 'dataIndex'> {
  dataIndex?: keyof RecordType;
}

export interface IIzvestajiColumnGroupProps<RecordType = unknown>
  extends Omit<ColumnGroupProps<RecordType>, 'children'> {
  children: IIzvestajiColumnProps<RecordType> | IIzvestajiColumnProps<RecordType>[];
}

// Todo: define correctly according to interface
export const OBRAZAC_1_GORIVA_COLUMNS: IIzvestajiColumnProps<Obrazac1Gorivo>[] = [
  { dataIndex: 'godina', title: 'Godina' },
  { render: (record: Obrazac1Proizvod) => record.preduzece.pib },
  { dataIndex: 'preduzece', title: 'Preduzece' },
  // { dataIndex: 'nacionalniId' },
  { dataIndex: 'postrojenje', title: 'Postrojenje' },
  // { dataIndex: 'opstinaPostrojenja' },
  // { dataIndex: 'nazivGoriva' },
  // { dataIndex: 'potrosnjaNaDan' },
  // { dataIndex: 'jedMere' },
  { dataIndex: 'kreirano', title: 'Kreirano' },
  { dataIndex: 'azurirano', title: 'Azurirano' },
  { dataIndex: 'napomena', title: 'Napomena' },
];

export const OBRAZAC_1_PROIZVODI_COLUMNS: IIzvestajiColumnProps<Obrazac1Proizvod>[] = [
  { dataIndex: 'godina', title: 'Godina' },
  { render: (record: Obrazac1Proizvod) => record.preduzece.pib, title: 'Pib' },
  { dataIndex: 'preduzece', title: 'Preduzeće' },
  { render: (record: Obrazac1Proizvod) => record.preduzece.maticni_broj, title: 'Matični broj' },
  { dataIndex: 'postrojenje', title: 'Postrojenje' },
  { dataIndex: 'prtr_kod', title: 'Prtr kod' },
  { render: (record: Obrazac1Proizvod) => record.postrojenje.postanski_broj, title: 'Poštanski broj' },
  { dataIndex: 'naziv_proizvoda', title: 'Naziv proizvoda' },
  { dataIndex: 'kolicina_proizvoda', title: 'Količina proizvoda' },
  { dataIndex: 'jedinica_mere', title: 'Jedinica mere' },
  { dataIndex: 'kreirano', title: 'Kreirano' },
  { dataIndex: 'azurirano', title: 'Ažurirano' },
  { dataIndex: 'napomena', title: 'Napomena' },
];

export const OBRAZAC_3_ZAGADLJIVE_MATERIJE_COLUMNS: IIzvestajiColumnProps<Obrazac3ZagadjujuceMaterije>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 200, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { width: 100, dataIndex: 'prtr', title: 'PRTR' },
  { width: 200, dataIndex: 'aktivnosti', title: 'Aktivnosti' },
  { width: 200, dataIndex: 'naziv_ispusta', title: 'Naziv ispusta' },
  { width: 100, dataIndex: 'broj_ispusta', title: 'Broj ispusta' },
  { width: 200, dataIndex: 'vrsta_recipijenta', title: 'Vrsta recipijenta' },
  { width: 200, dataIndex: 'naziv_recipijenta', title: 'Naziv recipijenta' },
  { width: 200, dataIndex: 'sliv', title: 'Sliv' },
  { width: 150, dataIndex: 'vremenski_period_ispustanja_vode', title: 'Vremenski period ispuštanja vode (dan/god)' },
  { width: 150, dataIndex: 'ukupna_kolicina_ispustene_vode', title: 'Ukupna količina ispuštene vode (m3/god)' },
  { width: 150, dataIndex: 'srednji_dnevni_protok_otpadne_vode', title: 'Srednji dnevni protok otpadne vode (m3/dan)' },
  { width: 200, dataIndex: 'zagadjujuca_materija', title: 'Zagađujuća materija' },
  { width: 150, dataIndex: 'srednja_godisnja_izmerena_vrednost', title: 'Srednja godišnja izmerena vrednost (mg/l)' },
  { width: 150, dataIndex: 'kolicina_pri_redovnom_radu', title: 'Količina pri redovnom radu (kg/god)' },
  { width: 150, dataIndex: 'kolicina_u_akcidentnim_situacijama', title: 'Količina u akcidentnim situacijama (kg/god)' },
  { width: 100, dataIndex: 'kreirano', title: 'Kreirano' },
  { width: 100, dataIndex: 'azurirano', title: 'Ažurirano' },
  { width: 250, dataIndex: 'napomena', title: 'Napomena' },
];

export const OBRAZAC_3_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Obrazac3PregledPoGodinama[]
) => IIzvestajiColumnProps<Obrazac3PregledPoGodinama>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 200, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 200, dataIndex: 'zagadjujuca_materija', title: 'Zagađujuća materija' },

  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const OBRAZAC_3_ISPUST_PO_GODINAMA_COLUMNS: (
  data: Obrazac3IspustPoGodinama[]
) => IIzvestajiColumnProps<Obrazac3IspustPoGodinama>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 200, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 100, dataIndex: 'oznaka_ispusta', title: 'Oznaka ispusta' },
  { width: 200, dataIndex: 'naziv_ispusta', title: 'Naziv ispusta' },

  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const OBRAZAC_3_OPSTE_COLUMNS: IIzvestajiColumnProps<Obrazac3Opste>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 200, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { width: 100, dataIndex: 'prtr', title: 'Prtr' },
  { width: 200, dataIndex: 'aktivnosti', title: 'Aktivnosti' },
  { width: 200, dataIndex: 'naziv_ispusta', title: 'Naziv ispusta' },
  { width: 100, dataIndex: 'oznaka_ispusta', title: 'Oznaka ispusta' },
  { width: 200, dataIndex: 'vrsta_recipijenta', title: 'Vrsta recipijenta' },
  { width: 200, dataIndex: 'naziv_recipijenta', title: 'Naziv recipijenta' },
  { width: 150, dataIndex: 'geografska_sirina', title: 'Geografska širina' },
  { width: 150, dataIndex: 'geografska_duzina', title: 'Geografska dužina' },
  { width: 150, dataIndex: 'rashladne', title: 'Rashladne' },
  { width: 150, dataIndex: 'atmosferske', title: 'Atmosferske' },
  { width: 150, dataIndex: 'sanitarne', title: 'Sanitarne' },
  { width: 150, dataIndex: 'tehnoloske', title: 'Tehnološke' },
  { width: 150, dataIndex: 'period_ispustanja', title: 'Period ispuštanja' },
  { width: 150, dataIndex: 'rezim_rada', title: 'Režim rada' },
  { width: 150, dataIndex: 'ukupna_kolicina_ispustene_vode', title: 'Ukupna količina ispuštene vode' },
  { width: 100, dataIndex: 'kreirano', title: 'Kreirano' },
  { width: 100, dataIndex: 'azurirano', title: 'Ažurirano' },
];

export const GIO_4_POSTROJENJA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Gio4PostrojenjaIndeksniBroj>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 200, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  {
    width: 200,
    dataIndex: 'opstina_postrojenja',
    title: 'Opština postrojenja',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 100, dataIndex: 'opasan', title: 'Opasan' },
  { width: 200, dataIndex: 'naziv_otpada', title: 'Naziv otpada' },
  { width: 200, dataIndex: 'ukupno_izvezeno', title: 'Ukupno izvezeno (t)' },
  { width: 200, dataIndex: 'zemlja_izvoza', title: 'Zemlja izvoza' },
  { width: 200, dataIndex: 'zemlja_tranzita', title: 'Zemlja tranzita' },
  { width: 100, dataIndex: 'y1', title: 'Y1' },
  { width: 100, dataIndex: 'y2', title: 'Y2' },
  { width: 100, dataIndex: 'y3', title: 'Y3' },
  { width: 100, dataIndex: 'c', title: 'C oznaka' },
  { width: 100, dataIndex: 'h1', title: 'H1' },
  { width: 100, dataIndex: 'h2', title: 'H2' },
  { width: 100, dataIndex: 'h3', title: 'H3' },
  { width: 100, dataIndex: 'ia', title: 'IA oznaka' },
  { width: 100, dataIndex: 'iib', title: 'IIB oznaka' },
  { width: 100, dataIndex: 'd', title: 'D oznaka' },
  { width: 100, dataIndex: 'r', title: 'R oznaka' },
];

export const GIO_4_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS = [
  { width: 150, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 250, dataIndex: 'opis', title: 'Opis' },
  { width: 200, dataIndex: 'kolicina', title: 'Količina (t)' },
];

export const GIO_4_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Gio4PregledPoGodinama[]
) => IIzvestajiColumnProps<Gio4PregledPoGodinama>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 200, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 200, dataIndex: 'opis_otpada', title: 'Opis otpada' },

  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const GIO_4_KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI_OTPADA_COLUMNS: IIzvestajiColumnProps<Gio4KolicinaIzvezenogOtpadaPoVrstiOtpada>[] =
  [
    { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
    { width: 200, dataIndex: 'otpad', title: 'Otpad' },
    { width: 150, dataIndex: 'kolicina_izvezenog_otpada', title: 'Količina izvezenog otpada' },
    { width: 150, dataIndex: 'zemlja_izvoza', title: 'Zemlja izvoza' },
  ];

export const GIO_4_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_COLUMNS: IIzvestajiColumnProps<Gio4ElektricniIElektronskiOtpad>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 200, dataIndex: 'preduzece', title: 'Preduzeće' },
  {
    width: 150,
    dataIndex: 'nacionalni_id',
    title: 'Nacionalni ID',
  },
  {
    width: 200,
    dataIndex: 'postrojenje',
    title: 'Postrojenje',
  },
  { width: 120, dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { width: 150, dataIndex: 'kolicina_izvezenog_otpada', title: 'Količina izvezenog otpada (t)' },
  { width: 150, dataIndex: 'broj_dozvole', title: 'Broj dozvole' },
  { width: 150, dataIndex: 'zemlja_izvoza', title: 'Zemlja izvoza' },
  { width: 150, dataIndex: 'zemlja_tranzita', title: 'Zemlja tranzita' },
  {
    width: 200,
    dataIndex: 'naziv_adresa_postrojenja_u_inostranstvu',
    title: 'Naziv adresa postrojenja u inostranstvu',
  },
  { width: 100, dataIndex: 'y1', title: 'Y1' },
  { width: 100, dataIndex: 'y2', title: 'Y2' },
  { width: 100, dataIndex: 'y3', title: 'Y3' },
  { width: 100, dataIndex: 'c', title: 'C oznaka' },
  { width: 100, dataIndex: 'h1', title: 'H1' },
  { width: 100, dataIndex: 'h2', title: 'H2' },
  { width: 100, dataIndex: 'h3', title: 'H3' },
  { width: 100, dataIndex: 'ia', title: 'IA' },
  { width: 100, dataIndex: 'iib', title: 'IIB' },
  { width: 100, dataIndex: 'd', title: 'D oznaka' },
  { width: 100, dataIndex: 'r', title: 'R oznaka' },
  { width: 100, dataIndex: 'razred', title: 'Razred' },
  { width: 150, dataIndex: 'vrsta_ee_otpada', title: 'Vrsta EE otpada' },
  { width: 100, dataIndex: 'kolicina_ee', title: 'Kolicina EE (t)' },
];

export const PTP_2_OBRACUN_ZA_NAPLATU_COLUMNS: (
  data: Ptp2ObracunZaNaplatu[]
) => IIzvestajiColumnProps<Ptp2ObracunZaNaplatu>[] = data => [
  { width: 200, dataIndex: 'vrsta_proizvoda', title: 'Vrsta proizvoda' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const PTP_2_VOZILA_UPOREDJIVANJE_COLUMNS: (
  data: Ptp2VozilaUporedjivanje[]
) => IIzvestajiColumnGroupProps<Ptp2VozilaUporedjivanje>[] = data => [
  ...obrazacIzvestajiService.getYearDifferenceGroupColumns(
    data,
    [
      { width: 150, dataIndex: 'pib', title: 'PIB' },
      { width: 200, dataIndex: 'preduzece', title: 'Preduzeće' },
    ],
    index => [
      {
        width: 200,
        render: (record: Ptp2VozilaUporedjivanje) => record.kolicine?.[index]?.prijavljena_kolicina,
        title: 'Prijavljena količina',
      },
      {
        width: 200,
        render: (record: Ptp2VozilaUporedjivanje) => record.kolicine?.[index]?.kolicina_na_carini,
        title: 'Količina na carini',
      },
      {
        width: 200,
        render: (record: Ptp2VozilaUporedjivanje) => record.kolicine?.[index]?.razlika,
        title: 'Razlika',
      },
    ]
  ),
];

export const PTP_2_NEDOSTAVLJENI_PODACI_O_VOZILIMA_COLUMNS: (
  data: Ptp2NedostavljeniPodaciOGumama[]
) => IIzvestajiColumnProps<Ptp2NedostavljeniPodaciOVozilima>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 200, dataIndex: 'preduzece', title: 'Preduzeće' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const PTP_2_UVOZ_GUMA_UPOREDJIVANJE_COLUMNS: (
  data: Ptp2UvozGumaUporedjivanje[]
) => IIzvestajiColumnGroupProps<Ptp2UvozGumaUporedjivanje>[] = data => [
  ...obrazacIzvestajiService.getYearDifferenceGroupColumns(
    data,
    [
      { width: 150, dataIndex: 'pib', title: 'PIB' },
      { width: 200, dataIndex: 'preduzece', title: 'Preduzeće' },
    ],
    index => [
      {
        width: 200,
        render: (record: Ptp2UvozGumaUporedjivanje) => record.kolicine?.[index]?.prijavljena_kolicina,
        title: 'Prijavljena količina',
      },
      {
        width: 200,
        render: (record: Ptp2UvozGumaUporedjivanje) => record.kolicine?.[index]?.kolicina_na_carini,
        title: 'Količina na carini',
      },
      {
        width: 200,
        render: (record: Ptp2UvozGumaUporedjivanje) => record.kolicine?.[index]?.razlika,
        title: 'Razlika',
      },
    ]
  ),
];

export const PTP_2_NEDOSTAVLJENI_PODACI_O_GUMAMA_COLUMNS: (
  data: Ptp2NedostavljeniPodaciOGumama[]
) => IIzvestajiColumnProps<Ptp2NedostavljeniPodaciOGumama>[] = data => [
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 200, dataIndex: 'preduzece', title: 'Preduzeće' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const PZV_KOLICINE_PROIZVODA_PO_PREDUZECU_COLUMNS: IIzvestajiColumnProps<PzvKolicineProizvodaPoPreduzecu>[] = [
  { dataIndex: 'godina', title: 'Godina' },
  { width: 150, dataIndex: 'pib', title: 'PIB' },
  { width: 200, dataIndex: 'preduzece', title: 'Preduzeće' },
  { dataIndex: 'grupa_proizvoda', title: 'Grupa proizvoda' },
  { dataIndex: 'proizvod', title: 'Proizvod' },
  { dataIndex: 'opis_proizvoda', title: 'Opis proizvoda' },
  { dataIndex: 'kolicina_proizvoda', title: 'Količina proizvoda (kg)' },
  {
    dataIndex: 'sadrzaj',
    title:
      'Sadržaj (%):\n- fosfata u deterdžentu\n- azota u mineralnom đubrivu\n- aktivne materije u sredstvu za zaštitu bilja',
  },
  {
    dataIndex: 'predefinisi_sadrzaj',
    title:
      'Predefinisi sadržaj (%):\n- fosfata u deterdžentu\n- azota u mineralnom đubrivu\n- aktivne materije u sredstvu za zaštitu bilja',
  },
  {
    dataIndex: 'ukupna_kolicina',
    title:
      'Ukupna količina (kg):\n- fosfata u deterdžentu\n- azota u mineralnom đubrivu\n- aktivne materije u sredstvu za zaštitu bilja',
  },
];

export const PZV_KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU_COLUMNS: IIzvestajiColumnProps<PzvKolicineProizvodaPoGrupiIPreduzecu>[] =
  [
    { dataIndex: 'godina', title: 'Godina' },
    { width: 150, dataIndex: 'pib', title: 'PIB' },
    { width: 200, dataIndex: 'preduzece', title: 'Preduzeće' },
    {
      dataIndex: 'grupa_proizvoda',
      title: 'Grupa proizvoda',
    },
    {
      dataIndex: 'ulica',
      title: 'Ulica',
    },
    {
      dataIndex: 'broj',
      title: 'Broj',
    },
    {
      dataIndex: 'kolicina_grupe_proizvoda',
      title: 'Ukupna količina grupe proizvoda (kg)',
    },
    {
      dataIndex: 'ukupna_kolicina',
      title:
        'Ukupna količina (kg):\n- fosfata u deterdžentu\n- azota u mineralnom đubrivu\n- aktivne materije u sredstvu za zaštitu bilja',
    },
    {
      dataIndex: 'kolicina_grupe_proizvoda_na_carini',
      title: 'Ukupna količina grupe proizvoda na Carini (kg)',
    },
  ];

export const OBRAZAC_5_POSTROJENJA_COLUMNS: IIzvestajiColumnProps<Obrazac5Postrojenja>[] = [
  { dataIndex: 'godina', title: 'Godina', width: '100px' },
  { dataIndex: 'pib', title: 'Pib', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'nacionalni_id', title: 'Nacionalni ID', width: '150px' },
  { dataIndex: 'postrojenje', title: 'Postrojenje', width: '300px' },
  { dataIndex: 'opstina_postrojenja', title: 'Opština postrojenja', width: '200px' },
  { dataIndex: 'aktivnosti', title: 'Aktivnosti', width: '150px' },
  { dataIndex: 'preneto_iz_prethodnog_perioda', title: 'Preneto iz prethodnog perioda', width: '150px' },
  {
    dataIndex: 'godisnja_kolicina_proizvedenog_otpada',
    title: 'Godišnja količina proizvedenog otpada',
    width: '150px',
  },
  {
    dataIndex: 'kolicina_otpada_predata_na_skladistenje',
    title: 'Količina otpada predata na skladištenje',
    width: '150px',
  },
  { dataIndex: 'kolicina_otpada_predata_na_odlaganje', title: 'Količina otpada predata na odlaganje', width: '150px' },
  { dataIndex: 'kolicina_otpada_predata_na_tretman', title: 'Količina otpada predata na tretman', width: '150px' },
  { dataIndex: 'kolicina_izvezenog_otpada', title: 'Količina izvezenog otpada', width: '150px' },
  { dataIndex: 'ostalo_na_lokaciji', title: 'Ostalo na lokaciji', width: '150px' },
];

export const OBRAZAC_5_POSTROJENJA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Obrazac5PostrojenjaIndeksniBroj>[] = [
  { dataIndex: 'godina', title: 'Godina', width: '100px' },
  { dataIndex: 'pib', title: 'Pib', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'sifra_pretezne_delatnosti', title: 'Šifra pretežne delatnosti', width: '150px' },
  { dataIndex: 'nacionalni_id', title: 'Nacionalni ID', width: '150px' },
  { dataIndex: 'postrojenje', title: 'Postrojenje', width: '300px' },
  { dataIndex: 'opstina_postrojenja', title: 'Opština postrojenja', width: '200px' },
  { dataIndex: 'aktivnosti', title: 'Aktivnosti', width: '150px' },
  { dataIndex: 'indeksni_broj', title: 'Indeksni broj', width: '100px' },
  { dataIndex: 'opasan', title: 'Opasan', width: '100px' },
  { dataIndex: 'naziv_otpada', title: 'Naziv otpada', width: '200px' },
  { dataIndex: 'vrsta_otpada', title: 'Vrsta otpada', width: '300px' },
  {
    dataIndex: 'godisnja_kolicina_proizvedenog_otpada',
    title: 'Godišnja količina proizvedenog otpada',
    width: '150px',
  },
  {
    dataIndex: 'kolicina_otpada_predata_na_skladistenje',
    title: 'Količina otpada predata na skladištenje',
    width: '150px',
  },
  { dataIndex: 'kolicina_otpada_predata_na_odlaganje', title: 'Količina otpada predata na odlaganje', width: '150px' },
  { dataIndex: 'kolicina_otpada_predata_na_tretman', title: 'Količina otpada predata na tretman', width: '150px' },
  { dataIndex: 'kolicina_izvezenog_otpada', title: 'Količina izvezenog otpada', width: '150px' },
  { dataIndex: 'y1', title: 'Y1', width: '100px' },
  { dataIndex: 'y2', title: 'Y2', width: '100px' },
  { dataIndex: 'y3', title: 'Y3', width: '100px' },
];

export const OBRAZAC_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Obrazac5GrupaPodgrupaIndeksniBroj>[] =
  [
    { dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
    { dataIndex: 'opis', title: 'Opis' },
    { dataIndex: 'kolicina', title: 'Količina' },
  ];

export const OBRAZAC_5_OTPAD_NE_OPASAN_COLUMNS: IIzvestajiColumnProps<Obrazac5NeOpasan>[] = [
  { dataIndex: 'grupa', title: 'Grupa' },
  { dataIndex: 'kolicina_neopasnog_otpada', title: 'Količina neopasnog otpada' },
  { dataIndex: 'kolicina_opasnog_otpada', title: 'Količina opasnog otpada' },
];

export const OBRAZAC_5_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Obrazac5PregledPoGodinama[]
) => IIzvestajiColumnProps<Obrazac5PregledPoGodinama>[] = data => [
  { dataIndex: 'pib', title: 'Pib', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'nacionalni_id', title: 'Nacionalni ID', width: '150px' },
  { dataIndex: 'postrojenje', title: 'Postrojenje', width: '300px' },
  { dataIndex: 'indeksni_broj', title: 'Indeksni broj', width: '150px' },
  { dataIndex: 'opis_otpada', title: 'Opis otpada', width: '300px' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const OBRAZAC_5_POSTROJENJA_ZA_PLACANJE_COLUMNS: IIzvestajiColumnProps<Obrazac5PostrojenjaZaPlacanje>[] = [
  { dataIndex: 'godina', title: 'Godina', width: '150px' },
  { dataIndex: 'pib', title: 'Pib', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'nacionalni_id', title: 'Nacionalni ID', width: '150px' },
  { dataIndex: 'postrojenje', title: 'Postrojenje', width: '300px' },
  { dataIndex: 'opstina_postrojenja', title: 'Opština postrojenja', width: '200px' },
  {
    render: (record: Obrazac5PostrojenjaZaPlacanje) => (record?.ippc ? 'Da' : 'Ne'),
    title: 'IPPC',
    width: '100px',
  },
  { dataIndex: 'aktivnosti', title: 'Aktivnosti', width: '150px' },
  { dataIndex: 'preneto_iz_prethodnog_perioda', title: 'Preneto iz prethodnog perioda', width: '150px' },
  {
    dataIndex: 'godisnja_kolicina_proizvedenog_otpada',
    title: 'Godišnja količina proizvedenog otpada',
    width: '150px',
  },
  {
    dataIndex: 'kolicina_otpada_predata_na_skladistenje',
    title: 'Količina otpada predata na skladištenje',
    width: '150px',
  },
  { dataIndex: 'kolicina_otpada_predata_na_odlaganje', title: 'Količina otpada predata na odlaganje', width: '150px' },
  { dataIndex: 'kolicina_otpada_predata_na_tretman', title: 'Količina otpada predata na tretman', width: '150px' },
  { dataIndex: 'kolicina_izvezenog_otpada', title: 'Količina izvezenog otpada', width: '150px' },
  { dataIndex: 'ostalo_na_lokaciji', title: 'Ostalo na lokaciji', width: '150px' },
];

export const GIO_5_POSTROJENJA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Gio5PostrojenjaIndeksniBroj>[] = [
  { dataIndex: 'godina', title: 'Godina', width: '100px' },
  { dataIndex: 'pib', title: 'Pib', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'nacionalni_id', title: 'Nacionalni ID', width: '150px' },
  { dataIndex: 'postrojenje', title: 'Postrojenje', width: '300px' },
  { dataIndex: 'opstina_postrojenja', title: 'Opština postrojenja', width: '200px' },
  { dataIndex: 'indeksni_broj', title: 'Indeksni broj', width: '100px' },
  {
    render: (record: Gio5PostrojenjaIndeksniBroj) => (record?.opasan ? 'Da' : 'Ne'),
    title: 'Opasan',
    width: '100px',
  },
  { dataIndex: 'naziv_otpada', title: 'Naziv otpada', width: '200px' },
  { dataIndex: 'ukupno_uvezeno', title: 'Ukupno uvezeno (t)', width: '150px' },
  { dataIndex: 'zemlja_izvoza', title: 'Zemlje Izvoza', width: '150px' },
  { dataIndex: 'zemlje_tranzita', title: 'Zemlje tranzita', width: '150px' },
  { dataIndex: 'y1', title: 'Y1', width: '100px' },
  { dataIndex: 'y2', title: 'Y2', width: '100px' },
  { dataIndex: 'y3', title: 'Y3', width: '100px' },
  { dataIndex: 'c_oznaka', title: 'C Oznaka', width: '100px' },
  { dataIndex: 'h1', title: 'H1', width: '100px' },
  { dataIndex: 'h2', title: 'H2', width: '100px' },
  { dataIndex: 'h3', title: 'H3', width: '100px' },
  { dataIndex: 'ia_oznaka', title: 'IA Oznaka', width: '100px' },
  { dataIndex: 'iib_oznaka', title: 'IIB Oznaka', width: '100px' },
];

export const GIO_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS: IIzvestajiColumnProps<Gio5GrupaPodgrupaIndeksniBroj>[] = [
  { dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
  { dataIndex: 'opis', title: 'Opis' },
  { dataIndex: 'kolicina', title: 'Količina (t)' },
];

export const GIO_5_UVOZ_OTPADA_PREGLED_PO_GODINI_COLUMNS: (
  data: Gio5PregledPoGodinama[]
) => IIzvestajiColumnProps<Gio5PregledPoGodinama>[] = data => [
  { dataIndex: 'pib', title: 'Pib', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'nacionalni_id', title: 'Nacionalni ID', width: '150px' },
  { dataIndex: 'postrojenje', title: 'Postrojenje', width: '300px' },
  { dataIndex: 'indeksni_broj', title: 'Indeksni broj', width: '150px' },
  { dataIndex: 'opis_otpada', title: 'Opis otpada', width: '300px' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const GIO_5_KOLICINA_UVEZENOG_OTPADA_PO_VRSTI_COLUMNS: IIzvestajiColumnProps<Gio5KolicinaUvezenogOtpadaPoVrstiOtpada>[] =
  [
    { dataIndex: 'indeksni_broj', title: 'Indeksni broj' },
    { dataIndex: 'otpad', title: 'Opis' },
    { dataIndex: 'kolicina_uvezenog_otpada', title: 'Količina uvezenog otpada(t)' },
    { dataIndex: 'zemlja_izvoza', title: 'Zemlja izvoza' },
  ];

export const AAO_1_PREGLED_PO_GODINAMA_COLUMNS: (
  data: Aao1PregledPoGodinama[]
) => IIzvestajiColumnProps<Aao1PregledPoGodinama>[] = data => [
  { dataIndex: 'pib', title: 'Pib', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'email', title: 'Email', width: '300px' },
  { dataIndex: 'vrsta_ambalaze', title: 'Vrsta ambalaže', width: '300px' },
  ...obrazacIzvestajiService.getYearlyColumns(data),
];

export const AAO_1_PRIKAZ_1_COLUMNS: IIzvestajiColumnProps<Aao1Prikaz1>[] = [
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'pib', title: 'Pib', width: '150px' },
  { dataIndex: 'maticni_broj', title: 'Matični broj', width: '300px' },
  { dataIndex: 'ulica', title: 'Ulica', width: '300px' },
  { dataIndex: 'kucni_broj', title: 'Kućni broj', width: '100px' },
  { dataIndex: 'opstina_naziv', title: 'Naziv opštine', width: '300px' },
  { dataIndex: 'vrsta_ambalaze', title: 'Vrsta ambalaže', width: '150px' },
  { dataIndex: 'ukupno_nepovratne_ambalaze', title: 'Ukupno nepovratne ambalaže', width: '150px' },
  { dataIndex: 'ukupno_povratne_ambalaze', title: 'Ukupno povratne ambalaže', width: '150px' },
  { dataIndex: 'operater1', title: 'Operater 1', width: '300px' },
  { dataIndex: 'operater2', title: 'Operater 2', width: '300px' },
  { dataIndex: 'kreirano', title: 'Kreirano', width: '200px' },
  { dataIndex: 'azurirano', title: 'Ažurirano', width: '200px' },
];

export const AAO_1_SVI_PODACI_COLUMNS: IIzvestajiColumnProps<Aao1SviPodaci>[] = [
  { dataIndex: 'godina', title: 'Godina', width: '150px' },
  { dataIndex: 'pib', title: 'Pib', width: '150px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'proizvodjac_ambalaze', title: 'Proizvođač ambalaže', width: '300px' },
  { dataIndex: 'paker_proizvoda_u_ambalazu', title: 'Paker proizvoda u ambalaži', width: '300px' },
  { dataIndex: 'isporucilac_ambalaze', title: 'Isporučilac ambalaže', width: '150px' },
  { dataIndex: 'izvozvnik_ambalaze', title: 'Izvoznik ambalaže', width: '300px' },
  { dataIndex: 'krajnji_korisnik_ambalaze', title: 'Krajnji korisnik ambalaže', width: '300px' },
  { dataIndex: 'oznaka_grupe', title: 'Oznaka grupe', width: '150px' },
  { dataIndex: 'oznaka_podgrupe', title: 'Oznaka podgrupe', width: '150px' },
  { dataIndex: 'vrsta_ambalaze', title: 'Vrsta ambalaže', width: '150px' },
  {
    dataIndex: 'nepovratna_ambalaza_proizvedena_kolicina',
    title: 'Nepovratna ambalaža - Proizvedena količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'nepovratna_ambalaza_uvezena_kolicina',
    title: 'Nepovratna ambalaža - Uvezena količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'nepovratna_amvalaza_izvezena_kolicina',
    title: 'Nepovratna ambalaža - Izvezena količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'nepovratna_ambalaza_ukupna_kolicina',
    title: 'Nepovratna ambalaža - Ukupna količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'povratna_ambalaza_proizvedena_kolicina',
    title: 'Povratna ambalaža - Proizvedena količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'povratna_ambalaza_uvezena_kolicina',
    title: 'Povratna ambalaža - Uvezena količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'povratna_ambalaza_izvezena_kolicina',
    title: 'Povratna ambalaža - Izvezena količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'povratna_ambalaza_ukupna_kolicina',
    title: 'Povratna ambalaža - Ukupna količina (t)',
    width: '200px',
  },
  {
    dataIndex: 'kolicina_ambalaze_preneta_na_operatere',
    title: 'Količina ambalaže preneta na operatere (t)',
    width: '320px',
  },
  {
    dataIndex: 'procenat_ambalaze_prenet_na_operatere',
    title: 'Procenat ambalaže prenet na operatere (%)',
    width: '320px',
  },
  { dataIndex: 'naziv_operatera1', title: 'Naziv operatera 1', width: '300px' },
  { dataIndex: 'broj_dozvole_operatera1', title: 'Broj dozvole operatera 1', width: '300px' },
  { dataIndex: 'naziv_operatera2', title: 'Naziv operatera 2', width: '300px' },
  { dataIndex: 'broj_dozvole_operatera2', title: 'Broj dozvole operatera 2', width: '300px' },
  {
    dataIndex: 'kolicina_ambalaze_na_samostalnom_upravljanju',
    title: 'Količina ambalaže na samostalnom upravljanju (t)',
    width: '300px',
  },
  {
    dataIndex: 'procenat_ambalaze_prenet_na_samostalno_upravljanje',
    title: 'Procenat ambalaže prenet na samostalno upravljanje (%)',
    width: '300px',
  },
];

export const AAO_1_NEDOSTAVLJENI_OBRASCI_COLUMNS: IIzvestajiColumnProps<Aao1NedostavljeniObrasci>[] = [
  { dataIndex: 'godina', title: 'Godina', width: '150px' },
  { dataIndex: 'pib_operatera', title: 'PIB operatera', width: '150px' },
  { dataIndex: 'naziv_operatera', title: 'Naziv operatera', width: '300px' },
  { dataIndex: 'pib', title: 'Pib', width: '150px' },
  { dataIndex: 'maticni_broj', title: 'Matični broj', width: '300px' },
  { dataIndex: 'preduzece', title: 'Preduzeće', width: '300px' },
  { dataIndex: 'adresa', title: 'Adresa', width: '300px' },
  { dataIndex: 'mesto', title: 'Mesto', width: '300px' },
  { dataIndex: 'postanski_broj', title: 'Poštanski broj', width: '300px' },
  { dataIndex: 'telefon', title: 'Telefon', width: '300px' },
  { dataIndex: 'email', title: 'E-mail', width: '300px' },
];

// Todo: figure out How to explicitly define types for dataIndex?
export const OBRAZAC_IZVESTAJI_COLUMN_MAP: {
  [key in IzvestajiSubtypes]: (data: any) => IIzvestajiColumnProps<any>[] | IIzvestajiColumnGroupProps<any>[];
} = {
  [Obrazac1IzvestajiEnum.GORIVA]: () => OBRAZAC_1_GORIVA_COLUMNS,
  [Obrazac1IzvestajiEnum.PROIZVODI]: () => OBRAZAC_1_PROIZVODI_COLUMNS,
  [Obrazac1IzvestajiEnum.SIROVINE]: () => [],
  [Obrazac1IzvestajiEnum.OPSTE]: () => [],
  [Obrazac2IzvestajiEnum.ZAGADLJIVE_MATERIJE]: () => [],
  [Obrazac2IzvestajiEnum.GORIVO]: () => [],
  [Obrazac2IzvestajiEnum.PREGLED_PO_GODINAMA]: () => [],
  [Obrazac2IzvestajiEnum.IZVORI_PO_GODINAMA]: () => [],
  [Obrazac2IzvestajiEnum.OPSTE]: () => [],
  [Obrazac3IzvestajiEnum.ISPUST_PO_GODINAMA]: data => OBRAZAC_3_ISPUST_PO_GODINAMA_COLUMNS(data),
  [Obrazac3IzvestajiEnum.ZAGADLJIVE_MATERIJE]: () => OBRAZAC_3_ZAGADLJIVE_MATERIJE_COLUMNS,
  [Obrazac3IzvestajiEnum.PREGLED_PO_GODINAMA]: data => OBRAZAC_3_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Obrazac3IzvestajiEnum.OPSTE]: () => OBRAZAC_3_OPSTE_COLUMNS,
  [Obrazac5IzvestajiEnum.POSTROJENJA]: () => OBRAZAC_5_POSTROJENJA_COLUMNS,
  [Obrazac5IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: () => OBRAZAC_5_POSTROJENJA_INDEKSNI_BROJ_COLUMNS,
  [Obrazac5IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: () => OBRAZAC_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS,
  [Obrazac5IzvestajiEnum.OTPAD_NE_OPASAN]: () => OBRAZAC_5_OTPAD_NE_OPASAN_COLUMNS,
  [Obrazac5IzvestajiEnum.GENERISANJE_OTPADA_PREGLED_PO_GODINAMA]: data =>
    OBRAZAC_5_GENERISANJE_OTPADA_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Obrazac5IzvestajiEnum.POSTROJENJA_ZA_PLACANJE]: () => OBRAZAC_5_POSTROJENJA_ZA_PLACANJE_COLUMNS,
  [Gio2IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: () => [],
  [Gio2IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: () => [],
  [Gio2IzvestajiEnum.ODLAGANJE_OTPADA_PREGLED_PO_GODINI]: () => [],
  [Gio2IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: () => [],
  [Gio3IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: () => [],
  [Gio3IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: () => [],
  [Gio3IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: () => [],
  [Gio3IzvestajiEnum.TRETMAN_OTPADA_PREGLED_PO_GODINI]: () => [],
  [Gio4IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: () => GIO_4_POSTROJENJA_INDEKSNI_BROJ_COLUMNS,
  [Gio4IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: () => GIO_4_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS,
  [Gio4IzvestajiEnum.ELEKTRICNI_I_ELEKTRONSKI_OTPAD]: () => GIO_4_ELEKTRICNI_I_ELEKTRONSKI_OTPAD_COLUMNS,
  [Gio4IzvestajiEnum.IZVOZ_OTPADA_PREGLED_PO_GODINI]: data => GIO_4_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Gio4IzvestajiEnum.KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI]: () => GIO_4_KOLICINA_IZVEZENOG_OTPADA_PO_VRSTI_OTPADA_COLUMNS,
  [Gio5IzvestajiEnum.POSTROJENJA_INDEKSNI_BROJ]: () => GIO_5_POSTROJENJA_INDEKSNI_BROJ_COLUMNS,
  [Gio5IzvestajiEnum.GRUPA_PODGRUPA_INDEKSNI_BROJ]: () => GIO_5_GRUPA_PODGRUPA_INDEKSNI_BROJ_COLUMNS,
  [Gio5IzvestajiEnum.UVOZ_OTPADA_PREGLED_PO_GODINI]: data => GIO_5_UVOZ_OTPADA_PREGLED_PO_GODINI_COLUMNS(data),
  [Gio5IzvestajiEnum.KOLICINA_UVEZENOG_OTPADA_PO_VRSTI]: () => GIO_5_KOLICINA_UVEZENOG_OTPADA_PO_VRSTI_COLUMNS,
  [Deo6IzvestajiEnum.UKUPNO_REUZET_OTPAD]: () => [],
  [Deo6IzvestajiEnum.PREUZET_OTPAD_POSTROJENJA_INDEKSNI_BROJ]: () => [],
  [Deo6IzvestajiEnum.UKUPNO_PREDAT_OTPAD]: () => [],
  [Deo6IzvestajiEnum.PREDAT_OTPAD_POSTROJENJA_INDEKSNI_BROJ]: () => [],
  [Deo6IzvestajiEnum.REKAPITULACIJA]: () => [],
  [Deo6IzvestajiEnum.REKALKULACIJA]: () => [],
  [Deo6IzvestajiEnum.PRIMOPREDAJA_OTPADA_IZMEDJU_PREDUZECA]: () => [],
  [Kom1IzvestajiEnum.SASTAV_KOMUNALNOG_OTPADA_PO_GODINAMA]: () => [],
  [Kom1IzvestajiEnum.KOLICINA_KOMUNALNOG_OTPADA_PO_GODINAMA]: () => [],
  [Kom1IzvestajiEnum.OBUHVAT_PRIKUPLJENOG_OTPADA_PO_GODINAMA]: () => [],
  [Kom1IzvestajiEnum.UPRAVLJANJE_KOMUNALNIM_OTPADOM]: () => [],
  [Ptp2IzvestajiEnum.OBRACUN_ZA_NAPLATU]: PTP_2_OBRACUN_ZA_NAPLATU_COLUMNS,
  [Ptp2IzvestajiEnum.VOZILA_UPOREDJIVANJE]: PTP_2_VOZILA_UPOREDJIVANJE_COLUMNS,
  [Ptp2IzvestajiEnum.NEDOSTAVLJENI_PODACI_O_VOZILIMA]: PTP_2_NEDOSTAVLJENI_PODACI_O_VOZILIMA_COLUMNS,
  [Ptp2IzvestajiEnum.UVOZ_GUMA_UPOREDJIVANJE]: PTP_2_UVOZ_GUMA_UPOREDJIVANJE_COLUMNS,
  [Ptp2IzvestajiEnum.NEDOSTAVLJENI_PODACI_O_GUMAMA]: PTP_2_NEDOSTAVLJENI_PODACI_O_GUMAMA_COLUMNS,
  [Aao1IzvestajiEnum.PREGLED_PO_GODINAMA]: data => AAO_1_PREGLED_PO_GODINAMA_COLUMNS(data),
  [Aao1IzvestajiEnum.PRIKAZ_1]: () => AAO_1_PRIKAZ_1_COLUMNS,
  [Aao1IzvestajiEnum.SVI_PODACI]: () => AAO_1_SVI_PODACI_COLUMNS,
  [Aao1IzvestajiEnum.NEDOSTAVLJENI_OBRASCI]: () => AAO_1_NEDOSTAVLJENI_OBRASCI_COLUMNS,
  [Dep1IzvestajiEnum.SVI_PODACI]: () => [],
  [Dep2IzvestajiEnum.SVI_PODACI]: () => [],
  [Aao2IzvestajiEnum.IZVESTAJ_FOR_BOOK]: () => [],
  [PzvIzvestajiEnum.KOLICINE_PROIZVODA_PO_PREDUZECU]: () => PZV_KOLICINE_PROIZVODA_PO_PREDUZECU_COLUMNS,
  [PzvIzvestajiEnum.KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU]: () => PZV_KOLICINE_PROIZVODA_PO_GRUPI_I_PREDUZECU_COLUMNS,
};
