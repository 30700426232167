import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NewGio3DozvoleForm } from './components/NewGio3DozvoleForm/NewGio3DozvoleForm';
import { GIO_3_DOZVOLE_INITIAL_STATE } from 'modules/obrasci/store/gio3/gio3.constants';
import { gio3ObrazacStore } from 'modules/obrasci/store/gio3/gio3.store';

export const Gio3DozvoleModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  return (
    <CRUDModal
      width="40%"
      name="gio3-dozvole-modal"
      title={t('WIZARD_TABS.DOZVOLE')}
      store={gio3ObrazacStore}
      data={gio3ObrazacStore.getDozvoleForm}
      children={() => <NewGio3DozvoleForm />}
      submitForm={gio3ObrazacStore.submitDozvoleForm}
      initialState={GIO_3_DOZVOLE_INITIAL_STATE}
      dataToChange={'dozvole_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    />
  );
});
