import { observer } from 'mobx-react-lite';
import { GveGorivaModal } from './components/GveGorivaModal/GveGorivaModal';
import { GveEmisijeBilansModal } from './components/GveEmisijeBilansModal/GveEmisijeBilansModal';
import { GveEmisijeMerenjaModal } from './components/GveEmisijeMerenjaModal/GveEmisijeMerenjaModal';
import { GveIzmereneVrednostiModal } from './components/GveIzmereneVrednostiModal/GveIzmereneVrednostiModal';

export const GveMicroServices: React.FC = observer(() => {
  return (
    <>
      <GveGorivaModal />
      <GveEmisijeBilansModal />
      <GveEmisijeMerenjaModal />
      <GveIzmereneVrednostiModal />
    </>
  );
});
