import { observer } from 'mobx-react-lite';
import styles from './Registri.module.scss';
import { Content } from 'components/Content/Content';
import { RegistriEnum } from 'modules/registri/registri.types';
import { useNavigate, useParams } from 'react-router-dom';
import { RegistriHeader } from './components/RegistriHeader/RegistriHeader';
import { isEnumValue } from 'utils/isEnumValue';
import { registriConfig } from 'modules/registri/registri.constants';
import { useEffect } from 'react';
import TableData from 'components/TableData/TableData';
import { RegistriFilters } from './components/RegistriFilters/RegistriFilters';
import { filtersStore } from 'store/filteri.store';
import { EditOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { Button } from 'components/Button/Button';

export const Registri = observer(() => {
  const navigate = useNavigate();

  const { registar } = useParams();

  const registarIsInEnum = !!registar && isEnumValue(registar, Object.values(RegistriEnum));

  useEffect(() => {
    if (!registarIsInEnum) navigate(registriConfig.otv.route);
  }, []);

  const validRegistar = registarIsInEnum ? registar : RegistriEnum.Otv;

  const renderRegistarTable = () => {
    const tableName = `${registriConfig[validRegistar].key}-table`;

    const { columns, fetchData, dataSource } = registriConfig[validRegistar].tableData;

    const baseColumns: ColumnType<any>[] = [
      ...columns,
      {
        align: 'center',
        width: '100px',
        key: 'registar-edit-btn',
        render(_, record) {
          if (!record.id) return;
          return (
            <Button
              icon={<EditOutlined rev={undefined} />}
              name={'registar-edit-btn'}
              onClick={() => navigate(`${registriConfig[validRegistar].route}/${record.id}`)}
            />
          );
        },
      },
    ];

    return (
      <div className="wrapper">
        <div id="registriFilters">
          <RegistriFilters registar={validRegistar} />
        </div>
        <TableData
          scrollConfig={['#registriHeader', '#registriFilters']}
          name={tableName}
          columns={baseColumns}
          dataSource={dataSource}
          withPagination
          getData={fetchData}
          forceFetch={[filtersStore.filters.registri, validRegistar]}
          className={styles.registarTable}
        />
      </div>
    );
  };

  return <Content header={<RegistriHeader registar={validRegistar} />}>{renderRegistarTable()}</Content>;
});
