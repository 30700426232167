import { Form, Modal, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { modalStore } from 'store';
import { ModalFormComponent } from '../ModalFormComponent/ModalFormComponent';
import { StoreValue } from 'antd/es/form/interface';
import { Button } from 'components/Button/Button';
import styles from './FormTableModal.module.scss';
import { FormListConfig } from 'typescript/NrizTypes';

interface FormTableModalProps<T> {
  name: string;
  addRow: (defaultValue?: StoreValue, insertIndex?: number) => void;
  removeRow: (index: number | number[]) => void;
  selectedIndex: number;
  configPath: FormListConfig;
  modalWidth: number | string;
  somethingThatWillUseThisTypeSomeday?: T;
  title?: string;
}

export const FormTableModal = observer(
  // eslint-disable-next-line
  <T extends object>({
    removeRow,
    configPath,
    name,
    selectedIndex,
    addRow,
    modalWidth,
    title,
  }: FormTableModalProps<T>) => {
    const form = Form.useFormInstance();

    const [itemForm] = Form.useForm();

    const formList = form.getFieldValue([name]);

    const isCreateMode = selectedIndex === formList.length;

    const selectedItem = isCreateMode ? null : formList[selectedIndex];

    useEffect(() => {
      itemForm.setFieldsValue(selectedItem);
    });
    // ERROR: Warning: Instance created by `useForm` is not connect to any Form element. Forget to pass `form` prop?
    // this happens because a form method is called before the Form instance is patched by it. todo: find a solution

    const handleFormItemSubmit = () => {
      const values = { ...itemForm.getFieldsValue(true), key: selectedIndex };
      isCreateMode ? addRow(values) : form.setFieldValue([name, selectedIndex], values);
      modalStore.clearModal(`${name}-modal`);
    };

    const handleDelete = () => {
      removeRow(selectedIndex);
      modalStore.clearModal(`${name}-modal`);
    };

    return (
      <Modal
        open={modalStore.getCurrentOpenedModal.includes(`${name}-modal`)}
        destroyOnClose
        onCancel={() => modalStore.clearModal(`${name}-modal`)}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        width={modalWidth}
        className={styles.formModal}
        modalRender={formChildren => {
          return (
            <Form
              form={itemForm}
              name={`${name}-form-in-modal`}
              onFinish={handleFormItemSubmit}
              preserve={false}
              labelAlign="left"
            >
              {formChildren}
            </Form>
          );
        }}
      >
        {title && <h3 className={styles.formModalTitle}>{title}</h3>}
        <ModalFormComponent<T>
          configPath={configPath}
          formListName={name}
          parentForm={form}
          selectedIndex={selectedIndex}
        />
        <Row className={styles.formTableButtons}>
          <Button name={`${name}-modal-delete-button`} onClick={handleDelete} className="ant-btn-dangerous">
            Obriši
          </Button>
          <Button name={`${name}-modal-submit-button`} htmlType="submit">
            {isCreateMode ? 'Sačuvaj' : 'Izmeni'}
          </Button>
        </Row>
      </Modal>
    );
  }
);
