import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { THE_ORDER_FORM, NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, NAME_SR_TABLE, THE_ORDER_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IGetWayOfHandOver, IPostWayOfHandOver } from './wayOfHandOver.types';

export const WAY_OF_HAND_OVER_INITIAL_STATE: IPostWayOfHandOver = {
  name: '',
  name_sr: '',
  the_order: '',
  is_active: true,
};

export const WAY_OF_HAND_OVER_TABLE_DATA: ColumnsType<IGetWayOfHandOver> = [NAME_TABLE, NAME_SR_TABLE, THE_ORDER_TABLE];
export const WAY_OF_HAND_OVER_FORM_OPTIONS = (_: FormInstance<IGetWayOfHandOver>) => {
  return [THE_ORDER_FORM, NAME_FORM, NAME_SR_FORM];
};
