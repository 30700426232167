import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NewAao2KolicinaAmbalazeForm } from './components/NewAao2KolicinaAmbalazeForm';
import { AAO2_KOLICINA_AMBALAZA_INITIAL_STATE } from 'modules/obrasci/store/aao2/aao2.constants';
import { aao2Store } from 'modules/obrasci/store/aao2/aao2.store';

export const Aao2KolicinaAmbalazeModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="50%"
      name="aao2-kolicina-ambalaze-modal"
      title={t('OBRASCI.AAO1.PACKAGING_AMOUNT.TITLE')}
      store={aao2Store}
      data={aao2Store.getKolicinaAmbalaze}
      children={({ form }) => <NewAao2KolicinaAmbalazeForm form={form} />}
      submitForm={aao2Store.submitKolicinaAmbalaze}
      initialState={AAO2_KOLICINA_AMBALAZA_INITIAL_STATE}
      dataToChange={'kolicinaAmbalaze'}
    />
  );
});
