import { ColumnsType } from 'antd/es/table';
import { IGetConfidentiality, IPostConfidentiality } from './confidentiality.types';
import { FormInstance } from 'antd';
import { CODE_TABLE, DESCRIPTION_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { CODE_FORM, DESCRIPTION_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';

export const CONFIDENTIALITY_INITIAL_STATE: IPostConfidentiality = {
  code: '',
  description: '',
  is_active: true,
};
export const CONFIDENTIALITY_TABLE_DATA: ColumnsType<IGetConfidentiality> = [CODE_TABLE, DESCRIPTION_TABLE];
export const CONFIDENTIALITY_FORM_OPTIONS = (_: FormInstance<IGetConfidentiality>) => {
  return [CODE_FORM, DESCRIPTION_FORM];
};
