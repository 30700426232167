import { Checkbox, Form } from 'antd';
import { FormCheckboxProps } from 'typescript';

export const FormCheckbox: React.FC<FormCheckboxProps> = props => {
  return (
    <Form.Item
      label={props.label}
      labelCol={props.labelCol}
      wrapperCol={props.wrapperCol}
      name={props.name}
      valuePropName="checked"
      rules={props.rules}
      style={props.style}
      className={props.className}
      colon={props.colon}
      hidden={props.hidden}
    >
      <Checkbox
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        disabled={props.disabled}
        onChange={(e: any) => props.onCheckBoxChange && props.onCheckBoxChange(e)}
      />
    </Form.Item>
  );
};
