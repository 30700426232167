import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, NAME_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IPostWasteWaterTreatmentMethod, IGetWasteWaterTreatmentMethod } from './wasteWaterTreatmentMethod.types';

export const WASTE_WATER_TREATMENT_METHOD_INITIAL_STATE: IPostWasteWaterTreatmentMethod = {
  name: '',
  name_sr: '',
  is_active: true,
};

export const WASTE_WATER_TREATMENT_METHOD_TABLE_DATA: ColumnsType<IGetWasteWaterTreatmentMethod> = [
  NAME_TABLE,
  NAME_SR_TABLE,
];

export const WASTE_WATER_TREATMENT_METHOD_FORM_OPTIONS = (_: FormInstance<IGetWasteWaterTreatmentMethod>) => {
  return [NAME_FORM, NAME_SR_FORM];
};
