import { ApiResponse, axios } from 'modules/axios';
import { ITipObrasca } from 'modules/obrasci/obrasci.types';
import { OBRAZAC_TYPE_IZVESTAJ_API_URL_MAP } from './izvestaji.constants';
import queryString from 'query-string';
import { ROUTES } from 'modules/nriz/nriz.constants';

const fetchPresek = (): ApiResponse<{ items: ITipObrasca[] }> => {
  return axios.get(`${ROUTES.TIP_OBRASCA}/`);
};

const fetchIzvestaji = <T>(type: string, filters: any): ApiResponse<T> => {
  const query = queryString.stringify(filters);
  const typeRoute = OBRAZAC_TYPE_IZVESTAJ_API_URL_MAP[type];
  return axios.get(`${ROUTES.OBRAZAC}/${typeRoute}/prijava/?${query}`);
};

const generisiIzvestaj = (type: string) => {
  const typeRoute = OBRAZAC_TYPE_IZVESTAJ_API_URL_MAP[type];
  return axios.post(`${ROUTES.OBRAZAC}/${typeRoute}/prijava/`);
};

// todo prepakovati nizove id-eva u objekat { ids: [id1,id2...]}

const iskljuciIzvestaje = (type: string, payload: any): ApiResponse<any> => {
  const typeRoute = OBRAZAC_TYPE_IZVESTAJ_API_URL_MAP[type];
  return axios.post(`${ROUTES.OBRAZAC}/${typeRoute}/prijava/iskljuci/`, payload);
};

const posaljiIzvestaje = (type: string): ApiResponse<any> => {
  const typeRoute = OBRAZAC_TYPE_IZVESTAJ_API_URL_MAP[type];
  return axios.post(`${ROUTES.OBRAZAC}/${typeRoute}/prijava/posalji/`);
};

export const izvestajiRepo = {
  fetchPresek,
  iskljuciIzvestaje,
  posaljiIzvestaje,
  fetchIzvestaji,
  generisiIzvestaj,
};
