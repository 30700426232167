import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { IFormScrollWithObjProps, IPagination } from 'typescript';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import styles from './FormScrollWithObjInside.module.scss';
import { makeLabel, map4selectNew } from 'utils/map4select';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { isEmpty } from 'lodash';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';

export const FormScrollWithObjInside: React.FC<IFormScrollWithObjProps> = props => {
  const [options, setOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<IPagination>(paginationInitialState);
  const [search, setSearch] = useState<string>('');
  const [allowClear, setAllowClear] = useState<boolean>(false);

  const { t } = useTranslation();

  const form = Form.useFormInstance();
  const allValuesForm = form.getFieldsValue(true);
  const keyNameForObject = props.objName || (props.formName as string).split('_id')[0];
  const objectForDataFromForm = allValuesForm[keyNameForObject];

  const resetFunc = () => {
    form.setFieldValue(keyNameForObject, undefined);
    setOptions([]);
    setPagination(paginationInitialState);
    setSearch('');
  };
  useEffect(() => {
    objectForDataFromForm
      ? setOptions([
          {
            label: makeLabel(
              props.labelOptionAccessor,
              !props.nestedObjKey ? objectForDataFromForm : objectForDataFromForm[props.nestedObjKey]
            ),
            value: objectForDataFromForm[props.valueOptionAccessor],
            item: objectForDataFromForm,
          },
        ])
      : resetFunc();
  }, [objectForDataFromForm]);

  useEffect(() => {
    if (props.resetOption) {
      resetFunc();
    }
  }, [props.resetOption]);

  const loadMoreOptions = useDebouncedCallback(async () => {
    setLoading(true);

    const response = await sifarniciStore.fetchSifarnikOptionData(props.fetchOptinsList, {
      ...pagination,
      search: search,
      ...props.filtersForFetch,
    });

    if (isEmpty(response)) {
      setLoading(false);
      return;
    }

    const selectOptions = map4selectNew({
      valueAccessor: props.valueOptionAccessor,
      labelAccessor: props.labelOptionAccessor,
      sourceArray: response.items,
      disabledAccessor: props.disabledAccessor,
      nestedObjKey: props.nestedObjKey,
    });

    const indexToRemove = selectOptions.findIndex(option => option.value === form.getFieldValue(props.formName));

    if (indexToRemove !== -1) {
      selectOptions.splice(indexToRemove, 1);
    }
    setPagination({
      // dodato zbog inkrementa paginacije
      page: response.page === response.total ? response.total : response.page + 1,
      size: response.size,
      total: response.total,
      pages: response.pages,
    });

    setOptions([...options, ...selectOptions]);

    setLoading(false);
  }, 500);

  const handleSearch = (value: string) => {
    setSearch(value);
    setPagination(paginationInitialState);
    setOptions([]);
    loadMoreOptions();
  };

  useEffect(() => {
    if (form.getFieldValue(props.formName)) setAllowClear(true);
    else {
      setAllowClear(false);
      form.setFieldValue(keyNameForObject, null);
    }
  }, [Form.useWatch(props.formName, form)]);

  const handleScroll = (e: any) => {
    e.persist();
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const heightDifference = clientHeight - (scrollHeight - scrollTop);

    if (Math.abs(heightDifference) < 10) {
      if (pagination.page > pagination.pages) return;
      loadMoreOptions();
    }
  };

  const handleLoadDataOnFocus = () => {
    setLoading(true);
    if (options.length <= 1) {
      loadMoreOptions();
    } else setLoading(false);
  };

  return (
    <Form.Item
      name={props.formName}
      label={props.label}
      rules={props.rules}
      labelCol={props.labelCol}
      wrapperCol={props.wrapperCol}
      colon={props.colon}
      validateTrigger={props.validateTrigger ? props.validateTrigger : 'onChange'}
    >
      <Select
        showSearch={true}
        placeholder={props.placeholder}
        onPopupScroll={handleScroll}
        onSearch={handleSearch}
        filterOption={false}
        // loading={loading}
        notFoundContent={loading ? <></> : props.notFoundContent ? props.notFoundContent : t('NO_OPTIONS_FOUND')}
        className={props.readOnly ? styles.readOnly : ''}
        onChange={(value: string, option: any) => {
          if (!value) {
            form.setFieldValue(keyNameForObject, null);
          } else {
            form.setFieldValue(keyNameForObject, option.item);
          }
          props.onChange && props.onChange(value, option ?? null);
        }}
        disabled={props.disabled}
        onClear={() => {
          form.setFieldValue(keyNameForObject, null);
          setOptions([]);
          setPagination(paginationInitialState);
          handleLoadDataOnFocus();
        }}
        options={options}
        onFocus={() => handleLoadDataOnFocus()}
        allowClear={allowClear}
      />
    </Form.Item>
  );
};
