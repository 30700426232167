import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { DeterdzentiTable } from 'pages/ObrazacWizard/components/pzv1/components/DeterdzentiWizardTab/components/DeterdzentiTable/DeterdzentiTable';
import { DeterdzentiHeader } from './components/DeterdzentiHeader/DetetrdzentiHeader';

export const DeterdzentiWizardTab = () => {
  // useEffect(()=> {

  // }, [])

  return (
    <InsideContentWrapper header={<DeterdzentiHeader />}>
      <DeterdzentiTable />
    </InsideContentWrapper>
  );
};
