import { Col } from 'antd';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { IGetNaselja } from 'modules/obrasci/store/kom1/kom1.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formWatchArray } from 'utils/formWatchArray';

export const NewNaseljeForm: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();
  const [naseljeUkupno, setNaseljeUkupno] = useState(false);
  const [prikupljenoUkupno, setPrikupljenoUkupno] = useState(false);

  const defaultMesto: ISifarnikInitial | undefined = kom1ObrazacStore.getNaseljeForm.mesto_id
    ? {
        label: (kom1ObrazacStore.getNaseljeForm as IGetNaselja).mesto.display_name,
        value: (kom1ObrazacStore.getNaseljeForm as IGetNaselja).mesto_id,
      }
    : undefined;

  useEffect(() => {
    if (Number(obrasciStore.opste.godina) >= 2024) {
      const sum =
        props.form.getFieldValue('broj_domacinstava_u_naselju_individulni_tip_stanovanja') +
        props.form.getFieldValue('broj_domacinstava_u_naselju_kolektivni_tip_stanovanja');
      props.form.setFieldValue('ukupan_broj_domacinstava_u_naselju', sum);
      setNaseljeUkupno(!sum);
    }
  }, [
    formWatchArray([
      'broj_domacinstava_u_naselju_individulni_tip_stanovanja',
      'broj_domacinstava_u_naselju_kolektivni_tip_stanovanja',
    ]),
  ]);
  useEffect(() => {
    if (Number(obrasciStore.opste.godina) >= 2024) {
      const sum =
        props.form.getFieldValue('broj_domacinstava_obuhvacen_prikupljanjem_otpada_individulni_tip_stanovanja') +
        props.form.getFieldValue('broj_domacinstava_obuhvacen_prikupljanjem_otpada_kolektivni_tip_stanovanja');

      props.form.setFieldValue('broj_domacinstava_obuhvacenih_prikupljanjem_otpada', sum);
      setPrikupljenoUkupno(!sum);
    }
  }, [
    formWatchArray([
      'broj_domacinstava_obuhvacen_prikupljanjem_otpada_individulni_tip_stanovanja',
      'broj_domacinstava_obuhvacen_prikupljanjem_otpada_kolektivni_tip_stanovanja',
    ]),
  ]);

  return (
    <Col span={24}>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'mesto_id'}
        fetchOptinsList={kom1ObrazacStore.fetchMesto}
        labelOptionAccessor="display_name"
        valueOptionAccessor="id"
        wait={kom1ObrazacStore.opstina_id === ''}
        rules={[
          REQUIRED_FIELD_RULE(true),
          {
            validator(_, value) {
              if (
                kom1ObrazacStore.getNaseljeForm.mesto_id !== value &&
                kom1ObrazacStore.initialState.naselja.some(item => {
                  return value === item.mesto_id;
                })
              ) {
                return Promise.reject(new Error(t('OBRASCI.KOM_1.VALIDATION.ISTO_NASELJE')));
              }
              return Promise.resolve();
            },
          },
        ]}
        label={t('OBRASCI.DEP_2.SETTLEMENT')}
        inputName={'mesto_id'}
        colon={true}
        defaultOption={defaultMesto}
        placeholder={''}
      />
      {Number(obrasciStore.opste.godina) >= 2024 && (
        <>
          <FormInputNumber
            name="broj_domacinstava_u_naselju_individulni_tip_stanovanja"
            label={t('Broj domaćinstava u naselju - individualni tip stanovanja')}
            rules={[REQUIRED_FIELD_RULE(naseljeUkupno), ...MORE_THEN_0]}
            placeholder={''}
            inputName={'broj_domacinstava_u_naselju_individulni_tip_stanovanja'}
            colon={true}
            precision={0}
          />

          <FormInputNumber
            name="broj_domacinstava_u_naselju_kolektivni_tip_stanovanja"
            label={t('Broj domaćinstava u naselju - kolektivni tip stanovanja')}
            rules={[REQUIRED_FIELD_RULE(naseljeUkupno), ...MORE_THEN_0]}
            placeholder={''}
            inputName={'broj_domacinstava_u_naselju_kolektivni_tip_stanovanja'}
            colon={true}
            precision={0}
          />
        </>
      )}

      <FormInputNumber
        name="ukupan_broj_domacinstava_u_naselju"
        label={t('OBRASCI.KOM_1.TOTAL_NUMBER_OF_HOUSEHOLDS_IN_SETTLEMENT')}
        rules={[REQUIRED_FIELD_RULE(Number(obrasciStore.opste.godina) < 2024)]}
        disabled={Number(obrasciStore.opste.godina) >= 2024}
        placeholder={''}
        inputName={'ukupan_broj_domacinstava_u_naselju'}
        colon={true}
        precision={0}
      />

      {Number(obrasciStore.opste.godina) >= 2024 && (
        // todo: prevod
        <>
          <FormInputNumber
            name="broj_domacinstava_obuhvacen_prikupljanjem_otpada_individulni_tip_stanovanja"
            label={t('Broj domaćinstava obuhvaćen prikupljanjem otpada - individualni tip stanovanja')}
            rules={[REQUIRED_FIELD_RULE(prikupljenoUkupno), ...MORE_THEN_0]}
            placeholder={''}
            inputName={'broj_domacinstava_obuhvacen_prikupljanjem_otpada_individulni_tip_stanovanja'}
            colon={true}
            precision={0}
          />

          <FormInputNumber
            name="broj_domacinstava_obuhvacen_prikupljanjem_otpada_kolektivni_tip_stanovanja"
            label={t('Broj domaćinstava obuhvaćen prikupljanjem otpada - kolektivni tip stanovanja')}
            rules={[REQUIRED_FIELD_RULE(prikupljenoUkupno), ...MORE_THEN_0]}
            placeholder={''}
            inputName={'broj_domacinstava_obuhvacen_prikupljanjem_otpada_kolektivni_tip_stanovanja'}
            colon={true}
            precision={0}
          />
        </>
      )}

      <FormInputNumber
        name="broj_domacinstava_obuhvacenih_prikupljanjem_otpada"
        label={t('OBRASCI.KOM_1.NUMBER_OF_HOUSEHOLDS_COVERED_BY_WASTE_COLLECTION')}
        rules={[REQUIRED_FIELD_RULE(Number(obrasciStore.opste.godina) < 2024)]}
        placeholder={''}
        inputName={'broj_domacinstava_obuhvacenih_prikupljanjem_otpada'}
        colon={true}
        precision={0}
        disabled={Number(obrasciStore.opste.godina) >= 2024}
      />
    </Col>
  );
});
