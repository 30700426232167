import {
  IPostObrazac3,
  IPostObrazac3AnalizaOtpadneVode,
  IPostObrazac3AnalizaRecipijenata,
  IPostObrazac3GlavniIndustrijskiPotrosaciVode,
  IPostObrazac3IzmereneVrednostiOtpadneVode,
  IPostObrazac3IzmereneVrednostiRecipijenta,
  IPostObrazac3MerenjeOtpadneVode,
  IPostObrazac3MerenjeRecipijenta,
} from './obrazac3.types';

export const OBRAZAC_3_INITIAL_STATE: IPostObrazac3 = {
  godina: '',
  preduzece_id: '',
  postrojenje_id: '',
  obrazac_3: false,
  lriz_obrazac_3: false,
  izvor_zagadjenja_vode_id: '',
  radni_rezim_ispusta_id: '',
  vremenski_period_ispustanja: '',
  ukupna_kolicina_ispustene_otpadne_vode_u_izvestajnoj_godini_na_ispustu: '',
  ukupan_kapacitet_proizvodnje_t_god: '',
  nacin_odredjivanja_kolicine_ispustene_vode: '',
  vodovodni_sistem: false,
  kanalizacioni_sistem: false,
  ukupna_povrsina_teritorije_opstine: '',
  procenat_ukupnog_stanovnistva_prikljucenog_na_vodovod: '',
  kolicina_proizvedene_vode: '',
  ukupna_kolicina_isporucene_vode_svim_potrosacima: '',
  ukupna_kolicina_isporucene_vode_stanovnistvu: '',
  ukupna_kolicina_isporucene_vode_industriji_i_drugim_institucijama: '',
  procenat_ukupnog_stanovnistva_prikacenog_na_kanalizaciju: '',
  da_li_se_vrse_merenja_kolicina_otpadnih_voda_na_kanalizacionom_sistemu: '',
  na_sistemu: '',
  na_izlivu: '',
  napomena: '',
  kreiranje_godisnjeg_bilansa: null,
  analiza_otpadne_vode: [],
  analiza_recipijenta: [],
  merenja_otpadne_vode: [],
  merenja_recipijenta: [],
  glavni_industrijski_potrosaci_vode: [],
  godisnji_bilans: [],
  otv: false,
};

// bilans i vode imaju isti tip i state

export const OBRAZAC_3_OTPADNE_VODE_INITIAL_STATE: IPostObrazac3AnalizaOtpadneVode = {
  naziv_zagadjujuce_materije_id: '',
  srednja_godisnja_izmerena_vrednost: '',
  emitovane_kolicine: '',
  nacin_odredjivanja_id: '',
  metod_odredjivanja_id: '',
  emitovane_kolicine_u_akcidentnim_situacijama: '',
};

export const OBRAZAC_3_OTPADNE_VODE_MERENJE_INITIAL_STATE: IPostObrazac3MerenjeOtpadneVode = {
  datum_merenja: '',
  indetifikacioni_broj_izvestaja: '',
  naziv_strucne_laboratorije_id: '',
  izmerene_vrednosti_otpadne_vode: [],
};

export const OBRAZAC_3_IZMERENE_VREDNOSTI_OTPADNE_VODE_INITIAL_STATE: IPostObrazac3IzmereneVrednostiOtpadneVode = {
  naziv_zagadjujuce_materije_id: '',
  jedinica_mere_id: '',
  izmerene_vrednosti: '',
  predznak_izmerene_vrednosti: '',
  metod_odredjivanja_id: '',
  emitovana_kolicina_u_akcidentnoj_situaciji: '',
};

export const OBRAZAC_3_RECIPIJENT_MERENJE_INITIAL_STATE: IPostObrazac3MerenjeRecipijenta = {
  datum_merenja: '',
  indetifikacioni_broj_izvestaja: '',
  naziv_strucne_laboratorije_id: '',
  izmerene_vrednosti_recipijenta: [],
};

export const OBRAZAC_3_IZMERENE_VREDNOSTI_RECIPIJENTA_INITIAL_STATE: IPostObrazac3IzmereneVrednostiRecipijenta = {
  naziv_zagadjujuce_materije_id: '',
  jedinica_mere_id: '',
  izmerene_vrednosti_pre_ispusta: '',
  predznak_izmerene_vrednosti_pre_ispusta: '',
  izmerene_vrednosti_posle_ispusta: '',
  predznak_izmerene_vrednosti_posle_ispusta: '',
  metod_odredjivanja_id: '',
};

export const OBRAZAC_3_ANALIZA_RECIPIJENATA_INITIAL_STATE: IPostObrazac3AnalizaRecipijenata = {
  naziv_zagadjujuce_materije_id: '',
  jedinica_mere_id: '',
  pre_ispusta_otpadne_vode: '',
  posle_ispusta_otpadne_vode: '',
  metod_odredjivanja_id: '',
  nacin_odredjivanja_id: '',
};

export const OBRAZAC_3_GLAVNI_INDUSTRIJSKI_POTROSACI_INITIAL_STATE: IPostObrazac3GlavniIndustrijskiPotrosaciVode = {
  naziv: '',
  sifra_pretezne_delatnosti_id: '',
  kolicina_isporucene_vode: '',
};

export const VODOVODNI_RESET: (keyof IPostObrazac3)[] = [
  'vodovodni_sistem',
  'ukupna_povrsina_teritorije_opstine',
  'procenat_ukupnog_stanovnistva_prikljucenog_na_vodovod',
  'kolicina_proizvedene_vode',
  'ukupna_kolicina_isporucene_vode_svim_potrosacima',
  'ukupna_kolicina_isporucene_vode_stanovnistvu',
  'ukupna_kolicina_isporucene_vode_industriji_i_drugim_institucijama',
];

export const KANALIZACIONI_RESET: (keyof IPostObrazac3)[] = [
  'kanalizacioni_sistem',
  'procenat_ukupnog_stanovnistva_prikacenog_na_kanalizaciju',
  'da_li_se_vrse_merenja_kolicina_otpadnih_voda_na_kanalizacionom_sistemu',
  'na_sistemu',
  'na_izlivu',
];

export const OBRAZAC_3_FIELDS_TO_RESET: (keyof IPostObrazac3)[] = [
  'radni_rezim_ispusta_id',
  'vremenski_period_ispustanja',
  'ukupna_kolicina_ispustene_otpadne_vode_u_izvestajnoj_godini_na_ispustu',
  'nacin_odredjivanja_kolicine_ispustene_vode',
  'ukupan_kapacitet_proizvodnje_t_god',
];
