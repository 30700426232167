import { ColumnsType } from 'antd/es/table';
import { IGetPollutantCode, IPostPollutantCode } from './pollutantCode.types';
import {
  NAME_FORM,
  NAME_SR_FORM,
  TITLE_FORM,
  DESCRIPTION_FORM,
  VERSION_FORM,
  CODE_FORM,
  VALIDITY_FROM_DATE_FORM,
  VALIDITY_TO_DATE_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';
import {
  NAME_TABLE,
  NAME_SR_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  VALIDITY_FROM_DATE_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';

export const POLLUTANT_CODE_INITIAL_STATE: IPostPollutantCode = {
  name: '',
  name_sr: '',
  validity_from_date: '',
  validity_to_date: '',
  title: '',
  description: '',
  version: null,
  code: '',
  group_code: false,
  parent_group_id: '',
  cas: '',
  is_active: true,
};
export const POLLUTANT_CODE_TABLE_DATA: ColumnsType<IGetPollutantCode> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  VALIDITY_FROM_DATE_TABLE,
  VALIDITY_TO_DATE_TABLE,
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,
];
export const POLLUTANT_CODE_FORM_OPTIONS = (form: FormInstance<IGetPollutantCode>) => {
  return [
    NAME_FORM,
    NAME_SR_FORM,
    VALIDITY_FROM_DATE_FORM(form),
    VALIDITY_TO_DATE_FORM(form),
    TITLE_FORM,
    DESCRIPTION_FORM,
    VERSION_FORM,
    CODE_FORM,
  ];
};
