import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { registriConfig } from 'modules/registri/registri.constants';
import { RegistarKeyProps } from 'modules/registri/registri.types';
import { useEffect, useMemo } from 'react';
import { IFilterTableProps } from 'typescript/NrizTypes';

export const RegistriFilters: React.FC<RegistarKeyProps> = observer(({ registar }) => {
  const [registriFiltersForm] = useForm();

  const { components, initialState } = registriConfig[registar].filters;

  useEffect(() => {
    registriFiltersForm.setFieldsValue(initialState);
  }, [registar]);

  const filterComponents = useMemo(() => {
    return components;
  }, [Form.useWatch([], registriFiltersForm)]);

  const filterData: IFilterTableProps = {
    filterName: 'registri',
    form: registriFiltersForm,
    initialState: initialState,
    filterOptions: filterComponents(registriFiltersForm),
  };

  return <FilterTableForm data={filterData} />;
});
