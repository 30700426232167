import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { IGetObrazac3GodisnjiBilans } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Obrazac3GodisnjiBilansTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetObrazac3GodisnjiBilans> = [
    {
      title: `${t('OBRASCI.OBRAZAC3.NAZIV_ZAGADJUJUCE_MATERIJE')}`,
      width: 200,
      dataIndex: ['naziv_zagadjujuce_materije', 'name_sr'],
      key: 'naziv_zagadjujuce_materije',
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.JEDINICA_MERE')}`,
      width: 200,
      dataIndex: ['jedinica_mere', 'code'],
      key: 'jedinica_mere',
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.SREDNJA_GODISNJA_IZMERENA')}`,
      dataIndex: 'srednja_godisnja_izmerena_vrednost',
      key: 'srednja_godisnja_izmerena_vrednost',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.EMITOVANE_REDOVNI')}`,
      dataIndex: 'emitovana_kolicina_pri_redovnom_radu',
      key: 'emitovana_kolicina_pri_redovnom_radu',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.EMITOVANE_AKCIDENTNE_GOD')}`,
      dataIndex: 'emitovana_kolicina_u_akcidentnoj_situaciji',
      key: 'emitovana_kolicina_u_akcidentnoj_situaciji',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.NACIN_ODREDJIVANJA')}`,
      render: () => {
        return t('OBRASCI.GVE.MERENJE');
      },
      key: 'nacin_odredjivanja',
      width: 200,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.METOD_ODREDJIVANJA')}`,
      dataIndex: ['metod_odredjivanja', 'name_sr'],
      key: 'metod_odredjivanja',
      width: 200,
    },
  ];
  return (
    <TableData
      name="godisnji-bilans-obrazac3"
      columns={baseColumns}
      dataSource={[...obrazac3Store.initialState.godisnji_bilans]}
    />
  );
});
