import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TableData from 'components/TableData/TableData';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { IGetObrazac3MerenjeRecipijenta } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const Obrazac3AnalizaRecipijenataMerenjeTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetObrazac3MerenjeRecipijenta> = [
    {
      title: `${t('OBRASCI.GVE.REDNI_BROJ_MERENJA')}`,
      width: 100,
      render: (_, _record, i) => obrazac3Store.initialState.merenja_recipijenta.length - i,
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.DATUM_MERENJA')}`,
      width: 200,
      dataIndex: 'datum_merenja',
      key: 'datum_merenja',
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.IDENTIFIKACIONI_BROJ_IZVESTAJA')}`,
      width: 200,
      dataIndex: 'indetifikacioni_broj_izvestaja',
      key: 'indetifikacioni_broj_izvestaja',
    },
    {
      title: `${t('OBRASCI.OBRAZAC3.NAZIV_LABORATORIJE')}`,
      dataIndex: ['naziv_strucne_laboratorije', 'name_of_laboratory'],
      key: 'naziv_strucne_laboratorije_id',
      width: 200,
    },
    {
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetObrazac3MerenjeRecipijenta) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-komponente'}
            onClick={() => {
              obrazac3Store.handleChange('recipijent_merenje_form', {
                ...record,
                datum_merenja: dayjs(record.datum_merenja),
              });
              modalStore.changeModalName('obrazac3-analiza-recipijenta-merenje-modal');
            }}
          />
        );
      },
    },
  ];
  return (
    <TableData
      name="analiza-recepijenata-merenje-obrazac3"
      columns={baseColumns}
      dataSource={[...obrazac3Store.initialState.merenja_recipijenta]}
    />
  );
  // resi store
});
