import { ColumnsType } from 'antd/es/table';
import { IPostProductsType, IGetProductsType } from './productsType.types';
import { t } from 'i18next';
import { FormInstance } from 'antd';
import { MAX_LENGTH_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import {
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  SelectSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { DESCRIPTION_TABLE, NAME_TABLE, THE_ORDER_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { DESCRIPTION_FORM, NAME_FORM, THE_ORDER_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { productTypeService } from './productsType.service';

export const PRODUCTS_TYPE_INITIAL_STATE: IPostProductsType = {
  products_nr_id: '',
  name: '',
  description: '',
  unit: '',
  priceor_percentageof_pdv: '',
  currencyor: '',
  p_class: '',
  display_product: '',
  is_active: true,
};

export const PRODUCTS_TYPE_TABLE_DATA: ColumnsType<IGetProductsType> = [
  NAME_TABLE,
  {
    title: `${t('SIFARNICI.PROIZVOD')}`,
    width: '200px',
    dataIndex: ['products_nr', 'display_product_category'],
    key: 'products_nr_id',
  },
  DESCRIPTION_TABLE,
  {
    title: `${t('SIFARNICI.RAZRED')}`,
    width: '100px',
    dataIndex: 'p_class',
    key: 'p_class',
  },
  {
    title: `${t('SIFARNICI.JEDINICA')}`,
    width: '100px',
    dataIndex: 'unit',
    key: 'unit',
  },
  {
    title: `${t('SIFARNICI.CENA_ILI_PROCENAT_PDV')}`,
    width: '150px',
    dataIndex: 'priceor_percentageof_pdv',
    key: 'priceor_percentageof_pdv',
  },
  {
    title: `${t('SIFARNICI.DINARA_ILI_PDV')}`,
    width: '100px',
    dataIndex: 'currencyor',
    key: 'currencyor',
  },

  THE_ORDER_TABLE,
];

export const CURRENCYOR_OPTIONS = [
  { value: 'RSD', label: 'RSD' },
  { value: '%', label: '%' },
];

export const UNIT_OPTIONS = [
  { value: 'KOM', label: 'KOM' },
  { value: 'KG', label: 'KG' },
  { value: 'T', label: 'T' },
  { value: 'L', label: 'L' },
];

export const P_CLASS_OPTIONS = [
  { value: 'Razred I', label: `${t('SIFARNICI.RAZRED')} I` },
  { value: 'Razred II', label: `${t('SIFARNICI.RAZRED')} II` },
  { value: 'Razred III', label: `${t('SIFARNICI.RAZRED')} III` },
  { value: 'Razred IV', label: `${t('SIFARNICI.RAZRED')} IV` },
  { value: 'Razred V', label: `${t('SIFARNICI.RAZRED')} V` },
  { value: 'Razred Va', label: `${t('SIFARNICI.RAZRED')} Va` },
  { value: 'Razred Vb', label: `${t('SIFARNICI.RAZRED')} Vb` },
  { value: 'Razred VI', label: `${t('SIFARNICI.RAZRED')} VI` },
  { value: 'Razred VII', label: `${t('SIFARNICI.RAZRED')} VII` },
  { value: 'Razred VIII', label: `${t('SIFARNICI.RAZRED')} VIII` },
  { value: 'Razred IX', label: `${t('SIFARNICI.RAZRED')} IX` },
  { value: 'Razred X', label: `${t('SIFARNICI.RAZRED')} X` },
];

export const PRODUCTS_TYPE_FORM_OPTIONS = (form: FormInstance<IGetProductsType>) => {
  return [
    {
      ...NAME_FORM,
      onChange: productTypeService.makeProizvodiInput(form),
      rules: [REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(200)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'searchScroll',
      label: t('SIFARNICI.PROIZVOD'),
      // placeholder={t('SIFARNICI.PROIZVOD')}
      rules: [REQUIRED_FIELD_RULE(true)],
      formName: 'products_nr_id',
      fetchOptinsList: SIFARNICI_ROUTES.PRODUCTS_NR,
      filtersForFetch: {},
      labelOptionAccessor: ['display_product_category'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
    } as SearchScrollSifarniciFormProps,

    DESCRIPTION_FORM,

    {
      type_of_form: 'select',
      name: 'p_class',
      label: t('SIFARNICI.RAZRED'),
      options: P_CLASS_OPTIONS,
    } as SelectSifarniciFormProps,
    {
      type_of_form: 'select',
      name: 'unit',
      label: t('SIFARNICI.JEDINICA'),
      options: UNIT_OPTIONS,
      onChange: productTypeService.makeProizvodiInput(form),
    } as SelectSifarniciFormProps,
    {
      type_of_form: 'input_number',
      name: 'priceor_percentageof_pdv',
      onChange: productTypeService.makeProizvodiInput(form),
      label: t('SIFARNICI.CENA_ILI_PROCENAT_PDV'),
      inputName: 'priceor_percentageof_pdv',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'select',
      name: 'currencyor',
      label: t('SIFARNICI.DINARA_ILI_PDV'),
      onChange: productTypeService.makeProizvodiInput(form),
      options: CURRENCYOR_OPTIONS,
      hideInput: true,
    } as SelectSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'display_product',
      label: 'Proizvod/cena',
      inputName: 'display_product',
      disabled: true,
      hideInput: true,
    } as InputSifarniciFormProps,

    THE_ORDER_FORM,
  ];
};
