import { ColumnsType } from 'antd/es/table';
import { IGetCompetentAuthorityParty, IPostCompetentAuthorityParty } from './competentAuthorityParty.types';
import { FormInstance } from 'antd';
import { t } from 'i18next';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { MAX_LENGTH_RULE, MORE_THEN_0, POST_NUMBER_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { NAME_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';

export const COMPETENT_AUTHORITY_PARTY_INITIAL_STATE: IPostCompetentAuthorityParty = {
  name: '',
  street_name: '',
  building_number: '',
  city_name: '',
  postcode_code: null,
  telephone_communication: '',
  fax_communication: '',
  email_communication: '',
  contact_person_name: '',
  is_active: true,
};

export const COMPETENT_AUTHORITY_PARTY_TABLE_DATA: ColumnsType<IGetCompetentAuthorityParty> = [
  NAME_TABLE,
  {
    title: 'Ime grada',
    width: '150px',
    dataIndex: 'city_name',
    key: 'city_name',
  },
  {
    title: t('COMPANY.ADDRESS'),
    width: '200px',
    dataIndex: 'street_name',
    key: 'street_name',
  },
  {
    title: t('COMPANY.ADDRESS_NUMBER'),
    width: '100px',
    dataIndex: 'building_number',
    key: 'building_number',
  },
  {
    title: t('COMPANY.POST_NUMBER'),
    width: '150px',
    dataIndex: 'postcode_code',
    key: 'postcode_code',
  },
  {
    title: t('COMPANY.PHONE'),
    width: '200px',
    dataIndex: 'telephone_communication',
    key: 'telephone_communication',
  },
  {
    title: 'Faks',
    width: '200px',
    dataIndex: 'fax_communication',
    key: 'fax_communication',
  },
  {
    title: t('COMPANY.EMAIL'),
    width: '250px',
    dataIndex: 'email_communication',
    key: 'email_communication',
  },
  {
    title: 'Osoba za kontakt',
    width: '200px',
    dataIndex: 'contact_person_name',
    key: 'contact_person_name',
  },
];
export const COMPETENT_AUTHORITY_PARTY_FORM_OPTIONS = (_: FormInstance<IGetCompetentAuthorityParty>) => {
  return [
    NAME_FORM,
    {
      type_of_form: 'input',
      name: 'city_name',
      // todo: prevod
      label: 'Ime grada',
      inputName: 'city_name',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'street_name',
      // todo: prevod
      label: t('COMPANY.ADDRESS'),
      inputName: 'street_name',
      rules: [MAX_LENGTH_RULE(50)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'building_number',
      // todo: prevod
      label: t('COMPANY.ADDRESS_NUMBER'),
      rules: [MAX_LENGTH_RULE(20)],
      inputName: 'building_number',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'postcode_code',
      // todo: prevod
      label: t('COMPANY.POST_NUMBER'),
      rules: [POST_NUMBER_FIELD_RULE, ...MORE_THEN_0],
      inputName: 'postcode_code',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'telephone_communication',
      // todo: prevod
      label: t('COMPANY.PHONE'),
      inputName: 'telephone_communication',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'fax_communication',
      // todo: prevod
      label: 'Faks',
      inputName: 'fax_communication',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'email_communication',
      // todo: prevod
      label: t('COMPANY.EMAIL'),
      inputName: 'email_communication',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'contact_person_name',
      // todo: prevod
      label: 'Osoba za kontakt',
      inputName: 'contact_person_name',
    } as InputSifarniciFormProps,
  ];
};
