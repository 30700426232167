import { Col, Row } from 'antd';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from './UserCompanyInfo.module.scss';
import { userStore } from 'modules/user/user.store';
import { IGetUser } from 'modules/user/user.types';
import { Button } from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { DoubleRightOutlined } from '@ant-design/icons';

export const UserCompanyInfoHeader: React.FC = observer(() => {
  const navigate = useNavigate();
  return (
    <Row justify={'space-between'} align={'middle'}>
      <h3>Informacije o preduzeću</h3>
      <Button
        className={styles.btn}
        name="preduzece"
        onClick={() => {
          if ((userStore.getUser as IGetUser)?.preduzece?.id) {
            return navigate(`/company/${(userStore.getUser as IGetUser)?.preduzece?.id}`);
          }
        }}
      >
        Vidi preduzeće
        <DoubleRightOutlined rev={undefined} />
      </Button>
    </Row>
  );
});

export const UserCompanyInfo: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<UserCompanyInfoHeader />}>
      <Row style={{ background: 'white', padding: '20px 10px' }} justify={'space-between'} align={'middle'}>
        <Col span={6}>Ime preduzeća:</Col>
        <Col span={18} style={{ fontWeight: 'bold', fontSize: '16px' }}>
          {(userStore.getUser as IGetUser)?.preduzece?.naziv}
        </Col>
      </Row>
    </InsideContentWrapper>
  );
});
