import { IBaseObrazacStore, IPagination } from 'typescript/NrizTypes';
import {
  IPostGio5Dozvole,
  IGio5Dozvole,
  IGio5ObrazacResponse,
  IPostGio5Obrazac,
  IPostGio5UvozOtpada,
  IGio5UvozOtpada,
  IGio5Country,
  IPostGio5Country,
} from './gio5.types';
import {
  GIO_5_UVOZ_OTPADA_INITIAL_STATE,
  GIO_5_DOZVOLE_INITIAL_STATE,
  GIO_5_INITIAL_STATE,
  GIO_5_ZEMLJA_TRANZITA_INITIAL_STATE,
} from './gio5.constants';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { obrasciService } from 'modules/obrasci/obrasci.service';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { nrizService } from 'modules/nriz/nriz.service';
import { formStore } from 'store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import to from 'await-to-js';
import dayjs from 'dayjs';
import { sifarniciRepo } from 'modules/sifarnici/sifarnici.repo';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { handleSuccessNotify } from 'utils/succesHandling';
import { arrayToObject } from 'utils/arrayToObject';
import { ResponseCList } from 'modules/sifarnici/store/cList/cList.types';
import { ResponseCountryCode } from 'modules/sifarnici/store/countryCode/countryCode.types';
import { ResponseNList } from 'modules/sifarnici/store/nList/nList.types';
import { ResponseYList } from 'modules/sifarnici/store/yList/yList.types';
import { ResponseIaList } from 'modules/sifarnici/store/iaList/iaList.types';
import { ResponseIibList } from 'modules/sifarnici/store/iibList/iibList.types';
import {
  IGetWasteCatalogue,
  ResponseWasteCatalouge,
} from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';

class Gio5ObrazacStore implements IBaseObrazacStore<IGio5ObrazacResponse, IPostGio5Obrazac> {
  initialState: IGio5ObrazacResponse | IPostGio5Obrazac = GIO_5_INITIAL_STATE;
  dozvoleInitial: IGio5Dozvole | IPostGio5Dozvole = GIO_5_DOZVOLE_INITIAL_STATE;
  uvozOtpada: IGio5UvozOtpada | IPostGio5UvozOtpada = GIO_5_UVOZ_OTPADA_INITIAL_STATE;
  zemljaTranzita: IGio5Country | IPostGio5Country = GIO_5_ZEMLJA_TRANZITA_INITIAL_STATE;
  index_id = '';
  dozvoleNizValidacija: string[] = [];
  imaTranzita = false;

  constructor() {
    makeAutoObservable(this, {
      initialState: observable,
      dozvoleInitial: observable,
      uvozOtpada: observable,
      index_id: observable,
      zemljaTranzita: observable,
      imaTranzita: observable,

      handleChange: action,
      handleInitialStateChange: action,
      handleUvozOtpada: action,

      getInitialState: computed,
      getDozvoleInitial: computed,
      getUvozOtpada: computed,
      getZemljaTranzita: computed,
      getImaTranzita: computed,
    });
  }

  get getImaTranzita() {
    return this.imaTranzita;
  }

  get getZemljaTranzita() {
    return this.zemljaTranzita;
  }

  get getDozvoleInitial() {
    return this.dozvoleInitial;
  }

  get getUvozOtpada() {
    return this.uvozOtpada;
  }

  get getInitialState() {
    return this.initialState;
  }

  handleChange(key: keyof Gio5ObrazacStore, value: any) {
    (this as any)[key] = value;
  }

  handleUvozOtpada(key: string, value: any) {
    (this as any).uvozOtpada[key] = value;
  }

  handleInitialStateChange(key: string, value: any) {
    (this as any).initialState[key] = value;
  }

  resetStates = () => {
    this.handleChange('initialState', GIO_5_INITIAL_STATE);
  };

  loadData = async (id: string) => {
    const [err, res] = await to(obrasciService.fetchSingleObrazac<IGio5ObrazacResponse>(TIPOVI_OBRAZACA.GIO5, id));
    if (err) return;
    const newGodina = dayjs().year((res.godina as any) * 1);
    const newDozvole = this.makeNewDozvole((res as IGio5ObrazacResponse).dozvole);
    this.handleChange('initialState', { ...res, godina: newGodina, dozvole: newDozvole });
    return res;
  };

  makeNewDozvole = (items: IGio5Dozvole[]) => {
    const newDozvoleArray = items.map((item: IGio5Dozvole) => {
      return {
        ...item,
        datum_isteka: dayjs(item.datum_isteka),
        datum_izdavanja: dayjs(item.datum_izdavanja),
      };
    });
    return newDozvoleArray;
  };

  setData?: ((res: IGio5ObrazacResponse) => void) | undefined;
  async postData(payload: IPostGio5Obrazac) {
    const [err, res] = await to(
      obrasciService.postSingleObrazac<IPostGio5Obrazac>(
        TIPOVI_OBRAZACA.GIO5,
        nrizService.setEmptyValuesToNull(payload)
      )
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  putData = async (payload: IGio5ObrazacResponse) => {
    const [err, res] = await to(
      obrasciService.putSingleObrazac<IPostGio5Obrazac>(TIPOVI_OBRAZACA.GIO5, payload.id, payload)
    );
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  };

  async deleteData(id: string) {
    const [err, res] = await to(obrasciService.deleteSingleObrazac(TIPOVI_OBRAZACA.GIO5, id));
    if (err || !res) return;
    formStore.handleChange('goBack', true);
    handleSuccessNotify();
  }

  submitData = (payload: IPostGio5Obrazac) => {
    const newInitialState = {
      ...GIO_5_INITIAL_STATE,
      id: (this.initialState as IGio5ObrazacResponse).id,
      ...payload,
      godina: obrasciStore.opste.godina,
      uvoz_otpada: this.initialState.uvoz_otpada,
      dozvole: this.initialState.dozvole,
    } as IGio5ObrazacResponse;

    obrasciService.submitFormObrazac({
      payload: newInitialState,
      postForm: this.postData,
      putForm: this.putData,
      deleteForm: this.deleteData,
    });
  };

  // SIFARNICI

  fetchCountryOptions = async (pagination: IPagination, search: string): Promise<ResponseCountryCode> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.COUNTRY_CODE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      code__neq: 'RS',
      order_by: '+name',
    });
    return response;
  };

  fetchGranica = async (id: string) => {
    this.handleUvozOtpada('zemlje_tranzita', []);
    if (!id) {
      this.handleChange('imaTranzita', '');
      return;
    }
    const response = await sifarniciRepo.fetchIsSifarnikTrue(SIFARNICI_ROUTES.COUNTRY_CODE, id);
    //@ts-ignore
    this.handleChange('imaTranzita', !response.granici_se_srbija);
  };

  fetchYListOptions = async (pagination: IPagination, search: string): Promise<ResponseYList> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.Y_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      v2: true,
      order_by: '+name',
    });
    return response;
  };

  fetchCListOptions = async (pagination: IPagination, search: string): Promise<ResponseCList> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.C_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+name',
    });
    return response;
  };

  fetchNListOptions = async (pagination: IPagination, search: string): Promise<ResponseNList> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.N_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+name',
    });
    return response;
  };

  fetchIaListOptions = async (pagination: IPagination, search: string): Promise<ResponseIaList> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.IA_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      approved: true,
      order_by: '+name',
    });
    return response;
  };

  fetchIibListOptions = async (pagination: IPagination, search: string): Promise<ResponseIibList> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.IIB_LIST, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      approved: true,
      order_by: '+name',
    });
    return response;
  };

  fetchWasteCatalogueList = async (pagination: IPagination, search: string): Promise<ResponseWasteCatalouge> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.WASTE_CATALOGUE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      level: 3,
      order_by: '+index_number',
    });
    return response;
  };

  /// DOZVOLE

  promeniDozvoleNizValidacijaListu = (type: string, value: boolean) => {
    const indexOfType = this.dozvoleNizValidacija.indexOf(type);

    if (value === true && indexOfType === -1) {
      this.dozvoleNizValidacija.push(type);
    } else if (value === false && indexOfType !== -1) {
      this.dozvoleNizValidacija.splice(indexOfType, 1);
    }
  };

  submitDozvoleForm = (payload: IPostGio5Dozvole) => {
    if (!this.dozvoleNizValidacija.length) return;
    let newPayload = payload;
    newPayload = { ...this.dozvoleInitial, ...newPayload };

    const newList = obrasciService.submitMicroServiceForm(this.initialState.dozvole, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('dozvole', newList);
    this.handleChange('dozvoleInitial', GIO_5_DOZVOLE_INITIAL_STATE);
  };

  // IZVOZ

  submitZemljeTranzita = (payload: IPostGio5Country) => {
    let newPayload = payload;
    let sifarnikCountryCode = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikCountryCode =
        // this.zemljaTranzita.zemlja_tranzita_id === payload.zemlja_tranzita_id
        //   ? this.zemljaTranzita
        nrizService.addSifarnikInObject({
          exId: this.zemljaTranzita.zemlja_tranzita_id,
          exObj: (this.zemljaTranzita as IGio5Country).zemlja_tranzita,
          list: sifarniciStore.list[SIFARNICI_ROUTES.COUNTRY_CODE],
          name: 'zemlja_tranzita',
          id: payload.zemlja_tranzita_id,
        });
    }
    newPayload = {
      id: (this.zemljaTranzita as IGio5Country).id,
      ...newPayload,
      ...sifarnikCountryCode,
    } as IGio5Country;

    const newList = obrasciService.submitMicroServiceForm(
      this.uvozOtpada.zemlje_tranzita,
      newPayload,
      true,
      'gio5-zemlje-tranzita-modal'
    );
    // handleSuccessNotify();
    this.handleUvozOtpada('zemlje_tranzita', newList);
    this.handleChange('zemljaTranzita', GIO_5_ZEMLJA_TRANZITA_INITIAL_STATE);
  };

  submitUvozOtpada = (payload: IPostGio5UvozOtpada) => {
    let newPayload = payload;
    let sifarnikIndeksOtpada = {};
    let sifarnikZemljaIzvoza = {};
    let sifarnikYLista = {};
    let sifarnikYLista2 = {};
    let sifarnikYLista3 = {};
    let sifarnikCLista = {};
    let sifarnikNLista = {};
    let sifarnikNLista2 = {};
    let sifarnikNLista3 = {};
    let sifarnikIaList = {};
    let sifarnikIIibList = {};

    if (formStore.getCrudButtonType === 'submit' || formStore.getCrudButtonType === 'change') {
      sifarnikIndeksOtpada = nrizService.addSifarnikInObject({
        exId: this.uvozOtpada.indeksni_broj_otpada_id,
        exObj: (this.uvozOtpada as IGio5UvozOtpada).indeksni_broj_otpada,
        list: sifarniciStore.list.WASTE_CATALOGUE,
        name: 'indeksni_broj_otpada',
        id: payload.indeksni_broj_otpada_id,
      });

      sifarnikZemljaIzvoza = nrizService.addSifarnikInObject({
        exId: this.uvozOtpada.zemlja_izvoza_id,
        exObj: (this.uvozOtpada as IGio5UvozOtpada).zemlja_izvoza,
        list: sifarniciStore.list.COUNTRY_CODE,
        name: 'zemlja_izvoza',
        id: payload.zemlja_izvoza_id,
      });

      sifarnikYLista = nrizService.addSifarnikInObject({
        exId: this.uvozOtpada.uvoz_otpada_y_lista_id,
        exObj: (this.uvozOtpada as IGio5UvozOtpada).uvoz_otpada_y_lista,
        list: sifarniciStore.list.Y_LIST,
        name: 'uvoz_otpada_y_lista',
        id: payload.uvoz_otpada_y_lista_id,
      });

      sifarnikYLista2 = nrizService.addSifarnikInObject({
        exId: this.uvozOtpada.uvoz_otpada_y_lista2_id,
        exObj: (this.uvozOtpada as IGio5UvozOtpada).uvoz_otpada_y_lista2,
        list: sifarniciStore.list.Y_LIST,
        name: 'uvoz_otpada_y_lista2',
        id: payload.uvoz_otpada_y_lista2_id,
      });

      sifarnikYLista3 = nrizService.addSifarnikInObject({
        exId: this.uvozOtpada.uvoz_otpada_y_lista3_id,
        exObj: (this.uvozOtpada as IGio5UvozOtpada).uvoz_otpada_y_lista3,
        list: sifarniciStore.list.Y_LIST,
        name: 'uvoz_otpada_y_lista3',
        id: payload.uvoz_otpada_y_lista3_id,
      });

      sifarnikCLista = nrizService.addSifarnikInObject({
        exId: this.uvozOtpada.uvoz_otpada_c_lista_id,
        exObj: (this.uvozOtpada as IGio5UvozOtpada).uvoz_otpada_c_lista,
        list: sifarniciStore.list.C_LIST,
        name: 'uvoz_otpada_c_lista',
        id: payload.uvoz_otpada_c_lista_id,
      });

      sifarnikNLista = nrizService.addSifarnikInObject({
        exId: this.uvozOtpada.uvoz_otpada_n_lista_id,
        exObj: (this.uvozOtpada as IGio5UvozOtpada).uvoz_otpada_n_lista,
        list: sifarniciStore.list.N_LIST,
        name: 'uvoz_otpada_n_lista',
        id: payload.uvoz_otpada_n_lista_id,
      });

      sifarnikNLista2 = nrizService.addSifarnikInObject({
        exId: this.uvozOtpada.uvoz_otpada_n_lista2_id,
        exObj: (this.uvozOtpada as IGio5UvozOtpada).uvoz_otpada_n_lista2,
        list: sifarniciStore.list.N_LIST,
        name: 'uvoz_otpada_n_lista2',
        id: payload.uvoz_otpada_n_lista2_id,
      });

      sifarnikNLista3 = nrizService.addSifarnikInObject({
        exId: this.uvozOtpada.uvoz_otpada_n_lista3_id,
        exObj: (this.uvozOtpada as IGio5UvozOtpada).uvoz_otpada_n_lista3,
        list: sifarniciStore.list.N_LIST,
        name: 'uvoz_otpada_n_lista3',
        id: payload.uvoz_otpada_n_lista3_id,
      });

      sifarnikIaList = nrizService.addSifarnikInObject({
        exId: this.uvozOtpada.ia_lista_id,
        exObj: (this.uvozOtpada as IGio5UvozOtpada).ia_lista,
        list: sifarniciStore.list.IA_LIST,
        name: 'ia_lista',
        id: payload.ia_lista_id,
      });

      sifarnikIIibList = nrizService.addSifarnikInObject({
        exId: this.uvozOtpada.iib_lista_id,
        exObj: (this.uvozOtpada as IGio5UvozOtpada).iib_lista,
        list: sifarniciStore.list.IIB_LIST,
        name: 'iib_lista',
        id: payload.iib_lista_id,
      });
    }
    newPayload = {
      id: (this.uvozOtpada as IGio5UvozOtpada).id,
      ...newPayload,
      ...sifarnikIndeksOtpada,
      ...sifarnikZemljaIzvoza,
      ...sifarnikYLista,
      ...sifarnikYLista2,
      ...sifarnikYLista3,
      ...sifarnikCLista,
      ...sifarnikNLista,
      ...sifarnikNLista2,
      ...sifarnikNLista3,
      ...sifarnikIaList,
      ...sifarnikIIibList,
      zemlje_tranzita: this.uvozOtpada.zemlje_tranzita,
    } as IGio5UvozOtpada;

    const newList = obrasciService.submitMicroServiceForm(this.initialState.uvoz_otpada, newPayload);
    // handleSuccessNotify();
    this.handleInitialStateChange('uvoz_otpada', newList);
    this.handleChange('uvozOtpada', GIO_5_UVOZ_OTPADA_INITIAL_STATE);
  };

  indexMap = (id: string) => {
    const obj = arrayToObject(sifarniciStore.list.WASTE_CATALOGUE);
    const findItem = obj[id] as IGetWasteCatalogue;
    return findItem;
  };
}

export const gio5ObrazacStore = new Gio5ObrazacStore();
