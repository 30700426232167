import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { gio3ObrazacStore } from 'modules/obrasci/store/gio3/gio3.store';
import { IGetPostrojenjeGio3 } from 'modules/obrasci/store/gio3/gio3.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

export const TablePostrojenjaGio3: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetPostrojenjeGio3> = [
    {
      title: `${t('ADDRESS')}`,
      width: 200,
      dataIndex: 'adresa_postrojenja',
      key: 'waste',
    },
    {
      title: `${t('COMPANY.LOCATION')}`,
      width: 200,
      dataIndex: ['mesto_postrojenja', 'display_name'],
      key: 'display_name',
    },
    {
      title: `${t('COMPANY.LATITUDE')}`,
      dataIndex: 'geografska_sirina',
      key: 'geografska_sirina',
      width: 200,
    },
    {
      title: `${t('COMPANY.LONGITUDE')}`,
      width: 200,
      dataIndex: 'geografska_duzina',
      key: 'geografska_duzina',
    },

    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetPostrojenjeGio3) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-postrojenja'}
            onClick={() => {
              gio3ObrazacStore.handleChange('postrojenja_form', record);
              modalStore.changeModalName('gio3-postrojenja-modal');
            }}
          />
        );
      },
    },
  ];
  // todo: check if backend return whole array or we have to do it with pagination
  return (
    <TableData
      name="postrojenja-gio3"
      columns={baseColumns}
      dataSource={[...gio3ObrazacStore.initialState.postrojenja]}
    />
  );
});
