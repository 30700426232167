import { observer } from 'mobx-react-lite';
import { modalStore } from 'store';
import styles from './FacilityUserModal.module.scss';
import { Modal } from 'components/Modal/Modal';
import { FacilityUsersModalTable } from 'components/Tables/FacilityUsersModalTable/FacilityUsersModalTable';
import { useTranslation } from 'react-i18next';

export const FacilityUserModal: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Modal
      name="facility-user-modal"
      open={modalStore.getCurrentOpenedModal.includes('facility-user-modal')}
      className={`.and-modal-wrap .ant-modal ${styles.facilityUserModal}`}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => modalStore.removeAllModals()}
      title={t('USER.ADD_TO_FACILITY')}
      width={'80%'}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
    >
      <FacilityUsersModalTable />
    </Modal>
  );
});
