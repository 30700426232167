import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { gio2ObrazacStore } from 'modules/obrasci/store/gio2/gio2.store';
import { IGetDozvoleGio2 } from 'modules/obrasci/store/gio2/gio2.types';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import dayjs from 'dayjs';
import { Checkbox } from 'antd';

export const Gio2TableDozvole: React.FC = observer(() => {
  const { t } = useTranslation();

  const baseColumns: ColumnsType<IGetDozvoleGio2> = [
    {
      title: `${t(['OBRASCI.GIO2.BROJ_DOZVOLE'])}`,
      width: 100,
      dataIndex: 'broj_dozvole',
      key: 'broj_dozvole',
    },
    {
      title: `${t('OBRASCI.GIO2.DATUM_IZDAVANJA')}`,
      width: 100,
      render: (_, record) => <span>{dayjs(record.datum_izdavanja).format('DD. MM. YYYY.')}</span>,
      key: 'datum_izdavanja',
    },
    {
      title: `${t('OBRASCI.GIO2.DATUM_ISTEKA')}`,
      key: 'datum_isteka',
      width: 100,
      render: (_, record) => <span>{dayjs(record.datum_isteka).format('DD. MM. YYYY.')}</span>,
    },
    {
      title: `${t('OBRASCI.GIO2.SAKUPLJANJE')}`,
      render: (_, record) => <Checkbox checked={!!record.sakupljanje} disabled={true} />,
      key: 'sakupljanje',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.TRANSPORT')}`,
      render: (_, record) => <Checkbox checked={!!record.transport} disabled={true} />,
      key: 'transport',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.SKLADISTENJE')}`,
      render: (_, record) => <Checkbox checked={!!record.skladistenje} disabled={true} />,
      key: 'skladistenje',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.TRETMAN')}`,
      render: (_, record) => <Checkbox checked={!!record.tretman} disabled={true} />,
      key: 'tretman',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('OBRASCI.GIO2.ODLAGANJE')}`,
      render: (_, record) => <Checkbox checked={!!record.odlaganje} disabled={true} />,
      key: 'odlaganje',
      width: 100,
      align: 'center',
    },
    {
      title: `${t('COMPANY.ADDITIONALLY')}`,
      key: 'operation',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (_, record: IGetDozvoleGio2) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-gio2-dozvole'}
            onClick={() => {
              gio2ObrazacStore.handleChange('dozvole_form', record);
              modalStore.changeModalName('gio2-dozvole-modal');
            }}
          />
        );
      },
    },
  ];

  return (
    <TableData name="dozvole-gio2" columns={baseColumns} dataSource={[...gio2ObrazacStore.initialState.dozvole]} />
  );
});
