import React from 'react';
import { observer } from 'mobx-react-lite';

import { TableDivljaDeponija } from './components/TableDivljaDeponija/TableDivljaDeponija';
import { HeaderDivljaDeponija } from './components/HeaderDivljaDeponija/HeaderDivljaDeponija';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';

export const DivljeDeponijeWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper header={<HeaderDivljaDeponija />}>
      <TableDivljaDeponija />
    </InsideContentWrapper>
  );
});
