import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { GORIVO_INITIAL_STATE } from 'modules/obrasci/store/obrazac1/obrazac1.constants';
import { obrazac1Store } from 'modules/obrasci/store/obrazac1/obrazac1.store';
import React from 'react';
import { NewGorivaForm } from './components/NewGorivaForm/NewGorivaForm';
import { useTranslation } from 'react-i18next';

export const GorivaModal: React.FC = observer(() => {
  const { t } = useTranslation();
  //   todo: prevod
  return (
    <CRUDModal
      width="70%"
      name="goriva-modal"
      title={t('OBRASCI.GVE.GORIVO')}
      store={obrazac1Store}
      data={obrazac1Store.getGorivoForm}
      children={() => <NewGorivaForm />}
      submitForm={obrazac1Store.submitGorivoForm}
      initialState={GORIVO_INITIAL_STATE}
      dataToChange="goriva_form"
    />
  );
});
