import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { aao1Store } from 'modules/obrasci/store/aao1/aao1.store';
import styles from './AmbalazaOptions.module.scss';

type AmbalazaOptionsProps = {
  disabled: boolean;
};

export const AmbalazaOptions: React.FC<AmbalazaOptionsProps> = observer(props => {
  const { t } = useTranslation();

  return (
    <Row className={`${styles.type_of_company} `}>
      <Col span={24} className={`${styles.type_options}`}>
        <h4 className={styles.text}>
          {t('OBRASCI.AAO1.CHECKBOX.TITLE')}
          <span style={{ color: 'red' }}> *</span>
        </h4>
        <FormCheckbox
          onCheckBoxChange={e => {
            aao1Store.changeCompanyTypeList('proizvodjac_ambalaze', e.target.checked);
          }}
          labelCol={{ span: 21, offset: 1 }}
          name={'proizvodjac_ambalaze'}
          label={t('OBRASCI.AAO1.CHECKBOX.PACKAGING_MANUFACTURER')}
          disabled={props.disabled}
        />
        <FormCheckbox
          onCheckBoxChange={e =>
            aao1Store.changeCompanyTypeList('paker_proizvoda_u_ambalazu_ili_punilac_ambalaze', e.target.checked)
          }
          labelCol={{ span: 21, offset: 1 }}
          name={'paker_proizvoda_u_ambalazu_ili_punilac_ambalaze'}
          label={t('OBRASCI.AAO1.CHECKBOX.PACKER_OF_PRODUCTS')}
          disabled={props.disabled}
        />
        <FormCheckbox
          onCheckBoxChange={e =>
            aao1Store.changeCompanyTypeList(
              'isporucilac_koji_stavlja_u_promet_ambalazu_ili_upakovani_proizvod',
              e.target.checked
            )
          }
          labelCol={{ span: 21, offset: 1 }}
          name={'isporucilac_koji_stavlja_u_promet_ambalazu_ili_upakovani_proizvod'}
          label={t('OBRASCI.AAO1.CHECKBOX.PACKAGING_SUPPLIER')}
          disabled={props.disabled}
        />
        <FormCheckbox
          onCheckBoxChange={e => aao1Store.changeCompanyTypeList('uvoznik_ambalaze', e.target.checked)}
          labelCol={{ span: 21, offset: 1 }}
          name={'uvoznik_ambalaze'}
          label={t('OBRASCI.AAO1.CHECKBOX.IMPORTER_OF_PACKAGING')}
          disabled={props.disabled}
        />
        <FormCheckbox
          onCheckBoxChange={e => aao1Store.changeCompanyTypeList('izvoznik_ambalaze', e.target.checked)}
          labelCol={{ span: 21, offset: 1 }}
          name={'izvoznik_ambalaze'}
          label={t('OBRASCI.AAO1.CHECKBOX.EXPORTER_OF_PACKAGING')}
          disabled={props.disabled}
        />
        <FormCheckbox
          onCheckBoxChange={e => aao1Store.changeCompanyTypeList('krajni_korisnik', e.target.checked)}
          labelCol={{ span: 21, offset: 1 }}
          name={'krajni_korisnik'}
          label={t('OBRASCI.AAO1.CHECKBOX.END_USER')}
          disabled={props.disabled}
        />
      </Col>
    </Row>
  );
});
