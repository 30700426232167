import { observer } from 'mobx-react-lite';
import React from 'react';
import { KolicinaAmbalazeModal } from './components/KolicinaAmbalazeModal/KolicinaAmbalazeModal';
import { UpravljanjeAmbalaznimOtpadomModal } from './components/UpravljanjeAmbalaznimOtpadomModal/UpravljanjeAmbalaznimOtpadomModal';
import { Aao1OperateriModal } from './components/Aao1OperateriModal/Aao1OperateriModal';

export const Aao1MicroServices: React.FC = observer(() => {
  return (
    <>
      <KolicinaAmbalazeModal />
      <UpravljanjeAmbalaznimOtpadomModal />
      <Aao1OperateriModal />
    </>
  );
});
