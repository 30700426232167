import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FacilityInfo } from './components/FacilityInfo/FacilityInfo';
import { Content } from 'components/Content/Content';
import { FacilityUsersList } from './components/FacilityUsersList/FacilityUsersList';
import { FacilitySourcesOfPollution } from './components/FacilitySourcesOfPollution/FacilitySourcesOfPollution';
import { FacilityActivityLists } from './components/FacilityActivityLists/FacilityActivityLists';
import { useParams } from 'react-router-dom';
import { FacilityTipoviObrazaca } from './components/FacilityTipoviObrazaca/FacilityTipoviObrazaca';
import { IGetFacility } from 'modules/facility/facility.types';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { IGetCompany } from 'modules/company/company.types';
import { companyStore } from 'modules/company/company.store';
import { facilityStore } from 'modules/facility/facility.store';

export const FacilityHeader: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Row justify={'space-between'}>
      <h1>{t('FACILITY.TITLE')}</h1>
    </Row>
  );
});

const Facility: React.FC = observer(() => {
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      facilityStore.fetchFacility(id);
    }

    return () => {
      facilityStore.resetFacilityPage();
    };
  }, []);

  return (
    <Content
      header={<FacilityHeader />}
      backBtn={{ name: 'preduzec', route: `/company/${(companyStore.getCompany as IGetCompany).id}` }}
    >
      <FacilityInfo />
      {(facilityStore.facility as IGetFacility).id && (
        <>
          <FacilityActivityLists />
          <FacilityTipoviObrazaca />
          <FacilityUsersList />
          <FacilitySourcesOfPollution />
        </>
      )}
    </Content>
  );
});

export default Facility;
