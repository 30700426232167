import {
  ITipObrascaHelpers,
  ITipObrascaConfig,
  TIPOVI_OBRAZACA,
  ITipObrascaCheckboxHandler,
  OBRAZAC_2_TO_5_KEYS,
  LRIZ_2_TO_5_KEYS,
  GIO2to5Keys,
  OBRAZAC_KEYS,
  LRIZ_KEYS,
} from './obrasci.types';
import { arrayToObject } from 'utils/arrayToObject';
import { obrasciStore } from './obrasci.store';
import { activityList1Store } from 'modules/activityList1/activityList1.store';
import { activityList2Store } from 'modules/activityList2/activityList2.store';
import { companyStore } from 'modules/company/company.store';
import { facilityStore } from 'modules/facility/facility.store';
import { IGetActivity1 } from 'modules/activityList1/activityList1.types';
import storage, { StorageEnum } from 'store/storage';

const handleToggleObrazac1: ITipObrascaCheckboxHandler = (event, form) => {
  const selectedIds = () => form.getFieldValue('tipovi_obrazaca') as string[];
  const obrazac1Id = obrasciStore.obrasciShortNameMap['OBRAZAC 1']?.id;
  const obrazac2Id = obrasciStore.obrasciShortNameMap['OBRAZAC 2']?.id;
  const obrazac3Id = obrasciStore.obrasciShortNameMap['OBRAZAC 3']?.id;
  const obrazac4Id = obrasciStore.obrasciShortNameMap['OBRAZAC 4']?.id;
  const obrazac5Id = obrasciStore.obrasciShortNameMap['OBRAZAC 5']?.id;
  const dkoId = obrasciStore.obrasciShortNameMap.DKO?.id;
  const allIds = [obrazac2Id, obrazac3Id, obrazac4Id, obrazac5Id];

  let newIds = [...selectedIds()];

  if (!event.target.checked) {
    newIds = [...newIds.filter(id => id !== event.target.value)];
  }

  if (event.target.checked && !newIds.includes(event.target.value)) {
    newIds = [...newIds, event.target.value];
  }

  if (event.target.checked && !newIds.includes(obrazac1Id)) {
    newIds = [...newIds, obrazac1Id];
  }

  if (newIds.includes(obrazac5Id) && !newIds.includes(dkoId)) {
    newIds = [...newIds, dkoId];
  }

  const hasObrasci = allIds.some(id => newIds.includes(id));

  if (!event.target.checked && newIds.includes(obrazac1Id) && !hasObrasci) {
    newIds = newIds.filter(id => id !== obrazac1Id);
  }

  form.setFieldValue('tipovi_obrazaca', newIds);
  const newArray = obrasciStore.selectedObrasci(newIds);
  facilityStore.setFacilityTipoviObrazaca(newArray);
};

const handleToggleLriz: ITipObrascaCheckboxHandler = (event, form) => {
  const selectedIds = () => form.getFieldValue('tipovi_obrazaca') as string[];
  const lrizObrazac1Id = obrasciStore.obrasciShortNameMap['LRIZ OBRAZAC 1']?.id;
  const lrizObrazac2Id = obrasciStore.obrasciShortNameMap['LRIZ OBRAZAC 2']?.id;
  const lrizObrazac3Id = obrasciStore.obrasciShortNameMap['LRIZ OBRAZAC 3']?.id;
  const lrizObrazac4Id = obrasciStore.obrasciShortNameMap['LRIZ OBRAZAC 4']?.id;
  const lrizObrazac5Id = obrasciStore.obrasciShortNameMap['LRIZ OBRAZAC 5']?.id;
  const dkoId = obrasciStore.obrasciShortNameMap.DKO?.id;
  const gio1Id = obrasciStore.obrasciShortNameMap.GIO1?.id;

  const allIds = [lrizObrazac2Id, lrizObrazac3Id, lrizObrazac4Id, lrizObrazac5Id];

  let newIds = [...selectedIds()];

  if (!event.target.checked) {
    newIds = [...newIds.filter(id => id !== event.target.value)];
  }

  if (event.target.checked && !newIds.includes(event.target.value)) {
    newIds = [...newIds, event.target.value];
  }

  if (event.target.checked && !newIds.includes(lrizObrazac1Id)) {
    newIds = [...newIds, lrizObrazac1Id];
  }
  if (newIds.includes(lrizObrazac5Id) && !newIds.includes(gio1Id)) {
    newIds = [...newIds, gio1Id];
  }

  if (newIds.includes(lrizObrazac5Id) && newIds.includes(gio1Id) && !newIds.includes(dkoId)) {
    newIds = [...newIds, dkoId];
  }

  const hasObrasci = allIds.some(id => newIds.includes(id));
  if (!event.target.checked && newIds.includes(lrizObrazac1Id) && !hasObrasci) {
    newIds = newIds.filter(id => id !== lrizObrazac1Id);
  }

  form.setFieldValue('tipovi_obrazaca', newIds);
  const newArray = obrasciStore.selectedObrasci(newIds);
  facilityStore.setFacilityTipoviObrazaca(newArray);
};

const handleToggleAao: ITipObrascaCheckboxHandler = (event, form) => {
  const aao1 = obrasciStore.obrasciShortNameMap.AAO1;
  const aao2 = obrasciStore.obrasciShortNameMap.AAO2;

  const selectedIds = () => form.getFieldValue('tipovi_obrazaca') as string[];
  let newIds: string[] = [...selectedIds()];

  if (!event.target.checked) {
    newIds = [...newIds.filter(id => id !== event.target.value)];
    form.setFieldValue('tipovi_obrazaca', [...newIds]);
  }

  if (event.target.checked) {
    newIds = [...newIds, event.target.value];
  }

  if (event.target.value === aao1.id && newIds.includes(aao2.id)) {
    setTimeout(() => {
      const removeAao2 = newIds.filter(id => id !== aao2.id);
      newIds = [...removeAao2];
      form.setFieldValue('tipovi_obrazaca', newIds);
    }, 300);
  }
  if (event.target.value === aao2.id && newIds.includes(aao1.id)) {
    setTimeout(() => {
      const removeAao1 = selectedIds().filter(id => id !== aao1.id);
      newIds = [...removeAao1];
      form.setFieldValue('tipovi_obrazaca', newIds);
    }, 300);
  }

  const newArray = obrasciStore.selectedObrasci(newIds);
  companyStore.setCompanyTipoviObrazaca(newArray);
};

const handleToggleGio: ITipObrascaCheckboxHandler = (event, form) => {
  const selectedIds = () => form.getFieldValue('tipovi_obrazaca') as string[];
  const gio3Id = obrasciStore.obrasciShortNameMap.GIO3?.id;
  const gio2Id = obrasciStore.obrasciShortNameMap.GIO2?.id;
  const gio4Id = obrasciStore.obrasciShortNameMap.GIO4?.id;
  const gio5Id = obrasciStore.obrasciShortNameMap.GIO5?.id;
  const gio6Id = obrasciStore.obrasciShortNameMap['DEO6/GIO6']?.id;

  let newIds: string[] = [...selectedIds()];

  if (!event.target.checked) {
    newIds = [...newIds.filter(id => id !== event.target.value)];
    form.setFieldValue('tipovi_obrazaca', [...newIds]);
  }

  if (
    event.target.checked &&
    (!selectedIds().includes(gio2Id) ||
      !selectedIds().includes(gio3Id) ||
      !selectedIds().includes(gio4Id) ||
      !selectedIds().includes(gio5Id))
  ) {
    setTimeout(() => {
      newIds = [...newIds, event.target.value, gio6Id];
      form.setFieldValue('tipovi_obrazaca', newIds);
    }, 200);
  }

  const newArray = obrasciStore.selectedObrasci(newIds);
  facilityStore.setFacilityTipoviObrazaca(newArray);
};

const handleRemoveFromSelectedObrazac: ITipObrascaCheckboxHandler = (event, form) => {
  const selectedIds = () => form.getFieldValue('tipovi_obrazaca') as string[];

  let newIds: string[] = [...selectedIds()];

  if (!event.target.checked) {
    newIds = [...newIds.filter(id => id !== event.target.value)];
    form.setFieldValue('tipovi_obrazaca', newIds);
  } else if (!newIds.includes(event.target.value)) {
    newIds = [...newIds, event.target.value];

    form.setFieldValue('tipovi_obrazaca', newIds);
  }

  const newArray = obrasciStore.selectedObrasci(newIds);
  facilityStore.setFacilityTipoviObrazaca(newArray);
};

// Preduzece
export const PREDUZECE_OBRASCI: ITipObrascaHelpers[] = [
  {
    name: TIPOVI_OBRAZACA.AAO1,
    disabled: selectedObrasci => {
      const hasAAO2 = selectedObrasci.some(o => o.skraceni_naziv === TIPOVI_OBRAZACA.AAO2);
      return hasAAO2;
    },
    onChange: handleToggleAao,
  },
  {
    name: TIPOVI_OBRAZACA.AAO2,
    disabled: selectedObrasci => {
      const hasAAO1 = selectedObrasci.some(o => o.skraceni_naziv === TIPOVI_OBRAZACA.AAO1);
      return hasAAO1;
    },
    onChange: handleToggleAao,
  },
  {
    name: TIPOVI_OBRAZACA.DEP1,
  },
  {
    name: TIPOVI_OBRAZACA.DEP2,
  },
  {
    name: TIPOVI_OBRAZACA.PTP2,
  },
  {
    name: TIPOVI_OBRAZACA.PZV1,
  },
];

// Postrojenje
export const GIO_OBRASCI: ITipObrascaHelpers[] = [
  {
    name: TIPOVI_OBRAZACA.GIO1,
    onChange: handleRemoveFromSelectedObrazac,
    disabled: (selectedObrasci: ITipObrascaConfig[]) => {
      const hasObrazac = selectedObrasci.some(o => OBRAZAC_2_TO_5_KEYS.includes(o.skraceni_naziv));
      const hasAktivnostLista1 = !!activityList1Store.activityList.length;
      const hasLriz5 = selectedObrasci.some(o => TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5.includes(o.skraceni_naziv));

      return hasObrazac || hasAktivnostLista1 || hasLriz5;
    },
  },
  {
    name: TIPOVI_OBRAZACA.GIO2,
    onChange: handleToggleGio,
  },
  {
    name: TIPOVI_OBRAZACA.GIO3,
    onChange: handleToggleGio,
  },
  {
    name: TIPOVI_OBRAZACA.GIO4,
    onChange: handleToggleGio,
  },
  {
    name: TIPOVI_OBRAZACA.GIO5,
    onChange: handleToggleGio,
  },
  {
    name: TIPOVI_OBRAZACA.DEO6_GIO6,
    disabled: (selectedObrasci: ITipObrascaConfig[]) => {
      const hasGio = selectedObrasci.some(o => GIO2to5Keys.includes(o.skraceni_naziv));
      return hasGio;
    },
    onChange: handleRemoveFromSelectedObrazac,
  },
];

export const OTHER_OBRASCI: ITipObrascaHelpers[] = [
  {
    name: TIPOVI_OBRAZACA.DKO,
    disabled: (selectedObrasci: ITipObrascaConfig[]) => {
      const hasLriz5 = selectedObrasci.some(o => o.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5);
      const hasObrazac5 = selectedObrasci.some(o => o.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_5);
      return hasLriz5 || hasObrazac5;
    },
    onChange: handleRemoveFromSelectedObrazac,
  },
  {
    name: TIPOVI_OBRAZACA.GVE,
    disabled: (_selectedObrasci: ITipObrascaConfig[]) => {
      return false;
    },
    onChange: handleRemoveFromSelectedObrazac,
  },
  {
    name: TIPOVI_OBRAZACA.KOM1,
    disabled: (_selectedObrasci: ITipObrascaConfig[]) => {
      return false;
    },
    onChange: handleRemoveFromSelectedObrazac,
  },
  {
    name: TIPOVI_OBRAZACA.VOC,
    disabled: (_selectedObrasci: ITipObrascaConfig[]) => {
      return true;
    },
    onChange: handleRemoveFromSelectedObrazac,
  },
];

export const OBRAZAC_OBRASCI: ITipObrascaHelpers[] = [
  {
    name: TIPOVI_OBRAZACA.OBRAZAC_1,
    disabled: (_selectedObrasci: ITipObrascaConfig[]) => {
      return true;
    },
    onChange: handleRemoveFromSelectedObrazac,
  },
  {
    name: TIPOVI_OBRAZACA.OBRAZAC_2,
    disabled: (selectedObrasci: ITipObrascaConfig[]) => {
      const hasLRIZ = selectedObrasci.some(o => LRIZ_2_TO_5_KEYS.includes(o.skraceni_naziv));
      const hasAktivnostLista1 = !!(
        activityList1Store.activityList.length &&
        !activityList1Store.activityList.some((item: IGetActivity1) => item.aktivnost1.code === '0')
      );
      const hasGio1 = selectedObrasci.some(o => o.skraceni_naziv === TIPOVI_OBRAZACA.GIO1);

      return hasLRIZ || !hasAktivnostLista1 || hasGio1;
    },
    onChange: handleToggleObrazac1,
  },
  {
    name: TIPOVI_OBRAZACA.OBRAZAC_3,
    disabled: (selectedObrasci: ITipObrascaConfig[]) => {
      const hasLRIZ = selectedObrasci.some(o => LRIZ_2_TO_5_KEYS.includes(o.skraceni_naziv));
      const hasAktivnostLista1 = !!(
        activityList1Store.activityList.length &&
        !activityList1Store.activityList.some((item: IGetActivity1) => item.aktivnost1.code === '0')
      );
      const hasGio1 = selectedObrasci.some(o => o.skraceni_naziv === TIPOVI_OBRAZACA.GIO1);
      return hasLRIZ || !hasAktivnostLista1 || hasGio1;
    },
    onChange: handleToggleObrazac1,
  },
  {
    name: TIPOVI_OBRAZACA.OBRAZAC_4,
    disabled: (selectedObrasci: ITipObrascaConfig[]) => {
      const hasLRIZ = selectedObrasci.some(o => LRIZ_2_TO_5_KEYS.includes(o.skraceni_naziv));
      const hasAktivnostLista1 = !!(
        activityList1Store.activityList.length &&
        !activityList1Store.activityList.some((item: IGetActivity1) => item.aktivnost1.code === '0')
      );
      const hasGio1 = selectedObrasci.some(o => o.skraceni_naziv === TIPOVI_OBRAZACA.GIO1);
      return hasLRIZ || !hasAktivnostLista1 || hasGio1;
    },
    onChange: handleToggleObrazac1,
  },
  {
    name: TIPOVI_OBRAZACA.OBRAZAC_5,
    disabled: (selectedObrasci: ITipObrascaConfig[]) => {
      const hasLRIZ = selectedObrasci.some(o => LRIZ_2_TO_5_KEYS.includes(o.skraceni_naziv));
      const hasAktivnostLista1 = !!(
        activityList1Store.activityList.length &&
        !activityList1Store.activityList.some((item: IGetActivity1) => item.aktivnost1.code === '0')
      );
      const hasGio1 = selectedObrasci.some(o => o.skraceni_naziv === TIPOVI_OBRAZACA.GIO1);
      return hasLRIZ || !hasAktivnostLista1 || hasGio1;
    },
    onChange: handleToggleObrazac1,
  },
];

export const LRIZ_OBRASCI: ITipObrascaHelpers[] = [
  {
    name: TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1,
    onChange: handleToggleLriz,
    disabled: (_selectedObrasci: ITipObrascaConfig[]) => {
      return true; // should be controlled by other checkboxes since its mandatory page
    },
  },
  {
    name: TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2,
    onChange: handleToggleLriz,
    disabled: (selectedObrasci: ITipObrascaConfig[]) => {
      const hasObrazac = selectedObrasci.some(o => OBRAZAC_2_TO_5_KEYS.includes(o.skraceni_naziv));
      const hasAktivnostLista2 = !!activityList2Store.activityList.length;
      return hasObrazac || !hasAktivnostLista2;
    },
  },
  {
    name: TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3,
    onChange: handleToggleLriz,
    disabled: (selectedObrasci: ITipObrascaConfig[]) => {
      const hasObrazac = selectedObrasci.some(o => OBRAZAC_2_TO_5_KEYS.includes(o.skraceni_naziv));
      const hasAktivnostLista2 = !!activityList2Store.activityList.length;
      return hasObrazac || !hasAktivnostLista2;
    },
  },
  {
    name: TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4,
    onChange: handleToggleLriz,
    disabled: (selectedObrasci: ITipObrascaConfig[]) => {
      const hasObrazac = selectedObrasci.some(o => OBRAZAC_2_TO_5_KEYS.includes(o.skraceni_naziv));
      const hasAktivnostLista2 = !!activityList2Store.activityList.length;
      return hasObrazac || !hasAktivnostLista2;
    },
  },
  {
    name: TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5,
    onChange: handleToggleLriz,
    disabled: (selectedObrasci: ITipObrascaConfig[]) => {
      const hasObrazac = selectedObrasci.some(o => OBRAZAC_2_TO_5_KEYS.includes(o.skraceni_naziv));
      const hasAktivnostLista2 = !!activityList2Store.activityList.length;
      return hasObrazac || !hasAktivnostLista2;
    },
  },
];

export const TIPOVI_OBRAZACA_FIELDS: ITipObrascaHelpers[] = [
  ...PREDUZECE_OBRASCI,
  ...GIO_OBRASCI,
  ...OTHER_OBRASCI,
  ...OBRAZAC_OBRASCI,
  ...LRIZ_OBRASCI,
];

export const TIPOVI_OBRAZACA_FIELDS_MAP = arrayToObject<TIPOVI_OBRAZACA, ITipObrascaConfig>(
  TIPOVI_OBRAZACA_FIELDS,
  'name'
);

export const deleteTipoveObrazacaIfActiveListIsEmpty = () => {
  const tipZaBrisanje = [...OBRAZAC_KEYS, ...LRIZ_KEYS, TIPOVI_OBRAZACA.GIO1];
  const newArray: any[] = facilityStore.getFacilityTipoviObrazaca
    .map((item: any) => {
      if (!tipZaBrisanje.includes(item.skraceni_naziv)) {
        return { id: item.id, skraceni_naziv: item.skraceni_naziv };
      }
    })
    .filter(Boolean);
  facilityStore.postFacilityTipoviObrasca(newArray);
};

// todo: prepakovati u objekat key value

export const CUSTOM_OBRASCI_MAKER = (
  type:
    | TIPOVI_OBRAZACA.OBRAZAC_2_GVE
    | TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE
    | TIPOVI_OBRAZACA.OBRAZAC_3_OTV
    | TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV
    | TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1
) => {
  const obj = {
    [TIPOVI_OBRAZACA.OBRAZAC_2_GVE]: {
      ...storage?.getData(StorageEnum.TIPOVI_OBRAZACA)[TIPOVI_OBRAZACA.OBRAZAC_2],
      skraceni_naziv: TIPOVI_OBRAZACA.OBRAZAC_2_GVE,
    },
    [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE]: {
      ...storage?.getData(StorageEnum.TIPOVI_OBRAZACA)[TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2],
      skraceni_naziv: TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE,
    },
    [TIPOVI_OBRAZACA.OBRAZAC_3_OTV]: {
      ...storage?.getData(StorageEnum.TIPOVI_OBRAZACA)[TIPOVI_OBRAZACA.OBRAZAC_3],
      skraceni_naziv: TIPOVI_OBRAZACA.OBRAZAC_3_OTV,
    },
    [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV]: {
      ...storage?.getData(StorageEnum.TIPOVI_OBRAZACA)[TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3],
      skraceni_naziv: TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV,
    },
    [TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1]: {
      ...storage.getData(StorageEnum.TIPOVI_OBRAZACA)[TIPOVI_OBRAZACA.OBRAZAC_5],
      skraceni_naziv: TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1,
    },
  };
  return obj[type];
};
