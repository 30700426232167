import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { OBRAZAC_3_IZMERENE_VREDNOSTI_RECIPIJENTA_INITIAL_STATE } from 'modules/obrasci/store/obrazac3/obrazac3.constants';
import { NewObrazac3AnalizaRecipijenataIzmereneVrednostiForm } from './NewObrazac3AnalizaRecipijenataIzmereneVrednostiForm/NewObrazac3AnalizaRecipijenataIzmereneVrednostiForm';

export const Obrazac3AnalizaRecipijenataIzmereneVrednostiModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="65%"
      name="obrazac3-analiza-recipijenta-izmerene-vrednosti-modal"
      title={t('OBRASCI.GVE.IZMERENE_VREDNOSTI')}
      onCancel={() => obrazac3Store.handleChange('jedinica_mere_option', {})}
      store={obrazac3Store}
      data={obrazac3Store.getIzmereneVrednostiRecipijenta}
      children={() => <NewObrazac3AnalizaRecipijenataIzmereneVrednostiForm />}
      submitForm={obrazac3Store.submitIzmereneVrednostiRecipijenta}
      initialState={OBRAZAC_3_IZMERENE_VREDNOSTI_RECIPIJENTA_INITIAL_STATE}
      dataToChange={'izmerene_vrednosti_recipijenta_form'}
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
      closeOneModal={true}
    />
  );
});
