import { action, makeAutoObservable, observable } from 'mobx';
import { ObrazacIzvestajiAllFilters } from 'modules/obrazacIzvestaji/obrazacIzvestaji.types';
import { IZakljucavanjeObrazacaFilters } from 'modules/zakljucavanje/zakljucavanje.types';

export interface IFiltersBySection {
  korisnik: any;
  korisnik_postrojenje: any;
  preduzeca: any;
  postrojenja: any;
  sifarnici: any;
  obrasci: any;
  lista_aktivnosti_1: any;
  lista_aktivnosti_2: any;
  izvor_voda: any;
  izvor_vazduh: any;
  izvor_tlo: any;
  nesanitarne_deponije: any;
  izvestaji: any;
  registri: any;
  obrazacIzvestaji: ObrazacIzvestajiAllFilters;
  istorijaPromena: any;
  zakljucavanjeObrasci: Partial<IZakljucavanjeObrazacaFilters>;
}

export class FilteriStore {
  filters: IFiltersBySection = {
    korisnik: {},
    korisnik_postrojenje: {},
    preduzeca: {},
    postrojenja: {},
    sifarnici: {},
    obrasci: {},
    lista_aktivnosti_1: {},
    lista_aktivnosti_2: {},
    izvor_voda: {},
    izvor_vazduh: {},
    izvor_tlo: {},
    nesanitarne_deponije: {},
    izvestaji: {},
    registri: {},
    obrazacIzvestaji: {},
    istorijaPromena: {},
    zakljucavanjeObrasci: {},
  };

  constructor() {
    makeAutoObservable(this, {
      filters: observable,
      handleFiltersChange: action,
      handleChange: action,
      setFilters: action,
    });
  }

  handleChange(key: string, value: any) {
    (this as any)[key] = value;
  }

  handleFiltersChange = (type: keyof IFiltersBySection, key: string, value: any) => {
    this.filters[type] = { ...this.filters[type], [key]: value };
  };

  setFilters = (type: keyof IFiltersBySection, filters: any) => {
    this.filters[type] = filters;
  };
}

export const filtersStore = new FilteriStore();
