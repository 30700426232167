import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { Aao2UpravljanjeAmbalaznimOtpadomTable } from './components/Aao2UpravljanjeAmbalaznimOtpadomTable';

export const Aao2UpravljanjeAmbalaznimOtpadomWizardTab = () => {
  return (
    <>
      <InsideContentWrapper
        header={
          <WizardObrazacListHeader
            name={'aao2-upravljanje-ambalaznim-otpadom-form'}
            modalName="aao2-upravljanje-ambalaznim-otpadom-modal"
          />
        }
      >
        <Aao2UpravljanjeAmbalaznimOtpadomTable />
      </InsideContentWrapper>
    </>
  );
};
