import { IGetFacility, IPostFacilityTipoviObrazaca } from 'modules/facility/facility.types.ts';
import { obrasciRepo } from './obrasci.repo';
import {
  CUSTOM_OBRASCI_KEYS,
  IFiltersSameObrazac,
  ISubmitFormObrasca,
  ITipObrasca,
  ITipObrascaConfig,
  ITipObrascaCreate,
  ITipoviObrascaReduceNivoResult,
  ITipoviObrazacaMap,
  TIPOVI_OBRAZACA,
} from './obrasci.types';
import { arrayToObject } from 'utils/arrayToObject';
import { CUSTOM_OBRASCI_MAKER, TIPOVI_OBRAZACA_FIELDS_MAP } from './tipoviObrasci.service';
import { formStore, modalStore } from 'store';
import { nrizService } from 'modules/nriz/nriz.service';
import { OBRAZAC_TYPE_TABLE_COLUMNS } from './tableDataObrasci.constants';
import { OBRASCI_FILTERS_DATA } from './filterDataObrasci.constants';
import { ISifarniciFormData } from 'modules/sifarnici/sifarnici.types';
import { obrasciStore } from './obrasci.store';
import { IGetCompany } from 'modules/company/company.types';
import storage, { StorageEnum } from 'store/storage';
import { IBaseObrazacStore, IPagination } from 'typescript/NrizTypes';
import { OBRAZAC_TYPE_WIZARD_URL_MAP, opsteInitial } from './obrasci.constants';
import to from 'await-to-js';
import { facilityService } from 'modules/facility/facility.service';
import { VALIDATION_OBRAZAC } from './validationObrasci.service';
import { Deo6Gio6Enum } from './store/deo6/deo6.types';
import { IExportDeo6Gio6PdfForm } from 'components/Modals/ExportDeo6Gio6PdfModal/ExportDeo6Gio6PdfModal';
import { IZakljucavanjeGodinaIzuzeci } from 'modules/zakljucavanje/zakljucavanje.types';
import { zakljucavanjeRepo } from 'modules/zakljucavanje/zakljucavanje.repo';
import { mergeArraysWithoutDuplicates } from 'utils/mergeArraysWithoutDuplicates';

class ObrasciService {
  fetchTipoviObrasca = () => {
    return obrasciRepo.fetchTipoviObrasca();
  };

  fetchTipoviObrazacaPoPreduzecu = (id: string) => {
    return obrasciRepo.fetchTipoviObrazacaPoPreduzecu(id);
  };
  fetchTipoviObrazacaPoPostrojenju = (id: string) => {
    return obrasciRepo.fetchTipoviObrazacaPoPostrojenju(id);
  };

  postTipoviObrasca = (obrazac: ITipObrascaCreate) => {
    return obrasciRepo.postTipoviObrasca(obrazac);
  };

  fetchSingleObrazac = <ObrazacResponse = any>(type: string, id: string) => {
    return obrasciRepo.fetchSingleObrazac<ObrazacResponse>(type, id);
  };

  postSingleObrazac = <ObrazacPayload = any>(type: string, payload: ObrazacPayload, filters?: any) => {
    return obrasciRepo.postSingleObrazac<ObrazacPayload>(type, nrizService.setEmptyValuesToNull(payload), filters);
  };

  putSingleObrazac = <ObrazacPayload = any>(type: string, id: string, payload: ObrazacPayload) => {
    return obrasciRepo.putSingleObrazac<ObrazacPayload>(type, id, nrizService.setEmptyValuesToNull(payload));
  };

  putSingleObrazacWithParams = (type: string, id: string, params: string) => {
    return obrasciRepo.putSingleObrazacWithParams(type, id, params);
  };

  deleteSingleObrazac = <ObrazacPayload = any>(type: string, id: string) => {
    return obrasciRepo.deleteSingleObrazac<ObrazacPayload>(type, id);
  };

  fetchListObrazac = <ObrazacPayload = any>(type: string, filters: any) => {
    return obrasciRepo.fetchListObrazac<ObrazacPayload>(type, filters);
  };

  exportObrazacListExcel = <ObrasciFilters = any>(type: TIPOVI_OBRAZACA, filters?: ObrasciFilters) => {
    return obrasciRepo.exportObrazacListExcel(type, filters);
  };

  calculateGodisnjiBilans = <BilansPayload = any>(type: string, payload: any) => {
    return obrasciRepo.calculateGodisnjiBilans<BilansPayload>(type, payload);
  };

  fetchInfoForLastYear = <ObrazacPayload = any>(filters: any) => {
    return obrasciRepo.fetchInforForLastYear<ObrazacPayload>(filters);
  };

  exportPdfSingleObrazac = (type: TIPOVI_OBRAZACA, id: string): Promise<Blob> => {
    return obrasciRepo.exportPdfSingleObrazac(type, id);
  };

  exportPdfDeo6Gio6 = (type: Deo6Gio6Enum, filters: IExportDeo6Gio6PdfForm): Promise<Blob> => {
    return obrasciRepo.exportPdfDeo6Gio6(type, filters);
  };

  submitFormObrazac = (props: ISubmitFormObrasca) => {
    if (formStore.getCrudButtonType === 'submit') {
      return props.postForm(props.payload);
    }

    if (formStore.getCrudButtonType === 'change') {
      return props.putForm(props.payload);
    }
    if (formStore.getCrudButtonType === 'delete') {
      return props.deleteForm(props.payload);
    }
  };

  makeObrasciShortNameMap = (obrasci: ITipObrascaConfig[]) => {
    return arrayToObject(obrasci, 'skraceni_naziv') as ITipoviObrazacaMap;
  };

  filterByNivo = (obrasci: ITipObrascaConfig[]): ITipoviObrascaReduceNivoResult => {
    if (!obrasci) return { preduzece: [], postrojenje: [] };

    return obrasci.reduce<ITipoviObrascaReduceNivoResult>(
      (acc, obrazac) => {
        if (obrazac.nivo === 'preduzece') acc.preduzece.push(obrazac);
        if (obrazac.nivo === 'postrojenje') acc.postrojenje.push(obrazac);
        return acc;
      },
      { preduzece: [], postrojenje: [] }
    );
  };

  getObrazacByShortName = (obrasci: ITipObrasca[], shortName: string) => {
    return obrasci.find(obrazac => obrazac.skraceni_naziv === shortName);
  };

  getTipoviNames = (tipoviId: string[], obrasci: ITipObrasca[]): IPostFacilityTipoviObrazaca => {
    return tipoviId.map(id => {
      const obrazacName = obrasci.find(obrazac => obrazac.id === id)?.skraceni_naziv || '';
      return { id, skraceni_naziv: obrazacName };
    });
  };

  buildTipoviConfig = (tipoviObrazaca: ITipObrasca[]) => {
    return tipoviObrazaca.map(tipO => ({
      ...tipO,
      ...TIPOVI_OBRAZACA_FIELDS_MAP[tipO.skraceni_naziv],
    }));
  };
  tableFinder = (type: string) => {
    return OBRAZAC_TYPE_TABLE_COLUMNS[type];
  };

  submitMicroServiceForm = (initialList: any[], payload: any, closeOneModal?: boolean, modalName?: string) => {
    if (closeOneModal && modalName) {
      modalStore.clearModal(modalName);
    } else {
      modalStore.removeAllModals();
    }

    let newList;
    if (formStore.getCrudButtonType === 'submit') {
      newList = nrizService.addItemToList(initialList, payload);
    }

    if (formStore.getCrudButtonType === 'change') {
      newList = nrizService.changeItemInList(initialList, payload);
    }
    if (formStore.getCrudButtonType === 'delete') {
      newList = nrizService.removeFromList(initialList, payload.id);
    }

    return newList;
  };

  setMiddleValue(state: any) {
    let sum = 0;
    let numberOfEmptyString = 0;

    for (const item in state) {
      const value = state[item];
      if (value === null) {
        numberOfEmptyString += 1;
        continue;
      }
      sum += value;
    }

    return sum / (Object.keys(state).length - numberOfEmptyString);
  }

  switchObrasciType = (type: TIPOVI_OBRAZACA) => {
    switch (type) {
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1:
        return TIPOVI_OBRAZACA.OBRAZAC_1;
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2:
        return TIPOVI_OBRAZACA.OBRAZAC_2;
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE:
        return TIPOVI_OBRAZACA.OBRAZAC_2;
      case TIPOVI_OBRAZACA.OBRAZAC_2_GVE:
        return TIPOVI_OBRAZACA.OBRAZAC_2;
      case TIPOVI_OBRAZACA.GVE:
        return TIPOVI_OBRAZACA.OBRAZAC_2;
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3:
        return TIPOVI_OBRAZACA.OBRAZAC_3;
      case TIPOVI_OBRAZACA.OTV:
        return TIPOVI_OBRAZACA.OBRAZAC_3;
      case TIPOVI_OBRAZACA.OBRAZAC_3_OTV:
        return TIPOVI_OBRAZACA.OBRAZAC_3;
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV:
        return TIPOVI_OBRAZACA.OBRAZAC_3;
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4:
        return TIPOVI_OBRAZACA.OBRAZAC_4;
      case TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5:
        return TIPOVI_OBRAZACA.OBRAZAC_5;
      case TIPOVI_OBRAZACA.GIO1:
        return TIPOVI_OBRAZACA.OBRAZAC_5;

      default:
        return type;
    }
  };

  filtersObrasciFinder = (type: string): ISifarniciFormData => {
    return OBRASCI_FILTERS_DATA[type];
  };

  setObrazacTypeWithUrl = (url: string) => {
    for (const [key, val] of Object.entries(OBRAZAC_TYPE_WIZARD_URL_MAP)) {
      if (url.includes(val)) {
        this.handleSameObrazac(key as TIPOVI_OBRAZACA);
      }
    }
  };

  handleValidation = () => {
    const currentValidationRules = VALIDATION_OBRAZAC[obrasciStore.obrazac_type];
    if (currentValidationRules.length === 0) return true;

    const validationResults = currentValidationRules.map(rule => rule.isValid());
    const isValid = validationResults.every(result => result);
    if (!isValid) {
      obrasciStore.handleChange('isValidationNotificationOpen', true);
    }
    return isValid;
  };

  handleSameObrazac = (type: TIPOVI_OBRAZACA) => {
    const tip = this.switchObrasciType(type);
    const originalTipObrascaObj = CUSTOM_OBRASCI_KEYS.includes(type)
      ? CUSTOM_OBRASCI_MAKER(type as any)
      : obrasciStore.obrasciShortNameMap[type];
    const tipObrascaObj = obrasciStore.obrasciShortNameMap[tip];

    obrasciStore.handleChange('tip_obrasca_id', tipObrascaObj.id);
    obrasciStore.handleChange('obrazac_type', tip);
    obrasciStore.handleChange('real_tip_obrasca_id', originalTipObrascaObj.id);
    obrasciStore.handleChange('real_obrazac_type', type);
  };

  filtersSameObrazac = (obrazacType: TIPOVI_OBRAZACA): IFiltersSameObrazac | undefined => {
    switch (obrasciStore.obrazac_type) {
      case TIPOVI_OBRAZACA.OBRAZAC_1:
        return {
          lriz_obrazac_1: obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_1,
          obrazac_1: obrazacType === TIPOVI_OBRAZACA.OBRAZAC_1,
        };

      case TIPOVI_OBRAZACA.OBRAZAC_2:
        return {
          obrazac_gve:
            obrazacType === TIPOVI_OBRAZACA.GVE ||
            obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE ||
            obrazacType === TIPOVI_OBRAZACA.OBRAZAC_2_GVE,
          lriz_obrazac_2:
            obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE || obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2,
          obrazac_2: obrazacType === TIPOVI_OBRAZACA.OBRAZAC_2_GVE || obrazacType === TIPOVI_OBRAZACA.OBRAZAC_2,
        };
      case TIPOVI_OBRAZACA.OBRAZAC_3:
        return {
          obrazac3_lriz:
            obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3 || obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV,
          otv:
            obrazacType === TIPOVI_OBRAZACA.OTV ||
            obrazacType === TIPOVI_OBRAZACA.OBRAZAC_3_OTV ||
            obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV,
          obrazac3: obrazacType === TIPOVI_OBRAZACA.OBRAZAC_3 || obrazacType === TIPOVI_OBRAZACA.OBRAZAC_3_OTV,
        };
      case TIPOVI_OBRAZACA.OBRAZAC_4:
        return {
          lriz_obrazac_4: obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_4,
          obrazac_4: obrazacType === TIPOVI_OBRAZACA.OBRAZAC_4,
        };
      case TIPOVI_OBRAZACA.OBRAZAC_5:
        return {
          obrazac_gio1: obrazacType === TIPOVI_OBRAZACA.GIO1 || obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1,
          lriz_obrazac_5:
            obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5 || obrazacType === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1,
          obrazac_5: obrazacType === TIPOVI_OBRAZACA.OBRAZAC_5,
        };
      default:
        break;
    }
  };

  combineTwoObrazacTypeIntoOne = (arrayObrazaca: ITipObrasca[]): ITipObrasca[] => {
    const obrazac2Index = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_2
    );
    const lrizObrazac2Index = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2
    );
    const obrazacGveIndex = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.GVE
    );

    if (obrazac2Index !== -1 && obrazacGveIndex !== -1) {
      arrayObrazaca[obrazac2Index] = CUSTOM_OBRASCI_MAKER(TIPOVI_OBRAZACA.OBRAZAC_2_GVE) as ITipObrasca;
      arrayObrazaca.splice(obrazacGveIndex, 1);
    }

    if (lrizObrazac2Index !== -1 && obrazacGveIndex !== -1) {
      arrayObrazaca[obrazac2Index] = CUSTOM_OBRASCI_MAKER(TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE) as ITipObrasca;
      arrayObrazaca.splice(obrazacGveIndex, 1);
    }

    // OBRAZAC 3 , LRIZ OBRAZAC 3 , OTV

    const obrazac3Index = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_3
    );
    const lrizObrazac3Index = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3
    );
    const obrazacOtvIndex = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.OTV
    );

    if (obrazac3Index !== -1 && obrazacOtvIndex !== -1) {
      arrayObrazaca[obrazac3Index] = CUSTOM_OBRASCI_MAKER(TIPOVI_OBRAZACA.OBRAZAC_3_OTV) as ITipObrasca;
      arrayObrazaca.splice(obrazacOtvIndex, 1);
    }

    if (lrizObrazac3Index !== -1 && obrazacOtvIndex !== -1) {
      arrayObrazaca[obrazac3Index] = CUSTOM_OBRASCI_MAKER(TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV) as ITipObrasca;
      arrayObrazaca.splice(obrazacOtvIndex, 1);
    }

    // LRIZ OBRAZAC 5 , GIO1

    const lrizObrazac5Index = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5
    );
    const gio1ObrazacIndex = arrayObrazaca.findIndex(
      (item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.GIO1
    );

    if (lrizObrazac5Index !== -1 && gio1ObrazacIndex !== -1) {
      arrayObrazaca[obrazac2Index] = CUSTOM_OBRASCI_MAKER(TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1) as ITipObrasca;
      arrayObrazaca.splice(gio1ObrazacIndex, 1);
    }

    return arrayObrazaca;
  };

  checkPostrojenjeTipObrascaSameObrazac = async () => {
    if (
      obrasciStore.obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_2 ||
      obrasciStore.obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_5 ||
      obrasciStore.obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_3
    ) {
      const [err, res] = await to(facilityService.fetchFacilityTipObrasca(obrasciStore.opste.postrojenje_id));
      if (err || !res) return Promise.reject();

      let sameObrazac:
        | TIPOVI_OBRAZACA.OBRAZAC_2_GVE
        | TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE
        | TIPOVI_OBRAZACA.OBRAZAC_3_OTV
        | TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV
        | TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1
        | null = null;

      if (obrasciStore.obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_2) {
        const obrazac2 = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_2);
        const lrizObrazac2 = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2);
        const gve = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.GVE);

        if (obrazac2 && gve) {
          sameObrazac = TIPOVI_OBRAZACA.OBRAZAC_2_GVE;
        }

        if (lrizObrazac2 && gve) {
          sameObrazac = TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE;
        }
      }
      if (obrasciStore.obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_3) {
        const obrazac3 = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.OBRAZAC_3);
        const lrizObrazac3 = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3);
        const otv = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.OTV);

        if (obrazac3 && otv) {
          sameObrazac = TIPOVI_OBRAZACA.OBRAZAC_3_OTV;
        }

        if (lrizObrazac3 && otv) {
          sameObrazac = TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV;
        }
      }

      if (obrasciStore.obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_5) {
        const gio1 = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.GIO1);
        const lrizObrazac5 = res.find((item: ITipObrasca) => item?.skraceni_naziv === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5);
        if (gio1 && lrizObrazac5) {
          sameObrazac = TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1;
        }
      }
      if (sameObrazac) {
        this.handleSameObrazac(sameObrazac);
      }
    }
  };

  obrasciFilterModifyPayload = (payload: any) => {
    if (obrasciStore.obrazac_type === TIPOVI_OBRAZACA.DEO6_GIO6) {
      const datum = payload.datum && payload.datum.split('T');
      for (const key in payload) {
        if (key === 'datum') {
          payload[key] = datum[0];
        }
      }
    }

    if (obrasciStore.obrazac_type === TIPOVI_OBRAZACA.DKO) {
      const datum = payload.datum_predaje_otpada && payload.datum_predaje_otpada.split('T');
      for (const key in payload) {
        if (key === 'datum_predaje_otpada') {
          payload[key] = datum[0];
        }
      }
    }

    return payload;
  };

  setOpsteObrazacWizardUser = (store: IBaseObrazacStore) => {
    let preduzecePostrojenje = opsteInitial;

    const storagePostrojenje = storage.getData(StorageEnum.POSTROJENJE) as IGetFacility;
    if (storagePostrojenje) {
      preduzecePostrojenje = {
        ...preduzecePostrojenje,
        postrojenje: storagePostrojenje,
        postrojenje_id: storagePostrojenje.id,
      };
    }

    const storagePreduzece = storage.getData(StorageEnum.PREDUZECE) as IGetCompany;
    if (storagePreduzece) {
      preduzecePostrojenje = {
        ...preduzecePostrojenje,
        preduzece: storagePreduzece,
        preduzece_id: storagePreduzece.id,
      };
    }

    const newInitialState = {
      ...store.initialState,
      ...preduzecePostrojenje,
    };

    obrasciStore.handleChange('opste', preduzecePostrojenje);
    store.handleChange('initialState', newInitialState);
  };

  resetObrazacWizardAdmin = () => {
    obrasciStore.handleChange('opste', opsteInitial);

    const mainTipObrasca =
      obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_2_GVE
        ? TIPOVI_OBRAZACA.OBRAZAC_2
        : obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2_GVE
        ? TIPOVI_OBRAZACA.LRIZ_OBRAZAC_2
        : obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.OBRAZAC_3_OTV
        ? TIPOVI_OBRAZACA.OBRAZAC_3
        : obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3_OTV
        ? TIPOVI_OBRAZACA.LRIZ_OBRAZAC_3
        : obrasciStore.real_obrazac_type === TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5_GIO1
        ? TIPOVI_OBRAZACA.LRIZ_OBRAZAC_5
        : '';

    if (mainTipObrasca) {
      this.handleSameObrazac(mainTipObrasca);
    }
  };

  checkGodinaZakljucana = async (filters: IPagination & Partial<IZakljucavanjeGodinaIzuzeci>): Promise<number[]> => {
    // poziv koji dohvata otkljucane godine po tipu obrasca
    if (!filters.tip_obrasca_id) return [];
    const [err, res] = await to(obrasciRepo.checkGodinaZakljucana(filters.tip_obrasca_id));

    if (err) return [];
    const otkljucaneGodine = res.reduce((listaGodina: number[], godina) => {
      if (!godina.zakljucana_godina) return [...listaGodina, godina.godina];
      return listaGodina;
    }, []);

    // poziv koji dohvata otkljucane godine iz tabele izuzetaka po tipu obrasca i preduzecu
    const [error, response] = await to(zakljucavanjeRepo.fetchIzuzeciPoGodini(filters));

    if (error) return otkljucaneGodine;

    const godineIzIzuzetaka = response.items.map(izuzetak => izuzetak.godina);

    const availableYears = mergeArraysWithoutDuplicates(otkljucaneGodine, godineIzIzuzetaka) as number[];

    return availableYears;
  };

  checkIfObrazacLocked = (id: string) => {
    return obrasciRepo.checkIfObrazacLocked(id);
  };

  deleteObrazac = (type: TIPOVI_OBRAZACA, id: string) => {
    return obrasciRepo.deleteObrazac(type, id);
  };
}

export const obrasciService = new ObrasciService();
