import { Col, Select, Form, FormInstance } from 'antd';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';

import { observer } from 'mobx-react-lite';
import { PHONE_FIELD_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { ProizvodjacVlasnikOptions } from 'modules/obrasci/store/dko/dko.constants';
import { dkoObrazacStore } from 'modules/obrasci/store/dko/dko.store';
import { IGetDkoObrazac } from 'modules/obrasci/store/dko/dko.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { disabledTodayAndFutureDates, disabledTomorrowAndPreviousDates } from 'utils/formatDate';
import styles from './DkoDeoBWizardTab.module.scss';
import { drListOptions } from 'utils/optionMakers';
import { useParams } from 'react-router-dom';

type FormProps = {
  form: FormInstance;
};

export const DkoDeoBWizardTab: React.FC<FormProps> = observer(({ form }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const defaultDrListOption: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState
    .predvidjeni_nacin_postupanja_id
    ? {
        value: dkoObrazacStore.getInitialState.predvidjeni_nacin_postupanja_id,
        label: `${(dkoObrazacStore.getInitialState as IGetDkoObrazac).predvidjeni_nacin_postupanja.name} ${
          (dkoObrazacStore.getInitialState as IGetDkoObrazac).predvidjeni_nacin_postupanja.description_sr
        }`,
      }
    : undefined;

  useEffect(() => {
    if (dkoObrazacStore.initialState.preduzece_id) {
      form.setFieldValue(
        'proizvodjac_vlasnik_otpada_preduzece',
        (dkoObrazacStore.initialState as IGetDkoObrazac).preduzece.naziv
      );
    }
  }, [dkoObrazacStore.initialState.preduzece_id]);

  const handleDateChange = (e: any) => {
    dkoObrazacStore.setDatumB(e.$d);
  };

  return (
    <Col span={24} className={styles.wrapper}>
      <h2>{`${t('WIZARD_TABS.DEO_B')} - ${t('OBRASCI.DKO.PODACI_O_PROIZVODJACU_VLASNIKU')}`}</h2>
      <FormInput
        name={'proizvodjac_vlasnik_otpada_preduzece'}
        inputName={'proizvodjac_vlasnik_otpada_preduzece'}
        disabled={true}
        label={t('OBRASCI.DKO.PREDUZECE_PROIZVODJAC')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      />
      <Col span={24}>
        <Form.Item
          name={'proizvodjac_vlasnik_otpada'}
          label={t('OBRASCI.DKO.PROIZVODJAC_VLASNIK_OTPADA_JE')}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          rules={[REQUIRED_FIELD_RULE(true)]}
        >
          <Select style={{ width: 120 }} options={ProizvodjacVlasnikOptions} />
        </Form.Item>
      </Col>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'predvidjeni_nacin_postupanja_id'}
        fetchOptinsList={dkoObrazacStore.fetchDrList}
        labelOptionAccessor="name"
        valueOptionAccessor="id"
        rules={[REQUIRED_FIELD_RULE(true)]}
        inputName={'predvidjeni_nacin_postupanja_id'}
        label={t('OBRASCI.DKO.PREDVIDJENI_NACIN_POSTUPANJA')}
        colon={true}
        defaultOption={defaultDrListOption}
        placeholder={''}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        optionsMaker={drListOptions}
      />
      <h3>{t('OBRASCI.DKO.DOZVOLA_ZA_UPRAVLJANJE')}</h3>
      <FormInput
        label={t('OBRASCI.DKO.BROJ_DOZVOLE')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'broj_dozvole'}
        type="text"
        inputName={'broj_dozvole'}
        placeholder=""
      />
      <FormDatePicker
        name="datum_izdavanja_dozvole"
        label={t('OBRASCI.DKO.DATUM_IZDAVANJA_DOZVOLE')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        format="DD-MM-YYYY"
        picker={'date'}
        disabledDate={disabledTodayAndFutureDates}
      />
      <FormDatePicker
        name="datum_predaje_otpada"
        label={t('OBRASCI.DKO.DATUM_PREDAJE_OTPADA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        format="DD-MM-YYYY"
        onChange={handleDateChange}
        picker={'date'}
        disabledDate={disabledTomorrowAndPreviousDates}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <h3>{t('OBRASCI.DKO.ODGOVORNO_LICE_PROIZVODJAC')}</h3>
      <FormInput
        label={t('OBRASCI.DKO.IME_PREZIME')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        name={'ime_prezime'}
        inputName={'ime_prezime'}
        placeholder=""
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormInput
        label={t('OBRASCI.DKO.BROJ_MOBILNOG_TELEFONA')}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        rules={id ? [REQUIRED_FIELD_RULE(true)] : [REQUIRED_FIELD_RULE(true), PHONE_FIELD_RULE]}
        name={'broj_mobilnog_telefona'}
        type="text"
        inputName={'broj_mobilnog_telefona'}
        placeholder=""
      />
    </Col>
  );
});
