import { IPagination } from 'typescript';
import { companyRepo } from './company.repo';
import { ICompanyTipoviObrasca, IGetCompany, IPostCompany } from './company.types';

class CompanyService {
  postPib = (payload: any) => {
    return companyRepo.postPib(payload);
  };

  companyRegistration = (payload: IPostCompany) => {
    return companyRepo.companyRegistration(payload);
  };

  fetchCompaniesList = (payload: Partial<IPagination>) => {
    return companyRepo.fetchCompaniesList(payload);
  };

  exportCompanyExcel = (payload: Partial<IPagination>) => {
    return companyRepo.exportCompanyExcel(payload);
  };

  checkCompany = () => {
    return companyRepo.checkCompany();
  };

  companyApprove = (id: string) => {
    return companyRepo.companyApprove(id);
  };
  companyReject = (id: string) => {
    return companyRepo.companyReject(id);
  };

  fetchCompany = (id: string) => {
    return companyRepo.fetchCompany(id);
  };

  fetchCompanyTipObrasca = (companyId: string) => {
    return companyRepo.fetchCompanyTipObrasca(companyId);
  };

  postCompanyTipObrasca = (companyId: string, tipoviObrazca: ICompanyTipoviObrasca) => {
    return companyRepo.postCompanyTipObrasca(companyId, tipoviObrazca);
  };

  putCompany = (company: IGetCompany) => {
    return companyRepo.putCompany(company);
  };

  deleteCompany = (id: string) => {
    return companyRepo.deleteCompany(id);
  };

  aktivirajPreduzece = (id: string) => {
    return companyRepo.aktivirajPreduzece(id);
  };
  deaktivirajPreduzece = (id: string) => {
    return companyRepo.deaktivirajPreduzece(id);
  };

  // todo: sifarnici prepakuj

  fetchLocationList = (payload: Partial<IPagination>) => {
    return companyRepo.fetchLocationList(payload);
  };

  fetchPretezneDelatnostiList = (payload: Partial<IPagination>) => {
    return companyRepo.fetchPretezneDelatnostiList(payload);
  };

  checkForDeo6 = (id: string) => {
    return companyRepo.checkForDeo6(id);
  };

  toggleDeo6Validation = (id: string, isValidationDisabled: boolean) => {
    const params = { privremeno_ukinuta_validacija_za_deo6: isValidationDisabled };
    return companyRepo.toggleDeo6Validation(id, params);
  };
}

export const companyService = new CompanyService();
