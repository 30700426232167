import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { gio5ObrazacStore } from 'modules/obrasci/store/gio5/gio5.store';
import { useTranslation } from 'react-i18next';
import { GIO_5_UVOZ_OTPADA_INITIAL_STATE } from 'modules/obrasci/store/gio5/gio5.constants';
import { NewGio5UvozOtpadaForm } from './NewGio5UvozOtpadaForm/NewGio5UvozOtpadaForm';
import { FormInstance } from 'antd';
import { IGio5UvozOtpada, IPostGio5UvozOtpada } from 'modules/obrasci/store/gio5/gio5.types';

export const Gio5UvozOtpadaModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  return (
    <CRUDModal
      width="80%"
      name="gio5-uvoz-otpada-modal"
      title={t('WIZARD_TABS.UVOZ_OTPADA')}
      store={gio5ObrazacStore}
      data={gio5ObrazacStore.getUvozOtpada}
      children={({ form }: { form: FormInstance<IGio5UvozOtpada> }) => <NewGio5UvozOtpadaForm form={form} />}
      submitForm={(payload: IPostGio5UvozOtpada) => {
        if (gio5ObrazacStore.getImaTranzita && gio5ObrazacStore.getUvozOtpada.zemlje_tranzita.length === 0) return;
        gio5ObrazacStore.submitUvozOtpada(payload);
      }}
      initialState={GIO_5_UVOZ_OTPADA_INITIAL_STATE}
      dataToChange={'uvozOtpada'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    />
  );
});
