import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { IFilterTableProps } from 'typescript/NrizTypes';

export const FacilityFiltersTable: React.FC = observer(() => {
  const { t } = useTranslation();

  const [facilityForm] = useForm();
  const facilityFilterOptions = [
    {
      label: t('FACILITY.TITLE'),
      name: 'naziv_postrojenja',
      type_of_form: 'input',
      inputName: 'naziv_postrojenja',
      sortName: 'naziv_postrojenja',
    },
  ];
  const data = {
    form: facilityForm,
    initialState: { naziv_postrojenja: '', order_by: '' },
    filterName: 'postrojenja',
    filterOptions: facilityFilterOptions,
  } as IFilterTableProps;

  return <FilterTableForm data={data} />;
});
