import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { TablePostrojenjaGio3 } from './components/TablePostrojenjaGio3/TablePostrojenjaGio3';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';

export const Gio3PostrojenjaWizardTab = () => {
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'gio3-postrojenja-form'} modalName="gio3-postrojenja-modal" />}
    >
      <TablePostrojenjaGio3 />
    </InsideContentWrapper>
  );
};
