import { observer } from 'mobx-react-lite';
import React from 'react';
import { EmisijeUTloModal } from './EmisijeUTloModal/EmisijeUTloModal';

export const Obrazac4MicroServices: React.FC = observer(() => {
  return (
    <>
      <EmisijeUTloModal />
    </>
  );
});
