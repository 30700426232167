import { makeAutoObservable } from 'mobx';
import { otvService } from './otv.service';
import { IOTVRegistar, OtvOpsteInfo } from './otv.types';
import { IPagination } from 'typescript';
import { nrizService } from 'modules/nriz/nriz.service';
import { filtersStore } from 'store/filteri.store';
import { ResponseCompany } from 'modules/company/company.types';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { ResponseFacility } from 'modules/facility/facility.types';
import { ResponsePollutantCode } from 'modules/sifarnici/store/pollutantCode/pollutantCode.types';
import { OTV_OPSTE_INITIAL } from './otv.constants';

class OTVStore {
  otvList: IOTVRegistar[] = [];
  otvRegistar: IOTVRegistar | undefined = undefined;

  otvOpsteInfo: OtvOpsteInfo = OTV_OPSTE_INITIAL;

  filters: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  handleOtvOpsteChange = (key: keyof OtvOpsteInfo, value: string) => {
    this.otvOpsteInfo = { ...this.otvOpsteInfo, [key]: value };
  };

  setOtvOpste = (opsteInfo: OtvOpsteInfo) => {
    this.otvOpsteInfo = opsteInfo;
  };

  setOtvList = (newOtvList: IOTVRegistar[]) => {
    this.otvList = newOtvList;
  };

  setOtvRegistar = (newRegistarValue: IOTVRegistar | undefined) => {
    this.otvRegistar = newRegistarValue;
  };

  setFilters = (newFilters: any) => {
    this.filters = { ...this.filters, ...newFilters };
  };

  fetchOtvList = async (pagination: IPagination) => {
    const data = await otvService.fetchOtvList({
      ...pagination,
      ...nrizService.pickFields(filtersStore.filters.registri),
    });
    this.setOtvList(data.items);
    return data;
  };

  clearOtvStates = () => {
    this.setOtvRegistar(undefined);
    this.setOtvOpste(OTV_OPSTE_INITIAL);
  };

  fetchOtvById = async (id: string) => {
    const otvRegistar = await otvService.fetchOtvById(id);
    this.setOtvRegistar(otvRegistar);
    if (!otvRegistar) return;
    const { preduzece_id, postrojenje_id, izvor_zagadjenja_vode_id } = otvRegistar;
    this.setOtvOpste({
      preduzece_id,
      postrojenje_id,
      izvor_zagadjenja_vode_id,
    });
    return otvRegistar;
  };

  fetchPreduzecaOtv = async (pagination: IPagination, search: string): Promise<ResponseCompany> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.PREDUZECE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
    });

    return response;
  };

  fetchPostrojenjaOtv = async (pagination: IPagination, search: string): Promise<ResponseFacility> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.POSTROJENJE, {
      preduzece_id: this.otvOpsteInfo.preduzece_id,
      page: pagination.page,
      size: pagination.size,
      search: search,
    });

    return response;
  };

  fetchIzvoriZagadjenjaVodeOtv = async (pagination: IPagination, search: string): Promise<ResponseFacility> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE, {
      postrojenje_id: this.otvOpsteInfo.postrojenje_id,
      page: pagination.page,
      size: pagination.size,
      search: search,
    });

    return response;
  };

  fetchPollutantCode = async (pagination: IPagination, search: string): Promise<ResponsePollutantCode> => {
    const response = await sifarniciStore.fetchSifarnikOptions(SIFARNICI_ROUTES.POLLUTANT_CODE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      code: 'WATER',
      order_by: '+name_sr',
    });

    return response;
  };

  postOtv = async (payload: IOTVRegistar) => {
    return await otvService.postOtv(payload);
  };

  updateOtv = async (payload: IOTVRegistar) => {
    return await otvService.updateOtv(payload);
  };
}
export const otvStore = new OTVStore();
