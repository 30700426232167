import { ColumnsType } from 'antd/es/table';
import { IGetTypeOfPacking, IPostTypeOfPacking } from './typeOfPacking.types';
import { FormInstance } from 'antd';
import { InputSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import { MIN_MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';

export const TYPE_OF_PACKING_INITIAL_STATE: IPostTypeOfPacking = {
  type: '',
  type_sr: '',
  subtype: '',
  subtype_sr: '',
  display_type: '',
  display_type_sr: '',
  group_number: '',
  sub_group_number: '',
  index_number: '',
  is_active: true,
};
export const TYPE_OF_PACKING_TABLE_DATA: ColumnsType<IGetTypeOfPacking> = [
  {
    title: 'Tip pakovanja',
    width: '100xp',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Tip pakovanja (srp.)',
    width: '250px',
    dataIndex: 'type_sr',
    key: 'type_sr',
  },
  {
    title: 'Podtip pakovanja',
    width: '150px',
    dataIndex: 'subtype',
    key: 'subtype',
  },
  {
    title: 'Podtip pakovanja (srp.)',
    width: '250px',
    dataIndex: 'subtype_sr',
    key: 'subtype_sr',
  },
  {
    title: 'Tip ekrana',
    width: '200px',
    dataIndex: 'display_type',
    key: 'display_type',
  },
  {
    title: 'Tip ekrana (srp.)',
    width: '200px',
    dataIndex: 'display_type_sr',
    key: 'display_type_sr',
  },
  {
    title: 'Broj grupe',
    width: '150px',
    dataIndex: 'group_number',
    key: 'group_number',
  },

  {
    title: 'Broj podgrupe',
    width: '150px',
    dataIndex: 'sub_group_number',
    key: 'sub_group_number',
  },

  {
    title: 'Indeksni broj',
    width: '150px',
    dataIndex: 'index_number',
    key: 'index_number',
  },
];
export const TYPE_OF_PACKING_FORM_OPTIONS = (_: FormInstance<IGetTypeOfPacking>) => {
  return [
    {
      type_of_form: 'input',
      name: 'type',
      // todo: prevod
      label: 'Tip pakovanja',
      inputName: 'type',
      rules: [MIN_MAX_LENGTH_RULE(3, 100)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'type_sr',
      // todo: prevod
      label: 'Tip pakovanja (srp.)',
      inputName: 'type_sr',
      rules: [MIN_MAX_LENGTH_RULE(3, 100)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'subtype',
      // todo: prevod
      label: 'Podtip pakovanja',
      inputName: 'subtype',
      rules: [MIN_MAX_LENGTH_RULE(3, 100)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'subtype_sr',
      // todo: prevod
      label: 'Podtip pakovanja (srp.)',
      inputName: 'subtype_sr',
      rules: [MIN_MAX_LENGTH_RULE(3, 100)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'display_type',
      // todo: prevod
      label: 'Tip ekrana',
      inputName: 'display_type',
      rules: [MIN_MAX_LENGTH_RULE(3, 100)],
    } as InputSifarniciFormProps,

    {
      type_of_form: 'input',
      name: 'display_type_sr',
      // todo: prevod
      label: 'Tip ekrana (srp.)',
      inputName: 'display_type_sr',
      rules: [MIN_MAX_LENGTH_RULE(3, 100)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'group_number',
      // todo: prevod
      label: 'Broj grupe',
      inputName: 'group_number',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'sub_group_number',
      // todo: prevod
      label: 'Broj podgrupe',
      inputName: 'sub_group_number',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'index_number',
      // todo: prevod
      label: 'Indeksni broj',
      inputName: 'index_number',
    } as InputSifarniciFormProps,
  ];
};
