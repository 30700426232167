import { Modal } from 'components/Modal/Modal';
import { NewFacilityForm } from 'components/NewForms';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';
import styles from './FacilityModal.module.scss';

export const FacilityModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <Modal
      name="facility-modal"
      open={modalStore.getCurrentOpenedModal.includes('facility-modal')}
      className={`.and-modal-wrap .ant-modal ${styles.facility_modal}`}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => modalStore.removeAllModals()}
      title={t('FACILITY.TITLE')}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      width={'70%'}
    >
      <NewFacilityForm readOnly={false} />
    </Modal>
  );
});
