import { Row } from 'antd';

import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { observer } from 'mobx-react-lite';
import { dep1Store } from 'modules/obrasci/store/dep1/dep1.store';
import { useTranslation } from 'react-i18next';

import styles from './HeaderNaseljeDep1.module.scss';

import React from 'react';
import { modalStore } from 'store';

export const HeaderNaseljeDep1: React.FC = observer(() => {
  const { t } = useTranslation();
  const hasError = dep1Store.initialState.obrazac_dep1_mesta.length === 0;

  return (
    <>
      <Row className={hasError ? styles.errorContainer : styles.buttonContainer}>
        {hasError && <span className={styles.naselje_error}>{t('OBRASCI.DEP1.MORA_IMATI_MESTO')}</span>}
        <AddNewButton
          name={'naselje-form'}
          onButtonClick={() => {
            modalStore.changeModalName('naselje-modal');
          }}
        />
      </Row>
    </>
  );
});
