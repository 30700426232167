import { ColProps, Form } from 'antd';
import { FormInstance, useForm } from 'antd/es/form/Form';
import { CRUDButton } from 'components/Buttons/CRUDButton/CRUDButton';
import { Modal } from 'components/Modal/Modal';
import { observer } from 'mobx-react-lite';
import { nrizService } from 'modules/nriz/nriz.service';
import React, { useEffect } from 'react';
import { formStore, modalStore } from 'store';
import { IBaseObrazacStore } from 'typescript/NrizTypes';

export interface ICrudTypeModalProps {
  form: FormInstance<any> | any;
}

export interface ICRUDModalProps {
  name: string;
  width?: string;
  title: string;
  store: IBaseObrazacStore;
  children: ({ form }: ICrudTypeModalProps) => JSX.Element;
  submitForm: (e: any) => void;
  data: any;
  initialState: any;
  dataToChange: string;
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  closeOneModal?: boolean;
  validation?: () => any;
  onCancel?: () => any;
}

export const CRUDModal: React.FC<ICRUDModalProps> = observer(props => {
  const [form] = useForm();

  const onFinishFaildIfDelete = () => {
    const newValues = form.validateFields().then(() => {
      form.setFields(
        form.getFieldsValue().reduce((acc: any, value: any, key: any) => {
          acc[key] = {
            value,
            error: [],
            rules: [],
          };
          return acc;
        }, {})
      );
    });
    return props.submitForm(newValues);
  };

  useEffect(() => {
    return form.setFieldsValue({ ...props.data });
  }, [props.data]);

  return (
    <Modal
      name={props.name}
      open={modalStore.getCurrentOpenedModal.includes(props.name)}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={true}
      onCancel={() => {
        props.store.handleChange(props.dataToChange, props.initialState);
        if (props.closeOneModal) {
          return modalStore.clearModal(props.name);
        }
        if (props.onCancel) {
          props.onCancel();
        }
        modalStore.removeAllModals();
      }}
      title={<h2>{props.title}</h2>}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      width={props.width}
      // forceRender
    >
      <Form
        form={form}
        initialValues={props.initialState}
        name={props.name}
        onFinish={values => {
          return props.submitForm(nrizService.setEmptyValuesToNull({ ...props.initialState, ...values }));
        }}
        labelCol={props.labelCol}
        wrapperCol={props.wrapperCol}
        labelAlign="left"
        onFinishFailed={() => {
          if (formStore.getCrudButtonType === 'delete') {
            onFinishFaildIfDelete();
          }
          if (props.validation) {
            props.validation();
          }
        }}
      >
        {<props.children form={form} />}
        <CRUDButton form={form} showButton={!!props.data.id} />
      </Form>
    </Modal>
  );
});
