import { Col, Row } from 'antd';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { observer } from 'mobx-react-lite';
import { opremaIMehanizacijaOptions } from 'modules/obrasci/store/kom1/kom1.constants';
import styles from './Kom1OpremaIMehanizacijaWizardTab.module.scss';
import React from 'react';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { MORE_THEN_0 } from 'modules/nriz/nriz.constants';
import { obrasciStore } from 'modules/obrasci/obrasci.store';

export const Kom1OpremaIMehanizacijaWizardTab: React.FC = observer(() => {
  return (
    <>
      {opremaIMehanizacijaOptions.map((item, index) => {
        if (item.key === 2 && Number(obrasciStore.opste.godina) < 2024) return;
        return (
          <InsideContentWrapper key={index}>
            <div className={styles.wrapper}>
              <Row key={item.key} className={styles.header}>
                <Col span={15}>
                  <span>{item.title}</span>
                </Col>
                <Col span={6}>
                  <span>/ {item.rightHeader}</span>
                </Col>
              </Row>
              <div className={styles.body}>
                {item.children.map((child, index) => {
                  return (
                    <div key={index}>
                      <FormInputNumber
                        inputName={child.name}
                        label={child.label}
                        labelCol={{ span: 15 }}
                        wrapperCol={{ span: 6 }}
                        name={child.name}
                        precision={0}
                        rules={MORE_THEN_0}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </InsideContentWrapper>
        );
      })}
    </>
  );
});
