import { observer } from 'mobx-react-lite';
import React from 'react';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { TableProizvodiObrazac1 } from './components/TableProizvodiObrazac1/TableProizvodiObrazac1';

export const Obrazac1ProizvodiWizardTab: React.FC = observer(() => {
  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'proizvodi-form'} modalName="proizvodi-obrazac-1-modal" />}
    >
      <TableProizvodiObrazac1 />
    </InsideContentWrapper>
  );
});
