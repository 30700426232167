import { FileExcelOutlined } from '@ant-design/icons';
import { Button } from 'components/Button/Button';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from './ExcelButton.module.scss';

interface IExcelButtonProps {
  name: string;
  onClick?: () => any;
}

export const ExcelButton: React.FC<IExcelButtonProps> = observer(props => {
  return (
    <div className={styles.excel}>
      <Button name={`${props.name}-excel`} onClick={props.onClick}>
        Excel
        <FileExcelOutlined rev={undefined} />
      </Button>
    </div>
  );
});
