import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { observer } from 'mobx-react-lite';
import { NASELJE_INITIAL_STATE } from 'modules/obrasci/store/kom1/kom1.constants';
import { kom1ObrazacStore } from 'modules/obrasci/store/kom1/kom1.store';
import { useTranslation } from 'react-i18next';
import { NewNaseljeForm } from './components/NewNaseljeForm/NewNaseljeForm';

export const NaseljeModal: React.FC = observer(() => {
  const { t } = useTranslation();
  t;
  // todo: prevod
  return (
    <CRUDModal
      width="70%"
      name="naselja-modal"
      title={'Naselja'}
      store={kom1ObrazacStore}
      data={kom1ObrazacStore.getNaseljeForm}
      children={({ form }) => <NewNaseljeForm form={form} />}
      submitForm={kom1ObrazacStore.submitNaseljeForm}
      initialState={NASELJE_INITIAL_STATE}
      dataToChange={'naselja_form'}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
    />
  );
});
