export const deactivateItems = (items: any): any[] => {
  const newArray = items.map((item: any) => {
    return { ...item, is_active: false };
  });
  return newArray;
};

export const deaktivirajItems = (items: any): any[] => {
  const newArray = items.map((item: any) => {
    return { ...item, aktivan: false };
  });
  return newArray;
};
