// POST

import {
  IPostAao2,
  IPostAao2KolicinaAmbalaze,
  IPostAao2UpravljanjeAmbalaznimOtpadom,
  IPostPravnoLiceIliPreduzetnik,
} from './aao2.types';

export const AAO2_INITIAL_STATE: IPostAao2 = {
  godina: '',
  preduzece_id: '',
  broj_dozvole: '',
  datum_izdavanja: '',
  napomena: '',
  kolicina_ambalaze: [],
  upravljanje_ambalaznim_otpadom: [],
  pravno_lice_ili_preduzetnik: [],
};

export const AAO2_KOLICINA_AMBALAZA_INITIAL_STATE: IPostAao2KolicinaAmbalaze = {
  type_of_packing_id: '',
  proizvedena_kolicina: null,
  uvezena_kolicina: null,
  izvezena_kolicina: null,
};

export const AAO2_UPRAVLJANJE_AMBALAZNIM_OTPADOM_INITIAL_STATE: IPostAao2UpravljanjeAmbalaznimOtpadom = {
  type_of_packing_id: '',
  preuzeta_kolicina_komunalnog_otpada: null,
  preuzeta_kolicina_nekomunalnog_otpada: null,
  kolicina_za_r1_operaciju: null,
  druga_operacija_r_id: '',
  kolicina_za_drugu_r_operaciju: null,
  kolicina_za_d1_operaciju: null,
  druga_operacija_d_id: '',
  kolicina_za_drugu_d_operaciju: null,
};

export const AAO2_PRAVNO_LICE: IPostPravnoLiceIliPreduzetnik = {
  pib: '',
  maticni_broj: '',
  naziv: '',
  mesto_id: '',
  postanski_broj: '',
  ulica: '',
  kucni_broj: '',
  telefon: '',
  telefax: '',
  email: '',
  sifarnik_pretezne_delatnosti_id: '',
};

export const AAO2_PRAVNO_LICE_FIELDS = [
  'pib',
  'maticni_broj',
  'naziv',
  'mesto_id',
  'mesto',
  'postanski_broj',
  'ulica',
  'kucni_broj',
  'telefon',
  'email',
  'sifarnik_pretezne_delatnosti_id',
  'sifarnik_pretezne_delatnosti',
];

export const keyMapping = {
  pib: 'tax_identification_number',
  maticni_broj: 'enterprise_id_number',
  naziv: 'full_company_name',
  mesto_id: 'location_name_id',
  mesto: 'location',
  postanski_broj: 'postal_code',
  ulica: 'street',
  kucni_broj: 'building_number',
  email: 'mail',
  sifarnik_pretezne_delatnosti_id: 'principalbusinessactivity_id',
  sifarnik_pretezne_delatnosti: 'principalbusinessactivity',
};
