import { t } from 'i18next';
import { IPostProductsPzv, IGetProductsPzv } from './productsPzv.types';
import dayjs from 'dayjs';
import { Checkbox, FormInstance } from 'antd';
import {
  DateSifarniciFormProps,
  InputSifarniciFormProps,
  SwitchSifarniciFormProps,
  TextAreaSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { MAX_LENGTH_RULE, MAX_NUMBER_LENGTH_RULE, MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { ColumnsType } from 'antd/es/table';
import { productsPzvService } from './productsPzv.service';
import { NAME_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';

export const PRODUCTS_PZV_INITIAL_STATE: IPostProductsPzv = {
  validity_from_year: '',
  validity_to_year: '',
  detergent: false,
  fertilizer: false,
  plant_protection: false,
  name: '',
  content_ph_nas: null,
  additional_information: '',
  is_active: true,
};

export const PRODUCTS_PZV_TABLE_DATA: ColumnsType<IGetProductsPzv> = [
  NAME_TABLE,
  {
    title: `${t('SIFARNICI.OD_GODINE')}`,
    width: '150px',
    dataIndex: 'validity_from_year',
    key: 'validity_from_year',
    render: (_: any, record: IGetProductsPzv) => <span>{dayjs(record.validity_from_year).format('YYYY.') || ''}</span>,
  },
  {
    title: `${t('SIFARNICI.DO_GODINE')}`,
    width: '150px',
    dataIndex: 'validity_to_year',
    key: 'validity_to_year',
    render: (_: any, record: IGetProductsPzv) => <span>{dayjs(record?.validity_to_year).format('YYYY.') || ''}</span>,
  },
  {
    title: `${t('SIFARNICI.DETERDZENT')}`,
    width: '150px',
    dataIndex: 'detergent',
    key: 'detergent',
    align: 'center',
    render: (_: any, record: IGetProductsPzv) => <Checkbox checked={!!record.detergent} disabled={true} />,
  },
  {
    title: `${t('SIFARNICI.DJUBRIVO')}`,
    width: '150px',
    dataIndex: 'fertilizer',
    key: 'fertilizer',
    align: 'center',
    render: (_: any, record: IGetProductsPzv) => <Checkbox checked={!!record.fertilizer} disabled={true} />,
  },
  {
    title: `${t('WIZARD_TABS.ZASTITA_BILJA')}`,
    width: '150px',
    dataIndex: 'plant_protection',
    key: 'plant_protection',
    align: 'center',
    render: (_: any, record: IGetProductsPzv) => <Checkbox checked={!!record.plant_protection} disabled={true} />,
  },

  {
    title: `${t('SIFARNICI.SADRZAJ_FOSFATA_AZOTA')}`,
    width: '200px',
    dataIndex: 'content_ph_nas',
    key: 'content_ph_nas',
  },
  {
    title: `${t('SIFARNICI.DODATNE_INFORMACIJE')}`,
    width: '200px',
    dataIndex: 'additional_information',
    key: 'additional_information',
  },
];

export const PRODUCTS_PZV_FORM_OPTIONS = (form: FormInstance<IGetProductsPzv>) => {
  const queryParams = new URLSearchParams(location.search);
  const sifarnik_type = queryParams.get('sifarnik_type');

  return [
    {
      type_of_form: 'input',
      label: t('OBRASCI.OBRAZAC_1.PRODUCT_NAME'),
      name: 'name',
      inputName: 'name',
      rules: [REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(150)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'validity_from_year',
      label: t('SIFARNICI.OD_GODINE'),
      format: 'YYYY',
      picker: 'year',
      onChange: () => {
        form.setFieldValue('validity_to_year', '');
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'validity_to_year',
      label: t('SIFARNICI.DO_GODINE'),
      format: 'YYYY',
      disabled: !!(sifarnik_type && !form.getFieldValue('validity_from_year')),
      picker: 'year',
      onChange: () => {
        //
      },
      disabledDate: (current: any) => {
        if (form.getFieldValue('validity_from_year')) {
          return current && current.year() < form.getFieldValue('validity_from_year').$y;
        }
      },
    } as DateSifarniciFormProps,

    {
      type_of_form: 'switch',
      name: 'detergent',
      rules: [
        {
          validator(_: any, value: any) {
            return productsPzvService.validationForSwitchComponents(value, form);
          },
        },
      ],
      onSwitchChange: () => {
        form.validateFields(['fertilizer', 'plant_protection']);
      },
      label: t('SIFARNICI.DETERDZENT'),
      disabled: !!(form.getFieldValue('fertilizer') || form.getFieldValue('plant_protection')),
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'switch',
      name: 'fertilizer',
      label: t('SIFARNICI.DJUBRIVO'),
      onSwitchChange: () => {
        form.validateFields(['detergent', 'plant_protection']);
      },
      rules: [
        {
          validator(_: any, value: any) {
            return productsPzvService.validationForSwitchComponents(value, form);
          },
        },
      ],
      disabled: !!(form.getFieldValue('detergent') || form.getFieldValue('plant_protection')),
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'switch',
      name: 'plant_protection',
      label: t('WIZARD_TABS.ZASTITA_BILJA'),
      onSwitchChange: () => {
        form.validateFields(['detergent', 'fertilizer']);
      },
      rules: [
        {
          validator(_: any, value: any) {
            return productsPzvService.validationForSwitchComponents(value, form);
          },
        },
      ],
      disabled: !!(form.getFieldValue('detergent') || form.getFieldValue('fertilizer')),
    } as SwitchSifarniciFormProps,
    {
      type_of_form: 'input_number',
      label: t('SIFARNICI.SADRZAJ_FOSFATA_AZOTA'),
      name: 'content_ph_nas',
      inputName: 'content_ph_nas',
      precision: 0,
      hideInput: true,
      rules: [...MORE_THEN_0, ...MAX_NUMBER_LENGTH_RULE(10)],
    } as InputSifarniciFormProps,
    {
      type_of_form: 'area',
      label: t('SIFARNICI.DODATNE_INFORMACIJE'),
      name: 'additional_information',
      rules: [MAX_LENGTH_RULE(1000)],
      rows: 4,
      labelCol: 10,
      wrapperCol: 14,
    } as TextAreaSifarniciFormProps,
  ];
};
