import { FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NAME_FORM, DESCRIPTION_FORM, DESCRIPTION_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { NAME_TABLE, DESCRIPTION_TABLE, DESCRIPTION_SR_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { IPostQList, IGetQList } from './qList.types';

export const Q_LIST_INITIAL_STATE: IPostQList = {
  name: '',
  description: '',
  description_sr: '',
  is_active: true,
};
export const Q_LIST_TABLE_DATA: ColumnsType<IGetQList> = [NAME_TABLE, DESCRIPTION_TABLE, DESCRIPTION_SR_TABLE];
export const Q_LIST_FORM_OPTIONS = (_: FormInstance<IGetQList>) => {
  return [NAME_FORM, DESCRIPTION_FORM, DESCRIPTION_SR_FORM];
};
