import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { CRUDModal } from 'components/Modals/CRUDModal/CRUDModal';
import { pzv1ObrazacStore } from 'modules/obrasci/store/pzv1/pzv1.store';
import { NewZastitaBiljaForm } from './components/NewZastitaBiljaForm/NewZastitaBiljaForm';
import { ZASTITA_BILJA_INITIAL_STATE } from 'modules/obrasci/store/pzv1/pzv1.constants';

export const ZastitaBiljaModal: React.FC = observer(() => {
  const { t } = useTranslation();

  return (
    <CRUDModal
      width="70%"
      name="zastita-bilja-modal"
      title={t('OBRASCI.PZV_1.PLANT_PROTECTION.TITLE')}
      store={pzv1ObrazacStore}
      data={pzv1ObrazacStore.getZastitaBilja}
      children={() => <NewZastitaBiljaForm />}
      submitForm={pzv1ObrazacStore.submitZastitaBiljaForm}
      initialState={ZASTITA_BILJA_INITIAL_STATE}
      dataToChange="zastitaBilja"
    />
  );
});
