import { ColumnsType } from 'antd/es/table';
import { IGetAnnexActivityCode, IPostAnnexActivityCode } from './annexActivityCode.types';
import dayjs from 'dayjs';
import { FormInstance } from 'antd';
import { t } from 'i18next';
import {
  DateSifarniciFormProps,
  ISifarniciTypeOfForm,
  InputSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import {
  CODE_2_TABLE,
  CODE_TABLE,
  DESCRIPTION_TABLE,
  NAME_SR_TABLE,
  NAME_TABLE,
  TITLE_TABLE,
  VERSION_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import {
  TITLE_FORM,
  DESCRIPTION_FORM,
  VERSION_FORM,
  CODE_FORM,
  NAME_FORM,
  NAME_SR_FORM,
  CODE_2_FORM,
} from 'modules/sifarnici/sifarniciFormObj.constants';

export const ANNEX_ACTIVITY_CODE_INITIAL_STATE: IPostAnnexActivityCode = {
  validity_fromdate: '',
  validity_todate: '',
  title: '',
  description: '',
  version: null,
  code: '',
  name: '',
  name_sr: '',
  minimumthresholdofcapacity: '',
  minimumthresholdofcapacity_sr: '',
  ippccode: '',
  aac_level: '',
  code2: '',
  is_active: true,
};

export const ANNEX_ACTIVITY_CODE_TABLE_DATA: ColumnsType<IGetAnnexActivityCode> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  {
    title: t('SIFARNICI.OD_DATUMA'),
    width: '150px',
    dataIndex: 'validity_fromdate',
    key: 'validity_fromdate',
    render: (_: any, record: IGetAnnexActivityCode) => (
      <span>{record.validity_fromdate ? dayjs(record.validity_fromdate).format('DD. MM. YYYY.') : ''}</span>
    ),
  },
  {
    title: t('SIFARNICI.DO_DATUMA'),
    width: '150px',
    dataIndex: 'validity_todate',
    key: 'validity_todate',
    render: (_: any, record: IGetAnnexActivityCode) => (
      <span>{record.validity_todate ? dayjs(record.validity_todate).format('DD. MM. YYYY.') : ''}</span>
    ),
  },
  TITLE_TABLE,
  DESCRIPTION_TABLE,
  VERSION_TABLE,
  CODE_TABLE,

  // todo: prevod
  {
    title: 'Minimalna granična vrednost kapaciteta ',
    width: '200px',
    dataIndex: 'minimumthresholdofcapacity',
    key: 'minimumthresholdofcapacity',
  },
  {
    title: 'Minimalna granična vrednost kapaciteta (srp.)',
    width: '250px',
    dataIndex: 'minimumthresholdofcapacity_sr',
    key: 'minimumthresholdofcapacity_sr',
  },
  {
    title: 'IPPC kod',
    width: '100px',
    dataIndex: 'ippccode',
    key: 'ippccode',
  },
  {
    title: 'Nivo aac',
    width: '100px',
    dataIndex: 'aac_level',
    key: 'aac_level',
  },
  CODE_2_TABLE,
];

export const ANNEX_ACTIVITY_CODE_FORM_OPTIONS = (form: FormInstance<IGetAnnexActivityCode>) => {
  const queryParams = new URLSearchParams(location.search);
  const sifarnik_type = queryParams.get('sifarnik_type');

  return [
    NAME_FORM,
    NAME_SR_FORM,
    {
      type_of_form: 'date',
      name: 'validity_fromdate',
      label: t('SIFARNICI.OD_DATUMA'),
      format: 'DD-MM-YYYY',
      picker: 'date',
      onChange: () => {
        form.setFieldValue('validity_todate', '');
      },
      // placeholder={t('SELECT_YEAR')}
    } as DateSifarniciFormProps,
    {
      type_of_form: 'date',
      format: 'DD-MM-YYYY',
      name: 'validity_todate',
      label: t('SIFARNICI.DO_DATUMA'),
      disabled: !!(sifarnik_type && !form.getFieldValue('validity_fromdate')),
      picker: 'date',
      onChange: () => {
        //
      },
      disabledDate: (current: any) => {
        return current && current.endOf('day') < form.getFieldValue('validity_fromdate');
      },
      // placeholder={t('SELECT_YEAR')}
    },
    TITLE_FORM,
    DESCRIPTION_FORM,
    VERSION_FORM,
    CODE_FORM,

    {
      type_of_form: 'input',
      name: 'minimumthresholdofcapacity',
      // todo: prevod
      label: 'Minimalna granična vrednost kapaciteta ',
      inputName: 'minimumthresholdofcapacity',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'minimumthresholdofcapacity_sr',
      // todo: prevod
      label: 'Minimalna granična vrednost kapaciteta (srp.)',
      inputName: 'minimumthresholdofcapacity_sr',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'ippccode',
      // todo: prevod
      label: 'IPPC kod',
      inputName: 'ippccode',
    } as InputSifarniciFormProps,
    {
      // todo: input_nubmer or input
      type_of_form: 'input_number',
      name: 'aac_level',
      // todo: prevod
      label: 'Nivo aac',
      inputName: 'aac_level',
    } as InputSifarniciFormProps,
    CODE_2_FORM,
    // /////////
  ] as ISifarniciTypeOfForm[];
};
