import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { IGodinaTipObrasca } from 'modules/zakljucavanje/zakljucavanje.types';
import { LockSwitch } from '../LockSwitch/LockSwitch';
import styles from './ZakljucavanjePoGodiniObrazacRow.module.scss';
import { zakljucavanjeStore } from 'modules/zakljucavanje/zakljucavanje.store';

interface ZakljucavanjePoGodiniObrazacRowProps {
  godinaTipObrasca: IGodinaTipObrasca;
}

export const ZakljucavanjePoGodiniObrazacRow: React.FC<ZakljucavanjePoGodiniObrazacRowProps> = observer(
  ({ godinaTipObrasca }) => {
    const tip = godinaTipObrasca.tip_obrasca;

    const handleOnSwitchClick = (isLocked: boolean) => {
      zakljucavanjeStore.toggleLockTipObrasca(isLocked, [godinaTipObrasca]);
    };

    return (
      <Row className={styles.zakljucavanjeGodinaRow}>
        <Col span={23}>{`${tip.skraceni_naziv} -  ${tip.naziv}`}</Col>
        <Col span={1}>
          <LockSwitch
            switchName={tip.skraceni_naziv}
            initialLocked={godinaTipObrasca.zakljucana_godina}
            onSwitch={handleOnSwitchClick}
          />
        </Col>
      </Row>
    );
  }
);
