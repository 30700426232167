import { ReactNode } from 'react';
import loadable from '@loadable/component';
import { UserRoles } from 'modules/user/user.types';
import { Company, Facility, Sifarnici, User, UsersList } from 'pages';
import { Obrasci } from 'pages/Obrasci/Obrasci';
import { OBRASCI_ROUTES } from 'modules/obrasci/obrasci.constants';
import { ObrazacWizard } from 'pages/ObrazacWizard/ObrazacWizard';
import { registriConfig } from 'modules/registri/registri.constants';
import { ROUTES } from 'modules/nriz/nriz.constants';
import { Registri } from 'pages/Registri/Registri';
import { Izvestaji } from 'pages/Izvestaji/Izvestaji';
import i18n from 'i18next';
import {
  AuditOutlined,
  BookOutlined,
  DatabaseOutlined,
  FolderOpenOutlined,
  FundOutlined,
  HistoryOutlined,
  HomeOutlined,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { ObrazacIzvestaji } from 'pages/ObrazacIzvestaji/ObrazacIzvestaji';
import { ZakljucavanjePoGodini } from 'pages/ZakljucavanjeObrazaca/ZakljucavanjePoGodini/ZakljucavanjePoGodini';
import { ZakljucavanjePoTipu } from 'pages/ZakljucavanjeObrazaca/ZakljucavanjePoTipu/ZakljucavanjePoTipu';
import { Obrazac1IzvestajiEnum, ObrazacIzvestajTypeEnum } from 'modules/obrazacIzvestaji/obrazacIzvestaji.types';
import { IstorijaPromena } from 'pages/IstorijaPromena/IstorijaPromena';

export interface IRouteConfig {
  path: string;
  element: ReactNode;
  key: string;
  children?: IRouteConfig[];
  menuProps?: MenuProps;
  allowedRoles: UserRoles[];
  isSubMenuSelected?: (path: string) => boolean;
}

interface MenuProps {
  menuLink?: string;
  subpath: string[];
  label: string;
  icon: ReactNode;
  roles: UserRoles[];
}

// *** DYNAMIC IMPORTS ***
const UserRegistration = loadable(() => import('pages/UserRegistration/UserRegistration'));
const UserStartPage = loadable(() => import('pages/UserStartPage/UserStartPage'));
const CompaniesList = loadable(() => import('pages/CompaniesList/CompaniesList'));

const OBRASCI_ROUTES_MAP: IRouteConfig[] = OBRASCI_ROUTES.map(route => {
  const routes = [
    {
      path: `${route.path}:obrazac_id/`,
      key: route.path,
      element: <ObrazacWizard wizardConfig={route.wizardConfig} store={route.store} modals={route.modals} />,
      allowedRoles: [UserRoles.SystemAdmin, UserRoles.CompanyOwner, UserRoles.CompanyUser],
    },
    {
      path: `${route.path}:obrazac_id/:id`,
      key: `${route.path}_id`,
      element: <ObrazacWizard wizardConfig={route.wizardConfig} store={route.store} modals={route.modals} />,
      allowedRoles: [UserRoles.SystemAdmin, UserRoles.CompanyOwner, UserRoles.CompanyUser],
    },
  ];
  return routes;
}).flat();

const REGISTRI_ROUTES_MAP: IRouteConfig[] = Object.values(registriConfig).map(registar => {
  return {
    path: `${registar.route}/:id`,
    key: registar.route,
    element: registriConfig[registar.key].component,
    allowedRoles: [UserRoles.SystemAdmin],
  };
});

export const routes: IRouteConfig[] = [
  {
    path: '/user-registration',
    element: <UserRegistration />,
    key: 'user-registration',
    // globalRoute
    allowedRoles: [],
  },
  {
    path: '/user-start-page',
    element: <UserStartPage />,
    key: 'user-start-page',
    allowedRoles: [UserRoles.CompanyUser, UserRoles.CompanyOwner],
  },
  {
    path: '/companies',
    element: <CompaniesList />,
    key: 'companies',
    allowedRoles: [UserRoles.SystemAdmin],
    menuProps: {
      subpath: ['companies', 'company', 'facility'],
      label: i18n.t('COMPANY.COMPANIES'),
      icon: <HomeOutlined rev={undefined} />,
      roles: [UserRoles.SystemAdmin],
    },
  },
  {
    path: '/company/:id',
    element: <Company />,
    key: 'company',
    allowedRoles: [UserRoles.SystemAdmin, UserRoles.CompanyOwner, UserRoles.CompanyUser],
    menuProps: {
      subpath: ['company', 'facility'],
      label: 'Preduzeće',
      icon: <HomeOutlined rev={undefined} />,
      roles: [UserRoles.CompanyOwner, UserRoles.CompanyUser],
    },
  },
  {
    path: '/users',
    element: <UsersList />,
    key: 'users',
    allowedRoles: [UserRoles.SystemAdmin],
    menuProps: {
      subpath: ['users', 'user'],
      label: i18n.t('USER.USERS'),
      icon: <UserOutlined rev={undefined} />,
      roles: [UserRoles.SystemAdmin],
    },
  },
  {
    path: '/user/:id',
    element: <User />,
    key: 'user',
    allowedRoles: [UserRoles.SystemAdmin, UserRoles.CompanyOwner, UserRoles.CompanyUser],
    menuProps: {
      subpath: ['user'],
      label: i18n.t('Korisnik'),
      icon: <UserOutlined rev={undefined} />,
      roles: [UserRoles.CompanyOwner, UserRoles.CompanyUser],
    },
  },
  {
    path: '/facility/:id',
    element: <Facility />,
    key: 'facility',
    allowedRoles: [UserRoles.SystemAdmin, UserRoles.CompanyOwner, UserRoles.CompanyUser],
  },
  {
    path: '/forms',
    element: <Obrasci />,
    key: 'forms',
    allowedRoles: [UserRoles.SystemAdmin, UserRoles.CompanyOwner, UserRoles.CompanyUser],
    menuProps: {
      subpath: ['forms', 'obrazac/'],
      label: i18n.t('OBRASCI.OBRASCI'),
      icon: <AuditOutlined rev={undefined} />,
      roles: [UserRoles.SystemAdmin, UserRoles.CompanyOwner, UserRoles.CompanyUser],
    },
  },
  ...OBRASCI_ROUTES_MAP,
  {
    path: `/${ROUTES.REGISTRI}/:registar`,
    element: <Registri />,
    key: 'registri',
    allowedRoles: [UserRoles.SystemAdmin],
    menuProps: {
      subpath: ['registri'],
      label: i18n.t('REGISTRI.TITLE'),
      icon: <BookOutlined rev={undefined} />,
      roles: [UserRoles.SystemAdmin],
    },
  },
  ...REGISTRI_ROUTES_MAP,
  {
    path: `/preseci`,
    element: <Izvestaji />,
    key: 'preseci',
    allowedRoles: [UserRoles.SystemAdmin],
    menuProps: {
      subpath: ['preseci'],
      label: i18n.t('Preseci'),
      icon: <FundOutlined rev={undefined} />,
      roles: [UserRoles.SystemAdmin],
    },
  },
  {
    path: `/sifarnici`,
    element: <Sifarnici />,
    key: 'sifarnici',
    allowedRoles: [UserRoles.SystemAdmin],
    menuProps: {
      subpath: ['sifarnici'],
      label: i18n.t('Šifarnici'),
      icon: <DatabaseOutlined rev={undefined} />,
      roles: [UserRoles.SystemAdmin],
    },
  },
  {
    path: '/obrazac-izvestaji',
    element: <ObrazacIzvestaji />,
    key: 'obrazac-izvestaji',
    allowedRoles: [UserRoles.SystemAdmin],
    menuProps: {
      menuLink: `/obrazac-izvestaji?type=${ObrazacIzvestajTypeEnum.OBRAZAC_1}&subtype=${Obrazac1IzvestajiEnum.GORIVA}`,
      subpath: ['/obrazac-izvestaji'],
      label: i18n.t('OBRAZAC_IZVESTAJI.OBRAZAC_IZVESTAJI'),
      icon: <FolderOpenOutlined rev={undefined} />,
      roles: [UserRoles.SystemAdmin],
    },
  },
  {
    path: '/zakljucavanje',
    element: <></>,
    key: 'zakljucavanje',
    allowedRoles: [UserRoles.SystemAdmin],
    menuProps: {
      subpath: [],
      label: i18n.t('ZAKLJUCAVANJE_OBRAZACA.TITLE'),
      icon: <LockOutlined rev={undefined} />,
      roles: [UserRoles.SystemAdmin],
    },
    children: [
      {
        path: 'zakljucavanje/godina',
        element: <ZakljucavanjePoGodini />,
        key: 'zakljucavanje-godina',
        allowedRoles: [UserRoles.SystemAdmin],
        menuProps: {
          subpath: [],
          label: i18n.t('ZAKLJUCAVANJE_OBRAZACA.PO_GODINI'),
          icon: <></>,
          roles: [UserRoles.SystemAdmin],
        },
      },
      {
        path: 'zakljucavanje/tip-obrasca',
        element: <ZakljucavanjePoTipu />,
        key: 'zakljucavanje-tip-obrasca',
        allowedRoles: [UserRoles.SystemAdmin],
        menuProps: {
          subpath: [],
          label: i18n.t('ZAKLJUCAVANJE_OBRAZACA.PO_TIPU'),
          icon: <></>,
          roles: [UserRoles.SystemAdmin],
        },
      },
    ],
  },
  {
    path: '/istorija-promena',
    element: <IstorijaPromena />,
    key: 'istorija-promena',
    allowedRoles: [UserRoles.SystemAdmin],
    menuProps: {
      menuLink: `/istorija-promena`,
      subpath: [],
      label: i18n.t('ISTORIJA_PROMENA.TITLE'),
      icon: <HistoryOutlined rev={undefined} />,
      roles: [UserRoles.SystemAdmin],
    },
  },
];
