import { IKolicine, INaselja, IPostKom1Obraza, IPostPredato, ISastav, IUpravljanje } from './kom1.types';
import { t } from 'i18next';
export const KOM1_INITIAL_STATE: IPostKom1Obraza = {
  godina: '',
  preduzece_id: '',
  postrojenje_id: '',
  opstina_id: '',
  komunalni_kontenjer_od_5_m3: null,
  komunalni_kontenjer_od_7_m3: null,
  komunalni_kontenjer_od_10_m3: null,
  prese_ili_rolo_kontejneri: null,
  kontejner_od_1_1_m3: null,
  veliki_kontejner: null,
  druge_vrste_kontejnera: null,
  kante: null,
  kese: null,
  komunalni_kontenjer_od_5_m3_sekundarna: null,
  komunalni_kontenjer_od_7_m3_sekundarna: null,
  komunalni_kontenjer_od_10_m3_sekundarna: null,
  prese_ili_rolo_kontejneri_sekundarna: null,
  kontejner_od_1_1_m3_sekundarna: null,
  veliki_kontejner_sekundarna: null,
  druge_vrste_kontejnera_sekundarna: null,
  kante_sekundarna: null,
  kese_sekundarna: null,
  autosmecar_rotopres: null,
  autopodizac: null,
  kamion: null,
  kiper: null,
  traktor_sa_prikolicom: null,
  zaprezno_vozilo: null,
  traktor_gusenicar: null,
  kompaktor: null,
  cisterna_pijace_vode: null,
  cisterna_za_ciscenje_ulica: null,
  vozila_za_pranje_kontejnera: null,
  vozila_za_ciscenje_septickih_jama: null,
  napomena: '',
  naselja: [],
  kolicine: [],
  sastav: [],
  upravljanje: [],
};

export const NASELJE_INITIAL_STATE: INaselja = {
  mesto_id: '',
  ukupan_broj_domacinstava_u_naselju: null,
  broj_domacinstava_obuhvacenih_prikupljanjem_otpada: null,
  broj_domacinstava_u_naselju_individulni_tip_stanovanja: null,
  broj_domacinstava_u_naselju_kolektivni_tip_stanovanja: null,
  broj_domacinstava_obuhvacen_prikupljanjem_otpada_individulni_tip_stanovanja: null,
  broj_domacinstava_obuhvacen_prikupljanjem_otpada_kolektivni_tip_stanovanja: null,
};

export const KOLICINA_INITIAL_STATE: IKolicine = {
  kom1_type_of_waste_id: '',
  prolecna_analiza: null,
  letnja_analiza: null,
  jesenja_analiza: null,
  zimska_analiza: null,
  srednja_vrednost: null,
};

export const SASTAV_INITIAL_STATE: ISastav = {
  composition_communal_waste_id: '',
  prolecna_analiza: null,
  letnja_analiza: null,
  jesenja_analiza: null,
  zimska_analiza: null,
  srednja_vrednost: null,
};

export const UPRAVLJANJE_INITIAL_STATE: IUpravljanje = {
  waste_catalogue_id: '',
  ukupna_kolicina_sakupljenog_ili_preuzetog_otpada_u_izvestajnoj_godini: null,
  method_basis_code_id: '',
  iz_kontejnera_za_primarnu_selekciju: null,
  iz_reciklaznih_dvorista: null,
  drugih_sabirnih_mesta: null,
  kom1_nacin_predaje_otpada: [],
  kolicina_sakupljenog_otpada_iz_domacinstva: null,
};

export const PREDATO_INITIAL_STATE: IPostPredato = {
  way_of_hand_over_id: '',
  kolicina: null,
  naziv_i_adresa_operatera: '',
  dr_list_id: '',
  broj_dozvole_operatera: '',
};

export const filtersKom1InitialState = {
  godina: '',
  preduzece_id: '',
  opstina_id: '',
  postrojenje_id: '',
};

export const opremaIMehanizacijaOptions = [
  {
    key: 1,
    title: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.WASTE_COLLECTION_EQUIPMENT'),
    rightHeader: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.NUMBER'),
    children: [
      {
        name: 'komunalni_kontenjer_od_5_m3',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.UTILITY_CONTAINER_OF_5_M3'),
      },
      {
        name: 'komunalni_kontenjer_od_7_m3',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.UTILITY_CONTAINER_OF_7_M3'),
      },
      {
        name: 'komunalni_kontenjer_od_10_m3',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.UTILITY_CONTAINER_OF_10_M3'),
      },
      {
        name: 'prese_ili_rolo_kontejneri',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.PRESS_OR_ROLL_CONTAINERS'),
      },
      {
        name: 'kontejner_od_1_1_m3',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.CONTAINERS_OF_1_1_M3'),
      },
      {
        name: 'veliki_kontejner',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.LARGE_CONTAINERS'),
      },
      {
        name: 'druge_vrste_kontejnera',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.OTHER_TYPES_OF_CONTAINERS'),
      },
      {
        name: 'kante',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.BUCKETS'),
      },
      {
        name: 'kese',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.BAGS'),
      },
    ],
  },
  {
    key: 2,
    title: t('Oprema za prikupljanje sekundarnih sirovina'),
    rightHeader: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.NUMBER'),
    children: [
      {
        name: 'komunalni_kontenjer_od_5_m3_sekundarna',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.UTILITY_CONTAINER_OF_5_M3'),
      },
      {
        name: 'komunalni_kontenjer_od_7_m3_sekundarna',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.UTILITY_CONTAINER_OF_7_M3'),
      },
      {
        name: 'komunalni_kontenjer_od_10_m3_sekundarna',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.UTILITY_CONTAINER_OF_10_M3'),
      },
      {
        name: 'prese_ili_rolo_kontejneri_sekundarna',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.PRESS_OR_ROLL_CONTAINERS'),
      },
      {
        name: 'kontejner_od_1_1_m3_sekundarna',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.CONTAINERS_OF_1_1_M3'),
      },
      {
        name: 'veliki_kontejner_sekundarna',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.LARGE_CONTAINERS'),
      },
      {
        name: 'druge_vrste_kontejnera_sekundarna',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.OTHER_TYPES_OF_CONTAINERS'),
      },
      {
        name: 'kante_sekundarna',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.BUCKETS'),
      },
      {
        name: 'kese_sekundarna',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.BAGS'),
      },
    ],
  },
  {
    key: 3,
    title: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.WASTE_TRANSPORT_EQUIPMENT'),
    rightHeader: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.VEHICLE_NUMBER'),
    children: [
      {
        name: 'autosmecar_rotopres',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.GARBAGE_TRUCK'),
      },
      {
        name: 'autopodizac',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.CAR_LIFTER'),
      },
      {
        name: 'kamion',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.TRUCK'),
      },
      {
        name: 'kiper',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.DUMP_TRACK'),
      },
      {
        name: 'traktor_sa_prikolicom',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.TRACTOR_WITH_TRAILER'),
      },
      {
        name: 'zaprezno_vozilo',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.CARTED_VEHICLE'),
      },
    ],
  },
  {
    key: 4,
    title: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.EQUIPMENT_ON_LANDFILL'),
    rightHeader: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.NUMBER_OF_MACHINES'),
    children: [
      {
        name: 'traktor_gusenicar',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.CRAWLER_TRACTOR'),
      },
      {
        name: 'kompaktor',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.COMPACTOR'),
      },
    ],
  },
  {
    key: 5,
    title: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.OTHER_EQUIPMENT'),
    rightHeader: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.NUMBER_OF_MACHINES'),
    children: [
      {
        name: 'cisterna_pijace_vode',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.DRINKING_WATER_TANKS'),
      },
      {
        name: 'cisterna_za_ciscenje_ulica',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.STREET_CLEANING_TANKS'),
      },
      {
        name: 'vozila_za_pranje_kontejnera',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.CONTAINER_WASHING_VEHICLES'),
      },
      {
        name: 'vozila_za_ciscenje_septickih_jama',
        label: t('OBRASCI.KOM_1.EQUIPMENT_AND_MECHANISATION.VEHICLES_FOR_CLEANING_SEPTIC_TANKS'),
      },
    ],
  },
];

export const INDEX_NUMBER_IN =
  '20 03 99,20 03 07,20 03 06,20 03 04,20 03 03,20 03 02,20 03 01,20 02 03,20 02 02,20 02 01,20 01 99,20 01 41,20 01 40,20 01 39,20 01 38,20 01 37*,20 01 36,20 01 35*,20 01 34,20 01 33*,20 01 32,20 01 31*,20 01 30,20 01 29*,20 01 28,20 01 27*,20 01 26*,20 01 25,20 01 23*,20 01 21*,20 01 19*,20 01 17*,20 01 15*,20 01 14*,20 01 13*,20 01 11,20 01 10,20 01 08,20 01 02,20 01 01,15 01 01,15 01 02,15 01 04,15 01 05,15 01 07,16 01 03,16 01 18';

export const KOLICINA_GODISNJA_DOBA: { name: string; label: string }[] = [
  {
    name: 'prolecna_analiza',
    label: t('OBRASCI.KOM_1.SPRING_ANALYSIS'),
  },
  {
    name: 'letnja_analiza',
    label: t('OBRASCI.KOM_1.SUMMER_ANALYSIS'),
  },
  {
    name: 'jesenja_analiza',
    label: t('OBRASCI.KOM_1.AUTUMN_ANALYSIS'),
  },
  {
    name: 'zimska_analiza',
    label: t('OBRASCI.KOM_1.WINTER_ANALYSIS'),
  },
];

export const GODISNJA_DOBA_FIELDS: string[] = KOLICINA_GODISNJA_DOBA.map(item => item.name);

export const SASTAV_GODISNJA_DOBA: { name: string; label: string }[] = [
  // todo: prevod
  {
    name: 'prolecna_analiza',
    label: `Prolećna analiza - ${t('OBRASCI.KOM_1.QUANTITY_FRACTIONS')}`,
  },
  {
    name: 'letnja_analiza',
    label: `Letnja analiza - ${t('OBRASCI.KOM_1.QUANTITY_FRACTIONS')}`,
  },
  {
    name: 'jesenja_analiza',
    label: `Jesenja analiza - ${t('OBRASCI.KOM_1.QUANTITY_FRACTIONS')}`,
  },
  {
    name: 'zimska_analiza',
    label: `Zimska analiza - ${t('OBRASCI.KOM_1.QUANTITY_FRACTIONS')}`,
  },
];
