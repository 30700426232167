import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FormInput } from 'components/FormInput/FormInput';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NewUserForm.module.scss';
import { observer } from 'mobx-react-lite';
import { authStore, formStore, modalStore } from 'store';
import { CRUDButton } from 'components/Buttons/CRUDButton/CRUDButton';
import { IFormProps } from 'typescript/NrizTypes';
import { EMAIL_FIELD_RULE, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { IGetUser, IPostUser, UserRoles } from 'modules/user/user.types';
import { userInitialState } from 'modules/user/user.constants';
import { userStore } from 'modules/user/user.store';
import { useLocation } from 'react-router-dom';

import { FormSwitch } from 'components/FormSwitch/FormSwitch';
import { FormSelect } from 'components/FormSelect/FormSelect';
import { Button } from 'components/Button/Button';
import { Switch } from 'components/Switch/Switch';

export const NewUserForm: React.FC<IFormProps> = observer(props => {
  const [form] = useForm();
  const { t } = useTranslation();

  const [showChangeOwner, setShowChangeOwner] = useState(false);

  useEffect(() => {
    if (
      !props.readOnly &&
      authStore.getUserRole !== UserRoles.CompanyUser &&
      (userStore.getUser as IGetUser).main_company_user
    ) {
      setShowChangeOwner(true);
    } else {
      setShowChangeOwner(false);
    }
  }, [props.readOnly, userStore.getUser]);

  const onFinishFailed = () => {
    const newValues = form.validateFields().then(() => {
      form.setFields(
        form.getFieldsValue().reduce((acc: any, value: any, key: any) => {
          acc[key] = {
            value,
            error: [],
            rules: [],
          };
          return acc;
        }, {})
      );
    });
    return userStore.submitUser(newValues as any);
  };
  const location = useLocation();

  useEffect(() => {
    if (props.passForm) {
      props.passForm(form);
    }
    form.setFieldsValue({ ...userStore.getUser });
  }, [userStore.getUser]);

  return (
    <Form
      form={form}
      initialValues={userInitialState}
      name={props.formName}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      layout="horizontal"
      onFinish={(values: IPostUser) => {
        userStore.submitUser(values);
      }}
      className={styles.user_form}
      labelAlign="left"
      onFinishFailed={() => {
        if (formStore.crudButtonType === 'delete') {
          onFinishFailed();
        }
      }}
    >
      <FormInput
        label={t('USER.USERNAME')}
        name="username"
        inputName={'username'}
        type={'text'}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
        readOnly={props.readOnly}
        disabled={!!(userStore.user?.username && !props.readOnly)}
      />
      <FormInput
        label={t('USER.NAME')}
        name="ime"
        inputName={'ime'}
        type={'text'}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
        readOnly={props.readOnly}
        disabled={!!(userStore.user?.ime && !props.readOnly)}
      />
      <FormInput
        label={t('USER.SURNAME')}
        name="prezime"
        inputName={'prezime'}
        type={'text'}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
        readOnly={props.readOnly}
        disabled={!!(userStore.user?.prezime && !props.readOnly)}
      />
      <FormInput
        label={t('USER.EMAIL')}
        name="email"
        inputName={'email'}
        type={'email'}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly), EMAIL_FIELD_RULE]}
        readOnly={props.readOnly}
        disabled={!!(userStore.user?.email && !props.readOnly)}
      />
      <FormInput
        label={t('USER.PHONE')}
        name="broj_telefona"
        inputName={'broj_telefona'}
        type={'text'}
        className={`removeArrow`}
        placeholder="+381 60666555"
        rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
        readOnly={props.readOnly}
      />

      <FormSelect
        allowClear={!props.readOnly}
        rules={[REQUIRED_FIELD_RULE(!props.readOnly)]}
        name={'jezik'}
        label={t('USER.LANGUAGE')}
        options={[{ label: t('SERBIAN'), value: 'srpski' }]}
        disabled={props.readOnly}
        className={props.readOnly ? styles.disabledDropdown : ''}
      />

      {authStore.getUserRole === UserRoles.SystemAdmin && (userStore.getUser as IGetUser).sistem_administrator && (
        <FormSwitch label="Sistem administrator" name={'sistem_administrator'} disabled={true} />
      )}

      {!location.pathname.includes('/user-registration') &&
        userStore.userTypeForm !== 'user-administrator' &&
        //todo: proveriti ovo
        !(userStore.getUser as IGetUser).sistem_administrator && (
          <>
            <FormInput
              label={`Alternativni telefon`}
              name="alternativni_telefon"
              inputName={'alternativni_telefon'}
              type={'text'}
              className={`removeArrow`}
              placeholder="+381 60666555"
              readOnly={props.readOnly}
            />
            <FormInput
              label={'Funkcija osobe'}
              name="function_of_person"
              inputName={'function_of_person'}
              type={'text'}
              readOnly={props.readOnly}
              disabled={authStore.getUserRole === UserRoles.CompanyUser}
            />
          </>
        )}
      {(userStore.getUser as IGetUser).main_company_user && !location.pathname.includes('/user-registration') && (
        <Row justify={'space-between'} align={'middle'} style={{ marginBottom: '20px' }}>
          <Col span={9} style={{ fontWeight: 'bold' }}>
            Vlasnik preduzeća:
          </Col>
          <Col span={15}>
            {!showChangeOwner ? (
              <Switch
                style={{ marginLeft: '5px' }}
                name={'main_company_user'}
                disabled={true}
                checked={(userStore.getUser as IGetUser).main_company_user}
              />
            ) : (
              <Button
                style={{ width: '100%' }}
                name={'promeni-vlasnika'}
                onClick={() => {
                  modalStore.changeModalName('change-company-owner');
                }}
              >
                Promeni vlasnika preduzeća
              </Button>
            )}
          </Col>
        </Row>
      )}
      {props.showButtons && (
        <CRUDButton showButton={!!(userStore.user as IGetUser).id} form={form} withOutDelete={true} />
      )}
    </Form>
  );
});
