import {
  IPostBilansGve,
  IPostEmisijeUVazduhGve,
  IPostGorivaGve,
  IPostGve,
  IPostIzmereneVrednostiGve,
} from './gve.types';

export const GVE_INITIAL_STATE: IPostGve = {
  godina: 0,
  preduzece_id: '',
  postrojenje_id: '',
  obrazac_gve: false,
  obrazac_2: false,
  lriz_obrazac_2: false,
  izvor_zagadjenja_vazduha_id: '',
  godisnja_iskoriscenost_kapaciteta: null,
  srednja_godisnja_temperatura: null,
  srednja_godisnja_brzina: null,
  srednji_godisnji_protok: null,
  radni_rezim_ispusta_id: '',
  broj_radnih_dana: null,
  broj_radnih_sati: null,
  ukupan_broj_radnih_sati_godisnje: null,
  zima: null,
  prolece: null,
  leto: null,
  jesen: null,
  napomena: '',
  kreiranje_godisnjeg_bilansa: null,
  goriva: [],
  bilans: [],
  emisije_u_vazduh: [],
};

export const GVE_GORIVA_INITIAL_STATE: IPostGorivaGve = {
  naziv_goriva_id: '',
  ukupna_godisnja_potrosnja: null,
  donja_toplotna_moc: null,
  sumpor: null,
  azot: null,
  hlor: null,
  naziv_dodatnog_sadrzaja1: '',
  dodatni_sadrzaj1: null,
  naziv_dodatnog_sadrzaja2: '',
  dodatni_sadrzaj2: null,
  obrazac2_id: '',
};

export const GVE_BILANS_INITIAL_STATE: IPostBilansGve = {
  naziv_zagadjujuce_materije_id: '',
  srednja_godisnja_izmerena_vrednost: null,
  nacin_odredjivanja_dimni_gas_id: '',
  emitovana_kolicina_normalan_rad_g_h: null,
  emitovana_kolicina_normalan_rad_kg_god: null,
  nacin_odredjivanja_normalan_rad_id: '',
  metod_utvrdjivanja_id: '',
  emitovane_kolicine_u_akcidentnim_situacijama: null,
  srednji_godisnji_protok_na_mernom_mestu: null,
  obrazac2_id: '',
};

export const GVE_EMISIJE_U_VAZDUH_INITIAL_STATE: IPostEmisijeUVazduhGve = {
  datum_merenja: '',
  naziv_strucne_laboratorije_id: '',
  indetifikacioni_broj_izvestaja: '',
  merenja_izmerene_vrednosti_emisija_u_vazduhu: [],
  obrazac2_id: '',
};

export const GVE_IZMERENE_VREDNOSTII_INITIAL_STATE: IPostIzmereneVrednostiGve = {
  naziv_zagadjujuce_materije_id: '',
  predznak_izmerene_vrednosti: '',
  izmerene_vrednosti: null,
  metod_utvrdjivanja_id: '',
  gve: null,
  protok_otpadnog_gasa: null,
  predznak_maseni_protok_zagadjujuce_materije: '',
  maseni_protok_zagadjujuce_materije: null,
  emitovane_kolicine_u_akcidentnim_situacijama: null,
  obrazac2_id: '',
  obrazac2_emisije_u_vazduh_id: '',
};
