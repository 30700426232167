import { Row } from 'antd';
import React from 'react';
import { AddNewButton } from 'components/Buttons/AddNewButton/AddNewButton';
import { useTranslation } from 'react-i18next';
import styles from './ZastitaBiljaHeader.module.scss';
import { modalStore } from 'store';

export const ZastitaBiljaHeader: React.FC<any> = () => {
  const { t } = useTranslation();

  return (
    <Row justify={'space-between'} align={'bottom'} className={styles.header}>
      <h3>{t('OBRASCI.PZV_1.PLANT_PROTECTION.TITLE')}</h3>
      <AddNewButton
        name="zastita-bilja"
        onButtonClick={() => {
          modalStore.changeModalName(`zastita-bilja-modal`);
        }}
      />
    </Row>
  );
};
