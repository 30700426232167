import { TIPOVI_OBRAZACA } from 'modules/obrasci/obrasci.types';
import { ColumnType } from 'antd/es/table';
import { IGetFacility } from 'modules/facility/facility.types';
import { IGetAir } from 'modules/sourcesOfPollution/air/air.types';
import { IGetWater } from 'modules/sourcesOfPollution/water/water.types';
import { IGetSoil } from 'modules/sourcesOfPollution/soil/soil.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { FormConfigItemWithForm } from 'modules/obrazacIzvestaji/obrazacIzvestajiFilters.constants';
import { FormInstance } from 'antd';
import { IZakljucavanjeObrazacaFilters } from './zakljucavanje.types';
import { IFormScrollWithObjProps } from 'typescript';

// import { IGetUnsanitaryLandfill } from 'modules/unsanitarylandfill/unsanitarylandfill.types';

//#region zakljucavanjeColumns
const PostrojenjeColumn: ColumnType<IGetFacility> = {
  width: 70,
  title: 'Postrojenje',
  dataIndex: ['postrojenje', 'naziv_postrojenja'],
  key: 'postrojenje',
};

const IzvorVazduhaColumn: ColumnType<IGetAir> = {
  width: 70,
  title: 'Izvor zagadjenja vazduha',
  dataIndex: ['izvor_zagadjenja_vazduha', 'naziv_izvora'],
  key: 'izvor_vazduh',
};

const IzvorVodeColumn: ColumnType<IGetWater> = {
  width: 70,
  title: 'Izvor zagadjenja vode',
  dataIndex: ['izvor_zagadjenja_vode', 'naziv_ispusta'],
  key: 'izvor_vode',
};

const IzvorTlaColumn: ColumnType<IGetSoil> = {
  width: 70,
  title: 'Izvor zagadjenja tla',
  dataIndex: ['izvor_zagadjenja_tla', 'naziv_lokacije'],
  key: 'izvor_tla',
};

const IndeksniBrojColumn: ColumnType<IGetWasteCatalogue> = {
  width: 70,
  title: 'Indeksni broj otpada',
  dataIndex: ['indeksni_broj_otpada', 'waste_sr'],
  key: 'indeksni_broj_otpada',
};

// const NesanitarnaDeponijaColumn: ColumnType<IGetUnsanitaryLandfill> = {
// width: 70,
//   title: 'Nesanitarna deponija',
//   dataIndex: ['nesanitarna_deponija', 'oznaka_nesanitarne_deponije'],
//   key: 'nesanitarna_deponija',
// };

//#endregion

//#region zakljucavanjeFields

export type ZakljucavanjeFormConfig = (form: FormInstance) => IFormScrollWithObjProps[];

export const zakljucavanjeFields: (keyof IZakljucavanjeObrazacaFilters)[] = [
  'godina',
  'preduzece_id',
  'tip_obrasca_id',
  'indeksni_broj_otpada_id',
  'izvor_zagadjenja_tla_id',
  'izvor_zagadjenja_vazduha_id',
  'izvor_zagadjenja_vode_id',
  'postrojenje_id',
];

const postrojenjeFieldsToReset = ['izvor_zagadjenja_vode_id', 'izvor_zagadjenja_vazduha_id', 'izvor_zagadjenja_tla_id'];

const POSTROJENJE_SELECT: FormConfigItemWithForm<IFormScrollWithObjProps> = form => ({
  name: 'postrojenje_id',
  formName: 'postrojenje_id',
  label: 'Postrojenje',
  labelOptionAccessor: ['naziv_postrojenja'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.POSTROJENJE,
  filtersForFetch: {
    preduzece_id: form.getFieldValue('preduzece_id'),
  },
  onChange: () =>
    postrojenjeFieldsToReset.forEach(field => {
      if (form.getFieldValue(field)) form.setFieldValue(field, '');
    }),
  placeholder: 'Postrojenje',
});

// const NESANITARNA_DEPONIJA_SELECT: FormConfigItemWithForm<IFormScrollWithObjProps> = form => ({
// //   name: 'nesanitarna_deponija_id',
//   formName: 'nesanitarna_deponija_id',
//   label: 'Nesanitarna Deponija'
//   labelOptionAccessor: ['oznaka_nesanitarne_deponije'],
//   valueOptionAccessor: 'id',
//   fetchOptinsList: SIFARNICI_ROUTES.NESANITARNA_DEPONIJA,
//   filtersForFetch: {
//     preduzece_id: form.getFieldValue('preduzece_id'),
//   },
//   placeholder: 'Nesanitarna Deponija',
// });

const IZVOR_ZAGADJENJA_VODE_SELECT: FormConfigItemWithForm<IFormScrollWithObjProps> = form => ({
  name: 'izvor_zagadjenja_vode_id',
  formName: 'izvor_zagadjenja_vode_id',
  label: 'Izvor Zagadjenja Vode',
  labelOptionAccessor: ['naziv_ispusta'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VODE,
  filtersForFetch: {
    postrojenje_id: form.getFieldValue('postrojenje_id'),
  },
  disabled: !form.getFieldValue('postrojenje_id'),
  placeholder: 'Izvor Zagadjenja Vode',
});

const IZVOR_ZAGADJENJA_VAZDUHA_SELECT: FormConfigItemWithForm<IFormScrollWithObjProps> = form => ({
  name: 'izvor_zagadjenja_vazduha_id',
  formName: 'izvor_zagadjenja_vazduha_id',
  label: 'Izvor Zagadjenja Vazduha',
  labelOptionAccessor: ['naziv_izvora'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VAZDUHA,
  filtersForFetch: {
    postrojenje_id: form.getFieldValue('postrojenje_id'),
  },
  disabled: !form.getFieldValue('postrojenje_id'),
  placeholder: 'Izvor Zagadjenja Vazduha',
});

const IZVOR_ZAGADJENJA_TLA_SELECT: FormConfigItemWithForm<IFormScrollWithObjProps> = form => ({
  name: 'izvor_zagadjenja_tla_id',
  formName: 'izvor_zagadjenja_tla_id',
  label: 'Izvor Zagadjenja Tla',
  labelOptionAccessor: ['naziv_izvora'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_TLA,
  filtersForFetch: {
    postrojenje_id: form.getFieldValue('postrojenje_id'),
  },
  disabled: !form.getFieldValue('postrojenje_id'),
  placeholder: 'Izvor Zagadjenja Tla',
});

const INDEKSNI_BROJ_SELECT: IFormScrollWithObjProps = {
  formName: 'indeksni_broj_otpada_id',
  fetchOptinsList: SIFARNICI_ROUTES.WASTE_CATALOGUE,
  labelOptionAccessor: ['index_number', 'waste_sr'],
  valueOptionAccessor: 'id',
  inputName: 'indeksni_broj_otpada_id',
  label: 'Indeksni broj',
  placeholder: 'Indeksni broj',
};

// const DEP1_FIELDS: ZakljucavanjeFormConfig = form => [NESANITARNA_DEPONIJA_SELECT(form)];
const GIO2_FIELDS: ZakljucavanjeFormConfig = form => [POSTROJENJE_SELECT(form)];
const GIO3_FIELDS: ZakljucavanjeFormConfig = form => [POSTROJENJE_SELECT(form)];
const GIO4_FIELDS: ZakljucavanjeFormConfig = form => [POSTROJENJE_SELECT(form)];
const GIO5_FIELDS: ZakljucavanjeFormConfig = form => [POSTROJENJE_SELECT(form)];
const KOM1_FIELDS: ZakljucavanjeFormConfig = form => [POSTROJENJE_SELECT(form)];
const OBRAZAC1_FIELDS: ZakljucavanjeFormConfig = form => [POSTROJENJE_SELECT(form)];
const OBRAZAC2_FIELDS: ZakljucavanjeFormConfig = form => [
  POSTROJENJE_SELECT(form),
  IZVOR_ZAGADJENJA_VAZDUHA_SELECT(form),
];
const OBRAZAC3_FIELDS: ZakljucavanjeFormConfig = form => [POSTROJENJE_SELECT(form), IZVOR_ZAGADJENJA_VODE_SELECT(form)];
const OBRAZAC4_FIELDS: ZakljucavanjeFormConfig = form => [POSTROJENJE_SELECT(form), IZVOR_ZAGADJENJA_TLA_SELECT(form)];
const OBRAZAC5_FIELDS: ZakljucavanjeFormConfig = form => [POSTROJENJE_SELECT(form), INDEKSNI_BROJ_SELECT];

//#endregion

// remaining values of TIPOVI_OBRAZACA enum are left out because they either match existing ones or dont require any additional fields or columns and are handled by zakljucavanjeService.getConfigByTipObrasca;

// DEP1 is expected to be added to required fields by BE soon.

export const ZAKLJUCAVANJE_TIP_OBRASCA_COLUMN_MAP: { [key: string]: ColumnType<any>[] } = {
  [TIPOVI_OBRAZACA.DEP1]: [],
  // [TIPOVI_OBRAZACA.DEP1]: [NesanitarnaDeponijaColumn],
  [TIPOVI_OBRAZACA.GIO2]: [PostrojenjeColumn],
  [TIPOVI_OBRAZACA.GIO3]: [PostrojenjeColumn],
  [TIPOVI_OBRAZACA.GIO4]: [PostrojenjeColumn],
  [TIPOVI_OBRAZACA.GIO5]: [PostrojenjeColumn],
  [TIPOVI_OBRAZACA.KOM1]: [PostrojenjeColumn],
  [TIPOVI_OBRAZACA.OBRAZAC_1]: [PostrojenjeColumn],
  [TIPOVI_OBRAZACA.OBRAZAC_2]: [PostrojenjeColumn, IzvorVazduhaColumn],
  [TIPOVI_OBRAZACA.OBRAZAC_3]: [PostrojenjeColumn, IzvorVodeColumn],
  [TIPOVI_OBRAZACA.OBRAZAC_4]: [PostrojenjeColumn, IzvorTlaColumn],
  [TIPOVI_OBRAZACA.OBRAZAC_5]: [PostrojenjeColumn, IndeksniBrojColumn],
};

export const ZAKLJUCAVANJE_TIP_OBRASCA_FIELDS_MAP: {
  [key: string]: (form: FormInstance) => IFormScrollWithObjProps[];
} = {
  [TIPOVI_OBRAZACA.DEP1]: () => [],
  // [TIPOVI_OBRAZACA.DEP1]: DEP1_FIELDS,
  [TIPOVI_OBRAZACA.GIO2]: GIO2_FIELDS,
  [TIPOVI_OBRAZACA.GIO3]: GIO3_FIELDS,
  [TIPOVI_OBRAZACA.GIO4]: GIO4_FIELDS,
  [TIPOVI_OBRAZACA.GIO5]: GIO5_FIELDS,
  [TIPOVI_OBRAZACA.KOM1]: KOM1_FIELDS,
  [TIPOVI_OBRAZACA.OBRAZAC_1]: OBRAZAC1_FIELDS,
  [TIPOVI_OBRAZACA.OBRAZAC_2]: OBRAZAC2_FIELDS,
  [TIPOVI_OBRAZACA.OBRAZAC_3]: OBRAZAC3_FIELDS,
  [TIPOVI_OBRAZACA.OBRAZAC_4]: OBRAZAC4_FIELDS,
  [TIPOVI_OBRAZACA.OBRAZAC_5]: OBRAZAC5_FIELDS,
};
