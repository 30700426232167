import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { modalStore } from 'store';
import styles from './FacilityUsersModalTable.module.scss';
import { nrizService } from 'modules/nriz/nriz.service';
import { CheckOutlined } from '@ant-design/icons';
import { IGetUser } from 'modules/user/user.types';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { useForm } from 'antd/es/form/Form';
import { IFilterTableProps } from 'typescript/NrizTypes';
import { filtersStore } from 'store/filteri.store';
import { userStore } from 'modules/user/user.store';

export const FacilityUsersModalTable: React.FC = observer(() => {
  const columns: ColumnsType<IGetUser> = [
    {
      title: `${t('USER.USERNAME')}`,
      width: '200px',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: `${t('USER.NAME')}`,
      width: '150px',
      dataIndex: 'ime',
      key: 'ime',
    },
    {
      title: `${t('USER.SURNAME')}`,
      width: '150px',
      dataIndex: 'prezime',
      key: 'prezime',
    },
    {
      title: `${t('USER.ADD')}`,
      key: 'id',
      width: '100px',
      fixed: 'right',
      align: 'center',
      render: (_: any, record: IGetUser) => {
        const isFacilityUser = nrizService.isItemInList(userStore.getFacilityUserList, record.id);
        return isFacilityUser ? (
          <CheckOutlined rev={undefined} />
        ) : (
          <Button
            name="add-user-to-facility"
            onClick={() => {
              userStore.postUserFacility(record);
              modalStore.removeAllModals();
            }}
          >
            {t('USER.ADD_BUTTON')}
          </Button>
        );
      },
    },
  ];

  // const userFilterOptions = [
  //   {
  //     label: 'Korisnik',
  //     name: 'search',
  //     type: 'input',
  //     inputName: 'search',
  //     sortName: 'naziv',
  //     type_of_form: 'input',
  //   },
  // ];

  const [userForm] = useForm();

  const data = {
    form: userForm,
    initialState: { ime: '', prezime: '', username: '', order_by: '' },
    filterName: 'korisnik',
    dontAddIlike: true,
    filterOptions: [
      {
        type_of_form: 'input',
        inputName: 'username',
        name: 'username',
        label: 'Korisničko ime',
        sortName: 'username',
      },
      {
        type_of_form: 'input',
        inputName: 'ime',
        name: 'ime',
        label: 'Ime korisnika',
        sortName: 'ime',
      },
      {
        type_of_form: 'input',
        inputName: 'prezime',
        name: 'prezime',
        label: 'Prezime korisnika',
        sortName: 'prezime',
      },
    ],
  } as IFilterTableProps;

  return (
    <div className={styles.modalDiv}>
      <FilterTableForm data={data} />
      <TableData
        name="facitiy-user"
        columns={columns}
        dataSource={[...userStore.getUserList]}
        getData={userStore.fetchUserCompanyList}
        withPagination={true}
        forceFetch={[filtersStore.filters.korisnik]}
      />
    </div>
  );
});
