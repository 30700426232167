import { Table as ANTTable, Pagination, TableProps } from 'antd';
import styles from './TableData.module.scss';
import { observer } from 'mobx-react-lite';
import { IPagination } from 'typescript';
import { nrizService } from 'modules/nriz/nriz.service';
import { useEffect, useState } from 'react';
import { localeEmpty, paginationInitialState } from 'modules/nriz/nriz.constants';
import { cloneDeep } from 'lodash';
import { formStore } from 'store';

import { GetComponentProps } from 'rc-table/lib/interface';
import { getTableScrollHeight } from 'utils/getTableScrollHeight';
import { ColumnProps } from 'antd/es/table';

export interface ITablePagination {
  page: number;
  size: number;
}

interface ITableProps extends TableProps<any> {
  columns: ColumnProps<any>[] | any[];
  dataSource: any[];
  getData?: (pagination: ITablePagination) => any;
  withPagination?: boolean;
  setPagination?: any;
  rowKey?: string | number;
  onChange?: () => void;
  scrollX?: number;
  name: string;
  // wait?: boolean;
  forceFetch?: any[];
  refetchSamePage?: boolean[];
  rowClassName?: (e: any) => string;
  onRow?: GetComponentProps<any>;
  scrollConfig?: string[];
  canFetchOnInit?: boolean;
}

const TableData: React.FC<ITableProps> = ({ canFetchOnInit = true, ...props }) => {
  const [data, setData] = useState<any[]>([]);
  const [paginationTable, setPaginationTable] = useState<IPagination>(paginationInitialState);
  const [isFetchOnInitEnabled, setIsFetchOnInitEnabled] = useState(canFetchOnInit);

  const loadData = async (page: any, size: any) => {
    setData([]);
    if (!props.getData) return;
    const response = await props.getData({
      page: page,
      size: size,
    });

    setData(response.items);
    setPaginationTable({ page: response.page, size: response.size, pages: response.pages, total: response.total });
  };

  const handlePaginationChange = (page: any, pageSize: any) => {
    setPaginationTable({ ...paginationTable, page: page, size: pageSize });
    loadData(page, pageSize);
  };

  useEffect(
    () => {
      if (isFetchOnInitEnabled) {
        loadData(1, paginationTable.size);
      } else {
        setIsFetchOnInitEnabled(true);
      }
    },
    props.forceFetch ? [...props.forceFetch] : []
  );

  useEffect(
    () => {
      if (props.refetchSamePage && props.refetchSamePage[0]) {
        loadData(paginationTable.page, paginationTable.size);
      }
    },
    props.refetchSamePage ? props.refetchSamePage : []
  );

  const setDataProps = () => {
    if (!props.getData) {
      return setData(props.dataSource);
    }

    // todo: proveriti da za sta smo koristili isEmpty
    // if (isEmpty(data)) {
    //   return setData(props.dataSource);
    // }

    // kada brise a na poslednjoj je strani i nije  posledjni item
    if (
      props.dataSource.length < data.length &&
      paginationTable.page === paginationTable.pages &&
      props.dataSource.length !== 0 &&
      formStore.crudButtonType === 'delete'
    ) {
      // console.log(' kada brise a na poslednjoj je strani i nije  posledjni item');

      setData(props.dataSource);
      setPaginationTable({ ...paginationTable, total: paginationTable.total - 1 });
    }

    // console.log(kada brisemo a na poslednjoj smo strani i poslednji item a nije prva strana)
    else if (
      props.dataSource.length < data.length &&
      paginationTable.page === paginationTable.pages &&
      props.dataSource.length === 0 &&
      paginationTable.page !== 1
    ) {
      // console.log('kada brisemo a na poslednjoj smo strani i poslednji item a nije prva strana');
      setPaginationTable({
        ...paginationTable,
        page: paginationTable.page - 1,
        pages: paginationTable.pages - 1,
        total: paginationTable.total - 1,
      });
      loadData(paginationTable.page - 1, paginationTable.size);
    }

    // kada brisemo a na prvoj strani i poslednji item
    else if (
      props.dataSource.length < data.length &&
      paginationTable.page === paginationTable.pages &&
      props.dataSource.length === 0 &&
      paginationTable.page === 1
    ) {
      setPaginationTable({
        ...paginationTable,
        total: paginationTable.total - 1,
      });
      setData(props.dataSource);
    }

    // kada brise a nije na poslednjoj strani ni na prvoj
    else if (
      props.dataSource.length < data.length &&
      paginationTable.page !== paginationTable.pages &&
      props.dataSource.length !== 0
    ) {
      // console.log('kada brise a nije na poslednjoj strani ni na prvoj');

      loadData(paginationTable.page, paginationTable.size);
    }
    // kada dodaje na prvoj je strani i vise od size
    else if (
      props.dataSource.length > data.length &&
      paginationTable.page === 1 &&
      props.dataSource.length > paginationTable.size
    ) {
      // console.log('kada dodaje na prvoj je strani i vise od size');

      props.dataSource.pop();
      setData(props.dataSource);
      setPaginationTable({ ...paginationTable, total: paginationTable.total + 1, pages: paginationTable.pages + 1 });
    }

    // kada dodajemo i na prvoj smo strani a manji od size
    else if (
      props.dataSource.length > data.length &&
      paginationTable.page === 1 &&
      props.dataSource.length <= paginationTable.size
    ) {
      // console.log('kada dodajemo i na prvoj smo strani a manji od size');

      setData(props.dataSource);
      setPaginationTable({ ...paginationTable, total: paginationTable.total + 1 });
    }

    // kada dodaje na strani koja nije 1
    else if (
      props.dataSource.length > data.length &&
      paginationTable.page !== 1 &&
      formStore.crudButtonType === 'submit'
    ) {
      // console.log('kada dodaje na strani koja nije 1');

      loadData(1, paginationTable.size);
    } else {
      // console.log('setDataProps');

      setData(props.dataSource);
    }
  };

  useEffect(() => {
    setDataProps();
  }, [props.dataSource]);

  const defaultOnRow = (_: any, index?: number) => ({
    'data-test': `${props.name}-row-${index}`,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.tableWrapper}>
        <ANTTable
          onChange={() => props.onChange}
          columns={props.columns}
          scroll={{ y: props.scrollConfig ? `calc(100vh - ${getTableScrollHeight(props.scrollConfig)}px)` : '400px' }}
          dataSource={cloneDeep(nrizService.addKey(data))}
          bordered={true}
          pagination={false}
          locale={localeEmpty}
          rowClassName={record => (props.rowClassName ? `${styles[props.rowClassName(record)]}` : '')}
          rowKey={props.rowKey}
          //@ts-ignore
          onRow={props.onRow || defaultOnRow}
          data-test={props.name}
          // sticky
        />
      </div>
      {props.withPagination && (
        <div className={styles.pagination}>
          <Pagination
            current={paginationTable.page}
            pageSize={paginationTable.size}
            total={paginationTable.total}
            onChange={handlePaginationChange}
            showSizeChanger
            showTotal={(_, range) => `${range[0]}-${range[1]} od ${paginationTable.total}`}
          />
        </div>
      )}
    </div>
  );
};
export default observer(TableData);
