import React from 'react';
import { InsideContentWrapper } from 'components/InsideContentWrapper/InsideContentWrapper';
import { WizardObrazacListHeader } from 'components/WizardObrazacListHeader/WizardObrazacListHeader';
import { observer } from 'mobx-react-lite';
import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import TableData from 'components/TableData/TableData';
import { useTranslation } from 'react-i18next';
import { gio4ObrazacStore } from 'modules/obrasci/store/gio4/gio4.store';
import { IGio4Country } from 'modules/obrasci/store/gio4/gio4.types';
import { modalStore } from 'store';

export const Gio4ZemljeTranzitaTable: React.FC = observer(() => {
  const { t } = useTranslation();
  t;

  const baseColumns: ColumnsType<IGio4Country> = [
    {
      title: 'Zemlja tranzita',
      width: 100,
      dataIndex: ['zemlja_tranzita', 'name'],
      key: 'category_name',
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record: IGio4Country) => {
        return (
          <Button
            icon={<EditOutlined rev={undefined} />}
            name={'edit-gio4-zemlja-tranzita'}
            onClick={() => {
              modalStore.changeModalName('gio4-zemlje-tranzita-modal');
              gio4ObrazacStore.handleChange('zemljaTranzita', record);
            }}
          />
        );
      },
    },
  ];

  return (
    <InsideContentWrapper
      header={<WizardObrazacListHeader name={'gio4-zemlja-tranzita-form'} modalName="gio4-zemlje-tranzita-modal" />}
    >
      <TableData
        name="zemlje-tranzita-gio4"
        columns={baseColumns}
        dataSource={[...gio4ObrazacStore.izvozOtpada.zemlje_tranzita]}
      />
    </InsideContentWrapper>
  );
});
